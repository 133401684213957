/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpInterceptor } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export enum AuthState {
  LoggedIn = 1,
  LogggedOut = 2,
  Expired = 3,
  Unknown = 4,
}

export const AUTH_HTTP_INTERCEPTOR = new InjectionToken<HttpInterceptor>('AuthHttpInterceptor');

// AuthProvider is class rather than interface.
// It is necessary to be a key for DI
export abstract class AuthProvider {
  abstract login(): Promise<boolean>;

  abstract logout(): Promise<boolean>;

  abstract get authState(): AuthState;

  abstract get onAuthState(): Observable<AuthState>;
}
