/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { toString } from '@gh/core-util';
import { assign, isArray, isObject, property } from 'lodash';

const TEXT_PROPERTY = property('text');

// If you need to set value as a property of any object -> set valueKey.
// Otherwise, value will be the whole source object
export class Option {
  static create(source: any[] | Object,
                textKey: TransformFn<any, string>,
                displayKey?: TransformFn<any, string>,
                valueKey?: IdentityFn<any>,
                isGroup?: PredicateFn<any>): Option {
    return new Option(source, textKey, displayKey, valueKey, isGroup);
  }

  static createText(text: string): Option {
    return Option.create({ text }, <any>TEXT_PROPERTY, <any>TEXT_PROPERTY, TEXT_PROPERTY);
  }

  source: any;
  text: string;
  display: string;
  value: string;
  group: boolean;

  // FIXME: @NOT_ACTUAL: If not string and not object (e.g. null) -> what?
  // FIXME: source is not Object || Array
  constructor(source: string | any | any[],
              textKey: TransformFn<any, string>,
              displayKey?: TransformFn<any, string>,
              valueKey?: IdentityFn<any>,
              isGroup?: PredicateFn<any>) {
    if (isObject(source)) {
      assign(this, {
        source,
        text: toString(textKey(source)),
        display: toString((displayKey || textKey)(source)),
        value: valueKey ? valueKey(source) : source,
        group: isGroup ? isGroup(source) : false,
      });
    } else if (isArray<any>(source)) {
      const [text, value] = source;
      assign(this, { text, value });
    }
  }
}
