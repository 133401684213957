<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<gh-ext-page>
  <gh-ext-page-title title="{{'lbl_404_title' | translate}}"></gh-ext-page-title>
  <gh-ext-page-content>
    <div class="m-content" fxLayout="column" fxLayoutAlign="start center">
      <div class="m-404">4<span class="ext-primary-color">0</span>4</div>
      <div class="m-404-title">{{'lbl_404_sorry' | translate}}</div>
      <div class="m-404-text ext-placeholder-secondary-color">{{'lbl_404_text' | translate}}</div>
    </div>
  </gh-ext-page-content>
</gh-ext-page>