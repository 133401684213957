/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { LocalizationProvider } from '@gh/core-mls';
import { nullate } from '@gh/core-util';
import { assign } from 'lodash';
import { Type, TypeDefenition } from '../type.interfaces';
import { composeTypeValidatorsWithMessages, nullTypeValidator, TypeValidatorFn } from '../validators';

export abstract class AbstractType<T> implements Type<T> {
  abstract readonly defenition: TypeDefenition;
  abstract readonly validator: ValidatorFn;
  abstract readonly name: string;

  abstract parse(str: string): T;

  abstract format(value: T): string;

  abstract validateFormat(str: string): Maybe<ValidationErrors>;

  abstract validate(value: T): Maybe<ValidationErrors>;

  abstract init(name: string, localizationProvider: LocalizationProvider): void;
}

export abstract class AbstractPrimitiveType<T> extends AbstractType<T> {
  readonly validator: ValidatorFn;

  private _name: string;
  private typeValidator: TypeValidatorFn;
  private validators: Hash<TypeValidatorFn>;

  constructor(readonly defenition: TypeDefenition,
              defaultValidators: Hash<TypeValidatorFn>) {
    super();
    this.validators = assign({}, defaultValidators, defenition.validators || {});
    this.validator = (control) => nullate(this.validate(control.value));
  }

  get name(): string {
    return this._name;
  }

  abstract parse(str: string): T;

  abstract format(value: T): string;

  abstract validateFormat(str: string): Maybe<ValidationErrors>;

  validate(value: T): Maybe<ValidationErrors> {
    return this.typeValidator(this.defenition.constraints, value);
  }

  init(name: string, localizationProvider: LocalizationProvider): void {
    const { defenition } = this;

    this._name = name;

    if (defenition.constraints) {
      this.typeValidator = composeTypeValidatorsWithMessages(
        name,
        defenition.messages,
        defenition.constraints,
        this.validators);
    } else {
      this.typeValidator = nullTypeValidator;
    }
  }
}

export abstract class DecoratedType<T, B> extends AbstractType<T> {
  constructor(protected baseType: AbstractType<B>) {
    super();
  }

  get mask(): any {
    return this.baseType['mask'];
  }

  get defenition(): TypeDefenition {
    return this.baseType.defenition;
  }

  get name(): string {
    return this.baseType.name;
  }

  init(name: string, localizationProvider: LocalizationProvider): void {
    this.baseType.init(name, localizationProvider);
  }

  validateFormat(str: string): Maybe<ValidationErrors> {
    return this.baseType.validateFormat(str);
  }
}
