/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

const CONTEXT_PARAM_RE = /%([A-Za-z_][A-Za-z0-9_]+)%/g;

@Pipe({
  name: 'inContext',
})
export class InContextPipe implements PipeTransform {
  transform(value: string, context: Hash<any>): string {
    return value && value.replace(CONTEXT_PARAM_RE, (match: string, name: string) => get(context, name, match));
  }
}
