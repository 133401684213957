/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, Input } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../core-mls/translation/translate.pipe";
import * as i3 from "../../../core-ui/common/pipes/default-currency.pipe";
import * as i4 from "../../../core-type/pipes/type-format.pipe";
function CustomerJobInfoComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 6);
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "defaultCurrency");
    i0.ɵɵpipe(7, "typeFormat");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 2, "lbl_contract_price"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(6, 4, i0.ɵɵpipeBind2(7, 6, ctx_r0.job.contractPrice, "Price")));
} }
function CustomerJobInfoComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 6);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 2, "lbl_job_admin"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.job.jobAdminName);
} }
function CustomerJobInfoComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 6);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 2, "lbl_supervisor"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r2.job.supervisorName);
} }
function CustomerJobInfoComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 6);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 2, "lbl_salesperson"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r3.job.salesPersonName);
} }
var CustomerJobInfoComponent = /** @class */ (function () {
    function CustomerJobInfoComponent() {
    }
    CustomerJobInfoComponent.ɵfac = function CustomerJobInfoComponent_Factory(t) { return new (t || CustomerJobInfoComponent)(); };
    CustomerJobInfoComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CustomerJobInfoComponent, selectors: [["gh-customer-job-info"]], inputs: { job: "job" }, decls: 9, vars: 6, consts: [[1, "info-box"], [1, "info-box__name", "ext-placeholder-color"], [1, "info-box__details"], ["class", "info-box__field", 4, "ngIf"], [1, "info-box__field"], [1, "info-box__field-label", "ext-placeholder-color"], [1, "info-box__field-value"]], template: function CustomerJobInfoComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵelementStart(1, "div", 1);
            i0.ɵɵtext(2);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "div", 2);
            i0.ɵɵtext(4);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(5, CustomerJobInfoComponent_div_5_Template, 8, 9, "div", 3);
            i0.ɵɵtemplate(6, CustomerJobInfoComponent_div_6_Template, 6, 4, "div", 3);
            i0.ɵɵtemplate(7, CustomerJobInfoComponent_div_7_Template, 6, 4, "div", 3);
            i0.ɵɵtemplate(8, CustomerJobInfoComponent_div_8_Template, 6, 4, "div", 3);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate(ctx.job.addressee);
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate(ctx.job.siteAddress);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.job.contractPrice >= 0);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.job.jobAdminName);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.job.supervisorName);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.job.salesPersonName);
        } }, directives: [i1.NgIf], pipes: [i2.TranslatePipe, i3.DefaultCurrencyPipe, i4.TypeFormatPipe], styles: ["[_nghost-%COMP%] {\n  display: block; }"] });
    return CustomerJobInfoComponent;
}());
export { CustomerJobInfoComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerJobInfoComponent, [{
        type: Component,
        args: [{
                selector: 'gh-customer-job-info',
                templateUrl: 'customer-job-info.component.html',
                styleUrls: ['customer-job-info.component.scss'],
            }]
    }], null, { job: [{
            type: Input
        }] }); })();
