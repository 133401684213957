/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Permission = /** @class */ (function () {
    function Permission(name, _action) {
        this._action = _action;
        this._name = toString(name);
        this._path = this._name + "#" + this._action;
    }
    Permission.create = function (name, action) {
        var _a;
        if (!action) {
            var path = name;
            if (Array.isArray(path)) {
                name = path.slice(0, path.length - 1);
                action = path[path.length - 1];
            }
            else {
                _a = path.split('#'), name = _a[0], action = _a[1];
            }
        }
        return new Permission(name, action);
    };
    Object.defineProperty(Permission.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Permission.prototype, "action", {
        get: function () {
            return this._action;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Permission.prototype, "path", {
        get: function () {
            return this._path;
        },
        enumerable: true,
        configurable: true
    });
    Permission.prototype.every = function (predicate) {
        return predicate(this);
    };
    Permission.prototype.some = function (predicate) {
        return predicate(this);
    };
    // tslint:disable-next-line:new-parens
    Permission.TRUE = new /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            return _super.call(this, [], 'TRUE') || this;
        }
        class_1.prototype.some = function () {
            return true;
        };
        class_1.prototype.every = function () {
            return true;
        };
        return class_1;
    }(Permission));
    // tslint:disable-next-line:new-parens
    Permission.FALSE = new /** @class */ (function (_super) {
        __extends(class_2, _super);
        function class_2() {
            return _super.call(this, [], 'FALSE') || this;
        }
        class_2.prototype.some = function () {
            return false;
        };
        class_2.prototype.every = function () {
            return false;
        };
        return class_2;
    }(Permission));
    return Permission;
}());
export { Permission };
var PermissionSet = /** @class */ (function (_super) {
    __extends(PermissionSet, _super);
    function PermissionSet(name, action, permissions) {
        var _this = _super.call(this, name, action) || this;
        _this.permissions = permissions;
        return _this;
    }
    PermissionSet.prototype.every = function (predicate) {
        return this.permissions.every(predicate);
    };
    PermissionSet.prototype.some = function (predicate) {
        return this.permissions.some(predicate);
    };
    return PermissionSet;
}(Permission));
export { PermissionSet };
var LazyPermission = /** @class */ (function (_super) {
    __extends(LazyPermission, _super);
    function LazyPermission(action) {
        return _super.call(this, '', action) || this;
    }
    LazyPermission.createLazy = function (action) {
        return new LazyPermission(action);
    };
    LazyPermission.prototype.complete = function (path) {
        return new Permission(path, this.action);
    };
    return LazyPermission;
}(Permission));
export { LazyPermission };
var LazyPermissionSet = /** @class */ (function (_super) {
    __extends(LazyPermissionSet, _super);
    function LazyPermissionSet(action, permissions) {
        return _super.call(this, '', action, permissions) || this;
    }
    LazyPermissionSet.createLazy = function (action, permissions) {
        return new LazyPermissionSet(action, permissions);
    };
    LazyPermissionSet.prototype.complete = function (path) {
        return new PermissionSet(path, this.action, this.permissions);
    };
    return LazyPermissionSet;
}(PermissionSet));
export { LazyPermissionSet };
function toString(path) {
    if (typeof path === 'string') {
        return path;
    }
    else {
        return path.join('/');
    }
}
