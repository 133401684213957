/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { custom } from '@gh/core-data';

import { Message } from './message';

// tslint:disable-next-line:variable-name
export const MessageMapper = custom({
  serialize: (message: Message) => {
    throw new Error('Operation is not supported');
  },

  deserialize: (json: any) => Message.create(json.messageIdentifier, json.severity, json.parameters),
});
