/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ErrorHandler, Injectable, Provider } from '@angular/core';
import { ENVIRONMENT, REGION_CODE, RELEASE, SENTRY_DSN, SENTRY_ON } from '@gh/config';
import { first$ } from '@gh/rx/operators';
import * as Sentry from '@sentry/browser';
import { isEmpty, omitBy } from 'lodash';
import { UserContext } from './user';
import { UserData } from '@gh/common-shared-data';

if (SENTRY_ON) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: RELEASE,
  });
}

export function defaultErrorHandler(): Provider[] {
  return SENTRY_ON ? [{ provide: ErrorHandler, useClass: SentryErrorHandler }] : [];
}

@Injectable()
export class SentryErrorHandler extends ErrorHandler {

  constructor(private userContext: UserContext) {
    super();

    this.userContext.onData
      .pipe(first$(Boolean))
      .subscribe((user: UserData) => {
        Sentry.configureScope((scope) => {
          scope.setUser(omitBy(
            {
              id: user.id,
              username: user.fullName,
              email: user.login,
              locale: user.locale.toStandardString(),
            },
            isEmpty));
          if (user.backendBuildNumber) {
            scope.setTag('backendBuildNumber', user.backendBuildNumber);
          }
          scope.setTag('region', REGION_CODE);
        });

      });
  }

  handleError(error: any): void {
    Sentry.captureException(error.originalError || error);
    // without setTimeout Sentry hangs while sending error
    setTimeout(
      () => {
        throw error;
      },
      0);
  }
}
