/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, Input, OnDestroy } from '@angular/core';
import { WaitingContext, WaitingMode } from './waiting-context.service';
import { WaitingProcess } from './waiting-process';

type StringifiedWaitingMode = 'background' | 'blocked';

const toWaitingMode = (mode: StringifiedWaitingMode): WaitingMode => {
  switch (mode) {
    case 'background':
      return WaitingMode.Background;
    case 'blocked':
      return WaitingMode.Blocked;
    default:
      return WaitingMode.None;
  }
};

@Directive({
  selector: 'gh-wait', // tslint:disable-line:directive-selector
})
export class WaitDirective implements OnDestroy {
  @Input() mode: StringifiedWaitingMode;

  private _show: boolean = false;
  private process?: WaitingProcess;

  @Input()
  set show(show: boolean) {
    if (show !== this._show) {
      this.setShow(show);
    }
  }

  get show(): boolean {
    return this._show;
  }

  constructor(private context: WaitingContext) {
  }

  ngOnDestroy(): void {
    this.setShow(false);
  }

  private setShow(show: boolean): void {
    if (show) {
      this.process = this.context.start(toWaitingMode(this.mode), 'directive');
    } else if (this.process) {
      this.process.stop();
    }
    this._show = show;
  }
}
