/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { tap$ } from '@gh/rx/operators';
import { isNil } from 'lodash';
import { BehaviorSubject, MonoTypeOperatorFunction, Observable, Observer } from 'rxjs';
import { isNotNil } from './data-util';

/**
 * Constructs observable which emits new value each animated frame.
 * Note!!! Use with CAUTION. This approach leads to Angular change detection EACH animated frame.
 */
export function fromAnimationFrame$(): Observable<void> {
  return new Observable((observer: Observer<void>) => {
    let handle = requestAnimationFrame(frameHandler);

    return () => {
      cancelAnimationFrame(handle);
    };

    function frameHandler(): void {
      observer.next(void 0);
      handle = requestAnimationFrame(frameHandler);
    }
  });
}

/**
 * Constructs new observable which emits the latest value emitted by source observable at most each animated frame.
 * @param ob source observable
 */
export function onAnimationFrame$<T>(ob: Observable<T>): Observable<T> {
  return new Observable((observer: Observer<T>) => {
    let valueToEmit: T;

    let frame: Maybe<number>;
    const scheduleFrame = () => {
      if (isNil(frame)) {
        frame = requestAnimationFrame(onFrame);
      }
    };
    const cancelFrame = () => {
      if (isNotNil(frame)) {
        cancelAnimationFrame(frame);
      }
    };
    const onFrame = () => {
      frame = void 0;
      observer.next(valueToEmit);
    };

    const subscription = ob.subscribe(
      (value) => {
        valueToEmit = value;
        scheduleFrame();
      },
      (error) => {
        cancelFrame();
        observer.error(error);
      },
      () => {
        cancelFrame();
        observer.complete();
      });

    subscription.add(cancelFrame);

    return subscription;
  });
}

export function log$<T>(message: string): MonoTypeOperatorFunction<T> {
  return tap$((value: T) => console.log(message, value)); // tslint:disable-line:no-console
}

/**
 * The simplest wrapper around {@link BehaviorSubject}.
 * Unlike {@link BehaviorSubject} this class serves for only goal:
 * hold value displayed on UI via <code>async</code> pipe.
 * {@link PipeableValue} should be used ONLY when no other solution is possible.
 */
export class PipeableValue<T> extends BehaviorSubject<T> {
  constructor(value: T) {
    super(value);
  }

  set(value: T): void {
    this.next(value);
  }

  get(): T {
    return this.value;
  }
}

export function pipeableValue<T>(value: T): PipeableValue<T> {
  return new PipeableValue<T>(value);
}
