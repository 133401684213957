/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { ID } from '@gh/common-shared-data';
import { CustomerPhotoListItemDto } from '@gh/customer-shared-data';

import { map, truncate, uniqueId } from 'lodash';

const MAX_TITLE_LENGTH = 75;

export type CustomerPhotoListItem = CustomerPhotoListItemDto & {
  id: ID;
};
export const customerPhotoListItemFromDto = (item: CustomerPhotoListItemDto) => ({
  ...item,
  id: uniqueId('photo-list-item:'),
});
export const customerPhotoListFromDto = (customerPhotoList: CustomerPhotoListItemDto[]) =>
  map(customerPhotoList, customerPhotoListItemFromDto);

export const shortenedPhotoTitle = (title: string): string => truncate(title, {
  length: MAX_TITLE_LENGTH,
});
