/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { DEFAULT_COUNTRY_CODE } from '@gh/config';
import { textType } from '@gh/core-type';
import { isUserNameValid } from './text-utils';

const BaseTextType = textType({
  messages: {
    maxLength: 'msg_max_value',
    pattern: 'msg_field_invalid_format',
  },
});

const FileName = textType({
  constraints: {
    maxLength: 64,
    pattern: /^[^\u0000-\u001F\u007F"*/:<>?\\|]*$/,
  },
  messages: {
    pattern: 'msg_symbols_not_allowed',
  },
});

export const TextTypes = {
  Unlimited: textType(BaseTextType),
  Text24: textType(BaseTextType, {
    constraints: {
      maxLength: 24,
    },
  }),
  Text32: textType(BaseTextType, {
    constraints: {
      maxLength: 32,
    },
  }),
  Text40: textType(BaseTextType, {
    constraints: {
      maxLength: 40,
    },
  }),
  Text64: textType(BaseTextType, {
    constraints: {
      maxLength: 64,
    },
  }),
  Text128: textType(BaseTextType, {
    constraints: {
      maxLength: 128,
    },
  }),
  Text255: textType(BaseTextType, {
    constraints: {
      maxLength: 255,
    },
  }),
  Text: textType(BaseTextType, {}),
  GoogleUserName: textType(BaseTextType, {
    constraints: {
      userName: true,
      maxLength: 64,
    },
    validators: {
      userName: (_: any, name: string) => isUserNameValid(name) ? void 0 : { actualValue: name },
    },
    messages: {
      userName: 'msg_field_invalid_format',
    },
  }),
  Email: textType(BaseTextType, {
    constraints: {
      // tslint:disable-next-line:max-line-length
      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z-_]*[0-9]*[a-zA-Z-_]*\.)+)[a-zA-Z]{2,})$/,
    },
    messages: {
      pattern: 'msg_email_format_not_correct',
    },
  }),
  EmailList: textType(BaseTextType, {
    constraints: {
      // tslint:disable-next-line:max-line-length
      pattern: /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
    },
  }),
  TopCode: textType(BaseTextType, {
    constraints: {
      pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
    },
    messages: {
      pattern: 'msg_field_top_code',
    },
  }),
  Description: textType(BaseTextType, {
    constraints: {
      maxLength: 40,
    },
  }),
  Phone: textType(BaseTextType, {
    constraints: {
      pattern: new RegExp(`^\\+${DEFAULT_COUNTRY_CODE}[0-9 ]+$`),
    },
    messages: {
      pattern: {
        id: 'msg_field_invalid_phone_number',
        params: {
          countryCode: String(DEFAULT_COUNTRY_CODE),
        },
      },
    },
  }),
  NexmoVirtualNumber: textType(BaseTextType, {
    constraints: {
      pattern: /^[0-9]+$/,
    },
  }),
  OfficeName: FileName,
  ChangeOrderName: FileName,
};
