/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { nullate } from '@gh/core-util';
import { assign } from 'lodash';
import { composeTypeValidatorsWithMessages, nullTypeValidator } from '../validators';
var AbstractType = /** @class */ (function () {
    function AbstractType() {
    }
    return AbstractType;
}());
export { AbstractType };
var AbstractPrimitiveType = /** @class */ (function (_super) {
    __extends(AbstractPrimitiveType, _super);
    function AbstractPrimitiveType(defenition, defaultValidators) {
        var _this = _super.call(this) || this;
        _this.defenition = defenition;
        _this.validators = assign({}, defaultValidators, defenition.validators || {});
        _this.validator = function (control) { return nullate(_this.validate(control.value)); };
        return _this;
    }
    Object.defineProperty(AbstractPrimitiveType.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    AbstractPrimitiveType.prototype.validate = function (value) {
        return this.typeValidator(this.defenition.constraints, value);
    };
    AbstractPrimitiveType.prototype.init = function (name, localizationProvider) {
        var defenition = this.defenition;
        this._name = name;
        if (defenition.constraints) {
            this.typeValidator = composeTypeValidatorsWithMessages(name, defenition.messages, defenition.constraints, this.validators);
        }
        else {
            this.typeValidator = nullTypeValidator;
        }
    };
    return AbstractPrimitiveType;
}(AbstractType));
export { AbstractPrimitiveType };
var DecoratedType = /** @class */ (function (_super) {
    __extends(DecoratedType, _super);
    function DecoratedType(baseType) {
        var _this = _super.call(this) || this;
        _this.baseType = baseType;
        return _this;
    }
    Object.defineProperty(DecoratedType.prototype, "mask", {
        get: function () {
            return this.baseType['mask'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DecoratedType.prototype, "defenition", {
        get: function () {
            return this.baseType.defenition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DecoratedType.prototype, "name", {
        get: function () {
            return this.baseType.name;
        },
        enumerable: true,
        configurable: true
    });
    DecoratedType.prototype.init = function (name, localizationProvider) {
        this.baseType.init(name, localizationProvider);
    };
    DecoratedType.prototype.validateFormat = function (str) {
        return this.baseType.validateFormat(str);
    };
    return DecoratedType;
}(AbstractType));
export { DecoratedType };
