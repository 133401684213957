import { NgModule } from '@angular/core';
import { AUTH_HTTP_INTERCEPTOR, AuthProvider } from './auth-provider';
import { AuthService } from './auth.service';
import { DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR, DECORATED_AUTH_PROVIDER, DecoratingAuthHttpRequestInterceptor, DecoratingAuthProvider, } from './decoratable-auth-provider.service';
import * as i0 from "@angular/core";
export function decorateAuthProvider(decoratable, decorated) {
    return new DecoratingAuthProvider(decoratable, decorated);
}
export function decorateAuthHttpRequestInterceptor(authProvider, decoratable, decorated) {
    return new DecoratingAuthHttpRequestInterceptor(authProvider, decoratable, decorated);
}
var CoreAuthModule = /** @class */ (function () {
    function CoreAuthModule() {
    }
    CoreAuthModule.forRoot = function (options) {
        return {
            ngModule: CoreAuthModule,
            providers: [
                { provide: AuthProvider, useExisting: options.authProvider },
                { provide: AUTH_HTTP_INTERCEPTOR, useExisting: options.authHttpInterceptor },
                AuthService,
                options.permissionProvider ? [options.permissionProvider] : [],
            ],
        };
    };
    CoreAuthModule.forChild = function () {
        return {
            ngModule: CoreAuthModule,
            providers: [],
        };
    };
    CoreAuthModule.decorateBy = function (options) {
        return {
            ngModule: CoreAuthModule,
            providers: [{
                    provide: AuthProvider,
                    useFactory: decorateAuthProvider,
                    deps: [
                        options.authProvider,
                        DECORATED_AUTH_PROVIDER,
                    ],
                }, {
                    provide: AUTH_HTTP_INTERCEPTOR,
                    useFactory: decorateAuthHttpRequestInterceptor,
                    deps: [
                        options.authProvider,
                        options.authHttpRequestInterceptor,
                        DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR,
                    ],
                }],
        };
    };
    CoreAuthModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreAuthModule });
    CoreAuthModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreAuthModule_Factory(t) { return new (t || CoreAuthModule)(); } });
    return CoreAuthModule;
}());
export { CoreAuthModule };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreAuthModule, [{
        type: NgModule,
        args: [{
                declarations: [],
                exports: [],
            }]
    }], null, null); })();
