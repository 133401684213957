/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { isNil, isString } from 'lodash';
import { TypeService } from '../services/type.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/type.service";
var TypeFormatPipe = /** @class */ (function () {
    function TypeFormatPipe(typeService) {
        this.typeService = typeService;
    }
    TypeFormatPipe.prototype.transform = function (value, name) {
        if (isNil(name) || name === '') {
            throw new Error('Type is not provided into "formatType" pipe');
        }
        var type = isString(name) ? this.typeService.get(name) : name;
        if (isNil(type)) {
            throw new Error("Type was not found for name " + name);
        }
        return isNil(value) ? value : type.format(value);
    };
    TypeFormatPipe.ɵfac = function TypeFormatPipe_Factory(t) { return new (t || TypeFormatPipe)(i0.ɵɵdirectiveInject(i1.TypeService)); };
    TypeFormatPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "typeFormat", type: TypeFormatPipe, pure: true });
    return TypeFormatPipe;
}());
export { TypeFormatPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TypeFormatPipe, [{
        type: Pipe,
        args: [{
                name: 'typeFormat',
            }]
    }], function () { return [{ type: i1.TypeService }]; }, null); })();
