var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import * as invariant from 'invariant';
import { isBoolean, sortBy, startsWith, uniq } from 'lodash';
import { isPermissionComplete, NESTED_ACTION } from './auth.utils';
import { PermissionProvider } from './permission-provider';
import * as i0 from "@angular/core";
var PermissionNameSetProvider = /** @class */ (function (_super) {
    __extends(PermissionNameSetProvider, _super);
    function PermissionNameSetProvider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PermissionNameSetProvider.prototype.init = function (names) {
        // sorting guarantees that parent permission is included before children
        var sortedNames = uniq(sortBy(names));
        // building of quick-to-check-permission index
        var index = this.permissionIndex = {};
        sortedNames.filter(isPermissionComplete).forEach(function (permission) { return index[permission] = true; });
        getNestedPermissions(sortedNames).forEach(function (permission) { return index[permission + "#" + NESTED_ACTION] = true; });
    };
    PermissionNameSetProvider.prototype.hasPermission = function (permission) {
        var permissionIndex = this.permissionIndex;
        invariant(permissionIndex, 'Permission name set is not initialized');
        var cached = permissionIndex[permission.path];
        if (isBoolean(cached)) {
            return cached;
        }
        cached = permission.some(function (innerPermission) { return permissionIndex[innerPermission.path] === true; });
        permissionIndex[permission.path] = cached;
        return cached;
    };
    PermissionNameSetProvider.ɵfac = function PermissionNameSetProvider_Factory(t) { return ɵPermissionNameSetProvider_BaseFactory(t || PermissionNameSetProvider); };
    PermissionNameSetProvider.ɵprov = i0.ɵɵdefineInjectable({ token: PermissionNameSetProvider, factory: PermissionNameSetProvider.ɵfac });
    return PermissionNameSetProvider;
}(PermissionProvider));
export { PermissionNameSetProvider };
var ɵPermissionNameSetProvider_BaseFactory = i0.ɵɵgetInheritedFactory(PermissionNameSetProvider);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(PermissionNameSetProvider, [{
        type: Injectable
    }], null, null); })();
/**
 * This method creates set of nested permissions.
 * Set of nested permissions has nested permission for permission p in the set P iff P has any permission under p
 * (e. g. p#VIEW, p/x/y).
 *
 * Implementation of this method assumes that input set of permissions is sorted in ascending order.
 *
 * @param names sorted set of permissions
 */
function getNestedPermissions(names) {
    var nested = [];
    var parent;
    for (var _i = 0, names_1 = names; _i < names_1.length; _i++) {
        var name_1 = names_1[_i];
        if (parent && startsWith(name_1, parent)) {
            nested.push(parent);
        }
        // here, we can forget about the last parent,
        // because we either added it into the set of nested permission or parent does have nested permissions
        if (!isPermissionComplete(name_1)) {
            parent = name_1;
        }
    }
    return nested;
}
