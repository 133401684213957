/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input } from '@angular/core';
import * as i0 from "@angular/core";
/**
 * Binds given container state to the injector scope of the directive.
 * This is necessary to make container state available for child directives.
 */
var ContainerStateDirective = /** @class */ (function () {
    function ContainerStateDirective() {
    }
    Object.defineProperty(ContainerStateDirective.prototype, "containerState", {
        get: function () {
            return this._containerState;
        },
        set: function (formState) {
            this._containerState = formState;
        },
        enumerable: true,
        configurable: true
    });
    ContainerStateDirective.prototype.ngOnInit = function () {
        if (!this._containerState) {
            throw new Error('Instance of ContainerState is not assigned to the "ghContainerState" directive');
        }
    };
    ContainerStateDirective.ɵfac = function ContainerStateDirective_Factory(t) { return new (t || ContainerStateDirective)(); };
    ContainerStateDirective.ɵdir = i0.ɵɵdefineDirective({ type: ContainerStateDirective, selectors: [["", "ghContainerState", ""]], inputs: { containerState: ["ghContainerState", "containerState"] } });
    return ContainerStateDirective;
}());
export { ContainerStateDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContainerStateDirective, [{
        type: Directive,
        args: [{
                selector: '[ghContainerState]',
            }]
    }], null, { containerState: [{
            type: Input,
            args: ['ghContainerState']
        }] }); })();
