/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CustomerEDocumentRestService } from './e-documents';
import { CustomerFinancialRecordRestService } from './financial-record';
import { CustomerJobRestService } from './jobs';
import { CustomerPhotoRestService } from './photo';
import { CustomerUserProfileRestService } from './profiles';
import { CustomerSharedDocumentRestService } from './shared-documents';

export const DATA_SERVICES = [
  CustomerUserProfileRestService,
  CustomerPhotoRestService,
  CustomerJobRestService,
  CustomerEDocumentRestService,
  CustomerSharedDocumentRestService,
  CustomerFinancialRecordRestService,
];
