/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { Injectable } from '@angular/core';
import { AbstractRestService, entity, RestSettings } from '@gh/core-data';
// import { GridLayout } from '@gh/core-ui-grid';
import { cast } from '@gh/core-util';
import { omit } from 'lodash';
import { Observable } from 'rxjs';

type GridLayout = any;
export const GridLayoutMapper = entity<GridLayout>({});

@Injectable()
export class GridRestService extends AbstractRestService {
  constructor(private settings: RestSettings) {
    super(settings);
  }

  get(name: string): Observable<GridLayout> {
    return this.httpGet(`/grids/${name}`, GridLayoutMapper);
  }

  update(layout: GridLayout): Observable<GridLayout> {
    return this.httpPut(`/grids/${layout.name}`, cast<GridLayout>(omit(layout, ['sort'])), GridLayoutMapper);
  }
}
