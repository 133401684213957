/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, InjectionToken } from '@angular/core';
import { NotificationService } from '../../notifications/notification.service';

export const UI_ERROR_HANDLER = new InjectionToken<UiErrorHandler>('UiErrorHandler');

export abstract class UiErrorHandler {
  abstract raise(error: any): void;
}

@Injectable()
export class DefaultUiErrorHandlerService implements UiErrorHandler {
  constructor(private notificationService: NotificationService) {

  }

  raise(error: any): void {
    this.notificationService.processAll(error);
  }
}
