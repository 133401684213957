/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormMode, traverseControlTree } from '@gh/core-ui';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { toAppFormGroup } from './controls/app-control-util';
import { FormState } from './form-state';
import { FormStateSnapshot } from './form-state-snapshot';

export abstract class AbstractSnapshotFormComponent<T> implements OnInit, OnChanges, OnDestroy {
  @Input() mode: FormMode;
  @Input() enabled: boolean;
  @Input() state: FormStateSnapshot<T>;

  @Output() stateChange = new EventEmitter<FormStateSnapshot<T>>();

  form: FormState<T>;

  private valueSubscription: Subscription;
  private _enableEmitting = true;
  private _pendingEmitting = false;
  // whether state of the form group was mutated when component was in pending mode
  private postponed = false;

  ngOnInit(): void {
    this.form = this.buildForm(this.state);
    this.valueSubscription = this.form.valueChanges
      .subscribe(() => {
        if (this._enableEmitting) {
          if (!this._pendingEmitting) {
            this.emitState();
          } else {
            this.postponed = true;
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { enabled, state, mode } = changes;

    this.enableEmitting(false);
    if (this.form && mode) {
      this.form.updateSchema({ mode: mode.currentValue });
    }
    if (state && this.form && !isEqual(state.currentValue, state.previousValue)) {
      this.form.restore(state.currentValue);
      this.afterRestored(state.currentValue, state.previousValue);
    }

    if (enabled && this.form) {
      toAppFormGroup(this.form.group).updateSchema({ enabled: enabled.currentValue });
    }
    if (this.form) {
      this.afterChanges();
    }
    this.enableEmitting(true);
  }

  ngOnDestroy(): void {
    this.valueSubscription.unsubscribe();
  }

  /**
   * Enables/Disable emitting of new state
   * @param {boolean} enable
   */
  enableEmitting(enable: boolean): void {
    this._enableEmitting = enable;
  }

  /**
   * Allows to postpone emitting. It is necessary to avoid unnecessary external state mutation on form value change.
   * When form was mutated in pending mode it will send state change event as soon as pending flag will be reset.
   *
   * @param {boolean} pending
   */
  pendingEmitting(pending: boolean): void {
    this._pendingEmitting = pending;

    if (!pending) {
      this.emitState();
    }
  }

  validate(): void {
    this.enableEmitting(false);
    traverseControlTree(this.form.group, (control) => control.updateValueAndValidity());
    this.enableEmitting(true);
  }

  protected abstract buildForm(snapshot: FormStateSnapshot<T>): FormState<T>;

  /**
   * This method allows to update form after it was restored, like update form by schema.
   */
  protected afterRestored(current: FormStateSnapshot<T>, previous: FormStateSnapshot<T>): void {
    // nothing to do
  }

  /**
   * This method allows to define custom logic executed after form is restored
   */
  protected afterChanges(): void {
    // nothing to do
  }

  private emitState(): void {
    const snapshot = this.state = this.form.makeSnapshot();
    this.stateChange.emit(snapshot);
  }
}
