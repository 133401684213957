/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

/**
 * This directive adds ... (three dots) for the given text if it does not fit into its block.
 * Actually CSS has text-overflow property for this purpose, but unfortunately it does not work for multiline text.
 *
 * There is no reliable cross-browser CSS only way to support ellipsis in multiline text.
 *
 * This directive was inspired by this library: https://github.com/dibari/angular-ellipsis.
 *
 * Here provided only the minimalistic implementation that will work for blocks having fixed dimension.
 *
 * Usage:
 * <code>
 * <div ghEllipsis="some very long text"></div>
 * </code>
 */
@Directive({
  selector: '[ghEllipsis]',
})
export class EllipsisDirective {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {

  }

  @Input()
  set ghEllipsis(text: string) {
    this.setText(text);
  }

  /**
   * This method tries to fit given text into the target area.
   * If text does not fit into the target area, this method cuts it and adds ... (three dots) in the end.
   *
   * @param text
   */
  private setText(text: string): void {
    this.setTextContent(text);

    if (this.isOverflowed()) {
      // split text on words and try to remove word by word to fit the rest of text into the target area.
      const words = splitOnWords(text);

      while (words.length) {
        words.pop();

        this.setTextContent(words.join(' ') + '...');

        if (!this.isOverflowed()) {
          break;
        }
      }
    }
  }

  private isOverflowed(): boolean {
    const element: HTMLElement = this.elementRef.nativeElement;

    return element.scrollHeight > element.clientHeight;
  }

  private setTextContent(text: string): void {
    this.elementRef.nativeElement.textContent = text;
  }
}

function splitOnWords(text: string): string[] {
  return text.split(' ');
}
