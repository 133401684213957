/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CollapsibleContainerDirective } from './collapsible-container.directive';

@Directive({
  selector: '[ghCollapsibleInput]',
})
export class CollapsibleInputDirective implements OnInit, OnDestroy {
  value: any;

  private valueSubscription?: Subscription;

  constructor(private elementRef: ElementRef,
              private container: CollapsibleContainerDirective,
              private control: NgControl) {

  }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.container.input = this;

    const { valueChanges } = this.control;
    this.value = this.control.value;
    if (valueChanges) {
      this.valueSubscription = valueChanges.subscribe((value) => {
        this.value = value;
        this.container.checkSize(value);
      });
    }
  }

  ngOnDestroy(): void {
    const { valueSubscription } = this;

    if (valueSubscription) {
      valueSubscription.unsubscribe();
    }
  }
}
