/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive } from '@angular/core';
import { WaitingContext } from './waiting-context.service';

import { WaitingService } from './waiting.service';

/**
 * The only purpose of this directive is to add {@link WaitingContext} and {@link WaitingService}
 * into the current injector.
 *
 * {@link WaitingContext} provides low-level capabilities for waiting side-effects.
 * {@link WaitingService} is a service to be used by developers in any page component.
 */
@Directive({
  selector: '[ghWaitingScope]',
  providers: [WaitingContext, WaitingService],
})
export class WaitingScopeDirective {

}
