import { ComponentFactoryResolver, Directive, Host, Inject, Injector, Input, TemplateRef, ViewContainerRef, } from '@angular/core';
import { isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { EXT_INFINITE_SCROLL_VIEWPORT, сollectionViewerWithSideEffect, } from '../ext-infinite-scroll.interfaces';
import { toQueryResult } from '../infinite-mat-data-source';
import { ExtInfiniteListItemComponent } from './ext-infinite-list-item.component';
import { ExtInfiniteListComponent } from './ext-infinite-list.component';
import * as i0 from "@angular/core";
import * as i1 from "./ext-infinite-list.component";
var ExtInfiniteScrollForDirective = /** @class */ (function () {
    function ExtInfiniteScrollForDirective(injector, viewport, list, componentFactoryResolver, templateRef, viewContainerRef) {
        this.injector = injector;
        this.viewport = viewport;
        this.list = list;
        this.componentFactoryResolver = componentFactoryResolver;
        this.templateRef = templateRef;
        this.viewContainerRef = viewContainerRef;
        this.dataSource$$ = new BehaviorSubject(void 0);
        this.itemFactory = this.componentFactoryResolver.resolveComponentFactory(ExtInfiniteListItemComponent);
    }
    Object.defineProperty(ExtInfiniteScrollForDirective.prototype, "dataSource", {
        get: function () {
            return this.dataSource$$.value;
        },
        set: function (dataSource) {
            this.dataSource$$.next(dataSource);
        },
        enumerable: true,
        configurable: true
    });
    ExtInfiniteScrollForDirective.prototype.ngOnInit = function () {
        if (this.list.tracker) {
            this.list.tracker.reset();
        }
        this.dataSource$$
            .subscribe(this.switchDataSource.bind(this));
    };
    ExtInfiniteScrollForDirective.prototype.ngOnDestroy = function () {
        this.dataSource$$.complete();
        this.switchDataSource();
    };
    ExtInfiniteScrollForDirective.prototype.switchDataSource = function (dataSource) {
        var _this = this;
        if (this._currentDataSource === dataSource) {
            return;
        }
        if (this._currentDataSource) {
            this._currentDataSource.disconnect(this.viewport);
        }
        if (this.renderSubscription) {
            this.renderSubscription.unsubscribe();
        }
        if (dataSource) {
            this.renderSubscription = dataSource
                .connect(сollectionViewerWithSideEffect(this.viewport, function () { return _this.list.tracker && _this.list.tracker.start(); }))
                .subscribe(this.renderItems.bind(this));
            this._currentDataSource = dataSource;
        }
    };
    ExtInfiniteScrollForDirective.prototype.renderItems = function (items) {
        this.viewContainerRef.clear();
        var _a = toQueryResult(items).query, from = _a.from, to = _a.to;
        for (var i = 0; i < items.length; i++) {
            var itemComponent = this.viewContainerRef.createComponent(this.itemFactory);
            itemComponent.instance.template = this.templateRef;
            itemComponent.instance.data = items[i];
            itemComponent.instance.index = from + i;
        }
        this.viewport.updateRowCount(to - from, (items || []).length, from);
        this.list.updateHeight();
        this.list.tracker && this.list.tracker.stop(isEmpty(items));
    };
    ExtInfiniteScrollForDirective.ɵfac = function ExtInfiniteScrollForDirective_Factory(t) { return new (t || ExtInfiniteScrollForDirective)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(EXT_INFINITE_SCROLL_VIEWPORT), i0.ɵɵdirectiveInject(i1.ExtInfiniteListComponent, 1), i0.ɵɵdirectiveInject(i0.ComponentFactoryResolver), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef)); };
    ExtInfiniteScrollForDirective.ɵdir = i0.ɵɵdefineDirective({ type: ExtInfiniteScrollForDirective, selectors: [["", "ghExtInfiniteScrollFor", ""], ["", "ghExtInfiniteScrollForOf", ""]], inputs: { dataSource: ["ghExtInfiniteScrollForOf", "dataSource"] } });
    return ExtInfiniteScrollForDirective;
}());
export { ExtInfiniteScrollForDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtInfiniteScrollForDirective, [{
        type: Directive,
        args: [{
                selector: '[ghExtInfiniteScrollFor],[ghExtInfiniteScrollForOf]',
            }]
    }], function () { return [{ type: i0.Injector }, { type: undefined, decorators: [{
                type: Inject,
                args: [EXT_INFINITE_SCROLL_VIEWPORT]
            }] }, { type: i1.ExtInfiniteListComponent, decorators: [{
                type: Host
            }] }, { type: i0.ComponentFactoryResolver }, { type: i0.TemplateRef }, { type: i0.ViewContainerRef }]; }, { dataSource: [{
            type: Input,
            args: ['ghExtInfiniteScrollForOf']
        }] }); })();
