/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CdkTableModule } from '@angular/cdk/table';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const MATERIAL_EXTERNAL_MODULES = [
  MatIconModule,
  MatSelectModule,
  MatButtonModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatRippleModule,
  MatSortModule,
  MatTableModule,
  MatCardModule,
  MatSlideToggleModule,
  CdkTableModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatSnackBarModule,
];

@NgModule({
  imports: MATERIAL_EXTERNAL_MODULES,
  exports: MATERIAL_EXTERNAL_MODULES,
})
export class MaterialExternalModule {
  static forRoot(): ModuleWithProviders {
    return { ngModule: MaterialExternalModule };
  }

  static forChild(): ModuleWithProviders {
    return { ngModule: MaterialExternalModule };
  }
}
