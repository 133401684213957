/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { createDialogRoute } from '@gh/common-shared';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../ext-dialog/ext-dialog.component";
import * as i3 from "../ext-dialog/ext-dialog-header.component";
import * as i4 from "../ext-dialog/ext-dialog-content.component";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "../ext-eversign-document/ext-eversign-document.component";
import * as i7 from "../../../core-mls/translation/translate.pipe";
var ExtEversignDialogComponent = /** @class */ (function () {
    function ExtEversignDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
    }
    ExtEversignDialogComponent.prototype.onSigned = function () {
        this.dialogRef.close(true);
    };
    ExtEversignDialogComponent.prototype.onDeclined = function () {
        this.dialogRef.close(false);
    };
    ExtEversignDialogComponent.ɵfac = function ExtEversignDialogComponent_Factory(t) { return new (t || ExtEversignDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef)); };
    ExtEversignDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtEversignDialogComponent, selectors: [["gh-ext-eversign-dialog"]], decls: 6, vars: 5, consts: [[3, "closableOnDesktop", "title"], ["fxLayout", "column"], [1, "ext-divider"], ["fxFlex", "", 3, "url", "signed", "declined"]], template: function ExtEversignDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "gh-ext-dialog");
            i0.ɵɵelement(1, "gh-ext-dialog-header", 0);
            i0.ɵɵpipe(2, "translate");
            i0.ɵɵelementStart(3, "gh-ext-dialog-content", 1);
            i0.ɵɵelement(4, "div", 2);
            i0.ɵɵelementStart(5, "gh-ext-eversign-document", 3);
            i0.ɵɵlistener("signed", function ExtEversignDialogComponent_Template_gh_ext_eversign_document_signed_5_listener() { return ctx.onSigned(); })("declined", function ExtEversignDialogComponent_Template_gh_ext_eversign_document_declined_5_listener() { return ctx.onDeclined(); });
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(1);
            i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 3, "lbl_e_doc_signing"));
            i0.ɵɵproperty("closableOnDesktop", true);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("url", ctx.input.url);
        } }, directives: [i2.ExtDialogComponent, i3.ExtDialogHeaderComponent, i4.ExtDialogContentComponent, i5.DefaultLayoutDirective, i6.ExtEversignDocumentComponent, i5.DefaultFlexDirective], pipes: [i7.TranslatePipe], styles: ["[_nghost-%COMP%] {\n  overflow: hidden;\n  display: flex;\n  flex-direction: column; }\n  @media (max-width: 959px) {\n    [_nghost-%COMP%] {\n      width: 100vw;\n      min-height: 100vh; } }\n  @media (min-width: 960px) {\n    [_nghost-%COMP%] {\n      min-width: 90vw;\n      min-height: 90vh; } }\n\ngh-ext-dialog-content[_ngcontent-%COMP%] {\n  flex: 1; }"], changeDetection: 0 });
    return ExtEversignDialogComponent;
}());
export { ExtEversignDialogComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtEversignDialogComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-eversign-dialog',
                templateUrl: 'ext-eversign-dialog.component.html',
                styleUrls: ['ext-eversign-dialog.component.scss'],
                changeDetection: ChangeDetectionStrategy.OnPush,
            }]
    }], function () { return [{ type: i1.MatDialogRef }]; }, null); })();
export var eversignDialogRoute = createDialogRoute({
    component: ExtEversignDialogComponent,
    config: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
});
