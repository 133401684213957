var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input } from '@angular/core';
import { UploadContext } from './upload-context.service';
import { UploadMultiSlot } from './upload-multi-slot';
import { UploadController } from './upload.interfaces';
import * as i0 from "@angular/core";
import * as i1 from "./upload-context.service";
/**
 * Implementation of {@link UploadController} interface
 * which binds itself to the assigned {@link UploadMultiSlot} instance.
 */
var UploadMultiSlotDirective = /** @class */ (function (_super) {
    __extends(UploadMultiSlotDirective, _super);
    function UploadMultiSlotDirective(context) {
        var _this = _super.call(this) || this;
        _this.context = context;
        return _this;
    }
    Object.defineProperty(UploadMultiSlotDirective.prototype, "event$", {
        get: function () {
            return this.slot.event$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadMultiSlotDirective.prototype, "items", {
        get: function () {
            return this.slot.items;
        },
        enumerable: true,
        configurable: true
    });
    UploadMultiSlotDirective.prototype.create = function (file) {
        var item = this.context.create(file);
        this.slot.add(item);
        return item;
    };
    UploadMultiSlotDirective.prototype.start = function (item) {
        this.slot.start(item);
    };
    UploadMultiSlotDirective.prototype.cancel = function (item) {
        this.slot.cancel(item);
    };
    UploadMultiSlotDirective.ɵfac = function UploadMultiSlotDirective_Factory(t) { return new (t || UploadMultiSlotDirective)(i0.ɵɵdirectiveInject(i1.UploadContext)); };
    UploadMultiSlotDirective.ɵdir = i0.ɵɵdefineDirective({ type: UploadMultiSlotDirective, selectors: [["", "ghUploadMultiSlot", ""]], inputs: { slot: ["ghUploadMultiSlot", "slot"] }, features: [i0.ɵɵProvidersFeature([{ provide: UploadController, useExisting: UploadMultiSlotDirective }]), i0.ɵɵInheritDefinitionFeature] });
    return UploadMultiSlotDirective;
}(UploadController));
export { UploadMultiSlotDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UploadMultiSlotDirective, [{
        type: Directive,
        args: [{
                selector: '[ghUploadMultiSlot]',
                providers: [{ provide: UploadController, useExisting: UploadMultiSlotDirective }],
            }]
    }], function () { return [{ type: i1.UploadContext }]; }, { slot: [{
            type: Input,
            args: ['ghUploadMultiSlot']
        }] }); })();
