/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { getRouteParam, InitViewService } from '@gh/common-shared';

import { ID } from '@gh/common-shared-data';
import { CustomerEDocumentRestService } from '@gh/customer-shared-data';

import { Observable } from 'rxjs';

import { EDocumentListItem } from './customer-e-document.model';

@Injectable()
export class CustomerEDocumentBusinessService implements InitViewService {
  constructor(private restService: CustomerEDocumentRestService) {
  }

  initView(route: ActivatedRouteSnapshot): any {
    const jobId = getRouteParam(route, 'jobId');
    return {
      eDocumentList: this.findAll(jobId),
    };
  }

  findAll(jobId: ID): Observable<EDocumentListItem[]> {
    return this.restService.findAll(jobId);
  }
}
