/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NumberFormatQuery } from '@gh/core-mls';
import { isNotNil } from '@gh/core-util';
import { isString, noop } from 'lodash';
import { createTextMaskInputElement } from 'text-mask-core';
import { NumberType } from '../core/type.interfaces';
import { InputState, TypeService } from '../services';

const FOCUSED_NUMBER_FORMAT_QUERY: NumberFormatQuery = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 4,
};

function isMinusOnly(str: string): boolean {
  return str === '-';
}

/**
 * This module declares directive for number inputs. We had to define own implementation because it provides
 * better integration with number type approach (core-type package)
 *
 * Implementation was taken from this repository https://github.com/text-mask/text-mask.
 * File: https://github.com/text-mask/text-mask/blob/master/angular2/src/angular2TextMask.ts
 */
@Directive({
  selector: '[ghNumberType]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NumberTypeDirective,
    multi: true,
  }],
})
export class NumberTypeDirective implements OnInit, ControlValueAccessor {
  @Input('ghNumberTypeFormatOnFocus') formatOnFocus: boolean = true;

  private textMaskInputElement: any;

  // stores the last value for comparison
  private lastStrValue: string;
  private lastValue?: number;

  private _type: NumberType;

  private _onTouched = noop;
  private _onChange = noop;

  constructor(private renderer: Renderer2,
              private element: ElementRef,
              private typeService: TypeService,
              private inputState: InputState) {
  }

  @Input('ghNumberType')
  set type(type: NumberType | string) {
    this._type = isString(type) ? this.typeService.get(type) : type;
  }

  ngOnInit(): void {
    this.setupMask();
  }

  @HostListener('input', ['$event.target.value'])
  onInput(str: string): void {
    this.input(str);
  }

  @HostListener('focus')
  onFocus(): void {
    const element = this.element.nativeElement;
    if (this.formatOnFocus && isNotNil(this.lastValue)) {
      element.value = this._type.format(this.lastValue, FOCUSED_NUMBER_FORMAT_QUERY);

      // Default browser behavior is to select whole value on focus,
      // but value is not selected when it is updated by statement above.
      // So, we have to select value manually (excluding the case when user focuses element using mouse)
      if (!this.inputState.mouseDown) {
        element.select();
      }
    }
  }

  @HostListener('blur')
  onBlur(): void {
    if (isNotNil(this.lastValue)) {
      this.element.nativeElement.value = this._type.format(this.lastValue);
    } else if (isMinusOnly(this.lastStrValue)) {
      this.element.nativeElement.value = '';
    }
    this._onTouched();
  }

  writeValue(value: any): void {
    this.lastValue = value;
    if (isNotNil(value) && this.textMaskInputElement !== void 0) {
      this.textMaskInputElement.update(this._type.format(value));
    } else {
      this.element.nativeElement.value = '';
    }
    this.lastStrValue = this.element.nativeElement.value;
  }

  /**
   * This method allows to simulate typing into the field.
   *
   * Note!!! This is low-level method.
   * Be patient using this method, because you can break field content.
   * To guarantee correct behavior it is better to focus the field after it was updated using this method.
   *
   * @param str string content to set into the field
   * @param emitEvent whether to emit onChange event
   */
  input(str: string, { emitEvent }: { emitEvent: boolean } = { emitEvent: true }): void {
    this.textMaskInputElement.update(str);

    const nextStrValue = this.element.nativeElement.value;

    if (this.lastStrValue !== nextStrValue) {
      this.lastStrValue = nextStrValue;
      if (nextStrValue && !isMinusOnly(nextStrValue)) {
        const nextValue = this._type.parse(nextStrValue);
        if (this.lastValue !== nextValue) {
          this.lastValue = nextValue;
          if (emitEvent) {
            this._onChange(nextValue);
          }
        }
      } else {
        this.lastValue = void 0;
        if (emitEvent) {
          this._onChange(void 0);
        }
      }
    }
  }

  registerOnChange(fn: (value: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
  }

  private setupMask(): void {
    this.textMaskInputElement = createTextMaskInputElement({
      inputElement: this.element.nativeElement,
      mask: this._type['mask'],
    });
  }
}
