/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createEnum } from '@gh/core-data';
import { setField } from '@gh/core-util';
import { negate } from 'lodash';
export var DirtyType = createEnum('DIRTY_TYPE', {
    New: 'NEW',
    Removed: 'DELETED',
    Updated: 'UPDATED',
});
export var ID = String;
export var EMPTY_ID = '';
export var PrintEntryPoint = createEnum('QUOTATION_PRINT_EP', {
    Produce: 'PRODUCE',
    Preview: 'PREVIEW',
});
/**
 * Returns assigned entity state
 * @param entity
 */
export function getEntityState(entity) {
    return entity['entityState'] || {};
}
/**
 * Sets entity state
 * @param entity
 * @param entityState
 */
export function setEntityState(entity, entityState) {
    return setField(entity, 'entityState', entityState);
}
/**
 * Returns state of entity field
 * @param entity
 * @param field
 */
export function getEntityFieldState(entity, field) {
    return getEntityState(entity)[field] || { required: false, editable: true };
}
/**
 * Sets state of the given field
 * @param entity
 * @param field
 * @param fieldState
 */
export function setEntityFieldState(entity, field, fieldState) {
    var _a;
    var entityState = getEntityState(entity);
    return setEntityState(entity, __assign(__assign({}, entityState), (_a = {}, _a[field] = fieldState, _a)));
}
export var EMPTY_PRODUCE_STATUS = { processed: 0, total: 0 };
export var EMPTY_REPORT_STATUS = { processed: false };
export var isProduceStatusInProgress = function (_a) {
    var total = _a.total, processed = _a.processed;
    return total > 0 && processed >= 0 && total !== processed;
};
export var isReportStatusInProgress = function (_a) {
    var processed = _a.processed;
    return !processed;
};
export var isProduceStatusCompleted = negate(isProduceStatusInProgress);
