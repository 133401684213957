/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { MatTableDataSource } from '@angular/material/table';
import { isNil } from 'lodash';

export const isDataSourceEmpty = (dataSource: MatTableDataSource<any>) =>
  isNil(dataSource) || dataSource.filteredData.length === 0;
