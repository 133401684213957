/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { DictionaryRestService } from '@gh/common-shared-data';
import { REGION_CODE } from '@gh/config';
import { Enum } from '@gh/core-data';
import { TranslationService } from '@gh/core-mls';
import { of$, throwError$ } from '@gh/rx';
import { catchError$, map$, tap$ } from '@gh/rx/operators';
import { isNil } from 'lodash';
import { dictionaryItemFromDto, EnumDictionary, TextDictionary } from './dictionary';
import { DictionaryContext } from './dictionary-context.service';
import * as i0 from "@angular/core";
import * as i1 from "@gh/common-shared-data";
import * as i2 from "./dictionary-context.service";
import * as i3 from "@gh/core-mls";
var DictionaryService = /** @class */ (function () {
    function DictionaryService(dictionaryDataService, dictionaryContext, translationService) {
        this.dictionaryDataService = dictionaryDataService;
        this.dictionaryContext = dictionaryContext;
        this.translationService = translationService;
        this.enumCache = {};
    }
    DictionaryService.prototype.get = function (code) {
        var _this = this;
        return this.dictionaryContext.get(code).pipe(catchError$(function () { return _this.getRawData(code).pipe(map$(function (items) { return new TextDictionary(items); }), tap$(function (dictionary) { return _this.dictionaryContext.put(code, dictionary); })); }), catchError$(function () { return _this.dictionaryDataService
            .get(code).pipe(map$(function (items) { return new TextDictionary(items.map(dictionaryItemFromDto)); }), tap$(function (dictionary) { return _this.dictionaryContext.put(code, dictionary); })); }));
    };
    DictionaryService.prototype.getByEnum = function (enumeration) {
        var _this = this;
        var name = Enum.name(enumeration);
        var dictionary = this.enumCache[name];
        if (!isNil(dictionary)) {
            return of$(dictionary);
        }
        return this.getRawData(name).pipe(map$(function (values) { return new EnumDictionary(enumeration, values.map(function (item) { return ({
            id: Enum.byValue(enumeration, item.id),
            name: item.name,
        }); })); }), tap$(function (newDictionary) { return _this.enumCache[name] = newDictionary; }));
    };
    DictionaryService.prototype.getByDef = function (definition, query) {
        var dictionary$ = definition.get(this);
        return dictionary$.pipe(map$(function (dictionary) {
            return definition.filter ? definition.filter(dictionary, query) : dictionary;
        }));
    };
    DictionaryService.prototype.getRawData = function (name) {
        var _this = this;
        var values = this.findRawData(name);
        if (isNil(values)) {
            return throwError$("Translation for dictionary '" + name + "' are not defined");
        }
        return of$(values.map(function (value) { return ({
            id: value,
            name: _this.translationService.get("dictionary:" + name + ":" + value),
        }); }));
    };
    /**
     * Tries to find raw data using regional and non-regional selector in order.
     * @param {string} name
     * @returns {Maybe<string[]>}
     */
    DictionaryService.prototype.findRawData = function (name) {
        var regionalRawData = this.translationService.getRaw("dictionary:" + name + "{" + REGION_CODE + "}");
        if (regionalRawData) {
            return regionalRawData;
        }
        return this.translationService.getRaw("dictionary:" + name);
    };
    DictionaryService.ɵfac = function DictionaryService_Factory(t) { return new (t || DictionaryService)(i0.ɵɵinject(i1.DictionaryRestService), i0.ɵɵinject(i2.DictionaryContext), i0.ɵɵinject(i3.TranslationService)); };
    DictionaryService.ɵprov = i0.ɵɵdefineInjectable({ token: DictionaryService, factory: DictionaryService.ɵfac });
    return DictionaryService;
}());
export { DictionaryService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DictionaryService, [{
        type: Injectable
    }], function () { return [{ type: i1.DictionaryRestService }, { type: i2.DictionaryContext }, { type: i3.TranslationService }]; }, null); })();
