/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, HostListener } from '@angular/core';
import { AppRouter } from './app-router.service';
import * as i0 from "@angular/core";
import * as i1 from "./app-router.service";
var RouterBackDirective = /** @class */ (function () {
    function RouterBackDirective(router) {
        this.router = router;
    }
    RouterBackDirective.prototype.onClick = function () {
        this.router.navigateBack();
    };
    RouterBackDirective.ɵfac = function RouterBackDirective_Factory(t) { return new (t || RouterBackDirective)(i0.ɵɵdirectiveInject(i1.AppRouter)); };
    RouterBackDirective.ɵdir = i0.ɵɵdefineDirective({ type: RouterBackDirective, selectors: [["", "ghRouterBack", ""]], hostBindings: function RouterBackDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("click", function RouterBackDirective_click_HostBindingHandler() { return ctx.onClick(); });
        } } });
    return RouterBackDirective;
}());
export { RouterBackDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(RouterBackDirective, [{
        type: Directive,
        args: [{
                selector: '[ghRouterBack]',
            }]
    }], function () { return [{ type: i1.AppRouter }]; }, { onClick: [{
            type: HostListener,
            args: ['click']
        }] }); })();
