/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { EMPTY$, of$ } from '@gh/rx';
import { Observable } from 'rxjs';
import { Message, MessageSeverity } from './message';

export function isMessageBasket(value: any): value is MessageBasket {
  return value instanceof MessageBasket;
}

export type Errors = MessageBasket;

export class MessageBasket implements Iterable<Message> {
  static empty(): MessageBasket {
    return new MessageBasket([]);
  }

  static success(key: string, params?: {}): MessageBasket {
    return MessageBasket.just(key, MessageSeverity.Success, params);
  }

  static info(key: string, params?: {}): MessageBasket {
    return MessageBasket.just(key, MessageSeverity.Info, params);
  }

  static warning(key: string, params?: {}): MessageBasket {
    return MessageBasket.just(key, MessageSeverity.Warning, params);
  }

  static error(key: string, params?: {}): MessageBasket {
    return MessageBasket.just(key, MessageSeverity.Error, params);
  }

  static confirmation(key: string, params?: {}): MessageBasket {
    return MessageBasket.just(key, MessageSeverity.Confirmation, params);
  }

  static just(key: string, severity: MessageSeverity | string, params?: {}): MessageBasket {
    return new MessageBasket(Message.create(key, severity, params));
  }

  static create(messages: Message | Message[]): MessageBasket {
    return new MessageBasket(messages);
  }

  private _messages: Message[];

  constructor(messages: Message | Message[]) {
    this._messages = Array.isArray(messages) ? messages : [messages];
  }

  get messages(): Message[] {
    return this._messages;
  }

  has(key: string): boolean {
    return this._messages.some((message) => message.key === key);
  }

  get(key: string): Maybe<Message> {
    return this.find(key)[0];
  }

  at(i: number): Message {
    return this._messages[i];
  }

  find(key: string): Message[] {
    return this._messages.filter((message) => message.key === key);
  }

  count(): number {
    return this._messages.length;
  }

  map(transform: IdentityFn<Message>): MessageBasket {
    return MessageBasket.create(this.messages.map(transform));
  }

  get length(): number {
    return this.count();
  }

  getAsObservable(key: string): Observable<Message> {
    const message = this.get(key);

    return message ? of$(message) : EMPTY$;
  }

  findAsObservable(key: string): Observable<Message[]> {
    const messages = this.find(key);

    return messages.length > 0 ? of$(messages) : EMPTY$;
  }

  [Symbol.iterator](): Iterator<Message> {
    let pointer = 0;

    return {
      next: () =>
        pointer < this._messages.length
          ? {
            done: false,
            value: this._messages[pointer++],
          }
          : {
            value: <any>void 0,
            done: true,
          },
    };
  }
}
