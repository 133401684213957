/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { CommonExternalSharedModule } from '@gh/common-external-shared';
import { UserContext } from '@gh/common-shared';
import { CustomerUserContext, CustomerUserContextService, CustomerUserProfileStore } from './user';
import * as i0 from "@angular/core";
import * as i1 from "../common-external-shared/common-external-shared.module";
var CustomerSharedModule = /** @class */ (function () {
    function CustomerSharedModule() {
    }
    CustomerSharedModule.forRoot = function () {
        return {
            ngModule: CustomerSharedModule,
            providers: [
                { provide: CustomerUserContext, useClass: CustomerUserContextService },
                { provide: UserContext, useExisting: CustomerUserContext },
                CustomerUserProfileStore,
            ],
        };
    };
    CustomerSharedModule.forChild = function () {
        return {
            ngModule: CustomerSharedModule,
            providers: [],
        };
    };
    CustomerSharedModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomerSharedModule });
    CustomerSharedModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomerSharedModule_Factory(t) { return new (t || CustomerSharedModule)(); }, imports: [[
                CommonExternalSharedModule.forChild(),
            ],
            CommonExternalSharedModule] });
    return CustomerSharedModule;
}());
export { CustomerSharedModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomerSharedModule, { imports: [i1.CommonExternalSharedModule], exports: [CommonExternalSharedModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerSharedModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonExternalSharedModule.forChild(),
                ],
                declarations: [],
                exports: [
                    CommonExternalSharedModule,
                ],
            }]
    }], null, null); })();
