/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-implicit-dependencies no-submodule-imports no-import-side-effect

// It is important to load polyfills in the beginning
import '@gh/common-shared/polyfills';
import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { CustomerAppModule } from './customer-app.module';

if (process.env.ANGULAR_PROD_MODE) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(CustomerAppModule);
