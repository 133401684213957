<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<gh-ext-dialog layout="text" forBreakpoint="lt-lg">
  <gh-ext-dialog-header title="{{'lbl_job_details' | translate}}"></gh-ext-dialog-header>
  <gh-ext-dialog-content>
    <gh-customer-job-info class="m-job-info" [job]="job"></gh-customer-job-info>
  </gh-ext-dialog-content>
  <gh-ext-dialog-button-bar align="center">
    <a *ngIf="job.officePhone" fxHide.gt-sm="" href="tel:{{ job.officePhone }}" mat-raised-button="" color="primary">{{'lbl_call_to_office' | translate}}</a>
    <button type="button" mat-raised-button="" fxHide.lt-md="" color="primary" class="large" (click)="onClose()">{{'lbl_ok' | translate}}</button>
  </gh-ext-dialog-button-bar>
</gh-ext-dialog>