/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { TableLayoutBodyComponent, TableLayoutComponent, TableLayoutHeaderComponent } from './table-layout.component';
import * as i0 from "@angular/core";
var CoreUiTableLayoutModule = /** @class */ (function () {
    function CoreUiTableLayoutModule() {
    }
    CoreUiTableLayoutModule.forRoot = function () {
        return { ngModule: CoreUiTableLayoutModule };
    };
    CoreUiTableLayoutModule.forChild = function () {
        return { ngModule: CoreUiTableLayoutModule };
    };
    CoreUiTableLayoutModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreUiTableLayoutModule });
    CoreUiTableLayoutModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreUiTableLayoutModule_Factory(t) { return new (t || CoreUiTableLayoutModule)(); } });
    return CoreUiTableLayoutModule;
}());
export { CoreUiTableLayoutModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreUiTableLayoutModule, { declarations: [TableLayoutComponent,
        TableLayoutHeaderComponent,
        TableLayoutBodyComponent], exports: [TableLayoutComponent,
        TableLayoutHeaderComponent,
        TableLayoutBodyComponent] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreUiTableLayoutModule, [{
        type: NgModule,
        args: [{
                declarations: [
                    TableLayoutComponent,
                    TableLayoutHeaderComponent,
                    TableLayoutBodyComponent,
                ],
                exports: [
                    TableLayoutComponent,
                    TableLayoutHeaderComponent,
                    TableLayoutBodyComponent,
                ],
            }]
    }], null, null); })();
