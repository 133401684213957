import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { tap$ } from '@gh/rx/operators';
import { MessagePushService } from './message-push.service';
import { MessageMapper } from './message.mapper';
import * as i0 from "@angular/core";
import * as i1 from "./message-push.service";
export var MESSAGE_HEADER = new InjectionToken('MessageHeader');
var DEFAULT_MESSAGE_HEADER = 'X-AppMessage';
var MessageHttpResponseInterceptor = /** @class */ (function () {
    function MessageHttpResponseInterceptor(notificationService, messageHeader) {
        this.notificationService = notificationService;
        this.messageHeader = messageHeader;
        this.messageHeader = messageHeader || DEFAULT_MESSAGE_HEADER;
    }
    MessageHttpResponseInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(tap$(function (response) {
            var headers = response.headers;
            var messageHeader = headers ? headers.get(_this.messageHeader) : void 0;
            if (messageHeader) {
                JSON.parse(messageHeader.trim())
                    .map(function (message) { return MessageMapper.deserialize(message); })
                    .forEach(function (message) { return _this.notificationService.pushMessage(message); });
            }
        }));
    };
    MessageHttpResponseInterceptor.ɵfac = function MessageHttpResponseInterceptor_Factory(t) { return new (t || MessageHttpResponseInterceptor)(i0.ɵɵinject(i1.MessagePushService), i0.ɵɵinject(MESSAGE_HEADER, 8)); };
    MessageHttpResponseInterceptor.ɵprov = i0.ɵɵdefineInjectable({ token: MessageHttpResponseInterceptor, factory: MessageHttpResponseInterceptor.ɵfac });
    return MessageHttpResponseInterceptor;
}());
export { MessageHttpResponseInterceptor };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MessageHttpResponseInterceptor, [{
        type: Injectable
    }], function () { return [{ type: i1.MessagePushService }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [MESSAGE_HEADER]
            }] }]; }, null); })();
