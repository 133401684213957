/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isEmpty } from 'lodash';

@Component({
  selector: 'gh-clear',
  templateUrl: 'clear.component.html',
  styleUrls: ['clear.component.scss'],
})
export class ClearComponent {
  @Input() control?: AbstractControl;
  @Input() sideEffect = true;
  @Input() clearable = true;

  @Output() clear = new EventEmitter();

  get canClear(): boolean {
    return this.clearable && (!this.control || !isEmpty(this.control.value));
  }

  onClick(): void {
    if (this.control && this.sideEffect) {
      this.control.setValue(void 0);
      this.control.markAsDirty();
    }
    this.clear.emit();
  }
}
