/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable, Injector, NgZone } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ICONS_URL } from '@gh/common-shared';
import { DEFAULT_LOCALE } from '@gh/config';
import { AuthProvider, AuthService, AuthState } from '@gh/core-auth';
import { Message, MessageBasket, MessageSeverity } from '@gh/core-messages';
import { Locale, TranslationMapLoader } from '@gh/core-mls';
import { TypeService } from '@gh/core-type';
import { DialogService } from '@gh/core-ui';
import { CustomerUserContext } from '@gh/customer-shared';
import { getCustomerBootstrapParams } from '../customer-app.utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@gh/core-mls";
import * as i3 from "@gh/customer-shared";
import * as i4 from "@gh/core-auth";
import * as i5 from "@angular/material/icon";
var CustomerBootstrapService = /** @class */ (function () {
    function CustomerBootstrapService(injector, sanitizer, translationLoader, userContext, authService, authProvider, iconRegistry, ngZone) {
        var _this = this;
        this.injector = injector;
        this.sanitizer = sanitizer;
        this.translationLoader = translationLoader;
        this.userContext = userContext;
        this.authService = authService;
        this.authProvider = authProvider;
        this.iconRegistry = iconRegistry;
        this.ngZone = ngZone;
        iconRegistry.addSvgIconSetInNamespace('gh', sanitizer.bypassSecurityTrustResourceUrl(ICONS_URL));
        this.authService.onAuthState.subscribe(function (authState) {
            if (authState !== AuthState.LoggedIn) {
                _this.setPageStatus('is-app-wait-for-login');
            }
        });
        // set this service on global object to be available on the page (for index.html)
        window['bootstrap'] = this;
    }
    Object.defineProperty(CustomerBootstrapService.prototype, "dialogService", {
        get: function () {
            return this.injector.get(DialogService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomerBootstrapService.prototype, "router", {
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    CustomerBootstrapService.prototype.login = function () {
        var _this = this;
        // we use here NgZone, because this method can be called outside of angular zone
        return this.ngZone.run(function () { return _this.authProvider.login(); });
    };
    CustomerBootstrapService.prototype.load = function () {
        var _this = this;
        return this.userContext.init(getCustomerBootstrapParams()).toPromise()
            .then(function (userData) {
            return Promise.all([
                _this.loadTranslation(userData.locale),
            ]).then(function () { return userData; });
        })
            .then(function (userData) {
            // init types after localization subsystem is ready
            _this.injector.get(TypeService);
            _this.setPageStatus('is-app-loaded');
            return userData;
        }).catch(function (error) {
            if (error instanceof Error) {
                return Promise.reject(error);
            }
            return _this.loadTranslation(Locale.createFromStandard(DEFAULT_LOCALE)).then(function () {
                if (error instanceof MessageBasket) {
                    var message = error.at(0) || Message.error('msg_internalservererror');
                    _this.dialogService.message({
                        severity: MessageSeverity.Error,
                        messageId: message.key,
                        messageParams: message.params,
                        disableClose: true,
                    }).subscribe(function () { return _this.userContext.logout(); });
                }
                return Promise.resolve();
            });
        });
    };
    CustomerBootstrapService.prototype.loadTranslation = function (locale) {
        return this.translationLoader.load(locale);
    };
    CustomerBootstrapService.prototype.setPageStatus = function (className) {
        document.body.classList.remove('is-app-loading', 'is-app-wait-for-login', 'is-app-loaded');
        document.body.classList.add(className);
    };
    CustomerBootstrapService.ɵfac = function CustomerBootstrapService_Factory(t) { return new (t || CustomerBootstrapService)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.DomSanitizer), i0.ɵɵinject(i2.TranslationMapLoader), i0.ɵɵinject(i3.CustomerUserContext), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i4.AuthProvider), i0.ɵɵinject(i5.MatIconRegistry), i0.ɵɵinject(i0.NgZone)); };
    CustomerBootstrapService.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerBootstrapService, factory: CustomerBootstrapService.ɵfac });
    return CustomerBootstrapService;
}());
export { CustomerBootstrapService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerBootstrapService, [{
        type: Injectable
    }], function () { return [{ type: i0.Injector }, { type: i1.DomSanitizer }, { type: i2.TranslationMapLoader }, { type: i3.CustomerUserContext }, { type: i4.AuthService }, { type: i4.AuthProvider }, { type: i5.MatIconRegistry }, { type: i0.NgZone }]; }, null); })();
