/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { BreakPoint, FlexLayoutModule } from '@angular/flex-layout';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { CoreUiCommonModule, CoreUiDialogsModule, CoreUiWaitingModule, NotificationService } from '@gh/core-ui';
import { CommonSharedModule } from '@gh/common-shared';
import {
  ExtConfirmationDialogComponent,
  ExtDialogButtonBarComponent,
  ExtDialogComponent,
  ExtDialogContentComponent,
  ExtDialogHeaderComponent,
  ExtEversignDialogComponent,
  ExtEversignDocumentComponent,
  ExtLoadTrackerComponent,
  ExtMessageDialogComponent,
  ExtNavigationComponent,
  ExtNavigationItemComponent,
  ExtNoDataComponent,
  ExtNotFoundPageComponent,
  ExtPageComponent,
  ExtPageContentComponent,
  ExtPageTitleDirective,
  ExtPreloaderComponent,
  ExtRootLayoutComponent,
  ExtUploaderComponent,
} from './components';
import { ExtIfQueryDirective } from './directives';
import {
  ExtInfiniteListComponent,
  ExtInfiniteListItemComponent,
  ExtInfiniteRowDirective,
  ExtInfiniteScrollForDirective,
  ExtInfiniteScrollViewportDirective,
  ExtInfiniteTableDirective,
  ExtInfiniteTableViewerDirective,
} from './infinite-scroll';
import { MaterialExternalModule } from './material-external.module';
import { ExtNotificationService } from './services/ext-notification.service';
import { ExtUploaderService } from './services/ext-uploader.service';
import { ConnectPipe } from './table';

/**
 * Technique described by this link does not work in AoT mode for our configuration
 * https://github.com/benbraou/flex-layout-wiki/blob/master/Custom-Breakpoints.md.
 *
 * So we duplicate array of breakpoints to customize them.
 */
export const DEFAULT_BREAKPOINTS: BreakPoint[] = [
  {
    alias: 'xs',
    mediaQuery: '(min-width: 0px) and (max-width: 599px)',
  },
  {
    alias: 'gt-xs',
    overlapping: true,
    mediaQuery: '(min-width: 600px)',
  },
  {
    alias: 'lt-sm',
    overlapping: true,
    mediaQuery: '(max-width: 599px)',
  },
  {
    alias: 'sm',
    mediaQuery: '(min-width: 600px) and (max-width: 959px)',
  },
  {
    alias: 'gt-sm',
    overlapping: true,
    mediaQuery: '(min-width: 960px)',
  },
  {
    alias: 'lt-md',
    overlapping: true,
    mediaQuery: '(max-width: 959px)',
  },
  {
    alias: 'md',
    mediaQuery: '(min-width: 960px) and (max-width: 1279px)',
  },
  {
    alias: 'gt-md',
    overlapping: true,
    mediaQuery: '(min-width: 1280px)',
  },
  {
    alias: 'lt-lg',
    overlapping: true,
    mediaQuery: '(max-width: 1279px)',
  },
  {
    alias: 'lg',
    mediaQuery: '(min-width: 1280px) and (max-width: 1599px)',
  },
  {
    alias: 'gt-lg',
    overlapping: true,
    mediaQuery: '(min-width: 1600px)',
  },
  {
    alias: 'lt-xl',
    overlapping: true,
    mediaQuery: '(max-width: 1599px)',
  },
  {
    alias: 'xl',
    mediaQuery: '(min-width: 1600px) and (max-width: 5000px)',
  },
];

@NgModule({
  imports: [
    FlexLayoutModule.withConfig(
      { disableDefaultBps: true },
      DEFAULT_BREAKPOINTS),
    CommonSharedModule.forChild(),
    CoreUiCommonModule.forChild(),
    CoreUiDialogsModule.forChild(),
    CoreUiWaitingModule.forChild(),
    MaterialExternalModule,
  ],
  providers: [
    ExtUploaderService,
  ],
  declarations: [
    ExtIfQueryDirective,
    ExtNavigationComponent,
    ExtNavigationItemComponent,
    ExtRootLayoutComponent,
    ExtPageComponent,
    ExtPageTitleDirective,
    ExtPageContentComponent,
    ExtNotFoundPageComponent,
    ExtNoDataComponent,
    ExtEversignDocumentComponent,
    ExtEversignDialogComponent,
    ExtPreloaderComponent,
    ExtDialogComponent,
    ExtDialogHeaderComponent,
    ExtDialogButtonBarComponent,
    ExtDialogContentComponent,
    ExtInfiniteTableDirective,
    ExtInfiniteScrollViewportDirective,
    ExtInfiniteScrollForDirective,
    ExtInfiniteListComponent,
    ExtInfiniteListItemComponent,
    ExtInfiniteTableViewerDirective,
    ExtInfiniteRowDirective,
    ExtUploaderComponent,
    ExtLoadTrackerComponent,

    ExtConfirmationDialogComponent,
    ExtMessageDialogComponent,

    ConnectPipe,
  ],
  entryComponents: [
    ExtInfiniteListItemComponent,
    ExtEversignDialogComponent,

    ExtConfirmationDialogComponent,
    ExtMessageDialogComponent,
  ],
  exports: [
    ExtIfQueryDirective,
    ExtNavigationComponent,
    ExtNavigationItemComponent,
    ExtRootLayoutComponent,
    ExtPageComponent,
    ExtPageTitleDirective,
    ExtPageContentComponent,
    ExtNotFoundPageComponent,
    ExtNoDataComponent,
    ExtEversignDocumentComponent,
    ExtPreloaderComponent,

    ExtDialogComponent,
    ExtDialogHeaderComponent,
    ExtDialogButtonBarComponent,
    ExtDialogContentComponent,
    ExtInfiniteTableDirective,
    ExtInfiniteScrollViewportDirective,
    ExtInfiniteTableViewerDirective,
    ExtInfiniteRowDirective,
    ExtInfiniteListComponent,
    ExtInfiniteListItemComponent,
    ExtInfiniteScrollForDirective,
    ExtUploaderComponent,
    ExtLoadTrackerComponent,

    FlexLayoutModule,
    CommonSharedModule,
    CoreUiCommonModule,
    CoreUiDialogsModule,
    MaterialExternalModule,

    ConnectPipe,
  ],
})
export class CommonExternalSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CommonExternalSharedModule,
      providers: [
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: { maxWidth: '100%', maxHeight: '100%', hasBackdrop: true, autoFocus: true },
        },
        { provide: NotificationService, useClass: ExtNotificationService },
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return { ngModule: CommonExternalSharedModule };
  }
}
