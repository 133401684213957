var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { TypeService } from '@gh/core-type';
import { isNotNil } from '@gh/core-util';
import { isString } from 'lodash';
import { createTextMaskInputElement } from 'text-mask-core';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-type";
var AutocompleteMaskedTypeDirective = /** @class */ (function () {
    function AutocompleteMaskedTypeDirective(renderer, element, typeService) {
        this.renderer = renderer;
        this.element = element;
        this.typeService = typeService;
        this.config = {
            guide: false,
            placeholderChar: '_',
            pipe: void 0,
            keepCharPositions: false,
            onReject: void 0,
            onAccept: void 0,
        };
    }
    Object.defineProperty(AutocompleteMaskedTypeDirective.prototype, "type", {
        set: function (type) {
            this._type = isString(type) ? this.typeService.get(type) : type;
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteMaskedTypeDirective.prototype.ngOnInit = function () {
        this.setupMask();
        if (this._type['maxLength']) {
            this.renderer.setAttribute(this.element.nativeElement, 'maxlength', String(this._type['maxLength']));
        }
    };
    AutocompleteMaskedTypeDirective.prototype.ngAfterViewInit = function () {
        if (!this.inputElement) {
            // the element was not found when ngOnInit ran, let's try to find it again
            this.setupMask();
        }
    };
    AutocompleteMaskedTypeDirective.prototype.update = function (value) {
        if (isNotNil(value) && isNotNil(this.textMaskInputElement)) {
            this.textMaskInputElement.update(value);
            var nextValue = this.element.nativeElement.value;
            // check against the last value to prevent firing ngModelChange despite no changes
            if (this.lastValue !== nextValue) {
                this.lastValue = nextValue;
            }
            return nextValue;
        }
        else {
            this.element.nativeElement.value = '';
            return '';
        }
    };
    AutocompleteMaskedTypeDirective.prototype.setupMask = function () {
        // tslint:disable-next-line:prefer-conditional-expression
        if (this.element.nativeElement.tagName === 'INPUT') {
            // `textMask` directive is used directly on an input element
            this.inputElement = this.element.nativeElement;
        }
        else {
            // `textMask` directive is used on an abstracted input element, `ion-input`, `mat-input`, etc
            this.inputElement = this.element.nativeElement.getElementsByTagName('INPUT')[0];
        }
        if (this.inputElement) {
            this.textMaskInputElement = createTextMaskInputElement(__assign(__assign({}, this.config), { inputElement: this.inputElement, mask: this._type.mask }));
        }
    };
    AutocompleteMaskedTypeDirective.ɵfac = function AutocompleteMaskedTypeDirective_Factory(t) { return new (t || AutocompleteMaskedTypeDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.TypeService)); };
    AutocompleteMaskedTypeDirective.ɵdir = i0.ɵɵdefineDirective({ type: AutocompleteMaskedTypeDirective, selectors: [["", "ghAutocompleteMaskedType", ""]], inputs: { config: ["ghAutocompletedMaskedTypeConfig", "config"], type: ["ghAutocompleteMaskedType", "type"] } });
    return AutocompleteMaskedTypeDirective;
}());
export { AutocompleteMaskedTypeDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AutocompleteMaskedTypeDirective, [{
        type: Directive,
        args: [{
                selector: '[ghAutocompleteMaskedType]',
            }]
    }], function () { return [{ type: i0.Renderer2 }, { type: i0.ElementRef }, { type: i1.TypeService }]; }, { config: [{
            type: Input,
            args: ['ghAutocompletedMaskedTypeConfig']
        }], type: [{
            type: Input,
            args: ['ghAutocompleteMaskedType']
        }] }); })();
