/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { InfiniteMatDataSource } from '@gh/common-external-shared';
import { getRouteInitData } from '@gh/common-shared';
import { openInNewWindow } from '@gh/core-util';
import { CustomerSharedDocumentBusinessService } from '../../resources/customer-shared-document-business.service';
import * as i0 from "@angular/core";
import * as i1 from "../../resources/customer-shared-document-business.service";
import * as i2 from "@angular/router";
import * as i3 from "../../../common-external-shared/components/ext-page/ext-page.component";
import * as i4 from "@angular/material/sort";
import * as i5 from "../../../common-external-shared/directives/ext-if-query.directive";
import * as i6 from "../../../common-external-shared/components/ext-load-tracker/ext-load-tracker.component";
import * as i7 from "@angular/flex-layout/flex";
import * as i8 from "@angular/material/table";
import * as i9 from "../../../common-external-shared/infinite-scroll/table/ext-infinite-table.directive";
import * as i10 from "../../../common-external-shared/infinite-scroll/table/ext-infinite-table-viewer.directive";
import * as i11 from "@angular/material/button";
import * as i12 from "../../../common-external-shared/infinite-scroll/table/ext-infinite-row.directive";
import * as i13 from "../../../common-external-shared/infinite-scroll/list/ext-infinite-list.component";
import * as i14 from "../../../common-external-shared/infinite-scroll/list/ext-infinite-scroll-for.directive";
import * as i15 from "@angular/material/card";
import * as i16 from "../../../core-mls/translation/translate.pipe";
function CustomerSharedDocumentListComponent_mat_table_5_mat_header_cell_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-header-cell", 15);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "lbl_file_name"));
} }
function CustomerSharedDocumentListComponent_mat_table_5_mat_cell_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-cell", 16);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var sharedDocument_r9 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(sharedDocument_r9.fileName);
} }
function CustomerSharedDocumentListComponent_mat_table_5_mat_header_cell_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-header-cell", 17);
} }
function CustomerSharedDocumentListComponent_mat_table_5_mat_cell_6_Template(rf, ctx) { if (rf & 1) {
    var _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-cell", 17);
    i0.ɵɵelementStart(1, "button", 18);
    i0.ɵɵlistener("click", function CustomerSharedDocumentListComponent_mat_table_5_mat_cell_6_Template_button_click_1_listener() { i0.ɵɵrestoreView(_r12); var sharedDocument_r10 = ctx.$implicit; var ctx_r11 = i0.ɵɵnextContext(2); return ctx_r11.viewSharedDocument(sharedDocument_r10); });
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 1, "lbl_view"));
} }
function CustomerSharedDocumentListComponent_mat_table_5_mat_header_row_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-header-row");
} }
function CustomerSharedDocumentListComponent_mat_table_5_mat_row_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-row", 19);
} if (rf & 2) {
    var sharedDocument_r13 = ctx.$implicit;
    i0.ɵɵproperty("ghExtInfiniteRow", sharedDocument_r13);
} }
function CustomerSharedDocumentListComponent_mat_table_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-table", 6);
    i0.ɵɵelementContainerStart(1, 7);
    i0.ɵɵtemplate(2, CustomerSharedDocumentListComponent_mat_table_5_mat_header_cell_2_Template, 3, 3, "mat-header-cell", 8);
    i0.ɵɵtemplate(3, CustomerSharedDocumentListComponent_mat_table_5_mat_cell_3_Template, 2, 1, "mat-cell", 9);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementContainerStart(4, 10);
    i0.ɵɵtemplate(5, CustomerSharedDocumentListComponent_mat_table_5_mat_header_cell_5_Template, 1, 0, "mat-header-cell", 11);
    i0.ɵɵtemplate(6, CustomerSharedDocumentListComponent_mat_table_5_mat_cell_6_Template, 4, 3, "mat-cell", 12);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵtemplate(7, CustomerSharedDocumentListComponent_mat_table_5_mat_header_row_7_Template, 1, 0, "mat-header-row", 13);
    i0.ɵɵtemplate(8, CustomerSharedDocumentListComponent_mat_table_5_mat_row_8_Template, 1, 1, "mat-row", 14);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r0 = i0.ɵɵnextContext();
    var _r2 = i0.ɵɵreference(8);
    i0.ɵɵproperty("dataSource", ctx_r0.sharedDocumentDataSource)("tracker", _r2)("trackBy", ctx_r0.getSharedDocumentId);
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("matHeaderRowDef", ctx_r0.displayedColumns)("matHeaderRowDefSticky", true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("matRowDefColumns", ctx_r0.displayedColumns);
} }
function CustomerSharedDocumentListComponent_div_6_ng_template_2_Template(rf, ctx) { if (rf & 1) {
    var _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-card", 23);
    i0.ɵɵelementStart(1, "mat-card-content");
    i0.ɵɵelementStart(2, "div", 24);
    i0.ɵɵelementStart(3, "div", 25);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 26);
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "mat-card-actions");
    i0.ɵɵelementStart(9, "button", 27);
    i0.ɵɵlistener("click", function CustomerSharedDocumentListComponent_div_6_ng_template_2_Template_button_click_9_listener() { i0.ɵɵrestoreView(_r17); var sharedDocument_r15 = ctx.$implicit; var ctx_r16 = i0.ɵɵnextContext(2); return ctx_r16.viewSharedDocument(sharedDocument_r15); });
    i0.ɵɵtext(10);
    i0.ɵɵpipe(11, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var sharedDocument_r15 = ctx.$implicit;
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(5, 3, "lbl_file_name"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(sharedDocument_r15.fileName);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(11, 5, "lbl_view"));
} }
function CustomerSharedDocumentListComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 20);
    i0.ɵɵelementStart(1, "gh-ext-infinite-list", 21);
    i0.ɵɵtemplate(2, CustomerSharedDocumentListComponent_div_6_ng_template_2_Template, 12, 7, "ng-template", 22);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r1 = i0.ɵɵnextContext();
    var _r2 = i0.ɵɵreference(8);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("itemHeight", 145)("tracker", _r2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ghExtInfiniteScrollForOf", ctx_r1.sharedDocumentDataSource);
} }
var CustomerSharedDocumentListComponent = /** @class */ (function () {
    function CustomerSharedDocumentListComponent(businessService, route) {
        this.businessService = businessService;
        this.route = route;
        this.displayedColumns = ['fileName', 'action'];
    }
    CustomerSharedDocumentListComponent.prototype.ngOnInit = function () {
        var _this = this;
        var sharedDocumentDataSource = getRouteInitData(this.route).sharedDocumentDataSource;
        this.sharedDocumentDataSource = InfiniteMatDataSource.wrap(sharedDocumentDataSource);
        this.route.paramMap
            .subscribe(function (_a) {
            var params = _a.params;
            _this.jobId = params.jobId;
            _this.loadSharedDocuments(_this.jobId);
        });
    };
    CustomerSharedDocumentListComponent.prototype.ngAfterViewInit = function () {
        this.sharedDocumentDataSource.sort = this.sort;
    };
    CustomerSharedDocumentListComponent.prototype.viewSharedDocument = function (_a) {
        var fileUrl = _a.fileUrl;
        if (fileUrl) {
            openInNewWindow(fileUrl);
        }
    };
    CustomerSharedDocumentListComponent.prototype.getSharedDocumentId = function (index, sharedDocument) {
        return sharedDocument.id;
    };
    CustomerSharedDocumentListComponent.prototype.loadSharedDocuments = function (jobId) {
        this.sharedDocumentDataSource = InfiniteMatDataSource.wrap(this.businessService.findAll(jobId));
        this.sharedDocumentDataSource.sort = this.sort;
    };
    CustomerSharedDocumentListComponent.ɵfac = function CustomerSharedDocumentListComponent_Factory(t) { return new (t || CustomerSharedDocumentListComponent)(i0.ɵɵdirectiveInject(i1.CustomerSharedDocumentBusinessService), i0.ɵɵdirectiveInject(i2.ActivatedRoute)); };
    CustomerSharedDocumentListComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CustomerSharedDocumentListComponent, selectors: [["gh-customer-shared-document-list"]], viewQuery: function CustomerSharedDocumentListComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(MatSort, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sort = _t.first);
        } }, decls: 9, vars: 5, consts: [[3, "title"], ["matSort", ""], ["ghExtInfiniteTable", "", 3, "dataSource", "tracker", "trackBy", 4, "ghExtIfQuery"], ["fxLayout", "column", 4, "ghExtIfQuery"], ["fxFlex", ""], ["tracker", "ghExtLoadTracker"], ["ghExtInfiniteTable", "", 3, "dataSource", "tracker", "trackBy"], ["matColumnDef", "fileName"], ["fxFlex", "85%", "mat-sort-header", "", 4, "matHeaderCellDef"], ["fxFlex", "85%", 4, "matCellDef"], ["matColumnDef", "action"], ["fxFlex", "15%", 4, "matHeaderCellDef"], ["fxFlex", "15%", 4, "matCellDef"], [4, "matHeaderRowDef", "matHeaderRowDefSticky"], [3, "ghExtInfiniteRow", 4, "matRowDef", "matRowDefColumns"], ["fxFlex", "85%", "mat-sort-header", ""], ["fxFlex", "85%"], ["fxFlex", "15%"], ["mat-stroked-button", "", "type", "button", "color", "primary", 1, "small", "cell-button", 3, "click"], [3, "ghExtInfiniteRow"], ["fxLayout", "column"], [3, "itemHeight", "tracker"], ["ghExtInfiniteScrollFor", "", 3, "ghExtInfiniteScrollForOf"], [1, "card-list-item"], [1, "card-content-item", "full-width"], [1, "card-content-item-title"], [1, "card-content-item-value"], ["mat-stroked-button", "", "type", "button", 1, "small", 3, "click"]], template: function CustomerSharedDocumentListComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "gh-ext-page");
            i0.ɵɵelement(1, "gh-ext-page-title", 0);
            i0.ɵɵpipe(2, "translate");
            i0.ɵɵelementStart(3, "gh-ext-page-content");
            i0.ɵɵelementContainerStart(4, 1);
            i0.ɵɵtemplate(5, CustomerSharedDocumentListComponent_mat_table_5_Template, 9, 6, "mat-table", 2);
            i0.ɵɵtemplate(6, CustomerSharedDocumentListComponent_div_6_Template, 3, 3, "div", 3);
            i0.ɵɵelement(7, "gh-ext-load-tracker", 4, 5);
            i0.ɵɵelementContainerEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(1);
            i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 3, "lbl_shared_docs"));
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ghExtIfQuery", "gt-sm");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ghExtIfQuery", "lt-md");
        } }, directives: [i3.ExtPageComponent, i3.ExtPageTitleDirective, i3.ExtPageContentComponent, i4.MatSort, i5.ExtIfQueryDirective, i6.ExtLoadTrackerComponent, i7.DefaultFlexDirective, i8.MatTable, i9.ExtInfiniteTableDirective, i10.ExtInfiniteTableViewerDirective, i8.MatColumnDef, i8.MatHeaderCellDef, i8.MatCellDef, i8.MatHeaderRowDef, i8.MatRowDef, i8.MatHeaderCell, i4.MatSortHeader, i8.MatCell, i11.MatButton, i8.MatHeaderRow, i8.MatRow, i12.ExtInfiniteRowDirective, i7.DefaultLayoutDirective, i13.ExtInfiniteListComponent, i14.ExtInfiniteScrollForDirective, i15.MatCard, i15.MatCardContent, i15.MatCardActions], pipes: [i16.TranslatePipe], encapsulation: 2 });
    return CustomerSharedDocumentListComponent;
}());
export { CustomerSharedDocumentListComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerSharedDocumentListComponent, [{
        type: Component,
        args: [{
                selector: 'gh-customer-shared-document-list',
                templateUrl: 'customer-shared-document-list.component.html',
            }]
    }], function () { return [{ type: i1.CustomerSharedDocumentBusinessService }, { type: i2.ActivatedRoute }]; }, { sort: [{
            type: ViewChild,
            args: [MatSort, { static: false }]
        }] }); })();
