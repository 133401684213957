/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { defer } from 'lodash';

/**
 * This directive move focus into assigned element.
 * It is important to note that this directive moves focus only on initialization (how its name states).
 */
@Directive({
  selector: '[ghFocusOnInit]',
})
export class FocusOnInitDirective implements OnInit {
  _focusOnInit: boolean = true;

  constructor(private elementRef: ElementRef) {

  }

  @Input('ghFocusOnInit')
  set focusOnInit(value: any) {
    this._focusOnInit = coerceBooleanProperty(value);
  }

  ngOnInit(): void {
    if (this._focusOnInit) {
      // Usage of NgZone service is cleaner approach for delayed operations,
      // but there are some problems when ghFocusOnInit attached to input with ghAutocomplete directive
      defer(() => this.elementRef.nativeElement.focus());
    }
  }
}
