/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MOCK_AUTH_MODE_PREDEFINED_USERS } from '@gh/config';
import { of$ } from '@gh/rx';
import { capitalize, defer, isEmpty, words } from 'lodash';
import { Observable } from 'rxjs';
import { PRIMARY_MOCK_OPTIONS, PrimaryMockOptions } from '../primary-mock-auth-provider.service';

const ANOTHER_USER: any = {};

export const MOCK_AUTH_MODE_USERS = MOCK_AUTH_MODE_PREDEFINED_USERS || [];

class MockUserDataSource implements DataSource<any> {
  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return of$(MOCK_AUTH_MODE_USERS);
  }

  disconnect(collectionViewer: CollectionViewer): void {
    // nothing to do
  }
}

@Component({
  selector: 'gh-mock-login-dialog',
  templateUrl: 'mock-login-dialog.component.html',
  styleUrls: ['mock-login-dialog.component.scss'],
})
export class MockLoginDialogComponent {
  anotherUserId = '';
  another = ANOTHER_USER;
  users = MOCK_AUTH_MODE_USERS;
  selectedUser = MOCK_AUTH_MODE_USERS[0] || ANOTHER_USER;
  dataSource = new MockUserDataSource();
  displayedColumns = ['radio'].concat(this.options.fields);
  firstFieldName = this.options.fields[0];
  isMultipleFields = this.options.fields.length > 1;

  @ViewChild('anotherInput', { static: false }) anotherEl: ElementRef;

  get dialogWidth(): number {
    return this.options.width;
  }

  constructor(private dialogRef: MatDialogRef<any>,
              @Inject(PRIMARY_MOCK_OPTIONS) private options: PrimaryMockOptions) {

  }

  get anotherUserError(): boolean {
    return this.selectedUser === ANOTHER_USER && isEmpty(this.anotherUserId);
  }

  stringifyField(field: string): string {
    return words(field).map(capitalize).join(' ');
  }

  onLogin(event: Event): void {
    event.preventDefault();

    const { selectedUser, anotherUserId } = this;

    this.dialogRef.close(selectedUser === ANOTHER_USER ? anotherUserId : selectedUser.id);
  }

  onSelect(user: any): void {
    this.selectedUser = user;
  }

  onAnotherUser(): void {
    this.onSelect(this.another);
    defer(() => this.anotherEl.nativeElement.focus());
  }
}
