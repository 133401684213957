/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input } from '@angular/core';
import { defer } from 'lodash';
import * as i0 from "@angular/core";
/**
 * This directive move focus into assigned element.
 * It is important to note that this directive moves focus only on initialization (how its name states).
 */
var FocusOnInitDirective = /** @class */ (function () {
    function FocusOnInitDirective(elementRef) {
        this.elementRef = elementRef;
        this._focusOnInit = true;
    }
    Object.defineProperty(FocusOnInitDirective.prototype, "focusOnInit", {
        set: function (value) {
            this._focusOnInit = coerceBooleanProperty(value);
        },
        enumerable: true,
        configurable: true
    });
    FocusOnInitDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (this._focusOnInit) {
            // Usage of NgZone service is cleaner approach for delayed operations,
            // but there are some problems when ghFocusOnInit attached to input with ghAutocomplete directive
            defer(function () { return _this.elementRef.nativeElement.focus(); });
        }
    };
    FocusOnInitDirective.ɵfac = function FocusOnInitDirective_Factory(t) { return new (t || FocusOnInitDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    FocusOnInitDirective.ɵdir = i0.ɵɵdefineDirective({ type: FocusOnInitDirective, selectors: [["", "ghFocusOnInit", ""]], inputs: { focusOnInit: ["ghFocusOnInit", "focusOnInit"] } });
    return FocusOnInitDirective;
}());
export { FocusOnInitDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FocusOnInitDirective, [{
        type: Directive,
        args: [{
                selector: '[ghFocusOnInit]',
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { focusOnInit: [{
            type: Input,
            args: ['ghFocusOnInit']
        }] }); })();
