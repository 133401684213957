/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, ViewContainerRef } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { toPx } from '@gh/core-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
/**
 * This code was mainly copy-pasted from @angular/material library (MdMenuTrigger directive)
 */
var ContextMenuTriggerDirective = /** @class */ (function () {
    function ContextMenuTriggerDirective(overlay, element, viewContainerRef) {
        this.overlay = overlay;
        this.element = element;
        this.viewContainerRef = viewContainerRef;
        this._menuOpen = false;
    }
    ContextMenuTriggerDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.menu.close.subscribe(function () { return _this.closeMenu(); });
    };
    ContextMenuTriggerDirective.prototype.ngOnDestroy = function () {
        this.destroyMenu();
    };
    /** Opens the menu. */
    ContextMenuTriggerDirective.prototype.openMenu = function (event) {
        if (!this._menuOpen) {
            this._createOverlay(event).attach(this._portal);
            this._subscribeToBackdrop();
            this._initMenu();
            if (this.menu instanceof MatMenu) {
                this.menu._startAnimation();
            }
        }
    };
    /** Closes the menu. */
    ContextMenuTriggerDirective.prototype.closeMenu = function () {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this._backdropSubscription.unsubscribe();
            this._resetMenu();
        }
    };
    /** Removes the menu from the DOM. */
    ContextMenuTriggerDirective.prototype.destroyMenu = function () {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = void 0;
            this._cleanUpSubscriptions();
        }
    };
    /** Focuses the menu trigger. */
    ContextMenuTriggerDirective.prototype.focus = function () {
        this.element.nativeElement.focus();
    };
    ContextMenuTriggerDirective.prototype.onContextMenu = function (event) {
        event.preventDefault();
        this.openMenu(event);
    };
    /**
     * This method ensures that the menu closes when the overlay backdrop is clicked.
     * We do not use first() here because doing so would not catch clicks from within
     * the menu, and it would fail to unsubscribe properly. Instead, we unsubscribe
     * explicitly when the menu is closed or destroyed.
     */
    ContextMenuTriggerDirective.prototype._subscribeToBackdrop = function () {
        var _this = this;
        if (this.overlayRef) {
            this._backdropSubscription = this.overlayRef.backdropClick().subscribe(function () {
                _this.menu.close.emit();
            });
        }
    };
    /**
     * This method sets the menu state to open and focuses the first item if
     * the menu was opened via the keyboard.
     */
    ContextMenuTriggerDirective.prototype._initMenu = function () {
        this._setIsMenuOpen(true);
        this.menu.focusFirstItem();
    };
    /**
     * This method resets the menu when it's closed, most importantly restoring
     * focus to the menu trigger if the menu was opened via the keyboard.
     */
    ContextMenuTriggerDirective.prototype._resetMenu = function () {
        this._setIsMenuOpen(false);
    };
    // set state rather than toggle to support triggers sharing a menu
    ContextMenuTriggerDirective.prototype._setIsMenuOpen = function (isOpen) {
        this._menuOpen = isOpen;
    };
    /**
     *  This method creates the overlay from the provided menu's template and saves its
     *  OverlayRef so that it can be attached to the DOM when openMenu is called.
     */
    ContextMenuTriggerDirective.prototype._createOverlay = function (event) {
        if (!this.overlayRef) {
            this._portal = new TemplatePortal(this.menu.templateRef, this.viewContainerRef);
            var config = this._getOverlayConfig(event);
            this.overlayRef = this.overlay.create(config);
        }
        else {
            this.reposition(this.overlayRef.getConfig().positionStrategy, event);
        }
        return this.overlayRef;
    };
    /**
     * This method builds the configuration object needed to create the overlay, the OverlayConfig.
     * @returns OverlayConfig
     */
    ContextMenuTriggerDirective.prototype._getOverlayConfig = function (event) {
        var overlayConfig = new OverlayConfig();
        overlayConfig.positionStrategy = this._getPosition(event);
        overlayConfig.hasBackdrop = true;
        overlayConfig.backdropClass = 'cdk-overlay-transparent-backdrop';
        overlayConfig.scrollStrategy = this.overlay.scrollStrategies.reposition();
        return overlayConfig;
    };
    ContextMenuTriggerDirective.prototype._getPosition = function (event) {
        return this.overlay.position()
            .global()
            .left(toPx(event.clientX))
            .top(toPx(event.clientY));
    };
    ContextMenuTriggerDirective.prototype.reposition = function (positionStrategy, event) {
        positionStrategy
            .left(toPx(event.clientX))
            .top(toPx(event.clientY));
    };
    ContextMenuTriggerDirective.prototype._cleanUpSubscriptions = function () {
        if (this._backdropSubscription) {
            this._backdropSubscription.unsubscribe();
        }
    };
    ContextMenuTriggerDirective.ɵfac = function ContextMenuTriggerDirective_Factory(t) { return new (t || ContextMenuTriggerDirective)(i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef)); };
    ContextMenuTriggerDirective.ɵdir = i0.ɵɵdefineDirective({ type: ContextMenuTriggerDirective, selectors: [["", "ghContextMenuTrigger", ""]], hostBindings: function ContextMenuTriggerDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("contextmenu", function ContextMenuTriggerDirective_contextmenu_HostBindingHandler($event) { return ctx.onContextMenu($event); });
        } }, inputs: { menu: ["ghContextMenuTrigger", "menu"] } });
    return ContextMenuTriggerDirective;
}());
export { ContextMenuTriggerDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContextMenuTriggerDirective, [{
        type: Directive,
        args: [{
                selector: "[ghContextMenuTrigger]",
            }]
    }], function () { return [{ type: i1.Overlay }, { type: i0.ElementRef }, { type: i0.ViewContainerRef }]; }, { menu: [{
            type: Input,
            args: ['ghContextMenuTrigger']
        }], onContextMenu: [{
            type: HostListener,
            args: ['contextmenu', ['$event']]
        }] }); })();
