/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { IGNORE_DIRTY_CHANGES } from '@gh/config';
import { TranslationService } from '@gh/core-mls';
import { remove } from 'lodash';
import { isViewDirty } from './can-leave.interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
var ApplicationComponentState = /** @class */ (function () {
    function ApplicationComponentState(translationService) {
        var _this = this;
        this.translationService = translationService;
        this._entries = [];
        if (!IGNORE_DIRTY_CHANGES) {
            window.addEventListener('beforeunload', function (event) { return _this.onBeforeUnload(event); });
        }
    }
    Object.defineProperty(ApplicationComponentState.prototype, "components", {
        get: function () {
            return this._entries.map(function (entry) { return entry.component; });
        },
        enumerable: true,
        configurable: true
    });
    ApplicationComponentState.prototype.activate = function (component) {
        this._entries.push({ component: component, ignoreDirty: false });
    };
    ApplicationComponentState.prototype.deactivate = function (component) {
        remove(this._entries, function (entry) { return entry.component === component; });
    };
    ApplicationComponentState.prototype.hasDirtyChanges = function () {
        return this._entries.filter(function (entry) { return !entry.ignoreDirty; }).some(function (entry) { return isViewDirty(entry.component); });
    };
    ApplicationComponentState.prototype.setIgnoreDirty = function () {
        this._entries.forEach(function (entry) { return entry.ignoreDirty = true; });
    };
    ApplicationComponentState.prototype.onBeforeUnload = function (event) {
        if (!this.hasDirtyChanges()) {
            return;
        }
        var message = this.translationService.get('msg_before_unload');
        event.returnValue = message;
        return message;
    };
    ApplicationComponentState.ɵfac = function ApplicationComponentState_Factory(t) { return new (t || ApplicationComponentState)(i0.ɵɵinject(i1.TranslationService)); };
    ApplicationComponentState.ɵprov = i0.ɵɵdefineInjectable({ token: ApplicationComponentState, factory: ApplicationComponentState.ɵfac });
    return ApplicationComponentState;
}());
export { ApplicationComponentState };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ApplicationComponentState, [{
        type: Injectable
    }], function () { return [{ type: i1.TranslationService }]; }, null); })();
