/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';

export const API_BASE_URL = new InjectionToken('ApiBaseUrl');

@Injectable()
export class RestSettings {
  constructor(public httpClient: HttpClient,
              @Inject(API_BASE_URL) public baseUrl: string) {

  }
}
