/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { LocalizationService, TranslationService } from '@gh/core-mls';
import { currentDate, currentDayOfMonth, isDateInvalid } from '@gh/core-util';
import { isNil, isNumber, isString, merge } from 'lodash';
import { MAT_NATIVE_DATE_FORMATS, MatDateFormats, NativeDateAdapter } from '@angular/material/core';

enum DisplayFormatTarget { Input, Layout }

export const APP_DATE_FORMATS: MatDateFormats = merge({}, MAT_NATIVE_DATE_FORMATS, {
  parse: {
    dateInput: 'date', // one of formats defined in the localization.json
  },
  display: {
    dateInput: { target: DisplayFormatTarget.Input, query: 'shortDate' },
    monthYearLabel: { target: DisplayFormatTarget.Layout, query: 'monthYear' },
    dateA11yLabel: { target: DisplayFormatTarget.Layout, query: 'dateA11y' },
    monthYearA11yLabel: { target: DisplayFormatTarget.Layout, query: 'monthYearA11y' },
  },
});

const getValidDate = (date: Date) => isDateInvalid(date) ? currentDate() : date;

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  constructor(private platform: Platform,
              private localizationProvider: LocalizationService,
              private translationService: TranslationService) {
    super(localizationProvider.getEffectiveLocale().code, platform);
  }

  today(): Date {
    return currentDate();
  }

  createDate(year: number, month: number, date: number): Date {
    return super.createDate(year, month, date < 0 ? currentDayOfMonth() : date);
  }

  getYear(date: Date): number {
    return super.getYear(getValidDate(date));
  }

  getMonth(date: Date): number {
    return super.getMonth(getValidDate(date));
  }

  getDate(date: Date): number {
    return isDateInvalid(date) ? -1 : super.getDate(getValidDate(date));
  }

  getDayOfWeek(date: Date): number {
    return super.getDayOfWeek(getValidDate(date));
  }

  getYearName(date: Date): string {
    return super.getYearName(getValidDate(date));
  }

  getNumDaysInMonth(date: Date): number {
    return super.getNumDaysInMonth(getValidDate(date));
  }

  clone(date: Date): Date {
    return super.clone(getValidDate(date));
  }

  addCalendarYears(date: Date, years: number): Date {
    return super.addCalendarYears(getValidDate(date), years);
  }

  addCalendarMonths(date: Date, months: number): Date {
    return super.addCalendarMonths(getValidDate(date), months);
  }

  addCalendarDays(date: Date, days: number): Date {
    return super.addCalendarDays(getValidDate(date), days);
  }

  toIso8601(date: Date): string {
    return super.toIso8601(getValidDate(date));
  }

  parse(value: any, parseFormat?: any): Date | null {
    if (isNil(value) || isString(value) && value.trim().length === 0) {
      return null; // tslint:disable-line:no-null-keyword
    } else if (isNumber(value)) {
      return new Date(value);
    }

    return this.localizationProvider.getDateTimeParser().parse(parseFormat, value);
  }

  format(date: Date, displayFormat: any): string {
    const { target, query } = displayFormat;
    const invalid = isDateInvalid(date);

    if (invalid && target === DisplayFormatTarget.Input) {
      return this.translationService.get('lbl_invalid_date');
    } else {
      return this.localizationProvider.getDateTimeFormat().format(query, getValidDate(date));
    }
  }

  /**
   * This method always treats date as valid. This is necessary to avoid binding of
   * internal datepicker validator. Internal validator is bound to the form control each time
   * component is created. This way we can have several similar validators and all of them
   * except the latest one (may be) will validate date based on input of <b>disposed (!!!) datepicker</b>.
   * Thus we have to avoid any internal datepicker validations.
   */
  isValid(date: Date): boolean {
    return true;
  }
}
