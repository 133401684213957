/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  AfterViewInit,
  Directive,
  ElementRef,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { coalesceObjectProperty } from '@gh/core-util';
import { filter$, first$ } from '@gh/rx/operators';
import { eq, inRange, isNil, isString, noop, remove } from 'lodash';
import { isUploadFinalEvent, UploadController, UploadEventType } from '../../../uploader';
import { TinymceSettings } from './tinymce-settings';

declare const tinymce: any;

export type PlaceholderID = any;

type Node = import('tinymce').html.Node & { firstChild?: Node; parent?: Node; next?: Node };

const WSC_CLASS_NAMES = ['wsc-spelling-problem', 'wsc-grammar-problem'];

/**
 * Cleans HTML content tree from wsc-* nodes
 * @param tree
 */
export const cleanContentTree = (tree: Node): Node => {
  const stack = [tree];

  while (stack.length) {
    const current = stack.pop()!; // tslint:disable-line:no-non-null-assertion

    const className = current.attr('class');
    if (className && isString(className) && WSC_CLASS_NAMES.some((wscClassName) => className.includes(wscClassName))) {
      // remove node if it belongs to wsc-* node
      if (current.parent && current.firstChild) {
        stack.push(current.firstChild);
        current.parent.insert(current.firstChild, current);
        current.remove();
      }
    } else if (current.firstChild) {
      // traverse only parent elements
      let nextChild: Maybe<Node> = current.firstChild;
      while (nextChild) {
        stack.push(nextChild);
        nextChild = nextChild.next;
      }
    }
  }
  return tree;
};

@Directive({
  selector: '[ghTinymce]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TinymceTextareaDirective,
    multi: true,
  }],
  exportAs: 'tinymceTextarea',
})
export class TinymceTextareaDirective implements ControlValueAccessor,
                                                 OnInit,
                                                 AfterViewInit,
                                                 OnDestroy {

  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() plugins: Maybe<string>[] = [];

  innerValue: string;
  onChangeCallback: any = noop;
  onTouchedCallback: any = noop;
  editor: any;
  control: NgControl;

  private _settings?: TinymceSettings;
  private placeholders: PlaceholderID[] = [];
  private serializer: import('tinymce').html.Serializer;

  constructor(private zone: NgZone,
              private injector: Injector,
              private elementRef: ElementRef,
              @Optional() @Self() private uploader?: UploadController) {
  }

  @Input('ghTinymce')
  set settings(settings: TinymceSettings) {
    this._settings = coalesceObjectProperty(settings);
  }

  ngOnInit(): void {

    if (isNil(this._settings)) {
      throw new Error('Settings is not specified for ghTinymce directive');
    }
    this.control = this.injector.get(NgControl);
  }

  ngAfterViewInit(): void {
    if (typeof tinymce === 'undefined') {
      throw new Error('TinymceTextareaDirective: tinymce is not loaded. Preload it using loadTinymce() function');
    }

    const options = {
      ...this._settings,
      target: this.elementRef.nativeElement,
      readonly: this.disabled,
      setup: (editor: any) => {
        this.editor = editor;
        editor.on('change keydown', this.keyboardIteractionsHandler(editor));
        editor.on('blur', () => this.onTouchedCallback());

        // initialize tinymce serializer.
        // It is used to serialize HTML content tree manually after it was cleaned from wsc-* nodes
        this.serializer = (<any>tinymce.html.Serializer)(
          options,
          editor.schema ? editor.schema : (<any>tinymce.html.Schema)(options));
      },
      init_instance_callback: (editor: any): void => {
        editor && this.value && editor.setContent(this.value);
      },
      images_upload_handler: this.imageUploadHandler.bind(this),
    };
    tinymce.init(options);
  }

  ngOnDestroy(): void {
    try {
      const editor = this.editor;
      this.editor = void 0;
      tinymce.remove(editor);
    } catch (e) {
      console.warn('Possible Tinymce race condition.');
    }
  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.zone.run(() => {
        this.onChangeCallback(value);
      });

    }
  }

  insertPlaceholder(id: PlaceholderID): void {
    if (!this.placeholders.includes(id)) {
      this.placeholders.push(id);
    }
  }

  removePlaceholder(id: PlaceholderID): void {
    const { placeholders } = this;
    if (placeholders.includes(id)) {
      this.placeholders = remove(placeholders, (removeId: PlaceholderID): boolean => eq(removeId, id));
    }
  }

  getPlaceholders(): PlaceholderID[] {
    return this.placeholders;
  }

  cleanPlaceholders(): void {
    this.placeholders = [];
  }

  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.editor && this.editor.setContent(value || '');
    }
  }

  registerOnChange(onChange: any): void {
    this.onChangeCallback = onChange;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.editor) {
      this.editor.setMode(isDisabled ? 'readonly' : 'design');
    }
  }

  change(value: string): void {
    this.innerValue = value;
    this.onChangeCallback(value);
  }

  private keyboardIteractionsHandler(editor: any): Function {
    return (event: KeyboardEvent): boolean | void => {
      const element = editor.selection.getNode(); // current caret node

      // TODO: think about deny removing with next/prev elements
      // const nextElement = elememt.parentNode.parentNode.nextSibling;

      if (element.classList.contains('velocity-code')) {
        if (!inRange(event.keyCode, 37, 41)) { // tslint:disable-line no-magic-numbers
          event.preventDefault();
          return false;
        }
      }
      const tree = editor.getContent({ format: 'tree' });
      const content = this.serializer.serialize(cleanContentTree(tree));
      this.value = content;
    };
  }

  private imageUploadHandler(blobInfo: any, success: Function, failure: Function): void {
    if (this.uploader) {
      this.uploader.event$.pipe(
        filter$((event) => event.item.uploadId === item.uploadId),
        first$(isUploadFinalEvent))
        .subscribe((event) => {
          if (event.type === UploadEventType.Completed) {
            success(event.item.location);
          } else {
            failure('Uploading error');
          }
        });

      const item = this.uploader.create({ name: blobInfo.filename(), blob: blobInfo.blob() });
      this.uploader.start(item);
    }
  }
}
