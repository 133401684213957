/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { get } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
/**
 * This directive helps to show loading effect for pending validations.
 * If assigned to component having ngModel or form* directive,
 * it adds spinner shown when corresponding control is in pending status.
 *
 * By setting [ghFormAsyncEffectPending]="..." attribute developer can manually control loading effect.
 *
 * Note!!! Currently this directive can be assigned only to input element.
 */
var FormAsyncEffectDirective = /** @class */ (function () {
    function FormAsyncEffectDirective(elementRef, ngControl) {
        this.elementRef = elementRef;
        this.ngControl = ngControl;
        this._pending = false;
        this.lastPending = false;
    }
    Object.defineProperty(FormAsyncEffectDirective.prototype, "pending", {
        get: function () {
            return this._pending || get(this.ngControl, 'control.pending', false);
        },
        set: function (pending) {
            this._pending = pending;
        },
        enumerable: true,
        configurable: true
    });
    FormAsyncEffectDirective.prototype.ngOnInit = function () {
        var spinnerEl = document.createElement('div');
        spinnerEl.classList.add('spinner', 'spinner-process', 'is-hidden');
        var parentEl = this.getParentElement();
        if (parentEl) {
            parentEl.appendChild(spinnerEl);
        }
        this.spinnerEl = spinnerEl;
    };
    FormAsyncEffectDirective.prototype.ngOnDestroy = function () {
        var parentEl = this.getParentElement();
        if (parentEl && this.spinnerEl) {
            parentEl.removeChild(this.spinnerEl);
        }
        this.spinnerEl = void 0;
    };
    FormAsyncEffectDirective.prototype.ngDoCheck = function () {
        var newPending = this.pending;
        if (newPending !== this.lastPending && this.spinnerEl) {
            this.spinnerEl.classList.toggle('is-hidden', !newPending);
            this.lastPending = newPending;
        }
    };
    FormAsyncEffectDirective.prototype.getParentElement = function () {
        return this.elementRef.nativeElement.parentElement;
    };
    FormAsyncEffectDirective.ɵfac = function FormAsyncEffectDirective_Factory(t) { return new (t || FormAsyncEffectDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.NgControl, 8)); };
    FormAsyncEffectDirective.ɵdir = i0.ɵɵdefineDirective({ type: FormAsyncEffectDirective, selectors: [["input", "ghFormAsyncEffect", ""], ["input", "ghFormAsyncEffectPending", ""]], inputs: { pending: ["ghFormAsyncEffectPending", "pending"] } });
    return FormAsyncEffectDirective;
}());
export { FormAsyncEffectDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FormAsyncEffectDirective, [{
        type: Directive,
        args: [{
                selector: 'input[ghFormAsyncEffect], input[ghFormAsyncEffectPending]',
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i1.NgControl, decorators: [{
                type: Optional
            }] }]; }, { pending: [{
            type: Input,
            args: ['ghFormAsyncEffectPending']
        }] }); })();
