/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { collectValues, FormMode, getControl, mapControlTree, traverseControlTree, updateControlTree, } from '@gh/core-ui';
import { startWith$ } from '@gh/rx/operators';
import { find, isEqual, isNil, omitBy, remove } from 'lodash';
import { toAppFormGroup } from './controls';
import { collectVisibleValues } from './controls/app-control-util';
import { FormConfirmationState } from './form-confirmation-state';
import { controlPackedValue, } from './form-schema';
import { toControlStateEntry } from './form-state-snapshot';
/**
 * Form state defines form structure and simplifies common form-related operations.
 */
var FormState = /** @class */ (function () {
    function FormState(schemaFactory) {
        if (schemaFactory) {
            this.schema = schemaFactory.create();
        }
    }
    Object.defineProperty(FormState.prototype, "group", {
        get: function () {
            return this._group;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "confirmations", {
        get: function () {
            return this._confirmations;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "valueChanges", {
        /**
         * Returns valueChanges stream of underlying form group
         * @returns {Observable<any>}
         */
        get: function () {
            return this._group.valueChanges;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "pending", {
        get: function () {
            return this._group.pending || this._confirmations.pending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "childForms", {
        /**
         * Returns all child forms with their names
         * @returns {FormAndName[]}
         */
        get: function () {
            return this.childFormSeq;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "submitValue", {
        get: function () {
            return collectVisibleValues(this.group);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "pristine", {
        get: function () {
            return this.group.pristine;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "mode", {
        get: function () {
            return this._mode;
        },
        set: function (_) {
            throw new Error('FormState.mode setter was deprecated: use setMode function instead');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "createMode", {
        get: function () {
            return this.mode === FormMode.CreateMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "editMode", {
        get: function () {
            return this.mode === FormMode.EditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "viewMode", {
        get: function () {
            return this.mode === FormMode.ViewMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "editable", {
        get: function () {
            var mode = this.mode;
            return mode === FormMode.CreateMode || mode === FormMode.EditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "invalid", {
        get: function () {
            return this.group.invalid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "dirty", {
        get: function () {
            return this.group.dirty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormState.prototype, "value", {
        /**
         * Returns underlying form value
         *
         * @returns {any}
         */
        get: function () {
            return this._group.value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * This method should build form group in subclasses
     */
    FormState.prototype.build = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.initSchema(args[0]);
    };
    /**
     * Returns control from the underlying form group
     * @param name
     * @returns {AbstractControl}
     */
    FormState.prototype.getControl = function (name) {
        return this._group.controls[name];
    };
    /**
     * Sets control of the underlying form group
     *
     * @param name
     * @param control
     */
    FormState.prototype.setControl = function (name, control) {
        this._group.setControl(name, control);
    };
    /**
     * Returns control from the underlying form group by the given path
     *
     * @param path
     * @returns {AbstractControl}
     */
    FormState.prototype.get = function (path) {
        return this.group.get(path);
    };
    /**
     * Finds child form using given predicate
     *
     * @param predicate
     * @returns {T}
     */
    FormState.prototype.findChildForm = function (predicate) {
        return find(this.childForms, function (form) { return predicate(form.form, form.name); });
    };
    FormState.prototype.findNestedForm = function (predicate) {
        var path = [];
        var found = {
            form: this,
            name: '',
        };
        if (!predicate(found.form, found.name)) {
            return [];
        }
        do {
            path.push(found);
            found = found.form.findChildForm(predicate);
        } while (found);
        return path;
    };
    /**
     * Returns child form by name
     *
     * @param name
     * @returns {any}
     */
    FormState.prototype.getChildForm = function (name) {
        return this.childFormIndex[name];
    };
    /**
     * Adds child form under <code>name</code> control of the underlying form group
     *
     * @param name
     * @param form
     */
    FormState.prototype.setChildForm = function (name, form) {
        this.childFormSeq.push({ name: name, form: form });
        this.childFormIndex[name] = form;
        // this._group.setControl(name, form.group);
        form.confirmations.setParent(this.confirmations);
    };
    /**
     * Removes child form for the given <code>name</code>
     *
     * @param name
     */
    FormState.prototype.removeChildForm = function (name) {
        var form = this.childFormIndex[name];
        if (form) {
            this.childFormSeq = remove(this.childFormSeq, { name: name });
            this.childFormIndex = omitBy(this.childFormIndex, form);
            // this._group.setControl(name, form.group);
            form.confirmations.resetParent();
        }
    };
    /**
     * Builds form according to the schema.
     *
     * @param originalValue
     */
    FormState.prototype.initSchema = function (originalValue) {
        var schema = this.schema;
        if (isNil(schema)) {
            // tslint:disable-next-line:max-line-length
            throw new Error('FormState.initSchema could be called only when FormState is initialized using FormSchemaFactory');
        }
        var rootSchema = this.buildSchema(originalValue, this.schema);
        var isNewSchema = isNil(this._rootSchema);
        if (isNewSchema) {
            rootSchema.init(this);
            this.setGroup(rootSchema.build(originalValue));
            this._rootSchema = rootSchema;
        }
        else {
            rootSchema.use(this._rootSchema);
            this._rootSchema.update(rootSchema);
            this._rootSchema.applyTo(toAppFormGroup(this.group), controlPackedValue(originalValue));
            this.markAllAsUnchanged();
        }
        this.childFormSeq = [];
        this.childFormIndex = {};
    };
    FormState.prototype.updateSchema = function (schema, packedValue) {
        toAppFormGroup(this._group).updateSchema(schema, packedValue);
    };
    FormState.prototype.setMode = function (mode) {
        this._mode = mode;
    };
    /**
     * Sets value for the underlying form group and updates all tree of form controls.
     *
     * Before this method changes underlying form values it calls {@link #beforeSetValue} to allow
     * form delegator to adapt form model to data.
     *
     * After this method have changed underlyling form values it calls {@link #afterSetValue}
     * for some post processing (is it necessary?).
     *
     * @param entity
     * @param extra
     */
    FormState.prototype.setValue = function (entity, extra) {
        if (extra === void 0) { extra = {}; }
        this._group.setValue(this.getFormProjection(entity, extra.strict), extra);
    };
    FormState.prototype.calculatable = function (control) {
        return control.valueChanges.pipe(startWith$(control.value));
    };
    FormState.prototype.markAsPristine = function () {
        this.group.markAsPristine();
    };
    FormState.prototype.makeSnapshot = function () {
        var group = this.group;
        return {
            value: mapControlTree(group, function (control) { return control.value; }),
            controlState: mapControlTree(group, toControlStateEntry),
        };
    };
    FormState.prototype.modifyValue = function (valueFn) {
        var newValue = valueFn(collectValues(this._group));
        this.updateSchema(this.buildSchema(newValue, this.schema), controlPackedValue(newValue));
    };
    FormState.prototype.refresh = function () {
        this.updateSchema(this.buildSchema(collectValues(this._group), this.schema));
    };
    FormState.prototype.restore = function (snapshot) {
        updateControlTree(this.group, snapshot.value, function (control, value) {
            if (!isEqual(control.value, value)) {
                control.setValue(value);
            }
        });
        if (snapshot.controlState) {
            updateControlTree(this.group, snapshot.controlState, function (control, state) {
                if (!control.dirty && state && state.dirty) {
                    control.markAsDirty();
                }
                if (!control.touched && state && state.touched) {
                    control.markAsTouched();
                }
                if (!control.errors && state && state.errors) {
                    control.setErrors(state.errors);
                }
            });
        }
    };
    FormState.prototype.highlightAll = function () {
        this.markAllAsTouched();
    };
    /**
     * Marks all tree of form controls as touched.
     */
    FormState.prototype.markAllAsTouched = function () {
        traverseControlTree(this._group, function (control) { return control.markAsTouched(); });
        return this;
    };
    FormState.prototype.resetHighlight = function () {
        this.markAsPristine();
    };
    /**
     * Disables form group
     */
    FormState.prototype.disable = function () {
        this._group.disable();
        return this;
    };
    /**
     * Disables list of controls form group
     */
    FormState.prototype.disablePartial = function (controlsNames) {
        var _this = this;
        controlsNames.forEach(function (name) { return getControl(_this._group, name).disable(); });
        return this;
    };
    /**
     * Disables list of controls form group
     */
    FormState.prototype.enablePartial = function (controlsNames) {
        var _this = this;
        controlsNames.forEach(function (name) { return getControl(_this._group, name).enable(); });
        return this;
    };
    /**
     * Enables form group
     */
    FormState.prototype.enable = function () {
        this._group.enable();
        return this;
    };
    FormState.prototype.buildSchema = function (value, schema) {
        throw new Error('FormState.buildSchema should be implemented in the subclass');
    };
    FormState.prototype.setGroup = function (group) {
        this._group = group;
        this._confirmations = new FormConfirmationState(group);
    };
    /**
     * Returns projected values from the entity. Projected entity corresponds to the form structure.
     *
     * @param entity
     * @param strict
     * @returns {{}}
     */
    FormState.prototype.getFormProjection = function (entity, strict) {
        return Object.keys(this._group.controls).reduce(function (value, name) {
            var fieldValue = entity[name];
            if (strict) {
                value[name] = fieldValue;
            }
            else if (fieldValue === undefined) {
                value[name] = null; // tslint:disable-line:no-null-keyword
            }
            else {
                value[name] = fieldValue;
            }
            return value;
        }, {});
    };
    /**
     * Marks all tree of form controls as untouched.
     */
    FormState.prototype.markAllAsUnchanged = function () {
        traverseControlTree(this._group, function (control) {
            control.markAsUntouched();
            control.markAsPristine();
        });
        return this;
    };
    return FormState;
}());
export { FormState };
