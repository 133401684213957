/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AfterViewChecked, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { first$ } from '@gh/rx/operators';
import { isNil } from 'lodash';
import { Subscription } from 'rxjs';

/**
 * This directive move focus into assigned element.
 * It is important to note that this directive moves focus only on initialization (how its name states).
 */
@Directive({
  selector: '[ghFocus]',
})
export class FocusDirective implements AfterViewChecked, OnDestroy {
  private _focus = true;
  private needsUpdate = false;
  private subscription?: Subscription;

  constructor(private elementRef: ElementRef,
              private ngZone: NgZone) {

  }

  @Input('ghFocus')
  set focus(value: any) {
    this._focus = !!value;

    this.needsUpdate = true;
    if (!value && this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = void 0;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewChecked(): void {
    if (this.needsUpdate && this._focus && isNil(this.subscription)) {
      this.subscription = this.ngZone.onMicrotaskEmpty.pipe(first$()).subscribe(() => {
        this.elementRef.nativeElement.focus();
        this.subscription = void 0;
        this.needsUpdate = false;
      });
    }
  }
}
