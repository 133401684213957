/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { eq, get } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/select";
var CompareByDirective = /** @class */ (function () {
    function CompareByDirective(select) {
        this.select = select;
    }
    Object.defineProperty(CompareByDirective.prototype, "property", {
        set: function (field) {
            this.select.compareWith = function (a, b) { return eq(get(a, field), get(b, field)); };
        },
        enumerable: true,
        configurable: true
    });
    CompareByDirective.ɵfac = function CompareByDirective_Factory(t) { return new (t || CompareByDirective)(i0.ɵɵdirectiveInject(i1.MatSelect)); };
    CompareByDirective.ɵdir = i0.ɵɵdefineDirective({ type: CompareByDirective, selectors: [["mat-select", "ghCompareBy", ""]], inputs: { property: ["ghCompareBy", "property"] } });
    return CompareByDirective;
}());
export { CompareByDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CompareByDirective, [{
        type: Directive,
        args: [{
                selector: 'mat-select[ghCompareBy]',
            }]
    }], function () { return [{ type: i1.MatSelect }]; }, { property: [{
            type: Input,
            args: ['ghCompareBy']
        }] }); })();
