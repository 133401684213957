/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

export class PreventableEvent {
  private _cancelled: boolean = false;

  set cancelled(_: boolean) {
    throw new Error('PreventableEvent: cancelled property is readonly');
  }

  get cancelled(): boolean {
    return this._cancelled;
  }

  preventDefault(): void {
    this._cancelled = true;
  }
}
