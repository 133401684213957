/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP_STATUS_SERVER_UNAVAILABLE_ERROR } from '@gh/core-data';
import { createMessageBasketFromResponse } from '@gh/core-messages';
import { tap$ } from '@gh/rx/operators';
import { Observable } from 'rxjs';
import { ApplicationComponentState } from '../routing';

@Injectable()
export class MaintenanceHttpResponseInterceptor implements HttpInterceptor {
  constructor(private applicationComponentState: ApplicationComponentState) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap$({
        error: (response) => {
          if (response.status === HTTP_STATUS_SERVER_UNAVAILABLE_ERROR) {
            const basket = createMessageBasketFromResponse(response);
            if (basket.has('msg_service_is_not_available')) {
              // if backend is not available => we suppose that index-maintenance.html already substitutes index.html,
              // this way we try to refresh page ignoring any dirty state (to avoid handling of beforeUnload event)
              this.applicationComponentState.setIgnoreDirty();
              location.reload();
            }
          }
        },
      }),
    );
  }
}
