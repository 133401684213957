/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ExtNotFoundPageComponent } from '@gh/common-external-shared';
import { createRoute } from '@gh/common-shared';
import { CustomerEDocumentsListComponent, CustomerFinancialRecordsListComponent, CustomerGalleryComponent, CustomerSharedDocumentListComponent, } from './components';
import { getCustomerBootstrapParams } from './customer-app.utils';
import { CustomerEDocumentBusinessService, CustomerFinancialRecordBusinessService, CustomerGalleryBusinessService, CustomerSharedDocumentBusinessService, } from './resources';
import { CustomerJobActivator } from './services';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var routes = [{
        path: 'accept-invitation/:officeId',
        canActivate: [CustomerJobActivator],
        data: {
            isAcceptInvitation: true,
        },
        // empty children is necesary here to workaround @angular/router problem:
        // it does not allow to use route without component/children/loadChildren/redirectTo
        // it is not a problem here, as we never load this route (CanActivate always return false)
        children: [],
    }, {
        path: '',
        pathMatch: 'full',
        canActivate: [CustomerJobActivator],
        children: [],
    }, {
        path: 'jobs/:jobId',
        canActivate: [CustomerJobActivator],
        children: [
            createRoute({
                path: 'shared-docs',
                pathMatch: 'full',
                component: CustomerSharedDocumentListComponent,
                init: CustomerSharedDocumentBusinessService,
            }),
            createRoute({
                path: 'e-docs',
                pathMatch: 'full',
                component: CustomerEDocumentsListComponent,
                init: CustomerEDocumentBusinessService,
            }),
            createRoute({
                path: 'financial-records',
                pathMatch: 'full',
                component: CustomerFinancialRecordsListComponent,
                init: CustomerFinancialRecordBusinessService,
            }),
            createRoute({
                path: 'photos',
                pathMatch: 'full',
                component: CustomerGalleryComponent,
                init: CustomerGalleryBusinessService,
            }),
        ],
    }, {
        path: '404',
        component: ExtNotFoundPageComponent,
    }, {
        path: '**',
        component: ExtNotFoundPageComponent,
    }];
var CustomerAppRoutingModule = /** @class */ (function () {
    function CustomerAppRoutingModule() {
    }
    CustomerAppRoutingModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomerAppRoutingModule });
    CustomerAppRoutingModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomerAppRoutingModule_Factory(t) { return new (t || CustomerAppRoutingModule)(); }, providers: [{
                provide: APP_BASE_HREF,
                useValue: getCustomerBootstrapParams().baseUrl,
            }], imports: [[RouterModule.forRoot(routes)],
            RouterModule] });
    return CustomerAppRoutingModule;
}());
export { CustomerAppRoutingModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomerAppRoutingModule, { imports: [i1.RouterModule], exports: [RouterModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerAppRoutingModule, [{
        type: NgModule,
        args: [{
                imports: [RouterModule.forRoot(routes)],
                providers: [{
                        provide: APP_BASE_HREF,
                        useValue: getCustomerBootstrapParams().baseUrl,
                    }],
                declarations: [],
                exports: [RouterModule],
            }]
    }], null, null); })();
