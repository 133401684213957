/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { cast } from '@gh/core-util';

import { omit } from 'lodash';

import { ID } from './common-dto-types';

export interface AutocompleteItem {
  id: ID;
  name: string;
  display?: string;
  description?: string;
}

export type AutocompleteItemWithDescription = AutocompleteItem & { description?: string };

export type AutocompleteFilterDto = {
  searchString: string;
};

// tslint:disable-next-line:max-line-length
export function withDisplayAsNameAndDescription(item: AutocompleteItemWithDescription): AutocompleteItemWithDescription {
  const { display, name, description } = item;

  if (display) {
    return item;
  } else {
    return { ...item, display: name && description ? `${name} ${description}` : name };
  }
}

export function withoutDisplay(item: AutocompleteItemWithDescription): AutocompleteItem {
  return cast<AutocompleteItem>(omit(item, 'display'));
}

export const autocompleteItemToDto = (item: AutocompleteItemWithDescription) =>
  <AutocompleteItem>{
    id: item.id,
    name: item.name,
    description: item.description,
  };

export const getAutocompleteItemId = (item: AutocompleteItem) => item.id;
