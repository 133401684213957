/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

/// <reference path="./eversign.d.ts" />

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { EXTERNAL_EVERSIGN_EMBEDDED_JS_LIBRARY_URL } from '@gh/config';
import { ResourceLoader } from '@gh/core-external';
import { UI_ERROR_HANDLER, UiErrorHandler } from '@gh/core-ui';
import { isEmpty, uniqueId } from 'lodash';

@Component({
  selector: 'gh-ext-eversign-document',
  templateUrl: 'ext-eversign-document.component.html',
  styleUrls: ['ext-eversign-document.component.scss'],
})
export class ExtEversignDocumentComponent implements AfterViewInit {
  @Output() signed = new EventEmitter<void>();
  @Output() declined = new EventEmitter<void>();

  containerId = uniqueId('eversign-container:');
  loading = true;

  private _url: string;
  private initialized = false;

  constructor(private scriptLoader: ResourceLoader,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(UI_ERROR_HANDLER) private errorHandler: UiErrorHandler) {

  }

  @Input()
  set url(url: string) {
    if (this.initialized) {
      throw new Error('Eversign: URL for signing document cannot be changed after component has been initialized');
    }

    this._url = url;
  }

  get url(): string {
    return this._url;
  }

  ngAfterViewInit(): void {
    if (isEmpty(this.url)) {
      throw new Error('Eversign: URL for signing document is not defined');
    }

    this.scriptLoader.require(EXTERNAL_EVERSIGN_EMBEDDED_JS_LIBRARY_URL)
      .subscribe(
        () => eversign.open({
          url: this.url,
          containerID: this.containerId,
          width: '100%',
          height: '100%',
          events: {
            loaded: () => this.finishLoading(),
            error: () => this.finishLoading(),
            signed: () => this.signed.emit(),
            declined: () => this.declined.emit(),
          },
        }),
        (error) => this.errorHandler.raise(error));

    this.initialized = true;
  }

  private finishLoading(): void {
    this.loading = false;
    this.changeDetectorRef.markForCheck();
  }
}
