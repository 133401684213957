/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, HostListener } from '@angular/core';
import { AppRouter } from './app-router.service';

@Directive({
  selector: '[ghRouterBack]',
})
export class RouterBackDirective {

  constructor(private router: AppRouter) {

  }

  @HostListener('click')
  onClick(): void {
    this.router.navigateBack();
  }
}
