var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { AbstractRestService, arrayOf, RestSettings } from '@gh/core-data';
import { EDocumentsListMapper } from './e-document.mapper';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-data";
var CustomerEDocumentRestService = /** @class */ (function (_super) {
    __extends(CustomerEDocumentRestService, _super);
    function CustomerEDocumentRestService(settings) {
        var _this = _super.call(this, settings) || this;
        _this.settings = settings;
        return _this;
    }
    CustomerEDocumentRestService.prototype.findAll = function (jobId) {
        return this.httpGet("/jobs/" + jobId + "/edocs", arrayOf(EDocumentsListMapper));
    };
    CustomerEDocumentRestService.ɵfac = function CustomerEDocumentRestService_Factory(t) { return new (t || CustomerEDocumentRestService)(i0.ɵɵinject(i1.RestSettings)); };
    CustomerEDocumentRestService.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerEDocumentRestService, factory: CustomerEDocumentRestService.ɵfac });
    return CustomerEDocumentRestService;
}(AbstractRestService));
export { CustomerEDocumentRestService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerEDocumentRestService, [{
        type: Injectable
    }], function () { return [{ type: i1.RestSettings }]; }, null); })();
