/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, ElementRef, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { EXT_INFINITE_SCROLL_DIMENSIONS, ExtInfiniteScrollDimensions } from '../ext-infinite-scroll.interfaces';

@Component({
  selector: 'gh-ext-infinite-list-item',
  template: `
    <ng-container [ngTemplateOutlet]="template"
                  [ngTemplateOutletContext]="context"></ng-container>`,
  styleUrls: ['ext-infinite-list-item.component.scss'],
})
export class ExtInfiniteListItemComponent implements OnInit {
  template: TemplateRef<any>;
  data: any;
  context: any;

  private _index: number;

  @Input()
  set index(index: number) {
    const top = this.dimensions.offsetTop + this.dimensions.itemHeight * index;
    this.elementRef.nativeElement.style.top = `${top}px`;
    this._index = index;
  }

  get index(): number {
    return this._index;
  }

  constructor(private elementRef: ElementRef,
              @Inject(EXT_INFINITE_SCROLL_DIMENSIONS) private dimensions: ExtInfiniteScrollDimensions) {
  }

  ngOnInit(): void {
    this.context = {
      $implicit: this.data,
      index: this._index,
    };
  }
}
