/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreMlsModule } from '@gh/core-mls';
import { S3UploadEngine } from './s3-upload-engine.service';
import { SingleUploaderComponent } from './single-uploader.component';
import { UploadContext } from './upload-context.service';
import { UploadMultiSlotDirective } from './upload-multi-slot.directive';
import { UploadSlotDirective } from './upload-slot.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,

    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,

    CoreMlsModule,
  ],
  declarations: [
    SingleUploaderComponent,
    UploadSlotDirective,
    UploadMultiSlotDirective,
  ],
  exports: [
    SingleUploaderComponent,
    UploadSlotDirective,
    UploadMultiSlotDirective,
  ],
})
export class CoreUiUploaderModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreUiUploaderModule,
      providers: [
        UploadContext,
        S3UploadEngine,
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return { ngModule: CoreUiUploaderModule };
  }
}
