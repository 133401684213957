var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { HttpHeaders, HttpParams, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNotNil, mergePath } from '@gh/core-util';
import { of$ } from '@gh/rx';
import { filter$, switchMap$ } from '@gh/rx/operators';
import * as invariant from 'invariant';
import { isNil, omitBy } from 'lodash';
import { mergeSortByParameter } from '../data-source';
import { createHttpData, DEFAULT_DESERIALIZATION_TYPE, DEFAULT_SERIALIZATION_TYPE, DummyHttpDataMapper, HttpDataMapper, NativeHeadersData, } from '../mappers';
import { HTTP_STATUS_NO_CONTENT, isResponseHttpEvent, RequestMethod } from './http.utils';
import { RestSettings } from './rest-settings.service';
import * as i0 from "@angular/core";
import * as i1 from "./rest-settings.service";
var CONTENT_RANGE_HEADER_RE = /^items\s+(((\d+)-(\d+))|(\*))\/(\d+)$/;
/**
 * Custom parameter encoder is necessary because Angular's default strategy is to allow '+'
 * and some other characters as parameter value. But '+' is treat as ' ' (space) character.
 *
 * Related issues:
 * https://github.com/angular/angular/issues/11058
 * https://github.com/mean-expert-official/loopback-sdk-builder/issues/573
 * https://github.com/angular/angular/issues/18261
 */
var NativeHttpParameterCodec = /** @class */ (function () {
    function NativeHttpParameterCodec() {
    }
    NativeHttpParameterCodec.prototype.encodeKey = function (k) {
        return encodeURIComponent(k);
    };
    NativeHttpParameterCodec.prototype.encodeValue = function (v) {
        return encodeURIComponent(v);
    };
    NativeHttpParameterCodec.prototype.decodeKey = function (k) {
        return decodeURIComponent(k);
    };
    NativeHttpParameterCodec.prototype.decodeValue = function (v) {
        return decodeURIComponent(v);
    };
    return NativeHttpParameterCodec;
}());
export { NativeHttpParameterCodec };
var NATIVE_HTTP_PARAMETER_CODEC = new NativeHttpParameterCodec();
/**
 * This method guarantees that {@link DataMapper} is always {@link HttpDataMapper}.
 * If given mapper is not an instance of ${link HttpDataMapper}
 * this method wraps it into the simplest {@link HttpDataMapper}.
 *
 * @param mapper mapper to be wrapped
 * @returns wrapped {@link HttpDataMapper}
 */
function wrapIntoHttpRequestMapper(mapper) {
    if (mapper instanceof HttpDataMapper) {
        return mapper;
    }
    else {
        return new DummyHttpDataMapper(mapper);
    }
}
/**
 * The same method as {@link #wrapIntoHttpRequestMapper}, but for response mapper
 * @param mapper
 */
function wrapIntoHttpResponseMapper(mapper) {
    if (mapper instanceof HttpDataMapper) {
        return mapper;
    }
    else {
        return new DummyHttpDataMapper(mapper);
    }
}
/**
 * Writes provided header data into angular {@link HttpHeaders} object.
 *
 * @param originalHeaders headers of Angular request
 * @param headersData headers to be written into Angular Headers
 */
function applyHttpHeaders(originalHeaders, headersData) {
    return headersData.keys().reduce(function (headers, key) {
        var value = headersData.get(key);
        return isNotNil(value) ? headers.set(key, value) : headers;
    }, originalHeaders);
}
/**
 * Basic class for all REST services.
 * It provides basic support of operations over HTTP protocol and integration with mappers.
 */
var AbstractRestService = /** @class */ (function () {
    function AbstractRestService(settings) {
        this.httpClient = settings.httpClient;
        this.baseUrl = settings.baseUrl;
    }
    /**
     * Rewrites provided url/path in order to add base URL for API endpoints.
     */
    AbstractRestService.prototype.url = function (path) {
        if (path.includes('://')) {
            return path;
        }
        else {
            return mergePath(this.baseUrl, path);
        }
    };
    /**
     * Performs HTTP request operation based on given config and using provided mappers (from config).
     */
    AbstractRestService.prototype.httpRequest = function (requestConfig) {
        var method = requestConfig.method, url = requestConfig.url, body = requestConfig.body, requestMapper = requestConfig.requestMapper, responseMapper = requestConfig.responseMapper;
        var httpRequestMapper = requestMapper ? wrapIntoHttpRequestMapper(requestMapper) : void 0;
        var httpResponseMapper = responseMapper ? wrapIntoHttpResponseMapper(responseMapper) : void 0;
        // create request object using provided mapper
        var request = this.createGenericRequest(method, url, body, httpRequestMapper, httpResponseMapper);
        // run request
        return this.httpClient.request(request).pipe(filter$(isResponseHttpEvent), switchMap$(function (response) {
            if (response.status === HTTP_STATUS_NO_CONTENT) {
                return of$(void 0);
            }
            // deserialize response using provided mapper, if any
            var serializedResponse = httpResponseMapper
                ? httpResponseMapper.deserialize(createHttpData(new NativeHeadersData(response.headers || new HttpHeaders()), response.body)) : response.body;
            return of$(serializedResponse);
        }));
    };
    AbstractRestService.prototype.httpGet = function (path, bodyOrResponseMapper, requestMapper, responseMapper) {
        return this.httpRequest({
            method: RequestMethod.Get,
            url: path,
            body: responseMapper ? bodyOrResponseMapper : void 0,
            requestMapper: requestMapper,
            responseMapper: responseMapper || bodyOrResponseMapper,
        });
    };
    AbstractRestService.prototype.httpPut = function (path, body, requestMapper, responseMapper) {
        return this.httpRequest({
            method: RequestMethod.Put,
            url: path,
            body: body,
            requestMapper: requestMapper,
            responseMapper: responseMapper || requestMapper,
        });
    };
    AbstractRestService.prototype.httpDelete = function (path, body, requestMapper, responseMapper) {
        return this.httpRequest({
            method: RequestMethod.Delete,
            url: path,
            body: body,
            requestMapper: requestMapper,
            responseMapper: responseMapper || requestMapper,
        });
    };
    AbstractRestService.prototype.httpPost = function (path, body, requestMapper, responseMapper) {
        return this.httpRequest({
            method: RequestMethod.Post,
            url: path,
            body: body,
            requestMapper: requestMapper,
            responseMapper: responseMapper || requestMapper,
        });
    };
    /**
     * Performs HTTP paginable request operation based on given config and using provided mappers (from config).
     * Paginable request differs from typical request in the following way
     *
     * * paginable request always has query.
     *             Query tells how to sort data and what slice of data should be retrieved from backend.
     *             Slice is defined by interval (from ... to)
     * * paginable request adds additional query parameter (sortBy) which defines how to sort data
     * * paginable request adds range header (Range) which defines slice of data that should be retrieved
     * * paginable request always returns instance of {@link SlicedData}
     */
    AbstractRestService.prototype.createSlicedDataRequest = function (config) {
        var method = config.method, url = config.url, query = config.query, body = config.body, requestMapper = config.requestMapper, responseMapper = config.responseMapper;
        var httpRequestMapper = requestMapper ? wrapIntoHttpRequestMapper(requestMapper) : void 0;
        var httpResponseMapper = responseMapper ? wrapIntoHttpResponseMapper(responseMapper) : void 0;
        // create request object using provided mapper
        var request = this.createGenericRequest(method, url, body, httpRequestMapper, httpResponseMapper);
        // add sorting as query parameter
        if (query.sorting) {
            request = request.clone({
                url: mergeSortByParameter(request.url, query.sorting),
            });
        }
        request = request.clone({
            headers: request.headers.set('Range', "items=" + query.from + "-" + Math.max(query.to - 1, 0)),
        });
        // run request
        return this.httpClient.request(request).pipe(filter$(isResponseHttpEvent), switchMap$(function (response) {
            var entities;
            // deserialize response using provided mapper, if any
            if (httpResponseMapper) {
                entities = httpResponseMapper.deserialize(createHttpData(new NativeHeadersData(response.headers || new Headers()), response.body));
            }
            else {
                entities = response.body;
            }
            // validate response
            invariant(Array.isArray(entities), "Response of '" + response.url + "' is not an array");
            var total = -1;
            if (response.headers) {
                var match = CONTENT_RANGE_HEADER_RE.exec(response.headers.get('Content-Range') || '');
                invariant(match && match[6], // tslint:disable-line:no-magic-numbers
                "Invalid Content-Range header for request '" + response.url + "': " + response.headers.get('Content-Range'));
                if (!match) {
                    throw new Error("Invalid Content-Range header for request '" + response.url + "': " + response.headers.get('Content-Range'));
                }
                // iError( (Number(match[4]) - Number(match[3]) + 1) === entities.length,
                //   `Invalid Content-Range header for request '${response.url}': ${response.headers.get('Content-Range')}`);
                total = Number(match[6]); // tslint:disable-line:no-magic-numbers
            }
            // pack result into SlicedData object
            return of$({
                from: query.from,
                to: query.to,
                total: total,
                data: entities,
            });
        }));
    };
    AbstractRestService.prototype.createSlicedDataSourceGet = function (path, bodyOrResponseMapper, requestMapper, responseMapper) {
        return this.createSlicedDataSource({
            method: RequestMethod.Get,
            url: path,
            body: responseMapper ? bodyOrResponseMapper : void 0,
            requestMapper: requestMapper,
            responseMapper: responseMapper || bodyOrResponseMapper,
        });
    };
    /**
     * Creates {@link SlicedDataSource} based on GET requests.
     *
     * This method allows to pass additional parameters common for each request.
     * Body is serialized using provided request mapper.
     *
     * Each request use provided mapper to deserialize its response.
     *
     * @param path relative url for endpoint
     * @param body entity to be serialized and send
     * @param requestMapper mapper for request entity
     * @param responseMapper mapper for response entity
     */
    AbstractRestService.prototype.createSlicedDataSourcePost = function (path, body, requestMapper, responseMapper) {
        return this.createSlicedDataSource({
            method: RequestMethod.Post,
            url: path,
            body: body,
            requestMapper: requestMapper,
            responseMapper: responseMapper,
        });
    };
    /**
     * Creates {@link SlicedDataSource} based on the provided config.
     * One instance of {@link SlicedDataSource} can be used to request data
     * for different queries ({@link SlicedDataQuery}).
     * Each performed query use configuration provided in the config parameter.
     * See {@link #createSlicedDataRequest} for details.
     */
    AbstractRestService.prototype.createSlicedDataSource = function (config) {
        var _this = this;
        var select = function (query) { return _this.createSlicedDataRequest(__assign(__assign({}, config), { query: query })); };
        return { select: select };
    };
    /**
     * Creates {@link Request} object for the given set of parameters.
     * Body is serialized using provided request mapper:
     *
     * * for GET request body is merged into url using querystring library
     * * for request methods body is always passed via body of HTTP request.
     *
     * @param method target HTTP method
     * @param path relative endpoint URL
     * @param body request entity
     * @param requestMapper mapper for mapping request entity into body
     * @param responseMapper mapper for mapping response entity into body
     * @returns request object
     */
    AbstractRestService.prototype.createGenericRequest = function (method, path, body, requestMapper, responseMapper) {
        var httpHeaders = this.createDefaulHeaders(requestMapper && requestMapper.serializationType);
        var serializedBody;
        if (body && requestMapper) {
            var httpData = requestMapper.serialize(body);
            httpHeaders = applyHttpHeaders(httpHeaders, httpData.headers);
            serializedBody = httpData.body;
        }
        else {
            serializedBody = body;
        }
        var httpParams = method === RequestMethod.Get && serializedBody ? new HttpParams({
            fromObject: omitBy(serializedBody, isNil),
            encoder: NATIVE_HTTP_PARAMETER_CODEC,
        }) : void 0;
        var httpBody = method === RequestMethod.Get ? void 0 : serializedBody;
        var responseType = responseMapper && responseMapper.deserializationType || DEFAULT_DESERIALIZATION_TYPE;
        return new HttpRequest(method, this.url(path), httpBody, {
            params: httpParams,
            headers: httpHeaders,
            responseType: responseType,
        });
    };
    AbstractRestService.prototype.createDefaulHeaders = function (type) {
        return new HttpHeaders({
            'Content-Type': type || DEFAULT_SERIALIZATION_TYPE,
        });
    };
    AbstractRestService.ɵfac = function AbstractRestService_Factory(t) { return new (t || AbstractRestService)(i0.ɵɵinject(i1.RestSettings)); };
    AbstractRestService.ɵprov = i0.ɵɵdefineInjectable({ token: AbstractRestService, factory: AbstractRestService.ɵfac });
    return AbstractRestService;
}());
export { AbstractRestService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AbstractRestService, [{
        type: Injectable
    }], function () { return [{ type: i1.RestSettings }]; }, null); })();
