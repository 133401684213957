/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { EXT_INFINITE_SCROLL_DIMENSIONS, EXT_INFINITE_SCROLL_VIEWPORT, } from '../ext-infinite-scroll.interfaces';
import * as i0 from "@angular/core";
var ExtInfiniteTableViewerDirective = /** @class */ (function () {
    function ExtInfiniteTableViewerDirective(elementRef, viewport) {
        this.elementRef = elementRef;
        this.viewport = viewport;
        this.offsetTop = 60;
        this.itemHeight = 50;
        this.loadThresholdCount = 10;
        this.pageSize = 50;
    }
    ExtInfiniteTableViewerDirective.prototype.ngOnInit = function () {
        this.viewport.setDimensions(this.elementRef.nativeElement, this);
    };
    ExtInfiniteTableViewerDirective.prototype.ngOnDestroy = function () {
        this.viewport.resetDimensions(this.elementRef.nativeElement, this);
    };
    ExtInfiniteTableViewerDirective.ɵfac = function ExtInfiniteTableViewerDirective_Factory(t) { return new (t || ExtInfiniteTableViewerDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(EXT_INFINITE_SCROLL_VIEWPORT)); };
    ExtInfiniteTableViewerDirective.ɵdir = i0.ɵɵdefineDirective({ type: ExtInfiniteTableViewerDirective, selectors: [["", "ghExtInfiniteTable", ""]], inputs: { offsetTop: "offsetTop", itemHeight: "itemHeight", loadThresholdCount: "loadThresholdCount", pageSize: "pageSize" }, features: [i0.ɵɵProvidersFeature([
                { provide: EXT_INFINITE_SCROLL_DIMENSIONS, useExisting: ExtInfiniteTableViewerDirective },
            ])] });
    return ExtInfiniteTableViewerDirective;
}());
export { ExtInfiniteTableViewerDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtInfiniteTableViewerDirective, [{
        type: Directive,
        args: [{
                selector: '[ghExtInfiniteTable]',
                providers: [
                    { provide: EXT_INFINITE_SCROLL_DIMENSIONS, useExisting: ExtInfiniteTableViewerDirective },
                ],
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: undefined, decorators: [{
                type: Inject,
                args: [EXT_INFINITE_SCROLL_VIEWPORT]
            }] }]; }, { offsetTop: [{
            type: Input
        }], itemHeight: [{
            type: Input
        }], loadThresholdCount: [{
            type: Input
        }], pageSize: [{
            type: Input
        }] }); })();
