/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isNil, isString, mapValues } from 'lodash';
import { getMergedState } from './action-util';
export var STEP_START = 'start';
export var STEP_PUSH_CONTEXT = '$pushContext';
export var STEP_POP_CONTEXT = '$popContext';
export var STEP_COMPLETE = '$complete';
export var STEP_FINISH = '@finish';
export function goToStep(step) {
    return function (dispatch, flowState) {
        var nextStep = isString(step) ? step : step(flowState);
        if (isNil(nextStep)) {
            throw new Error('Next step is undetermined');
        }
        dispatch(nextStep, flowState);
    };
}
export function logicalNoop() {
    return logicalAction('success', 'fail', function (dispatch, flowState) { return dispatch('success', flowState); });
}
export function constantNoop() {
    return constantAction('next', function (dispatch, flowState) { return dispatch('next', flowState); });
}
export function exitAction(name, merge) {
    return function (dispatch, state) {
        dispatch({ type: STEP_POP_CONTEXT, nextStep: name }, getMergedState(state, merge));
    };
}
export function exitFlow(merge) {
    return completeFlow(merge);
}
export function completeFlow(merge) {
    return exitAction(STEP_FINISH, merge);
}
export function throwError(message) {
    return function () {
        throw new Error(message);
    };
}
export function constantAction(actionName, action) {
    // tslint:disable-next-line:no-unnecessary-callback-wrapper
    var newAction = function (queueResolver, flowState) { return action(queueResolver, flowState); };
    newAction.bindAs = function (nextActionName) {
        var _a;
        return constantAction(nextActionName, mapActionSteps(action, (_a = {},
            _a[actionName] = nextActionName,
            _a)));
    };
    return newAction;
}
export function logicalAction(success, fail, action) {
    // tslint:disable-next-line:no-unnecessary-callback-wrapper
    var newAction = function (dispatch, flowState) { return action(dispatch, flowState); };
    newAction.bindAs = function (nextSuccess, nextFail) {
        var _a;
        return logicalAction(nextSuccess, nextFail, mapActionSteps(action, (_a = {},
            _a[success] = nextSuccess,
            _a[fail] = nextFail,
            _a)));
    };
    return newAction;
}
export function compoundAction(actions) {
    return function (dispatch, flowState) {
        dispatch({
            type: STEP_PUSH_CONTEXT,
            actions: actions,
        }, flowState);
    };
}
export function compoundLogicalAction(success, fail, actions) {
    return logicalAction(success, fail, compoundAction(actions));
}
export function compoundConstantAction(actionName, actions) {
    return constantAction(actionName, compoundAction(actions));
}
function mapActionSteps(action, nameMapping) {
    return function (dispatch, flowState) {
        dispatch({
            type: STEP_PUSH_CONTEXT,
            actions: __assign({ start: action }, mapValues(nameMapping, exitAction)),
        }, flowState);
    };
}
export function mapFlowStateAction(toNext, fromNext, decoratedAction) {
    var newAction = mapStateActionFactory(decoratedAction);
    var bindAs = decoratedAction['bindAs'];
    if (bindAs) {
        newAction['bindAs'] = function () {
            // we use anonymous function here instead of lambda, because we need to access arguments
            return mapStateActionFactory(bindAs.apply(void 0, arguments));
        };
    }
    return newAction;
    function mapStateActionFactory(action) {
        return function (dispatch, cState) {
            dispatch({
                type: STEP_PUSH_CONTEXT,
                actions: {
                    // call original action on start step
                    start: function (innterDispatch) { return action(innterDispatch, toNext(cState)); },
                    // exit to the requested step on any step (end step)
                    '*': function (innerDispatch, nState, step) {
                        return innerDispatch({ type: STEP_POP_CONTEXT, nextStep: step }, fromNext(cState, nState));
                    },
                },
            }, cState);
        };
    }
}
