/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { cloneDeep } from 'lodash';
import { DataMapper, JSONObject } from './data-mapper';

export class IdentityEntityMapper<Entity, ID> implements DataMapper<Entity, JSONObject> {
  constructor(private clone: boolean = true) {
  }

  serialize(obj: Entity): JSONObject {
    return this.clone ? cloneDeep(obj) : obj;
  }

  deserialize(json: JSONObject): Entity {
    return this.clone ? <Entity>cloneDeep(json) : json;
  }
}
