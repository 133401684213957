/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { range } from 'lodash';

export function digits(n: number): RegExp[] {
  return range(n).map(() => /\d/);
}

export function repeat(n: number, re: RegExp): RegExp[] {
  return range(n).map(() => re);
}
