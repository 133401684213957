/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateSiteComponent } from './components';
import { AutomationIDDirective, CaptureEventDirective, EllipsisDirective, EventStopPropagationDirective, FocusDirective, FocusOnInitDirective, InfiniteScrollDirective, LocalDirective, RendererDirective, } from './directives';
import { DefaultCurrencyPipe, HighlightPipe, HtmlWithUrlsPipe, InContextPipe, KeysPipe, LogPipe, Pluck$Pipe, SanitizeHtmlPipe, SanitizeUrlPipe, TextWithUrlsPipe, ToArrayPipe, } from './pipes';
import { Clipboard, ContentRulerFactory, DefaultUiErrorHandlerService, DndManager, UI_ERROR_HANDLER } from './services';
import * as i0 from "@angular/core";
var CoreUiCommonModule = /** @class */ (function () {
    function CoreUiCommonModule() {
    }
    CoreUiCommonModule.forRoot = function () {
        return {
            ngModule: CoreUiCommonModule,
            providers: [
                ContentRulerFactory,
                DndManager,
                DefaultUiErrorHandlerService,
                Clipboard,
                { provide: UI_ERROR_HANDLER, useExisting: DefaultUiErrorHandlerService },
            ],
        };
    };
    CoreUiCommonModule.forChild = function () {
        return { ngModule: CoreUiCommonModule };
    };
    CoreUiCommonModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreUiCommonModule });
    CoreUiCommonModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreUiCommonModule_Factory(t) { return new (t || CoreUiCommonModule)(); }, imports: [[
                CommonModule,
            ]] });
    return CoreUiCommonModule;
}());
export { CoreUiCommonModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreUiCommonModule, { declarations: [InfiniteScrollDirective,
        AutomationIDDirective,
        EllipsisDirective,
        FocusDirective,
        FocusOnInitDirective,
        CaptureEventDirective,
        EventStopPropagationDirective,
        RendererDirective,
        LocalDirective,
        SanitizeHtmlPipe,
        SanitizeUrlPipe,
        HighlightPipe,
        HtmlWithUrlsPipe,
        TextWithUrlsPipe,
        KeysPipe,
        ToArrayPipe,
        InContextPipe,
        Pluck$Pipe,
        DefaultCurrencyPipe,
        LogPipe,
        TemplateSiteComponent], imports: [CommonModule], exports: [InfiniteScrollDirective,
        AutomationIDDirective,
        EllipsisDirective,
        FocusDirective,
        FocusOnInitDirective,
        CaptureEventDirective,
        EventStopPropagationDirective,
        RendererDirective,
        LocalDirective,
        SanitizeHtmlPipe,
        SanitizeUrlPipe,
        HighlightPipe,
        HtmlWithUrlsPipe,
        TextWithUrlsPipe,
        KeysPipe,
        ToArrayPipe,
        InContextPipe,
        Pluck$Pipe,
        DefaultCurrencyPipe,
        LogPipe,
        TemplateSiteComponent] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreUiCommonModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                ],
                declarations: [
                    InfiniteScrollDirective,
                    AutomationIDDirective,
                    EllipsisDirective,
                    FocusDirective,
                    FocusOnInitDirective,
                    CaptureEventDirective,
                    EventStopPropagationDirective,
                    RendererDirective,
                    LocalDirective,
                    SanitizeHtmlPipe,
                    SanitizeUrlPipe,
                    HighlightPipe,
                    HtmlWithUrlsPipe,
                    TextWithUrlsPipe,
                    KeysPipe,
                    ToArrayPipe,
                    InContextPipe,
                    Pluck$Pipe,
                    DefaultCurrencyPipe,
                    LogPipe,
                    TemplateSiteComponent,
                ],
                entryComponents: [
                    TemplateSiteComponent,
                ],
                exports: [
                    InfiniteScrollDirective,
                    AutomationIDDirective,
                    EllipsisDirective,
                    FocusDirective,
                    FocusOnInitDirective,
                    CaptureEventDirective,
                    EventStopPropagationDirective,
                    RendererDirective,
                    LocalDirective,
                    SanitizeHtmlPipe,
                    SanitizeUrlPipe,
                    HighlightPipe,
                    HtmlWithUrlsPipe,
                    TextWithUrlsPipe,
                    KeysPipe,
                    ToArrayPipe,
                    InContextPipe,
                    Pluck$Pipe,
                    DefaultCurrencyPipe,
                    LogPipe,
                    TemplateSiteComponent,
                ],
            }]
    }], null, null); })();
