/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { RouterStateSnapshot } from '@angular/router';
import { isFunction } from 'lodash';
import { Observable } from 'rxjs';

export const IS_VIEW_DIRTY_METHOD = 'isViewDirty';

export interface IsViewDirty {
  isViewDirty?(): boolean;
}

export interface CanLeaveDialog extends IsViewDirty {
  canDeactivate?(): Observable<boolean> | Promise<boolean> | boolean;
}

export interface CanLeave extends IsViewDirty {
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}

export interface CanLeaveRoute extends IsViewDirty {
  canDeactivate(nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean;
}

export function isViewDirty(component: any): boolean {
  const isViewDirtyMethod = component[IS_VIEW_DIRTY_METHOD];
  return isFunction(isViewDirtyMethod) ? isViewDirtyMethod.call(component) : false;
}
