var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { AuthProvider, AuthState } from '@gh/core-auth';
import { isFunction } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { KeycloakService } from './keycloak.service';
import * as i0 from "@angular/core";
import * as i1 from "./keycloak.service";
export var KEYCLOAK_LOGIN_OPTIONS = new InjectionToken('KeycloakLoginOptions');
export var KEYCLOAK_LOGOUT_OPTIONS = new InjectionToken('KeycloakLogoutOptions');
var KeycloakAuthProvider = /** @class */ (function (_super) {
    __extends(KeycloakAuthProvider, _super);
    function KeycloakAuthProvider(keycloak, loginOptions, 
    // tslint:disable-next-line:max-line-length
    logoutOptions) {
        var _this = _super.call(this) || this;
        _this.keycloak = keycloak;
        _this.loginOptions = loginOptions;
        _this.logoutOptions = logoutOptions;
        _this._authState = new ReplaySubject(1);
        _this._currentAuthState = AuthState.Unknown;
        keycloak.init().then(function (authenticated) {
            _this.bindAuthState();
            if (authenticated) {
                _this._authState.next(AuthState.LoggedIn);
                return keycloak.getToken();
            }
            _this._authState.next(AuthState.Unknown);
            return '';
        });
        return _this;
    }
    KeycloakAuthProvider.prototype.login = function () {
        return this.keycloak.login(this.loginOptions);
    };
    KeycloakAuthProvider.prototype.logout = function () {
        var logoutOptions = this.logoutOptions;
        return this.keycloak.logout(isFunction(logoutOptions) ? logoutOptions() : logoutOptions);
    };
    Object.defineProperty(KeycloakAuthProvider.prototype, "authState", {
        get: function () {
            return this._currentAuthState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KeycloakAuthProvider.prototype, "onAuthState", {
        get: function () {
            return this._authState;
        },
        enumerable: true,
        configurable: true
    });
    KeycloakAuthProvider.prototype.bindAuthState = function () {
        var _this = this;
        this.keycloak.onAuthSuccess.subscribe(function () { return _this._authState.next(AuthState.LoggedIn); });
        this.keycloak.onAuthError.subscribe(function () { return _this._authState.next(AuthState.LogggedOut); });
        this.keycloak.onAuthLogout.subscribe(function () { return _this._authState.next(AuthState.LogggedOut); });
        this.keycloak.onAuthRefreshSuccess.subscribe(function () { return _this._authState.next(AuthState.LoggedIn); });
        this.keycloak.onAuthRefreshError.subscribe(function () { return _this._authState.next(AuthState.Expired); });
        this._authState.subscribe(function (authState) {
            _this._currentAuthState = authState;
        });
    };
    KeycloakAuthProvider.ɵfac = function KeycloakAuthProvider_Factory(t) { return new (t || KeycloakAuthProvider)(i0.ɵɵinject(i1.KeycloakService), i0.ɵɵinject(KEYCLOAK_LOGIN_OPTIONS, 8), i0.ɵɵinject(KEYCLOAK_LOGOUT_OPTIONS, 8)); };
    KeycloakAuthProvider.ɵprov = i0.ɵɵdefineInjectable({ token: KeycloakAuthProvider, factory: KeycloakAuthProvider.ɵfac });
    return KeycloakAuthProvider;
}(AuthProvider));
export { KeycloakAuthProvider };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(KeycloakAuthProvider, [{
        type: Injectable
    }], function () { return [{ type: i1.KeycloakService }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [KEYCLOAK_LOGIN_OPTIONS]
            }] }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [KEYCLOAK_LOGOUT_OPTIONS]
            }] }]; }, null); })();
