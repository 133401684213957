/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:max-line-length

import * as gen from './environment.gen';

export const NODE_ENV = gen.NODE_ENV;

export const KEYCLOAK_JS_URL = gen.KEYCLOAK_JS_URL;
export const KEYCLOAK_JSON_URL = gen.KEYCLOAK_JSON_URL;
export const KEYCLOAK_AUTH_SERVER_URL = gen.KEYCLOAK_AUTH_SERVER_URL;
export const KEYCLOAK_REALM = gen.KEYCLOAK_REALM;
export const KEYCLOAK_RESOURCE = gen.KEYCLOAK_RESOURCE;
export const KEYCLOAK_SECRET = gen.KEYCLOAK_SECRET;
export const KEYCLOAK_MIN_VALIDITY = gen.KEYCLOAK_MIN_VALIDITY;

export const API_BASE_URL = gen.API_BASE_URL;
export const API_SB_URL = gen.API_SB_URL;
export const ENV_BASE_URL = gen.ENV_BASE_URL;

export const CONTEXT_PATH = gen.CONTEXT_PATH;

export const VERSION = gen.VERSION;
export const RELEASE = gen.RELEASE;
export const ENVIRONMENT = gen.ENVIRONMENT;
export const APP_NAME = gen.APP_NAME;
export const APPLICATION = gen.APPLICATION;
export const BACKEND = gen.BACKEND;
export const REGION_CODE = gen.REGION_CODE;

export const DEFAULT_LOCALE = gen.DEFAULT_LOCALE;
export const DEFAULT_EMAIL_SUFFIX = gen.DEFAULT_EMAIL_SUFFIX;
export const DEFAULT_COUNTRY_CODE = gen.DEFAULT_COUNTRY_CODE;

export const IGNORE_DIRTY_CHANGES = gen.IGNORE_DIRTY_CHANGES;
export const ANGULAR_PROD_MODE = gen.ANGULAR_PROD_MODE;

export const GOOGLE_CLIENT_ID = gen.GOOGLE_CLIENT_ID;
export const GOOGLE_DEFAULT_SCOPES = gen.GOOGLE_DEFAULT_SCOPES;
export const GOOGLE_UX_MODE = gen.GOOGLE_UX_MODE;
export const GOOGLE_HOSTED_DOMAIN = gen.GOOGLE_HOSTED_DOMAIN;
export const GOOGLE_ANALYTICS_ID = gen.GOOGLE_ANALYTICS_ID;

export const SENTRY_ON = gen.SENTRY_ON;
export const SENTRY_DSN = gen.SENTRY_DSN;

export const HELP_URL = gen.HELP_URL;

export const TODO_CHECK_ENABLED = gen.TODO_CHECK_ENABLED;
export const TODO_CHECK_INTERVAL = gen.TODO_CHECK_INTERVAL;

export const MAINTENANCE_CHECK_ENABLED = gen.MAINTENANCE_CHECK_ENABLED;
export const MAINTENANCE_CHECK_INTERVAL = gen.MAINTENANCE_CHECK_INTERVAL;

export const PRODUCE_STATUS_CHECK_INTERVAL = gen.PRODUCE_STATUS_CHECK_INTERVAL;

export const DEFAULT_CURRENCY_TEMPLATE = gen.DEFAULT_CURRENCY_TEMPLATE;

export const MOCK_AUTH_MODE_PREDEFINED_USERS = gen.MOCK_AUTH_MODE_PREDEFINED_USERS;

export const LOCALES_CONTEXT_FILTER = gen.LOCALES_CONTEXT_FILTER;

export const AUTOMATION_GRID = gen.AUTOMATION_GRID;

export const SCAYT_SERVICE_ID = gen.SCAYT_SERVICE_ID;

export const EXPERIMENTS_DEV_MODULE = gen.EXPERIMENTS_DEV_MODULE;
export const MOCK_BACKEND_DEV_MODULE = gen.MOCK_BACKEND_DEV_MODULE;

export const EXTERNAL_GOOGLE_PLATFORM_JS_LIBRARY_URL = 'https://apis.google.com/js/platform.js';
export const EXTERNAL_GOOGLE_CHARTS_JS_LIBRARY_URL = 'https://www.gstatic.com/charts/loader.js';
export const EXTERNAL_EVERSIGN_EMBEDDED_JS_LIBRARY_URL = 'https://s3.amazonaws.com/eversign-embedded-js-library/eversign.embedded.latest.js';

export const HELP_DEV_MODE = gen.HELP_DEV_MODE;

export const KEYCLOAK_AUTH_MODE: string = 'keycloak';
export const KEYCLOAK_WITH_MOCK_AUTH_MODE: string = 'keycloak-with-mock';
export const MOCK_AUTH_MODE: string = 'mock';

export const AUTH_MODE = gen.AUTH_MODE;
