/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Message, MessageBasket, MessageSeverity } from '@gh/core-messages';
import { TranslationParams } from '@gh/core-mls';
import { logError } from '@gh/core-util';
import { Observable } from 'rxjs';

/**
 * This service allows to manage balloons shown in the overlay on top of the screen.
 */
export abstract class NotificationService {
  protected constructor() {
    this.processAll = this.processAll.bind(this);
  }

  /**
   * Shows neutral notification with the given message.
   * Click on notification closes this notification and opens success dialog.
   * Notification is automatically closed in 5 seconds.
   *
   * @param id
   * @param params
   * @returns {NotificationRef}
   */
  abstract success(id: string, params?: TranslationParams): NotificationRef;

  /**
   * Shows warning notification with the given message.
   * Click on notification closes this notification and opens warning dialog.
   *
   * @param id
   * @param params
   * @returns {NotificationRef}
   */
  abstract warning(id: string, params?: TranslationParams): NotificationRef;

  /**
   * Shows error notification with the given message.
   * Click on notification opens error dialog.
   *
   * @param id
   * @param params
   * @returns {NotificationRef}
   */
  abstract error(id: string, params?: TranslationParams): NotificationRef;

  /**
   * Shows notification depending on message severity
   */
  message(message: Message): NotificationRef {
    let ref;

    switch (message.severity) {
      case MessageSeverity.Error:
        ref = this.error(message.key, message.params);
        break;
      case MessageSeverity.Info:
        ref = this.success(message.key, message.params);
        break;
      case MessageSeverity.Success:
        ref = this.success(message.key, message.params);
        break;
      case MessageSeverity.Warning:
        ref = this.warning(message.key, message.params);
        break;
      default:
        throw new Error(`Unknown severity value: ${message.severity}`);
    }

    return ref;
  }

  /**
   * This method allows to show any notification message.
   * Unlike other methods this method does not open dialog on click.
   * Any behaviour should be attached manually using instance of {@link NotificationRef}.
   *
   * @param config
   * @returns {NotificationRef}
   */
  abstract show(config: NotificationConfig): NotificationRef;

  /**
   * Closes all openeds notifications
   */
  abstract closeAll(): void;

  /**
   * This method processes message(s) and show notifications if necessary.
   *
   * If parameter is an instance of {@link MessageBasket} this method shows notification for each message.
   * if parameter is an instance of {@link Message} this method show notification.
   * If parameter is an error method throws this error. This method should not hide errors.
   * If parameter is neither {@link MessageBasket} nor {@link Error} method throws an error.
   *
   * @param param
   */
  processAll(param: Message | MessageBasket | Error): void {
    if (param instanceof MessageBasket) {
      param.messages.forEach((message) => this.message({
        ...message,
        key: /^msg_/.test(message.key) ? message.key : `msg_${message.key}`,
      }));
    } else if (param instanceof Message) {
      this.message(param);
    } else if (param instanceof Error) {
      logError(<any>param);
      throw param;
    } else {
      logError('Unknown kind of error: ', param);
      throw new Error(`Unknown kind of error [${param}]`);
    }
  }

}

/**
 * This class allows to manage notification lifecyle and handle notification events (clicks, afterClosed).
 *
 * This is a wrapper over internal notification object.
 */
export interface NotificationRef {

  /**
   * Stream of clicks occurred on notification component
   *
   * @returns {Observable<MouseEvent>}
   */
  readonly clicks: Observable<void>;

  /**
   * Stream that is emits strongly one value when notification is closed.
   * @returns {Observable<any>}
   */
  readonly afterClosed: Observable<any>;

  /**
   * Closed this notification
   */
  close(): void;
}

/**
 * Options of notification object provided during creation
 */
export interface NotificationConfig {
  severity: MessageSeverity;
  messageId: string;
  messageParams?: TranslationParams;
  hideDelay?: number;
}
