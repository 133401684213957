var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { AbstractRestService, RestSettings } from '@gh/core-data';
import { FinancialRecordMapper } from './financial-record.mapper';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-data";
var CustomerFinancialRecordRestService = /** @class */ (function (_super) {
    __extends(CustomerFinancialRecordRestService, _super);
    function CustomerFinancialRecordRestService(settings) {
        var _this = _super.call(this, settings) || this;
        _this.settings = settings;
        return _this;
    }
    CustomerFinancialRecordRestService.prototype.get = function (jobId) {
        return this.httpGet("/jobs/" + jobId + "/financial-records", FinancialRecordMapper);
    };
    CustomerFinancialRecordRestService.ɵfac = function CustomerFinancialRecordRestService_Factory(t) { return new (t || CustomerFinancialRecordRestService)(i0.ɵɵinject(i1.RestSettings)); };
    CustomerFinancialRecordRestService.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerFinancialRecordRestService, factory: CustomerFinancialRecordRestService.ɵfac });
    return CustomerFinancialRecordRestService;
}(AbstractRestService));
export { CustomerFinancialRecordRestService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerFinancialRecordRestService, [{
        type: Injectable
    }], function () { return [{ type: i1.RestSettings }]; }, null); })();
