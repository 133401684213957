/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AppRouter, getInheritedRouteParam, getRoutePath } from '@gh/common-shared';
import { CustomerUserContext } from '@gh/customer-shared';

@Injectable()
export class CustomerJobActivator implements CanActivate {
  constructor(private userContext: CustomerUserContext,
              private router: AppRouter) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // if user context is not initialized (in case of error on login) => forbid to open any page
    if (!this.userContext.isInitialized()) {
      return false;
    } else if (getRoutePath(route) === '') {
      // if root url => navigate to the first job
      if (this.userContext.jobs.length > 0) {
        this.router.navigateByUrl(`/jobs/${this.userContext.currentJob.jobId}/shared-docs`);
        return false;
      } else {
        return true;
      }
    } else if (route.data.isAcceptInvitation) {
      // if acceptInvitation => navigate to the first job
      if (this.userContext.jobs.length > 0) {
        this.router.navigateByUrl(`/jobs/${this.userContext.currentJob.jobId}/shared-docs`);
      } else {
        this.router.navigateByUrl('');
      }
      return false;
    } else {
      const jobId = getInheritedRouteParam(route, 'jobId');
      if (jobId) {
        if (this.userContext.isJobExist(jobId)) {
          this.userContext.setCurrentJob(jobId);
          return true;
        } else {
          this.router.navigateByUrl('/404');
          return false;
        }
      } else {
        return true;
      }
    }
  }

}
