/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { findPermission, Permission } from '@gh/core-auth';
import * as invariant from 'invariant';
import { isString } from 'lodash';
import { UserContext } from '../user/index';

@Pipe({
  name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private userContext: UserContext) {
  }

  transform(permission: any): boolean {
    if (isString(permission)) {
      permission = findPermission(permission);
    }

    invariant(permission instanceof Permission, `Permission '${permission}' is not an instance of Permission`);

    return this.userContext.hasPermission(permission);
  }
}
