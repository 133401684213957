var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '@gh/core-mls';
import { ConfirmationDialogBaseComponent } from '@gh/core-ui';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../ext-dialog/ext-dialog.component";
import * as i4 from "../ext-dialog/ext-dialog-content.component";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "../ext-dialog/ext-dialog-button-bar.component";
import * as i7 from "@angular/common";
import * as i8 from "@angular/material/button";
import * as i9 from "../../../core-ui/common/directives/focus-on-init.directive";
import * as i10 from "../../../core-mls/translation/translate.pipe";
function ExtConfirmationDialogComponent_button_7_Template(rf, ctx) { if (rf & 1) {
    var _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 8);
    i0.ɵɵlistener("click", function ExtConfirmationDialogComponent_button_7_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r3); var ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.onCancel(); });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ghFocusOnInit", ctx_r0.defaultCancelButton);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 2, "lbl_cancel"));
} }
function ExtConfirmationDialogComponent_button_10_Template(rf, ctx) { if (rf & 1) {
    var _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function ExtConfirmationDialogComponent_button_10_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r5); var ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.onNo(); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ghFocusOnInit", ctx_r1.defaultNoButton);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r1.noLabel);
} }
var ExtConfirmationDialogComponent = /** @class */ (function (_super) {
    __extends(ExtConfirmationDialogComponent, _super);
    function ExtConfirmationDialogComponent(translationService, dialogRef, elementRef, renderer) {
        return _super.call(this, translationService, dialogRef, elementRef, renderer) || this;
    }
    ExtConfirmationDialogComponent.ɵfac = function ExtConfirmationDialogComponent_Factory(t) { return new (t || ExtConfirmationDialogComponent)(i0.ɵɵdirectiveInject(i1.TranslationService), i0.ɵɵdirectiveInject(i2.MatDialogRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2)); };
    ExtConfirmationDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtConfirmationDialogComponent, selectors: [["gh-ext-confirmation-dialog"]], features: [i0.ɵɵInheritDefinitionFeature], decls: 11, vars: 5, consts: [["closable", "", 3, "close"], ["fxLayout", "column", "fxLayoutAlign", "start center"], [1, "m-question"], [1, "m-message"], [1, "button-bar", "clearfix"], ["color", "primary", "mat-button", "", "class", "m-cancel-btn large ext-to-left", "data-automation-id", "confirmation-dialog:cancel", 3, "ghFocusOnInit", "click", 4, "ngIf"], ["mat-raised-button", "", "color", "primary", "data-automation-id", "confirmation-dialog:yes", 1, "large", "ext-to-right", "m-yes-btn", 3, "ghFocusOnInit", "click"], ["mat-button", "", "color", "accent", "class", "m-no-btn ext-to-right large", "data-automation-id", "confirmation-dialog:no", 3, "ghFocusOnInit", "click", 4, "ngIf"], ["color", "primary", "mat-button", "", "data-automation-id", "confirmation-dialog:cancel", 1, "m-cancel-btn", "large", "ext-to-left", 3, "ghFocusOnInit", "click"], ["mat-button", "", "color", "accent", "data-automation-id", "confirmation-dialog:no", 1, "m-no-btn", "ext-to-right", "large", 3, "ghFocusOnInit", "click"]], template: function ExtConfirmationDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "gh-ext-dialog", 0);
            i0.ɵɵlistener("close", function ExtConfirmationDialogComponent_Template_gh_ext_dialog_close_0_listener() { return ctx.onClose(); });
            i0.ɵɵelementStart(1, "gh-ext-dialog-content", 1);
            i0.ɵɵelementStart(2, "div", 2);
            i0.ɵɵtext(3, "?");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "span", 3);
            i0.ɵɵtext(5);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(6, "gh-ext-dialog-button-bar", 4);
            i0.ɵɵtemplate(7, ExtConfirmationDialogComponent_button_7_Template, 3, 4, "button", 5);
            i0.ɵɵelementStart(8, "button", 6);
            i0.ɵɵlistener("click", function ExtConfirmationDialogComponent_Template_button_click_8_listener() { return ctx.onYes(); });
            i0.ɵɵtext(9);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(10, ExtConfirmationDialogComponent_button_10_Template, 2, 2, "button", 7);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.message);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.cancelButton == true);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ghFocusOnInit", ctx.defaultYesButton);
            i0.ɵɵadvance(1);
            i0.ɵɵtextInterpolate1("", ctx.yesLabel, " ");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.noButton == true);
        } }, directives: [i3.ExtDialogComponent, i4.ExtDialogContentComponent, i5.DefaultLayoutDirective, i5.DefaultLayoutAlignDirective, i6.ExtDialogButtonBarComponent, i7.NgIf, i8.MatButton, i9.FocusOnInitDirective], pipes: [i10.TranslatePipe], styles: ["[_nghost-%COMP%] {\n  overflow: hidden;\n  display: flex;\n  flex-direction: column; }\n  @media (max-width: 959px) {\n    [_nghost-%COMP%] {\n      width: 100vw;\n      min-height: 100vh; } }\n  @media (min-width: 960px) {\n    [_nghost-%COMP%] {\n      min-width: 650px;\n      min-height: auto; } }\n\n.m-question[_ngcontent-%COMP%] {\n  box-sizing: border-box;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 12px solid rgba(112, 112, 112, 0.54);\n  color: rgba(112, 112, 112, 0.54);\n  text-align: center;\n  font-size: 120px;\n  font-weight: bold; }\n\n@media (min-width: 960px) {\n  .m-question[_ngcontent-%COMP%] {\n    width: 180px;\n    height: 180px;\n    border-radius: 90px;\n    margin: 70px 0; } }\n\n@media (max-width: 959px) {\n  .m-question[_ngcontent-%COMP%] {\n    width: 150px;\n    height: 150px;\n    border-radius: 75px;\n    margin: 40px 0; } }\n\n.m-message[_ngcontent-%COMP%] {\n  padding: 0 30px;\n  margin-bottom: 75px;\n  text-align: center;\n  font-size: 24px;\n  font-weight: bold; }\n\n@media (max-height: 500px) {\n  gh-ext-dialog-content[_ngcontent-%COMP%] {\n    flex: 1; }\n  .m-question[_ngcontent-%COMP%] {\n    margin: 15px 0 0; }\n  .m-message[_ngcontent-%COMP%] {\n    margin-bottom: 15px; } }"] });
    return ExtConfirmationDialogComponent;
}(ConfirmationDialogBaseComponent));
export { ExtConfirmationDialogComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtConfirmationDialogComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-confirmation-dialog',
                templateUrl: 'ext-confirmation-dialog.component.html',
                styleUrls: ['ext-confirmation-dialog.component.scss'],
            }]
    }], function () { return [{ type: i1.TranslationService }, { type: i2.MatDialogRef }, { type: i0.ElementRef }, { type: i0.Renderer2 }]; }, null); })();
