/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers
import { MaskedTextTypeDefenition } from '@gh/core-type';
import { digits } from '../mask-utils';

export const MASKED_EN_AU: Hash<MaskedTextTypeDefenition> = {
  Zip: {
    constraints: {
      pattern: /^[0-9]{4}$/,
    },
    mask: digits(4),
  },
  Acn: {
    constraints: {
      pattern: /^[0-9]{3} [0-9]{3} [0-9]{3}$/,
    },
    mask: [...digits(3), ' ', ...digits(3), ' ', ...digits(3)],
  },
  Abn: {
    constraints: {
      pattern: /^[0-9]{2} [0-9]{3} [0-9]{3} [0-9]{3}$/,
    },
    mask: [...digits(2), ' ', ...digits(3), ' ', ...digits(3), ' ', ...digits(3)],
  },
  BsbNumber: {
    constraints: {
      pattern: /^\d{6}$/,
    },
    mask: digits(6),
  },
  PhoneNumber: {
    constraints: {
      pattern: /^\d{20}$/,
    },
    mask: ['+', ...digits(20)],
  },
};
