/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../ext-preloader/ext-preloader.component";
import * as i2 from "../../../core-ui/waiting/wait.directive";
import * as i3 from "@angular/common";
import * as i4 from "../ext-no-data/ext-no-data.component";
import * as i5 from "../../../core-mls/translation/translate.pipe";
function ExtLoadTrackerComponent_gh_ext_no_data_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "gh-ext-no-data", 3);
    i0.ɵɵpipe(1, "translate");
} if (rf & 2) {
    i0.ɵɵpropertyInterpolate("message", i0.ɵɵpipeBind1(1, 2, "lbl_no_entries"));
    i0.ɵɵproperty("forceIcon", true);
} }
var ExtLoadTrackerComponent = /** @class */ (function () {
    function ExtLoadTrackerComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.isForegroundLoading = false;
        this.isBackgroundLoading = false;
        this.isNoData = false;
        this.hasContent = false;
    }
    Object.defineProperty(ExtLoadTrackerComponent.prototype, "noData", {
        get: function () {
            return this.isNoData;
        },
        set: function (noData) {
            this.isNoData = coerceBooleanProperty(noData);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtLoadTrackerComponent.prototype, "isHidden", {
        get: function () {
            return !(this.isForegroundLoading || this.isNoData);
        },
        enumerable: true,
        configurable: true
    });
    ExtLoadTrackerComponent.prototype.reset = function () {
        this.isNoData = false;
        this.hasContent = false;
    };
    ExtLoadTrackerComponent.prototype.start = function () {
        if (this.hasContent) {
            this.isBackgroundLoading = true;
        }
        else {
            this.isForegroundLoading = true;
        }
        this.isNoData = false;
        this.changeDetectorRef.markForCheck();
    };
    ExtLoadTrackerComponent.prototype.stop = function (noData) {
        if (noData === void 0) { noData = false; }
        this.isForegroundLoading = false;
        this.isBackgroundLoading = false;
        this.isNoData = noData;
        this.hasContent = !noData;
        this.changeDetectorRef.markForCheck();
    };
    ExtLoadTrackerComponent.ɵfac = function ExtLoadTrackerComponent_Factory(t) { return new (t || ExtLoadTrackerComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
    ExtLoadTrackerComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtLoadTrackerComponent, selectors: [["gh-ext-load-tracker"]], hostVars: 2, hostBindings: function ExtLoadTrackerComponent_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵclassProp("m-hidden", ctx.isHidden);
        } }, inputs: { noData: "noData" }, exportAs: ["ghExtLoadTracker"], decls: 3, vars: 4, consts: [[1, "m-preloader", 3, "size", "loading"], ["mode", "background", 3, "show"], [3, "forceIcon", "message", 4, "ngIf"], [3, "forceIcon", "message"]], template: function ExtLoadTrackerComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelement(0, "gh-ext-preloader", 0);
            i0.ɵɵelement(1, "gh-wait", 1);
            i0.ɵɵtemplate(2, ExtLoadTrackerComponent_gh_ext_no_data_2_Template, 2, 4, "gh-ext-no-data", 2);
        } if (rf & 2) {
            i0.ɵɵproperty("size", 120)("loading", ctx.isForegroundLoading);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("show", ctx.isBackgroundLoading);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.isNoData);
        } }, directives: [i1.ExtPreloaderComponent, i2.WaitDirective, i3.NgIf, i4.ExtNoDataComponent], pipes: [i5.TranslatePipe], styles: ["[_nghost-%COMP%] {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center; }\n\n.m-hidden[_nghost-%COMP%] {\n  display: none; }\n\n  .load-tracker-container {\n  position: relative; }"], changeDetection: 0 });
    return ExtLoadTrackerComponent;
}());
export { ExtLoadTrackerComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtLoadTrackerComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-load-tracker',
                templateUrl: 'ext-load-tracker.component.html',
                styleUrls: ['ext-load-tracker.component.scss'],
                changeDetection: ChangeDetectionStrategy.OnPush,
                exportAs: 'ghExtLoadTracker',
            }]
    }], function () { return [{ type: i0.ChangeDetectorRef }]; }, { noData: [{
            type: Input
        }], isHidden: [{
            type: HostBinding,
            args: ['class.m-hidden']
        }] }); })();
