/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { digits } from '../mask-utils';
export var MASKED_EN_US = {
    Zip: {
        constraints: {
            pattern: /^[0-9]{5}(-[0-9]{4})?$/,
        },
        mask: function (value, config) {
            var mask = digits(5);
            value = value.replace(new RegExp(config.placeholderChar + '|-', 'g'), '');
            if (value.length > 5) {
                mask.push.apply(mask, __spreadArrays(['-'], digits(4)));
            }
            return mask;
        },
    },
    TaxId: {
        constraints: {
            pattern: /^[0-9]{2}-[0-9]{7}$/,
        },
        mask: __spreadArrays(digits(2), ['-'], digits(7)),
    },
    SocialNumber: {
        constraints: {
            pattern: /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/,
        },
        mask: __spreadArrays(digits(3), ['-'], digits(2), ['-'], digits(4)),
    },
    TaxNumber: {
        constraints: {
            pattern: /^[0-9]{2}-[0-9]{7}$/,
        },
        mask: __spreadArrays(digits(2), ['-'], digits(7)),
    },
    BsbNumber: {
        constraints: {
            pattern: /^\d{9}$/,
        },
        mask: digits(9),
    },
    PhoneNumber: {
        constraints: {
            pattern: /^\d{20}$/,
        },
        mask: __spreadArrays(['+'], digits(20)),
    },
};
