/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CoreMlsModule } from '@gh/core-mls';
import { CoreUiCommonModule } from '../common';
import {
  DIALOG_HOOKS,
  DIALOG_STANDARD_SET,
  DialogHooks,
  DialogService,
  DialogServiceState,
  DialogStandardSet,
} from './services/dialog.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface CoreUiDialogsModuleConfig {
  dialogHooks?: Type<DialogHooks>;
  standardSet: DialogStandardSet;
}

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatTooltipModule,

    CoreMlsModule,
    CoreUiCommonModule,
  ],
})
export class CoreUiDialogsModule {
  static forRoot(config: CoreUiDialogsModuleConfig): ModuleWithProviders {
    return {
      ngModule: CoreUiDialogsModule,
      providers: [
        config.dialogHooks ? [{ provide: DIALOG_HOOKS, useExisting: config.dialogHooks }] : [],
        config.standardSet ? [{ provide: DIALOG_STANDARD_SET, useValue: config.standardSet }] : [],

        DialogServiceState,
        DialogService,
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: CoreUiDialogsModule,
      providers: [
        // each lazy routed module has own instance of DialogService
        // this is necessary, because dialog service requests other services directly via Injector
        DialogService,
      ],
    };
  }
}
