/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AuthProvider } from '@gh/core-auth';

export abstract class MockAuthProvider extends AuthProvider {
  readonly userKey: string;
}
