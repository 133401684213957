/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { of$, throwError$ } from '@gh/rx';
import { Observable } from 'rxjs';
import { Dictionary } from './dictionary';

@Injectable()
export class DictionaryContext {
  cached: Hash<Dictionary<any>> = {};

  get(code: string): Observable<Dictionary<any>> {
    if (this.cached[code]) {
      return of$(this.cached[code]);
    } else {
      return throwError$(`Enum is not defined for code ${code}`);
    }
  }

  put(code: string, dictionary: Dictionary<any>): void {
    this.cached[code] = dictionary;
  }
}
