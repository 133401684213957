/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CONTEXT_PATH } from '@gh/config';

export const TYPING_DELAY = 300;

export const ICONS_URL = CONTEXT_PATH + 'icons.svg';

export const DEFAULT_AUTOSAVE_INTERVALS = ['5m', '10m', '15m', '30m', '40m', '60m'];
