/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreAuthModule, PermissionNameSetProvider } from '@gh/core-auth';
import { MockAuthHttpRequestInterceptor } from './mock-auth-http-request-interceptor';
import { MockAuthProvider } from './mock-auth-provider.service';
import { MockLoginDialogComponent, PRIMARY_MOCK_OPTIONS, PrimaryMockAuthProvider, PrimaryMockOptions } from './primary';
import { SecondaryMockAuthProvider } from './secondary/secondary-mock-auth-provider.service';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    CdkTableModule,

    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatTableModule,

    CoreAuthModule.forRoot({
      authProvider: PrimaryMockAuthProvider,
      permissionProvider: PermissionNameSetProvider,
      authHttpInterceptor: MockAuthHttpRequestInterceptor,
    }),
  ],
  providers: [
    PrimaryMockAuthProvider,
    { provide: MockAuthProvider, useExisting: PrimaryMockAuthProvider },
    MockAuthHttpRequestInterceptor,
  ],
  declarations: [MockLoginDialogComponent],
  entryComponents: [MockLoginDialogComponent],
  exports: [CoreAuthModule],
})
export class CorePrimaryAuthMockModule {
}

@NgModule({
  imports: [
    CoreAuthModule.decorateBy({
      authProvider: SecondaryMockAuthProvider,
      authHttpRequestInterceptor: MockAuthHttpRequestInterceptor,
    }),
  ],
  providers: [
    SecondaryMockAuthProvider,
    { provide: MockAuthProvider, useExisting: SecondaryMockAuthProvider },
    MockAuthHttpRequestInterceptor,
  ],
})
export class CoreSecondaryAuthMockModule {
}

export class CoreAuthMockModule {
  static asStandalone(options: PrimaryMockOptions): ModuleWithProviders {
    return {
      ngModule: CorePrimaryAuthMockModule,
      providers: [{ provide: PRIMARY_MOCK_OPTIONS, useValue: options }],
    };
  }

  static asDecorating(): ModuleWithProviders {
    return {
      ngModule: CoreSecondaryAuthMockModule,
      providers: [],
    };
  }
}
