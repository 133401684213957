/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { AbstractRestService, arrayOf, RestSettings } from '@gh/core-data';
import { Observable } from 'rxjs';
import { DictionaryItemDto } from './dictionary-item.dto';
import { DictionaryItemMapper } from './dictionary-item.mapper';

@Injectable()
export class DictionaryRestService extends AbstractRestService {

  constructor(private settings: RestSettings) {
    super(settings);
  }

  get(code: string): Observable<DictionaryItemDto[]> {
    return this.httpGet(`/dictionaries/${code}`, arrayOf(DictionaryItemMapper));
  }
}
