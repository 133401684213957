/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime$ } from '@gh/rx/operators';
import * as i0 from "@angular/core";
var NoDblClickMatDirective = /** @class */ (function () {
    function NoDblClickMatDirective() {
        this.debounceTime = 500;
        this.debounceClick = new EventEmitter();
        this.clicks = new Subject();
    }
    NoDblClickMatDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.clicks.pipe(debounceTime$(this.debounceTime)).subscribe(function (e) { return _this.debounceClick.emit(e); });
    };
    NoDblClickMatDirective.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    NoDblClickMatDirective.prototype.clickEvent = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    };
    NoDblClickMatDirective.ɵfac = function NoDblClickMatDirective_Factory(t) { return new (t || NoDblClickMatDirective)(); };
    NoDblClickMatDirective.ɵdir = i0.ɵɵdefineDirective({ type: NoDblClickMatDirective, selectors: [["", "ghNoDblClickMat", ""]], hostBindings: function NoDblClickMatDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("click", function NoDblClickMatDirective_click_HostBindingHandler($event) { return ctx.clickEvent($event); });
        } }, inputs: { debounceTime: "debounceTime" }, outputs: { debounceClick: "debounceClick" } });
    return NoDblClickMatDirective;
}());
export { NoDblClickMatDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NoDblClickMatDirective, [{
        type: Directive,
        args: [{
                selector: '[ghNoDblClickMat]',
            }]
    }], null, { debounceTime: [{
            type: Input
        }], debounceClick: [{
            type: Output
        }], clickEvent: [{
            type: HostListener,
            args: ['click', ['$event']]
        }] }); })();
