/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { UserData } from '@gh/common-shared-data';
import { AuthState, Permission } from '@gh/core-auth';
import { Locale } from '@gh/core-mls';

import { Observable } from 'rxjs';

export abstract class UserContext {
  abstract get baseUrl(): string;

  abstract get authState(): AuthState;

  abstract get onAuthState(): Observable<AuthState>;

  abstract get data(): UserData;

  abstract get onData(): Observable<UserData>;

  abstract isInitialized(): boolean;

  abstract init(params: Hash<any>): Observable<UserData>;

  abstract logout(): Observable<boolean>;

  abstract changePassword(): Observable<boolean>;

  abstract hasPermission(permission: Permission): boolean;

  abstract changeLocale(locale: Locale): void;

  abstract get locale(): Locale;
}
