var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:variable-name
import { Injectable } from '@angular/core';
import { AbstractRestService, entity, RestSettings } from '@gh/core-data';
// import { GridLayout } from '@gh/core-ui-grid';
import { cast } from '@gh/core-util';
import { omit } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-data";
export var GridLayoutMapper = entity({});
var GridRestService = /** @class */ (function (_super) {
    __extends(GridRestService, _super);
    function GridRestService(settings) {
        var _this = _super.call(this, settings) || this;
        _this.settings = settings;
        return _this;
    }
    GridRestService.prototype.get = function (name) {
        return this.httpGet("/grids/" + name, GridLayoutMapper);
    };
    GridRestService.prototype.update = function (layout) {
        return this.httpPut("/grids/" + layout.name, cast(omit(layout, ['sort'])), GridLayoutMapper);
    };
    GridRestService.ɵfac = function GridRestService_Factory(t) { return new (t || GridRestService)(i0.ɵɵinject(i1.RestSettings)); };
    GridRestService.ɵprov = i0.ɵɵdefineInjectable({ token: GridRestService, factory: GridRestService.ɵfac });
    return GridRestService;
}(AbstractRestService));
export { GridRestService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(GridRestService, [{
        type: Injectable
    }], function () { return [{ type: i1.RestSettings }]; }, null); })();
