/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  Directive,
  DoCheck,
  EmbeddedViewRef,
  Input,
  IterableChangeRecord,
  IterableChanges,
  IterableDiffer,
  IterableDiffers,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { FormMessageService, MessageRepository } from '@gh/core-mls';
import { denull } from '@gh/core-util';
import { isNil, uniqBy } from 'lodash';
import { ErrorStateMatcher } from '@angular/material/core';

interface ErrorMessage {
  key: string;
  params: any;
  text?: string;
}

function errorTrackBy(index: number, message: ErrorMessage): string {
  return message.key;
}

const MAT_ERROR_RE = /^mat[A-Za-z]/;

@Directive({
  selector: '[ghMessagesFor]',
})
export class MessagesForDirective implements DoCheck {
  /**
   * This options allows to enable/disable displaying of standard material errors
   * (matDatepickerFilter, matDatepickerMin, etc)
   */
  @Input('ghMessagesForMatErrors') matErrors: boolean = true;

  private _messageRepository: MessageRepository;
  private errors: ErrorMessage[];
  private errorMap: Hash<any>;

  private differ: IterableDiffer<ErrorMessage>;
  private control?: FormControl;

  constructor(private messageService: FormMessageService,
              private viewContainerRef: ViewContainerRef,
              private differs: IterableDiffers,
              private templateRef: TemplateRef<any>,
              private errorStateMatcher: ErrorStateMatcher,
              @Optional() private parentForm: NgForm,
              @Optional() private parentFormGroup: FormGroupDirective,
              @Optional() private controlContainer: ControlContainer) {
    this._messageRepository = messageService;
  }

  @Input()
  set ghMessagesFor(target: FormControl | 'string' | number) {
    this.setTarget(target);
  }

  @Input()
  set ghMessagesForOf(target: FormControl | 'string' | number) {
    this.setTarget(target);
  }

  @Input('ghMessagesForRepository')
  set messageRepository(repository: Maybe<MessageRepository>) {
    this._messageRepository = repository ? this.messageService.createNested(repository) : this.messageService;
  }

  ngDoCheck(): void {
    this.checkErrors();
    if (this.differ) {
      const changes = this.differ.diff(this.errors || []);
      if (changes) {
        this.applyChanges(changes);
      }
    }
  }

  private setTarget(target: FormControl | NgControl | 'string' | number): void {
    let control: Maybe<FormControl> = undefined;

    if (target instanceof FormControl) {
      control = target;
    } else if (target instanceof NgControl) {
      control = <FormControl>denull(target.control);
    } else if (target) {
      control = <FormControl>this.getChildControl(target);
    }

    this.control = control;
  }

  private applyChanges(changes: IterableChanges<ErrorMessage>): void {
    changes.forEachOperation((record: IterableChangeRecord<ErrorMessage>,
                              adjustedPreviousIndex: number,
                              currentIndex: number) => {
      if (isNil(record.previousIndex)) {
        const view = this.viewContainerRef.createEmbeddedView(this.templateRef, {}, currentIndex);
        view.context.$implicit = record.item;
      } else if (isNil(currentIndex)) {
        this.viewContainerRef.remove(adjustedPreviousIndex);
      } else {
        const view = <EmbeddedViewRef<any>>this.viewContainerRef.get(adjustedPreviousIndex);
        this.viewContainerRef.move(view, currentIndex);
        view.context.$implicit = record.item;
      }
    });

    changes.forEachIdentityChange((record: any) => {
      const viewRef = <EmbeddedViewRef<any>>this.viewContainerRef.get(record.currentIndex);
      viewRef.context.$implicit = record.item;
    });
  }

  private getChildControl(index: any): AbstractControl {
    const control = this.controlContainer.control;

    if (control instanceof FormGroup) {
      return control.controls[index];
    } else if (control instanceof FormArray) {
      return control.controls[index];
    }

    throw new Error('Wrong parent control');
  }

  private checkErrors(): void {
    const errorMap = this.control && this.control.errors;
    if (!errorMap || !this.canDisplayMessages()) {
      this.errors = [];
      return;
    } else if (this.errorMap === errorMap) {
      return;
    }

    let errors: ErrorMessage[] = Object.keys(errorMap).map((key) => ({
      key,
      params: errorMap[key],
    }));

    errors.forEach((error) => {
      error.text = this._messageRepository.getText(error.key, error.params);
    });

    if (errors.length > 1) {
      errors = errors.filter((error) => error.key !== 'required');
      errors = uniqBy(errors, (error) => error.text);
    }

    if (!this.matErrors) {
      errors = errors.filter((error) => !MAT_ERROR_RE.test(error.key));
    }

    this.errors = errors;
    this.errorMap = errorMap;

    if (!this.differ) {
      this.differ = this.differs.find(errors).create(errorTrackBy);
    }
  }

  private canDisplayMessages(): boolean {
    const { control } = this;
    if (!control) {
      return false;
    }

    // tslint:disable-next-line:no-null-keyword
    return this.errorStateMatcher.isErrorState(control || null, this.parentFormGroup || this.parentForm);
  }
}
