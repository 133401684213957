/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl, FormGroup } from '@angular/forms';
import { getControl } from '@gh/core-ui';
import { each, mapValues, omit, pickBy } from 'lodash';
import { FormGroupSchemaConfig } from '../form-schema';
import { BaseControlSchemaConfig } from '../form-schema/base-control-schema';
import { FormControlSchemaConfig } from '../form-schema/form-control-schema';
import { AppControl } from './app-control';
import { AppFormArray } from './app-form-array';
import { AppFormControl } from './app-form-control';
import { AppFormGroup } from './app-form-group';

export function isControlVisible(control: AppControl): boolean {
  return control.visible;
}

export function updateChildrenSchema(group: AppFormGroup, childrenConfig: Hash<Maybe<FormControlSchemaConfig>>): void {
  each(childrenConfig, (fieldSchema, fieldName) => {
    const fieldControl = <AppFormControl>getControl(group, fieldName);

    fieldControl.updateSchema(fieldSchema || {});
  });
}

export function updateSchema(control: AbstractControl, schema: BaseControlSchemaConfig): void {
  const appControl = toAppFormControl(control);
  appControl.updateSchema(schema);
}

/**
 * Updates group by schema along with children
 */
export function updateSchemaWithChildren(group: FormGroup, schema: FormGroupSchemaConfig): void {
  const appGroup = toAppFormGroup(group);

  if (schema.children) {
    updateChildrenSchema(appGroup, schema.children);
  }

  appGroup.updateSchema(omit(schema, 'children'));
}

/**
 * Collect values from the given control and all its visible descendants into single object.
 * This method ignores state of control.
 * Value of FormGroup and FormArray consists of values of enabled children, this means if child control is disabled
 * its value is not merged into value of parent control. This method allows to bypass this restriction
 *
 * @param control
 * @returns {any}
 */
export function collectVisibleValues(control: AppControl): any {
  if (control instanceof AppFormArray) {
    return control.controls.filter(isControlVisible).map(collectVisibleValues);
  } else if (control instanceof AppFormGroup) {
    return mapValues(pickBy(control.controls, isControlVisible), collectVisibleValues);
  } else {
    return control.value;
  }
}

export function toAppFormControl(control: AbstractControl): AppFormControl {
  return <AppFormControl>control;
}

export function toAppFormGroup(control: AbstractControl): AppFormGroup {
  return <AppFormGroup>control;
}

export function toAppFormArray(control: AbstractControl): AppFormArray {
  return <AppFormArray>control;
}
