/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { registerLocaleData } from '@angular/common';
import RequireContext = __WebpackModuleApi.RequireContext;

function importAllLocaleData(r: RequireContext): void {
  r.keys()
    .filter((key) => !key.includes('extra'))
    .filter((key) => !key.includes('global'))
    // load all basic and extra locale data
    .forEach((key) => registerLocaleData(r(key).default, r(key.replace(/(\/[^/]+)$/, '/extra$1')).default));
}

// LOCALES_CONTEXT_FILTER is a filter for set of files for allowed locales
importAllLocaleData(require.context('@angular/common/locales', true, <any>process.env.LOCALES_CONTEXT_FILTER));
