/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isBoolean, isNil } from 'lodash';

@Pipe({ name: 'isControlVisible', pure: false })// tslint:disable-line:pipe-impure
export class IsControlVisiblePipe implements PipeTransform {

  transform(control: Nilable<AbstractControl>): boolean {
    if (isNil(control)) {
      return false;
    }

    const visible = control['visible'];

    if (isBoolean(visible)) {
      return visible;
    } else {
      throw new Error('Control does not have visible flag. Looks like you do not use form schema to build form');
    }
  }
}
