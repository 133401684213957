/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isDateInvalid } from '@gh/core-util';
import { isNil } from 'lodash';
import { AbstractPrimitiveType } from './abstract-type';
var DateTimeTypeImpl = /** @class */ (function (_super) {
    __extends(DateTimeTypeImpl, _super);
    function DateTimeTypeImpl(defenition) {
        return _super.call(this, defenition, {}) || this;
    }
    DateTimeTypeImpl.prototype.parse = function (str) {
        return this.dateTimeParser.parse(this.getInputFormatName(), str);
    };
    DateTimeTypeImpl.prototype.format = function (value) {
        return this.dateTimeFormat.format(this.defenition.format, value);
    };
    DateTimeTypeImpl.prototype.validate = function (value) {
        if (isNil(value)) {
            return void 0;
        }
        return isDateInvalid(value) ? { format: { messageId: 'msg_field_invalid_format' } } : _super.prototype.validate.call(this, value);
    };
    DateTimeTypeImpl.prototype.validateFormat = function (str) {
        if (!this.dateTimeParser.validate(this.getInputFormatName(), str)) {
            return {
                format: {
                    messageId: 'msg_field_invalid_format',
                },
            };
        }
    };
    DateTimeTypeImpl.prototype.init = function (name, localizationProvider) {
        _super.prototype.init.call(this, name, localizationProvider);
        this.dateTimeFormat = localizationProvider.getDateTimeFormat();
        this.dateTimeParser = localizationProvider.getDateTimeParser();
    };
    DateTimeTypeImpl.prototype.getInputFormatName = function () {
        var format = this.defenition.inputFormat;
        if (isNil(format)) {
            throw new Error('Input format is not specified for date type');
        }
        return format;
    };
    return DateTimeTypeImpl;
}(AbstractPrimitiveType));
export { DateTimeTypeImpl };
