/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, Directive, Input } from '@angular/core';
import { ExtRootLayout } from '..';
import * as i0 from "@angular/core";
import * as i1 from "..";
var _c0 = ["*"];
var ExtPageComponent = /** @class */ (function () {
    function ExtPageComponent() {
    }
    ExtPageComponent.ɵfac = function ExtPageComponent_Factory(t) { return new (t || ExtPageComponent)(); };
    ExtPageComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtPageComponent, selectors: [["gh-ext-page"]], ngContentSelectors: _c0, decls: 1, vars: 0, template: function ExtPageComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵprojection(0);
        } }, styles: ["[_nghost-%COMP%] {\n          flex: 1;\n          display: flex;\n          flex-direction: column;\n      }"] });
    return ExtPageComponent;
}());
export { ExtPageComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtPageComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-page',
                template: '<ng-content></ng-content>',
                styles: ["\n      :host {\n          flex: 1;\n          display: flex;\n          flex-direction: column;\n      }"],
            }]
    }], null, null); })();
var ExtPageContentComponent = /** @class */ (function () {
    function ExtPageContentComponent() {
    }
    ExtPageContentComponent.ɵfac = function ExtPageContentComponent_Factory(t) { return new (t || ExtPageContentComponent)(); };
    ExtPageContentComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtPageContentComponent, selectors: [["gh-ext-page-content"]], ngContentSelectors: _c0, decls: 1, vars: 0, template: function ExtPageContentComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵprojection(0);
        } }, styles: ["[_nghost-%COMP%] {\n          position: relative;\n          flex: 1;\n          display: flex;\n          flex-direction: column;\n          min-height: 100%;\n      }"] });
    return ExtPageContentComponent;
}());
export { ExtPageContentComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtPageContentComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-page-content',
                template: '<ng-content></ng-content>',
                styles: ["\n      :host {\n          position: relative;\n          flex: 1;\n          display: flex;\n          flex-direction: column;\n          min-height: 100%;\n      }"],
            }]
    }], null, null); })();
var ExtPageTitleDirective = /** @class */ (function () {
    function ExtPageTitleDirective(rootLayout) {
        this.rootLayout = rootLayout;
    }
    ExtPageTitleDirective.prototype.ngOnInit = function () {
        this.rootLayout.setTitle(this.title);
    };
    ExtPageTitleDirective.prototype.ngOnDestroy = function () {
        this.rootLayout.resetTitle();
    };
    ExtPageTitleDirective.ɵfac = function ExtPageTitleDirective_Factory(t) { return new (t || ExtPageTitleDirective)(i0.ɵɵdirectiveInject(i1.ExtRootLayout)); };
    ExtPageTitleDirective.ɵdir = i0.ɵɵdefineDirective({ type: ExtPageTitleDirective, selectors: [["gh-ext-page-title"]], inputs: { title: "title" } });
    return ExtPageTitleDirective;
}());
export { ExtPageTitleDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtPageTitleDirective, [{
        type: Directive,
        args: [{
                selector: 'gh-ext-page-title',
            }]
    }], function () { return [{ type: i1.ExtRootLayout }]; }, { title: [{
            type: Input
        }] }); })();
