/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { flatten, isNil, memoize } from 'lodash';
import { isNotNil } from './data-util';

export function domOffset(el: HTMLElement): { left: number; top: number } {
  const box = el.getBoundingClientRect();
  const win = window;
  const docElem = document.documentElement!; // tslint:disable-line:no-non-null-assertion

  return {
    left: box.left + (win.pageXOffset || docElem.scrollLeft) - (docElem.clientLeft || 0),
    top: box.top + (win.pageYOffset || docElem.scrollTop) - (docElem.clientTop || 0),
  };
}

export function domOffsetUntil(el: HTMLElement, lastEl: HTMLElement): Maybe<{ left: number; top: number }> {
  let left = 0;
  let top = 0;

  let currentEl: Maybe<HTMLElement> = el;

  while (currentEl && currentEl !== lastEl) {
    left += currentEl.offsetLeft;
    top += currentEl.offsetTop;

    currentEl = <HTMLElement>currentEl.offsetParent;
  }
  if (isNil(currentEl)) {
    return;
  }

  return { left, top };
}

export function stopEvent(event: Event): void {
  stopPropagation(event);
  preventDefault(event);
}

export function stopPropagation(event: Event): void {
  event.stopPropagation();
}

export function preventDefault(event: Event): void {
  event.preventDefault();
}

export function toPx(n: number): string {
  return `${n}px`;
}

function getParentContainClass(element: any, classNames: string[]): Maybe<HTMLElement> {
  let parentElement = element && element.parentElement && element.parentElement;

  while (parentElement) {
    if (classNames.some((className) => parentElement.classList.contains(className))) {
      return parentElement;
    } else {
      parentElement = parentElement.parentElement;
    }
  }
}

export function getClosest(element: any, classNameToCheck: string): Maybe<HTMLElement> {
  const classNames = classNameToCheck.split(',');
  if (element && classNames.some((className) => element.classList.contains(className))) {
    return element;
  } else {
    return getParentContainClass(element, classNames);
  }
}

export function hasClosest(element: any, classNameToCheck: string): boolean {
  return isNotNil(getClosest(element, classNameToCheck));
}

export function getVScrollbarWidth(el: HTMLElement): number {
  return el.offsetWidth - el.clientWidth;
}

export function isElementFocused(el: HTMLElement): boolean {
  return el === document.activeElement;
}

export const getScrollbarWidth = () => getScrollbarSettings().width;
export const getScrollbarHeight = () => getScrollbarSettings().height;

export const mergeClassNames = (...classNames: (Maybe<string> | string[])[]): string[] =>
  flatten(<any>classNames.filter(Boolean));

/**
 * Returns browser specific dimensions of scroll.
 * Implementation of this method was copied from ag-grid library (utils.ts#getScrollbarWidth),
 * but was generalized for width and height calculation
 */
const getScrollbarSettings = memoize(() => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.height = '100px';
  // outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  const heightNoScroll = outer.offsetHeight;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  inner.style.height = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;
  const heightWithScroll = inner.offsetHeight;

  // remove divs
  outer.parentNode!.removeChild(outer); // tslint:disable-line:no-non-null-assertion

  return {
    width: widthNoScroll - widthWithScroll,
    height: heightNoScroll - heightWithScroll,
  };
});

export function isDomElement(node: Node): boolean {
  return node.nodeType === Node.ELEMENT_NODE;
}

export function createScriptElement(url: string): HTMLElement {
  const node = document.createElement('script');
  node.src = url;
  node.type = 'text/javascript';
  node.charset = 'utf-8';
  return node;
}

export function createLinkElement(url: string): HTMLElement {
  const node = document.createElement('link');
  node.href = url;
  node.type = 'text/css';
  node.rel = 'stylesheet';
  return node;
}

export function createStyleElement(): HTMLElement {
  const node = document.createElement('style');
  node.type = 'text/css';
  return node;
}

export function appendElementToHead(element: HTMLElement): void {
  document.getElementsByTagName('head')[0].appendChild(element);
}

export function destroyElement(el: HTMLElement): void {
  const { parentElement } = el;
  if (parentElement) {
    parentElement.removeChild(el);
  }
}

export function getStyle(el: HTMLElement, ruleName: string): any {
  return getComputedStyle(el)[ruleName];
}
