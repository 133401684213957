/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive } from '@angular/core';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import * as i0 from "@angular/core";
var ShowErrorOnDirtyDirective = /** @class */ (function () {
    function ShowErrorOnDirtyDirective() {
    }
    ShowErrorOnDirtyDirective.ɵfac = function ShowErrorOnDirtyDirective_Factory(t) { return new (t || ShowErrorOnDirtyDirective)(); };
    ShowErrorOnDirtyDirective.ɵdir = i0.ɵɵdefineDirective({ type: ShowErrorOnDirtyDirective, selectors: [["", "ghShowErrorOnDirty", ""]], features: [i0.ɵɵProvidersFeature([{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }])] });
    return ShowErrorOnDirtyDirective;
}());
export { ShowErrorOnDirtyDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ShowErrorOnDirtyDirective, [{
        type: Directive,
        args: [{
                selector: '[ghShowErrorOnDirty]',
                providers: [{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }],
            }]
    }], null, null); })();
