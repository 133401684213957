/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ID } from '@gh/common-shared-data';

import { FinancialInvoicePayment, paymentsTotal } from '../../../resources';

@Component({
  selector: 'gh-customer-financial-record-payment-list',
  templateUrl: 'customer-financial-record-payment-list.component.html',
  styleUrls: ['customer-financial-record-payment-list.component.scss'],
})
export class CustomerFinancialRecordPaymentListComponent implements AfterViewInit {

  @Input() paymentDataSource: MatTableDataSource<FinancialInvoicePayment>;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['reference', 'paidDate', 'amount'];

  ngAfterViewInit(): void {
    this.paymentDataSource.sort = this.sort;
  }

  getPaymentId(index: number, payment: FinancialInvoicePayment): ID {
    return payment.paymentId;
  }

  paidTotal(): string {
    return paymentsTotal(this.paymentDataSource.data);
  }
}
