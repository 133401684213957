/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { createDialogRoute } from '@gh/common-shared';
import { DialogInput } from '@gh/core-ui';

export interface ExtEversignDialogConfig {
  url: string;
}

@Component({
  selector: 'gh-ext-eversign-dialog',
  templateUrl: 'ext-eversign-dialog.component.html',
  styleUrls: ['ext-eversign-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtEversignDialogComponent implements DialogInput<ExtEversignDialogConfig> {
  input: ExtEversignDialogConfig;

  constructor(private dialogRef: MatDialogRef<any>) {
  }

  onSigned(): void {
    this.dialogRef.close(true);
  }

  onDeclined(): void {
    this.dialogRef.close(false);
  }
}

export const eversignDialogRoute = createDialogRoute({
  component: ExtEversignDialogComponent,
  config: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});
