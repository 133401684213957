var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ErrorHandler, Injectable } from '@angular/core';
import { ENVIRONMENT, REGION_CODE, RELEASE, SENTRY_DSN, SENTRY_ON } from '@gh/config';
import { first$ } from '@gh/rx/operators';
import * as Sentry from '@sentry/browser';
import { isEmpty, omitBy } from 'lodash';
import { UserContext } from './user';
import * as i0 from "@angular/core";
import * as i1 from "./user";
if (SENTRY_ON) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT,
        release: RELEASE,
    });
}
export function defaultErrorHandler() {
    return SENTRY_ON ? [{ provide: ErrorHandler, useClass: SentryErrorHandler }] : [];
}
var SentryErrorHandler = /** @class */ (function (_super) {
    __extends(SentryErrorHandler, _super);
    function SentryErrorHandler(userContext) {
        var _this = _super.call(this) || this;
        _this.userContext = userContext;
        _this.userContext.onData
            .pipe(first$(Boolean))
            .subscribe(function (user) {
            Sentry.configureScope(function (scope) {
                scope.setUser(omitBy({
                    id: user.id,
                    username: user.fullName,
                    email: user.login,
                    locale: user.locale.toStandardString(),
                }, isEmpty));
                if (user.backendBuildNumber) {
                    scope.setTag('backendBuildNumber', user.backendBuildNumber);
                }
                scope.setTag('region', REGION_CODE);
            });
        });
        return _this;
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        Sentry.captureException(error.originalError || error);
        // without setTimeout Sentry hangs while sending error
        setTimeout(function () {
            throw error;
        }, 0);
    };
    SentryErrorHandler.ɵfac = function SentryErrorHandler_Factory(t) { return new (t || SentryErrorHandler)(i0.ɵɵinject(i1.UserContext)); };
    SentryErrorHandler.ɵprov = i0.ɵɵdefineInjectable({ token: SentryErrorHandler, factory: SentryErrorHandler.ɵfac });
    return SentryErrorHandler;
}(ErrorHandler));
export { SentryErrorHandler };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SentryErrorHandler, [{
        type: Injectable
    }], function () { return [{ type: i1.UserContext }]; }, null); })();
