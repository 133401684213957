/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { ResourceLoader } from './resource-loader.service';

@NgModule({
  providers: [],
})
export class CoreExternalCommonModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreExternalCommonModule,
      providers: [ResourceLoader],
    };
  }
}
