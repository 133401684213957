/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Locale } from '../locale';
import { TranslationService } from '../translation/translation.service';
import { DateTimeFormatImpl, DateTimeParserImpl } from './date-time-l10n';
import './import-locale-data'; // tslint:disable-line:no-import-side-effect
import {
  DateLocalization,
  DateTimeFormat,
  DateTimeParser,
  LocalizationProvider,
  NumberFormat,
  NumberLocalization,
  NumberParser,
  QuantityLocalization,
} from './localization-provider';
import { NumberFormatImpl, NumberParserImpl } from './number-l10n';
import { StringL10nHelper } from './string-l10n-helper';

/**
 * Implementation of {@link LocalizationProvider} interface.
 * Look at {@link LocalizationProvider} interface for details.
 */
@Injectable()
export class LocalizationService implements LocalizationProvider {
  private locale: Locale;
  private numberLocalization: NumberLocalization;
  private dateLocalization: DateLocalization;
  private quantityLocalization: QuantityLocalization;
  private numberParser: NumberParser;
  private numberFormat: NumberFormat;
  private dateTimeParser: DateTimeParser;
  private dateTimeFormat: DateTimeFormat;
  private stringL10nHelper: StringL10nHelper;

  constructor(private translationService: TranslationService,
              private numberPipe: DecimalPipe,
              private datePipe: DatePipe) {
    const localization = this.translationService.getRaw('localization');

    this.numberLocalization = {
      groupSeparator: localization.numberFormat.groupSeparator,
      decimalSeparator: localization.numberFormat.decimalSeparator,
      grouping: !!localization.numberFormat.grouping,
    };
    this.dateLocalization = localization.dateFormat;
    this.quantityLocalization = { formats: localization.quantityFormat };

    this.locale = Locale.create(localization.effectiveLocale);
    this.numberParser = new NumberParserImpl(this.numberLocalization);
    this.numberFormat = new NumberFormatImpl(this.locale, this.numberPipe);
    this.dateTimeParser = new DateTimeParserImpl(this.dateLocalization.inputPattern);
    this.dateTimeFormat = new DateTimeFormatImpl(this.locale, this.dateLocalization.styles, this.datePipe);
    this.stringL10nHelper = new StringL10nHelper(this.locale);
  }

  getEffectiveLocale(): Locale {
    return this.locale;
  }

  getNumberLocalization(): NumberLocalization {
    return this.numberLocalization;
  }

  getDateLocalization(): DateLocalization {
    return this.dateLocalization;
  }

  getQuantityLocalization(): QuantityLocalization {
    return this.quantityLocalization;
  }

  getNumberFormat(): NumberFormat {
    return this.numberFormat;
  }

  getDateTimeFormat(): DateTimeFormat {
    return this.dateTimeFormat;
  }

  getNumberParser(): NumberParser {
    return this.numberParser;
  }

  getDateTimeParser(): DateTimeParser {
    return this.dateTimeParser;
  }

  getStringL10nHelper(): StringL10nHelper {
    return this.stringL10nHelper;
  }
}
