/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component } from '@angular/core';
import * as i0 from "@angular/core";
var ExtNotFoundPageComponent = /** @class */ (function () {
    function ExtNotFoundPageComponent() {
    }
    ExtNotFoundPageComponent.ɵfac = function ExtNotFoundPageComponent_Factory(t) { return new (t || ExtNotFoundPageComponent)(); };
    ExtNotFoundPageComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtNotFoundPageComponent, selectors: [["gh-ext-not-found-page"]], decls: 16, vars: 9, consts: [[3, "title"], ["fxLayout", "column", "fxLayoutAlign", "start center", 1, "m-content"], [1, "m-404"], [1, "ext-primary-color"], [1, "m-404-title"], [1, "m-404-text", "ext-placeholder-secondary-color"]], template: function ExtNotFoundPageComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "gh-ext-page");
            i0.ɵɵelement(1, "gh-ext-page-title", 0);
            i0.ɵɵpipe(2, "translate");
            i0.ɵɵelementStart(3, "gh-ext-page-content");
            i0.ɵɵelementStart(4, "div", 1);
            i0.ɵɵelementStart(5, "div", 2);
            i0.ɵɵtext(6, "4");
            i0.ɵɵelementStart(7, "span", 3);
            i0.ɵɵtext(8, "0");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(9, "4");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(10, "div", 4);
            i0.ɵɵtext(11);
            i0.ɵɵpipe(12, "translate");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(13, "div", 5);
            i0.ɵɵtext(14);
            i0.ɵɵpipe(15, "translate");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(1);
            i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 3, "lbl_404_title"));
            i0.ɵɵadvance(10);
            i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(12, 5, "lbl_404_sorry"));
            i0.ɵɵadvance(3);
            i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(15, 7, "lbl_404_text"));
        } }, styles: [".m-content[_ngcontent-%COMP%] {\n  padding: 40px;\n  text-align: center; }\n\n.m-404[_ngcontent-%COMP%] {\n  font-size: 150px; }\n\n@media (min-width: 960px) {\n  .m-404[_ngcontent-%COMP%] {\n    margin-top: 140px; } }\n\n.m-404-title[_ngcontent-%COMP%] {\n  margin-bottom: 25px;\n  font-size: 24px;\n  line-height: 1.21; }\n\n.m-404-text[_ngcontent-%COMP%] {\n  font-size: 16px;\n  line-height: 1.13; }"] });
    return ExtNotFoundPageComponent;
}());
export { ExtNotFoundPageComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtNotFoundPageComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-not-found-page',
                templateUrl: 'ext-not-found-page.component.html',
                styleUrls: ['ext-not-found-page.component.scss'],
            }]
    }], null, null); })();
