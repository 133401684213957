/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, ContentChild, ElementRef, ViewChild } from '@angular/core';
import { getScrollbarWidth } from '@gh/core-util';
import { isNil } from 'lodash';
import * as i0 from "@angular/core";
var _c0 = ["*"];
var _c1 = ["header"];
var _c2 = ["body"];
var _c3 = [[["gh-table-layout-header"]], [["gh-table-layout-body"]]];
var _c4 = ["gh-table-layout-header", "gh-table-layout-body"];
var TableLayoutHeaderComponent = /** @class */ (function () {
    function TableLayoutHeaderComponent() {
    }
    TableLayoutHeaderComponent.ɵfac = function TableLayoutHeaderComponent_Factory(t) { return new (t || TableLayoutHeaderComponent)(); };
    TableLayoutHeaderComponent.ɵcmp = i0.ɵɵdefineComponent({ type: TableLayoutHeaderComponent, selectors: [["gh-table-layout-header"]], ngContentSelectors: _c0, decls: 1, vars: 0, template: function TableLayoutHeaderComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵprojection(0);
        } }, encapsulation: 2 });
    return TableLayoutHeaderComponent;
}());
export { TableLayoutHeaderComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TableLayoutHeaderComponent, [{
        type: Component,
        args: [{
                selector: 'gh-table-layout-header',
                template: '<ng-content></ng-content>',
            }]
    }], null, null); })();
var TableLayoutBodyComponent = /** @class */ (function () {
    function TableLayoutBodyComponent() {
    }
    TableLayoutBodyComponent.ɵfac = function TableLayoutBodyComponent_Factory(t) { return new (t || TableLayoutBodyComponent)(); };
    TableLayoutBodyComponent.ɵcmp = i0.ɵɵdefineComponent({ type: TableLayoutBodyComponent, selectors: [["gh-table-layout-body"]], ngContentSelectors: _c0, decls: 1, vars: 0, template: function TableLayoutBodyComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵprojection(0);
        } }, encapsulation: 2 });
    return TableLayoutBodyComponent;
}());
export { TableLayoutBodyComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TableLayoutBodyComponent, [{
        type: Component,
        args: [{
                selector: 'gh-table-layout-body',
                template: '<ng-content></ng-content>',
            }]
    }], null, null); })();
var TableLayoutComponent = /** @class */ (function () {
    function TableLayoutComponent() {
        this.scrollWidth = getScrollbarWidth();
        this.onUpdateScroll = this.onUpdateScroll.bind(this);
    }
    TableLayoutComponent.prototype.ngAfterContentInit = function () {
        if (isNil(this.header)) {
            throw new Error('TableLayoutComponent: Header does not exist');
        }
        if (isNil(this.body)) {
            throw new Error('TableLayoutComponent: Body does not exist');
        }
        this.getBodyElement().addEventListener('scroll', this.onUpdateScroll);
    };
    TableLayoutComponent.prototype.ngOnDestroy = function () {
        this.getBodyElement().removeEventListener('scroll', this.onUpdateScroll);
    };
    TableLayoutComponent.prototype.onUpdateScroll = function () {
        var headerEl = this.getHeaderElement();
        var bodyEl = this.getBodyElement();
        headerEl.scrollLeft = bodyEl.scrollLeft;
    };
    TableLayoutComponent.prototype.getHeaderElement = function () {
        return this.headerEl.nativeElement;
    };
    TableLayoutComponent.prototype.getBodyElement = function () {
        return this.bodyEl.nativeElement;
    };
    TableLayoutComponent.ɵfac = function TableLayoutComponent_Factory(t) { return new (t || TableLayoutComponent)(); };
    TableLayoutComponent.ɵcmp = i0.ɵɵdefineComponent({ type: TableLayoutComponent, selectors: [["gh-table-layout"]], contentQueries: function TableLayoutComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
            i0.ɵɵcontentQuery(dirIndex, TableLayoutHeaderComponent, true);
            i0.ɵɵcontentQuery(dirIndex, TableLayoutBodyComponent, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.header = _t.first);
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.body = _t.first);
        } }, viewQuery: function TableLayoutComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵstaticViewQuery(_c1, true);
            i0.ɵɵstaticViewQuery(_c2, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerEl = _t.first);
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.bodyEl = _t.first);
        } }, ngContentSelectors: _c4, decls: 7, vars: 2, consts: [[1, "table-layout-header"], ["header", ""], [1, "table-layout-body-viewport"], ["body", ""], [1, "table-layout-body"]], template: function TableLayoutComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef(_c3);
            i0.ɵɵelementStart(0, "div", 0, 1);
            i0.ɵɵprojection(2);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "div", 2, 3);
            i0.ɵɵelementStart(5, "div", 4);
            i0.ɵɵprojection(6, 1);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵstyleProp("margin-right", ctx.scrollWidth, "px");
        } }, styles: ["[_nghost-%COMP%] {\n  position: relative;\n  overflow: hidden; }\n\n.table-layout-header[_ngcontent-%COMP%] {\n  display: flex;\n  flex-direction: column;\n  min-height: 0;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: 50px;\n  overflow: hidden; }\n\n.table-layout-body-viewport[_ngcontent-%COMP%] {\n  display: flex;\n  flex-direction: column;\n  min-height: 0;\n  position: absolute;\n  top: 50px;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  overflow: auto; }\n\n[_nghost-%COMP%]     gh-table-layout-header {\n  flex: 1; }\n\n[_nghost-%COMP%]     gh-table-layout-body {\n  flex: 1; }"] });
    return TableLayoutComponent;
}());
export { TableLayoutComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TableLayoutComponent, [{
        type: Component,
        args: [{
                selector: 'gh-table-layout',
                templateUrl: 'table-layout.component.html',
                styleUrls: ['table-layout.component.scss'],
            }]
    }], function () { return []; }, { header: [{
            type: ContentChild,
            args: [TableLayoutHeaderComponent, { static: false }]
        }], body: [{
            type: ContentChild,
            args: [TableLayoutBodyComponent, { static: false }]
        }], headerEl: [{
            type: ViewChild,
            args: ['header', { static: true }]
        }], bodyEl: [{
            type: ViewChild,
            args: ['body', { static: true }]
        }] }); })();
