/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'gh-ext-preloader',
  templateUrl: 'ext-preloader.component.html',
  styleUrls: ['ext-preloader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtPreloaderComponent {
  @HostBinding('class.m-is-loading')
  @Input() loading: boolean = false;
  @Input() size: number = 150;
}
