/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpInterceptor } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { AUTH_HTTP_INTERCEPTOR, AuthProvider } from './auth-provider';

import { AuthService } from './auth.service';
import {
  DecoratableAuthProvider,
  DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR,
  DECORATED_AUTH_PROVIDER,
  DecoratingAuthHttpRequestInterceptor,
  DecoratingAuthProvider,
} from './decoratable-auth-provider.service';
import { PermissionProvider } from './permission-provider';

export function decorateAuthProvider(decoratable: DecoratableAuthProvider,
                                     decorated: AuthProvider): AuthProvider {
  return new DecoratingAuthProvider(decoratable, decorated);
}

export function decorateAuthHttpRequestInterceptor(authProvider: DecoratableAuthProvider,
                                                   decoratable: HttpInterceptor,
                                                   decorated: HttpInterceptor): HttpInterceptor {
  return new DecoratingAuthHttpRequestInterceptor(authProvider, decoratable, decorated);
}

export interface CoreAuthModuleOptions {
  authProvider: Type<AuthProvider>;
  authHttpInterceptor: Type<HttpInterceptor>;
  permissionProvider?: Type<PermissionProvider>;
}

export interface CoreDecoratableAuthModuleOptions {
  authProvider: Type<DecoratableAuthProvider>;
  authHttpRequestInterceptor: Type<HttpInterceptor>;
}

@NgModule({
  declarations: [],
  exports: [],
})
export class CoreAuthModule {
  static forRoot(options: CoreAuthModuleOptions): ModuleWithProviders {
    return {
      ngModule: CoreAuthModule,
      providers: [
        { provide: AuthProvider, useExisting: options.authProvider },
        { provide: AUTH_HTTP_INTERCEPTOR, useExisting: options.authHttpInterceptor },
        AuthService,
        options.permissionProvider ? [options.permissionProvider] : [],
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: CoreAuthModule,
      providers: [],
    };
  }

  static decorateBy(options: CoreDecoratableAuthModuleOptions): ModuleWithProviders {
    return {
      ngModule: CoreAuthModule,
      providers: [{
        provide: AuthProvider,
        useFactory: decorateAuthProvider,
        deps: [
          options.authProvider,
          DECORATED_AUTH_PROVIDER,
        ],
      }, {
        provide: AUTH_HTTP_INTERCEPTOR,
        useFactory: decorateAuthHttpRequestInterceptor,
        deps: [
          options.authProvider,
          options.authHttpRequestInterceptor,
          DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR,
        ],
      }],
    };
  }
}
