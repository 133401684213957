/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { CoreAuthModule, DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR, DECORATED_AUTH_PROVIDER, PermissionNameSetProvider, } from '@gh/core-auth';
import { KeycloakAuthHttpRequestInterceptor } from './keycloak-auth-http-request-interceptor';
import { KEYCLOAK_LOGIN_OPTIONS, KEYCLOAK_LOGOUT_OPTIONS, KeycloakAuthProvider } from './keycloak-auth-provider';
import { KEYCLOAK_CONFIGURATION, KEYCLOAK_INIT_OPTIONS, KEYCLOAK_MIN_VALIDITY, KeycloakService, } from './keycloak.service';
import * as i0 from "@angular/core";
import * as i1 from "../core-auth/core-auth.module";
export function buildKeycloakConfiguration(configuration) {
    return [
        { provide: KEYCLOAK_CONFIGURATION, useValue: configuration.configuration },
        { provide: KEYCLOAK_INIT_OPTIONS, useValue: configuration.initOptions },
        { provide: KEYCLOAK_LOGIN_OPTIONS, useValue: configuration.loginOptions },
        { provide: KEYCLOAK_LOGOUT_OPTIONS, useValue: configuration.logoutOptions },
        { provide: KEYCLOAK_MIN_VALIDITY, useValue: configuration.minValidity },
    ];
}
var CoreKeycloakAuthModule = /** @class */ (function () {
    function CoreKeycloakAuthModule() {
    }
    CoreKeycloakAuthModule.asStandalone = function (configuration) {
        return {
            ngModule: CoreKeycloakAuthModule,
            providers: [
                buildKeycloakConfiguration(configuration),
            ],
        };
    };
    CoreKeycloakAuthModule.asDecorated = function (configuration) {
        return {
            ngModule: CoreKeycloakAuthModule,
            providers: [
                buildKeycloakConfiguration(configuration),
                { provide: DECORATED_AUTH_PROVIDER, useExisting: KeycloakAuthProvider },
                { provide: DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR, useExisting: KeycloakAuthHttpRequestInterceptor },
            ],
        };
    };
    CoreKeycloakAuthModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreKeycloakAuthModule });
    CoreKeycloakAuthModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreKeycloakAuthModule_Factory(t) { return new (t || CoreKeycloakAuthModule)(); }, providers: [
            KeycloakService,
            KeycloakAuthProvider,
            KeycloakAuthHttpRequestInterceptor,
        ], imports: [[
                CoreAuthModule.forRoot({
                    authProvider: KeycloakAuthProvider,
                    permissionProvider: PermissionNameSetProvider,
                    authHttpInterceptor: KeycloakAuthHttpRequestInterceptor,
                }),
            ],
            CoreAuthModule] });
    return CoreKeycloakAuthModule;
}());
export { CoreKeycloakAuthModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreKeycloakAuthModule, { imports: [i1.CoreAuthModule], exports: [CoreAuthModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreKeycloakAuthModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CoreAuthModule.forRoot({
                        authProvider: KeycloakAuthProvider,
                        permissionProvider: PermissionNameSetProvider,
                        authHttpInterceptor: KeycloakAuthHttpRequestInterceptor,
                    }),
                ],
                providers: [
                    KeycloakService,
                    KeycloakAuthProvider,
                    KeycloakAuthHttpRequestInterceptor,
                ],
                exports: [CoreAuthModule],
            }]
    }], null, null); })();
