/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { TranslationParams, TranslationService } from '@gh/core-mls';
import { coalesce, isNotNil } from '@gh/core-util';
import { DialogInput } from '../dialog-input';
import { isDetailedMessage, splitByLines } from '../dialog.utils';

/**
 * Set of possible answers of confirmation dialog
 */
export enum Confirmation {
  Cancel = 0,
  No = 1,
  Yes = 2,
}

export interface ConfirmationResponseFn {
  (confirmation: Confirmation): any;
}

/**
 * Configuration object for confirmation dialog
 */
export interface ConfirmationDialogConfig {
  /**
   * Text message
   */
  message?: string;
  /**
   * Id of MLS label
   */
  messageId?: string;
  /**
   * Parameters for MLS label
   */
  messageParams?: TranslationParams;
  /**
   * Set of available buttons for confirmation dialog
   */
  buttons?: Confirmation[];
  /**
   * Default (focused) button for confirmation dialog.
   * This value returns if confirmation is closed by clicking outside of dialog
   */
  defaultButton?: Confirmation;
  /**
   * Whether to close dialog on backdrop click.
   * By default this option is true.
   */
  closeOnBackdropClick?: boolean;
  /**
   * Label for 'yes' button
   */
  yesLabel?: string;
  /**
   * Label for 'no' button
   */
  noLabel?: string;
  /**
   * Label for 'no' button
   */
  cancelLabel?: string;
}

export class ConfirmationDialogBaseComponent implements DialogInput<ConfirmationDialogConfig>, OnInit {
  input: ConfirmationDialogConfig;
  detailed: boolean = false;
  message?: string;
  title?: string;
  description?: string;
  noButton: boolean;
  cancelButton: boolean;
  defaultButton: number;

  constructor(private translationService: TranslationService,
              public dialogRef: MatDialogRef<ConfirmationDialogBaseComponent>,
              private elementRef: ElementRef,
              private renderer: Renderer2) {

  }

  get defaultNoButton(): boolean {
    return this.defaultButton === Confirmation.No;
  }

  get defaultYesButton(): boolean {
    return this.defaultButton === Confirmation.Yes;
  }

  get defaultCancelButton(): boolean {
    return this.defaultButton === Confirmation.Cancel;
  }

  get closeOnBackdropClick(): boolean {
    return coalesce(this.input.closeOnBackdropClick, true);
  }

  get yesLabel(): string {
    return this.input.yesLabel ? this.input.yesLabel : this.translationService.get('lbl_yes');
  }

  get noLabel(): string {
    return this.input.noLabel ? this.input.noLabel : this.translationService.get('lbl_no');
  }

  get cancelLabel(): string {
    return this.input.cancelLabel ? this.input.cancelLabel : this.translationService.get('lbl_cancel');
  }

  ngOnInit(): void {
    const { messageId, messageParams, message, buttons, defaultButton } = this.input;

    if (messageId) {
      const rawMessage = this.translationService.getRaw(messageId);

      if (isDetailedMessage(rawMessage)) {
        this.title = splitByLines(this.translationService.get([messageId, 'title'].join('.'), messageParams));
        // tslint:disable:max-line-length
        this.description = splitByLines(this.translationService.get(
          [messageId, 'description'].join('.'),
          messageParams));
        this.detailed = true;
      } else {
        this.message = splitByLines(this.translationService.get(messageId, messageParams));
      }
    } else if (message) {
      this.message = splitByLines(message);
    }

    this.noButton = buttons ? this.hasButton(Confirmation.No) : false;
    this.cancelButton = buttons ? this.hasButton(Confirmation.Cancel) : true;

    if (isNotNil(defaultButton)) {
      this.defaultButton = defaultButton;
    }

    this.renderer.addClass(this.elementRef.nativeElement, this.detailed
      ? 'confirmation-dialog--detailed' : 'confirmation-dialog--simple');

    if (this.closeOnBackdropClick) {
      this.dialogRef
        .backdropClick()
        .subscribe(() => {
          this.dialogRef.close(this.input.defaultButton);
        });
    }
  }

  onYes(): void {
    this.dialogRef.close(Confirmation.Yes);
  }

  onNo(): void {
    this.dialogRef.close(Confirmation.No);
  }

  onCancel(): void {
    this.dialogRef.close(Confirmation.Cancel);
  }

  onClose(): void {
    if (this.closeOnBackdropClick) {
      this.dialogRef.close(this.input.defaultButton);
    }
  }

  private hasButton(button: Confirmation): boolean {
    if (this.input.buttons) {
      return this.input.buttons.indexOf(button) >= 0;
    } else {
      return false;
    }
  }
}
