/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Provider, Type } from '@angular/core';

import { Locale } from '../locale';

export function provideTranslationMapLoader(loader: Type<TranslationMapLoader>): Provider {
  return {
    provide: TranslationMapLoader,
    useClass: loader,
  };
}

export abstract class TranslationMapLoader {
  abstract load(locale: Locale): Promise<any>;
}
