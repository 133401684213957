/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { SubstitutionKey } from './translation-compiler.service';

export abstract class SubstitutionCompiler {
  abstract compileKey(key: string): SubstitutionKey;

  abstract apply(key: SubstitutionKey, value: string): string;
}

export class DefaultSubstitutionCompiler implements SubstitutionCompiler {

  compileKey(key: string): SubstitutionKey {
    return { name: key };
  }

  apply(key: SubstitutionKey, value: string): string {
    return value;
  }
}
