/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable, Optional } from '@angular/core';
import { constant, isString, isUndefined } from 'lodash';
import { DefaultSubstitutionCompiler, SubstitutionCompiler } from './substitution-compiler.service';
import * as i0 from "@angular/core";
import * as i1 from "./substitution-compiler.service";
var PLACEHOLDER_RE = /(^|[^\\])(\{\s*([a-zA-Z_])\w*\s*})/g;
var PLACEHOLDER_MATCH_EXPR_INDEX = 2;
var TranslationCompiler = /** @class */ (function () {
    function TranslationCompiler(substitutionCompiler) {
        this.substitutionCompiler = substitutionCompiler;
        this.substitutionKeyCache = {};
        if (!this.substitutionCompiler) {
            this.substitutionCompiler = new DefaultSubstitutionCompiler();
        }
    }
    TranslationCompiler.prototype.compile = function (text) {
        var fullText = isString(text) ? text : text.join('\n');
        PLACEHOLDER_RE.lastIndex = 0;
        var substitutions = {};
        var cursor = 0;
        var parts = [];
        while (true) {
            var match = PLACEHOLDER_RE.exec(fullText);
            if (!match) {
                break;
            }
            var expression = match[PLACEHOLDER_MATCH_EXPR_INDEX];
            parts.push(fullText.substring(cursor, PLACEHOLDER_RE.lastIndex - expression.length));
            substitutions[expression.substring(1, expression.length - 1)] = parts.length;
            parts.push('');
            cursor = PLACEHOLDER_RE.lastIndex;
        }
        parts.push(fullText.substring(cursor));
        if (parts.length === 1) {
            return this.createSimpleTranslation(parts[0]);
        }
        else {
            return this.createMultipartTranslation(parts, substitutions);
        }
    };
    TranslationCompiler.prototype.createSimpleTranslation = function (text) {
        return constant(text);
    };
    TranslationCompiler.prototype.createMultipartTranslation = function (parts, substitutions) {
        var _this = this;
        return function (params) {
            if (!params) {
                return parts.join('');
            }
            var paramKeys = Object.keys(params);
            if (paramKeys.length === 0) {
                return parts.join('');
            }
            var stage = parts.concat();
            paramKeys.forEach(function (key) {
                var parsedKey = _this.parseSubstitutionKey(key);
                var sub = substitutions[parsedKey.name];
                if (isUndefined(sub)) {
                    console.warn("Illegal parameter is passed into message: " + stage.join('') + ", " + key);
                    return;
                }
                stage[sub] = _this.substitutionCompiler.apply(parsedKey, params[key]);
            });
            return stage.join('');
        };
    };
    TranslationCompiler.prototype.parseSubstitutionKey = function (key) {
        var parsed = this.substitutionKeyCache[key];
        if (!parsed) {
            parsed = this.substitutionKeyCache[key] = this.substitutionCompiler.compileKey(key);
        }
        return parsed;
    };
    TranslationCompiler.ɵfac = function TranslationCompiler_Factory(t) { return new (t || TranslationCompiler)(i0.ɵɵinject(i1.SubstitutionCompiler, 8)); };
    TranslationCompiler.ɵprov = i0.ɵɵdefineInjectable({ token: TranslationCompiler, factory: TranslationCompiler.ɵfac });
    return TranslationCompiler;
}());
export { TranslationCompiler };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TranslationCompiler, [{
        type: Injectable
    }], function () { return [{ type: i1.SubstitutionCompiler, decorators: [{
                type: Optional
            }] }]; }, null); })();
