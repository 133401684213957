/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive } from '@angular/core';
import { WaitingContext } from './waiting-context.service';
import { WaitingService } from './waiting.service';
import * as i0 from "@angular/core";
/**
 * The only purpose of this directive is to add {@link WaitingContext} and {@link WaitingService}
 * into the current injector.
 *
 * {@link WaitingContext} provides low-level capabilities for waiting side-effects.
 * {@link WaitingService} is a service to be used by developers in any page component.
 */
var WaitingScopeDirective = /** @class */ (function () {
    function WaitingScopeDirective() {
    }
    WaitingScopeDirective.ɵfac = function WaitingScopeDirective_Factory(t) { return new (t || WaitingScopeDirective)(); };
    WaitingScopeDirective.ɵdir = i0.ɵɵdefineDirective({ type: WaitingScopeDirective, selectors: [["", "ghWaitingScope", ""]], features: [i0.ɵɵProvidersFeature([WaitingContext, WaitingService])] });
    return WaitingScopeDirective;
}());
export { WaitingScopeDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(WaitingScopeDirective, [{
        type: Directive,
        args: [{
                selector: '[ghWaitingScope]',
                providers: [WaitingContext, WaitingService],
            }]
    }], null, null); })();
