/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { from$ } from '@gh/rx';

import { Observable } from 'rxjs';

import { AuthProvider, AuthState } from './auth-provider';
import { Permission } from './permission';
import { PermissionProvider } from './permission-provider';

@Injectable()
export class AuthService {
  constructor(private authProvider: AuthProvider,
              private permissionProvider: PermissionProvider) {
  }

  login(): Observable<boolean> {
    return from$(this.authProvider.login());
  }

  logout(): Observable<boolean> {
    return from$(this.authProvider.logout());
  }

  hasPermission(permission: Permission): boolean {
    return this.permissionProvider.hasPermission(permission);
  }

  get authState(): AuthState {
    return this.authProvider.authState;
  }

  get onAuthState(): Observable<AuthState> {
    return this.authProvider.onAuthState;
  }
}
