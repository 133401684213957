/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CollapsibleContainerDirective } from './collapsible-container.directive';
import * as i0 from "@angular/core";
import * as i1 from "./collapsible-container.directive";
var CollapsibleLabelComponent = /** @class */ (function () {
    function CollapsibleLabelComponent(container) {
        this.container = container;
    }
    CollapsibleLabelComponent.prototype.ngOnInit = function () {
        this.container.label = this;
    };
    Object.defineProperty(CollapsibleLabelComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;
            this.container.recalculate();
        },
        enumerable: true,
        configurable: true
    });
    CollapsibleLabelComponent.ɵfac = function CollapsibleLabelComponent_Factory(t) { return new (t || CollapsibleLabelComponent)(i0.ɵɵdirectiveInject(i1.CollapsibleContainerDirective)); };
    CollapsibleLabelComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CollapsibleLabelComponent, selectors: [["gh-collapsible-label"]], inputs: { value: "value" }, decls: 1, vars: 1, template: function CollapsibleLabelComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtext(0);
        } if (rf & 2) {
            i0.ɵɵtextInterpolate(ctx.value);
        } }, encapsulation: 2, changeDetection: 0 });
    return CollapsibleLabelComponent;
}());
export { CollapsibleLabelComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CollapsibleLabelComponent, [{
        type: Component,
        args: [{
                selector: 'gh-collapsible-label',
                template: '{{ value }}',
                changeDetection: ChangeDetectionStrategy.OnPush,
            }]
    }], function () { return [{ type: i1.CollapsibleContainerDirective }]; }, { value: [{
            type: Input
        }] }); })();
