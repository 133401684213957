/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { TranslationService } from '../translation/translation.service';
import { MessageRepository } from './message-repository';

/**
 * This service encapsulates common logic of retrieving message translations for form errors.
 *
 * This service translates message in the following way:
 * 1. If message params were provided and parameter <code>messageId</code> exists,
 * <code>messageId</code> is used to retrieve translation for this message.
 *
 * 2. Otherwise, provided <code>key</code> is used to construct translation key.
 * Translation key is constructed by adding prefix <code>msg_field_</code>
 * (e. g. <code>required => msg_field_required</code>)
 */
@Injectable()
export class FormMessageService implements MessageRepository {

  constructor(private translationService: TranslationService) {
  }

  /**
   * Creates nested {@link MessageRepository}.
   * Initially, given {@link MessageRepository} tries to find translation for the message.
   * If translation was not found it loads translation using common logic described here above.
   */
  createNested(repository: MessageRepository): MessageRepository {
    return {
      getText: (key, params) => {
        const text = repository.getText(key, params);

        if (text) {
          return text;
        }

        return this.getText(key, params);
      },
    };
  }

  getText(key: string, params: any): string {
    let text = '';

    if (params && params.messageId) {
      text = this.translationService.get(params.messageId, params);
    }

    if (!text) {
      text = this.translationService.get(`msg_field_${key}`, params);
    }

    return text;
  }
}
