/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreAuthModule, PermissionNameSetProvider } from '@gh/core-auth';
import { MockAuthHttpRequestInterceptor } from './mock-auth-http-request-interceptor';
import { MockAuthProvider } from './mock-auth-provider.service';
import { MockLoginDialogComponent, PRIMARY_MOCK_OPTIONS, PrimaryMockAuthProvider } from './primary';
import { SecondaryMockAuthProvider } from './secondary/secondary-mock-auth-provider.service';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import * as i0 from "@angular/core";
import * as i1 from "../core-auth/core-auth.module";
var CorePrimaryAuthMockModule = /** @class */ (function () {
    function CorePrimaryAuthMockModule() {
    }
    CorePrimaryAuthMockModule.ɵmod = i0.ɵɵdefineNgModule({ type: CorePrimaryAuthMockModule });
    CorePrimaryAuthMockModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CorePrimaryAuthMockModule_Factory(t) { return new (t || CorePrimaryAuthMockModule)(); }, providers: [
            PrimaryMockAuthProvider,
            { provide: MockAuthProvider, useExisting: PrimaryMockAuthProvider },
            MockAuthHttpRequestInterceptor,
        ], imports: [[
                CommonModule,
                FormsModule,
                CdkTableModule,
                MatInputModule,
                MatDialogModule,
                MatButtonModule,
                MatRadioModule,
                MatTableModule,
                CoreAuthModule.forRoot({
                    authProvider: PrimaryMockAuthProvider,
                    permissionProvider: PermissionNameSetProvider,
                    authHttpInterceptor: MockAuthHttpRequestInterceptor,
                }),
            ],
            CoreAuthModule] });
    return CorePrimaryAuthMockModule;
}());
export { CorePrimaryAuthMockModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CorePrimaryAuthMockModule, { declarations: [MockLoginDialogComponent], imports: [CommonModule,
        FormsModule,
        CdkTableModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        MatRadioModule,
        MatTableModule, i1.CoreAuthModule], exports: [CoreAuthModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CorePrimaryAuthMockModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                    FormsModule,
                    CdkTableModule,
                    MatInputModule,
                    MatDialogModule,
                    MatButtonModule,
                    MatRadioModule,
                    MatTableModule,
                    CoreAuthModule.forRoot({
                        authProvider: PrimaryMockAuthProvider,
                        permissionProvider: PermissionNameSetProvider,
                        authHttpInterceptor: MockAuthHttpRequestInterceptor,
                    }),
                ],
                providers: [
                    PrimaryMockAuthProvider,
                    { provide: MockAuthProvider, useExisting: PrimaryMockAuthProvider },
                    MockAuthHttpRequestInterceptor,
                ],
                declarations: [MockLoginDialogComponent],
                entryComponents: [MockLoginDialogComponent],
                exports: [CoreAuthModule],
            }]
    }], null, null); })();
var CoreSecondaryAuthMockModule = /** @class */ (function () {
    function CoreSecondaryAuthMockModule() {
    }
    CoreSecondaryAuthMockModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreSecondaryAuthMockModule });
    CoreSecondaryAuthMockModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreSecondaryAuthMockModule_Factory(t) { return new (t || CoreSecondaryAuthMockModule)(); }, providers: [
            SecondaryMockAuthProvider,
            { provide: MockAuthProvider, useExisting: SecondaryMockAuthProvider },
            MockAuthHttpRequestInterceptor,
        ], imports: [[
                CoreAuthModule.decorateBy({
                    authProvider: SecondaryMockAuthProvider,
                    authHttpRequestInterceptor: MockAuthHttpRequestInterceptor,
                }),
            ]] });
    return CoreSecondaryAuthMockModule;
}());
export { CoreSecondaryAuthMockModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreSecondaryAuthMockModule, { imports: [i1.CoreAuthModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreSecondaryAuthMockModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CoreAuthModule.decorateBy({
                        authProvider: SecondaryMockAuthProvider,
                        authHttpRequestInterceptor: MockAuthHttpRequestInterceptor,
                    }),
                ],
                providers: [
                    SecondaryMockAuthProvider,
                    { provide: MockAuthProvider, useExisting: SecondaryMockAuthProvider },
                    MockAuthHttpRequestInterceptor,
                ],
            }]
    }], null, null); })();
var CoreAuthMockModule = /** @class */ (function () {
    function CoreAuthMockModule() {
    }
    CoreAuthMockModule.asStandalone = function (options) {
        return {
            ngModule: CorePrimaryAuthMockModule,
            providers: [{ provide: PRIMARY_MOCK_OPTIONS, useValue: options }],
        };
    };
    CoreAuthMockModule.asDecorating = function () {
        return {
            ngModule: CoreSecondaryAuthMockModule,
            providers: [],
        };
    };
    return CoreAuthMockModule;
}());
export { CoreAuthMockModule };
