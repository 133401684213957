/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { isNumber } from 'lodash';
var DOLLAR_SIGN = '$';
var EMPTY_STRING = '';
var COMMA = ',';
var PERIOD = '.';
var MINUS = '-';
var MINUS_RE = /-/;
var NON_DIGITS_RE = /\D+/g;
var DIGIT_RE = /\d/;
var CARET_TRAP = '[]';
var START_ZERO_RE = /^0+([0-9])/;
export function createNumberMask(_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.prefix, prefix = _c === void 0 ? DOLLAR_SIGN : _c, _d = _b.suffix, suffix = _d === void 0 ? EMPTY_STRING : _d, _e = _b.includeThousandsSeparator, includeThousandsSeparator = _e === void 0 ? true : _e, _f = _b.thousandsSeparatorSymbol, thousandsSeparatorSymbol = _f === void 0 ? COMMA : _f, _g = _b.allowDecimal, allowDecimal = _g === void 0 ? false : _g, _h = _b.decimalSymbol, decimalSymbol = _h === void 0 ? PERIOD : _h, _j = _b.decimalLimit, decimalLimit = _j === void 0 ? 2 : _j, // tslint:disable-line:no-magic-numbers
    _k = _b.requireDecimal, // tslint:disable-line:no-magic-numbers
    requireDecimal = _k === void 0 ? false : _k, _l = _b.allowNegative, allowNegative = _l === void 0 ? false : _l, _m = _b.integerLimit, integerLimit = _m === void 0 ? void 0 : _m;
    var decimalRe = new RegExp("\\" + decimalSymbol);
    var prefixLength = prefix && prefix.length || 0;
    var thousandsSeparatorSymbolLength = thousandsSeparatorSymbol && thousandsSeparatorSymbol.length || 0;
    function numberMask(rawValue) {
        if (rawValue === void 0) { rawValue = EMPTY_STRING; }
        var rawValueLength = rawValue.length;
        if (rawValue === EMPTY_STRING ||
            (rawValue[0] === prefix[0] && rawValueLength === 1)) {
            return prefix.split(EMPTY_STRING).concat([DIGIT_RE]).concat(suffix.split(EMPTY_STRING));
        }
        var indexOfLastDecimal = rawValue.lastIndexOf(decimalSymbol);
        var hasDecimal = indexOfLastDecimal !== -1;
        var isNegative = (rawValue[0] === MINUS) && allowNegative;
        var integer;
        var fraction;
        var mask;
        if (hasDecimal && (allowDecimal || requireDecimal)) {
            integer = rawValue.slice(rawValue.slice(0, prefixLength) === prefix ? prefixLength : 0, indexOfLastDecimal);
            fraction = rawValue.slice(indexOfLastDecimal + 1, rawValueLength);
            fraction = convertToMask(fraction.replace(NON_DIGITS_RE, EMPTY_STRING));
        }
        else {
            if (rawValue.slice(0, prefixLength) === prefix) {
                integer = rawValue.slice(prefixLength);
            }
            else {
                integer = rawValue;
            }
        }
        if (isNumber(integerLimit)) {
            var numberOfThousandSeparators = (integer.match(new RegExp("" + thousandsSeparatorSymbol, 'g')) || []).length;
            integer = integer.slice(0, integerLimit + (numberOfThousandSeparators * thousandsSeparatorSymbolLength));
        }
        integer = integer.replace(NON_DIGITS_RE, EMPTY_STRING) /*.replace(START_ZERO_RE, '$1')*/;
        integer = (includeThousandsSeparator) ? addThousandsSeparator(integer, thousandsSeparatorSymbol) : integer;
        var needsLeadingZero = integer === EMPTY_STRING && (!isNegative || hasDecimal);
        mask = needsLeadingZero ? ['0'] : convertToMask(integer);
        if ((hasDecimal && allowDecimal) || requireDecimal === true) {
            if (rawValue[indexOfLastDecimal - 1] !== decimalSymbol) {
                mask.push(CARET_TRAP);
            }
            mask.push(decimalRe, CARET_TRAP);
            if (fraction) {
                if (isNumber(decimalLimit)) {
                    fraction = fraction.slice(0, decimalLimit);
                }
                mask = mask.concat(fraction);
            }
            if (requireDecimal === true && rawValue[indexOfLastDecimal - 1] === decimalSymbol) {
                mask.push(DIGIT_RE);
            }
        }
        if (prefixLength > 0) {
            mask = prefix.split(EMPTY_STRING).concat(mask);
        }
        if (isNegative) {
            // If user is entering a negative number, add a mask placeholder spot to attract the caret to it.
            if (mask.length === prefixLength && needsLeadingZero) {
                mask.push(DIGIT_RE);
            }
            mask = [MINUS_RE].concat(mask);
        }
        if (suffix.length > 0) {
            mask = mask.concat(suffix.split(EMPTY_STRING));
        }
        return mask;
    }
    numberMask['instanceOf'] = 'createNumberMask';
    return numberMask;
}
function convertToMask(strNumber) {
    return strNumber
        .split(EMPTY_STRING)
        .map(function (char) { return DIGIT_RE.test(char) ? DIGIT_RE : char; });
}
// http://stackoverflow.com/a/10899795/604296
function addThousandsSeparator(n, thousandsSeparatorSymbol) {
    return n.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparatorSymbol);
}
