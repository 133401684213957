/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers no-null-keyword no-submodule-imports no-console

import { Inject, InjectionToken } from '@angular/core';
import { isObservable, parseUrl } from '@gh/core-util';
import { of$, throwError$ } from '@gh/rx';
import { delay$, switchMap$ } from '@gh/rx/operators';
import { InMemoryBackendConfig, InMemoryDbService, RequestInfo } from 'angular-in-memory-web-api';
import { isNil } from 'lodash';
import { MockDataRegistry } from './mock-data.interfaces';

const URL_MEANING_NAME = /^.+:\/\/[^\/]+([^?]+)/;
const ID_RE = /([^/])+$/;

export const MOCK_DATA = new InjectionToken<MockDataRegistry>('MockData');

export class MockDataService implements InMemoryDbService {
  constructor(@Inject(MOCK_DATA) private registry: MockDataRegistry,
              private config: InMemoryBackendConfig) {
  }

  createDb(): any {
    return {};
  }

  parseRequestUrl(url: string): any {
    const match = URL_MEANING_NAME.exec(url);
    const parsedUrl = parseUrl(url);
    const idMatch = ID_RE.exec(parsedUrl.pathname);

    return {
      base: '',
      collectionName: '',
      id: idMatch ? idMatch[0] : '',
      query: parsedUrl.query,
      resourceUrl: match ? match[1] : url,
    };
  }

  get(request: RequestInfo): any {
    return this.request(request);
  }

  put(request: RequestInfo): any {
    return this.request(request);
  }

  post(request: RequestInfo): any {
    return this.request(request);
  }

  patch(request: RequestInfo): any {
    return this.request(request);
  }

  delete(request: RequestInfo): any {
    return this.request(request);
  }

  private request(request: RequestInfo): any {
    const endpoint = this.registry.getEndpoint(request.resourceUrl, request.method);
    if (isNil(endpoint)) {
      console.log(`External: ${request.method.toUpperCase()} ${request.resourceUrl}`);
      return;
    }

    if (endpoint.supportsMethod(request.method)) {
      console.log(`Mocked:   ${request.method.toUpperCase()} ${request.resourceUrl}`);
      const response = endpoint.process(request);
      if (response) {
        if (isObservable(response)) {
          return response;
        } else {
          return of$(true).pipe(
            delay$(this.config['delay'] || 0),
            switchMap$(() => response.status < 400 ? of$(response) : throwError$(response)));
        }
      }
    } else {
      console.log(`External: ${request.method.toUpperCase()} ${request.resourceUrl}`);
    }
  }
}
