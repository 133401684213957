/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component } from '@angular/core';

@Component({
  selector: 'gh-ext-not-found-page',
  templateUrl: 'ext-not-found-page.component.html',
  styleUrls: ['ext-not-found-page.component.scss'],
})
export class ExtNotFoundPageComponent {

}
