/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, ElementRef, Inject, Input } from '@angular/core';
import { ExtLoadTrackerComponent } from '../../components';
import { EXT_INFINITE_SCROLL_DIMENSIONS, EXT_INFINITE_SCROLL_VIEWPORT, } from '../ext-infinite-scroll.interfaces';
import * as i0 from "@angular/core";
var _c0 = ["*"];
var ExtInfiniteListComponent = /** @class */ (function () {
    function ExtInfiniteListComponent(elementRef, viewport) {
        this.elementRef = elementRef;
        this.viewport = viewport;
        this.offsetTop = 0;
        this.itemHeight = 50;
        this.loadThresholdCount = 10;
        this.pageSize = 50;
    }
    ExtInfiniteListComponent.prototype.ngOnInit = function () {
        this.viewport.setDimensions(this.elementRef.nativeElement, this);
        this.updateHeight();
    };
    ExtInfiniteListComponent.prototype.ngOnDestroy = function () {
        this.viewport.resetDimensions(this.elementRef.nativeElement, this);
    };
    ExtInfiniteListComponent.prototype.updateHeight = function () {
        if (this.viewport.rowCount >= 0) {
            this.elementRef.nativeElement.style.height = this.viewport.rowCount * this.itemHeight + "px";
        }
    };
    ExtInfiniteListComponent.ɵfac = function ExtInfiniteListComponent_Factory(t) { return new (t || ExtInfiniteListComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(EXT_INFINITE_SCROLL_VIEWPORT)); };
    ExtInfiniteListComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtInfiniteListComponent, selectors: [["gh-ext-infinite-list"]], inputs: { offsetTop: "offsetTop", itemHeight: "itemHeight", loadThresholdCount: "loadThresholdCount", pageSize: "pageSize", tracker: "tracker" }, exportAs: ["ghExtInfiniteList"], features: [i0.ɵɵProvidersFeature([{ provide: EXT_INFINITE_SCROLL_DIMENSIONS, useExisting: ExtInfiniteListComponent }])], ngContentSelectors: _c0, decls: 1, vars: 0, template: function ExtInfiniteListComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵprojection(0);
        } }, styles: ["[_nghost-%COMP%] {\n  position: relative; }"] });
    return ExtInfiniteListComponent;
}());
export { ExtInfiniteListComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtInfiniteListComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-infinite-list',
                template: "\n    <ng-content></ng-content>",
                styleUrls: ['ext-infinite-list.component.scss'],
                providers: [{ provide: EXT_INFINITE_SCROLL_DIMENSIONS, useExisting: ExtInfiniteListComponent }],
                exportAs: 'ghExtInfiniteList',
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: undefined, decorators: [{
                type: Inject,
                args: [EXT_INFINITE_SCROLL_VIEWPORT]
            }] }]; }, { offsetTop: [{
            type: Input
        }], itemHeight: [{
            type: Input
        }], loadThresholdCount: [{
            type: Input
        }], pageSize: [{
            type: Input
        }], tracker: [{
            type: Input
        }] }); })();
