/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { get, isNil } from 'lodash';
import * as i0 from "@angular/core";
var TranslationStorage = /** @class */ (function () {
    function TranslationStorage() {
        this.maps = [];
    }
    TranslationStorage.prototype.putAll = function (map) {
        this.maps.push(map);
    };
    TranslationStorage.prototype.get = function (key) {
        var maps = this.maps;
        for (var _i = 0, maps_1 = maps; _i < maps_1.length; _i++) {
            var map = maps_1[_i];
            var translation = get(map, key);
            if (!isNil(translation)) {
                return translation;
            }
        }
    };
    TranslationStorage.ɵfac = function TranslationStorage_Factory(t) { return new (t || TranslationStorage)(); };
    TranslationStorage.ɵprov = i0.ɵɵdefineInjectable({ token: TranslationStorage, factory: TranslationStorage.ɵfac });
    return TranslationStorage;
}());
export { TranslationStorage };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TranslationStorage, [{
        type: Injectable
    }], null, null); })();
