/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { EMPTY$, of$ } from '@gh/rx';
import { Message, MessageSeverity } from './message';
export function isMessageBasket(value) {
    return value instanceof MessageBasket;
}
var MessageBasket = /** @class */ (function () {
    function MessageBasket(messages) {
        this._messages = Array.isArray(messages) ? messages : [messages];
    }
    MessageBasket.empty = function () {
        return new MessageBasket([]);
    };
    MessageBasket.success = function (key, params) {
        return MessageBasket.just(key, MessageSeverity.Success, params);
    };
    MessageBasket.info = function (key, params) {
        return MessageBasket.just(key, MessageSeverity.Info, params);
    };
    MessageBasket.warning = function (key, params) {
        return MessageBasket.just(key, MessageSeverity.Warning, params);
    };
    MessageBasket.error = function (key, params) {
        return MessageBasket.just(key, MessageSeverity.Error, params);
    };
    MessageBasket.confirmation = function (key, params) {
        return MessageBasket.just(key, MessageSeverity.Confirmation, params);
    };
    MessageBasket.just = function (key, severity, params) {
        return new MessageBasket(Message.create(key, severity, params));
    };
    MessageBasket.create = function (messages) {
        return new MessageBasket(messages);
    };
    Object.defineProperty(MessageBasket.prototype, "messages", {
        get: function () {
            return this._messages;
        },
        enumerable: true,
        configurable: true
    });
    MessageBasket.prototype.has = function (key) {
        return this._messages.some(function (message) { return message.key === key; });
    };
    MessageBasket.prototype.get = function (key) {
        return this.find(key)[0];
    };
    MessageBasket.prototype.at = function (i) {
        return this._messages[i];
    };
    MessageBasket.prototype.find = function (key) {
        return this._messages.filter(function (message) { return message.key === key; });
    };
    MessageBasket.prototype.count = function () {
        return this._messages.length;
    };
    MessageBasket.prototype.map = function (transform) {
        return MessageBasket.create(this.messages.map(transform));
    };
    Object.defineProperty(MessageBasket.prototype, "length", {
        get: function () {
            return this.count();
        },
        enumerable: true,
        configurable: true
    });
    MessageBasket.prototype.getAsObservable = function (key) {
        var message = this.get(key);
        return message ? of$(message) : EMPTY$;
    };
    MessageBasket.prototype.findAsObservable = function (key) {
        var messages = this.find(key);
        return messages.length > 0 ? of$(messages) : EMPTY$;
    };
    MessageBasket.prototype[Symbol.iterator] = function () {
        var _this = this;
        var pointer = 0;
        return {
            next: function () {
                return pointer < _this._messages.length
                    ? {
                        done: false,
                        value: _this._messages[pointer++],
                    }
                    : {
                        value: void 0,
                        done: true,
                    };
            },
        };
    };
    return MessageBasket;
}());
export { MessageBasket };
