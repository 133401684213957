/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { REGION_CODE } from '@gh/config';

export const REGION_US: string = 'us';
export const REGION_AU: string = 'au';
export const REGION_NZ: string = 'nz';

export function isRegionAnyOf(...regions: string[]): boolean {
  return regions.some(isCurrentRegion);
}

export function isCurrentRegion(region: string): boolean {
  return REGION_CODE === region;
}

export const IS_REGION_US = REGION_CODE === REGION_US;
export const IS_REGION_AU = REGION_CODE === REGION_AU;
export const IS_REGION_NZ = REGION_CODE === REGION_NZ;
export const IS_REGION_AU_OR_NZ = REGION_CODE === REGION_AU || REGION_CODE === REGION_NZ;
