/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ValidationErrors } from '@angular/forms';
import { TextType, TextTypeDefenition } from '../type.interfaces';
import { StringTypeValidators } from '../validators';
import { AbstractPrimitiveType } from './abstract-type';

export class TextTypeImpl extends AbstractPrimitiveType<string> implements TextType {
  maxLength?: number;
  pattern?: RegExp;

  constructor(defenition: TextTypeDefenition) {
    super(defenition, StringTypeValidators);

    if (defenition.constraints) {
      this.maxLength = defenition.constraints.maxLength;
      this.pattern = defenition.constraints.pattern;
    }
  }

  parse(str: string): string {
    return str;
  }

  format(value: string): string {
    return value;
  }

  validateFormat(str: string): Maybe<ValidationErrors> {
    return this.validate(str);
  }
}
