/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { getRouteParam } from '@gh/common-shared';
import { CustomerEDocumentRestService } from '@gh/customer-shared-data';
import * as i0 from "@angular/core";
import * as i1 from "@gh/customer-shared-data";
var CustomerEDocumentBusinessService = /** @class */ (function () {
    function CustomerEDocumentBusinessService(restService) {
        this.restService = restService;
    }
    CustomerEDocumentBusinessService.prototype.initView = function (route) {
        var jobId = getRouteParam(route, 'jobId');
        return {
            eDocumentList: this.findAll(jobId),
        };
    };
    CustomerEDocumentBusinessService.prototype.findAll = function (jobId) {
        return this.restService.findAll(jobId);
    };
    CustomerEDocumentBusinessService.ɵfac = function CustomerEDocumentBusinessService_Factory(t) { return new (t || CustomerEDocumentBusinessService)(i0.ɵɵinject(i1.CustomerEDocumentRestService)); };
    CustomerEDocumentBusinessService.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerEDocumentBusinessService, factory: CustomerEDocumentBusinessService.ɵfac });
    return CustomerEDocumentBusinessService;
}());
export { CustomerEDocumentBusinessService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerEDocumentBusinessService, [{
        type: Injectable
    }], function () { return [{ type: i1.CustomerEDocumentRestService }]; }, null); })();
