var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { concatMap$, filter$, share$ } from '@gh/rx/operators';
import { isNil, omit, uniqueId } from 'lodash';
import { Subject } from 'rxjs';
import { isUploadFinalEvent, UploadEngine, UploadStatus } from './upload.interfaces';
import * as i0 from "@angular/core";
/**
 * Abstract implementation of {@link UploadEngine} provides basic managing of upload processes.
 * Concrete implementation should provide implementation of {@link UploadProcess} interface.
 *
 * This implementation constructs an upload queue.
 */
var AbstractUploadEngine = /** @class */ (function (_super) {
    __extends(AbstractUploadEngine, _super);
    function AbstractUploadEngine() {
        var _this = _super.call(this) || this;
        _this.process$$ = new Subject();
        _this.processIndex = {};
        // tslint:disable-next-line:member-ordering
        _this.event$ = _this.process$$.pipe(concatMap$(function (process) { return process.event$; }), share$());
        _this.event$
            .pipe(filter$(isUploadFinalEvent))
            .subscribe(function (event) {
            var uploadId = event.item.uploadId;
            if (uploadId) {
                _this.removeUploadProcess(uploadId);
            }
        });
        return _this;
    }
    AbstractUploadEngine.prototype.ngOnDestroy = function () {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
    };
    AbstractUploadEngine.prototype.create = function (file) {
        return {
            name: file.name,
            mimeType: file.mimeType || file.blob.type,
            status: UploadStatus.NotStarted,
            uploadId: uniqueId('upload-item:'),
            blob: file.blob,
        };
    };
    AbstractUploadEngine.prototype.start = function (item, params) {
        if (isNil(item.uploadId)) {
            throw new Error('Upload item does not have id assigned');
        }
        var process = this.createUploadProcess(item, params);
        this.processIndex[item.uploadId] = process;
        this.process$$.next(process);
    };
    AbstractUploadEngine.prototype.cancel = function (item) {
        var uploadId = item.uploadId;
        if (isNil(uploadId)) {
            throw new Error('Upload item does not have id assigned');
        }
        var process = this.processIndex[uploadId];
        process.cancel();
        this.removeUploadProcess(uploadId);
    };
    AbstractUploadEngine.prototype.removeUploadProcess = function (uploadId) {
        this.processIndex = omit(this.processIndex, uploadId);
    };
    AbstractUploadEngine.ɵfac = function AbstractUploadEngine_Factory(t) { return new (t || AbstractUploadEngine)(); };
    AbstractUploadEngine.ɵprov = i0.ɵɵdefineInjectable({ token: AbstractUploadEngine, factory: AbstractUploadEngine.ɵfac });
    return AbstractUploadEngine;
}(UploadEngine));
export { AbstractUploadEngine };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AbstractUploadEngine, [{
        type: Injectable
    }], function () { return []; }, null); })();
