import { Directive, ElementRef, Input } from '@angular/core';
import { domOffset, fromAnimationFrame$ } from '@gh/core-util';
import { fromEvent$ } from '@gh/rx';
import { distinctUntilChanged$, filter$, finalize$, map$, mapTo$, sample$, startWith$, switchMap$, tap$, } from '@gh/rx/operators';
import { BehaviorSubject } from 'rxjs';
import { EXT_INFINITE_SCROLL_VIEWPORT, } from './ext-infinite-scroll.interfaces';
import * as i0 from "@angular/core";
var ExtInfiniteScrollViewportDirective = /** @class */ (function () {
    function ExtInfiniteScrollViewportDirective(elementRef) {
        this.elementRef = elementRef;
        this.lastRequestedStart = Number.MAX_SAFE_INTEGER;
        this.lastRequestedEnd = 0;
        this._rowCount = 0;
        this._topRowIndex = -1;
        this.containerTopOffset = 0;
        this.viewportElement$$ = new BehaviorSubject(this.elementRef.nativeElement);
        this.dimensions$$ = new BehaviorSubject(void 0);
        this.dimensionsHaveChanged = false;
        this.viewportHasChanged = false;
    }
    Object.defineProperty(ExtInfiniteScrollViewportDirective.prototype, "viewport", {
        set: function (viewport) {
            if (this._viewport !== viewport) {
                this._viewport = viewport;
                this.viewportHasChanged = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtInfiniteScrollViewportDirective.prototype, "topRowIndex", {
        get: function () {
            return this._topRowIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtInfiniteScrollViewportDirective.prototype, "rowCount", {
        get: function () {
            return this._rowCount;
        },
        enumerable: true,
        configurable: true
    });
    ExtInfiniteScrollViewportDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.visibleRange = this.viewportElement$$.pipe(switchMap$(function (element) { return element === document.documentElement
            ? fromAnimationFrame$().pipe(map$(function () { return element.scrollTop; }), distinctUntilChanged$(), mapTo$(element))
            : fromEvent$(element, 'scroll').pipe(sample$(fromAnimationFrame$()), startWith$(void 0), mapTo$(element)); }), map$(function (element) { return ({ top: element.scrollTop, bottom: element.scrollTop + element.clientHeight }); }));
        var dimensions$ = this.dimensions$$.pipe(filter$(Boolean));
        this.visibleItemsRange = dimensions$.pipe(switchMap$(function (_a) {
            var itemHeight = _a.itemHeight;
            return _this.visibleRange.pipe(
            // get rid of anchor offset height
            map$(function (_a) {
                var top = _a.top, bottom = _a.bottom;
                var collapseHeight = top > _this.containerTopOffset ? _this.containerTopOffset : top;
                return {
                    top: top - collapseHeight,
                    bottom: bottom - collapseHeight,
                };
            }), 
            // map to range of items
            map$(function (_a) {
                var top = _a.top, bottom = _a.bottom;
                return ({
                    start: Math.floor(top / itemHeight),
                    end: Math.ceil(bottom / itemHeight),
                });
            }));
        }), tap$({
            next: function (_a) {
                var start = _a.start;
                if (!_this.dimensionsHaveChanged) {
                    _this._topRowIndex = start;
                }
            },
            complete: function () {
                _this._topRowIndex = -1;
            },
        }));
        this.viewChange = dimensions$.pipe(switchMap$(function (_a) {
            var loadThresholdCount = _a.loadThresholdCount, pageSize = _a.pageSize;
            return _this.visibleItemsRange.pipe(
            // map to range of items with threshold to load more items
            map$(function (_a) {
                var start = _a.start, end = _a.end;
                return ({
                    start: Math.max(0, start - loadThresholdCount),
                    end: end + loadThresholdCount,
                });
            }), 
            // if we need to load more items
            filter$(function (_a) {
                var start = _a.start, end = _a.end;
                return !(start >= _this.lastRequestedStart && end <= _this.lastRequestedEnd);
            }), 
            // round range to page size
            map$(function (_a) {
                var start = _a.start, end = _a.end;
                return ({
                    start: Math.floor(start / pageSize) * pageSize,
                    end: Math.ceil(end / pageSize) * pageSize,
                });
            }), 
            // update loaded range bounds
            tap$(function (_a) {
                var start = _a.start, end = _a.end;
                _this.lastRequestedStart = start;
                _this.lastRequestedEnd = end;
            }), 
            // reset loaded range bounds when stream completes/error/unsubscribe
            finalize$(function () {
                _this.lastRequestedStart = Number.MAX_SAFE_INTEGER;
                _this.lastRequestedEnd = 0;
            }));
        }));
    };
    ExtInfiniteScrollViewportDirective.prototype.ngOnDestroy = function () {
        this.dimensions$$.complete();
        this.viewportElement$$.complete();
    };
    ExtInfiniteScrollViewportDirective.prototype.ngDoCheck = function () {
        // apply viewport changes
        if (this.viewportHasChanged) {
            this.updateViewportElement();
        }
        // apply dimension changes
        if (this.dimensionsHaveChanged || this.viewportHasChanged) {
            this.dimensions$$.next(this.nextDimensions);
            this.containerElement = this.nextContainerElement;
            this.updateContainerOffset();
            this.nextDimensions = void 0;
            this.nextContainerElement = void 0;
            // restore scroll position
            var viewportElement = this.viewportElement$$.value;
            var dimensions = this.dimensions$$.value;
            if (dimensions) {
                viewportElement.scrollTop = this._topRowIndex === 0
                    ? 0
                    : this.containerTopOffset + this._topRowIndex * dimensions.itemHeight;
            }
            else {
                this._rowCount = 0;
                this._topRowIndex = -1;
                this.lastRequestedStart = Number.MAX_SAFE_INTEGER;
                this.lastRequestedEnd = 0;
            }
        }
        if (this.dimensionsHaveChanged || this.viewportHasChanged) {
            this.viewportHasChanged = false;
            this.dimensionsHaveChanged = false;
        }
    };
    ExtInfiniteScrollViewportDirective.prototype.scrollToTop = function () {
        this.viewportElement$$.value.scrollTop = 0;
    };
    ExtInfiniteScrollViewportDirective.prototype.setDimensions = function (container, dimensions) {
        if (this.dimensions$$.value && this.nextDimensions) {
            throw new Error('ExtInfiniteScrollViewportDirective: try to set dimensions when it is already set');
        }
        // we postpone changes until changes are not propagated
        this.dimensionsHaveChanged = true;
        this.nextDimensions = dimensions;
        this.nextContainerElement = container;
    };
    ExtInfiniteScrollViewportDirective.prototype.resetDimensions = function (container, dimensions) {
        if (this.dimensions$$.value === dimensions) {
            // we postpone changes until changes are not propagated
            this.dimensionsHaveChanged = true;
            this.nextDimensions = void 0;
            this.nextContainerElement = void 0;
        }
        else {
            throw new Error('ExtInfiniteScrollViewportDirective: try to reset wrong dimensions');
        }
    };
    ExtInfiniteScrollViewportDirective.prototype.updateRowCount = function (requestedCount, loadedCount, firstRowIndex) {
        var newRowCount = this._rowCount;
        if (requestedCount === loadedCount) {
            newRowCount = Math.max(this._rowCount, firstRowIndex + loadedCount);
        }
        else if (requestedCount > loadedCount) {
            newRowCount = firstRowIndex + loadedCount;
        }
        if (newRowCount !== this._rowCount) {
            this._rowCount = newRowCount;
        }
    };
    ExtInfiniteScrollViewportDirective.prototype.updateViewportElement = function () {
        var viewport = this._viewport;
        if (viewport === 'root') {
            this.viewportElement$$.next(document.documentElement); // tslint:disable-line:no-non-null-assertion
        }
        else if (viewport instanceof HTMLElement) {
            this.viewportElement$$.next(viewport);
        }
        else {
            this.viewportElement$$.next(this.elementRef.nativeElement);
        }
    };
    ExtInfiniteScrollViewportDirective.prototype.updateContainerOffset = function () {
        var viewport = this._viewport;
        if (this.containerElement) {
            if (viewport === 'root') {
                this.containerTopOffset = domOffset(this.containerElement).top;
            }
            else if (viewport instanceof HTMLElement) {
                throw new Error('ExtInfiniteScrollViewportDirective: anchor as HTMLElement is not supported yet');
            }
            else {
                this.containerTopOffset = domOffset(this.containerElement).top - domOffset(this.elementRef.nativeElement).top;
            }
        }
        else {
            this.containerTopOffset = 0;
        }
    };
    ExtInfiniteScrollViewportDirective.ɵfac = function ExtInfiniteScrollViewportDirective_Factory(t) { return new (t || ExtInfiniteScrollViewportDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    ExtInfiniteScrollViewportDirective.ɵdir = i0.ɵɵdefineDirective({ type: ExtInfiniteScrollViewportDirective, selectors: [["", "ghExtInfiniteScrollViewport", ""]], inputs: { viewport: ["ghExtInfiniteScrollViewport", "viewport"] }, features: [i0.ɵɵProvidersFeature([{ provide: EXT_INFINITE_SCROLL_VIEWPORT, useExisting: ExtInfiniteScrollViewportDirective }])] });
    return ExtInfiniteScrollViewportDirective;
}());
export { ExtInfiniteScrollViewportDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtInfiniteScrollViewportDirective, [{
        type: Directive,
        args: [{
                selector: '[ghExtInfiniteScrollViewport]',
                providers: [{ provide: EXT_INFINITE_SCROLL_VIEWPORT, useExisting: ExtInfiniteScrollViewportDirective }],
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { viewport: [{
            type: Input,
            args: ['ghExtInfiniteScrollViewport']
        }] }); })();
