/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { appendElementToHead, createLinkElement, createScriptElement, isNotNil } from '@gh/core-util';
import { combineLatest$, EMPTY$, of$, throwError$ } from '@gh/rx';
import { first$, mapTo$, pluck$, switchMap$ } from '@gh/rx/operators';
import { isNil, isString, uniqueId } from 'lodash';
import { BehaviorSubject, Observable, Observer } from 'rxjs';

export enum ResourceLoadingStatus { NotLoaded, Loading, Loaded, Error }

type Resource = {
  status: ResourceLoadingStatus;
  ob$: Observable<any>;
  dependencies: string[];
  error?: any;
};
const createResourceElement = (url: string) => /\.css$/.test(url) ? createLinkElement(url) : createScriptElement(url);

const createResourceInjector = (url: string) => new Observable((observer: Observer<boolean>) => {
  const el = createResourceElement(url);
  appendElementToHead(el);
  el.onload = () => {
    observer.next(true);
    observer.complete();
  };
  el.onerror = (error) => {
    observer.error(error);
  };
});
const createResource = (urlOb: string | Observable<any>, dependencies: string[]) => ({
  status: ResourceLoadingStatus.NotLoaded,
  ob$: isString(urlOb) ? createResourceInjector(urlOb) : urlOb,
  dependencies,
});

const toResourceStream = (resource: Resource) => {
  if (resource.status === ResourceLoadingStatus.Loaded) {
    return of$(true);
  } else if (resource.status === ResourceLoadingStatus.Error) {
    return throwError$(resource.error);
  } else {
    return EMPTY$;
  }
};

@Injectable()
export class ResourceLoader {
  private state = new BehaviorSubject<Hash<Resource>>({});

  require(...names: string[]): Observable<boolean> {
    return names.length ? combineLatest$(names.map((name) => this.loadSingle(name))).pipe(mapTo$(true)) : of$(true);
  }

  define(url: string): void;
  define(name: string, url: string, dependencies?: string[]): void;
  define(ob$: Observable<any>): void;
  define(name: string, ob$: Observable<any>, dependencies?: string[]): void;
  define(nameUrlOb: string | Observable<any>, urlOb?: string | Observable<any>, dependencies?: string[]): void {
    const name = isString(nameUrlOb) ? nameUrlOb : uniqueId('resource:');

    if (this.isDefined(name)) {
      throw new Error(`Resource for name '${name}' is already defined`);
    }

    const resource = createResource(urlOb || nameUrlOb, dependencies || []);
    this.state.next({ ...this.state.value, [name]: resource });
  }

  isDefined(name: string): boolean {
    return isNotNil(this.state.value[name]);
  }

  verify(...nameOrUrls: string[]): void {
    const resources = nameOrUrls.map((url) => ({ url, resource: this.state.value[url] }));
    const notLoadedResources = resources
      .filter(({ url, resource }) => isNil(resource) || resource.status !== ResourceLoadingStatus.Loaded);

    if (notLoadedResources.length) {
      throw new Error(`Resource '${notLoadedResources.map(({ url }) => url).join(', ')}' is not loaded`);
    }
  }

  private loadSingle(name: string): Observable<boolean> {
    const resource = this.ensureDefined(name);

    switch (resource.status) {
      case ResourceLoadingStatus.Loaded:
        return of$(true);
      case ResourceLoadingStatus.Loading:
        return this.state.pipe(pluck$(name), switchMap$(toResourceStream), first$());
      default:
        this.startLoading(name);
        return this.state.pipe(pluck$(name), switchMap$(toResourceStream), first$());
    }
  }

  private startLoading(name: string): void {
    this.setStatus(name, ResourceLoadingStatus.Loading);

    const resource = this.get(name);
    const dependencies$ = resource.dependencies.length > 0 ? this.require(...resource.dependencies) : of$(true);
    dependencies$.pipe(
      switchMap$(() => resource.ob$))
      .subscribe(
        () => this.setStatus(name, ResourceLoadingStatus.Loaded),
        (error) => this.setStatus(name, ResourceLoadingStatus.Error, error));
  }

  private setStatus(name: string, status: ResourceLoadingStatus, error?: any): void {
    const { state } = this;
    state.next({ ...state.value, [name]: { ...state.value[name], status, error } });
  }

  private ensureDefined(name: string): Resource {
    if (!this.isDefined(name)) {
      this.define(name);
    }

    return this.get(name);
  }

  private get(name: string): Resource {
    return this.state.value[name];
  }
}
