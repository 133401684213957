/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { isNil, isString } from 'lodash';
import { Type } from '../core';
import { TypeService } from '../services/type.service';

@Pipe({
  name: 'typeFormat',
})
export class TypeFormatPipe implements PipeTransform {
  constructor(private typeService: TypeService) {

  }

  transform(value: any, name: string | Type<any>): any {
    if (isNil(name) || name === '') {
      throw new Error('Type is not provided into "formatType" pipe');
    }

    const type = isString(name) ? this.typeService.get(name) : name;

    if (isNil(type)) {
      throw new Error(`Type was not found for name ${name}`);
    }

    return isNil(value) ? value : type.format(value);
  }
}
