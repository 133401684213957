/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialogRef } from '@angular/material/dialog';
import { filter$ } from '@gh/rx/operators';
import { Subscription } from 'rxjs';

export type ExtDialogContentType = 'center' | 'text';

@Component({
  selector: 'gh-ext-dialog',
  template: '<ng-content></ng-content>',
  styleUrls: ['ext-dialog.component.scss'],
})
export class ExtDialogComponent implements OnInit, OnDestroy {
  @Input() layout: ExtDialogContentType;

  private _forBreakpoint: string;
  private mediaSubscription: Subscription;

  @Input()
  set forBreakpoint(value: any) {
    this._forBreakpoint = value;
  }

  @HostBinding('class')
  get layoutClass(): string {
    return `m-dialog-layout-${this.layout}`;
  }

  constructor(private dialogRef: MatDialogRef<any>,
              private observer: MediaObserver) {
  }

  ngOnInit(): void {
    if (this._forBreakpoint) {
      this.mediaSubscription = this.observer
        .media$.pipe(
          filter$(() => !this.observer.isActive(this._forBreakpoint)))
        .subscribe(() => this.dialogRef.close());
    }
  }

  ngOnDestroy(): void {
    if (this.mediaSubscription) {
      this.mediaSubscription.unsubscribe();
    }
  }

}
