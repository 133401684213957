/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '@gh/core-mls';
import { ConfirmationDialogBaseComponent } from '@gh/core-ui';

@Component({
  selector: 'gh-ext-confirmation-dialog',
  templateUrl: 'ext-confirmation-dialog.component.html',
  styleUrls: ['ext-confirmation-dialog.component.scss'],
})
export class ExtConfirmationDialogComponent extends ConfirmationDialogBaseComponent {

  constructor(translationService: TranslationService,
              dialogRef: MatDialogRef<ConfirmationDialogBaseComponent>,
              elementRef: ElementRef,
              renderer: Renderer2) {
    super(translationService, dialogRef, elementRef, renderer);
  }
}
