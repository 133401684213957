/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DatePipe } from '@angular/common';
import { Locale } from '../locale';
import { dateParse, isDateValid } from './date-parser';
import { DateTimeFormat, DateTimeParser } from './localization-provider';

export class DateTimeFormatImpl implements DateTimeFormat {
  constructor(private locale: Locale,
              private formats: Hash<string>,
              private datePipe: DatePipe) {
  }

  format(formatName: string, date: Date): string {
    return <string>this.datePipe.transform(date, this.formats[formatName] || formatName, void 0, this.locale.code);
  }
}

export class DateTimeParserImpl implements DateTimeParser {
  constructor(private formats: Hash<string>) {

  }

  parse(formatName: string, str: string): Date {
    return dateParse(str, this.formats[formatName]);
  }

  validate(formatName: string, str: string): boolean {
    return isDateValid(str, this.formats[formatName]);
  }
}
