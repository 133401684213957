/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'gh-ext-load-tracker',
  templateUrl: 'ext-load-tracker.component.html',
  styleUrls: ['ext-load-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'ghExtLoadTracker',
})
export class ExtLoadTrackerComponent {
  isForegroundLoading: boolean = false;
  isBackgroundLoading: boolean = false;
  isNoData: boolean = false;

  @Input()
  set noData(noData: any) {
    this.isNoData = coerceBooleanProperty(noData);
  }

  get noData(): any {
    return this.isNoData;
  }

  @HostBinding('class.m-hidden')
  get isHidden(): boolean {
    return !(this.isForegroundLoading || this.isNoData);
  }

  private hasContent: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  reset(): void {
    this.isNoData = false;
    this.hasContent = false;
  }

  start(): void {
    if (this.hasContent) {
      this.isBackgroundLoading = true;
    } else {
      this.isForegroundLoading = true;
    }
    this.isNoData = false;

    this.changeDetectorRef.markForCheck();
  }

  stop(noData: boolean = false): void {
    this.isForegroundLoading = false;
    this.isBackgroundLoading = false;
    this.isNoData = noData;
    this.hasContent = !noData;

    this.changeDetectorRef.markForCheck();
  }
}
