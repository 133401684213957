/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

export const OPTION_HEIGHT = 48;
export const DEFAULT_NEXT_BATCH_SIZE = 50;
export const DEFAULT_CARD_OFFSET = 20;
export const DEFAULT_NEXT_BATCH_DISTANCE_PX = 100;
export const DEFAULT_MAX_VISIBLE_OPTIONS = 6;

export const PLACEHOLDER_PADDING_HORIZONTAL = 20;
export const BOTTOM_PLACEHOLDER_PADDING_TOP = 20;
export const BOTTOM_PLACEHOLDER_PADDING_BOTTOM = 5;
export const TOP_PLACEHOLDER_PADDING_TOP = 20;
export const TOP_PLACEHOLDER_PADDING_BOTTOM = 5;
export const PANEL_MIN_WIDTH = 300;
