/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { paymentsTotal } from '../../../resources';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common-external-shared/directives/ext-if-query.directive";
import * as i2 from "@angular/material/table";
import * as i3 from "@angular/material/sort";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "../../../../core-mls/translation/translate.pipe";
import * as i6 from "../../../../core-type/pipes/type-format.pipe";
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_header_cell_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-header-cell", 16);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "lbl_receipt_no"));
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_cell_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-cell", 17);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var payment_r13 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(payment_r13.reference);
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_footer_cell_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-footer-cell");
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_header_cell_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-header-cell", 18);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "lbl_date"));
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_cell_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-cell", 19);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "typeFormat");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var payment_r14 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(2, 1, payment_r14.paidDate, "ShortDate"));
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_footer_cell_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-footer-cell");
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_header_cell_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-header-cell", 20);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "lbl_paid_dollar"));
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_cell_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-cell", 21);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "typeFormat");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var payment_r15 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(2, 1, payment_r15.amount, "Price"));
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_footer_cell_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-footer-cell", 22);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementStart(3, "span", 23);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r9 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind1(2, 2, "lbl_total"), ":\u00A0");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r9.paidTotal());
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_header_row_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-header-row");
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_row_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-row");
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_footer_row_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-footer-row");
} }
function CustomerFinancialRecordPaymentListComponent_mat_table_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-table", 1);
    i0.ɵɵelementContainerStart(1, 2);
    i0.ɵɵtemplate(2, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_header_cell_2_Template, 3, 3, "mat-header-cell", 3);
    i0.ɵɵtemplate(3, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_cell_3_Template, 2, 1, "mat-cell", 4);
    i0.ɵɵtemplate(4, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_footer_cell_4_Template, 1, 0, "mat-footer-cell", 5);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementContainerStart(5, 6);
    i0.ɵɵtemplate(6, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_header_cell_6_Template, 3, 3, "mat-header-cell", 7);
    i0.ɵɵtemplate(7, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_cell_7_Template, 3, 4, "mat-cell", 8);
    i0.ɵɵtemplate(8, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_footer_cell_8_Template, 1, 0, "mat-footer-cell", 5);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementContainerStart(9, 9);
    i0.ɵɵtemplate(10, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_header_cell_10_Template, 3, 3, "mat-header-cell", 10);
    i0.ɵɵtemplate(11, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_cell_11_Template, 3, 4, "mat-cell", 11);
    i0.ɵɵtemplate(12, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_footer_cell_12_Template, 5, 4, "mat-footer-cell", 12);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵtemplate(13, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_header_row_13_Template, 1, 0, "mat-header-row", 13);
    i0.ɵɵtemplate(14, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_row_14_Template, 1, 0, "mat-row", 14);
    i0.ɵɵtemplate(15, CustomerFinancialRecordPaymentListComponent_mat_table_0_mat_footer_row_15_Template, 1, 0, "mat-footer-row", 15);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("dataSource", ctx_r0.paymentDataSource)("trackBy", ctx_r0.getPaymentId);
    i0.ɵɵadvance(13);
    i0.ɵɵproperty("matHeaderRowDef", ctx_r0.displayedColumns);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("matRowDefColumns", ctx_r0.displayedColumns);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("matFooterRowDef", ctx_r0.displayedColumns);
} }
var CustomerFinancialRecordPaymentListComponent = /** @class */ (function () {
    function CustomerFinancialRecordPaymentListComponent() {
        this.displayedColumns = ['reference', 'paidDate', 'amount'];
    }
    CustomerFinancialRecordPaymentListComponent.prototype.ngAfterViewInit = function () {
        this.paymentDataSource.sort = this.sort;
    };
    CustomerFinancialRecordPaymentListComponent.prototype.getPaymentId = function (index, payment) {
        return payment.paymentId;
    };
    CustomerFinancialRecordPaymentListComponent.prototype.paidTotal = function () {
        return paymentsTotal(this.paymentDataSource.data);
    };
    CustomerFinancialRecordPaymentListComponent.ɵfac = function CustomerFinancialRecordPaymentListComponent_Factory(t) { return new (t || CustomerFinancialRecordPaymentListComponent)(); };
    CustomerFinancialRecordPaymentListComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CustomerFinancialRecordPaymentListComponent, selectors: [["gh-customer-financial-record-payment-list"]], viewQuery: function CustomerFinancialRecordPaymentListComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(MatSort, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sort = _t.first);
        } }, inputs: { paymentDataSource: "paymentDataSource" }, decls: 1, vars: 1, consts: [["matSort", "", 3, "dataSource", "trackBy", 4, "ghExtIfQuery"], ["matSort", "", 3, "dataSource", "trackBy"], ["matColumnDef", "reference"], ["fxFlex", "30%", "mat-sort-header", "", "class", "cell-align-left", 4, "matHeaderCellDef"], ["fxFlex", "30%", "class", "cell-align-left", 4, "matCellDef"], [4, "matFooterCellDef"], ["matColumnDef", "paidDate"], ["fxFlex", "50%", "mat-sort-header", "", "class", "cell-align-left", 4, "matHeaderCellDef"], ["fxFlex", "50%", "class", "cell-align-left", 4, "matCellDef"], ["matColumnDef", "amount"], ["fxFlex", "20%", "mat-sort-header", "", "class", "cell-align-right", 4, "matHeaderCellDef"], ["fxFlex", "20%", "class", "cell-align-right", 4, "matCellDef"], ["class", "cell-align-right", 4, "matFooterCellDef"], [4, "matHeaderRowDef"], [4, "matRowDef", "matRowDefColumns"], [4, "matFooterRowDef"], ["fxFlex", "30%", "mat-sort-header", "", 1, "cell-align-left"], ["fxFlex", "30%", 1, "cell-align-left"], ["fxFlex", "50%", "mat-sort-header", "", 1, "cell-align-left"], ["fxFlex", "50%", 1, "cell-align-left"], ["fxFlex", "20%", "mat-sort-header", "", 1, "cell-align-right"], ["fxFlex", "20%", 1, "cell-align-right"], [1, "cell-align-right"], [1, "footer-total"]], template: function CustomerFinancialRecordPaymentListComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, CustomerFinancialRecordPaymentListComponent_mat_table_0_Template, 16, 5, "mat-table", 0);
        } if (rf & 2) {
            i0.ɵɵproperty("ghExtIfQuery", "gt-sm");
        } }, directives: [i1.ExtIfQueryDirective, i2.MatTable, i3.MatSort, i2.MatColumnDef, i2.MatHeaderCellDef, i2.MatCellDef, i2.MatFooterCellDef, i2.MatHeaderRowDef, i2.MatRowDef, i2.MatFooterRowDef, i2.MatHeaderCell, i4.DefaultFlexDirective, i3.MatSortHeader, i2.MatCell, i2.MatFooterCell, i2.MatHeaderRow, i2.MatRow, i2.MatFooterRow], pipes: [i5.TranslatePipe, i6.TypeFormatPipe], styles: ["mat-table[_ngcontent-%COMP%]   .mat-header-cell[_ngcontent-%COMP%] {\n  font-size: 18px;\n  color: rgba(112, 112, 112, 0.87); }\n\nmat-table[_ngcontent-%COMP%]   .mat-row[_ngcontent-%COMP%]:nth-of-type(odd) {\n  background: none; }\n\nmat-table[_ngcontent-%COMP%]   .mat-cell[_ngcontent-%COMP%] {\n  font-size: 18px;\n  color: rgba(112, 112, 112, 0.87); }\n\nmat-table[_ngcontent-%COMP%]   .mat-footer-cell[_ngcontent-%COMP%] {\n  font-size: 18px;\n  color: rgba(112, 112, 112, 0.87); }\n\nmat-table[_ngcontent-%COMP%]   .footer-total[_ngcontent-%COMP%] {\n  font-weight: bold; }"] });
    return CustomerFinancialRecordPaymentListComponent;
}());
export { CustomerFinancialRecordPaymentListComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerFinancialRecordPaymentListComponent, [{
        type: Component,
        args: [{
                selector: 'gh-customer-financial-record-payment-list',
                templateUrl: 'customer-financial-record-payment-list.component.html',
                styleUrls: ['customer-financial-record-payment-list.component.scss'],
            }]
    }], null, { paymentDataSource: [{
            type: Input
        }], sort: [{
            type: ViewChild,
            args: [MatSort, { static: false }]
        }] }); })();
