/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { isRouterStateLazyActivator } from '@gh/core-lazy';
import { DialogService } from '@gh/core-ui';
import { wrapInObservable } from '@gh/core-util';
import { FALSE$, interval$, of$, throwError$, TRUE$ } from '@gh/rx/index';
import { catchError$, concatMap$, filter$, first$, mapTo$, scan$, switchMap$ } from '@gh/rx/operators';
import * as invariant from 'invariant';
import { isFunction, isNil, reverse } from 'lodash';
import { Observable } from 'rxjs';
import { CanLeaveRoute } from './can-leave.interfaces';

@Injectable()
export class LeaveGuard implements CanDeactivate<CanLeaveRoute> {
  constructor(private dialogs: DialogService) {

  }

  canDeactivate(component: CanLeaveRoute,
                route: ActivatedRouteSnapshot,
                _: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // always allow any lazy activator route as it never results to location change
    if (nextState && isRouterStateLazyActivator(nextState)) {
      return true;
    }

    // try to close all dialogs first
    return this.closeAllDialogs().pipe(
      switchMap$((allowToClose) => {
        if (!allowToClose) {
          return FALSE$;
        }

        // if current route supports leave flow then delegate deactivate logic to component itself
        const supportsLeave = route.data['leave'];
        if (supportsLeave) {
          invariant(
            isFunction(component.canDeactivate),
            `Component for route ${route.toString()} does not have canDeactivate function`,
          );
          return interval$(1).pipe(
            first$(),
            switchMap$(() => wrapInObservable<boolean>(component.canDeactivate(nextState))));
        } else {
          return TRUE$;
        }
      }));
  }

  private closeAllDialogs(): Observable<boolean> {
    // close dialogs starting from the last one
    const { openedDialogs } = this.dialogs;
    if (openedDialogs.length === 0) {
      return TRUE$;
    }
    return of$(...reverse(openedDialogs)).pipe(
      concatMap$((dialogRef) => {
        const { componentInstance } = dialogRef;
        // if dialog is in loading state => complete loading
        if (isNil(componentInstance)) {
          dialogRef.close();
          return TRUE$;
        }

        // if dialog is not deactivatable => close dialog
        const isDeactivatable = componentInstance['canDeactivate'];
        if (!isDeactivatable) {
          dialogRef.close();
          return TRUE$;
        }

        // if dialog is deactivatable => run deactivate flow
        return wrapInObservable(componentInstance.canDeactivate()).pipe(
          switchMap$((allowToClose) => {
            if (allowToClose) {
              dialogRef.close();
              return TRUE$;
            } else {
              // if dialog cannot be closed => stop closeDialogs flow
              return throwError$('break');
            }
          }));
      }),
      scan$((sum: number, _: boolean) => sum + 1, 0),
      filter$((sum) => sum === openedDialogs.length),
      mapTo$(true),
      catchError$(() => FALSE$));
  }
}
