/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, DoCheck, ElementRef, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { get } from 'lodash';

/**
 * This directive helps to show loading effect for pending validations.
 * If assigned to component having ngModel or form* directive,
 * it adds spinner shown when corresponding control is in pending status.
 *
 * By setting [ghFormAsyncEffectPending]="..." attribute developer can manually control loading effect.
 *
 * Note!!! Currently this directive can be assigned only to input element.
 */
@Directive({
  selector: 'input[ghFormAsyncEffect], input[ghFormAsyncEffectPending]',
})
export class FormAsyncEffectDirective implements OnInit, OnDestroy, DoCheck {
  private spinnerEl?: HTMLElement;
  private _pending: boolean = false;
  private lastPending: boolean = false;

  constructor(private elementRef: ElementRef, @Optional() private ngControl: NgControl) {
  }

  @Input('ghFormAsyncEffectPending')
  set pending(pending: boolean) {
    this._pending = pending;
  }

  get pending(): boolean {
    return this._pending || get(this.ngControl, 'control.pending', false);
  }

  ngOnInit(): void {
    const spinnerEl = document.createElement('div');
    spinnerEl.classList.add('spinner', 'spinner-process', 'is-hidden');

    const parentEl = this.getParentElement();
    if (parentEl) {
      parentEl.appendChild(spinnerEl);
    }

    this.spinnerEl = spinnerEl;
  }

  ngOnDestroy(): void {
    const parentEl = this.getParentElement();
    if (parentEl && this.spinnerEl) {
      parentEl.removeChild(this.spinnerEl);
    }
    this.spinnerEl = void 0;
  }

  ngDoCheck(): void {
    const newPending = this.pending;

    if (newPending !== this.lastPending && this.spinnerEl) {
      this.spinnerEl.classList.toggle('is-hidden', !newPending);

      this.lastPending = newPending;
    }
  }

  getParentElement(): Maybe<HTMLElement> {
    return this.elementRef.nativeElement.parentElement;
  }
}
