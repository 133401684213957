/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { custom } from '@gh/core-data';
import { Locale } from '@gh/core-mls';

export const LocaleMapper = custom({
  serialize: (locale: Locale) => { // tslint:disable-line:arrow-return-shorthand
    return locale.toStandardString();
  },
  deserialize: (json) => { // tslint:disable-line:arrow-return-shorthand
    return Locale.createFromStandard(json);
  },
});
