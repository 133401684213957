/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { EXT_INFINITE_SCROLL_DIMENSIONS, ExtInfiniteScrollDimensions } from '../ext-infinite-scroll.interfaces';
import { getQueryItemIndex } from '../infinite-mat-data-source';

@Directive({
  selector: '[ghExtInfiniteRow]',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'ext-infinite-row',
  },
})
export class ExtInfiniteRowDirective {
  @Input('ghExtInfiniteRow')
  set index(data: any) {
    const top = this.dimensions.offsetTop + this.dimensions.itemHeight * getQueryItemIndex(data);
    this.elementRef.nativeElement.style.top = `${top}px`;
  }

  constructor(private elementRef: ElementRef,
              @Inject(EXT_INFINITE_SCROLL_DIMENSIONS) private dimensions: ExtInfiniteScrollDimensions) {
  }
}
