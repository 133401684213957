/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { SubstitutionCompiler, SubstitutionKey, TranslationStorage } from '@gh/core-mls';
import { logError } from '@gh/core-util';
import { isNil } from 'lodash';

@Injectable()
export class AppSubstitutionCompiler implements SubstitutionCompiler {
  constructor(private translationStorage: TranslationStorage) {
  }

  compileKey(key: string): SubstitutionKey {
    const dictionarySelectorIndex = key.indexOf('@');

    if (dictionarySelectorIndex >= 0) {
      return {
        name: key.substring(0, dictionarySelectorIndex),
        attributes: { dictionary: key.substring(dictionarySelectorIndex + 1) },
      };
    } else {
      return { name: key };
    }
  }

  apply(key: SubstitutionKey, value: string): string {
    const dictionary = key.attributes && key.attributes.dictionary;

    if (dictionary) {
      const dictionaryKey = `dictionary:${dictionary}:${value}`;
      const translation = this.translationStorage.get(dictionaryKey);

      if (isNil(translation)) {
        // tslint:disable-next-line:max-line-length prefer-template
        logError(`AppSubstitutionCompiler: translation was not found for message parameter '${key.name}@${dictionary}=${value}'.\n` +
          `Add translation for key '${dictionaryKey}'`);
      }

      return translation;
    } else {
      return value;
    }
  }
}
