/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpHeaders } from '@angular/common/http';
import { DataMapper } from './data-mapper';

export interface HeadersData {
  get(name: string): Nullable<string>;

  set(name: string, value: string | string[]): void;

  keys(): string[];
}

export class NativeHeadersData implements HeadersData {
  constructor(public headers: HttpHeaders) {

  }

  get(name: string): Nullable<string> {
    return this.headers.get(name);
  }

  set(name: string, value: string | string[]): void {
    this.headers.set(name, value);
  }

  keys(): string[] {
    return this.headers.keys();
  }
}

export class EmulatedHeadersData implements HeadersData {
  private headerKeys: string[] = [];
  private headerValues: { [name: string]: string[] } = {};

  get(name: string): string {
    return this.headerValues[name][0];
  }

  set(name: string, value: string | string[]): void {
    if (!this.headerValues.hasOwnProperty(name)) {
      this.headerKeys.push(name);
    }

    this.headerValues[name] = Array.isArray(value) ? value : [value];
  }

  keys(): string[] {
    return this.headerKeys;
  }
}

export interface HttpData {
  headers: HeadersData;
  body: any;
}

export function createHttpData(headers: HeadersData, body: any): HttpData {
  return { headers, body };
}

function isHttpDataMapper(mapper: DataMapper<any, any>): mapper is HttpDataMapper<any> {
  return mapper instanceof HttpDataMapper;
}

function toHttpData(data: any): HttpData {
  return <HttpData>data;
}

export const DEFAULT_SERIALIZATION_TYPE = 'application/json';
export const DEFAULT_DESERIALIZATION_TYPE = 'json';

export type DeserializationType = 'arraybuffer' | 'blob' | 'json' | 'text';

export abstract class HttpDataMapper<ObjectView> implements DataMapper<ObjectView, HttpData> {
  /**
   * Mime type used for serialization. If not specified 'application/json' is supposed
   */
  readonly serializationType: string;

  /**
   * Type of response.
   * List of deserialization types is compatible with {@link HttpRequest#responseType}
   * If not specified 'json' is supposed
   */
  readonly deserializationType: DeserializationType;

  constructor(private mapper: DataMapper<ObjectView, any>,
              serializationType?: string,
              deserializationType?: DeserializationType) {
    this.serializationType = serializationType
      || (isHttpDataMapper(this.mapper) ? this.mapper.serializationType : DEFAULT_SERIALIZATION_TYPE);
    this.deserializationType = deserializationType
      || (isHttpDataMapper(this.mapper) ? this.mapper.deserializationType : DEFAULT_DESERIALIZATION_TYPE);
  }

  serialize(obj: ObjectView): HttpData {
    const serialized = this.mapper.serialize(obj);

    let headers: HeadersData;
    let json: any;

    if (isHttpDataMapper(this.mapper)) {
      headers = toHttpData(serialized).headers;
      json = toHttpData(serialized).body;
    } else {
      headers = new EmulatedHeadersData();
      json = serialized;
    }

    json = this.serializeHeaders(headers, obj, json);

    return { headers, body: json };
  }

  deserialize(data: HttpData): ObjectView {
    const object = isHttpDataMapper(this.mapper) ? this.mapper.deserialize(data) : this.mapper.deserialize(data.body);

    return this.deserializeHeaders(data.headers, object);
  }

  serializeHeaders(headers: HeadersData, obj: ObjectView, json: any): any {
    return json;
  }

  deserializeHeaders(headers: HeadersData, obj: ObjectView): ObjectView {
    return obj;
  }
}
