/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { NavigationEnd } from '@angular/router';
import { isNotNil, parseUrl, stringifyUrl } from '@gh/core-util';
import { combineLatest$ } from '@gh/rx';
import { filter$, map$, switchMap$, tap$ } from '@gh/rx/operators';
import { assign, isEqual } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
/**
 * Checks if provided snapshot is in the snapshot tree of the router state
 */
function hasRouteInState(router, snapshot) {
    var snapshotStack = [];
    snapshotStack.push(router.snapshot.root);
    // simple tree traversal via stack
    while (snapshotStack.length) {
        var current = snapshotStack.pop(); // tslint:disable-line:no-non-null-assertion
        if (current === snapshot) {
            // we have found snapshot in the tree
            return true;
        }
        else {
            current.children.forEach(function (child) { return snapshotStack.push(child); });
        }
    }
    // we did not find snapshot in the tree
    return false;
}
/**
 * RouteTracker allows to
 * -- track changes of route query parameters;
 * -- update in-page data when route query parameters has changed;
 * -- update route query parameters when in-page data has been changed.
 *
 * <i>
 * Example:
 * Application has a page displaying list of entries filtered by parameters set on UI.
 * When one of filter parameters is changed => list of entries should be reloaded accordingly.
 * User can open one of entries in the separate page (leaving list of entries).
 * When he/she returns to the list (by pressing 'Back' browser's button)
 * it is convenient to have the latest parameters set on UI.
 *
 * Typical solution of this problem is to save all filters in query parameters of the page.
 * It allows to implement 'Back' behavior and gives 'bookmarkability' of such pages (at least).
 * </i>
 *
 * This class helps to solve similar problems.
 * In order to track and update query parameters RouteTracker registers {@link RouteParamTracker}s.
 * Each {@link RouteParamTracker} tracks and updates one or several query parameters.
 *
 * All registered {@link RouteParamTracker}s are divided into filter and effect parameter trackers.
 *
 * When query parameters tracked by filter parameter trackers are changed => {@link RouteTracker} emits the last filters
 * into {@link RouteTracker#filterChanges} stream. This allows to update page based on filter changes.
 *
 * Effect parameter trackers also tracks query parameters, but they do not notify {@link RouteTracker#filterChanges}
 * stream about the changes. This is the only difference.
 */
var RouteTracker = /** @class */ (function () {
    function RouteTracker(router, filterTrackers, effectTrackers, defaultFilter) {
        this.router = router;
        this.filterTrackers = filterTrackers;
        this.effectTrackers = effectTrackers;
        this.defaultFilter = defaultFilter;
    }
    Object.defineProperty(RouteTracker.prototype, "listening", {
        /**
         * Returns true iff tracker listens for some ActivatedRoute
         */
        get: function () {
            return isNotNil(this.subscription) && !this.subscription.closed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RouteTracker.prototype, "filterChanges", {
        /**
         * Returns the latest filters tracked by filter {@link RouteParamTracker}s
         */
        get: function () {
            if (!this.listening) {
                throw new Error('RouteTracker: filterChanges can be called only when tracker in listening state (listen(route))');
            }
            return this.filterChanges$$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Constructs new {@link RouteTracker} with the given set of {@link RouteParamTracker}s as effect param trackers.
     * Set of filter {@link RouteParamTracker}s is not modified.
     */
    RouteTracker.prototype.withEffects = function () {
        var effectTrackers = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            effectTrackers[_i] = arguments[_i];
        }
        return new RouteTracker(this.router, this.filterTrackers, effectTrackers, this.defaultFilter);
    };
    /**
     * Starts listening for given {@link ActivatedRoute}. Although this method returns {@link Subscription}, generally
     * it is unnecessary to unsubscribe explicitly. When user leaves given route, subscription is destroyed automatically.
     */
    RouteTracker.prototype.listen = function (route) {
        var _this = this;
        if (this.listening) {
            throw new Error('RouteTracker: tracker already in listening state.');
        }
        var _a = this, filterTrackers = _a.filterTrackers, effectTrackers = _a.effectTrackers;
        var subscription = this.subscription = new Subscription();
        this.filterChanges$$ = new BehaviorSubject(this.defaultFilter);
        this.enableMergeParams = true;
        // listen for route.queryParams
        // Generally speaking, it is unnecessarily to unsubscribe from route observables (as stated in the documentation
        // https://angular.io/guide/router#observable-parammap-and-component-reuse)
        subscription.add(route.queryParams.subscribe(function (queryParams) {
            // merge queryParams into filter and effect trackers
            if (_this.enableMergeParams) {
                filterTrackers.forEach(function (tracker) { return tracker.mergeParams(queryParams); });
                effectTrackers.forEach(function (tracker) { return tracker.mergeParams(queryParams); });
            }
        }));
        // Emit filter changes
        subscription.add(combineLatest$(filterTrackers.map(function (tracker) { return tracker.filterChanges; }))
            .subscribe(function (filters) { return _this.filterChanges$$.next(filters); }));
        // Listen for paramTrackers.paramChanges
        subscription.add(combineLatest$(filterTrackers.concat(effectTrackers).map(function (tracker) { return tracker.paramChanges; })).pipe(map$(function (paramsArr) { return assign.apply(void 0, __spreadArrays([{}], paramsArr)); }), 
        // merge params into url
        map$(function (params) { return _this.mergeQueryParams(params); }), 
        // if URL was modified
        filter$(Boolean), 
        // there is no need to merge next query parameters, because they just changed
        tap$(function () {
            _this.enableMergeParams = false;
        }), 
        // navigate
        switchMap$(function (nextUrl) { return _this.router.navigateByUrl(nextUrl, { replaceUrl: true }); }))
            .subscribe(function () {
            // enable parameter merging again
            _this.enableMergeParams = true;
        }));
        // Create dispose subscription.
        // This subscription is necessary just to catch the moment when listened route was removed from the router state.
        // Unfortunately there is no easy way to catch the moment when ActivatedRoute should be disposed:
        // here we listen all NavigationEnd events and try to find if current route was removed from the router state.
        subscription.add(this.router.events.pipe(
        // wait when any navigation has been completed
        filter$(function (event) { return event instanceof NavigationEnd; }), 
        // if listened route was removed from router state => it means we left listened route
        filter$(function () { return !hasRouteInState(_this.router.routerState, route.snapshot); }))
            // dispose subscription if we leave listened route
            .subscribe(function () { return subscription.unsubscribe(); }));
        return this.subscription;
    };
    /**
     * Dispose subscription for listened route.
     */
    RouteTracker.prototype.unlisten = function () {
        if (this.listening) {
            this.filterChanges$$.complete();
            this.subscription.unsubscribe(); // tslint:disable-line:no-non-null-assertion
            this.subscription = void 0;
        }
    };
    /**
     * Merges given query parametes into current URL. If parameters do not change final URL this method returns undefined.
     */
    RouteTracker.prototype.mergeQueryParams = function (params) {
        var url = this.router.routerState.snapshot.url;
        var _a = parseUrl(url), pathname = _a.pathname, query = _a.query, hash = _a.hash;
        var nextQuery = __assign(__assign({}, query), params);
        var nextUrl = stringifyUrl({ pathname: pathname, query: nextQuery, hash: hash });
        return nextUrl === url || isEqual(query, nextQuery) ? void 0 : nextUrl;
    };
    return RouteTracker;
}());
export { RouteTracker };
