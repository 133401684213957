/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, Input } from '@angular/core';
import { JobInfo } from '@gh/customer-shared';

@Component({
  selector: 'gh-customer-job-info',
  templateUrl: 'customer-job-info.component.html',
  styleUrls: ['customer-job-info.component.scss'],
})
export class CustomerJobInfoComponent {
  @Input() job: JobInfo;
}
