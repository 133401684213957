/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { isFunction } from 'lodash';
import { AppFormGroup, collectVisibleValues, toAppFormGroup } from './controls';
import { FormRootSchema, FormStateSchema } from './form-schema';
import { FormRootSchemaConfig } from './form-schema/form-root-schema';
import { FormSchemaFactory } from './form-schema/form-schema-factory';
import { FormState } from './form-state';
import { FormStateSnapshot } from './form-state-snapshot';

type FormSubmitValueFn = TransformFn<AppFormGroup, any>;
export type FormSubmitValue = 'all' | 'visible' | FormSubmitValueFn;

const PREDEFINED_FORM_SUBMIT_VALUES = {
  'all': (group: AppFormGroup) => group.getRawValue(),
  'visible': collectVisibleValues,
};

function getFormSubmitValueFn(submitValue?: FormSubmitValue): FormSubmitValueFn {
  return isFunction(submitValue) ? submitValue : PREDEFINED_FORM_SUBMIT_VALUES[submitValue || 'visible'];
}

export type PlainFormRootSchemaConfig = FormRootSchemaConfig & {
  value?: any;
  submitValue?: FormSubmitValue;
  snapshot?: FormStateSnapshot<any>;
};

class PlainFormState<K> extends FormState<K> {
  get submitValue(): K {
    return getFormSubmitValueFn(this.rootConfig.submitValue)(toAppFormGroup(this.group));
  }

  constructor(schemaFactory: FormSchemaFactory,
              private rootConfig: PlainFormRootSchemaConfig) {
    super(schemaFactory);
  }

  protected buildSchema(value: K, { root }: FormStateSchema): FormRootSchema {
    return root(this.rootConfig);
  }
}

type FunctionalSchemaFactory<T> = (value: T, schema: FormStateSchema) => FormRootSchema;

class FunctionalFormState<K> extends FormState<K> {
  constructor(schemaFactory: FormSchemaFactory,
              private schemaFn: FunctionalSchemaFactory<K>) {
    super(schemaFactory);
  }

  protected buildSchema(value: K, schema: FormStateSchema): FormRootSchema {
    return this.schemaFn(value, schema);
  }
}

@Injectable()
export class FormStateBuilder {
  constructor(private schemaFactory: FormSchemaFactory) {

  }

  buildPlain<T>(root: PlainFormRootSchemaConfig): FormState<T> {
    const state = new PlainFormState<T>(this.schemaFactory, root);

    state.initSchema(root.value);
    if (root.snapshot) {
      state.restore(root.snapshot);
    }

    return state;
  }

  buildFunctional<T>(original: T, schemaFn: FunctionalSchemaFactory<T>): FormState<T> {
    const state = new FunctionalFormState<T>(this.schemaFactory, schemaFn);

    state.build(original);

    return state;
  }
}
