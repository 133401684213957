/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { isNotNil } from '@gh/core-util';
import { clone } from 'lodash';
import { DataMapper, JSONObject } from './data-mapper';

export class EntityMapper<Entity, ID> implements DataMapper<Entity, JSONObject> {
  private fieldArray: (keyof Entity)[];

  constructor(private fields: { [P in keyof Entity]?: DataMapper<Entity[P], any> }) {
    this.fieldArray = fields ? <(keyof Entity)[]>Object.keys(fields) : [];
  }

  serialize(source: Entity): JSONObject {
    if (this.fieldArray.length === 0) {
      return source;
    }

    return this.fieldArray.reduce(
      (target, field) => {
        if (isNotNil(target[field])) {
          target[field] = (<any>this.fields[field]).serialize(target[field]);
        }

        return target;
      },
      clone(source));
  }

  deserialize(json: JSONObject): Entity {
    if (this.fieldArray.length === 0) {
      return json;
    }

    return this.fieldArray.reduce(
      (target, field) => {
        if (isNotNil(target[field])) {
          target[field] = (<any>this.fields[field]).deserialize(target[field]);
        }

        return target;
      },
      clone(json));
  }
}
