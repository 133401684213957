/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// tslint:disable:no-magic-numbers
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { mergePath, parseUrl } from '@gh/core-util';
import { throwError$ } from '@gh/rx';
import { filter$, map$, switchMap$ } from '@gh/rx/operators';
import { cloneDeep, find, isEmpty, isFunction, isNil, omit, orderBy } from 'lodash';
import { isServerErrorStatus } from '../http/http.utils';
import { decorateEndpoint, mockBadRequestResponse, mockErrorResponse, mockMethodNotAllowedResponse, mockNotFoundResponse, mockSuccessResponse, } from './mock-data.interfaces';
var HEADER_RANGE_ITEMS = /^items=(\d+)-(\d+)$/;
function prepareUrlRegExp(url) {
    return new RegExp(url.replace(/\/(\*|:[^/]+)/g, '/[^\/]+').replace('/', '\\/') + "$");
}
function parseUrlParams(urlWithParams, url) {
    var params = {};
    var urlWithParamSegmentRe = /\/(:?)([^\/]+)/g;
    var urlSegmentRe = /\/([^\/]+)/g;
    while (true) {
        var urlWithParamSegment = urlWithParamSegmentRe.exec(urlWithParams);
        var urlSegment = urlSegmentRe.exec(url);
        if (isNil(urlWithParamSegment) || isNil(urlSegment)) {
            break;
        }
        var isParam = !isEmpty(urlWithParamSegment[1]);
        var urlWithParamName = urlWithParamSegment[2];
        var urlName = urlSegment[1];
        if (isParam) {
            params[urlWithParamName] = urlName;
        }
    }
    return params;
}
export function mockRegistry() {
    var endpoints = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        endpoints[_i] = arguments[_i];
    }
    return {
        getEndpoint: function (url, method) {
            return find(endpoints, function (ep) { return ep.supportsUrl(url) && ep.supportsMethod(method); });
        },
    };
}
export var createMockEndpoint = function (url, methods, processor) { return new MockDataEndpointImpl(url, methods, processor); };
export var createMockDataRequest = function (requestInfo) { return ({
    params: requestInfo.params,
    query: requestInfo.query,
    body: requestInfo.utils.getJsonBody(requestInfo.req),
}); };
export var filterData = function (data, requestInfo) {
    return isFunction(data) ? data(createMockDataRequest(requestInfo)) : data;
};
export var mockResource = function (config) {
    var url = config.url;
    return createMockEndpoint(mergePath(url, '*'), ['get', 'post', 'put'], resourceMockDataProcessor(config));
};
export var mockPagination = function (config) {
    var url = config.url, method = config.method;
    return createMockEndpoint(url, [method || 'get'], paginationMockDataProcessor(config));
};
export var mockRequest = function (config) {
    var url = config.url, method = config.method;
    return createMockEndpoint(url, [method || 'get'], requestMockDataProcessor(config));
};
export var decorateRequest = function (config) {
    var url = config.url, method = config.method;
    return createMockEndpoint(url, [method || 'get'], decorateRequestMockDataProcessor(config.handler));
};
export var decorateResponse = function (_a) {
    var url = _a.url, method = _a.method, map = _a.map;
    return decorateRequest({
        url: url,
        method: method,
        handler: function (request, next) { return next(request).pipe(switchMap$(function (response) {
            var decoratedResponse = map(response);
            if (isServerErrorStatus(decoratedResponse.status)) {
                return throwError$(decoratedResponse);
            }
            else {
                return [decoratedResponse];
            }
        })); },
    });
};
export var decorateResponseBody = function (_a) {
    var url = _a.url, method = _a.method, map = _a.map;
    return decorateRequest({
        url: url,
        method: method,
        handler: function (request, next) { return next(request).pipe(map$(function (response) { return response.clone({ body: map(response.body) }); })); },
    });
};
var decorateRequestMockDataProcessor = function (handler) { return function (request) {
    return handler(request.req, function (req) { return request.utils.getPassThruBackend().handle(req).pipe(filter$(function (event) { return event instanceof HttpResponse; })); });
}; };
var requestMockDataProcessor = function (config) { return function (request) {
    return new HttpResponse({
        url: config.url,
        status: config.status || 200,
        body: filterData(config.data, request),
        headers: config.headers,
    });
}; };
export var logRequest = function (endpoint) {
    return decorateEndpoint(endpoint, function (request) {
        // tslint:disable-next-line:no-console
        console.log({
            url: request.url,
            method: request.method,
            query: request.query,
            body: request.utils.getJsonBody(request.req),
        });
        return endpoint.process(request);
    });
};
var paginationMockDataProcessor = function (config) { return function (request) {
    var data = config.data;
    switch (request.method) {
        case 'get':
        case 'post':
            var range = request.req['headers'].get('Range');
            var sortBy = request.query['sortBy'];
            if (range) {
                var match = HEADER_RANGE_ITEMS.exec(range);
                if (match) {
                    var filteredData = filterData(data, request);
                    var sortedData = sortBy
                        ? orderBy(filteredData, [sortBy.substring(1)], [sortBy[0] === '+' ? 'asc' : 'desc'])
                        : filteredData;
                    var slicedData = sortedData.slice(Number(match[1]), Number(match[2]) + 1);
                    return mockSuccessResponse(request.resourceUrl, new HttpHeaders({
                        'Content-Range': "items " + match[1] + "-" + match[2] + "/" + sortedData.length,
                    }), slicedData);
                }
            }
            return mockBadRequestResponse(request.resourceUrl);
        default:
            return mockMethodNotAllowedResponse(request.resourceUrl);
    }
}; };
export var mockError = function (config) {
    var url = config.url, method = config.method;
    return createMockEndpoint(url, [method || 'get'], errorMockDataProcessor(config));
};
var errorMockDataProcessor = function (config) { return function () {
    var url = config.url, status = config.status, statusText = config.statusText;
    return mockErrorResponse(url, status, statusText || '');
}; };
var resourceMockDataProcessor = function (config) { return function (request) {
    var data = config.data, withEtag = config.withEtag;
    var db = data || {};
    switch (request.method) {
        case 'get': {
            var id = request.id;
            var resource = db[id];
            if (resource) {
                return mockSuccessResponse(request.resourceUrl, withEtag && resource.etag ? new HttpHeaders({ 'ETag': resource.etag }) : void 0, cloneDeep(omit(resource, 'etag')));
            }
            else {
                return mockNotFoundResponse(request.resourceUrl);
            }
        }
        case 'post': {
            var id = request.id;
            var body = JSON.parse(request.utils.getJsonBody(request.req));
            db[id] = withEtag ? __assign(__assign({}, body), { etag: request.req.headers.get('ETag') }) : body;
            return mockSuccessResponse(request.resourceUrl, new HttpHeaders(), body);
        }
        case 'put': {
            var id = request.id;
            var existingResource = db[id];
            if (isNil(existingResource)) {
                return mockNotFoundResponse(request.resourceUrl);
            }
            var body = JSON.parse(request.utils.getJsonBody(request.req));
            var etag = request.req.headers.get('ETag');
            db[id] = withEtag ? __assign(__assign({}, body), { etag: etag }) : body;
            return mockSuccessResponse(request.resourceUrl, new HttpHeaders(), body);
        }
        default:
            return mockMethodNotAllowedResponse(request.resourceUrl);
    }
}; };
var MockDataEndpointImpl = /** @class */ (function () {
    function MockDataEndpointImpl(urlWithParams, methods, processor) {
        this.urlWithParams = urlWithParams;
        this.methods = methods;
        this.processor = processor;
        this.urlRe = prepareUrlRegExp(urlWithParams);
    }
    MockDataEndpointImpl.prototype.process = function (request) {
        var parsedUrl = parseUrl(request.url);
        // tslint:disable-next-line:no-non-null-assertion
        var params = parseUrlParams(this.urlWithParams, this.urlRe.exec(parsedUrl.pathname)[0]);
        return this.processor(__assign(__assign({}, request), { params: params }));
    };
    MockDataEndpointImpl.prototype.supportsMethod = function (method) {
        return this.methods.length === 0 || this.methods.includes(method);
    };
    MockDataEndpointImpl.prototype.supportsUrl = function (url) {
        return this.urlRe.test(url);
    };
    return MockDataEndpointImpl;
}());
