/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ApplicationComponentState } from './application-component-state.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'router-outlet',
})
export class AppRouterOutletDirective implements OnInit {
  constructor(private native: RouterOutlet,
              private applicationComponentState: ApplicationComponentState) {

  }

  ngOnInit(): void {
    this.native.activateEvents.subscribe((component: any) => this.applicationComponentState.activate(component));
    this.native.deactivateEvents.subscribe((component: any) => this.applicationComponentState.deactivate(component));
  }
}
