/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ExtLoadTrackerComponent } from '../../components';
import {
  EXT_INFINITE_SCROLL_DIMENSIONS,
  EXT_INFINITE_SCROLL_VIEWPORT,
  ExtInfiniteScrollDimensions,
  ExtInfiniteScrollViewport,
} from '../ext-infinite-scroll.interfaces';

@Component({
  selector: 'gh-ext-infinite-list',
  template: `
    <ng-content></ng-content>`,
  styleUrls: ['ext-infinite-list.component.scss'],
  providers: [{ provide: EXT_INFINITE_SCROLL_DIMENSIONS, useExisting: ExtInfiniteListComponent }],
  exportAs: 'ghExtInfiniteList',
})
export class ExtInfiniteListComponent implements ExtInfiniteScrollDimensions, OnInit, OnDestroy {
  @Input() offsetTop: number = 0;
  @Input() itemHeight: number = 50;
  @Input() loadThresholdCount: number = 10;
  @Input() pageSize: number = 50;
  @Input() tracker?: ExtLoadTrackerComponent;

  constructor(private elementRef: ElementRef,
              @Inject(EXT_INFINITE_SCROLL_VIEWPORT) private viewport: ExtInfiniteScrollViewport) {
  }

  ngOnInit(): void {
    this.viewport.setDimensions(this.elementRef.nativeElement, this);
    this.updateHeight();
  }

  ngOnDestroy(): void {
    this.viewport.resetDimensions(this.elementRef.nativeElement, this);
  }

  updateHeight(): void {
    if (this.viewport.rowCount >= 0) {
      this.elementRef.nativeElement.style.height = `${this.viewport.rowCount * this.itemHeight}px`;
    }
  }
}
