/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { DictionaryService, getRouteParam } from '@gh/common-shared';
import { ID } from '@gh/common-shared-data';
import { CustomerFinancialRecordRestService, InvoiceType } from '@gh/customer-shared-data';

import { Observable } from 'rxjs';

import { FinancialRecord } from './customer-financial-record.model';

@Injectable()
export class CustomerFinancialRecordBusinessService {
  constructor(private restService: CustomerFinancialRecordRestService,
              private dictionaryService: DictionaryService) {
  }

  initView(route: ActivatedRouteSnapshot): any {
    const jobId = getRouteParam(route, 'jobId');
    return {
      financialRecord: this.get(jobId),
      invoiceType: this.dictionaryService.getByEnum(InvoiceType),
    };
  }

  get(jobId: ID): Observable<FinancialRecord> {
    return this.restService.get(jobId);
  }
}
