/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { normalizePermission } from '@gh/core-auth';
import { UserContext } from '../user/user-context';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../user/user-context";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, userContext) {
        this.router = router;
        this.userContext = userContext;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var canNavigate = this.verifyRoute(route, state);
        if (!canNavigate) {
            this.router.navigateByUrl('/404');
            return false;
        }
        else {
            return true;
        }
    };
    AuthGuard.prototype.canActivateChild = function (childRoute, state) {
        var parent = childRoute.parent;
        var canNavigate = parent ? this.verifyRoute(parent, state) : true;
        if (!canNavigate) {
            this.router.navigateByUrl('/404');
            return false;
        }
        else {
            return true;
        }
    };
    AuthGuard.prototype.verifyRoute = function (route, state) {
        var _this = this;
        var permission = route.data['permission'];
        return this.verifyEntity(permission, function (p) { return _this.userContext.hasPermission(normalizePermission(p)); });
    };
    AuthGuard.prototype.verifyEntity = function (entity, fn) {
        if (Array.isArray(entity)) {
            if (entity.length > 0) {
                return entity.some(fn);
            }
            else {
                return true;
            }
        }
        else if (entity) {
            return fn(entity);
        }
        else {
            return true;
        }
    };
    AuthGuard.ɵfac = function AuthGuard_Factory(t) { return new (t || AuthGuard)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.UserContext)); };
    AuthGuard.ɵprov = i0.ɵɵdefineInjectable({ token: AuthGuard, factory: AuthGuard.ɵfac });
    return AuthGuard;
}());
export { AuthGuard };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AuthGuard, [{
        type: Injectable
    }], function () { return [{ type: i1.Router }, { type: i2.UserContext }]; }, null); })();
