/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/progress-spinner";
var ExtPreloaderComponent = /** @class */ (function () {
    function ExtPreloaderComponent() {
        this.loading = false;
        this.size = 150;
    }
    ExtPreloaderComponent.ɵfac = function ExtPreloaderComponent_Factory(t) { return new (t || ExtPreloaderComponent)(); };
    ExtPreloaderComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtPreloaderComponent, selectors: [["gh-ext-preloader"]], hostVars: 2, hostBindings: function ExtPreloaderComponent_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵclassProp("m-is-loading", ctx.loading);
        } }, inputs: { loading: "loading", size: "size" }, decls: 1, vars: 1, consts: [["mode", "indeterminate", 3, "diameter"]], template: function ExtPreloaderComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelement(0, "mat-progress-spinner", 0);
        } if (rf & 2) {
            i0.ɵɵproperty("diameter", ctx.size);
        } }, directives: [i1.MatProgressSpinner], styles: ["[_nghost-%COMP%] {\n  display: none; }\n\n.m-is-loading[_nghost-%COMP%] {\n  display: flex;\n  justify-content: center;\n  align-items: center; }"], changeDetection: 0 });
    return ExtPreloaderComponent;
}());
export { ExtPreloaderComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtPreloaderComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-preloader',
                templateUrl: 'ext-preloader.component.html',
                styleUrls: ['ext-preloader.component.scss'],
                changeDetection: ChangeDetectionStrategy.OnPush,
            }]
    }], null, { loading: [{
            type: HostBinding,
            args: ['class.m-is-loading']
        }, {
            type: Input
        }], size: [{
            type: Input
        }] }); })();
