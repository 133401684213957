/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { setControlValue } from '@gh/core-ui';
import { denull, wrapInObservable } from '@gh/core-util';
import { each, extend, forEach, isEmpty, omit, pull, values } from 'lodash';
var ConfirmationStatus;
(function (ConfirmationStatus) {
    ConfirmationStatus[ConfirmationStatus["Pending"] = 0] = "Pending";
    ConfirmationStatus[ConfirmationStatus["Approved"] = 1] = "Approved";
    ConfirmationStatus[ConfirmationStatus["Rejected"] = 2] = "Rejected";
})(ConfirmationStatus || (ConfirmationStatus = {}));
/**
 * This class defines merged state of form state confirmations and provides helper methods to manage this state.
 */
var FormConfirmationState = /** @class */ (function () {
    function FormConfirmationState(form) {
        this.form = form;
        /**
         * State of all nested form confirmations
         */
        this.controls = {};
        /**
         * All child confirmation form states
         */
        this.children = [];
    }
    Object.defineProperty(FormConfirmationState.prototype, "approved", {
        /**
         * Returns true if current status is {@link ConfirmationStatus#Approved}, otherwise false
         */
        get: function () {
            return this.status === ConfirmationStatus.Approved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormConfirmationState.prototype, "pending", {
        /**
         * Returns true if current status is {@link ConfirmationStatus#Pending}, otherwise false
         */
        get: function () {
            return this.status === ConfirmationStatus.Pending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormConfirmationState.prototype, "rejected", {
        /**
         * Returns true if current status is {@link ConfirmationStatus#Rejected}, otherwise false
         */
        get: function () {
            return this.status === ConfirmationStatus.Rejected;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Predefine set of nested confirmations for this form state.
     * Each nested confirmation has name and tracked form control
     *
     * @param controls keys are names of nested confirmations, values are tracked values of form
     */
    FormConfirmationState.prototype.define = function (controls) {
        var _this = this;
        forEach(controls, function (control, name) { return _this.ensureControl(name, control); });
    };
    /**
     * Marks specified nested confirmation as approved.
     * This method resets errors assigned for the nested confirmation. It can reset all or just one error.
     *
     * @param name name of nested confirmation
     * @param error specific error for this confirmation
     */
    FormConfirmationState.prototype.markAsApproved = function (name, error) {
        this.controls[name].markAsApproved(error);
    };
    /**
     * This method creates nested confirmation state and sets validator for this state.
     * Validation is triggered each time value of the underlying control is changed.
     *
     * Validator should follow the same rules as general Angular Validator from @angular/forms package
     *
     * @param name name of the nested confirmation
     * @param control tracked form control
     * @param validator
     */
    FormConfirmationState.prototype.setValidator = function (name, control, validator) {
        this.ensureControl(name, control).setValidator(validator);
    };
    /**
     * This method creates nested confirmation state and sets async validator for this state.
     * Validation is triggered each time value of the underlying control is changed.
     *
     * Validator should follow the same rules as general Angular AsyncValidator from @angular/forms package
     *
     * @param name name of the nested confirmation
     * @param control tracked form control
     * @param validator asynchronous validator
     */
    FormConfirmationState.prototype.setAsyncValidator = function (name, control, validator) {
        this.ensureControl(name, control).setAsyncValidator(validator);
    };
    /**
     * Resets validator for the given nested confirmation
     *
     * @param name
     */
    FormConfirmationState.prototype.clearAsyncValidator = function (name) {
        var state = this.controls[name];
        if (state) {
            state.clearAsyncValidator();
        }
    };
    /**
     * Clears async validators for all nested confirmations
     */
    FormConfirmationState.prototype.clearAllAsyncValidators = function () {
        var _this = this;
        Object.keys(this.controls).forEach(function (name) {
            _this.clearAsyncValidator(name);
        });
    };
    /**
     * Recalculates state of confirmation state based on descendants
     *
     * @internal this method should not be used by developers
     */
    FormConfirmationState.prototype._updateState = function () {
        // merged list of all confirmations and form state confirmations
        var nodes = values(this.controls).concat(this.children);
        // recalculate status based on descendants
        if (nodes.some(function (node) { return node.status === ConfirmationStatus.Pending; })) {
            this.status = ConfirmationStatus.Pending;
        }
        else if (nodes.some(function (node) { return node.status === ConfirmationStatus.Pending; })) {
            this.status = ConfirmationStatus.Rejected;
        }
        else {
            this.status = ConfirmationStatus.Approved;
        }
        // merges all descendant errors
        this.errors = nodes.reduce(function (errors, node) {
            var current = node.errors;
            if (current) {
                if (errors) {
                    extend(errors, current);
                }
                else {
                    errors = extend({}, current);
                }
            }
            return errors;
        }, void 0);
        if (this.parent) {
            this.parent._updateState();
        }
    };
    /**
     * Returns state of nested confirmation by its name
     */
    FormConfirmationState.prototype.get = function (name) {
        return this.controls[name];
    };
    /**
     * Returns specified error retrieved either from this form confirmation state
     * or from nested confirmation (if name parameter is provided).
     *
     * @param code code of the error
     * @param name name of the nested confirmation.
     *             If name is not provided method returns error from form confirmation state
     */
    FormConfirmationState.prototype.getError = function (code, name) {
        var errors = name ? this.controls[name].errors : this.errors;
        if (errors) {
            return errors[code];
        }
    };
    /**
     * Returns whether error exist either for this form confirmation state
     * or for nested confirmation (if name parameter is provided).
     *
     * @param code code of the error
     * @param name name of the nested confirmation.
     */
    FormConfirmationState.prototype.hasError = function (code, name) {
        return !!this.getError(code, name);
    };
    /**
     * Sets parent of this confirmation state
     * @internal this method should not be used by developer
     */
    FormConfirmationState.prototype.setParent = function (parent) {
        this.parent = parent;
        parent.children.push(this);
    };
    /**
     * Resets parent connection
     */
    FormConfirmationState.prototype.resetParent = function () {
        var parent = this.parent;
        if (parent) {
            pull(parent.children, this);
        }
        this.parent = void 0;
    };
    /**
     * Validates all controls in this confirmation state
     */
    FormConfirmationState.prototype.validate = function () {
        each(this.controls, function (control) { return control.validate(); });
    };
    /**
     * Creates nested confirmation state
     *
     * @param name name of nested confirmation state
     * @param control tracked form control
     */
    FormConfirmationState.prototype.ensureControl = function (name, control) {
        var state = this.controls[name];
        if (!state) {
            this.controls[name] = state = new ControlConfirmationState(this, name, control);
        }
        return state;
    };
    return FormConfirmationState;
}());
export { FormConfirmationState };
/**
 * This class defines state of nested confirmation state.
 */
var ControlConfirmationState = /** @class */ (function () {
    function ControlConfirmationState(parent, name, control) {
        this.parent = parent;
        this.name = name;
        this.control = control;
    }
    Object.defineProperty(ControlConfirmationState.prototype, "approved", {
        /**
         * Returns whether this confirmation is approved or not
         */
        get: function () {
            return this.status === ConfirmationStatus.Approved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlConfirmationState.prototype, "pending", {
        /**
         * Returns whether this confirmation is in pending status or not
         */
        get: function () {
            return this.status === ConfirmationStatus.Pending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlConfirmationState.prototype, "rejected", {
        /**
         * Returns whether this confirmation is rejected or not
         */
        get: function () {
            return this.status === ConfirmationStatus.Rejected;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Sets validator for this state.
     * Validation is triggered each time value of the underlying control is changed.
     *
     * Validator should follow the same rules as general Angular Validator from @angular/forms package
     *
     * @param validator
     */
    ControlConfirmationState.prototype.setValidator = function (validator) {
        this.ensureValueSubscription();
        this.validator = validator;
    };
    /**
     * Sets async validator for this state.
     * Validation is triggered each time value of the underlying control is changed.
     *
     * Validator should follow the same rules as general Angular AsyncValidator from @angular/forms package
     *
     * @param validator asynchronous validator
     */
    ControlConfirmationState.prototype.setAsyncValidator = function (validator) {
        this.ensureValueSubscription();
        this.asyncValidator = validator;
    };
    /**
     * Resets validator for this confirmation state
     */
    ControlConfirmationState.prototype.clearAsyncValidator = function () {
        this.asyncValidator = undefined;
        this.unsubscribeValue();
    };
    /**
     * Marks specified this confirmation state as approved.
     * This method resets errors assigned for this state. It can reset all or just one error.
     *
     * @param error specific error for this confirmation
     */
    ControlConfirmationState.prototype.markAsApproved = function (error) {
        if (this.errors) {
            if (error) {
                var errors = omit(this.errors, error);
                if (isEmpty(errors)) {
                    this.clearErrors();
                }
                else {
                    this.setErrors(errors);
                }
            }
            else {
                this.clearErrors();
            }
        }
    };
    /**
     * Validates this control validation state
     */
    ControlConfirmationState.prototype.validate = function () {
        var _this = this;
        this.errors = void 0;
        if (this.validator) {
            this.errors = denull(this.validator(this.control));
        }
        this.status = this.calculateStatus();
        if (this.asyncValidator && this.control.enabled) {
            this.status = ConfirmationStatus.Pending;
            var ob = wrapInObservable(this.asyncValidator(this.control));
            this.cancelValidationSubscription();
            this.validationSubscription = ob.subscribe(function (errors) { return _this.setErrors(errors); });
        }
        this.updateFormState();
    };
    /**
     * Directly sets errors for this confirmation state
     *
     * @param errors
     */
    ControlConfirmationState.prototype.setErrors = function (errors) {
        this.errors = errors;
        this.status = this.calculateStatus();
        this.updateFormState();
    };
    ControlConfirmationState.prototype.ensureValueSubscription = function () {
        var _this = this;
        if (this.valueSubscription) {
            return;
        }
        this.valueSubscription = this.control.valueChanges.subscribe(function () { return _this.validate(); });
    };
    ControlConfirmationState.prototype.unsubscribeValue = function () {
        this.cancelValidationSubscription();
        if (this.valueSubscription) {
            this.valueSubscription.unsubscribe();
            this.valueSubscription = undefined;
        }
    };
    ControlConfirmationState.prototype.cancelValidationSubscription = function () {
        if (this.validationSubscription) {
            this.validationSubscription.unsubscribe();
            this.validationSubscription = undefined;
        }
    };
    ControlConfirmationState.prototype.calculateStatus = function () {
        if (this.errors) {
            return ConfirmationStatus.Rejected;
        }
        return ConfirmationStatus.Approved;
    };
    ControlConfirmationState.prototype.clearErrors = function () {
        this.setErrors();
    };
    /**
     * Updates form controls corresponding to this confirmation
     */
    ControlConfirmationState.prototype.updateFormState = function () {
        var _a;
        this.setWarningFormValue((_a = {},
            _a[ConfirmationStatus.Rejected] = false,
            _a[ConfirmationStatus.Approved] = true,
            _a[ConfirmationStatus.Pending] = false,
            _a)[this.status]);
        this.parent._updateState();
    };
    ControlConfirmationState.prototype.setWarningFormValue = function (value) {
        setControlValue(this.parent.form, "confirmations." + this.name, value);
    };
    return ControlConfirmationState;
}());
export { ControlConfirmationState };
