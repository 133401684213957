/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { UserData } from '@gh/common-shared-data';
import { AuthService, AuthState, Permission } from '@gh/core-auth';
import { Locale } from '@gh/core-mls';
import { EMPTY$, of$ } from '@gh/rx';
import { distinctUntilChanged$, filter$, first$, map$ } from '@gh/rx/operators';

import { noop } from 'lodash';
import { Observable } from 'rxjs';

import { CommonUserProfileStore, UserLoginStatus } from './common-user-profile-store.service';
import { UserContext } from './user-context';

export abstract class CommonUserContextService<U extends UserData> extends UserContext {
  private initParams: Hash<any>;
  private user$: Observable<U>;

  constructor(private authService: AuthService,
              private commonStore: CommonUserProfileStore<U>) {
    super();

    this.user$ = this.commonStore.user$;
    this.user$.subscribe((user) => this.updateUserData(<U>user), noop);

    this.commonStore.status$.pipe(
      filter$((status) => status === UserLoginStatus.LoggedOut))
      .subscribe(() => {
        this.authService.logout();
      });
  }

  get authState(): AuthState {
    return this.authService.authState;
  }

  get onAuthState(): Observable<AuthState> {
    return this.authService.onAuthState;
  }

  get data(): UserData {
    if (!this.user) {
      return this.userNotInitialized();
    }

    return this.user;
  }

  get onData(): Observable<UserData> {
    return this.user$;
  }

  get user(): Maybe<U> {
    return this.commonStore.user;
  }

  get locale(): Locale {
    return this.data.locale;
  }

  get baseUrl(): string {
    return `${location.origin}${this.initParams['baseUrl']}`;
  }

  isInitialized(): boolean {
    return this.commonStore.initialized;
  }

  init(params: Hash<any>): Observable<UserData> {
    if (this.user) {
      return of$(this.user);
    }

    this.initParams = params;

    this.setupHttpHeaders(params);

    const authState$ = this.authService.onAuthState.pipe(distinctUntilChanged$());
    const loggedIn$ = authState$.pipe(first$((state) => state === AuthState.LoggedIn));
    loggedIn$.subscribe(() => {
      this.commonStore.load(params);
    });

    return this.user$.pipe(first$());
  }

  logout(): Observable<boolean> {
    this.commonStore.logout();

    return this.commonStore.status$.pipe(
      first$(),
      map$((status) => status === UserLoginStatus.LoggedOut));
  }

  changePassword(): Observable<boolean> {
    window.location.href = 'https://myaccount.google.com/security';
    return EMPTY$;
  }

  hasPermission(permission: Permission): boolean {
    if (!this.user) {
      return this.userNotInitialized();
    }

    return this.authService.hasPermission(permission);
  }

  changeLocale(locale: Locale): void {
    this.commonStore.updateLocale(locale).subscribe(() => location.reload());
  }

  protected getInitParam(name: string): any {
    return this.initParams[name];
  }

  protected checkUserInitialized(): void {
    if (!this.user) {
      return this.userNotInitialized();
    }
  }

  protected userNotInitialized(): any {
    throw new Error('UserContext is still not initialized');
  }

  protected abstract updateUserData(user: Maybe<U>): void;

  protected abstract setupHttpHeaders(params: Hash<any>): void;
}
