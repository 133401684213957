/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { createDialogRoute } from '@gh/common-shared';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../common-external-shared/components/ext-dialog/ext-dialog.component";
import * as i3 from "../../../common-external-shared/components/ext-dialog/ext-dialog-header.component";
import * as i4 from "../../../common-external-shared/components/ext-dialog/ext-dialog-content.component";
import * as i5 from "../customer-job-info/customer-job-info.component";
import * as i6 from "../../../common-external-shared/components/ext-dialog/ext-dialog-button-bar.component";
import * as i7 from "@angular/common";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/flex-layout/extended";
import * as i10 from "../../../core-mls/translation/translate.pipe";
function CustomerJobInfoDialogComponent_a_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 6);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵpropertyInterpolate1("href", "tel:", ctx_r0.job.officePhone, "", i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 2, "lbl_call_to_office"));
} }
var CustomerJobInfoDialogComponent = /** @class */ (function () {
    function CustomerJobInfoDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
    }
    Object.defineProperty(CustomerJobInfoDialogComponent.prototype, "job", {
        get: function () {
            return this.input.job;
        },
        enumerable: true,
        configurable: true
    });
    CustomerJobInfoDialogComponent.prototype.onClose = function () {
        this.dialogRef.close();
    };
    CustomerJobInfoDialogComponent.ɵfac = function CustomerJobInfoDialogComponent_Factory(t) { return new (t || CustomerJobInfoDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef)); };
    CustomerJobInfoDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CustomerJobInfoDialogComponent, selectors: [["gh-customer-job-info-dialog"]], decls: 10, vars: 8, consts: [["layout", "text", "forBreakpoint", "lt-lg"], [3, "title"], [1, "m-job-info", 3, "job"], ["align", "center"], ["fxHide.gt-sm", "", "mat-raised-button", "", "color", "primary", 3, "href", 4, "ngIf"], ["type", "button", "mat-raised-button", "", "fxHide.lt-md", "", "color", "primary", 1, "large", 3, "click"], ["fxHide.gt-sm", "", "mat-raised-button", "", "color", "primary", 3, "href"]], template: function CustomerJobInfoDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "gh-ext-dialog", 0);
            i0.ɵɵelement(1, "gh-ext-dialog-header", 1);
            i0.ɵɵpipe(2, "translate");
            i0.ɵɵelementStart(3, "gh-ext-dialog-content");
            i0.ɵɵelement(4, "gh-customer-job-info", 2);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "gh-ext-dialog-button-bar", 3);
            i0.ɵɵtemplate(6, CustomerJobInfoDialogComponent_a_6_Template, 3, 4, "a", 4);
            i0.ɵɵelementStart(7, "button", 5);
            i0.ɵɵlistener("click", function CustomerJobInfoDialogComponent_Template_button_click_7_listener() { return ctx.onClose(); });
            i0.ɵɵtext(8);
            i0.ɵɵpipe(9, "translate");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(1);
            i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 4, "lbl_job_details"));
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("job", ctx.job);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.job.officePhone);
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(9, 6, "lbl_ok"));
        } }, directives: [i2.ExtDialogComponent, i3.ExtDialogHeaderComponent, i4.ExtDialogContentComponent, i5.CustomerJobInfoComponent, i6.ExtDialogButtonBarComponent, i7.NgIf, i8.MatButton, i9.DefaultShowHideDirective, i8.MatAnchor], pipes: [i10.TranslatePipe], styles: ["[_nghost-%COMP%] {\n  overflow: hidden;\n  display: flex;\n  flex-direction: column; }\n  @media (max-width: 959px) {\n    [_nghost-%COMP%] {\n      width: 100vw;\n      min-height: 100vh; } }\n  @media (min-width: 960px) {\n    [_nghost-%COMP%] {\n      min-width: 700px;\n      min-height: auto; } }\n\n.m-job-info[_ngcontent-%COMP%] {\n  margin-bottom: 20px; }"] });
    return CustomerJobInfoDialogComponent;
}());
export { CustomerJobInfoDialogComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerJobInfoDialogComponent, [{
        type: Component,
        args: [{
                selector: 'gh-customer-job-info-dialog',
                templateUrl: 'customer-job-info-dialog.component.html',
                styleUrls: ['customer-job-info-dialog.component.scss'],
            }]
    }], function () { return [{ type: i1.MatDialogRef }]; }, null); })();
export var customerJobInfoDialogRoute = createDialogRoute({
    component: CustomerJobInfoDialogComponent,
    config: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
});
