var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicAuthHttpRequestInterceptor } from '@gh/core-auth';
import { from$, throwError$ } from '@gh/rx';
import { switchMap$ } from '@gh/rx/operators';
import { KeycloakService } from './keycloak.service';
import * as i0 from "@angular/core";
import * as i1 from "./keycloak.service";
var reqWithTokenHeader = function (req, token) {
    return req.clone({ headers: req.headers.set('Authorization', "Bearer " + token) });
};
var KeycloakAuthHttpRequestInterceptor = /** @class */ (function (_super) {
    __extends(KeycloakAuthHttpRequestInterceptor, _super);
    function KeycloakAuthHttpRequestInterceptor(keycloak) {
        var _this = _super.call(this) || this;
        _this.keycloak = keycloak;
        return _this;
    }
    KeycloakAuthHttpRequestInterceptor.prototype.interceptWithoutRefresh = function (req, next) {
        if (this.keycloak.isTokenExpired()) {
            return throwError$(new HttpResponse({
                url: req.url,
                status: 401,
                statusText: 'Unauthorized',
            }));
        }
        else {
            return next.handle(reqWithTokenHeader(req, this.keycloak.token));
        }
    };
    KeycloakAuthHttpRequestInterceptor.prototype.interceptWithRefresh = function (req, next) {
        return from$(this.keycloak.getToken()).pipe(switchMap$(function (token) { return next.handle(reqWithTokenHeader(req, token)); }));
    };
    KeycloakAuthHttpRequestInterceptor.ɵfac = function KeycloakAuthHttpRequestInterceptor_Factory(t) { return new (t || KeycloakAuthHttpRequestInterceptor)(i0.ɵɵinject(i1.KeycloakService)); };
    KeycloakAuthHttpRequestInterceptor.ɵprov = i0.ɵɵdefineInjectable({ token: KeycloakAuthHttpRequestInterceptor, factory: KeycloakAuthHttpRequestInterceptor.ɵfac });
    return KeycloakAuthHttpRequestInterceptor;
}(BasicAuthHttpRequestInterceptor));
export { KeycloakAuthHttpRequestInterceptor };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(KeycloakAuthHttpRequestInterceptor, [{
        type: Injectable
    }], function () { return [{ type: i1.KeycloakService }]; }, null); })();
