/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FilteredDataQuery } from '@gh/core-data';
import { isNotNil, stopEvent, wrapInObservable } from '@gh/core-util';
import { catchError$, delay$, filter$, first$, map$, switchAll$, switchMap$, tap$ } from '@gh/rx/operators';

import { assign, clamp, eq, find, isEmpty, isNil, isString, map, property } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AutocompleteOptionDirective } from './autocomplete-option.directive';
import { Option } from './autocomplete-option.model';
import {
  BOTTOM_PLACEHOLDER_PADDING_BOTTOM,
  BOTTOM_PLACEHOLDER_PADDING_TOP,
  DEFAULT_CARD_OFFSET,
  DEFAULT_MAX_VISIBLE_OPTIONS,
  DEFAULT_NEXT_BATCH_DISTANCE_PX,
  DEFAULT_NEXT_BATCH_SIZE,
  OPTION_HEIGHT,
  PANEL_MIN_WIDTH,
  PLACEHOLDER_PADDING_HORIZONTAL,
  TOP_PLACEHOLDER_PADDING_BOTTOM,
  TOP_PLACEHOLDER_PADDING_TOP,
} from './autocomplete.constants';

export interface AutocompleteLoadMoreEvent {
  query: FilteredDataQuery<any>;

  load(ob: Observable<any[]> | any[]): void;
}

/**
 * TODO: host Component parameter
 * TODO: NOT ACTUAL(we have no scroll then) bug with 2 options (without scroll),
 * TODO: NOT ACTUAL Test real data request
 * TODO: Cancel request on blur
 * FIXME: scroll top when autocomplete loaded variants
 * FIXME: DO NOT send request if empty buffer
 * TODO: replace function (anyList: any[]) => {
 *      this.updateOptions(anyList);
 *      assign(this.listSourceParams, { loading: false });
 *    } to WITHOUT side effects function
 */
@Component({
  selector: 'gh-autocomplete',
  templateUrl: 'autocomplete.component.html',
  styleUrls: ['autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'role': 'autocomplete',
    '[class.gh-autocomplete]': 'true',
  },
  exportAs: 'ghAutocomplete',
})
export class AutocompleteComponent implements OnInit, AfterContentInit {
  inputBuffer: string = '';
  selectedOption?: Option;
  // onChangeCallback: (_: any) => void = noop;
  activeCardWidth?: number = void 0;

  // @DANGEROUS: check initial active width
  @Input() activeWidth?: number = void 0;
  @Input() minWidth: number = PANEL_MIN_WIDTH;

  @Input() nextBatchSize: number = DEFAULT_NEXT_BATCH_SIZE;
  @Input() nextBatchDistance: number = DEFAULT_NEXT_BATCH_DISTANCE_PX;
  @Input() debounceTime: number = 500;
  @Input() maxVisibleOptions = DEFAULT_MAX_VISIBLE_OPTIONS;
  @Input() hasChips: boolean = false;

  // @TODO: describe default value in main docs
  @Input('panelClass') panelClass?: string;

  @Input() grouped?: PredicateFn<any>;

  // tslint:disable-next-line:no-output-named-after-standard-event
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-named-after-standard-event
  @Output() select: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadMore: EventEmitter<AutocompleteLoadMoreEvent> = new EventEmitter<AutocompleteLoadMoreEvent>();

  @ViewChild(TemplateRef, { static: false }) template: TemplateRef<any>;
  @ViewChild('optionContainer', { read: ElementRef, static: false }) optionContainer: ElementRef;

  @ContentChild(AutocompleteOptionDirective, { static: false }) optionTemplate?: AutocompleteOptionDirective;

  suggestionListHasChanged = new Subject<void>();
  selectedOptionChange = new Subject<Maybe<Option>>();

  focusedIndex: number = 0;
  opened: boolean = false;

  private loadFilter$$ = new Subject<Observable<any[]>>();
  private _unbound: boolean;
  private currentValue?: any = void 0;
  private options: Option[] = [];
  // private autocompleteInput: AutocompleteDirective;

  private initialized$$ = new BehaviorSubject<boolean>(false);

  private isLoading = false;
  private endOfLastLoadedRange = 0;
  private reachEndOfList = false;

  private suggestions: any[] = [];

  private anchorDimensions = { width: 0, height: 0 };

  private placeholderPosition = 'top';

  private _textKey: TransformFn<any, string> = property('text');
  private _displayKey: TransformFn<any, string>;
  private _valueKey: IdentityFn<any>;

  @Input('optionTextKey')
  set textKey(textKey: any) {
    this._textKey = isString(textKey) ? property(textKey) : textKey;
  }

  @Input('optionDisplayKey')
  set displayKey(displayKey: any) {
    this._displayKey = isString(displayKey) ? property(displayKey) : displayKey;
  }

  @Input('optionValueKey')
  set valueKey(valueKey: any) {
    this._valueKey = isString(valueKey) ? property(valueKey) : valueKey;
  }

  get noResults(): boolean {
    return this.suggestions.length === 0 && this.reachEndOfList;
  }

  get isLoadingMore(): boolean {
    return this.isLoading;
  }

  @Input()
  set value(value: any) {
    this.setValue(value);
  }

  get value(): any {
    return this.currentValue;
  }

  get displayFoundItems(): boolean {
    const { opened, options } = this;
    // selectedOption ==> inputBuffer exists
    return opened && !isEmpty(options);
  }

  get displayMenu(): boolean {
    return this.displayFoundItems || this.displayNoResults;
  }

  /**
   * Display "no results" panel only in bound mode
   */
  get displayNoResults(): boolean {
    return this.noResults && !this._unbound;
  }

  // get unbound(): boolean {
  //   return this.autocompleteInput.unbound;
  // }

  get width(): number {
    return Math.max(this.anchorDimensions.width + PLACEHOLDER_PADDING_HORIZONTAL * 2, this.minWidth);
  }

  get anchorWidth(): number {
    return this.anchorDimensions.width + PLACEHOLDER_PADDING_HORIZONTAL * 2;
  }

  get topOffset(): number {
    return this.isPlaceholderOnTop ? this.anchorDimensions.height + TOP_PLACEHOLDER_PADDING_TOP : 0;
  }

  get topPlaceholderHeight(): number {
    return this.isPlaceholderOnTop
      ? this.anchorDimensions.height + TOP_PLACEHOLDER_PADDING_TOP + TOP_PLACEHOLDER_PADDING_BOTTOM
      : 0;
  }

  get bottomOffset(): number {
    return this.isPlaceholderOnTop ? 0 : this.anchorDimensions.height + BOTTOM_PLACEHOLDER_PADDING_BOTTOM;
  }

  get bottomPlaceholderHeight(): number {
    return this.isPlaceholderOnTop
      ? 0
      : this.anchorDimensions.height + BOTTOM_PLACEHOLDER_PADDING_TOP + BOTTOM_PLACEHOLDER_PADDING_BOTTOM;
  }

  get placeholderTopPadding(): number {
    return this.isPlaceholderOnTop ? TOP_PLACEHOLDER_PADDING_TOP : BOTTOM_PLACEHOLDER_PADDING_TOP;
  }

  get placeholderBottomPadding(): number {
    // return PLACEHOLDER_PADDING_BOTTOM + 5;
    return this.isPlaceholderOnTop ? TOP_PLACEHOLDER_PADDING_BOTTOM : BOTTOM_PLACEHOLDER_PADDING_BOTTOM;
  }

  get placeholderLeftPadding(): number {
    return PLACEHOLDER_PADDING_HORIZONTAL;
  }

  get placeholderRightPadding(): number {
    return this.width - this.anchorWidth + PLACEHOLDER_PADDING_HORIZONTAL;
  }

  get progressBarBottomOffset(): number {
    return this.isPlaceholderOnTop ? 0 : -this.anchorDimensions.height;
  }

  get hasOptionTemplate(): boolean {
    return isNotNil(this.optionTemplate);
  }

  get isPlaceholderOnTop(): boolean {
    return this.placeholderPosition === 'top';
  }

  get containerHeight(): number {
    return Math.min(this.options.length, this.maxVisibleOptions) * OPTION_HEIGHT;
  }

  get listHeight(): number {
    return this.options.length * OPTION_HEIGHT;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    // Loading filter autocomplete
    this.loadFilter$$.pipe(
      tap$(() => {
        this.isLoading = true;
      }),
      switchAll$(),
      catchError$(() => [[]]),
      tap$((anyList: any[]) => {
        const { endOfLastLoadedRange, nextBatchSize } = this;

        const suggestions = this.suggestions.concat(anyList);

        this.isLoading = false;
        this.endOfLastLoadedRange = endOfLastLoadedRange + nextBatchSize;
        this.suggestions = suggestions;

        this.updateOptions(this.suggestions);

        this.changeDetectorRef.markForCheck();
      }),
      switchMap$(() => this.ngZone.onMicrotaskEmpty.pipe(first$())))
      .subscribe(() => {
        this.suggestionListHasChanged.next();
      });
  }

  setUnbound(unbound: boolean): void {
    this._unbound = unbound;
  }

  setAnchorDimensions(width: number, height: number): void {
    this.anchorDimensions = { width, height };
  }

  typingResults(value: string): void {
    assign(this, { inputBuffer: value, suggestions: [], endOfLastLoadedRange: 0 });
    this.loadNextSuggestions();
    this.currentValue = void 0;

    this.changeDetectorRef.markForCheck();
  }

  ngAfterContentInit(): void {
    this.initialized$$.next(true);
  }

  /**
   * Clears selected suggestion
   */
  resetTo(value: any): void {
    const option = isNil(value) ? void 0 : this.createOption(value);
    const inputBuffer = option ? option.text : '';
    const optionValue = option ? option.value : void 0;
    assign(this, {
      selectedOption: void 0,
      currentValue: void 0,
      focusedIndex: 0,
      suggestions: [],
      opened: false,
    });
    this.resetSearch(inputBuffer);
    this.updateOptions([]);
    this.change.emit(optionValue);
    this.select.emit(optionValue);

    this.changeDetectorRef.markForCheck();
  }

  open(): void {
    const { activeWidth } = this;
    assign(this, {
      opened: true,
      focusedIndex: 0,
      // Sometimes we need change input width according to UI (e.g. long data)
      activeCardWidth: activeWidth ? (activeWidth + DEFAULT_CARD_OFFSET * 2) : void 0,
      suggestions: [],
      endOfLastLoadedRange: 0,
      reachEndOfList: false,
    });

    this.loadNextSuggestions();

    this.changeDetectorRef.markForCheck();
  }

  close(): void {
    const selectedOption = this.getVirtualSelectedOption();
    assign(this, {
      opened: false,
      options: [],
      inputBuffer: selectedOption && selectedOption.text || '',
    });

    this.changeDetectorRef.markForCheck();
  }

  // FIXME: if no activeCard width -> add paddings to card
  controlWidth(): string {
    const { activeCardWidth, activeWidth, displayMenu } = this;
    return (displayMenu && activeCardWidth && `${activeCardWidth - DEFAULT_CARD_OFFSET * 2}px`) || '100%';
  }

  moveFocus(shift: number): void {
    const { focusedIndex, options } = this;
    const targetIndex = clamp(focusedIndex + shift, 0, options.length - 1);
    this.focusedIndex = targetIndex;
    this.ensureOptionVisible(targetIndex);

    this.changeDetectorRef.markForCheck();
  }

  selectByIndex(index: number): void {
    const { options } = this;
    const selectedOption = options[index];
    if (selectedOption && !selectedOption.group) {
      const { text, value } = selectedOption;
      assign(this, {
        selectedOption,
        inputBuffer: text,
        currentValue: value,
      });
      this.selectedOptionChange.next(selectedOption);
      this.change.emit(value);
      this.select.emit(value);
      this.close(); // ???
      this.changeDetectorRef.markForCheck();
    }
  }

  setPlaceholderPosition(position: 'top' | 'bottom'): void {
    this.placeholderPosition = position;
    Promise.resolve().then(() => this.changeDetectorRef.markForCheck());
  }

  onScroll(): void {
    const {
      isLoading,
      reachEndOfList,
    } = this;

    if (!isLoading && !reachEndOfList && !this.noResults) {
      this.loadNextSuggestions();
    }
  }

  setValue(nextValue: any): Observable<Maybe<Option>> {
    return this.initialized$$.pipe(
      // set value only after autocomplete component is initialized
      // this method can be called from AutocompleteDirective.writeValue when AutocompleteComponent is not initialized
      filter$(Boolean),
      // we use delay(0) to avoid ExpressionChangedAfterItHasBeenCheckedError
      delay$(0),
      map$(() => {
        if (this._unbound) {
          this.setUnboundValue(nextValue);
        } else {
          this.setBoundValue(nextValue);
        }

        this.changeDetectorRef.markForCheck();
        return this.getVirtualSelectedOption();
      }));
  }

  /**
   * Returns selected option for bound mode, or virtual selected option for unbound mode.
   * There is no actual selected option in unbound mode.
   */
  private getVirtualSelectedOption(): Maybe<Option> {
    return this._unbound ? Option.createText(this.inputBuffer) : this.selectedOption;
  }

  private setUnboundValue(text: any): void {
    const { inputBuffer } = this;

    if (text !== inputBuffer) {
      assign(this, {
        selectedOption: void 0,
        inputBuffer: text,
        currentValue: void 0,
      });
      if (this.initialized$$.value) {
        this.change.emit(text);
      }
    }
  }

  private setBoundValue(nextValue: any): void {
    const { currentValue, options } = this;
    if (nextValue !== currentValue) {
      const equalsByValueKey = ({ value }: Option) => eq(value, nextValue);
      // if value is set before options loaded, then create single option for current value.
      const optionFromList = find(options, equalsByValueKey);
      const nextOptionSource = optionFromList || nextValue;
      const selectedOption = this.createOption(nextOptionSource);
      if (!optionFromList) {
        const { text, value } = selectedOption;
        assign(this, {
          selectedOption,
          inputBuffer: text,
          currentValue: value,
        });
      } else {
        assign(this, {
          inputBuffer: selectedOption.text,
          currentValue: nextValue,
          selectedOption,
        });
      }
      if (this.initialized$$.value) {
        this.change.emit(nextValue);
      }
    }
  }

  /**
   * Updates scroll of suggestion menu
   * // FIXME: Short distance reduces nextBatchDistance luft. When data is big we have narrow bottom
   * // TODO: Create controlled component which will control scroll. AutocompleteScroll/InfiniteScroll Directive
   * @TODO PLANNING: replace keys controls to next sprint
   */
  private ensureOptionVisible(index: number): void {
    const optionContainerEl = this.optionContainer.nativeElement;

    const focusedTop = index * OPTION_HEIGHT;

    const { scrollTop, clientHeight } = optionContainerEl;

    if (focusedTop + OPTION_HEIGHT > scrollTop + clientHeight) {
      // scroll down
      optionContainerEl.scrollTop = focusedTop - clientHeight + OPTION_HEIGHT;
    } else if (focusedTop < scrollTop) {
      // scroll up
      optionContainerEl.scrollTop = focusedTop;
    }
  }

  /**
   * selectOption option
   * @param event
   * @param index of selected item
   */
  private selectOption(event: Event, index: number): void {
    stopEvent(event);
    this.selectByIndex(index);
  }

  /**
   * Update suggestions
   * @param suggestions
   */
  private updateOptions(suggestions: any[]): void {
    const { endOfLastLoadedRange } = this;

    this.options = map(suggestions, (suggestion) => this.createOption(suggestion));
    this.reachEndOfList = suggestions.length < endOfLastLoadedRange;
  }

  private loadNextSuggestions(): void {
    const { nextBatchSize, endOfLastLoadedRange } = this;
    this.loadMore.emit({
      query: { from: endOfLastLoadedRange, to: endOfLastLoadedRange + nextBatchSize, filter: this.inputBuffer },
      load: (ob) => this.loadFilter$$.next(wrapInObservable(ob)),
    });
  }

  private resetSearch(inputBuffer: string): void {
    this.inputBuffer = inputBuffer;
    this.suggestions = [];
    this.endOfLastLoadedRange = 0;
    this.reachEndOfList = false;
  }

  private createOption(suggestion: any): Option {
    const { _textKey, _displayKey, _valueKey, grouped } = this;
    return Option.create(suggestion, _textKey, _displayKey, _valueKey, grouped);
  }
}
