/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { UserContext } from '@gh/common-shared';
import { ID, UserData } from '@gh/common-shared-data';
import { OfficeStatus } from '@gh/customer-shared-data';

export type CustomerUserData = UserData;

export interface JobInfo {
  jobId: ID;
  jobNumber: string;
  officeId: ID;
  officeName: string;
  officeStatus: OfficeStatus;
  officePhone: string;
  siteAddress: string;
  addressee: string;
  contractPrice: number;
  jobAdminName: string;
  supervisorName: string;
  salesPersonName: string;
  isFinalized: boolean;
  isNotProceeding: boolean;
}

export abstract class CustomerUserContext extends UserContext {
  abstract readonly currentJob: JobInfo;
  abstract readonly jobs: JobInfo[];

  abstract setCurrentJob(id: ID): void;

  abstract isJobExist(id: ID): boolean;
}
