/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive } from '@angular/core';
import { MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material/core';
import * as i0 from "@angular/core";
/**
 * This is a temporarily solution until we decide
 * whether we will use always floated labels in the whole application or will not.
 * Alternative is <code>MAT_LABEL_GLOBAL_OPTIONS</code> on the root module.
 */
var FloatLabelAlwaysDirective = /** @class */ (function () {
    function FloatLabelAlwaysDirective() {
    }
    FloatLabelAlwaysDirective.ɵfac = function FloatLabelAlwaysDirective_Factory(t) { return new (t || FloatLabelAlwaysDirective)(); };
    FloatLabelAlwaysDirective.ɵdir = i0.ɵɵdefineDirective({ type: FloatLabelAlwaysDirective, selectors: [["", "ghFloatLabelAlways", ""]], features: [i0.ɵɵProvidersFeature([{ provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'always' } }])] });
    return FloatLabelAlwaysDirective;
}());
export { FloatLabelAlwaysDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FloatLabelAlwaysDirective, [{
        type: Directive,
        args: [{
                selector: '[ghFloatLabelAlways]',
                providers: [{ provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'always' } }],
            }]
    }], null, null); })();
