/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { isNil } from 'lodash';
import { LocalizationBusinessService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services";
var DurationPipe = /** @class */ (function () {
    function DurationPipe(localization) {
        this.localization = localization;
    }
    DurationPipe.prototype.transform = function (value) {
        return isNil(value) ? value : this.localization.formatHoursDuration(value);
    };
    DurationPipe.ɵfac = function DurationPipe_Factory(t) { return new (t || DurationPipe)(i0.ɵɵdirectiveInject(i1.LocalizationBusinessService)); };
    DurationPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "duration", type: DurationPipe, pure: true });
    return DurationPipe;
}());
export { DurationPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DurationPipe, [{
        type: Pipe,
        args: [{ name: 'duration' }]
    }], function () { return [{ type: i1.LocalizationBusinessService }]; }, null); })();
