/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Quantity } from '@gh/core-data';
import { isNotNil } from '@gh/core-util';
import { isEqual, isString, noop } from 'lodash';
import { createTextMaskInputElement } from 'text-mask-core';
import { QuantityTypeBase } from '../core';
import { QuantityType } from '../core/type.interfaces';
import { InputState, TypeService } from '../services';

const FOCUSED_QUANTITY_FORMAT_QUERY = {
  noLabels: true,
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
};

function isMinusOnly(str: string): boolean {
  return str === '-';
}

/**
 * This module declares directive for quantity inputs. We had to define own implementation because it provides
 * better integration with number type approach (core-type package)
 *
 * Implementation was taken from this repository https://github.com/text-mask/text-mask.
 * File: https://github.com/text-mask/text-mask/blob/master/angular2/src/angular2TextMask.ts
 */
@Directive({
  selector: '[ghQuantityType]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: QuantityTypeDirective,
    multi: true,
  }],
})
export class QuantityTypeDirective implements OnInit, ControlValueAccessor {
  @Input('ghQuantityTypeFormatOnFocus') formatOnFocus: boolean = true;

  private textMaskInputElement: any;

  // stores the last value for comparison
  private lastStrValue: string;
  private lastValue?: Quantity<any>;

  private _type: QuantityType<any>;

  private _onTouched = noop;
  private _onChange = noop;

  constructor(private renderer: Renderer2,
              private element: ElementRef,
              private typeService: TypeService,
              private inputState: InputState) {
  }

  @Input('ghQuantityType')
  set type(type: QuantityType<any> | string) {
    this._type = isString(type) ? <QuantityType<any>>this.typeService.get(type) : type;
  }

  get isNumberBased(): boolean {
    return this._type.base === QuantityTypeBase.Number;
  }

  ngOnInit(): void {
    if (this.isNumberBased) {
      this.setupMask();
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(str: string): void {
    if (this.isNumberBased) {
      this.textMaskInputElement.update(str);
    }

    const nextStrValue = this.element.nativeElement.value;

    if (this.lastStrValue !== nextStrValue) {
      this.lastStrValue = nextStrValue;
      if (nextStrValue && !isMinusOnly(nextStrValue)) {
        const nextValue = this._type.parse(nextStrValue);
        if (!isEqual(this.lastValue, nextValue)) {
          this.lastValue = nextValue;
          this._onChange(nextValue);
        }
      } else {
        this.lastValue = void 0;
        this._onChange(void 0);
      }
    }
  }

  @HostListener('focus')
  onFocus(): void {
    const element = this.element.nativeElement;
    if (this.formatOnFocus && isNotNil(this.lastValue)) {
      element.value = this._type.format(this.lastValue, FOCUSED_QUANTITY_FORMAT_QUERY);

      if (!this.inputState.mouseDown) {
        element.select();
      }
    }
  }

  @HostListener('blur')
  onBlur(): void {
    if (isNotNil(this.lastValue)) {
      this.element.nativeElement.value = this._type.format(this.lastValue);
    }
    this._onTouched();
  }

  writeValue(value?: Quantity<any>): void {
    this.lastValue = value;
    if (this.isNumberBased) {
      if (isNotNil(value) && this.textMaskInputElement !== void 0) {
        this.textMaskInputElement.update(this._type.format(value));
      } else {
        this.element.nativeElement.value = '';
      }
      this.lastStrValue = this.element.nativeElement.value;
    } else {
      const strValue = value ? this._type.format(value) : '';
      this.lastStrValue = strValue;
      this.element.nativeElement.value = strValue;
    }
  }

  registerOnChange(fn: (value: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
  }

  private setupMask(): void {
    this.textMaskInputElement = createTextMaskInputElement({
      inputElement: this.element.nativeElement,
      mask: this._type['mask'],
    });
  }
}
