<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<gh-ext-page>
  <gh-ext-page-title title="{{'lbl_docs_sent_for_signing' | translate}}"></gh-ext-page-title>
  <gh-ext-page-content matSort="">
    <gh-ext-load-tracker *ngIf="!isDocumentsExist()" fxFlex="" noData=""></gh-ext-load-tracker>

    <ng-container *ngIf="isDocumentsExist()">
      <!-- Desktop version -->
      <mat-table fxShow="" fxHide.lt-md="true" [dataSource]="eDocumentListDataSource" [trackBy]="getEDocumentId">
        <ng-container matColumnDef="sentDate">
          <mat-header-cell fxFlex="20%" *matHeaderCellDef="" mat-sort-header="">{{'lbl_sent_date' | translate}}</mat-header-cell>
          <mat-cell fxFlex="20%" *matCellDef="let eDocument">{{ eDocument.sentDate | typeFormat:'ShortDate' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="documents">
          <mat-header-cell fxFlex="50%" *matHeaderCellDef="" mat-sort-header="">{{'lbl_documents' | translate}}</mat-header-cell>
          <mat-cell fxFlex="50%" *matCellDef="let eDocument">{{ eDocument.documents }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="isSigned">
          <mat-header-cell fxFlex="160px" *matHeaderCellDef="" mat-sort-header="">{{'lbl_status' | translate}}</mat-header-cell>
          <mat-cell fxFlex="160px" *matCellDef="let eDocument" [class.ext-primary-color]="!isEDocumentSigned(eDocument)">
            {{ statusColumnValue(eDocument) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell fxFlex="15%" *matHeaderCellDef="" mat-sort-header="">
          </mat-header-cell>
          <mat-cell fxFlex="15%" *matCellDef="let eDocument">
            <button *ngIf="isEDocumentActive(eDocument)" (click)="signEDocument(eDocument)" mat-flat-button="" type="button" class="small cell-button">{{'lbl_sign' | translate}}</button>
            <button *ngIf="isEDocumentSigned(eDocument)" (click)="viewEDocument(eDocument)" mat-stroked-button="" type="button" class="small cell-button" color="primary">{{'lbl_view' | translate}}</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let eDocument; columns: displayedColumns">
        </mat-row>

      </mat-table>

      <!-- Mobile version -->
      <div fxHide="" fxShow.lt-md="true">
        <mat-card *ngFor="let eDocument of eDocumentListDataSource | connect" class="card-list-item">
          <mat-card-content>
            <div class="card-content-item full-width">
              <div class="card-content-item-title">{{'lbl_sent_date' | translate}}</div>
              <div class="card-content-item-value">{{ eDocument.sentDate | typeFormat:'ShortDate' }}</div>
            </div>
            <div class="card-content-item full-width">
              <div class="card-content-item-title">{{'lbl_documents' | translate}}</div>
              <div class="card-content-item-value">{{ eDocument.documents }}</div>
            </div>
            <div class="card-content-item full-width">
              <div class="card-content-item-title">{{'lbl_status' | translate}}</div>
              <div class="card-content-item-value" [class.ext-primary-color]="!isEDocumentSigned(eDocument)">
                {{ statusColumnValue(eDocument) }}
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <button *ngIf="isEDocumentActive(eDocument)" (click)="signEDocument(eDocument)" mat-flat-button="" type="button" color="primary" class="small">{{'lbl_sign' | translate}}</button>
            <button *ngIf="isEDocumentSigned(eDocument)" (click)="viewEDocument(eDocument)" mat-stroked-button="" type="button" class="small">{{'lbl_view' | translate}}</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </ng-container>
  </gh-ext-page-content>
</gh-ext-page>
