/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var API_BASE_URL = new InjectionToken('ApiBaseUrl');
var RestSettings = /** @class */ (function () {
    function RestSettings(httpClient, baseUrl) {
        this.httpClient = httpClient;
        this.baseUrl = baseUrl;
    }
    RestSettings.ɵfac = function RestSettings_Factory(t) { return new (t || RestSettings)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(API_BASE_URL)); };
    RestSettings.ɵprov = i0.ɵɵdefineInjectable({ token: RestSettings, factory: RestSettings.ɵfac });
    return RestSettings;
}());
export { RestSettings };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(RestSettings, [{
        type: Injectable
    }], function () { return [{ type: i1.HttpClient }, { type: undefined, decorators: [{
                type: Inject,
                args: [API_BASE_URL]
            }] }]; }, null); })();
