export const APPLICATION = "customer";
export const ENVIRONMENT = "prod";
export const BACKEND = "";
export const KEYCLOAK_JS_URL = "https://api-builder.gjgardner.co.nz/auth/js/keycloak.js";
export const KEYCLOAK_JSON_URL = "";
export const KEYCLOAK_MIN_VALIDITY = 5;
export const KEYCLOAK_AUTH_SERVER_URL = "https://api-builder.gjgardner.co.nz/auth";
export const KEYCLOAK_REALM = "GH-CPortal-NZ";
export const KEYCLOAK_RESOURCE = "GH-CPortal-WebApp-NZ";
export const KEYCLOAK_SECRET = "";
export const API_BASE_URL = "https://api-builder.gjgardner.co.nz/cp/rest";
export const API_SB_URL = "/";
export const ENV_BASE_URL = "https://api-builder.gjgardner.co.nz";
export const NODE_ENV = "production";
export const REGION_CODE = "nz";
export const CONTEXT_PATH = "/";
export const VERSION = "5.2.21";
export const RELEASE = "customer@5.2.21";
export const APP_NAME = "Customer Portal";
export const DEFAULT_LOCALE = "en_NZ";
export const LOCALES_CONTEXT_FILTER = /(en|en-AU|en-US|en-NZ)\.js$/;
export const DEFAULT_EMAIL_SUFFIX = "en_US";
export const DEFAULT_COUNTRY_CODE = undefined;
export const SCAYT_SERVICE_ID = undefined;
export const IGNORE_DIRTY_CHANGES = false;
export const ANGULAR_PROD_MODE = true;
export const AUTH_MODE = "keycloak";
export const AUTH_MODE_INDEX = 0;
export const MOCK_AUTH_MODE_PREDEFINED_USERS = [];
export const TODO_CHECK_ENABLED = undefined;
export const TODO_CHECK_INTERVAL = undefined;
export const MAINTENANCE_CHECK_ENABLED = undefined;
export const MAINTENANCE_CHECK_INTERVAL = undefined;
export const PRODUCE_STATUS_CHECK_INTERVAL = undefined;
export const AUTOMATION_GRID = false;
export const GOOGLE_CLIENT_ID = undefined;
export const GOOGLE_DEFAULT_SCOPES = undefined;
export const GOOGLE_UX_MODE = undefined;
export const GOOGLE_HOSTED_DOMAIN = undefined;
export const GOOGLE_ANALYTICS_ID = "G-VEY04N6EVF";
export const SENTRY_ON = true;
export const SENTRY_DSN = "https://b9d703ca5a14464cb64c7c8951abf57a@sentry.io/1423018";
export const HELP_URL = "";
export const DEFAULT_CURRENCY_TEMPLATE = "{sign}${number}";
export const EXPERIMENTS_DEV_MODULE = false;
export const MOCK_BACKEND_DEV_MODULE = false;
export const IE_11_DEV_MODE = false;
export const HELP_DEV_MODE = false;