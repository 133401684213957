/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CollectionComparator } from '@gh/core-util';
import { isEqual, isNil } from 'lodash';

export const INCHES_IN_FOOT = 12;

export type Quantity<T> = {
  value: T;
  error?: any;
  uomCode: string;
};

export type Foot = { negative: boolean; ft: number; in: number; delta: number };

export type NumberQuantity<T> = Quantity<number>;
export type FootQuantity = Quantity<Foot>;

export const footToDecimal = (foot: Foot) =>
  (foot.negative ? -1 : 1) * ((foot.ft || 0) + (foot.in || 0) / INCHES_IN_FOOT + foot.delta);
export const decimalToFoot = (d: number): Foot => {
  const dAbsolute = Math.abs(d);
  const inWithFractionalPart = (dAbsolute - Math.trunc(dAbsolute)) * INCHES_IN_FOOT;
  const inches = Math.round(inWithFractionalPart);
  const isLastInch = inches === 12;
  const foot = {
    negative: d < 0,
    ft: isLastInch ? Math.floor(dAbsolute) + 1 : Math.floor(dAbsolute),
    in: isLastInch ? 0 : inches,
    delta: 0,
  };
  foot.delta = (inWithFractionalPart - inches) / INCHES_IN_FOOT;
  return foot;
};

export const truncateFoot = (f: Foot) => f.delta === 0 ? f : ({ ...f, delta: 0 });

export const createQuantity = <T>(value: T, uomCode: string): Quantity<T> => ({ value, uomCode });
export const createErrorQuantity = <T>(error: string): Quantity<T> => <any>({ error });
export const getQuantityUomCode = (q: Quantity<any>) => q.uomCode;
export const isQuantityValid = (q: Quantity<any>) => isNil(q.error);
export const getQuantityValue = <T>(q: Quantity<T>) => q.value;
export const isQuantityEquals = <T>(a: Quantity<T>, b: Quantity<T>) => {
  const aNil = isNil(a);
  const bNil = isNil(b);

  if (aNil !== bNil || aNil) {
    return false;
  }

  const aValid = isQuantityValid(a);
  const bValid = isQuantityValid(b);

  if (aValid && aValid === bValid) {
    return isEqual(getQuantityValue(a), getQuantityValue(b));
  }

  return false;
};

// tslint:disable-next-line:max-line-length
export const createQuantityComparator = (toNumberMap: Hash<TransformFn<Quantity<any>, number>>): CollectionComparator<any> =>
  (a: Quantity<any>, b: Quantity<any>): number => {
    const aNil = isNil(a);
    const bNil = isNil(b);

    if (aNil !== bNil) {
      return aNil ? -1 : 1;
    }

    const aValid = isQuantityValid(a);
    const bValid = isQuantityValid(b);

    if (aValid !== bValid) {
      return aValid ? 1 : -1;
    }

    const aMapper = toNumberMap[a.uomCode] || getQuantityValue;
    const bMapper = toNumberMap[b.uomCode] || getQuantityValue;

    return Math.sign(aMapper(a) - bMapper(b));
  };

export const mapQuantity = <T>(q: Quantity<T>, map: IdentityFn<T>): T => map(q.value);
