/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { isFunction, isNil } from 'lodash';
import * as i0 from "@angular/core";
var Clipboard = /** @class */ (function () {
    function Clipboard() {
    }
    Clipboard.prototype.ngOnDestroy = function () {
        this.destroy();
    };
    Clipboard.prototype.copy = function (text) {
        this.init();
        var currentFocusedElement = document.activeElement;
        if (this.clipboardEl) {
            this.clipboardEl.value = text;
            this.clipboardEl.select();
            document.execCommand('copy');
            if (currentFocusedElement && isFunction(currentFocusedElement['focus'])) {
                currentFocusedElement['focus']();
            }
        }
    };
    Clipboard.prototype.init = function () {
        if (isNil(this.clipboardEl)) {
            this.clipboardEl = document.createElement('textarea');
            this.clipboardEl.readOnly = true;
            this.clipboardEl.style.position = 'absolute';
            this.clipboardEl.style.left = '-1000px';
            this.clipboardEl.style.top = '-1000px';
            document.body.appendChild(this.clipboardEl);
        }
    };
    Clipboard.prototype.destroy = function () {
        if (this.clipboardEl) {
            document.body.removeChild(this.clipboardEl);
        }
        this.clipboardEl = void 0;
    };
    Clipboard.ɵfac = function Clipboard_Factory(t) { return new (t || Clipboard)(); };
    Clipboard.ɵprov = i0.ɵɵdefineInjectable({ token: Clipboard, factory: Clipboard.ɵfac });
    return Clipboard;
}());
export { Clipboard };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(Clipboard, [{
        type: Injectable
    }], null, null); })();
