/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ValidationErrors } from '@angular/forms';
import { assign, isString, keysIn } from 'lodash';

export interface TypeValidatorFn {
  (constraint: any, value: any): Maybe<{ [name: string]: any }>;
}

export function nullTypeValidator(constraints: any, value: any): Maybe<ValidationErrors> {
  return;
}

export function composeTypeValidators(constraintDefs: any,
                                      validators: { [name: string]: TypeValidatorFn }): TypeValidatorFn {
  const names = keysIn(constraintDefs);

  return (constraint, value) => {
    let violations: Maybe<ValidationErrors> = void 0;

    names.forEach((name: string) => {
      const error = validators[name](constraintDefs[name], value);

      if (error) {
        if (!violations) {
          violations = {};
        }

        violations[name] = error;
      }
    });

    return violations;
  };
}

export function composeTypeValidatorsWithMessages(prefix: string,
                                                  messageDefs: any,
                                                  constraintDefs: any,
                                                  validators: { [name: string]: TypeValidatorFn }): TypeValidatorFn {
  const names = keysIn(constraintDefs);

  return (constraints, value) => {
    let violations: Maybe<ValidationErrors> = void 0;

    names.forEach((name: string) => {
      const error = validators[name](constraints[name], value);

      if (error) {
        if (!violations) {
          violations = {};
        }

        if (messageDefs) {
          const messageDef = messageDefs[name];
          if (isString(messageDef)) {
            error['messageId'] = messageDef;
          } else {
            error['messageId'] = messageDef.id;
            assign(error, messageDef.params);
          }
        }

        violations[`${prefix}:${name}`] = error;
      }
    });

    return violations;
  };
}
