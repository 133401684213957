/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl, AsyncValidatorFn, FormGroup, ValidatorFn } from '@angular/forms';
import { ControlSchemaPackedValue } from '../form-schema';
import { FormGroupSchema, FormGroupSchemaConfig } from '../form-schema/form-group-schema';
import { AppControl } from './app-control';

export class AppFormGroup extends FormGroup implements AppControl {
  key?: string;
  required: boolean;
  visible: boolean;
  privileged: boolean;
  lastValidators?: ValidatorFn[];
  lastAsyncValidators?: AsyncValidatorFn[];

  constructor(private schema: FormGroupSchema,
              controls: { [key: string]: AbstractControl }, validator?: ValidatorFn,
              asyncValidator?: AsyncValidatorFn) {
    super(controls, validator, asyncValidator);
    this.key = schema.key;
  }

  updateSchema(config: FormGroupSchemaConfig, packedValue?: ControlSchemaPackedValue): void {
    this.schema.update(config);
    this.schema.applyTo(this, packedValue);
  }
}
