/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Output, } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/material/icon";
function AutocompleteArrowIconComponent_mat_icon_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-icon", 1);
} }
var AutocompleteArrowIconComponent = /** @class */ (function () {
    function AutocompleteArrowIconComponent(_element, changeDetectorRef) {
        this._element = _element;
        this.changeDetectorRef = changeDetectorRef;
        this.trigger = new EventEmitter();
        this.disabled = false;
        this.display = true;
    }
    AutocompleteArrowIconComponent.prototype.handleClick = function ($event) {
        if (this._element.nativeElement.parentElement.firstElementChild.disabled) {
            return;
        }
        $event.stopPropagation();
        $event.preventDefault();
        this.trigger.emit($event);
        this.changeDetectorRef.markForCheck();
    };
    AutocompleteArrowIconComponent.prototype.setDisplay = function (isDisplay) {
        this.display = isDisplay;
        this.changeDetectorRef.markForCheck();
    };
    AutocompleteArrowIconComponent.prototype.setDisabled = function (isDisabled) {
        this.disabled = isDisabled;
        this.changeDetectorRef.markForCheck();
    };
    AutocompleteArrowIconComponent.ɵfac = function AutocompleteArrowIconComponent_Factory(t) { return new (t || AutocompleteArrowIconComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
    AutocompleteArrowIconComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AutocompleteArrowIconComponent, selectors: [["gh-autocomplete-action-icon"]], hostBindings: function AutocompleteArrowIconComponent_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("click", function AutocompleteArrowIconComponent_click_HostBindingHandler($event) { return ctx.handleClick($event); });
        } }, outputs: { trigger: "trigger" }, decls: 1, vars: 1, consts: [["svgIcon", "gh:arrow-drop-down", "class", "s20 gh-autocomplete-clear-icon", 4, "ngIf"], ["svgIcon", "gh:arrow-drop-down", 1, "s20", "gh-autocomplete-clear-icon"]], template: function AutocompleteArrowIconComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, AutocompleteArrowIconComponent_mat_icon_0_Template, 1, 0, "mat-icon", 0);
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", ctx.display && !ctx.disabled);
        } }, directives: [i1.NgIf, i2.MatIcon], styles: [".gh-autocomplete-clear-icon[_ngcontent-%COMP%] {\n  position: absolute;\n  top: 6px;\n  left: calc(100% - 20px);\n  color: rgba(0, 0, 0, 0.54);\n  cursor: pointer; }"], changeDetection: 0 });
    return AutocompleteArrowIconComponent;
}());
export { AutocompleteArrowIconComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AutocompleteArrowIconComponent, [{
        type: Component,
        args: [{
                moduleId: module.id,
                selector: 'gh-autocomplete-action-icon',
                template: "\n    <mat-icon\n      svgIcon=\"gh:arrow-drop-down\"\n      *ngIf=\"display && !disabled\"\n      class=\"s20 gh-autocomplete-clear-icon\"></mat-icon>",
                styleUrls: ['autocomplete-icon.component.scss'],
                changeDetection: ChangeDetectionStrategy.OnPush,
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.ChangeDetectorRef }]; }, { trigger: [{
            type: Output
        }], handleClick: [{
            type: HostListener,
            args: ['click', ['$event']]
        }] }); })();
