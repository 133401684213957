/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import * as invariant from 'invariant';

import { get as objectGet, isNil, isString } from 'lodash';

import { Permission } from './permission';

const roots: any[] = [];

export function addPermissionRoot(root: any): void {
  roots.push(root);
}

export function findPermission(path: string): Maybe<Permission> {
  const permission = roots.reduce(
    (p, root) => {
      if (p) {
        return p;
      }

      return objectGet(root, path);
    },
    void 0);

  invariant(permission, `Permission cannot be found for path '${path}'`);

  return permission;
}

export function getPermission(path: string): Permission {
  const permission = findPermission(path);

  if (isNil(permission)) {
    throw new Error(`Permission cannot be found for the path '${path}'`);
  }

  return permission;
}

export function normalizePermission(p: string | Permission): Permission {
  return isString(p) ? getPermission(p) : p;
}
