/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { regexpablePlainString } from '@gh/core-util';
import { isNil } from 'lodash';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  /**
   * Transform function
   * @param value string
   * @param query string filter value
   * @return filtered string with markup
   */
  transform(value: any, query: string): string {
    if (isNil(query) || query.length < 1) {
      return value;
    }

    const pattern = new RegExp(`(.*)(${regexpablePlainString(query)})(.*)`, 'ig');
    const replaceWith = '$1<span class="highlighted">$2</span>$3';
    return value && value.toString().replace(pattern, replaceWith);
  }
}
