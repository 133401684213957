var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { appendElementToHead, createLinkElement, createScriptElement, isNotNil } from '@gh/core-util';
import { combineLatest$, EMPTY$, of$, throwError$ } from '@gh/rx';
import { first$, mapTo$, pluck$, switchMap$ } from '@gh/rx/operators';
import { isNil, isString, uniqueId } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import * as i0 from "@angular/core";
export var ResourceLoadingStatus;
(function (ResourceLoadingStatus) {
    ResourceLoadingStatus[ResourceLoadingStatus["NotLoaded"] = 0] = "NotLoaded";
    ResourceLoadingStatus[ResourceLoadingStatus["Loading"] = 1] = "Loading";
    ResourceLoadingStatus[ResourceLoadingStatus["Loaded"] = 2] = "Loaded";
    ResourceLoadingStatus[ResourceLoadingStatus["Error"] = 3] = "Error";
})(ResourceLoadingStatus || (ResourceLoadingStatus = {}));
var createResourceElement = function (url) { return /\.css$/.test(url) ? createLinkElement(url) : createScriptElement(url); };
var createResourceInjector = function (url) { return new Observable(function (observer) {
    var el = createResourceElement(url);
    appendElementToHead(el);
    el.onload = function () {
        observer.next(true);
        observer.complete();
    };
    el.onerror = function (error) {
        observer.error(error);
    };
}); };
var createResource = function (urlOb, dependencies) { return ({
    status: ResourceLoadingStatus.NotLoaded,
    ob$: isString(urlOb) ? createResourceInjector(urlOb) : urlOb,
    dependencies: dependencies,
}); };
var toResourceStream = function (resource) {
    if (resource.status === ResourceLoadingStatus.Loaded) {
        return of$(true);
    }
    else if (resource.status === ResourceLoadingStatus.Error) {
        return throwError$(resource.error);
    }
    else {
        return EMPTY$;
    }
};
var ResourceLoader = /** @class */ (function () {
    function ResourceLoader() {
        this.state = new BehaviorSubject({});
    }
    ResourceLoader.prototype.require = function () {
        var _this = this;
        var names = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            names[_i] = arguments[_i];
        }
        return names.length ? combineLatest$(names.map(function (name) { return _this.loadSingle(name); })).pipe(mapTo$(true)) : of$(true);
    };
    ResourceLoader.prototype.define = function (nameUrlOb, urlOb, dependencies) {
        var _a;
        var name = isString(nameUrlOb) ? nameUrlOb : uniqueId('resource:');
        if (this.isDefined(name)) {
            throw new Error("Resource for name '" + name + "' is already defined");
        }
        var resource = createResource(urlOb || nameUrlOb, dependencies || []);
        this.state.next(__assign(__assign({}, this.state.value), (_a = {}, _a[name] = resource, _a)));
    };
    ResourceLoader.prototype.isDefined = function (name) {
        return isNotNil(this.state.value[name]);
    };
    ResourceLoader.prototype.verify = function () {
        var _this = this;
        var nameOrUrls = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            nameOrUrls[_i] = arguments[_i];
        }
        var resources = nameOrUrls.map(function (url) { return ({ url: url, resource: _this.state.value[url] }); });
        var notLoadedResources = resources
            .filter(function (_a) {
            var url = _a.url, resource = _a.resource;
            return isNil(resource) || resource.status !== ResourceLoadingStatus.Loaded;
        });
        if (notLoadedResources.length) {
            throw new Error("Resource '" + notLoadedResources.map(function (_a) {
                var url = _a.url;
                return url;
            }).join(', ') + "' is not loaded");
        }
    };
    ResourceLoader.prototype.loadSingle = function (name) {
        var resource = this.ensureDefined(name);
        switch (resource.status) {
            case ResourceLoadingStatus.Loaded:
                return of$(true);
            case ResourceLoadingStatus.Loading:
                return this.state.pipe(pluck$(name), switchMap$(toResourceStream), first$());
            default:
                this.startLoading(name);
                return this.state.pipe(pluck$(name), switchMap$(toResourceStream), first$());
        }
    };
    ResourceLoader.prototype.startLoading = function (name) {
        var _this = this;
        this.setStatus(name, ResourceLoadingStatus.Loading);
        var resource = this.get(name);
        var dependencies$ = resource.dependencies.length > 0 ? this.require.apply(this, resource.dependencies) : of$(true);
        dependencies$.pipe(switchMap$(function () { return resource.ob$; }))
            .subscribe(function () { return _this.setStatus(name, ResourceLoadingStatus.Loaded); }, function (error) { return _this.setStatus(name, ResourceLoadingStatus.Error, error); });
    };
    ResourceLoader.prototype.setStatus = function (name, status, error) {
        var _a;
        var state = this.state;
        state.next(__assign(__assign({}, state.value), (_a = {}, _a[name] = __assign(__assign({}, state.value[name]), { status: status, error: error }), _a)));
    };
    ResourceLoader.prototype.ensureDefined = function (name) {
        if (!this.isDefined(name)) {
            this.define(name);
        }
        return this.get(name);
    };
    ResourceLoader.prototype.get = function (name) {
        return this.state.value[name];
    };
    ResourceLoader.ɵfac = function ResourceLoader_Factory(t) { return new (t || ResourceLoader)(); };
    ResourceLoader.ɵprov = i0.ɵɵdefineInjectable({ token: ResourceLoader, factory: ResourceLoader.ɵfac });
    return ResourceLoader;
}());
export { ResourceLoader };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ResourceLoader, [{
        type: Injectable
    }], null, null); })();
