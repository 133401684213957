/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:import-blacklist

import { of as of$ } from 'rxjs';

export {
  of as of$,
  from as from$,
  combineLatest as combineLatest$,
  interval as interval$,
  fromEvent as fromEvent$,
  timer as timer$,
  defer as defer$,
  throwError as throwError$,
  merge as merge$,
  concat as concat$,
  EMPTY as EMPTY$,
  NEVER as NEVER$,
} from 'rxjs';

export const TRUE$ = of$(true);
export const FALSE$ = of$(false);
