/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { logError } from '@gh/core-util';

import { distinctUntilChanged$, map$, mapTo$, scan$, startWith$, tap$ } from '@gh/rx/operators';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type MutateStateEvent = { type: any; [key: string]: any };

export type ChildStateFactory<T, U> = (state: T) => U;
export type MutateStateReducer<T> = (state: T, event: MutateStateEvent) => T;
export type ChildMutateStateReducer<T, U> = (parent: T, state: U, event: MutateStateEvent) => U;

const INIT_CHILD_STATE_EVENT: MutateStateEvent = { type: Symbol('init-child-state-event') };

export class State<T> extends BehaviorSubject<T> {
  private events$$ = new Subject<MutateStateEvent>();
  private dumpOn: boolean = false;

  constructor(private initState: T, private reducer: MutateStateReducer<T>) {
    super(initState);

    this.events$$.pipe(
      scan$(reducer, initState),
      distinctUntilChanged$())
      .subscribe(
        (state) => {
          if (this.dumpOn) {
            this.dumpState(state);
          }

          this.next(state);
        },
        (error) => console.error(error), // tslint:disable-line:no-console
      );
  }

  select<U>(selector: (value: T) => U): Observable<U>;
  select<U, A1>(selector: (value: T, a1: A1) => U, a1: A1): Observable<U>;
  select<U, A1, A2>(selector: (value: T, a1: A1, a2: A2) => U, a1: A1, a2: A2): Observable<U>;
  select<U, A1, A2, A3>(selector: (value: T, a1: A1, a2: A2, a3: A3) => U, a1: A1, a2: A2, a3: A3): Observable<U>;
  select<U>(selector: (value: T, ...args: any[]) => U, ...args: any[]): Observable<U>;
  select(selector: (value: any, ...args: any[]) => any, ...args: any[]): any {
    return this.pipe(
      distinctUntilChanged$(),
      map$((value) => selector(value, ...args)),
      distinctUntilChanged$());
  }

  project<U>(selector: (value: T) => U): U;
  project<U, A1>(selector: (value: T, a1: A1) => U, a1: A1): U;
  project<U, A1, A2>(selector: (value: T, a1: A1, a2: A2) => U, a1: A1, a2: A2): U;
  project<U, A1, A2, A3>(selector: (value: T, a1: A1, a2: A2, a3: A3) => U, a1: A1, a2: A2, a3: A3): U;
  project<U>(selector: (value: T, ...args: any[]) => U, ...args: any[]): Observable<U>;
  project(selector: (value: any, ...args: any[]) => any, ...args: any[]): any {
    return selector(this.value, ...args);
  }

  mutate(event: MutateStateEvent): void {
    this.events$$.next(event);
  }

  createChild<U>(factory: ChildStateFactory<T, U>, reducer: ChildMutateStateReducer<T, U>): Observable<U> {
    return this.events$$.pipe(
      startWith$(INIT_CHILD_STATE_EVENT),
      scan$(
        (state, event) => {
          if (event === INIT_CHILD_STATE_EVENT) {
            // calculate initial state
            return factory(this.value);
          } else {
            // transform the last state of calculated state
            return reducer(this.value, state, event);
          }
        },
        <any>void 0));
  }

  dump(): void {
    this.dumpOn = true;

    this.dumpState(this.value);
  }

  private dumpState(state: T): void {
    console.log(state); // tslint:disable-line:no-console
  }
}

export function mutate<T>(state: State<T>): (ob: Observable<MutateStateEvent>) => Observable<void> {
  return (ob) => ob.pipe(
    tap$({
      next: (event) => state.mutate(event),
      error: logError,
    }),
    mapTo$(void 0));
}
