/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { UploadEventType, UploadStatus } from './upload.interfaces';
export function s3UploadXhr(config, item, params) {
    return new Observable(function (subscriber) {
        var xhr = new XMLHttpRequest();
        var body = buildBody(xhr, config, item);
        xhr.open('POST', getPostUrl(config), true);
        xhr.withCredentials = true;
        xhr.responseType = 'document';
        xhr.upload.onprogress = function (event) {
            var progress = event.lengthComputable ? event.loaded / event.total : 0;
            subscriber.next({
                item: __assign(__assign({}, item), { status: UploadStatus.Uploading, progress: progress }),
                type: UploadEventType.Progress,
            });
        };
        xhr.onload = function () {
            if (isSuccessResponse(xhr)) {
                subscriber.next({
                    item: __assign(__assign(__assign(__assign({}, item), { status: UploadStatus.Completed }), parseSuccessResponse(config, xhr)), { progress: 1 }),
                    type: UploadEventType.Completed,
                });
                subscriber.complete();
            }
            else {
                subscriber.next({
                    item: __assign(__assign({}, item), { status: UploadStatus.Error, progress: void 0 }),
                    type: UploadEventType.Error,
                });
                subscriber.error();
            }
        };
        xhr.onerror = function () {
            subscriber.next({
                item: __assign(__assign({}, item), { status: UploadStatus.Error, progress: void 0 }),
                type: UploadEventType.Error,
            });
            subscriber.error();
        };
        xhr.onabort = function () {
            subscriber.next({
                item: __assign(__assign({}, item), { status: UploadStatus.NotStarted, progress: void 0 }),
                type: UploadEventType.Cancelled,
            });
            subscriber.complete();
        };
        xhr.send(body);
        return function () {
            xhr.abort();
        };
    });
}
function isSuccessResponse(xhr) {
    var status = xhr.status;
    return status >= 200 && status < 400; // tslint:disable-line:no-magic-numbers
}
function parseSuccessResponse(config, xhr) {
    var key = config.key, location = config.location;
    return { key: key, location: location };
}
function getPostUrl(config) {
    return location.protocol + "//" + config.bucket + ".s3.amazonaws.com/";
}
function buildBody(xhr, config, item) {
    var blob = item.blob;
    if (isNil(blob)) {
        throw new Error('Uploading process cannot be started, because Blob data to be uploaded is not defined');
    }
    var formData = new FormData();
    formData.append('key', config.key);
    formData.append('acl', config.acl);
    formData.append('Content-Type', item.mimeType || blob.type);
    formData.append('X-Amz-Credential', config.credentials);
    formData.append('X-Amz-Algorithm', config.algorithm);
    formData.append('X-Amz-Date', config.date);
    formData.append('Policy', config.policy);
    formData.append('X-AMZ-Signature', config.signature);
    formData.append('file', blob);
    return formData;
}
