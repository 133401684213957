/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
// tslint:disable:no-magic-numbers
import { digits } from '../mask-utils';
export var MASKED_EN_NZ = {
    Zip: {
        constraints: {
            pattern: /^[0-9]{4}$/,
        },
        mask: digits(4),
    },
    Gst: {
        constraints: {
            pattern: /^([0-9]{2}-[0-9]{3}-[0-9]{3})|([0-9]{3}-[0-9]{3}-[0-9]{3})$/,
        },
        mask: function (value, config) {
            var mask = __spreadArrays(digits(2), ['-'], digits(3), ['-'], digits(3));
            value = value.replace(new RegExp(config.placeholderChar + '|-', 'g'), '');
            if (value.length > 8) {
                mask = __spreadArrays(digits(3), ['-'], digits(3), ['-'], digits(3));
            }
            return mask;
        },
    },
    BsbNumber: {
        constraints: {
            pattern: /^\d{6}$/,
        },
        mask: digits(6),
    },
    PhoneNumber: {
        constraints: {
            pattern: /^\d{20}$/,
        },
        mask: __spreadArrays(digits(20)),
    },
};
