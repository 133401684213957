/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ID } from '@gh/common-shared-data';
import { CONTEXT_PATH } from '@gh/config';

const APP_VIEW_RE = new RegExp(`^${CONTEXT_PATH}/?(accept-invitation/([^/?]+))?`);

export interface BootstrapParams {
  baseUrl: string;
  path: string;
  query: string;
  acceptedOfficeId?: ID;
}

export function getCustomerBootstrapParams(): BootstrapParams {
  const routeMatch = APP_VIEW_RE.exec(location.pathname);

  const acceptedOfficeId = routeMatch ? routeMatch[2] : void 0; // tslint:disable-line:no-magic-numbers

  return {
    baseUrl: CONTEXT_PATH,
    path: location.pathname.substring(CONTEXT_PATH.length),
    query: location.search,
    acceptedOfficeId,
  };
}
