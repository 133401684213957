/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers
import { MaskedTextTypeDefenition } from '@gh/core-type';
import { digits } from '../mask-utils';

export const MASKED_EN_US: Hash<MaskedTextTypeDefenition> = {
  Zip: {
    constraints: {
      pattern: /^[0-9]{5}(-[0-9]{4})?$/,
    },
    mask: (value: string, config: any) => {
      const mask: any[] = digits(5);
      value = value.replace(new RegExp(config.placeholderChar + '|-', 'g'), '');

      if (value.length > 5) {
        mask.push('-', ...digits(4));
      }
      return mask;
    },
  },
  TaxId: {
    constraints: {
      pattern: /^[0-9]{2}-[0-9]{7}$/,
    },
    mask: [...digits(2), '-', ...digits(7)],
  },
  SocialNumber: {
    constraints: {
      pattern: /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/,
    },
    mask: [...digits(3), '-', ...digits(2), '-', ...digits(4)],
  },
  TaxNumber: {
    constraints: {
      pattern: /^[0-9]{2}-[0-9]{7}$/,
    },
    mask: [...digits(2), '-', ...digits(7)],
  },
  BsbNumber: {
    constraints: {
      pattern: /^\d{9}$/,
    },
    mask: digits(9),
  },
  PhoneNumber: {
    constraints: {
      pattern: /^\d{20}$/,
    },
    mask: ['+', ...digits(20)],
  },
};
