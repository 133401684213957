<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<ng-template>
  <mat-card *ngIf="displayMenu" class="gh-autocomplete-card" [ngStyle]="{
      'width.px': width,
      'padding-top.px': topPlaceholderHeight,
      'padding-bottom.px': bottomPlaceholderHeight,
      'marginTop.px': -topOffset,
      'marginBottom.px': -bottomOffset
    }" [class.m-placeholder-on-top]="isPlaceholderOnTop" [class.m-placeholder-on-bottom]="!isPlaceholderOnTop">
    <div class="placeholder-container" [style.height.px]="topPlaceholderHeight || bottomPlaceholderHeight">
      <div class="placeholder-top" [style.height.px]="placeholderTopPadding"></div>
      <div class="placeholder-left" [style.width.px]="placeholderLeftPadding"></div>
      <div class="placeholder-right" [style.width.px]="placeholderRightPadding"></div>
      <div class="placeholder-bottom" [style.height.px]="placeholderBottomPadding"></div>
    </div>
    <div class="m-menu-container" #optionContainer="" (ghInfiniteScroll)="onScroll()" [loadDistance]="nextBatchDistance" [style.height.px]="containerHeight">
      <mat-list class="gh-autocomplete-menu" [style.height.px]="listHeight">
        <ng-container *ngFor="let option of options; let i = index;">
          <mat-list-item *ngIf="option.group" class="gh-option-group" [title]="option.display" [class.focus]="focusedIndex === i">
            <div class="gh-text">{{ option.display }}</div>
          </mat-list-item>
          <mat-list-item *ngIf="!option.group" class="gh-option" tabindex="0" [title]="option.display" [class.focus]="focusedIndex === i" (click)="selectOption($event, i)" data-automation-id="autocomplete-item:{{i}}">
            <ng-container [ngSwitch]="hasOptionTemplate">
              <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="optionTemplate?.templateRef" [ngTemplateOutletContext]="{ $implicit: option.source, value: option.value }"></ng-container>
              <div *ngSwitchDefault="" class="gh-text" [innerHtml]="option.display | highlight:inputBuffer | sanitizeHtml"></div>
            </ng-container>
          </mat-list-item>
        </ng-container>
        <mat-list-item *ngIf="displayNoResults">
          <div class="gh-text-no-results">{{'lbl_no_results' | translate}}</div>...
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card>

  <div *ngIf="isLoadingMore" class="gh-autocomplete-progress-bar" [class.m-placeholder-on-top]="isPlaceholderOnTop" [class.m-placeholder-on-bottom]="!isPlaceholderOnTop" [ngStyle]="{
        'width.px': width,
        'padding-left.px': placeholderLeftPadding,
        'padding-right.px': placeholderRightPadding,
        'margin-bottom.px': progressBarBottomOffset
      }">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

</ng-template>
