/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { isNil } from 'lodash';
import * as i0 from "@angular/core";
/**
 * This pipe should be used for safe transforming of control to {@link FormGroup}
 */
var AsFormGroupPipe = /** @class */ (function () {
    function AsFormGroupPipe() {
    }
    AsFormGroupPipe.prototype.transform = function (control) {
        if (isNil(control)) {
            return control;
        }
        else if (control instanceof FormGroup) {
            return control;
        }
        else {
            throw new Error('Provided control is not a form group');
        }
    };
    AsFormGroupPipe.ɵfac = function AsFormGroupPipe_Factory(t) { return new (t || AsFormGroupPipe)(); };
    AsFormGroupPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "asFormGroup", type: AsFormGroupPipe, pure: false });
    return AsFormGroupPipe;
}());
export { AsFormGroupPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AsFormGroupPipe, [{
        type: Pipe,
        args: [{ name: 'asFormGroup', pure: false }]
    }], null, null); })();
/**
 * This pipe should be used for safe transforming of control to {@link FormArray}
 */
var AsFormArrayPipe = /** @class */ (function () {
    function AsFormArrayPipe() {
    }
    AsFormArrayPipe.prototype.transform = function (control) {
        if (isNil(control)) {
            return control;
        }
        else if (control instanceof FormArray) {
            return control;
        }
        else {
            throw new Error('Provided control is not a form array');
        }
    };
    AsFormArrayPipe.ɵfac = function AsFormArrayPipe_Factory(t) { return new (t || AsFormArrayPipe)(); };
    AsFormArrayPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "asFormArray", type: AsFormArrayPipe, pure: false });
    return AsFormArrayPipe;
}());
export { AsFormArrayPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AsFormArrayPipe, [{
        type: Pipe,
        args: [{ name: 'asFormArray', pure: false }]
    }], null, null); })();
