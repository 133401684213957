/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef, } from '@angular/core';
import * as i0 from "@angular/core";
var LocalDirective = /** @class */ (function () {
    function LocalDirective(viewContainerRef, changeDetectorRef, templateRef) {
        this.viewContainerRef = viewContainerRef;
        this.changeDetectorRef = changeDetectorRef;
        this.templateRef = templateRef;
        this.initialized = false;
    }
    Object.defineProperty(LocalDirective.prototype, "local", {
        set: function (value) {
            if (this.initialized) {
                this.viewRef.context.$implicit = value;
                this.changeDetectorRef.markForCheck();
            }
            else {
                this._local = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    LocalDirective.prototype.ngOnInit = function () {
        this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, { $implicit: this._local });
        this._local = void 0;
        this.initialized = true;
    };
    LocalDirective.ɵfac = function LocalDirective_Factory(t) { return new (t || LocalDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.TemplateRef)); };
    LocalDirective.ɵdir = i0.ɵɵdefineDirective({ type: LocalDirective, selectors: [["", "ghLocal", ""], ["", "ghLocalOf", ""]], inputs: { local: ["ghLocalOf", "local"] } });
    return LocalDirective;
}());
export { LocalDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LocalDirective, [{
        type: Directive,
        args: [{
                selector: '[ghLocal],[ghLocalOf]',
            }]
    }], function () { return [{ type: i0.ViewContainerRef }, { type: i0.ChangeDetectorRef }, { type: i0.TemplateRef }]; }, { local: [{
            type: Input,
            args: ['ghLocalOf']
        }] }); })();
