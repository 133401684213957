/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { UserData } from '@gh/common-shared-data';

import { Locale } from '@gh/core-mls';
import { distinctUntilChanged$, filter$ } from '@gh/rx/operators';
import { BehaviorSubject, Observable } from 'rxjs';

export enum UserLoginStatus {
  Unknown = 0,
  LoggedIn = 1,
  LoggedOut = 2,
}

@Injectable()
export abstract class CommonUserProfileStore<U extends UserData> {
  private status$$ = new BehaviorSubject<UserLoginStatus>(UserLoginStatus.Unknown);
  private user$$ = new BehaviorSubject<Maybe<U>>(void 0);

  get user(): Maybe<U> {
    return this.user$$.hasError ? void 0 : this.user$$.value;
  }

  get user$(): Observable<U> {
    // @ts-ignore
    return this.user$$.pipe(filter$(Boolean));
  }

  get status$(): Observable<UserLoginStatus> {
    return this.status$$.asObservable().pipe(distinctUntilChanged$());
  }

  get initialized(): boolean {
    return !this.user$$.hasError && Boolean(this.user$$.value);
  }

  abstract load(params: Hash<any>): void;

  abstract logout(): void;

  abstract updateLocale(locale: Locale): Observable<any>;

  protected setLoggedInUser(user: U): void {
    this.status$$.next(UserLoginStatus.LoggedIn);
    this.user$$.next(user);
  }

  protected setLoginError(error: any): void {
    this.user$$.error(error);
  }

  protected setLoggedOut(): void {
    this.status$$.next(UserLoginStatus.LoggedOut);
  }
}
