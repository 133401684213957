/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isEqual, isNil } from 'lodash';
export var INCHES_IN_FOOT = 12;
export var footToDecimal = function (foot) {
    return (foot.negative ? -1 : 1) * ((foot.ft || 0) + (foot.in || 0) / INCHES_IN_FOOT + foot.delta);
};
export var decimalToFoot = function (d) {
    var dAbsolute = Math.abs(d);
    var inWithFractionalPart = (dAbsolute - Math.trunc(dAbsolute)) * INCHES_IN_FOOT;
    var inches = Math.round(inWithFractionalPart);
    var isLastInch = inches === 12;
    var foot = {
        negative: d < 0,
        ft: isLastInch ? Math.floor(dAbsolute) + 1 : Math.floor(dAbsolute),
        in: isLastInch ? 0 : inches,
        delta: 0,
    };
    foot.delta = (inWithFractionalPart - inches) / INCHES_IN_FOOT;
    return foot;
};
export var truncateFoot = function (f) { return f.delta === 0 ? f : (__assign(__assign({}, f), { delta: 0 })); };
export var createQuantity = function (value, uomCode) { return ({ value: value, uomCode: uomCode }); };
export var createErrorQuantity = function (error) { return ({ error: error }); };
export var getQuantityUomCode = function (q) { return q.uomCode; };
export var isQuantityValid = function (q) { return isNil(q.error); };
export var getQuantityValue = function (q) { return q.value; };
export var isQuantityEquals = function (a, b) {
    var aNil = isNil(a);
    var bNil = isNil(b);
    if (aNil !== bNil || aNil) {
        return false;
    }
    var aValid = isQuantityValid(a);
    var bValid = isQuantityValid(b);
    if (aValid && aValid === bValid) {
        return isEqual(getQuantityValue(a), getQuantityValue(b));
    }
    return false;
};
// tslint:disable-next-line:max-line-length
export var createQuantityComparator = function (toNumberMap) {
    return function (a, b) {
        var aNil = isNil(a);
        var bNil = isNil(b);
        if (aNil !== bNil) {
            return aNil ? -1 : 1;
        }
        var aValid = isQuantityValid(a);
        var bValid = isQuantityValid(b);
        if (aValid !== bValid) {
            return aValid ? 1 : -1;
        }
        var aMapper = toNumberMap[a.uomCode] || getQuantityValue;
        var bMapper = toNumberMap[b.uomCode] || getQuantityValue;
        return Math.sign(aMapper(a) - bMapper(b));
    };
};
export var mapQuantity = function (q, map) { return map(q.value); };
