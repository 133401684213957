/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNotNil, logError } from '@gh/core-util';
import { from$ } from '@gh/rx';
import { isNil } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/**
 * This service manages registry of all loaded modules and its symbols.
 * Symbol is named chunk loaded from a module.
 *
 * Lazy loading is based on @angular/router functionality:
 * 1. When developer asks to lazy load some module using its path,
 * this service appends <code>/@lazy-activator</code> path element and tries to open result route
 * 2. <code>/@lazy-activator</code> route should have <code>CanActivate</code> service {@link LazyActivator}
 * that just adds symbols into {@link LazyRegistry}.
 * 3. Symbols exported by lazy module should be defined under {@link LAZY_SYMBOLS} token in the loaded module.
 */
var LazyRegistry = /** @class */ (function () {
    function LazyRegistry(router) {
        this.router = router;
        this.modules = {};
    }
    /**
     * Answers if module having the given path was loaded
     */
    LazyRegistry.prototype.isModuleLoaded = function (modulePath) {
        return isNotNil(this.modules[modulePath]);
    };
    /**
     * Adds module along with its injector and symbols into the set of loaded modules.
     */
    LazyRegistry.prototype.add = function (path, injector, symbols) {
        this.modules[path] = { injector: injector, symbols: symbols };
    };
    /**
     * Tries to load module. If module was loaded successfully takes separate symbol from it.
     * Symbol is always retrieved with injector.
     */
    LazyRegistry.prototype.load = function (modulePath, name) {
        var _this = this;
        return from$(this.router.navigate([modulePath + "/@lazy-activator"])
            .then(function () {
            var module = _this.modules[modulePath];
            if (isNil(module)) {
                throw new Error("LazyRegistry: cannot find module by path '" + modulePath + "'");
            }
            var symbol = module.symbols[name];
            if (isNil(symbol)) {
                throw new Error("LazyRegistry: cannot find symbol '" + modulePath + ":" + name + "'");
            }
            return { injector: module.injector, symbol: symbol };
        })
            .catch(function (error) {
            logError("LazyRegistry: error while loading module '" + modulePath + "'", error);
            return Promise.reject(error);
        }));
    };
    LazyRegistry.ɵfac = function LazyRegistry_Factory(t) { return new (t || LazyRegistry)(i0.ɵɵinject(i1.Router)); };
    LazyRegistry.ɵprov = i0.ɵɵdefineInjectable({ token: LazyRegistry, factory: LazyRegistry.ɵfac });
    return LazyRegistry;
}());
export { LazyRegistry };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LazyRegistry, [{
        type: Injectable
    }], function () { return [{ type: i1.Router }]; }, null); })();
