/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';

import { CommonUserContextService, ConfigurationHttpRequestInterceptor } from '@gh/common-shared';
import { ID, UserData } from '@gh/common-shared-data';
import { REGION_CODE } from '@gh/config';
import { AuthService, Permission } from '@gh/core-auth';
import { isNotNil } from '@gh/core-util';
import { CustomerJobRestService, CustomerUserProfileRestService } from '@gh/customer-shared-data';
import { map$, mapTo$, switchMap$, tap$ } from '@gh/rx/operators';

import { find, isNil, sortBy } from 'lodash';
import { Observable } from 'rxjs';

import { CustomerUserContext, CustomerUserData, JobInfo } from './customer-user-context';
import { CustomerUserProfileStore } from './customer-user-profile-store.service';

@Injectable()
export class CustomerUserContextService extends CommonUserContextService<CustomerUserData> implements CustomerUserContext {
  private _jobs: JobInfo[] = [];
  private _currentJob: JobInfo;

  set jobs(jobs: JobInfo[]) {
    throw new Error('CustomerUserContextService: list of jobs cannot be changed');
  }

  get jobs(): JobInfo[] {
    this.checkUserInitialized();
    return this._jobs;
  }

  set currentJob(job: JobInfo) {
    throw new Error(`CustomerUserContextService: to change job use setCurrentJob method`);
  }

  get currentJob(): JobInfo {
    this.checkUserInitialized();
    return this._currentJob;
  }

  constructor(authService: AuthService,
              userProfileStore: CustomerUserProfileStore,
              private profileRest: CustomerUserProfileRestService,
              private jobRest: CustomerJobRestService,
              private configurationHttpRequestInterceptor: ConfigurationHttpRequestInterceptor) {
    super(authService, userProfileStore);
  }

  init(params: Hash<any>): Observable<UserData> {
    const { acceptedOfficeId } = params;
    return super.init(params).pipe(
      switchMap$((userData) => acceptedOfficeId
        ? this.profileRest.acceptInvitation(acceptedOfficeId).pipe(mapTo$(userData))
        : [userData]),
      switchMap$((userData) =>
        this.jobRest.findAll().pipe(
          map$((jobs) => sortBy(jobs, 'jobNumber')),
          tap$((jobs) => {
            this._jobs = jobs;
            if (jobs.length) {
              this.setCurrentJob(jobs[0].jobId);
            }
          }),
          mapTo$(userData))));
  }

  setCurrentJob(jobId: ID): void {
    if (this._currentJob && this._currentJob.jobId === jobId) {
      return;
    }

    const nextJob = find(this._jobs, { jobId });
    if (isNil(nextJob)) {
      throw new Error(`CustomerUserContextService: id "${jobId}" does not correspond to any of loaded jobs`);
    }

    this._currentJob = nextJob;
    this.configurationHttpRequestInterceptor.setHeader('X-OfficeId', nextJob.officeId);
  }

  isJobExist(jobId: ID): boolean {
    return isNotNil(find(this._jobs, { jobId }));
  }

  hasPermission(permission: Permission): boolean {
    throw new Error('CustomerUserContext: permissions are not supported');
  }

  protected updateUserData(user: Maybe<CustomerUserData>): void {
    // nothing to do
  }

  protected setupHttpHeaders(params: Hash<any>): void {
    this.configurationHttpRequestInterceptor.headers = {
      'X-Region': REGION_CODE,
    };
  }
}
