/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'log',
})
export class LogPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (args.length) {
      console.log(...args, value); // tslint:disable-line:no-console
    } else {
      console.log(value); // tslint:disable-line:no-console
    }
    return value;
  }
}
