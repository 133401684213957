/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationParams, TranslationTuple } from './translation.interfaces';
import { TranslationService } from './translation.service';

@Pipe({
  name: 'translate',
  pure: true,
})
export class TranslatePipe implements PipeTransform {
  constructor(private translation: TranslationService) {

  }

  transform(keyOrTuple: any | TranslationTuple, params?: TranslationParams): any {
    return this.translation.get(keyOrTuple, params);
  }
}
