/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import * as i0 from "@angular/core";
import * as i1 from "@angular/flex-layout";
var ExtIfQueryDirective = /** @class */ (function () {
    function ExtIfQueryDirective(observer, viewContainerRef, template) {
        this.observer = observer;
        this.viewContainerRef = viewContainerRef;
        this.template = template;
        this.rendered = false;
    }
    Object.defineProperty(ExtIfQueryDirective.prototype, "breakpoint", {
        get: function () {
            return this._breakpoint;
        },
        set: function (breakpoint) {
            this._breakpoint = breakpoint;
            this.checkView();
        },
        enumerable: true,
        configurable: true
    });
    ExtIfQueryDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.checkView();
        this.mediaSubscription = this.observer.media$.subscribe(function () { return _this.checkView(); });
    };
    ExtIfQueryDirective.prototype.ngOnDestroy = function () {
        if (this.mediaSubscription) {
            this.mediaSubscription.unsubscribe();
        }
    };
    ExtIfQueryDirective.prototype.checkView = function () {
        var shouldBeRendered = this.observer.isActive(this.breakpoint);
        if (this.rendered === shouldBeRendered) {
            return;
        }
        if (this.rendered) {
            this.viewContainerRef.clear();
        }
        if (shouldBeRendered) {
            this.viewContainerRef.createEmbeddedView(this.template);
        }
        this.rendered = shouldBeRendered;
    };
    ExtIfQueryDirective.ɵfac = function ExtIfQueryDirective_Factory(t) { return new (t || ExtIfQueryDirective)(i0.ɵɵdirectiveInject(i1.MediaObserver), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef)); };
    ExtIfQueryDirective.ɵdir = i0.ɵɵdefineDirective({ type: ExtIfQueryDirective, selectors: [["", "ghExtIfQuery", ""]], inputs: { breakpoint: ["ghExtIfQuery", "breakpoint"] } });
    return ExtIfQueryDirective;
}());
export { ExtIfQueryDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtIfQueryDirective, [{
        type: Directive,
        args: [{
                selector: '[ghExtIfQuery]',
            }]
    }], function () { return [{ type: i1.MediaObserver }, { type: i0.ViewContainerRef }, { type: i0.TemplateRef }]; }, { breakpoint: [{
            type: Input,
            args: ['ghExtIfQuery']
        }] }); })();
