/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isEmpty } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/button";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../core-mls/translation/translate.pipe";
var ClearComponent = /** @class */ (function () {
    function ClearComponent() {
        this.sideEffect = true;
        this.clearable = true;
        this.clear = new EventEmitter();
    }
    Object.defineProperty(ClearComponent.prototype, "canClear", {
        get: function () {
            return this.clearable && (!this.control || !isEmpty(this.control.value));
        },
        enumerable: true,
        configurable: true
    });
    ClearComponent.prototype.onClick = function () {
        if (this.control && this.sideEffect) {
            this.control.setValue(void 0);
            this.control.markAsDirty();
        }
        this.clear.emit();
    };
    ClearComponent.ɵfac = function ClearComponent_Factory(t) { return new (t || ClearComponent)(); };
    ClearComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ClearComponent, selectors: [["gh-clear"]], inputs: { control: "control", sideEffect: "sideEffect", clearable: "clearable" }, outputs: { clear: "clear" }, decls: 3, vars: 4, consts: [["mat-icon-button", "", "type", "button", 3, "matTooltip", "hidden", "click"], ["svgIcon", "gh:close", 1, "s16"]], template: function ClearComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "button", 0);
            i0.ɵɵlistener("click", function ClearComponent_Template_button_click_0_listener() { return ctx.onClick(); });
            i0.ɵɵpipe(1, "translate");
            i0.ɵɵelement(2, "mat-icon", 1);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵpropertyInterpolate("matTooltip", i0.ɵɵpipeBind1(1, 2, "lbl_clear"));
            i0.ɵɵproperty("hidden", !ctx.canClear || (ctx.control == null ? null : ctx.control.disabled));
        } }, directives: [i1.MatButton, i2.MatTooltip, i3.MatIcon], pipes: [i4.TranslatePipe], styles: [".mat-input-suffix[_nghost-%COMP%], .mat-input-suffix   [_nghost-%COMP%] {\n  display: inline-block;\n  width: 16px; }\n  .mat-input-suffix[_nghost-%COMP%]   .mat-icon-button[_ngcontent-%COMP%], .mat-input-suffix   [_nghost-%COMP%]   .mat-icon-button[_ngcontent-%COMP%] {\n    width: 16px;\n    height: 16px;\n    line-height: 16px; }\n    .mat-input-suffix[_nghost-%COMP%]   .mat-icon-button[_ngcontent-%COMP%]   .mat-icon[_ngcontent-%COMP%], .mat-input-suffix   [_nghost-%COMP%]   .mat-icon-button[_ngcontent-%COMP%]   .mat-icon[_ngcontent-%COMP%] {\n      vertical-align: middle; }"] });
    return ClearComponent;
}());
export { ClearComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ClearComponent, [{
        type: Component,
        args: [{
                selector: 'gh-clear',
                templateUrl: 'clear.component.html',
                styleUrls: ['clear.component.scss'],
            }]
    }], null, { control: [{
            type: Input
        }], sideEffect: [{
            type: Input
        }], clearable: [{
            type: Input
        }], clear: [{
            type: Output
        }] }); })();
