/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { logError } from '@gh/core-util';
import { filter$, switchMap$, tap$ } from '@gh/rx/operators';
import { BehaviorSubject } from 'rxjs';
/**
 * This class serves as wrapper around {@link MatDialogRef}.
 * It is necessary to provide additional capabilities for dialog: like resolving of initial data.
 * Instance of this class takes care about lifecycle of MatDialog.
 */
var DialogRef = /** @class */ (function () {
    function DialogRef(nativeRef$) {
        var _this = this;
        this.nativeRef$$ = new BehaviorSubject(void 0);
        this.subscription = nativeRef$.pipe(tap$({
            next: function (ref) { return _this.nativeRef$$.next(ref); },
            error: function (error) { return _this.nativeRef$$.error(error); },
        }), switchMap$(function (dialogRef) { return dialogRef.afterClosed(); }))
            .subscribe(function () {
            _this.nativeRef$$.complete();
        }, function (error) {
            logError(error);
            _this.nativeRef$$.error(error);
        });
    }
    Object.defineProperty(DialogRef.prototype, "componentInstance", {
        get: function () {
            var nativeRef = this.nativeRef$$.value;
            return nativeRef ? nativeRef.componentInstance : void 0;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Closes the dialog.
     *
     * @param dialogResult Optional result to return to the dialog opener.
     */
    DialogRef.prototype.close = function (dialogResult) {
        if (this.nativeRef$$.value) {
            this.nativeRef$$.value.close(dialogResult);
        }
        this.subscription.unsubscribe();
        this.nativeRef$$.complete();
    };
    /**
     * Gets an observable that is notified when the dialog is finished closing.
     */
    DialogRef.prototype.afterOpened = function () {
        return this.nativeRef$$.pipe(filter$(Boolean), switchMap$(function (nativeRef) { return nativeRef.afterOpened(); }));
    };
    /**
     * Gets an observable that is notified when the dialog is finished closing.
     */
    DialogRef.prototype.afterClosed = function () {
        return this.nativeRef$$.pipe(filter$(Boolean), switchMap$(function (nativeRef) { return nativeRef.afterClosed(); }));
    };
    return DialogRef;
}());
export { DialogRef };
