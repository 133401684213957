/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, Input } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { eq, get } from 'lodash';

@Directive({
  selector: 'mat-select[ghCompareBy]',
})
export class CompareByDirective {
  constructor(public select: MatSelect) {

  }

  @Input('ghCompareBy')
  set property(field: string) {
    this.select.compareWith = (a: any, b: any) => eq(get(a, field), get(b, field));
  }
}
