/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Inject, Injectable, InjectionToken } from '@angular/core';

import { LocalizationService } from '@gh/core-mls';

import { each, uniqueId } from 'lodash';

import { Type } from '../core';

export const TYPE_SET = new InjectionToken<Hash<Type<any>>>('TypeSet');

@Injectable()
export class TypeService {
  private registry: Hash<Type<any>> = {};

  constructor(@Inject(TYPE_SET) typeSets: Hash<Type<any>>[],
              private localizationService: LocalizationService) {
    typeSets.forEach((set) => {
      each(set, (type, name: string) => {
        if (this.registry[name]) {
          throw new Error(`Type with name ${name} is already registered`);
        }

        this.registry[name] = type;
        (<any>type).init(name, localizationService);
      });
    });
  }

  create<T extends Type<any>>(type: T): T {
    (<any>type).init(uniqueId('DynamicType:'), this.localizationService);

    return type;
  }

  get(name: string): Type<any> {
    return this.registry[name];
  }
}
