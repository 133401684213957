/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { RestSettings } from './http/rest-settings.service';
import { MOCK_DATA, MockDataRegistry, MockDataService } from './mock';
import { RouteDataService } from './route/route-data.service';
import { RouteDataStore } from './route/route-store.service';

@NgModule({})
export class CoreDataModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreDataModule,
      providers: [RestSettings, RouteDataService, RouteDataStore],
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: CoreDataModule,
      providers: [],
    };
  }
}

@NgModule({
  imports: [
    HttpClientInMemoryWebApiModule.forRoot(MockDataService, {
      delay: 1000,
      passThruUnknownUrl: true,
    }),
  ],
})
export class CoreMockDataModule {
  static withMockData(registry: MockDataRegistry): ModuleWithProviders {
    return {
      ngModule: CoreMockDataModule,
      providers: [{ provide: MOCK_DATA, useValue: registry }],
    };
  }
}
