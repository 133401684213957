/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicAuthHttpRequestInterceptor } from '@gh/core-auth';
import { from$, throwError$ } from '@gh/rx';
import { switchMap$ } from '@gh/rx/operators';

import { Observable } from 'rxjs';

import { KeycloakService } from './keycloak.service';

const reqWithTokenHeader = (req: HttpRequest<any>, token: string) =>
  req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });

@Injectable()
export class KeycloakAuthHttpRequestInterceptor extends BasicAuthHttpRequestInterceptor {
  constructor(private keycloak: KeycloakService) {
    super();
  }

  protected interceptWithoutRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.keycloak.isTokenExpired()) {
      return throwError$(new HttpResponse({
        url: req.url,
        status: 401,
        statusText: 'Unauthorized',
      }));
    } else {
      return next.handle(reqWithTokenHeader(req, this.keycloak.token));
    }
  }

  protected interceptWithRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from$(this.keycloak.getToken()).pipe(switchMap$((token) => next.handle(reqWithTokenHeader(req, token))));
  }
}
