/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { WaitDirective } from './wait.directive';
import { WaitingContext } from './waiting-context.service';
import { WaitingScopeDirective } from './waiting-scope.directive';
import { WaitingService } from './waiting.service';
import * as i0 from "@angular/core";
var CoreUiWaitingModule = /** @class */ (function () {
    function CoreUiWaitingModule() {
    }
    CoreUiWaitingModule.forRoot = function () {
        return {
            ngModule: CoreUiWaitingModule,
            providers: [
                WaitingService,
                WaitingContext,
            ],
        };
    };
    CoreUiWaitingModule.forChild = function () {
        return {
            ngModule: CoreUiWaitingModule,
        };
    };
    CoreUiWaitingModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreUiWaitingModule });
    CoreUiWaitingModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreUiWaitingModule_Factory(t) { return new (t || CoreUiWaitingModule)(); } });
    return CoreUiWaitingModule;
}());
export { CoreUiWaitingModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreUiWaitingModule, { declarations: [WaitingScopeDirective,
        WaitDirective], exports: [WaitingScopeDirective,
        WaitDirective] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreUiWaitingModule, [{
        type: NgModule,
        args: [{
                declarations: [
                    WaitingScopeDirective,
                    WaitDirective,
                ],
                exports: [
                    WaitingScopeDirective,
                    WaitDirective,
                ],
            }]
    }], null, null); })();
