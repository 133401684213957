/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { assign, forEach, omit } from 'lodash';
import { DataMapper } from './data-mapper';
import { HeadersData, HttpDataMapper } from './http-data-mapper';

export type PrivilegedToken = string;

export interface PrivilegedTokenSupport {
  privilegedTokens?: Hash<PrivilegedToken>;
}

export type WithPrivilegedToken<T> = T & { privilegedTokens?: Hash<PrivilegedToken> };

export function createPrivilegedTokenEntity(entryPoint: string, token: PrivilegedToken): PrivilegedTokenSupport {
  return { privilegedTokens: { [entryPoint]: token } };
}

export function mergeWithPrivelegedTokens(...entities: WithPrivilegedToken<any>[]): Hash<PrivilegedToken> {
  return assign({}, ...entities.map((entity: WithPrivilegedToken<any>) => entity.privilegedTokens));
}

export class PrivilegedTokenHttpDataMapper<ObjectView extends PrivilegedTokenSupport>
  extends HttpDataMapper<ObjectView> {

  constructor(mapper: DataMapper<ObjectView, any>) {
    super(mapper);
  }

  serializeHeaders(headers: HeadersData, obj: ObjectView, json: any): any {
    if (obj.privilegedTokens) {
      forEach(obj.privilegedTokens, (token, key) => headers.set(`X-PrivilegedToken-${key}`, token));
    }
    return omit(json, 'privilegedTokens');
  }

  deserializeHeaders(headers: HeadersData, obj: ObjectView): ObjectView {
    obj.privilegedTokens = void 0;

    return obj;
  }
}
