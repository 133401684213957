/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { createErrorQuantity, createQuantity, getQuantityValue, isQuantityValid } from '@gh/core-data';
import { isNotNil, nullate } from '@gh/core-util';
import { find, fromPairs, isNil } from 'lodash';
import { EMPTY_DECOMPOSED_QUANTITY, QuantityTypeBase, } from '../type.interfaces';
import { AbstractPrimitiveType, DecoratedType } from './abstract-type';
import { NumberTypeImpl } from './number-type';
var QuantityNumberTypeImpl = /** @class */ (function (_super) {
    __extends(QuantityNumberTypeImpl, _super);
    function QuantityNumberTypeImpl(quantityDefenition) {
        var _this = _super.call(this, new NumberTypeImpl(quantityDefenition)) || this;
        _this.quantityDefenition = quantityDefenition;
        _this.base = QuantityTypeBase.Number;
        _this.validator = function (control) { return nullate(_this.validate(control.value)); };
        return _this;
    }
    QuantityNumberTypeImpl.prototype.parse = function (str) {
        return createQuantity(this.baseType.parse(str), this.name);
    };
    QuantityNumberTypeImpl.prototype.format = function (q, _a) {
        var noLabels = (_a === void 0 ? { noLabels: true } : _a).noLabels;
        if (isNil(q)) {
            return '';
        }
        var formattedNumber = this.baseType.format(getQuantityValue(q));
        if (noLabels || isNil(this.quantityDefenition.label)) {
            return formattedNumber;
        }
        else {
            return "" + formattedNumber + this.quantityDefenition.label;
        }
    };
    QuantityNumberTypeImpl.prototype.validate = function (q) {
        return isNotNil(q) ? this.baseType.validate(getQuantityValue(q)) : void 0;
    };
    return QuantityNumberTypeImpl;
}(DecoratedType));
export { QuantityNumberTypeImpl };
var QuantityPatternTypeImpl = /** @class */ (function (_super) {
    __extends(QuantityPatternTypeImpl, _super);
    function QuantityPatternTypeImpl(quantityDefenition) {
        var _this = _super.call(this, quantityDefenition, {}) || this;
        _this.quantityDefenition = quantityDefenition;
        _this.base = QuantityTypeBase.Pattern;
        return _this;
    }
    QuantityPatternTypeImpl.prototype.parse = function (str) {
        var result = this.preParse(str);
        return result.success
            ? this.quantityDefenition.mappings.parse(result)
            : createErrorQuantity(str);
    };
    QuantityPatternTypeImpl.prototype.format = function (value, query) {
        var noLabels = query && query.noLabels || false;
        if (isQuantityValid(value)) {
            var _a = this.quantityDefenition.mappings.format(value) || EMPTY_DECOMPOSED_QUANTITY, negative = _a.negative, elements_1 = _a.elements;
            var notZeroElements = this.formatElements.filter(function (_a) {
                var name = _a.name;
                return elements_1[name];
            });
            // if all values are zero => take only the first 0 element
            var positivePart = (notZeroElements.length === 0 ? [this.formatElements[0]] : notZeroElements)
                .map(function (_a) {
                var name = _a.name, label = _a.label, type = _a.type;
                return "" + type.format(elements_1[name]) + (noLabels ? '' : label);
            })
                .join(' ');
            return negative && notZeroElements.length > 0 ? "-" + positivePart : positivePart;
        }
        else {
            return value.error;
        }
    };
    QuantityPatternTypeImpl.prototype.validateFormat = function (str) {
        var result = this.preParse(str);
        return result.success
            ? void 0
            : { format: { messageId: 'msg_field_invalid_format' } };
    };
    QuantityPatternTypeImpl.prototype.validate = function (value) {
        if (isNotNil(value)) {
            return isQuantityValid(value) ? _super.prototype.validate.call(this, value) : { format: { messageId: 'msg_field_invalid_format' } };
        }
    };
    QuantityPatternTypeImpl.prototype.init = function (name, localizationProvider) {
        var _this = this;
        _super.prototype.init.call(this, name, localizationProvider);
        var format = localizationProvider.getQuantityLocalization().formats[this.quantityDefenition.format];
        if (isNil(format)) {
            throw new Error("QuantityPatternType: format '" + this.quantityDefenition.format + "' could not be found");
        }
        this.formatElements = format.map(function (element) { return ({
            name: element.name,
            label: element.label,
            type: _this.getElementType(element.name),
        }); });
        this.formatElements.forEach(function (element) { return element.type.init(name, localizationProvider); });
    };
    QuantityPatternTypeImpl.prototype.preParse = function (str) {
        var _this = this;
        // split string on raw values (unparsed)
        var trimmedStr = str.trim();
        var isNegative = trimmedStr.startsWith('-');
        var numberPartStr = isNegative ? trimmedStr.substring(1).trim() : str.trim();
        var rawValues = numberPartStr.split(/\s+/);
        if (rawValues.length > this.formatElements.length) {
            return { success: false, elements: {}, negative: false };
        }
        // validate raw values according to the format of given type
        var rawValueWithElements = rawValues.map(function (part, i) {
            var element = find(_this.formatElements, function (_a) {
                var label = _a.label;
                return part.endsWith(label);
            });
            if (element) {
                return { element: element, rawValue: part.substring(0, part.length - element.label.length) };
            }
            else {
                return { element: _this.formatElements[i], rawValue: part };
            }
        });
        var isFormatValid = rawValueWithElements
            .every(function (_a) {
            var element = _a.element, rawValue = _a.rawValue;
            return isNil(element.type.validateFormat(rawValue));
        });
        if (!isFormatValid) {
            return { success: false, elements: {}, negative: false };
        }
        // validate parsed values
        var valueWithElements = rawValueWithElements
            .map(function (_a) {
            var element = _a.element, rawValue = _a.rawValue;
            return ({ element: element, value: element.type.parse(rawValue) });
        });
        var isValid = valueWithElements.every(function (_a) {
            var element = _a.element, value = _a.value;
            return isNil(element.type.validate(value));
        });
        if (!isValid) {
            return { success: false, elements: {}, negative: false };
        }
        return {
            success: true,
            negative: isNegative,
            elements: fromPairs(rawValueWithElements.map(function (_a) {
                var rawValue = _a.rawValue, element = _a.element;
                return [
                    element.name,
                    element.type.parse(rawValue),
                ];
            })),
        };
    };
    QuantityPatternTypeImpl.prototype.getElementType = function (name) {
        var type = this.quantityDefenition.types[name];
        if (isNil(type)) {
            throw new Error("QuantityType: element type '" + name + "' is not defined");
        }
        return type;
    };
    return QuantityPatternTypeImpl;
}(AbstractPrimitiveType));
export { QuantityPatternTypeImpl };
