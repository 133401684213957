/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, TemplateRef } from '@angular/core';
import * as i0 from "@angular/core";
/**
 * This directive allows to specify template for autocomplete option displayed in the overlay.
 *
 * For example,
 * <code>
 *   <gh-autocomplete
 *     ...
 *     optionsTextKey="name"
 *     (loadMore)="loadOptions($event)">
 *     <ng-template ghAutocompleteOption let-option>
 *       <span [class.text-bold]="option.isPreferred">{{ option.name }}</span>
 *     </ng-template>
 *   </gh-autocomplete>
 * </code>
 */
var AutocompleteOptionDirective = /** @class */ (function () {
    function AutocompleteOptionDirective(templateRef) {
        this.templateRef = templateRef;
    }
    AutocompleteOptionDirective.ɵfac = function AutocompleteOptionDirective_Factory(t) { return new (t || AutocompleteOptionDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef)); };
    AutocompleteOptionDirective.ɵdir = i0.ɵɵdefineDirective({ type: AutocompleteOptionDirective, selectors: [["", "ghAutocompleteOption", ""]] });
    return AutocompleteOptionDirective;
}());
export { AutocompleteOptionDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AutocompleteOptionDirective, [{
        type: Directive,
        args: [{
                selector: '[ghAutocompleteOption]',
            }]
    }], function () { return [{ type: i0.TemplateRef }]; }, null); })();
