<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<div class="info-box">
  <div class="info-box__name ext-placeholder-color">{{ job.addressee }}</div>
  <div class="info-box__details">{{ job.siteAddress }}</div>
  <div *ngIf="job.contractPrice >= 0" class="info-box__field">
    <div class="info-box__field-label ext-placeholder-color">{{'lbl_contract_price' | translate}}</div>
    <div class="info-box__field-value">{{ job.contractPrice | typeFormat:'Price' | defaultCurrency }}</div>
  </div>
  <div *ngIf="job.jobAdminName" class="info-box__field">
    <div class="info-box__field-label ext-placeholder-color">{{'lbl_job_admin' | translate}}</div>
    <div class="info-box__field-value">{{ job.jobAdminName }}</div>
  </div>
  <div *ngIf="job.supervisorName" class="info-box__field">
    <div class="info-box__field-label ext-placeholder-color">{{'lbl_supervisor' | translate}}</div>
    <div class="info-box__field-value">{{ job.supervisorName }}</div>
  </div>
  <div *ngIf="job.salesPersonName" class="info-box__field">
    <div class="info-box__field-label ext-placeholder-color">{{'lbl_salesperson' | translate}}</div>
    <div class="info-box__field-value">{{ job.salesPersonName }}</div>
  </div>
</div>
