var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { aspectAround } from '@gh/core-util';
import { of$ } from '@gh/rx';
import { takeUntil$ } from '@gh/rx/operators';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { ExtLoadTrackerComponent } from '../../components';
import { EXT_INFINITE_SCROLL_DIMENSIONS, EXT_INFINITE_SCROLL_VIEWPORT, сollectionViewerWithSideEffect, } from '../ext-infinite-scroll.interfaces';
import { getQueryItemIndex, toQueryResult } from '../infinite-mat-data-source';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/table";
function getTableUnknownDataSourceError() {
    return Error("Provided data source did not match an array, Observable, or DataSource");
}
var ExtInfiniteTableDirective = /** @class */ (function () {
    function ExtInfiniteTableDirective(elementRef, table, viewport, dimensions) {
        this.elementRef = elementRef;
        this.table = table;
        this.viewport = viewport;
        this.dimensions = dimensions;
        var self = this;
        aspectAround(this.table, '_switchDataSource', function (original) { return function (dataSource) {
            original.apply(this, [dataSource]);
            self._switchDataSource(dataSource);
        }; });
        this.table['_observeRenderChanges'] = this._observeRenderChanges.bind(this);
        aspectAround(this.table, '_getRenderRowsForData', function (original) { return function (data, dataIndex) {
            var rest = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                rest[_i - 2] = arguments[_i];
            }
            return original.apply(this, __spreadArrays([data, dataIndex], rest))
                .map(function (row) { return (__assign(__assign({}, row), { dataIndex: getQueryItemIndex(row.data) })); });
        }; });
    }
    ExtInfiniteTableDirective.prototype.ngOnInit = function () {
        if (this.tracker) {
            this.tracker.reset();
        }
        this.updateHeight();
    };
    ExtInfiniteTableDirective.prototype._switchDataSource = function (_) {
        this.viewport.scrollToTop();
    };
    /** Set up a subscription for the data provided by the data source. */
    ExtInfiniteTableDirective.prototype._observeRenderChanges = function () {
        var _this = this;
        // If no data source has been set, there is nothing to observe for changes.
        if (!this.table.dataSource) {
            return;
        }
        var dataStream;
        // Check if the datasource is a DataSource object by observing if it has a connect function.
        // Cannot check this.dataSource['connect'] due to potential property renaming, nor can it
        // checked as an instanceof DataSource<T> since the table should allow for data sources
        // that did not explicitly extend DataSource<T>.
        if (this.table.dataSource.connect instanceof Function) {
            dataStream = this.table.dataSource.connect(сollectionViewerWithSideEffect(this.viewport, function () { return _this.tracker && _this.tracker.start(); }));
        }
        else if (this.table.dataSource instanceof Observable) {
            dataStream = this.table.dataSource;
        }
        else if (Array.isArray(this.table.dataSource)) {
            dataStream = of$(this.table.dataSource);
        }
        if (dataStream === undefined) {
            throw getTableUnknownDataSourceError();
        }
        this.table['_renderChangeSubscription'] = dataStream.pipe(takeUntil$(this.table['_onDestroy']))
            .subscribe(function (data) {
            var _a = toQueryResult(data).query, from = _a.from, to = _a.to;
            _this.table['_data'] = data || [];
            _this.table.renderRows();
            _this.viewport.updateRowCount(to - from, (data || []).length, from);
            _this.updateHeight();
            _this.tracker && _this.tracker.stop(isEmpty(data));
        });
    };
    ExtInfiniteTableDirective.prototype.updateHeight = function () {
        if (this.viewport.rowCount >= 0) {
            this.elementRef.nativeElement.style.height = this.viewport.rowCount * this.dimensions.itemHeight + "px";
        }
    };
    ExtInfiniteTableDirective.ɵfac = function ExtInfiniteTableDirective_Factory(t) { return new (t || ExtInfiniteTableDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.MatTable), i0.ɵɵdirectiveInject(EXT_INFINITE_SCROLL_VIEWPORT), i0.ɵɵdirectiveInject(EXT_INFINITE_SCROLL_DIMENSIONS)); };
    ExtInfiniteTableDirective.ɵdir = i0.ɵɵdefineDirective({ type: ExtInfiniteTableDirective, selectors: [["", "ghExtInfiniteTable", ""]], hostAttrs: [1, "ext-infinite-table"], inputs: { tracker: "tracker" }, exportAs: ["ghExtInfiniteTable"] });
    return ExtInfiniteTableDirective;
}());
export { ExtInfiniteTableDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtInfiniteTableDirective, [{
        type: Directive,
        args: [{
                selector: '[ghExtInfiniteTable]',
                // tslint:disable-next-line:use-host-property-decorator
                host: {
                    'class': 'ext-infinite-table',
                },
                exportAs: 'ghExtInfiniteTable',
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i1.MatTable }, { type: undefined, decorators: [{
                type: Inject,
                args: [EXT_INFINITE_SCROLL_VIEWPORT]
            }] }, { type: undefined, decorators: [{
                type: Inject,
                args: [EXT_INFINITE_SCROLL_DIMENSIONS]
            }] }]; }, { tracker: [{
            type: Input
        }] }); })();
