/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, DoCheck, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isBoolean, isNil } from 'lodash';

@Directive({
  selector: '[ghIfControlVisible]',
})
export class IfControlVisibleDirective implements DoCheck {
  private _control?: AbstractControl;
  private hasView = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<Object>,
  ) {
  }

  @Input('ghIfControlVisible')
  set control(control: AbstractControl) {
    this._control = control;
  }

  ngDoCheck(): void {
    const visible = this.isVisible();

    if (visible && !this.hasView) {
      this.hasView = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (!visible && this.hasView) {
      this.hasView = false;
      this.viewContainerRef.clear();
    }
  }

  private isVisible(): boolean {
    if (isNil(this._control)) {
      return false;
    }

    const visible = this._control['visible'];

    return isBoolean(visible) ? visible : true;
  }
}
