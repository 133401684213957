/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import * as i0 from "@angular/core";
//  tslint:disable-next-line:max-line-length
var URL_RE = '(?:(?:ht|f)tp(?:s?)\\:\\/\\/|~\\/|\\/)?(?:\\w+:\\w+@)?((?:(?:[-\\w\\d{1-3}]+\\.)+(?:com|org|net|gov|mil|ru|by|biz|info|mobi|name|aero|jobs|edu|co\\.uk|ac\\.uk|it|fr|tv|museum|asia|local|travel))|((\\b25[0-5]\\b|\\b[2][0-4][0-9]\\b|\\b[0-1]?[0-9]?[0-9]\\b)(\\.(\\b25[0-5]\\b|\\b[2][0-4][0-9]\\b|\\b[0-1]?[0-9]?[0-9]\\b)){3}))(?::[\\d]{1,5})?(?:(?:(?:\\/(?:[-\\w~!$+|.,=]|%[a-f\\d]{2})+)+|\\/)+|\\?|#)?(?:(?:\\?(?:[-\\w~!$+|.,*:]|%[a-f\\d{2}])+=?(?:[-\\w~!$+|.,*:=]|%[a-f\\d]{2})*)(?:&(?:[-\\w~!$+|.,*:]|%[a-f\\d{2}])+=?(?:[-\\w~!$+|.,*:=]|%[a-f\\d]{2})*)*)*(?:#(?:[-\\w~!$ |\\/.,*:;=]|%[a-f\\d]{2})*)?';
var ANCHOR_RE = '(<a[^>]*>.*</a>)';
var HTML_URL_RE = new RegExp(ANCHOR_RE + "|" + URL_RE, 'ig');
var HtmlWithUrlsPipe = /** @class */ (function () {
    function HtmlWithUrlsPipe() {
    }
    HtmlWithUrlsPipe.prototype.transform = function (value) {
        return value && value.replace(HTML_URL_RE, function (match, anchorTag) {
            return anchorTag ? match : "<a target=\"_blank\" href=\"" + match + "\">" + match + "</a>";
        });
    };
    HtmlWithUrlsPipe.ɵfac = function HtmlWithUrlsPipe_Factory(t) { return new (t || HtmlWithUrlsPipe)(); };
    HtmlWithUrlsPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "htmlWithUrls", type: HtmlWithUrlsPipe, pure: true });
    return HtmlWithUrlsPipe;
}());
export { HtmlWithUrlsPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(HtmlWithUrlsPipe, [{
        type: Pipe,
        args: [{ name: 'htmlWithUrls' }]
    }], null, null); })();
