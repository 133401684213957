var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge$ } from '@gh/rx';
import { map$, tap$ } from '@gh/rx/operators';
import { noop } from 'lodash';
import { Observable } from 'rxjs';
import { clearPendings, getPendings } from './json-translation-bundle-registry';
import { TranslationMapLoader } from './translation-map-loader';
import { TranslationStorage } from './translation-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./translation-storage.service";
import * as i2 from "@angular/common/http";
var JSONTranslationMapLoader = /** @class */ (function (_super) {
    __extends(JSONTranslationMapLoader, _super);
    function JSONTranslationMapLoader(storage, httpClient) {
        var _this = _super.call(this) || this;
        _this.storage = storage;
        _this.httpClient = httpClient;
        return _this;
    }
    JSONTranslationMapLoader.prototype.load = function (locale) {
        var _this = this;
        var localeStr = locale.language + "_" + locale.country;
        var pendings = getPendings();
        if (pendings.length > 0) {
            clearPendings();
            return this.ready = new Promise(function (resolve, reject) {
                merge$.apply(Observable, pendings.map(function (source) { return _this.httpClient.get(source[localeStr], {
                    observe: 'response',
                }); }))
                    .pipe(map$(function (response) { return response.body; }), tap$(function (map) { return _this.storage.putAll(map); }))
                    .subscribe(noop, reject, resolve);
            });
        }
        else {
            return this.ready || Promise.resolve();
        }
    };
    JSONTranslationMapLoader.ɵfac = function JSONTranslationMapLoader_Factory(t) { return new (t || JSONTranslationMapLoader)(i0.ɵɵinject(i1.TranslationStorage), i0.ɵɵinject(i2.HttpClient)); };
    JSONTranslationMapLoader.ɵprov = i0.ɵɵdefineInjectable({ token: JSONTranslationMapLoader, factory: JSONTranslationMapLoader.ɵfac });
    return JSONTranslationMapLoader;
}(TranslationMapLoader));
export { JSONTranslationMapLoader };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(JSONTranslationMapLoader, [{
        type: Injectable
    }], function () { return [{ type: i1.TranslationStorage }, { type: i2.HttpClient }]; }, null); })();
