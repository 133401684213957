/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  HttpClient,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNotNil, mergePath } from '@gh/core-util';
import { of$ } from '@gh/rx';
import { filter$, switchMap$ } from '@gh/rx/operators';
import * as invariant from 'invariant';
import { isNil, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { mergeSortByParameter, SlicedData, SlicedDataQuery, SlicedDataSource } from '../data-source';
import {
  createHttpData,
  DEFAULT_DESERIALIZATION_TYPE,
  DEFAULT_SERIALIZATION_TYPE,
  DummyHttpDataMapper,
  HeadersData,
  HttpDataMapper,
  NativeHeadersData,
} from '../mappers';
import { DataMapper } from '../mappers/data-mapper';
import { HTTP_STATUS_NO_CONTENT, isResponseHttpEvent, RequestMethod } from './http.utils';
import { RestSettings } from './rest-settings.service';

const CONTENT_RANGE_HEADER_RE = /^items\s+(((\d+)-(\d+))|(\*))\/(\d+)$/;

/**
 * Custom parameter encoder is necessary because Angular's default strategy is to allow '+'
 * and some other characters as parameter value. But '+' is treat as ' ' (space) character.
 *
 * Related issues:
 * https://github.com/angular/angular/issues/11058
 * https://github.com/mean-expert-official/loopback-sdk-builder/issues/573
 * https://github.com/angular/angular/issues/18261
 */
export class NativeHttpParameterCodec implements HttpParameterCodec {
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }

  encodeValue(v: string): string {
    return encodeURIComponent(v);
  }

  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }

  decodeValue(v: string): string {
    return decodeURIComponent(v);
  }
}

const NATIVE_HTTP_PARAMETER_CODEC = new NativeHttpParameterCodec();

/**
 * This method guarantees that {@link DataMapper} is always {@link HttpDataMapper}.
 * If given mapper is not an instance of ${link HttpDataMapper}
 * this method wraps it into the simplest {@link HttpDataMapper}.
 *
 * @param mapper mapper to be wrapped
 * @returns wrapped {@link HttpDataMapper}
 */
function wrapIntoHttpRequestMapper<T>(mapper: DataMapper<T, any>): HttpDataMapper<T> {
  if (mapper instanceof HttpDataMapper) {
    return mapper;
  } else {
    return new DummyHttpDataMapper(mapper);
  }
}

/**
 * The same method as {@link #wrapIntoHttpRequestMapper}, but for response mapper
 * @param mapper
 */
function wrapIntoHttpResponseMapper<T>(mapper: DataMapper<any, T>): HttpDataMapper<T> {
  if (mapper instanceof HttpDataMapper) {
    return mapper;
  } else {
    return new DummyHttpDataMapper(mapper);
  }
}

/**
 * Writes provided header data into angular {@link HttpHeaders} object.
 *
 * @param originalHeaders headers of Angular request
 * @param headersData headers to be written into Angular Headers
 */
function applyHttpHeaders(originalHeaders: HttpHeaders, headersData: HeadersData): HttpHeaders {
  return headersData.keys().reduce(
    (headers, key) => {
      const value = headersData.get(key);

      return isNotNil(value) ? headers.set(key, value) : headers;
    },
    originalHeaders);
}

/**
 * Definition of HTTP request operation.
 */
export interface HttpRequestConfig<Req, Resp> {
  method: RequestMethod;
  url: string;
  body?: Req;
  requestMapper?: DataMapper<Req, any>;
  responseMapper: DataMapper<Resp, any>;
}

/**
 * Definition of HTTP request operation which returns paginable result set.
 */
export type HttpSlicedDataRequestConfig<Req, Resp> = HttpRequestConfig<Req, Resp[]>;

/**
 * Definition of HTTP request operation having given query which returns pageable result set.
 */
export interface HttpSlicedDataQueryRequestConfig<Req, Resp> extends HttpSlicedDataRequestConfig<Req, Resp> {
  query: SlicedDataQuery;
}

/**
 * Basic class for all REST services.
 * It provides basic support of operations over HTTP protocol and integration with mappers.
 */
@Injectable()
export abstract class AbstractRestService {
  protected httpClient: HttpClient;
  private baseUrl: string;

  constructor(settings: RestSettings) {
    this.httpClient = settings.httpClient;
    this.baseUrl = settings.baseUrl;
  }

  /**
   * Rewrites provided url/path in order to add base URL for API endpoints.
   */
  protected url(path: string): string {
    if (path.includes('://')) {
      return path;
    } else {
      return mergePath(this.baseUrl, path);
    }
  }

  /**
   * Performs HTTP request operation based on given config and using provided mappers (from config).
   */
  protected httpRequest<S, R>(requestConfig: HttpRequestConfig<S, R>): Observable<R> {
    const { method, url, body, requestMapper, responseMapper } = requestConfig;

    const httpRequestMapper = requestMapper ? wrapIntoHttpRequestMapper(requestMapper) : void 0;
    const httpResponseMapper = responseMapper ? wrapIntoHttpResponseMapper(responseMapper) : void 0;

    // create request object using provided mapper
    const request = this.createGenericRequest(method, url, body, httpRequestMapper, httpResponseMapper);

    // run request
    return this.httpClient.request(request).pipe(
      filter$<HttpResponse<any>>(isResponseHttpEvent),
      switchMap$((response: HttpResponse<any>) => {
        if (response.status === HTTP_STATUS_NO_CONTENT) {
          return of$(void 0);
        }

        // deserialize response using provided mapper, if any
        const serializedResponse = httpResponseMapper
          ? httpResponseMapper.deserialize(createHttpData(
            new NativeHeadersData(response.headers || new HttpHeaders()),
            response.body,
          )) : response.body;
        return of$(serializedResponse);
      }));
  }

  /**
   * Performs GET request and deserialize response using provided mapper
   *
   * @param path relative url for endpoint
   * @param responseMapper mapper for response
   */
  protected httpGet<Resp>(path: string, responseMapper: DataMapper<Resp, any>): Observable<Resp>;
  /**
   * Performs GET request with parameters (via body parameter) and deserialize response using provided mapper.
   * Body is serialized using provided request mapper.
   *
   * @param path relative url for endpoint
   * @param body body is merged into url using querystring library.
   * @param requestMapper serializes body. Optional, if mapper is not provided body is used as is.
   * @param responseMapper mapper for response
   */
  protected httpGet<Req, Resp>(path: string,
                               body: Req,
                               requestMapper: Maybe<DataMapper<Req, any>>,
                               responseMapper: DataMapper<Resp, any>): Observable<Resp>;
  protected httpGet<Req, Resp>(path: string,
                               bodyOrResponseMapper: Req | DataMapper<Resp, any>,
                               requestMapper?: DataMapper<Req, any>,
                               responseMapper?: DataMapper<Resp, any>): Observable<Resp> {
    return this.httpRequest({
      method: RequestMethod.Get,
      url: path,
      body: responseMapper ? <Req>bodyOrResponseMapper : void 0,
      requestMapper,
      responseMapper: responseMapper || <DataMapper<Resp, any>>bodyOrResponseMapper,
    });
  }

  /**
   * Performs PUT request.
   * Request and response use the same mapper for serialization of body and deserialization of response correspondingly.
   *
   * @param path relative url for endpoint
   * @param body entity to be serialized and send
   * @param requestOrResponseMapper mapper for request and response entity
   */
  protected httpPut<T>(path: string, body: Maybe<T>, requestOrResponseMapper: DataMapper<T, any>): Observable<T>;
  /**
   * Performs PUT request. This method uses separate mappers to serialize request body and deserialize response.
   *
   * @param path relative url for endpoint
   * @param body entity to be serialized and send
   * @param requestMapper mapper for request entity
   * @param responseMapper mapper for response entity
   */
  protected httpPut<S, R>(path: string,
                          body: Maybe<S>,
                          requestMapper: DataMapper<S, any>,
                          responseMapper: DataMapper<R, any>): Observable<R>;
  protected httpPut(path: string,
                    body: any,
                    requestMapper: DataMapper<any, any>,
                    responseMapper?: DataMapper<any, any>): Observable<any> {
    return this.httpRequest({
      method: RequestMethod.Put,
      url: path,
      body,
      requestMapper,
      responseMapper: responseMapper || requestMapper,
    });
  }

  /**
   * Performs DELETE request.
   * Request and response use the same mapper for serialization of body and deserialization of response correspondingly.
   *
   * @param path relative url for endpoint
   * @param body entity to be serialized and send
   * @param requestOrResponseMapper mapper for request or response entity
   */
  protected httpDelete<T>(path: string, body: Maybe<T>, requestOrResponseMapper: DataMapper<T, any>): Observable<T>;
  /**
   * Performs DELETE request. This method uses separate mappers to serialize request body and deserialize response.
   *
   * @param path relative url for endpoint
   * @param body entity to be serialized and send
   * @param requestMapper mapper for request entity
   * @param responseMapper mapper for response entity
   */
  protected httpDelete<S, R>(path: string,
                             body: Maybe<S>,
                             requestMapper: DataMapper<S, any>,
                             responseMapper: DataMapper<R, any>): Observable<R>;
  protected httpDelete(path: string,
                       body: any,
                       requestMapper: DataMapper<any, any>,
                       responseMapper?: DataMapper<any, any>): Observable<any> {
    return this.httpRequest({
      method: RequestMethod.Delete,
      url: path,
      body,
      requestMapper,
      responseMapper: responseMapper || requestMapper,
    });
  }

  /**
   * Performs POST request.
   * Request and response use the same mapper for serialization of body and deserialization of response correspondingly.
   *
   * @param path relative url for endpoint
   * @param body entity to be serialized and send
   * @param requestOrResponseMapper mapper for request or response entity
   */
  protected httpPost<T>(path: string, body: T, requestOrResponseMapper: DataMapper<T, any>): Observable<T>;
  /**
   * Performs POST request. This method uses separate mappers to serialize request body and deserialize response.
   *
   * @param path relative url for endpoint
   * @param body entity to be serialized and send
   * @param requestMapper mapper for request entity
   * @param responseMapper mapper for response entity
   */
  protected httpPost<S, R>(path: string,
                           body: S,
                           requestMapper: DataMapper<S, any>,
                           responseMapper: DataMapper<R, any>): Observable<R>;
  protected httpPost(path: string,
                     body: any,
                     requestMapper: DataMapper<any, any>,
                     responseMapper?: DataMapper<any, any>): Observable<any> {
    return this.httpRequest({
      method: RequestMethod.Post,
      url: path,
      body,
      requestMapper,
      responseMapper: responseMapper || requestMapper,
    });
  }

  /**
   * Performs HTTP paginable request operation based on given config and using provided mappers (from config).
   * Paginable request differs from typical request in the following way
   *
   * * paginable request always has query.
   *             Query tells how to sort data and what slice of data should be retrieved from backend.
   *             Slice is defined by interval (from ... to)
   * * paginable request adds additional query parameter (sortBy) which defines how to sort data
   * * paginable request adds range header (Range) which defines slice of data that should be retrieved
   * * paginable request always returns instance of {@link SlicedData}
   */
  protected createSlicedDataRequest<S, R>(config: HttpSlicedDataQueryRequestConfig<S, R>): Observable<SlicedData<R>> {
    const { method, url, query, body, requestMapper, responseMapper } = config;

    const httpRequestMapper = requestMapper ? wrapIntoHttpRequestMapper(requestMapper) : void 0;
    const httpResponseMapper = responseMapper ? wrapIntoHttpResponseMapper(responseMapper) : void 0;

    // create request object using provided mapper
    let request = this.createGenericRequest(method, url, body, httpRequestMapper, httpResponseMapper);

    // add sorting as query parameter
    if (query.sorting) {
      request = request.clone({
        url: mergeSortByParameter(request.url, query.sorting),
      });
    }

    request = request.clone({
      headers: request.headers.set('Range', `items=${query.from}-${Math.max(query.to - 1, 0)}`),
    });

    // run request
    return this.httpClient.request(request).pipe(
      filter$<HttpResponse<any>>(isResponseHttpEvent),
      switchMap$((response: HttpResponse<any>) => {
        let entities;
        // deserialize response using provided mapper, if any
        if (httpResponseMapper) {
          entities = httpResponseMapper.deserialize(createHttpData(
            new NativeHeadersData(response.headers || new Headers()),
            response.body,
          ));
        } else {
          entities = response.body;
        }

        // validate response
        invariant(Array.isArray(entities), `Response of '${response.url}' is not an array`);

        let total = -1;

        if (response.headers) {
          const match = CONTENT_RANGE_HEADER_RE.exec(response.headers.get('Content-Range') || '');

          invariant(
            match && match[6], // tslint:disable-line:no-magic-numbers
            `Invalid Content-Range header for request '${response.url}': ${response.headers.get('Content-Range')}`);

          if (!match) {
            throw new Error(`Invalid Content-Range header for request '${response.url}': ${response.headers.get(
              'Content-Range')}`);
          }
          // iError( (Number(match[4]) - Number(match[3]) + 1) === entities.length,
          //   `Invalid Content-Range header for request '${response.url}': ${response.headers.get('Content-Range')}`);

          total = Number(match[6]); // tslint:disable-line:no-magic-numbers
        }

        // pack result into SlicedData object
        return of$({
          from: query.from,
          to: query.to,
          total,
          data: entities,
        });
      }));
  }

  /**
   * Creates {@link SlicedDataSource} based on GET requests.
   * Each request use provided mapper to deserialize its response.
   *
   * @param path relative url for endpoint
   * @param responseMapper mapper for response entity
   */
  protected createSlicedDataSourceGet<Resp>(path: string,
                                            responseMapper: DataMapper<Resp[], any>): SlicedDataSource<Resp>;
  /**
   * Creates {@link SlicedDataSource} based on GET requests.
   *
   * This method allows to pass additional parameters common for each request.
   * Body is serialized using provided request mapper (optional).
   *
   * Each request use provided mapper to deserialize its response.
   *
   * @param path relative url for endpoint
   * @param body body is merged into url using querystring library.
   * @param requestMapper serializes body. Optional, if mapper is not provided body is used as is.
   * @param responseMapper mapper for response entity
   */
  protected createSlicedDataSourceGet<Req, Resp>(path: string,
                                                 body: Req,
                                                 requestMapper: Maybe<DataMapper<Req, any>>,
                                                 responseMapper: DataMapper<Resp[], any>): SlicedDataSource<Resp>;
  protected createSlicedDataSourceGet<Req, Resp>(path: string,
                                                 bodyOrResponseMapper: Req | DataMapper<Resp[], any>,
                                                 requestMapper?: DataMapper<Req, any>,
                                                 responseMapper?: DataMapper<Resp[], any>): SlicedDataSource<Resp> {

    return this.createSlicedDataSource({
      method: RequestMethod.Get,
      url: path,
      body: responseMapper ? <Req>bodyOrResponseMapper : void 0,
      requestMapper,
      responseMapper: responseMapper || <DataMapper<Resp[], any>>bodyOrResponseMapper,
    });
  }

  /**
   * Creates {@link SlicedDataSource} based on GET requests.
   *
   * This method allows to pass additional parameters common for each request.
   * Body is serialized using provided request mapper.
   *
   * Each request use provided mapper to deserialize its response.
   *
   * @param path relative url for endpoint
   * @param body entity to be serialized and send
   * @param requestMapper mapper for request entity
   * @param responseMapper mapper for response entity
   */
  protected createSlicedDataSourcePost<S, R>(path: string,
                                             body: S,
                                             requestMapper: DataMapper<S, any>,
                                             responseMapper: DataMapper<R[], any>): SlicedDataSource<R> {
    return this.createSlicedDataSource({
      method: RequestMethod.Post,
      url: path,
      body,
      requestMapper,
      responseMapper,
    });
  }

  /**
   * Creates {@link SlicedDataSource} based on the provided config.
   * One instance of {@link SlicedDataSource} can be used to request data
   * for different queries ({@link SlicedDataQuery}).
   * Each performed query use configuration provided in the config parameter.
   * See {@link #createSlicedDataRequest} for details.
   */
  protected createSlicedDataSource<S, R>(config: HttpSlicedDataRequestConfig<S, R>): SlicedDataSource<R> {
    const select = (query: SlicedDataQuery) => this.createSlicedDataRequest({
      ...config,
      query,
    });

    return { select };
  }

  /**
   * Creates {@link Request} object for the given set of parameters.
   * Body is serialized using provided request mapper:
   *
   * * for GET request body is merged into url using querystring library
   * * for request methods body is always passed via body of HTTP request.
   *
   * @param method target HTTP method
   * @param path relative endpoint URL
   * @param body request entity
   * @param requestMapper mapper for mapping request entity into body
   * @param responseMapper mapper for mapping response entity into body
   * @returns request object
   */
  private createGenericRequest<S>(method: RequestMethod,
                                  path: string,
                                  body?: S,
                                  requestMapper?: HttpDataMapper<S>,
                                  responseMapper?: HttpDataMapper<S>): HttpRequest<any> {
    let httpHeaders = this.createDefaulHeaders(requestMapper && requestMapper.serializationType);
    let serializedBody: any;

    if (body && requestMapper) {
      const httpData = requestMapper.serialize(body);
      httpHeaders = applyHttpHeaders(httpHeaders, httpData.headers);
      serializedBody = httpData.body;
    } else {
      serializedBody = body;
    }

    const httpParams = method === RequestMethod.Get && serializedBody ? new HttpParams({
      fromObject: omitBy(serializedBody, isNil),
      encoder: NATIVE_HTTP_PARAMETER_CODEC,
    }) : void 0;
    const httpBody = method === RequestMethod.Get ? void 0 : serializedBody;
    const responseType = responseMapper && <any>responseMapper.deserializationType || DEFAULT_DESERIALIZATION_TYPE;

    return new HttpRequest(method, this.url(path), httpBody, {
      params: httpParams,
      headers: httpHeaders,
      responseType,
    });
  }

  private createDefaulHeaders(type?: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': type || DEFAULT_SERIALIZATION_TYPE,
    });
  }
}
