/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { regexpablePlainString } from '@gh/core-util';
import { isNil } from 'lodash';
import * as i0 from "@angular/core";
var HighlightPipe = /** @class */ (function () {
    function HighlightPipe() {
    }
    /**
     * Transform function
     * @param value string
     * @param query string filter value
     * @return filtered string with markup
     */
    HighlightPipe.prototype.transform = function (value, query) {
        if (isNil(query) || query.length < 1) {
            return value;
        }
        var pattern = new RegExp("(.*)(" + regexpablePlainString(query) + ")(.*)", 'ig');
        var replaceWith = '$1<span class="highlighted">$2</span>$3';
        return value && value.toString().replace(pattern, replaceWith);
    };
    HighlightPipe.ɵfac = function HighlightPipe_Factory(t) { return new (t || HighlightPipe)(); };
    HighlightPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "highlight", type: HighlightPipe, pure: true });
    return HighlightPipe;
}());
export { HighlightPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(HighlightPipe, [{
        type: Pipe,
        args: [{ name: 'highlight' }]
    }], null, null); })();
