/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Message, MessageBasket, MessageSeverity } from '@gh/core-messages';
import { logError } from '@gh/core-util';
/**
 * This service allows to manage balloons shown in the overlay on top of the screen.
 */
var NotificationService = /** @class */ (function () {
    function NotificationService() {
        this.processAll = this.processAll.bind(this);
    }
    /**
     * Shows notification depending on message severity
     */
    NotificationService.prototype.message = function (message) {
        var ref;
        switch (message.severity) {
            case MessageSeverity.Error:
                ref = this.error(message.key, message.params);
                break;
            case MessageSeverity.Info:
                ref = this.success(message.key, message.params);
                break;
            case MessageSeverity.Success:
                ref = this.success(message.key, message.params);
                break;
            case MessageSeverity.Warning:
                ref = this.warning(message.key, message.params);
                break;
            default:
                throw new Error("Unknown severity value: " + message.severity);
        }
        return ref;
    };
    /**
     * This method processes message(s) and show notifications if necessary.
     *
     * If parameter is an instance of {@link MessageBasket} this method shows notification for each message.
     * if parameter is an instance of {@link Message} this method show notification.
     * If parameter is an error method throws this error. This method should not hide errors.
     * If parameter is neither {@link MessageBasket} nor {@link Error} method throws an error.
     *
     * @param param
     */
    NotificationService.prototype.processAll = function (param) {
        var _this = this;
        if (param instanceof MessageBasket) {
            param.messages.forEach(function (message) { return _this.message(__assign(__assign({}, message), { key: /^msg_/.test(message.key) ? message.key : "msg_" + message.key })); });
        }
        else if (param instanceof Message) {
            this.message(param);
        }
        else if (param instanceof Error) {
            logError(param);
            throw param;
        }
        else {
            logError('Unknown kind of error: ', param);
            throw new Error("Unknown kind of error [" + param + "]");
        }
    };
    return NotificationService;
}());
export { NotificationService };
