/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';

import { ID } from '@gh/common-shared-data';
import { AbstractRestService, arrayOf, identity, RestSettings, SlicedDataSource } from '@gh/core-data';

import { SharedDocumentListItemDto } from './shared-document.dto';

@Injectable()
export class CustomerSharedDocumentRestService extends AbstractRestService {
  constructor(private settings: RestSettings) {
    super(settings);
  }

  findAll(jobId: ID): SlicedDataSource<SharedDocumentListItemDto> {
    return this.createSlicedDataSourceGet(`/jobs/${jobId}/docs`, arrayOf(identity()));
  }

}
