/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { logError } from '@gh/core-util';
import { isNil } from 'lodash';
import { Enum, EnumSet } from '../enum';
import { DataMapper } from './data-mapper';

export abstract class EnumMapper<T extends Enum<S>, S> implements DataMapper<EnumSet<T>, string> {
  abstract enumeration: T;

  serialize(name: EnumSet<T>): string {
    const value = Enum.toValue(this.enumeration, name);
    if (isNil(value)) {
      logError(`EnumMapper: enumeration value '${name}' cannot be serialized (Enum '${Enum.name(this.enumeration)}')`);
    }
    return value;
  }

  deserialize(value: string): EnumSet<T> {
    const name = Enum.byValue(this.enumeration, value);
    if (isNil(name)) {
      // tslint:disable-next-line:max-line-length
      logError(`EnumMapper: enumeration value '${value}' cannot be deserialized (Enum '${Enum.name(this.enumeration)}')`);
    }
    return name;
  }
}
