/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InjectionToken } from '@angular/core';
import { AuthProvider } from './auth-provider';
export var DECORATED_AUTH_PROVIDER = new InjectionToken('DECORATED_AUTH_PROVIDER');
export var DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR = new InjectionToken('DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR');
var DecoratableAuthProvider = /** @class */ (function (_super) {
    __extends(DecoratableAuthProvider, _super);
    function DecoratableAuthProvider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DecoratableAuthProvider;
}(AuthProvider));
export { DecoratableAuthProvider };
var DecoratingAuthProvider = /** @class */ (function (_super) {
    __extends(DecoratingAuthProvider, _super);
    function DecoratingAuthProvider(decoratable, decorated) {
        var _this = _super.call(this) || this;
        _this.decoratable = decoratable;
        _this.decorated = decorated;
        return _this;
    }
    Object.defineProperty(DecoratingAuthProvider.prototype, "authState", {
        get: function () {
            if (this.decoratable.use) {
                return this.decoratable.authState;
            }
            else {
                return this.decorated.authState;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DecoratingAuthProvider.prototype, "onAuthState", {
        get: function () {
            if (this.decoratable.use) {
                return this.decoratable.onAuthState;
            }
            else {
                return this.decorated.onAuthState;
            }
        },
        enumerable: true,
        configurable: true
    });
    DecoratingAuthProvider.prototype.login = function () {
        if (this.decoratable.use) {
            return this.decoratable.login();
        }
        else {
            return this.decorated.login();
        }
    };
    DecoratingAuthProvider.prototype.logout = function () {
        if (this.decoratable.use) {
            return this.decoratable.logout();
        }
        else {
            return this.decorated.logout();
        }
    };
    return DecoratingAuthProvider;
}(AuthProvider));
export { DecoratingAuthProvider };
var DecoratingAuthHttpRequestInterceptor = /** @class */ (function () {
    function DecoratingAuthHttpRequestInterceptor(authProvider, decoratable, decorated) {
        this.authProvider = authProvider;
        this.decoratable = decoratable;
        this.decorated = decorated;
    }
    DecoratingAuthHttpRequestInterceptor.prototype.intercept = function (req, next) {
        if (this.authProvider.use) {
            return this.decoratable.intercept(req, next);
        }
        else {
            return this.decorated.intercept(req, next);
        }
    };
    return DecoratingAuthHttpRequestInterceptor;
}());
export { DecoratingAuthHttpRequestInterceptor };
