/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { from$ } from '@gh/rx';
import { AuthProvider } from './auth-provider';
import { PermissionProvider } from './permission-provider';
import * as i0 from "@angular/core";
import * as i1 from "./auth-provider";
import * as i2 from "./permission-provider";
var AuthService = /** @class */ (function () {
    function AuthService(authProvider, permissionProvider) {
        this.authProvider = authProvider;
        this.permissionProvider = permissionProvider;
    }
    AuthService.prototype.login = function () {
        return from$(this.authProvider.login());
    };
    AuthService.prototype.logout = function () {
        return from$(this.authProvider.logout());
    };
    AuthService.prototype.hasPermission = function (permission) {
        return this.permissionProvider.hasPermission(permission);
    };
    Object.defineProperty(AuthService.prototype, "authState", {
        get: function () {
            return this.authProvider.authState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "onAuthState", {
        get: function () {
            return this.authProvider.onAuthState;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.ɵfac = function AuthService_Factory(t) { return new (t || AuthService)(i0.ɵɵinject(i1.AuthProvider), i0.ɵɵinject(i2.PermissionProvider)); };
    AuthService.ɵprov = i0.ɵɵdefineInjectable({ token: AuthService, factory: AuthService.ɵfac });
    return AuthService;
}());
export { AuthService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AuthService, [{
        type: Injectable
    }], function () { return [{ type: i1.AuthProvider }, { type: i2.PermissionProvider }]; }, null); })();
