/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { TranslationService } from '@gh/core-mls';
import { compact } from 'lodash';

export const HOURS_IN_DAY = 8;

/**
 * {@link LocalizationBusinessService} serves to keep shared business-related localization logic.
 */
@Injectable()
export class LocalizationBusinessService {
  constructor(private translations: TranslationService) {
  }

  /**
   * Formats given hours-based duration to human-readable form:
   * <ul>
   *   <li>244 => 30d 4h</li>
   *   <li>16 => 2d</li>
   *   <li>3 => 3h</li>
   * </ul>
   */
  formatHoursDuration(duration: number): string {
    const days = Math.floor(duration / HOURS_IN_DAY);
    const hours = duration % HOURS_IN_DAY;

    return compact([
      (days === 0 ? [] : [days, this.translations.get('lbl_duration_d')].join('')),
      (hours === 0 ? [] : [hours, this.translations.get('lbl_duration_h')].join('')),
    ]).join(' ') || '0';
  }

  /**
   * Formats given hours-based duration to human-readable form:
   * <ul>
   *   <li>30 => 30d</li>
   *   <li>1 => 1d</li>
   * </ul>
   */
  formatDaysDuration(duration: number): string {
    return `${duration}${this.translations.get('lbl_duration_d')}`;
  }
}
