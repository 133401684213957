/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { TranslationService } from './translation.service';
import * as i0 from "@angular/core";
import * as i1 from "./translation.service";
var TranslatePipe = /** @class */ (function () {
    function TranslatePipe(translation) {
        this.translation = translation;
    }
    TranslatePipe.prototype.transform = function (keyOrTuple, params) {
        return this.translation.get(keyOrTuple, params);
    };
    TranslatePipe.ɵfac = function TranslatePipe_Factory(t) { return new (t || TranslatePipe)(i0.ɵɵdirectiveInject(i1.TranslationService)); };
    TranslatePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "translate", type: TranslatePipe, pure: true });
    return TranslatePipe;
}());
export { TranslatePipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TranslatePipe, [{
        type: Pipe,
        args: [{
                name: 'translate',
                pure: true,
            }]
    }], function () { return [{ type: i1.TranslationService }]; }, null); })();
