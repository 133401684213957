/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DataSource } from '@angular/cdk/collections';
import { RenderRow } from '@angular/cdk/table';
import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { aspectAround } from '@gh/core-util';
import { of$ } from '@gh/rx';
import { takeUntil$ } from '@gh/rx/operators';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { ExtLoadTrackerComponent } from '../../components';
import {
  EXT_INFINITE_SCROLL_DIMENSIONS,
  EXT_INFINITE_SCROLL_VIEWPORT,
  ExtInfiniteScrollDimensions,
  ExtInfiniteScrollViewport,
  сollectionViewerWithSideEffect,
} from '../ext-infinite-scroll.interfaces';
import { getQueryItemIndex, toQueryResult } from '../infinite-mat-data-source';

function getTableUnknownDataSourceError(): Error {
  return Error(`Provided data source did not match an array, Observable, or DataSource`);
}

@Directive({
  selector: '[ghExtInfiniteTable]',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'ext-infinite-table',
  },
  exportAs: 'ghExtInfiniteTable',
})
export class ExtInfiniteTableDirective<T> implements OnInit {
  @Input() tracker?: ExtLoadTrackerComponent;

  constructor(private elementRef: ElementRef,
              private table: MatTable<T>,
              @Inject(EXT_INFINITE_SCROLL_VIEWPORT) private viewport: ExtInfiniteScrollViewport,
              @Inject(EXT_INFINITE_SCROLL_DIMENSIONS) private dimensions: ExtInfiniteScrollDimensions) {
    const self = this;
    aspectAround(
      this.table,
      '_switchDataSource',
      (original) => function(dataSource: DataSource<T>): void {
        original.apply(this, [dataSource]);
        self._switchDataSource(dataSource);
      });
    this.table['_observeRenderChanges'] = this._observeRenderChanges.bind(this);
    aspectAround(
      this.table,
      '_getRenderRowsForData',
      (original) => function(data: T, dataIndex: number, ...rest: any[]): RenderRow<T>[] {
        return original.apply(this, [data, dataIndex, ...rest])
          .map((row: RenderRow<T>) => ({
            ...row,
            dataIndex: getQueryItemIndex(row.data),
          }));
      });
  }

  ngOnInit(): void {
    if (this.tracker) {
      this.tracker.reset();
    }

    this.updateHeight();
  }

  private _switchDataSource(_: DataSource<T>): void {
    this.viewport.scrollToTop();
  }

  /** Set up a subscription for the data provided by the data source. */
  private _observeRenderChanges(): void {
    // If no data source has been set, there is nothing to observe for changes.
    if (!this.table.dataSource) {
      return;
    }

    let dataStream: Observable<ReadonlyArray<T> | T[]> | undefined;

    // Check if the datasource is a DataSource object by observing if it has a connect function.
    // Cannot check this.dataSource['connect'] due to potential property renaming, nor can it
    // checked as an instanceof DataSource<T> since the table should allow for data sources
    // that did not explicitly extend DataSource<T>.
    if ((this.table.dataSource as DataSource<T>).connect instanceof Function) {
      dataStream = (this.table.dataSource as DataSource<T>).connect(сollectionViewerWithSideEffect(
        this.viewport,
        () => this.tracker && this.tracker.start()));
    } else if (this.table.dataSource instanceof Observable) {
      dataStream = this.table.dataSource;
    } else if (Array.isArray(this.table.dataSource)) {
      dataStream = of$(this.table.dataSource);
    }

    if (dataStream === undefined) {
      throw getTableUnknownDataSourceError();
    }

    this.table['_renderChangeSubscription'] = dataStream.pipe(
      takeUntil$(this.table['_onDestroy']))
      .subscribe((data: any[]) => {
        const { query: { from, to } } = toQueryResult(data);
        this.table['_data'] = data || [];
        this.table.renderRows();
        this.viewport.updateRowCount(to - from, (data || []).length, from);
        this.updateHeight();
        this.tracker && this.tracker.stop(isEmpty(data));
      });
  }

  private updateHeight(): void {
    if (this.viewport.rowCount >= 0) {
      this.elementRef.nativeElement.style.height = `${this.viewport.rowCount * this.dimensions.itemHeight}px`;
    }
  }
}
