/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime } from '@gh/core-util';

@Directive({
  selector: '[ghDateTimePicker]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DateTimePickerInputDirective, multi: true }],
})
export class DateTimePickerInputDirective implements ControlValueAccessor {
  onSetDisabledState: (isDisabled: boolean) => void;
  onWriteValue: (datetime?: DateTime) => void;
  onChange: Function;
  onTouched: Function;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnSetDisabledState(onSetDisabledState: (isDisabled: boolean) => void): void {
    this.onSetDisabledState = onSetDisabledState;
  }

  registerOnWriteValue(onWriteValue: (datetime?: DateTime) => void): void {
    this.onWriteValue = onWriteValue;
  }

  setDisabledState(isDisabled: boolean): void {
    this.onSetDisabledState(isDisabled);
  }

  writeValue(datetime?: DateTime): void {
    this.onWriteValue(datetime);
  }
}
