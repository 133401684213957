/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, ElementRef, EventEmitter, Input, Optional, Output, Self, ViewChild, } from '@angular/core';
import { acceptor, parseByteSize } from '@gh/core-util';
import { map$ } from '@gh/rx/operators';
import { constant, isNil } from 'lodash';
import { createFileInfoFromBlob, getUploadItemName, isUploadCompleted, UploadController, UploadEventType, } from './upload.interfaces';
import * as i0 from "@angular/core";
import * as i1 from "./upload.interfaces";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/form-field";
import * as i4 from "@angular/material/input";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/material/button";
import * as i7 from "../../core-mls/translation/translate.pipe";
var _c0 = ["fileInput"];
function SingleUploaderComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelement(1, "mat-icon", 11);
    i0.ɵɵelementEnd();
} }
function SingleUploaderComponent_div_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("", ctx_r2.progress, "%");
} }
function SingleUploaderComponent_button_10_Template(rf, ctx) { if (rf & 1) {
    var _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function SingleUploaderComponent_button_10_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r6); var ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.reset(); });
    i0.ɵɵelement(1, "mat-icon", 14);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("svgIcon", ctx_r3.deleteIcon);
} }
function SingleUploaderComponent_a_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 15);
    i0.ɵɵelement(1, "mat-icon", 16);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("href", ctx_r4.item.location, i0.ɵɵsanitizeUrl);
} }
export var InvalidFileReason;
(function (InvalidFileReason) {
    InvalidFileReason[InvalidFileReason["NotAccepted"] = 0] = "NotAccepted";
    InvalidFileReason[InvalidFileReason["TooLarge"] = 1] = "TooLarge";
    InvalidFileReason[InvalidFileReason["WrongSize"] = 2] = "WrongSize";
})(InvalidFileReason || (InvalidFileReason = {}));
/**
 * This component allows to mangage single upload process. It is displayed as typical input with file name.
 *
 * All managing of an upload process is delegated to the {@link UploadController}. It is necessary to detach UI from
 * the way how upload process is managed. It is supposed that this component has assigned directive providing
 * implementation of {@link UploadController} (e.g. ghUploadSlot).
 */
var SingleUploaderComponent = /** @class */ (function () {
    function SingleUploaderComponent(uploader) {
        this.uploader = uploader;
        this.disabled = false;
        this.deleteIcon = 'gh:delete';
        this.openByClick = true;
        this.useUploadIcon = true;
        this.useDownloadIcon = true;
        this.minWidth = 0;
        this.minHeight = 0;
        this.checkImageSize = false;
        // tslint:disable-next-line:no-output-named-after-standard-event
        this.invalid = new EventEmitter();
        // tslint:disable-next-line:no-output-named-after-standard-event
        this.selectionChange = new EventEmitter();
        this._accept = '';
        this.acceptor = constant(true);
    }
    Object.defineProperty(SingleUploaderComponent.prototype, "maxSize", {
        set: function (maxSize) {
            this._maxSize = parseByteSize(maxSize);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleUploaderComponent.prototype, "accept", {
        get: function () {
            return this._accept;
        },
        set: function (accept) {
            this._accept = accept;
            this.acceptor = acceptor(accept);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleUploaderComponent.prototype, "item", {
        get: function () {
            return this.uploader.items[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleUploaderComponent.prototype, "fileName", {
        get: function () {
            var item = this.item;
            return item && getUploadItemName(item) || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleUploaderComponent.prototype, "displayDeleteButton", {
        get: function () {
            return !isNil(this.item) && !this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleUploaderComponent.prototype, "displayDownloadButton", {
        get: function () {
            var item = this.item;
            return this.useDownloadIcon && (item ? isUploadCompleted(item) : false);
        },
        enumerable: true,
        configurable: true
    });
    SingleUploaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (isNil(this.uploader)) {
            throw new Error('Upload controller is not defined');
        }
        this.progressSubscription = this.uploader.event$
            .pipe(map$(function (event) {
            switch (event.type) {
                case UploadEventType.Started:
                    return 0;
                case UploadEventType.Progress:
                    return Math.round((event.item.progress || 0) * 100); // tslint:disable-line:no-magic-numbers
                case UploadEventType.Error:
                    return void 0;
                case UploadEventType.Completed:
                    return 100; // tslint:disable-line:no-magic-numbers
                case UploadEventType.Cancelled:
                    _this._resetSelection(false);
                    return void 0;
                default:
                    return void 0;
            }
        }))
            .subscribe(function (progress) {
            _this.progress = progress;
        });
    };
    SingleUploaderComponent.prototype.ngOnDestroy = function () {
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
    };
    SingleUploaderComponent.prototype.open = function () {
        this.fileInput.nativeElement.click();
    };
    SingleUploaderComponent.prototype.onSelect = function (file) {
        var _this = this;
        if (isNil(file)) {
            return;
        }
        if (this.checkImageSize) {
            var reader_1 = new FileReader();
            reader_1.readAsDataURL(file);
            reader_1.onload = function () {
                var img = new Image();
                img.src = reader_1.result;
                img.onload = function () {
                    var height = img.naturalHeight;
                    var width = img.naturalWidth;
                    if (width < _this.minWidth || height < _this.minHeight) {
                        _this.invalid.emit({ reason: InvalidFileReason.WrongSize, file: file });
                    }
                    else if (_this._maxSize && file.size > _this._maxSize) {
                        _this.invalid.emit({ reason: InvalidFileReason.TooLarge, file: file });
                    }
                    else if (!_this.acceptor(file)) {
                        _this.invalid.emit({ reason: InvalidFileReason.NotAccepted, file: file });
                    }
                    else {
                        _this._cancel(false);
                        var item = _this.uploader.create(createFileInfoFromBlob(file));
                        _this.uploader.start(item);
                        _this.selectionChange.emit(item);
                    }
                };
            };
            return;
        }
        else {
            if (this._maxSize && file.size > this._maxSize) {
                this.invalid.emit({ reason: InvalidFileReason.TooLarge, file: file });
                return;
            }
            else if (!this.acceptor(file)) {
                this.invalid.emit({ reason: InvalidFileReason.NotAccepted, file: file });
                return;
            }
            this._cancel(false);
            var item = this.uploader.create(createFileInfoFromBlob(file));
            this.uploader.start(item);
            this.selectionChange.emit(item);
        }
    };
    SingleUploaderComponent.prototype.reset = function () {
        this._cancel(true);
    };
    SingleUploaderComponent.prototype._cancel = function (emitEvent) {
        if (emitEvent === void 0) { emitEvent = false; }
        if (isNil(this.item)) {
            return;
        }
        this.uploader.cancel(this.item);
        this._resetSelection(emitEvent);
    };
    SingleUploaderComponent.prototype._resetSelection = function (emitEvent) {
        if (emitEvent === void 0) { emitEvent = false; }
        this.progress = void 0;
        this.fileInput.nativeElement.value = null; // tslint:disable-line:no-null-keyword
        if (emitEvent) {
            this.selectionChange.emit();
        }
    };
    SingleUploaderComponent.ɵfac = function SingleUploaderComponent_Factory(t) { return new (t || SingleUploaderComponent)(i0.ɵɵdirectiveInject(i1.UploadController, 10)); };
    SingleUploaderComponent.ɵcmp = i0.ɵɵdefineComponent({ type: SingleUploaderComponent, selectors: [["gh-single-uploader"]], viewQuery: function SingleUploaderComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(_c0, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileInput = _t.first);
        } }, inputs: { disabled: "disabled", deleteIcon: "deleteIcon", openByClick: "openByClick", useUploadIcon: "useUploadIcon", useDownloadIcon: "useDownloadIcon", minWidth: "minWidth", minHeight: "minHeight", checkImageSize: "checkImageSize", maxSize: "maxSize", accept: "accept" }, outputs: { invalid: "invalid", selectionChange: "selectionChange" }, exportAs: ["ghSingleUploader"], decls: 12, vars: 16, consts: [[1, "file-container", 3, "title"], ["type", "file", 1, "file-input", 3, "accept", "disabled", "change"], ["fileInput", ""], [1, "flex-row"], ["class", "file-icon squared-icon", 4, "ngIf"], [1, "file-name"], ["readonly", "", "matInput", "", 3, "placeholder", "value", "disabled"], ["class", "m-progress", 4, "ngIf"], ["type", "button", "mat-icon-button", "", "data-automation-id", "single-uploader:delete", 3, "click", 4, "ngIf"], ["mat-icon-button", "", "target", "_blank", "data-automation-id", "single-uploader:download", 3, "href", 4, "ngIf"], [1, "file-icon", "squared-icon"], ["svgIcon", "gh:vertical-align-top"], [1, "m-progress"], ["type", "button", "mat-icon-button", "", "data-automation-id", "single-uploader:delete", 3, "click"], [1, "s20", 3, "svgIcon"], ["mat-icon-button", "", "target", "_blank", "data-automation-id", "single-uploader:download", 3, "href"], ["svgIcon", "gh:cloud-queue", 1, "s20"]], template: function SingleUploaderComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵpipe(1, "translate");
            i0.ɵɵelementStart(2, "input", 1, 2);
            i0.ɵɵlistener("change", function SingleUploaderComponent_Template_input_change_2_listener($event) { return ctx.onSelect($event.target.files[0]); });
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "div", 3);
            i0.ɵɵtemplate(5, SingleUploaderComponent_div_5_Template, 2, 0, "div", 4);
            i0.ɵɵelementStart(6, "mat-form-field", 5);
            i0.ɵɵelement(7, "input", 6);
            i0.ɵɵpipe(8, "translate");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(9, SingleUploaderComponent_div_9_Template, 2, 1, "div", 7);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(10, SingleUploaderComponent_button_10_Template, 2, 1, "button", 8);
            i0.ɵɵtemplate(11, SingleUploaderComponent_a_11_Template, 2, 1, "a", 9);
        } if (rf & 2) {
            i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(1, 12, "lbl_browse"));
            i0.ɵɵadvance(2);
            i0.ɵɵclassProp("is-hidden", !ctx.openByClick);
            i0.ɵɵproperty("accept", ctx.accept)("disabled", ctx.disabled);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngIf", ctx.useUploadIcon);
            i0.ɵɵadvance(2);
            i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(8, 14, "lbl_file_name"));
            i0.ɵɵproperty("value", ctx.fileName)("disabled", ctx.disabled);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.progress != null);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.displayDeleteButton);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.displayDownloadButton);
        } }, directives: [i2.NgIf, i3.MatFormField, i4.MatInput, i5.MatIcon, i6.MatButton, i6.MatAnchor], pipes: [i7.TranslatePipe], styles: ["[_nghost-%COMP%] {\n  display: flex;\n  flex-direction: row;\n  align-items: center; }\n\n.file-container[_ngcontent-%COMP%] {\n  position: relative;\n  overflow: hidden;\n  display: inline-block;\n  height: 60px; }\n\n.file-icon[_ngcontent-%COMP%] {\n  margin-right: 15px; }\n\n.file-input[_ngcontent-%COMP%] {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n  opacity: 0;\n  cursor: pointer; }\n\n.m-progress[_ngcontent-%COMP%] {\n  position: absolute;\n  top: 17px;\n  right: 0;\n  font-size: 13px;\n  color: rgba(0, 0, 0, 0.54); }\n\n[_nghost-%COMP%]     .mat-input-element {\n  width: calc(100% - 32px); }"] });
    return SingleUploaderComponent;
}());
export { SingleUploaderComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SingleUploaderComponent, [{
        type: Component,
        args: [{
                selector: 'gh-single-uploader',
                templateUrl: 'single-uploader.component.html',
                styleUrls: ['single-uploader.component.scss'],
                exportAs: 'ghSingleUploader',
            }]
    }], function () { return [{ type: i1.UploadController, decorators: [{
                type: Optional
            }, {
                type: Self
            }] }]; }, { disabled: [{
            type: Input
        }], deleteIcon: [{
            type: Input
        }], openByClick: [{
            type: Input
        }], useUploadIcon: [{
            type: Input
        }], useDownloadIcon: [{
            type: Input
        }], minWidth: [{
            type: Input
        }], minHeight: [{
            type: Input
        }], checkImageSize: [{
            type: Input
        }], invalid: [{
            type: Output
        }], selectionChange: [{
            type: Output
        }], fileInput: [{
            type: ViewChild,
            args: ['fileInput', { static: false }]
        }], maxSize: [{
            type: Input
        }], accept: [{
            type: Input
        }] }); })();
