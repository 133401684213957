/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { AppRouter, getInheritedRouteParam, getRoutePath } from '@gh/common-shared';
import { CustomerUserContext } from '@gh/customer-shared';
import * as i0 from "@angular/core";
import * as i1 from "@gh/customer-shared";
import * as i2 from "@gh/common-shared";
var CustomerJobActivator = /** @class */ (function () {
    function CustomerJobActivator(userContext, router) {
        this.userContext = userContext;
        this.router = router;
    }
    CustomerJobActivator.prototype.canActivate = function (route) {
        // if user context is not initialized (in case of error on login) => forbid to open any page
        if (!this.userContext.isInitialized()) {
            return false;
        }
        else if (getRoutePath(route) === '') {
            // if root url => navigate to the first job
            if (this.userContext.jobs.length > 0) {
                this.router.navigateByUrl("/jobs/" + this.userContext.currentJob.jobId + "/shared-docs");
                return false;
            }
            else {
                return true;
            }
        }
        else if (route.data.isAcceptInvitation) {
            // if acceptInvitation => navigate to the first job
            if (this.userContext.jobs.length > 0) {
                this.router.navigateByUrl("/jobs/" + this.userContext.currentJob.jobId + "/shared-docs");
            }
            else {
                this.router.navigateByUrl('');
            }
            return false;
        }
        else {
            var jobId = getInheritedRouteParam(route, 'jobId');
            if (jobId) {
                if (this.userContext.isJobExist(jobId)) {
                    this.userContext.setCurrentJob(jobId);
                    return true;
                }
                else {
                    this.router.navigateByUrl('/404');
                    return false;
                }
            }
            else {
                return true;
            }
        }
    };
    CustomerJobActivator.ɵfac = function CustomerJobActivator_Factory(t) { return new (t || CustomerJobActivator)(i0.ɵɵinject(i1.CustomerUserContext), i0.ɵɵinject(i2.AppRouter)); };
    CustomerJobActivator.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerJobActivator, factory: CustomerJobActivator.ɵfac });
    return CustomerJobActivator;
}());
export { CustomerJobActivator };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerJobActivator, [{
        type: Injectable
    }], function () { return [{ type: i1.CustomerUserContext }, { type: i2.AppRouter }]; }, null); })();
