/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { ExtNavigationItemComponent } from './ext-navigation-item.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
function ExtNavigationComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 2);
} }
var _c0 = [[["gh-ext-navigation-item", "align", "start"]], [["gh-ext-navigation-item", "align", "end"]]];
var _c1 = ["gh-ext-navigation-item[align=start]", "gh-ext-navigation-item[align=end]"];
var ExtNavigationComponent = /** @class */ (function () {
    function ExtNavigationComponent() {
    }
    Object.defineProperty(ExtNavigationComponent.prototype, "hasOptions", {
        get: function () {
            return this.items.some(function (item) { return item.type === 'option'; });
        },
        enumerable: true,
        configurable: true
    });
    ExtNavigationComponent.ɵfac = function ExtNavigationComponent_Factory(t) { return new (t || ExtNavigationComponent)(); };
    ExtNavigationComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtNavigationComponent, selectors: [["gh-ext-navigation"]], contentQueries: function ExtNavigationComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
            i0.ɵɵcontentQuery(dirIndex, ExtNavigationItemComponent, false);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
        } }, ngContentSelectors: _c1, decls: 4, vars: 1, consts: [[1, "m-gap"], ["class", "m-option-divider", 4, "ngIf"], [1, "m-option-divider"]], template: function ExtNavigationComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef(_c0);
            i0.ɵɵprojection(0);
            i0.ɵɵelement(1, "div", 0);
            i0.ɵɵprojection(2, 1);
            i0.ɵɵtemplate(3, ExtNavigationComponent_div_3_Template, 1, 0, "div", 1);
        } if (rf & 2) {
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngIf", ctx.hasOptions);
        } }, directives: [i1.NgIf], styles: ["@media (max-width: 959px) {\n  [_nghost-%COMP%] {\n    display: inline-flex;\n    flex-direction: column;\n    padding: 30px 0; }\n  .m-gap[_ngcontent-%COMP%] {\n    height: 60px; }\n  .m-option-divider[_ngcontent-%COMP%] {\n    height: 2px;\n    margin: 50px 0 20px 50px;\n    background: rgba(255, 255, 255, 0.36); } }\n\n@media (min-width: 960px) and (max-width: 1279px) {\n  [_nghost-%COMP%] {\n    padding: 10px 0; } }\n\n@media (min-width: 600px) and (max-width: 959px) {\n  [_nghost-%COMP%] {\n    padding-right: 20px; } }\n\n@media (min-width: 960px) {\n  [_nghost-%COMP%] {\n    display: flex;\n    flex-direction: row; }\n  .m-gap[_ngcontent-%COMP%] {\n    flex: 1; } }\n\n@media (min-width: 1280px) {\n  [_nghost-%COMP%] {\n    padding: 15px 0; } }"], changeDetection: 0 });
    return ExtNavigationComponent;
}());
export { ExtNavigationComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtNavigationComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-navigation',
                templateUrl: 'ext-navigation.component.html',
                styleUrls: ['ext-navigation.component.scss'],
                changeDetection: ChangeDetectionStrategy.OnPush,
            }]
    }], null, { items: [{
            type: ContentChildren,
            args: [ExtNavigationItemComponent]
        }] }); })();
