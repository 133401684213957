/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageBasket } from '@gh/core-messages';
import { FileInvalidEvent, InvalidFileReason, NotificationService, WaitingService } from '@gh/core-ui';
import { acceptor, parseByteSize } from '@gh/core-util';
import { constant, isNil } from 'lodash';
import { ExtUploaderService } from '../../services/ext-uploader.service';

@Component({
  selector: 'gh-ext-uploader',
  templateUrl: 'ext-uploader.component.html',
  styleUrls: ['ext-uploader.component.scss'],
})
export class ExtUploaderComponent {
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() url: string;

  // tslint:disable-next-line:no-output-named-after-standard-event
  @Output() invalid = new EventEmitter<FileInvalidEvent>();
  // tslint:disable-next-line:no-output-named-after-standard-event
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  private _maxSize: number;
  private _accept: string = '';
  private acceptor: PredicateFn<File> = constant(true);

  constructor(
    private waitings: WaitingService,
    private notifications: NotificationService,
    private uploaderService: ExtUploaderService,
  ) {
  }

  @Input()
  set maxSize(maxSize: string) {
    this._maxSize = parseByteSize(maxSize);
  }

  @Input()
  set accept(accept: string) {
    this._accept = accept;
    this.acceptor = acceptor(accept);
  }

  get accept(): string {
    return this._accept;
  }

  onSelect(file?: File): void {
    if (isNil(file)) {
      return;
    }

    if (this._maxSize && file.size > this._maxSize) {
      this.invalid.emit({ reason: InvalidFileReason.TooLarge, file });
      return;
    } else if (!this.acceptor(file)) {
      this.invalid.emit({ reason: InvalidFileReason.NotAccepted, file });
      return;
    }

    this.uploaderService
      .upload(this.url, file).pipe((this.waitings.operator()))
      .subscribe(
        (response: any) => this.success.emit(response),
        (error: MessageBasket) => this.notifications.processAll(error),
      );
  }
}
