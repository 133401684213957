<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->
<div class="file-container" title="{{'lbl_browse' | translate}}">
  <input type="file" #fileInput="" class="file-input" [class.is-hidden]="!openByClick" [accept]="accept" (change)="onSelect($event.target.files[0])" [disabled]="disabled">
  <div class="flex-row">
    <div *ngIf="useUploadIcon" class="file-icon squared-icon">
      <mat-icon svgIcon="gh:vertical-align-top"></mat-icon>
    </div>
    <mat-form-field class="file-name">
      <input placeholder="{{'lbl_file_name' | translate}}" readonly="" [value]="fileName" matInput="" [disabled]="disabled">
    </mat-form-field>
  </div>
  <div *ngIf="progress != null" class="m-progress">{{ progress }}%</div>
</div>
<button type="button" mat-icon-button="" (click)="reset()" data-automation-id="single-uploader:delete" *ngIf="displayDeleteButton">
  <mat-icon class="s20" svgIcon="{{ deleteIcon }}"></mat-icon>
</button>
<a mat-icon-button="" [href]="item.location" target="_blank" data-automation-id="single-uploader:download" *ngIf="displayDownloadButton">
  <mat-icon class="s20" svgIcon="gh:cloud-queue"></mat-icon>
</a>
