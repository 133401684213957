/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNotNil } from '@gh/core-util';
import { isEqual, isString, noop } from 'lodash';
import { createTextMaskInputElement } from 'text-mask-core';
import { QuantityTypeBase } from '../core';
import { InputState, TypeService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services";
var FOCUSED_QUANTITY_FORMAT_QUERY = {
    noLabels: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
};
function isMinusOnly(str) {
    return str === '-';
}
/**
 * This module declares directive for quantity inputs. We had to define own implementation because it provides
 * better integration with number type approach (core-type package)
 *
 * Implementation was taken from this repository https://github.com/text-mask/text-mask.
 * File: https://github.com/text-mask/text-mask/blob/master/angular2/src/angular2TextMask.ts
 */
var QuantityTypeDirective = /** @class */ (function () {
    function QuantityTypeDirective(renderer, element, typeService, inputState) {
        this.renderer = renderer;
        this.element = element;
        this.typeService = typeService;
        this.inputState = inputState;
        this.formatOnFocus = true;
        this._onTouched = noop;
        this._onChange = noop;
    }
    Object.defineProperty(QuantityTypeDirective.prototype, "type", {
        set: function (type) {
            this._type = isString(type) ? this.typeService.get(type) : type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuantityTypeDirective.prototype, "isNumberBased", {
        get: function () {
            return this._type.base === QuantityTypeBase.Number;
        },
        enumerable: true,
        configurable: true
    });
    QuantityTypeDirective.prototype.ngOnInit = function () {
        if (this.isNumberBased) {
            this.setupMask();
        }
    };
    QuantityTypeDirective.prototype.onInput = function (str) {
        if (this.isNumberBased) {
            this.textMaskInputElement.update(str);
        }
        var nextStrValue = this.element.nativeElement.value;
        if (this.lastStrValue !== nextStrValue) {
            this.lastStrValue = nextStrValue;
            if (nextStrValue && !isMinusOnly(nextStrValue)) {
                var nextValue = this._type.parse(nextStrValue);
                if (!isEqual(this.lastValue, nextValue)) {
                    this.lastValue = nextValue;
                    this._onChange(nextValue);
                }
            }
            else {
                this.lastValue = void 0;
                this._onChange(void 0);
            }
        }
    };
    QuantityTypeDirective.prototype.onFocus = function () {
        var element = this.element.nativeElement;
        if (this.formatOnFocus && isNotNil(this.lastValue)) {
            element.value = this._type.format(this.lastValue, FOCUSED_QUANTITY_FORMAT_QUERY);
            if (!this.inputState.mouseDown) {
                element.select();
            }
        }
    };
    QuantityTypeDirective.prototype.onBlur = function () {
        if (isNotNil(this.lastValue)) {
            this.element.nativeElement.value = this._type.format(this.lastValue);
        }
        this._onTouched();
    };
    QuantityTypeDirective.prototype.writeValue = function (value) {
        this.lastValue = value;
        if (this.isNumberBased) {
            if (isNotNil(value) && this.textMaskInputElement !== void 0) {
                this.textMaskInputElement.update(this._type.format(value));
            }
            else {
                this.element.nativeElement.value = '';
            }
            this.lastStrValue = this.element.nativeElement.value;
        }
        else {
            var strValue = value ? this._type.format(value) : '';
            this.lastStrValue = strValue;
            this.element.nativeElement.value = strValue;
        }
    };
    QuantityTypeDirective.prototype.registerOnChange = function (fn) {
        this._onChange = fn;
    };
    QuantityTypeDirective.prototype.registerOnTouched = function (fn) {
        this._onTouched = fn;
    };
    QuantityTypeDirective.prototype.setDisabledState = function (isDisabled) {
        this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
    };
    QuantityTypeDirective.prototype.setupMask = function () {
        this.textMaskInputElement = createTextMaskInputElement({
            inputElement: this.element.nativeElement,
            mask: this._type['mask'],
        });
    };
    QuantityTypeDirective.ɵfac = function QuantityTypeDirective_Factory(t) { return new (t || QuantityTypeDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.TypeService), i0.ɵɵdirectiveInject(i1.InputState)); };
    QuantityTypeDirective.ɵdir = i0.ɵɵdefineDirective({ type: QuantityTypeDirective, selectors: [["", "ghQuantityType", ""]], hostBindings: function QuantityTypeDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("input", function QuantityTypeDirective_input_HostBindingHandler($event) { return ctx.onInput($event.target.value); })("focus", function QuantityTypeDirective_focus_HostBindingHandler() { return ctx.onFocus(); })("blur", function QuantityTypeDirective_blur_HostBindingHandler() { return ctx.onBlur(); });
        } }, inputs: { formatOnFocus: ["ghQuantityTypeFormatOnFocus", "formatOnFocus"], type: ["ghQuantityType", "type"] }, features: [i0.ɵɵProvidersFeature([{
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: QuantityTypeDirective,
                    multi: true,
                }])] });
    return QuantityTypeDirective;
}());
export { QuantityTypeDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(QuantityTypeDirective, [{
        type: Directive,
        args: [{
                selector: '[ghQuantityType]',
                providers: [{
                        provide: NG_VALUE_ACCESSOR,
                        useExisting: QuantityTypeDirective,
                        multi: true,
                    }],
            }]
    }], function () { return [{ type: i0.Renderer2 }, { type: i0.ElementRef }, { type: i1.TypeService }, { type: i1.InputState }]; }, { formatOnFocus: [{
            type: Input,
            args: ['ghQuantityTypeFormatOnFocus']
        }], type: [{
            type: Input,
            args: ['ghQuantityType']
        }], onInput: [{
            type: HostListener,
            args: ['input', ['$event.target.value']]
        }], onFocus: [{
            type: HostListener,
            args: ['focus']
        }], onBlur: [{
            type: HostListener,
            args: ['blur']
        }] }); })();
