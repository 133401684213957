/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component } from '@angular/core';

@Component({
  selector: 'gh-ext-dialog-content',
  template: '<ng-content></ng-content>',
  styleUrls: [],
})
export class ExtDialogContentComponent {
}
