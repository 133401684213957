/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, OnDestroy } from '@angular/core';
import { of$, throwError$ } from '@gh/rx/index';
import { filter$, first$, switchMap$ } from '@gh/rx/operators';
import { assign, omit, values } from 'lodash';
import { Observable } from 'rxjs';
import { UploadMultiSlot } from './upload-multi-slot';
import { UploadSlot } from './upload-slot';
import {
  FileInfo,
  isUploadFinalEvent,
  isUploadCompleted,
  UploadEngine,
  UploadEvent,
  UploadEventType,
  UploadItem,
} from './upload.interfaces';

/**
 * This service controls upload processes. Actually this service is a facade around {@link UploadEngine}
 * which provides actual upload processing. {@link UploadContext} provides additional convenient features
 * for application, like scoped uploads or upload slots.
 *
 * Each upload process has corresponding id (upload ID) assigned by the underlying {@link UploadEngine}.
 * Upload ID allows {@link UploadEngine} to track upload process and associate process with the corresponding entity.
 *
 * Upload process is not restricted by the scope of uploader directive/component.
 * It means upload process should not stop when corresponding component is destroyed.
 *
 * This service can be scoped to the page component. In this case all upload processes will be destroyed
 * as soon as user leaves from the page. In order to scope this service to page component just define it
 * in providers/viewProviders section of the corresponding component.
 */
@Injectable()
export class UploadContext implements OnDestroy {
  started: Hash<UploadItem> = {};

  event$: Observable<UploadEvent>;

  constructor(private engine: UploadEngine) {
    this.init();
  }

  createUploadSlot(params: Hash<any> = {}): UploadSlot {
    return new UploadSlot(this.engine, params);
  }

  createUploadMultiSlot(params: Hash<any> = {}): UploadMultiSlot {
    return new UploadMultiSlot(this.engine, params);
  }

  upload(item: UploadItem, params: Hash<any> = {}): Observable<UploadItem> {
    const slot = this.createUploadSlot(params);
    slot.assign(item);
    slot.start();
    return slot.event$.pipe(
      filter$(isUploadFinalEvent),
      // first() is used here just in case to fix potential reason for GJG-11163
      // There is no full confidence that underlying uploading engine fires error/cancelled events only once
      first$(),
      switchMap$((event) => isUploadCompleted(event.item) ? of$(event.item) : throwError$(event.error)));
  }

  create(file: FileInfo): UploadItem {
    return this.engine.create(file);
  }

  start(item: UploadItem, params: Hash<any>): void {
    this.engine.start(item, params);
  }

  cancel(item: UploadItem): void {
    this.engine.cancel(item);
  }

  /**
   * Upload context can be scoped to the page component.
   * In this case when attached component is destroyed Angular will also call this method.
   */
  ngOnDestroy(): void {
    values(this.started).forEach((item) => this.cancel(item));
  }

  private init(): void {
    const { event$ } = this.engine;

    const started$ = event$.pipe(
      filter$((event) => event.type === UploadEventType.Started));

    const completed$ = event$.pipe(filter$(isUploadFinalEvent));

    started$.subscribe(({ item }) => {
      assign(this.started, { [<string>item.uploadId]: item });
    });

    completed$.subscribe(({ item }) => {
      this.started = <any>omit(this.started, <string>item.uploadId);
    });

    this.event$ = event$;
  }

}
