/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import * as invariant from 'invariant';
import { isNil, isString, noop } from 'lodash';
import { Type } from '../core';
import { TypeService } from '../services';

@Directive({
  selector: '[ghDataType]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DataTypeDirective,
    multi: true,
  }, {
    provide: NG_VALIDATORS,
    useExisting: DataTypeDirective,
    multi: true,
  }],
})
export class DataTypeDirective implements OnInit, ControlValueAccessor, Validator {
  _type: Type<any>;

  private formatErrors?: ValidationErrors;

  private _onChange = noop;

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef,
              private typeService: TypeService) {
  }

  @Input('ghDataType')
  set type(type: Type<any> | string) {
    this._type = isString(type) ? this.typeService.get(type) : type;
  }

  ngOnInit(): void {
    if (this._type['maxLength']) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'maxlength', String(this._type['maxLength']));
    }

    invariant(this._type, 'Type is undefined for ghDataType directive');

  }

  @HostListener('input', ['$event.target.value'])
  onChange(str: string): void {
    if (str) {
      this.formatErrors = this._type.validateFormat(str);

      if (!this.formatErrors) {
        this._onChange(this._type.parse(str));
      } else {
        this._onChange(void 0);
      }
    } else {
      this.formatErrors = undefined;

      this._onChange(void 0);
    }
  }

  @HostListener('blur')
  onTouched(): void {
    // nothing to do
  }

  writeValue(value: any): void {
    const normalizedValue = isNil(value) ? '' : this._type.format(value);
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', normalizedValue);
    this.formatErrors = undefined;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  validate(c: AbstractControl): { [p: string]: any } {
    return <any>this.formatErrors;
  }
}
