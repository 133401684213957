/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Locale } from '../locale';
import { TranslationService } from '../translation/translation.service';
import { DateTimeFormatImpl, DateTimeParserImpl } from './date-time-l10n';
import './import-locale-data'; // tslint:disable-line:no-import-side-effect
import { NumberFormatImpl, NumberParserImpl } from './number-l10n';
import { StringL10nHelper } from './string-l10n-helper';
import * as i0 from "@angular/core";
import * as i1 from "../translation/translation.service";
import * as i2 from "@angular/common";
/**
 * Implementation of {@link LocalizationProvider} interface.
 * Look at {@link LocalizationProvider} interface for details.
 */
var LocalizationService = /** @class */ (function () {
    function LocalizationService(translationService, numberPipe, datePipe) {
        this.translationService = translationService;
        this.numberPipe = numberPipe;
        this.datePipe = datePipe;
        var localization = this.translationService.getRaw('localization');
        this.numberLocalization = {
            groupSeparator: localization.numberFormat.groupSeparator,
            decimalSeparator: localization.numberFormat.decimalSeparator,
            grouping: !!localization.numberFormat.grouping,
        };
        this.dateLocalization = localization.dateFormat;
        this.quantityLocalization = { formats: localization.quantityFormat };
        this.locale = Locale.create(localization.effectiveLocale);
        this.numberParser = new NumberParserImpl(this.numberLocalization);
        this.numberFormat = new NumberFormatImpl(this.locale, this.numberPipe);
        this.dateTimeParser = new DateTimeParserImpl(this.dateLocalization.inputPattern);
        this.dateTimeFormat = new DateTimeFormatImpl(this.locale, this.dateLocalization.styles, this.datePipe);
        this.stringL10nHelper = new StringL10nHelper(this.locale);
    }
    LocalizationService.prototype.getEffectiveLocale = function () {
        return this.locale;
    };
    LocalizationService.prototype.getNumberLocalization = function () {
        return this.numberLocalization;
    };
    LocalizationService.prototype.getDateLocalization = function () {
        return this.dateLocalization;
    };
    LocalizationService.prototype.getQuantityLocalization = function () {
        return this.quantityLocalization;
    };
    LocalizationService.prototype.getNumberFormat = function () {
        return this.numberFormat;
    };
    LocalizationService.prototype.getDateTimeFormat = function () {
        return this.dateTimeFormat;
    };
    LocalizationService.prototype.getNumberParser = function () {
        return this.numberParser;
    };
    LocalizationService.prototype.getDateTimeParser = function () {
        return this.dateTimeParser;
    };
    LocalizationService.prototype.getStringL10nHelper = function () {
        return this.stringL10nHelper;
    };
    LocalizationService.ɵfac = function LocalizationService_Factory(t) { return new (t || LocalizationService)(i0.ɵɵinject(i1.TranslationService), i0.ɵɵinject(i2.DecimalPipe), i0.ɵɵinject(i2.DatePipe)); };
    LocalizationService.ɵprov = i0.ɵɵdefineInjectable({ token: LocalizationService, factory: LocalizationService.ɵfac });
    return LocalizationService;
}());
export { LocalizationService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LocalizationService, [{
        type: Injectable
    }], function () { return [{ type: i1.TranslationService }, { type: i2.DecimalPipe }, { type: i2.DatePipe }]; }, null); })();
