/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, ContentChildren, QueryList } from '@angular/core';
import { MessageRepository } from '../../message';

import { MessageDirective } from './message.directive';

@Component({
  selector: 'gh-message-repository',
  template: '',
})
export class MessageRepositoryComponent implements MessageRepository {
  @ContentChildren(MessageDirective) messages: QueryList<MessageDirective>;

  getText(key: string, params: any): string {
    const message = this.messages.find((msg) => msg.key === key);

    return message && message.value || '';
  }
}
