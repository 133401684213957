/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { MatMenu, MatMenuPanel } from '@angular/material/menu';
import { toPx } from '@gh/core-util';
import { Subscription } from 'rxjs';

/**
 * This code was mainly copy-pasted from @angular/material library (MdMenuTrigger directive)
 */
@Directive({
  selector: `[ghContextMenuTrigger]`,
})
export class ContextMenuTriggerDirective implements AfterViewInit, OnDestroy {
  /** References the menu instance that the trigger is associated with. */
  @Input('ghContextMenuTrigger') menu: MatMenuPanel;

  private _portal: TemplatePortal<any>;
  private overlayRef: Maybe<OverlayRef>;
  private _menuOpen: boolean = false;
  private _backdropSubscription: Subscription;

  constructor(private overlay: Overlay, private element: ElementRef,
              private viewContainerRef: ViewContainerRef) {
  }

  ngAfterViewInit(): void {
    this.menu.close.subscribe(() => this.closeMenu());
  }

  ngOnDestroy(): void {
    this.destroyMenu();
  }

  /** Opens the menu. */
  openMenu(event: MouseEvent): void {
    if (!this._menuOpen) {
      this._createOverlay(event).attach(this._portal);
      this._subscribeToBackdrop();
      this._initMenu();

      if (this.menu instanceof MatMenu) {
        this.menu._startAnimation();
      }
    }
  }

  /** Closes the menu. */
  closeMenu(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this._backdropSubscription.unsubscribe();
      this._resetMenu();
    }
  }

  /** Removes the menu from the DOM. */
  destroyMenu(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = void 0;

      this._cleanUpSubscriptions();
    }
  }

  /** Focuses the menu trigger. */
  focus(): void {
    this.element.nativeElement.focus();
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: MouseEvent): void {
    event.preventDefault();
    this.openMenu(event);
  }

  /**
   * This method ensures that the menu closes when the overlay backdrop is clicked.
   * We do not use first() here because doing so would not catch clicks from within
   * the menu, and it would fail to unsubscribe properly. Instead, we unsubscribe
   * explicitly when the menu is closed or destroyed.
   */
  private _subscribeToBackdrop(): void {
    if (this.overlayRef) {
      this._backdropSubscription = this.overlayRef.backdropClick().subscribe(() => {
        this.menu.close.emit();
      });
    }
  }

  /**
   * This method sets the menu state to open and focuses the first item if
   * the menu was opened via the keyboard.
   */
  private _initMenu(): void {
    this._setIsMenuOpen(true);
    this.menu.focusFirstItem();
  }

  /**
   * This method resets the menu when it's closed, most importantly restoring
   * focus to the menu trigger if the menu was opened via the keyboard.
   */
  private _resetMenu(): void {
    this._setIsMenuOpen(false);
  }

  // set state rather than toggle to support triggers sharing a menu
  private _setIsMenuOpen(isOpen: boolean): void {
    this._menuOpen = isOpen;
  }

  /**
   *  This method creates the overlay from the provided menu's template and saves its
   *  OverlayRef so that it can be attached to the DOM when openMenu is called.
   */
  private _createOverlay(event: MouseEvent): OverlayRef {
    if (!this.overlayRef) {
      this._portal = new TemplatePortal(this.menu.templateRef, this.viewContainerRef);
      const config = this._getOverlayConfig(event);
      this.overlayRef = this.overlay.create(config);
    } else {
      this.reposition(<GlobalPositionStrategy>this.overlayRef.getConfig().positionStrategy, event);
    }

    return this.overlayRef;
  }

  /**
   * This method builds the configuration object needed to create the overlay, the OverlayConfig.
   * @returns OverlayConfig
   */
  private _getOverlayConfig(event: MouseEvent): OverlayConfig {
    const overlayConfig = new OverlayConfig();
    overlayConfig.positionStrategy = this._getPosition(event);
    overlayConfig.hasBackdrop = true;
    overlayConfig.backdropClass = 'cdk-overlay-transparent-backdrop';
    overlayConfig.scrollStrategy = this.overlay.scrollStrategies.reposition();
    return overlayConfig;
  }

  private _getPosition(event: MouseEvent): GlobalPositionStrategy {
    return this.overlay.position()
      .global()
      .left(toPx(event.clientX))
      .top(toPx(event.clientY));
  }

  private reposition(positionStrategy: GlobalPositionStrategy, event: MouseEvent): void {
    positionStrategy
      .left(toPx(event.clientX))
      .top(toPx(event.clientY));
  }

  private _cleanUpSubscriptions(): void {
    if (this._backdropSubscription) {
      this._backdropSubscription.unsubscribe();
    }
  }

}
