var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { parseUrl, stringifyUrl } from '@gh/core-util';
import { from$, of$ } from '@gh/rx';
import { identity } from '../mappers';
import { RouteTracker } from './route-tracker';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/**
 * This service allows to request and save dynamic data associated with routes.
 *
 * Service supports two kinds of data association:
 * - data can be associated with the route by its url and query parameters (current route)
 * - data can be associated with the state id attached to the current route as a parameter (savepoint)
 *
 */
var RouteDataService = /** @class */ (function () {
    function RouteDataService(router) {
        this.router = router;
    }
    RouteDataService.prototype.tracker = function () {
        var filters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            filters[_i] = arguments[_i];
        }
        return new RouteTracker(this.router, filters, [], []);
    };
    /**
     * Merges provided <code>data</code> into the query parameters of current route
     */
    RouteDataService.prototype.mergeQueryParams = function (data, mapper) {
        if (mapper === void 0) { mapper = identity(); }
        var url = this.router.routerState.snapshot.url;
        var _a = parseUrl(url), pathname = _a.pathname, query = _a.query, hash = _a.hash;
        var serializedData = mapper.serialize(data);
        var nextUrl = stringifyUrl({ pathname: pathname, query: __assign(__assign({}, query), serializedData), hash: hash });
        return nextUrl === url ? of$(true) : from$(this.router.navigateByUrl(nextUrl, {
            replaceUrl: true,
        }));
    };
    RouteDataService.ɵfac = function RouteDataService_Factory(t) { return new (t || RouteDataService)(i0.ɵɵinject(i1.Router)); };
    RouteDataService.ɵprov = i0.ɵɵdefineInjectable({ token: RouteDataService, factory: RouteDataService.ɵfac });
    return RouteDataService;
}());
export { RouteDataService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(RouteDataService, [{
        type: Injectable
    }], function () { return [{ type: i1.Router }]; }, null); })();
