/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, ContentChildren, QueryList } from '@angular/core';
import { MessageDirective } from './message.directive';
import * as i0 from "@angular/core";
var MessageRepositoryComponent = /** @class */ (function () {
    function MessageRepositoryComponent() {
    }
    MessageRepositoryComponent.prototype.getText = function (key, params) {
        var message = this.messages.find(function (msg) { return msg.key === key; });
        return message && message.value || '';
    };
    MessageRepositoryComponent.ɵfac = function MessageRepositoryComponent_Factory(t) { return new (t || MessageRepositoryComponent)(); };
    MessageRepositoryComponent.ɵcmp = i0.ɵɵdefineComponent({ type: MessageRepositoryComponent, selectors: [["gh-message-repository"]], contentQueries: function MessageRepositoryComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
            i0.ɵɵcontentQuery(dirIndex, MessageDirective, false);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.messages = _t);
        } }, decls: 0, vars: 0, template: function MessageRepositoryComponent_Template(rf, ctx) { }, encapsulation: 2 });
    return MessageRepositoryComponent;
}());
export { MessageRepositoryComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MessageRepositoryComponent, [{
        type: Component,
        args: [{
                selector: 'gh-message-repository',
                template: '',
            }]
    }], null, { messages: [{
            type: ContentChildren,
            args: [MessageDirective]
        }] }); })();
