/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { AuthProvider, AuthState } from '@gh/core-auth';

import { isFunction } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';

import { KeycloakService } from './keycloak.service';

export const KEYCLOAK_LOGIN_OPTIONS = new InjectionToken('KeycloakLoginOptions');
export const KEYCLOAK_LOGOUT_OPTIONS = new InjectionToken('KeycloakLogoutOptions');

@Injectable()
export class KeycloakAuthProvider extends AuthProvider {
  private _authState = new ReplaySubject<AuthState>(1);
  private _currentAuthState: AuthState = AuthState.Unknown;

  constructor(private keycloak: KeycloakService,
              @Optional() @Inject(KEYCLOAK_LOGIN_OPTIONS) private loginOptions?: KeycloakModule.LoginOptions,
              // tslint:disable-next-line:max-line-length
              @Optional() @Inject(KEYCLOAK_LOGOUT_OPTIONS) private logoutOptions?: KeycloakModule.RedirectUriOptions | RetFn<KeycloakModule.RedirectUriOptions>) {

    super();

    keycloak.init().then((authenticated) => {
      this.bindAuthState();

      if (authenticated) {
        this._authState.next(AuthState.LoggedIn);
        return keycloak.getToken();
      }
      this._authState.next(AuthState.Unknown);
      return '';
    });
  }

  login(): Promise<boolean> {
    return this.keycloak.login(this.loginOptions);
  }

  logout(): Promise<boolean> {
    const { logoutOptions } = this;
    return this.keycloak.logout(isFunction(logoutOptions) ? logoutOptions() : logoutOptions);
  }

  get authState(): AuthState {
    return this._currentAuthState;
  }

  get onAuthState(): Observable<AuthState> {
    return this._authState;
  }

  private bindAuthState(): void {
    this.keycloak.onAuthSuccess.subscribe(() => this._authState.next(AuthState.LoggedIn));
    this.keycloak.onAuthError.subscribe(() => this._authState.next(AuthState.LogggedOut));
    this.keycloak.onAuthLogout.subscribe(() => this._authState.next(AuthState.LogggedOut));
    this.keycloak.onAuthRefreshSuccess.subscribe(() => this._authState.next(AuthState.LoggedIn));
    this.keycloak.onAuthRefreshError.subscribe(() => this._authState.next(AuthState.Expired));

    this._authState.subscribe((authState) => {
      this._currentAuthState = authState;
    });
  }

}
