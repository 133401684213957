/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

const USERNAME_CHARACTER_RE = /^[a-zA-Z0-9\-_.]$/;
const USERNAME_MIDDLE_CHARACTER_RE = /^[a-zA-Z0-9\-_]$/;
const USERNAME_NOT_REPEATED_CHARACTER_RE = /^[\-_.]$/;

export const cleanUserName = (name: string): string => {
  const lastNameIndex = name.length - 1;
  const normalized: string[] = [];

  let i = 0;
  let lastCh: string = '';

  while (i <= lastNameIndex) {
    const ch = name[i];

    if (USERNAME_CHARACTER_RE.test(ch)) {
      // do not allow to repeat '-', '_' or '.' characters
      if (USERNAME_NOT_REPEATED_CHARACTER_RE.test(ch) && ch === lastCh) {
        continue;
      }
      // do not allow dot ('.') in the start and end of a string
      if (!USERNAME_MIDDLE_CHARACTER_RE.test(ch) && (i === 0 || i === lastNameIndex)) {
        continue;
      }

      normalized.push(ch);
    }

    lastCh = ch;
    i++;
  }

  return normalized.join('');
};

export const isUserNameValid = (name: string): boolean => {
  const lastNameIndex = name.length - 1;

  let i = 0;
  let lastCh: string = '';

  while (i <= lastNameIndex) {
    const ch = name[i];

    if (USERNAME_CHARACTER_RE.test(ch)) {
      // do not allow to repeat '-', '_' or '.' characters
      if (USERNAME_NOT_REPEATED_CHARACTER_RE.test(ch) && ch === lastCh) {
        return false;
      }
      // do not allow dot ('.') in the start and end of a string
      if (!USERNAME_MIDDLE_CHARACTER_RE.test(ch) && (i === 0 || i === lastNameIndex)) {
        return false;
      }
    } else {
      return false;
    }

    lastCh = ch;
    i++;
  }

  return true;
};
