/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ChangeDetectorRef, Component, TemplateRef } from '@angular/core';

@Component({
  selector: 'gh-template-site',
  template: `
    <div [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="context"></div>`,
})
export class TemplateSiteComponent<C> {
  templateRef: TemplateRef<any>;
  context: any;

  constructor(private changeDetectorRef: ChangeDetectorRef) {

  }

  setTemplateRef(templateRef: TemplateRef<any>): void {
    this.templateRef = templateRef;
  }

  setContext(context: any): void {
    this.context = context;
    this.changeDetectorRef.markForCheck();
  }
}
