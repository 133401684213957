/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { isFunction, isNil } from 'lodash';

@Directive({
  selector: 'input[ghMapValue]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MapValueDirective,
    multi: true,
  }],
})
export class MapValueDirective implements ControlValueAccessor {
  @Input('ghMapValue') map: string | TransformFn<any, string>;

  constructor(private elementRef: ElementRef) {

  }

  writeValue(value: any): void {
    if (isNil(value)) {
      this.elementRef.nativeElement.value = '';
    } else {
      const { map } = this;
      this.elementRef.nativeElement.value = isFunction(map) ? map(value) : value[map];
    }
  }

  registerOnChange(fn: any): void {
    // nothing to do
  }

  registerOnTouched(fn: any): void {
    // nothing to do
  }

  setDisabledState(isDisabled: boolean): void {
    // nothing to do
  }
}
