/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { TranslationService } from '@gh/core-mls';

@Injectable()
export class AppDatepickerIntl extends MatDatepickerIntl {
  calendarLabel = this.translationService.get('lbl_calendar');
  openCalendarLabel = this.translationService.get('lbl_open_calendar');
  prevMonthLabel = this.translationService.get('lbl_prev_month');
  nextMonthLabel = this.translationService.get('lbl_next_month');
  prevYearLabel = this.translationService.get('lbl_prev_year');
  nextYearLabel = this.translationService.get('lbl_next_year');
  switchToMonthViewLabel = this.translationService.get('lbl_switch_to_month_view');
  switchToYearViewLabel = this.translationService.get('lbl_switch_to_year_view');

  constructor(private translationService: TranslationService) {
    super();
  }
}
