/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterLinkWithHref } from '@angular/router';
import { filter$ } from '@gh/rx/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gh-ext-navigation-item',
  templateUrl: 'ext-navigation-item.component.html',
  styleUrls: ['ext-navigation-item.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '[class]': '"m-type-" + type',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtNavigationItemComponent implements AfterViewInit, OnDestroy {
  @Input() type: 'button' | 'link' | 'option' = 'button';
  @Input() title: string;
  @Input() icon: string;
  @Input() disabled: boolean;

  isLinkActive: boolean = false;

  @ViewChild(RouterLinkWithHref, { static: false }) linkWithHref: RouterLinkWithHref;

  private _link: string | any[];
  private subscription: Subscription;

  @Input()
  set link(link: string | any[]) {
    this._link = link;
    this.recalculateActiveLinkState();
  }

  get link(): string | any[] {
    return this._link;
  }

  get isTypeLink(): boolean {
    return this.type === 'link';
  }

  get isTypeButton(): boolean {
    return this.type === 'button';
  }

  get isTypeOption(): boolean {
    return this.type === 'option';
  }

  constructor(private router: Router,
              private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    if (this.isTypeLink) {
      this.recalculateActiveLinkState();
      this.subscription = this.router.events.pipe(
        filter$((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.recalculateActiveLinkState();
        });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private recalculateActiveLinkState(): void {
    if (this.isTypeLink && this.linkWithHref) {
      Promise.resolve().then(() => {
        this.isLinkActive = !this.disabled && this.router.isActive(this.linkWithHref.urlTree, false);
        this.cdr.markForCheck();
      });
    }
  }
}
