/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as invariant from 'invariant';
import { isNil, isString, noop } from 'lodash';
import { Type } from '../core';
import { TypeService } from '../services';

/**
 * This directive is assigned to date-based control to format value (date value).
 *
 * <b>Note!</b> This directive is not intended to be used for editable inputs.
 * If developer needs to enter date he should use MatDatepicker module from @angular/material library
 */
@Directive({
  selector: '[ghDateType]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DateTypeDirective,
    multi: true,
  }],
})
export class DateTypeDirective implements OnInit, ControlValueAccessor {
  _type: Type<any>;

  @HostBinding('attr.readonly')
  readonly = true;

  private _onChange = noop;

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef,
              private typeService: TypeService) {
  }

  @Input('ghDateType')
  set type(type: Type<any> | string) {
    this._type = isString(type) ? this.typeService.get(type) : type;
  }

  ngOnInit(): void {
    invariant(this._type, 'Type is undefined for ghDataType directive');

  }

  writeValue(value: any): void {
    const normalizedValue = isNil(value) ? '' : this._type.format(value);
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', normalizedValue);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    // nothing to do
  }
}
