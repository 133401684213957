/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, Input, OnInit } from '@angular/core';
import { ContainerState } from '../container-state';

/**
 * Binds given container state to the injector scope of the directive.
 * This is necessary to make container state available for child directives.
 */
@Directive({
  selector: '[ghContainerState]',
})
export class ContainerStateDirective implements OnInit {
  private _containerState: ContainerState<any>;

  @Input('ghContainerState')
  set containerState(formState: ContainerState<any>) {
    this._containerState = formState;
  }

  get containerState(): ContainerState<any> {
    return this._containerState;
  }

  ngOnInit(): void {
    if (!this._containerState) {
      throw new Error('Instance of ContainerState is not assigned to the "ghContainerState" directive');
    }
  }
}
