var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { distinctUntilChanged$, filter$, map$ } from '@gh/rx/operators';
import { without } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
var getDropTargetGroupKey = function (selector, engine) { return selector + ":" + engine; };
/**
 * {@link DndManager} manages all available drag sources and drop targets. Also it initiates communication between them.
 *
 * Different UI components support DnD operations. Each component can have its own implementation of DnD:
 * <ul>
 *   <li>based on native drag-and-drop browser capabilities</li>
 *   <li>emulated drag-and-drop capabilities by placing layer having fixed/absolute position over the rest page content
 *     and handling mouse/touch events manually.
 *     This solution does not have common approach: each component has its own implementation of DnD operations.</li>
 * </ul>
 * The problem here is to allow drag-and-drop between such components.
 *
 * The main purpose of this service is to allow DnD operations in heterogenous environment,
 * environment where each separate component supports DnD operation (or can support),
 * but uses different technical solution.
 *
 * DndManager manipulates by two kinds of entities: <code>DragSource</code> and <code>DropTarget</code>.
 * <code>DragSource</code> is a component which initiates dragging operation,
 * while <code>DropTarget</code> is a component which serves as a target for drag operation.
 *
 * Each <code>DragSource</code> or <code>DropTarget</code> belongs to specific <code>selector</code>
 * and <code>engine</code>.
 * Dragging between <code>DragSource</code> and <code>DropTarget</code> is possible only if they have the same
 * <code>selector</code> and <code>engine</code>.
 * <ul>
 *   <li><code>engine</code> defines technical stack used by DnD operation.
 *     For example, dragging initiated by tree component MUST BE implemented using native drag-and-drop capabilities,
 *     but dragging initiated by ag-grid component MUST be implemented by providing implementation of some internal
 *     ag-grid interfaces. This means dragging uses different technical stack (engines) under the hood.</li>
 *   <li><code>selector</code> defines direction of DnD operation.
 *     For example, developer has 3 grids and it needs to allow dragging from 1st to 2nd and from 2nd to 3rd,
 *     but dragging between 1st and 3rd is forbidden. In this case it can define separate <code>selector</code>s
 *     for 1st/2nd and for 2nd/3rd groups</li>
 * </ul>
 *
 * DndManager allows to create/remove <code>DragSource</code>s and register/unregister <code>DropTarget</code>s.
 * Each component interested in DnD operations can listen for changes in <code>DragSource<code>s
 * or <code>DropTarget<code>s if the satisfy given <code>selector</code> and/or <code>engine</code>,
 * look at {@link #listenDragSources} and {@link #listenDropTargets}.
 */
var DndManager = /** @class */ (function () {
    function DndManager() {
        var _this = this;
        this.sources$$ = new BehaviorSubject({});
        this.sourceAdded$$ = new Subject();
        this.sourceRemoved$$ = new Subject();
        this.dragging$$ = new Subject();
        this.targetAdded$$ = new Subject();
        this.targetRemoved$$ = new Subject();
        this.targets$$ = new BehaviorSubject({});
        this.dragging$$.subscribe(function (event) {
            var type = event.type, _a = event.source, selector = _a.selector, engine = _a.engine;
            var satisfiedTargets = _this.targets$$.value[getDropTargetGroupKey(selector, engine)] || [];
            satisfiedTargets.forEach(function (target) {
                switch (type) {
                    case 'start':
                        target.onDragStart(event);
                        break;
                    case 'stop':
                        target.onDragStop(event);
                        break;
                    default:
                        break;
                }
            });
        });
    }
    /**
     * Creates <code>DragSource</code> for specific <code>selector</code> and <code>engine</code>
     * and notifies all listening subscriptions.
     */
    DndManager.prototype.createDragSource = function (selector, engine) {
        var _a;
        var _this = this;
        var source = {
            selector: selector, engine: engine,
            startDrag: function (element) { return _this.dragging$$.next({ type: 'start', source: source, element: element }); },
            stopDrag: function () { return _this.dragging$$.next({ type: 'stop', source: source, element: void 0 }); },
        };
        var group = this.sources$$.value[selector] || [];
        if (!group.includes(source)) {
            this.sources$$.next(__assign(__assign({}, this.sources$$.value), (_a = {}, _a[selector] = group.concat([source]), _a)));
        }
        return source;
    };
    /**
     * Removes given <code>DragSource</code> and notifies all listening subscriptions.
     */
    DndManager.prototype.removeDragSource = function (source) {
        var _a;
        var group = this.sources$$.value[source.selector] || [];
        if (group.includes(source)) {
            this.sources$$.next(__assign(__assign({}, this.sources$$.value), (_a = {}, _a[source.selector] = without(group, source), _a)));
        }
    };
    /**
     * Create subscription for <code>DragSource</code>-related events.
     */
    DndManager.prototype.listenDragSources = function (selector) {
        return {
            sources: this.sources$$.pipe(map$(function (sources) { return sources[selector] || []; }), distinctUntilChanged$()),
            sourceAdded: this.sourceAdded$$.pipe(filter$(function (source) { return source.selector === selector; })),
            sourceRemoved: this.sourceRemoved$$.pipe(filter$(function (source) { return source.selector === selector; })),
        };
    };
    /**
     * Creates <code>DropTarget</code> for specific <code>selector</code> and <code>engine</code>
     * and notifies all listening subscriptions.
     */
    DndManager.prototype.addDropTarget = function (selector, target) {
        var _a;
        var groupKey = getDropTargetGroupKey(selector, target.engine);
        var group = this.targets$$.value[groupKey] || [];
        if (!group.includes(target)) {
            this.targets$$.next(__assign(__assign({}, this.targets$$.value), (_a = {}, _a[groupKey] = group.concat([target]), _a)));
        }
    };
    /**
     * Removes given <code>DropTarget</code> and notifies all listening subscriptions.
     */
    DndManager.prototype.removeDropTarget = function (selector, target) {
        var _a;
        var groupKey = getDropTargetGroupKey(selector, target.engine);
        var group = this.targets$$.value[groupKey] || [];
        if (group.includes(target)) {
            this.targets$$.next(__assign(__assign({}, this.targets$$.value), (_a = {}, _a[groupKey] = without(group, target), _a)));
        }
    };
    /**
     * Create subscription for <code>DropTarget</code>-related events.
     */
    DndManager.prototype.listenDropTargets = function (selector, engine) {
        return {
            targets: this.targets$$.pipe(map$(function (targets) { return targets[getDropTargetGroupKey(selector, engine)] || []; }), distinctUntilChanged$()),
            targetAdded: this.targetAdded$$.pipe(filter$(function (event) { return event.selector === selector && event.target.engine === engine; }), map$(function (event) { return event.target; })),
            targetRemoved: this.targetRemoved$$.pipe(filter$(function (event) { return event.selector === selector && event.target.engine === engine; }), map$(function (event) { return event.target; })),
        };
    };
    DndManager.ɵfac = function DndManager_Factory(t) { return new (t || DndManager)(); };
    DndManager.ɵprov = i0.ɵɵdefineInjectable({ token: DndManager, factory: DndManager.ɵfac });
    return DndManager;
}());
export { DndManager };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DndManager, [{
        type: Injectable
    }], function () { return []; }, null); })();
