/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  EXT_INFINITE_SCROLL_DIMENSIONS,
  EXT_INFINITE_SCROLL_VIEWPORT,
  ExtInfiniteScrollDimensions,
  ExtInfiniteScrollViewport,
} from '../ext-infinite-scroll.interfaces';

@Directive({
  selector: '[ghExtInfiniteTable]',
  providers: [
    { provide: EXT_INFINITE_SCROLL_DIMENSIONS, useExisting: ExtInfiniteTableViewerDirective },
  ],
})
export class ExtInfiniteTableViewerDirective implements ExtInfiniteScrollDimensions, OnInit, OnDestroy {
  @Input() offsetTop: number = 60;
  @Input() itemHeight: number = 50;
  @Input() loadThresholdCount: number = 10;
  @Input() pageSize: number = 50;

  constructor(private elementRef: ElementRef,
              @Inject(EXT_INFINITE_SCROLL_VIEWPORT) private viewport: ExtInfiniteScrollViewport) {
  }

  ngOnInit(): void {
    this.viewport.setDimensions(this.elementRef.nativeElement, this);
  }

  ngOnDestroy(): void {
    this.viewport.resetDimensions(this.elementRef.nativeElement, this);
  }
}
