var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { assign } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/**
 * This service holds the current snapshot of router state.
 */
var AppRouterState = /** @class */ (function () {
    function AppRouterState(router) {
        this.router = router;
    }
    /**
     * Updates internal state of this service
     *
     * @internal this method should not be used by developers
     */
    AppRouterState.prototype._update = function () {
        var root = this.router.routerState.snapshot.root;
        var queryParams = extractParams(root, function (route) { return route.queryParams; });
        var params = extractParams(root, function (route) { return route.params; });
        var parentRoute = queryParams.parentRoute, parentTitle = queryParams.parentTitle;
        assign(this, { parentRoute: parentRoute, parentTitle: parentTitle });
        assign(this, { queryParams: queryParams, params: params });
    };
    AppRouterState.ɵfac = function AppRouterState_Factory(t) { return new (t || AppRouterState)(i0.ɵɵinject(i1.Router)); };
    AppRouterState.ɵprov = i0.ɵɵdefineInjectable({ token: AppRouterState, factory: AppRouterState.ɵfac });
    return AppRouterState;
}());
export { AppRouterState };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppRouterState, [{
        type: Injectable
    }], function () { return [{ type: i1.Router }]; }, null); })();
/**
 * Recursively extracts parameters from route and its children.
 * Finally this method merges parameters into single object.
 *
 * @param route
 * @param extractor
 * @returns {T}
 */
function extractParams(route, extractor) {
    return assign.apply(void 0, __spreadArrays([{},
        extractor(route)], route.children.map(function (child) { return extractParams(child, extractor); })));
}
