/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:no-magic-numbers no-submodule-imports
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ConfigurationHttpRequestInterceptor, MaintenanceHttpResponseInterceptor } from '@gh/common-shared';
import { API_BASE_URL } from '@gh/config';
import { AUTH_HTTP_INTERCEPTOR } from '@gh/core-auth';
import { API_BASE_URL as API_BASE_URL_TOKEN } from '@gh/core-data';
import { MessageHttpErrorInterceptor, MessageHttpResponseInterceptor } from '@gh/core-messages';
import * as i0 from "@angular/core";
var CustomerHttpClientConfigModule = /** @class */ (function () {
    function CustomerHttpClientConfigModule() {
    }
    CustomerHttpClientConfigModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomerHttpClientConfigModule });
    CustomerHttpClientConfigModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomerHttpClientConfigModule_Factory(t) { return new (t || CustomerHttpClientConfigModule)(); }, providers: [
            {
                provide: API_BASE_URL_TOKEN,
                useValue: API_BASE_URL,
            },
            { provide: HTTP_INTERCEPTORS, useExisting: ConfigurationHttpRequestInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useExisting: AUTH_HTTP_INTERCEPTOR, multi: true },
            { provide: HTTP_INTERCEPTORS, useExisting: MessageHttpResponseInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useExisting: MessageHttpErrorInterceptor, multi: true },
            // { provide: HTTP_INTERCEPTORS, useExisting: LogoutHttpErrorInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useExisting: MaintenanceHttpResponseInterceptor, multi: true },
        ], imports: [[HttpClientModule]] });
    return CustomerHttpClientConfigModule;
}());
export { CustomerHttpClientConfigModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomerHttpClientConfigModule, { imports: [HttpClientModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerHttpClientConfigModule, [{
        type: NgModule,
        args: [{
                imports: [HttpClientModule],
                providers: [
                    {
                        provide: API_BASE_URL_TOKEN,
                        useValue: API_BASE_URL,
                    },
                    { provide: HTTP_INTERCEPTORS, useExisting: ConfigurationHttpRequestInterceptor, multi: true },
                    { provide: HTTP_INTERCEPTORS, useExisting: AUTH_HTTP_INTERCEPTOR, multi: true },
                    { provide: HTTP_INTERCEPTORS, useExisting: MessageHttpResponseInterceptor, multi: true },
                    { provide: HTTP_INTERCEPTORS, useExisting: MessageHttpErrorInterceptor, multi: true },
                    // { provide: HTTP_INTERCEPTORS, useExisting: LogoutHttpErrorInterceptor, multi: true },
                    { provide: HTTP_INTERCEPTORS, useExisting: MaintenanceHttpResponseInterceptor, multi: true },
                ],
                exports: [],
            }]
    }], null, null); })();
