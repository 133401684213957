/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers variable-name

import { REGION_CODE } from '@gh/config';
import { maskedTextType } from '@gh/core-type';

import { MASKED_EN_AU } from './localization/mask-en-au';
import { MASKED_EN_NZ } from './localization/mask-en-nz';
import { MASKED_EN_US } from './localization/mask-en-us';
import { digits, repeat } from './mask-utils';

const MASKED = {
  au: MASKED_EN_AU,
  nz: MASKED_EN_NZ,
  us: MASKED_EN_US,
}[REGION_CODE];

export const BaseMaskedTextType = maskedTextType({
  messages: {
    rangeLength: 'msg_field_invalid_format',
    maxLength: 'msg_max_value',
    pattern: 'msg_field_invalid_format',
  },
});

export const MaskedTypes = {
  Zip: maskedTextType(BaseMaskedTextType, {
    ...MASKED['Zip'],
  }),
  TaxFileNumber: maskedTextType(BaseMaskedTextType, {
    mask: [...digits(3), '-', ...digits(3), '-', ...digits(3)],
    constraints: {
      pattern: /^\d{3}-\d{3}-\d{3}$/,
    },
  }),
  AuthNumber: maskedTextType(BaseMaskedTextType, {
    mask: repeat(6, /[A-Z1-9]/i),
    constraints: {
      pattern: /^[A-Z1-9]{6}$/i,
    },
  }),
  SourceNumber: maskedTextType(BaseMaskedTextType, {
    mask: [...digits(6)],
    constraints: {
      pattern: /^\d{6}$/,
    },
  }),
  TaxId: maskedTextType(BaseMaskedTextType, {
    ...MASKED['TaxId'],
  }),
  Gst: maskedTextType(BaseMaskedTextType, {
    ...MASKED['Gst'],
  }),
  Acn: maskedTextType(BaseMaskedTextType, {
    ...MASKED['Acn'],
  }),
  Abn: maskedTextType(BaseMaskedTextType, {
    ...MASKED['Abn'],
  }),
  MasterZoneAbbr: maskedTextType(BaseMaskedTextType, {
    mask: [/[A-Z0-9\-_\']/i, ...repeat(15, /[A-Z0-9\.\-_\']/i)],
    constraints: {
      pattern: /^([A-Z0-9\-_\'])([A-Z0-9\-_\']*(\.[A-Z0-9\-_\']*)?)$/i,
      maxLength: 16,
    },
  }),
  GoogleGroupName: maskedTextType(BaseMaskedTextType, {
    mask: [/[A-Z0-9\-_\']/i, ...repeat(63, /[A-Z0-9\.\-_\']/i)],
    constraints: {
      pattern: /^([A-Z0-9\-_\'])([A-Z0-9\-_\']*(\.[A-Z0-9\-_\']*)?)$/i,
      maxLength: 64,
    },
  }),
  GoogleUserPassword: maskedTextType(BaseMaskedTextType, {
    mask: repeat(100, /[\x00-\x7F]/i),
    constraints: {
      maxLength: 100,
      pattern: /^[\x00-\x7F]+$/i,
      rangeLength: { min: 8, max: 100 },
    },
    messages: {
      rangeLength: 'msg_password_length',
    },
  }),
  CustomFieldsLength: maskedTextType(BaseMaskedTextType, {
    mask: repeat(3, /[0-9]/i),
  }),
  SocialNumber: maskedTextType(BaseMaskedTextType, {
    ...MASKED['SocialNumber'],
  }),
  TaxNumber: maskedTextType(BaseMaskedTextType, {
    ...MASKED['TaxNumber'],
  }),
  NumberFormat: maskedTextType(BaseMaskedTextType, {
    mask: (value: string) => {
      const parsedMask: any[] = [];
      for (const char of value) {
        if (/^\d+$/.test(char)) {
          parsedMask.push(...digits(1));
        }
        if (char === '.') {
          parsedMask.push('.');
        }
      }
      return parsedMask;

    },
    constraints: {
      pattern: /^(\d*\.*)*$/,
      maxLength: 16,
    },
  }),
  CostCenterCode: maskedTextType(BaseMaskedTextType, {
    mask: repeat(3, /[0-9]/i),
  }),
  OrderNumber: maskedTextType(BaseMaskedTextType, {
    mask: repeat(2, /[0-9]/i),
  }),
  BsbNumber: maskedTextType(BaseMaskedTextType, {
    ...MASKED['BsbNumber'],
  }),
  PhoneNumber: maskedTextType(BaseMaskedTextType, {
    ...MASKED['PhoneNumber'],
  }),
};
