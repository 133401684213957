/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { arrayOf, date, DateFormat, entity, enumeration, subentity } from '@gh/core-data';

import {
  FinancialInvoiceListItemDto,
  FinancialInvoicePaymentDto,
  FinancialRecordDto,
  InvoiceType,
} from './financial-record.dto';

export const FinancialRecordMapper = entity<FinancialRecordDto>({
  invoices: arrayOf(subentity<FinancialInvoiceListItemDto>({
    accountingDate: date(DateFormat.Date),
    dueDate: date(DateFormat.Date),
    invoiceType: enumeration(InvoiceType),
    type: enumeration(InvoiceType),
    payments: arrayOf(subentity<FinancialInvoicePaymentDto>({
      paidDate: date(DateFormat.Date),
    })),
  })),
});
