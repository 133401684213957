/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { assign, fromPairs, isDate, isFunction } from 'lodash';

/**
 * Immutable setter for array element
 */
export function setArrayElement<T>(entities: T[], index: number, entity: T): T[] {
  if (entities[index] === entity) {
    return entities;
  } else {
    const copied = entities.concat();
    copied[index] = entity;
    return copied;
  }
}

/**
 * Immutable setter for the given field.
 */
export function setField<T, N extends keyof T>(entity: T, name: N, value: T[N]): T;
export function setField<T, N extends keyof T>(entity: T, name: N, value: (old: T[N]) => T[N]): T;
export function setField<T>(entity: T, name: string, valueOrFn: any | ((value: any) => any)): T {
  const value = isFunction(valueOrFn) ? valueOrFn.call(void 0, entity[name]) : valueOrFn;

  return entity && !isSimpleValueEqual(entity[name], value) ? { ...<any>entity, [name]: value } : entity;
}

/**
 * Immutable setter for the given fields.
 */
export function setFields<T>(entity: T, values: Hash<any>): T {
  if (entity) {
    const modifiedFields = Object.keys(values).filter((field) => !isSimpleValueEqual(entity[field], values[field]));

    if (modifiedFields.length > 0) {
      return assign({}, entity, fromPairs(modifiedFields.map((field) => [field, values[field]])));
    }
  }

  return entity;
}

export function isSimpleValueEqual(a: any, b: any): boolean {
  if (a === b || a == null && a == b) { // tslint:disable-line:triple-equals no-null-keyword
    return true;
  } else if (isDate(a) && isDate(b)) {
    return a.getTime() === b.getTime();
  } else {
    return false;
  }
}
