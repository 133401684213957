/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { combineLatest$, from$, of$ } from '@gh/rx';
import { map$ } from '@gh/rx/operators';
import { fromPairs, isEmpty, isFunction, isNil, isNull, isObject, isUndefined } from 'lodash';
import { Observable } from 'rxjs';

export const NULL: any = null; // tslint:disable-line:no-null-keyword

export const EMPTY_ARRAY: any[] = <any>Object.freeze([]);

export function isPromise<T>(val: any): val is Promise<T> {
  return !isNil(val) && isFunction(val.then);
}

export function isObservable<T>(ob: any): ob is Observable<T> {
  return ob instanceof Observable;
}

export function wrapInObservable<T>(value: any): Observable<T> {
  if (value instanceof Observable) {
    return value;
  } else if (isPromise(value)) {
    return <any>from$(value);
  } else {
    return of$(value);
  }
}

export function expandObservable(data: any): Observable<any> {
  // if it is Observable or primitive => return immediately
  if (data instanceof Observable || !isObject(data)) {
    return wrapInObservable(data);
  }

  const fields = Object.keys(data);
  const fields$ = isEmpty(fields) ? of$([]) : combineLatest$(fields.map((field) => wrapInObservable(data[field])));

  return fields$.pipe(map$((values: any) => fromPairs(fields.map((field, i) => [field, values[i]]))));
}

export function denull<T>(value: null): undefined; // tslint:disable-line:no-null-keyword
export function denull<T>(value: T | null): T | undefined;
export function denull<T>(value: T | null): T | undefined {
  return isNull(value) ? void 0 : value;
}

export function nullate<T>(value: undefined): null; // tslint:disable-line:no-null-keyword
export function nullate<T>(value: T | undefined): T | null;
export function nullate<T>(value: T | undefined): T | null {
  return isUndefined(value) ? null : value; // tslint:disable-line:no-null-keyword
}

export function cast<T>(value: any): T {
  return value;
}
