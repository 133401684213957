/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { TranslationService } from '@gh/core-mls';
import { compact } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
export var HOURS_IN_DAY = 8;
/**
 * {@link LocalizationBusinessService} serves to keep shared business-related localization logic.
 */
var LocalizationBusinessService = /** @class */ (function () {
    function LocalizationBusinessService(translations) {
        this.translations = translations;
    }
    /**
     * Formats given hours-based duration to human-readable form:
     * <ul>
     *   <li>244 => 30d 4h</li>
     *   <li>16 => 2d</li>
     *   <li>3 => 3h</li>
     * </ul>
     */
    LocalizationBusinessService.prototype.formatHoursDuration = function (duration) {
        var days = Math.floor(duration / HOURS_IN_DAY);
        var hours = duration % HOURS_IN_DAY;
        return compact([
            (days === 0 ? [] : [days, this.translations.get('lbl_duration_d')].join('')),
            (hours === 0 ? [] : [hours, this.translations.get('lbl_duration_h')].join('')),
        ]).join(' ') || '0';
    };
    /**
     * Formats given hours-based duration to human-readable form:
     * <ul>
     *   <li>30 => 30d</li>
     *   <li>1 => 1d</li>
     * </ul>
     */
    LocalizationBusinessService.prototype.formatDaysDuration = function (duration) {
        return "" + duration + this.translations.get('lbl_duration_d');
    };
    LocalizationBusinessService.ɵfac = function LocalizationBusinessService_Factory(t) { return new (t || LocalizationBusinessService)(i0.ɵɵinject(i1.TranslationService)); };
    LocalizationBusinessService.ɵprov = i0.ɵɵdefineInjectable({ token: LocalizationBusinessService, factory: LocalizationBusinessService.ɵfac });
    return LocalizationBusinessService;
}());
export { LocalizationBusinessService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LocalizationBusinessService, [{
        type: Injectable
    }], function () { return [{ type: i1.TranslationService }]; }, null); })();
