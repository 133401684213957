/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DataMapper, entity } from '@gh/core-data';
import { denull, setArrayElement, setField } from '@gh/core-util';
import { constant, get, isArray, isFunction, mapValues } from 'lodash';
import { setEntityFields } from '../immutable';

export interface FormControlState {
  $$control: true;
  dirty?: boolean;
  touched?: boolean;
  errors?: ValidationErrors;
}

export type FormControlStateHierarchy = Hash<FormControlState | any>;

export const EMPTY_FORM_STATE_SNAPSHOT: FormStateSnapshot<any> = formStateSnapshot({});

export class FormStateSnapshot<T> {
  constructor(public value: T,
              public controlState?: FormControlStateHierarchy) {
  }
}

export function formStateSnapshot<T>(value: T): FormStateSnapshot<T> {
  return { value };
}

export function formStateSnapshotOf<T>(mapper: DataMapper<T, any>): DataMapper<FormStateSnapshot<T>, any> {
  return entity<FormStateSnapshot<T>>({ value: mapper });
}

export function getFormStateValue<T>(snapshot: FormStateSnapshot<T>): T {
  return snapshot.value;
}

export function toControlStateEntry(control: AbstractControl): FormControlState {
  return { $$control: true, dirty: control.dirty, touched: control.touched, errors: denull(control.errors) };
}

export function hasFormStateError<T>(snapshot: FormStateSnapshot<T>, path?: string): boolean {
  const stack: any = [];

  stack.push(path ? get(snapshot.controlState, path, void 0) : snapshot.controlState);

  while (stack.length) {
    const state = stack.pop();

    if (isFormControlState(state)) {
      if (state.errors) {
        return true;
      }
    } else if (isArray(state)) {
      state.forEach((childState) => stack.push(childState));
    } else if (state) {
      Object.keys(state).forEach((key) => stack.push(state[key]));
    }
  }

  return false;
}

export function mapFormState<T>(snapshot: FormStateSnapshot<T>,
                                transformer: IdentityFn<FormControlState>): FormStateSnapshot<T> {
  return setField(snapshot, 'controlState', mapFormStateControlState(snapshot.controlState, transformer));
}

export function markFormStateAsTouched<T>(snapshot: FormStateSnapshot<T>): FormStateSnapshot<T> {
  return mapFormState(ensureFormControlState(snapshot), (state) => setField(state, 'touched', true));
}

export function isFormControlState(state: any): state is FormControlState {
  return state && state.$$control || false;
}

export function getFormStateSnapshotValue<T>(snapshot: FormStateSnapshot<T>): T {
  return snapshot.value;
}

/**
 * Transforms {@link FormStateSnapshot} by the given value or function.
 *
 * If function is provided it should returns new value based on old one.
 */
export function setFormStateSnapshotValue<T>(snapshot: FormStateSnapshot<T>,
                                             value: T | IdentityFn<T>): FormStateSnapshot<T> {
  return { ...snapshot, value: isFunction(value) ? value(snapshot.value) : value };
}

/**
 * Transforms {@link FormStateSnapshot} by merging provided value.
 * Only fields included into <code>value</code> will be updated. All other fields will be unchanged
 *
 * @param snapshot
 * @param value
 */
export function mergeFormStateSnapshotValue<T>(snapshot: FormStateSnapshot<T>,
                                               value: T): FormStateSnapshot<T> {
  return setFormStateSnapshotValue(snapshot, (oldValue) => setEntityFields(oldValue, value));
}

/**
 * Maps form state snapshot tree using specified reducer and original value.
 */
function mapFormStateControlState(state: any,
                                  transformer: IdentityFn<FormControlState>): any {
  if (isFormControlState(state)) {
    return transformer(state);
  } else if (isArray(state)) {
    return state.reduce(
      (previousState, elementOfState, i) => setArrayElement(
        <any[]>previousState,
        i,
        mapFormStateControlState(elementOfState, transformer)),
      state);
  } else {
    return Object.keys(state).reduce(
      (previousState, key) => setField(
        previousState,
        key,
        mapFormStateControlState(state[key], transformer)),
      state);
  }
}

function ensureFormControlState<T>(snapshot: FormStateSnapshot<T>): FormStateSnapshot<T> {
  const { value, controlState } = snapshot;

  if (controlState) {
    return snapshot;
  } else {
    return {
      value,
      controlState: mapValues(
        <any>getFormStateSnapshotValue(snapshot),
        constant({ $$control: true, dirty: false, touched: false })),
    };
  }
}
