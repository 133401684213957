/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { extend } from 'lodash';
import {
  DateTimeType,
  DateTypeDefenition,
  MaskedTextType,
  MaskedTextTypeDefenition,
  NumberType,
  NumberTypeDefenition,
  QuantityNumberTypeDefenition,
  QuantityPatternTypeDefenition,
  QuantityType,
  TextType,
  TextTypeDefenition,
  TypeConstructor,
  TypeDefenition,
} from './type.interfaces';
import { NumberTypeImpl, TextTypeImpl } from './types';
import { AbstractType } from './types/abstract-type';
import { DateTimeTypeImpl } from './types/date-type';
import { MaskedTextTypeImpl } from './types/masked-text-type';
import { QuantityNumberTypeImpl, QuantityPatternTypeImpl } from './types/quantity-type';

type TypeFn<T> = () => T;

/**
 * Constructor for number type.
 *
 * @param def definition of the type defines all type-related capabilities
 */
export function numberType(def: NumberTypeDefenition): NumberType;
/**
 * Constructor for number type that inherits all settings from the base type
 *
 * @param baseType base type
 * @param def definition of the type defines all type-related capabilities
 */
export function numberType(baseType: TypeFn<NumberType> | NumberType,
                           def: NumberTypeDefenition): NumberType;
export function numberType(baseTypeOrDefenition: TypeFn<NumberType> | NumberType | NumberTypeDefenition,
                           def?: NumberTypeDefenition): NumberType {
  return declareType(NumberTypeImpl, <any>baseTypeOrDefenition, def);
}

/**
 * Constructor for text type.
 *
 * @param def definition of the type defines all type-related capabilities
 */
export function textType(def: TextTypeDefenition): TextType;
/**
 * Constructor for text type that inherits all settings from the base type
 *
 * @param baseType base type
 * @param def definition of the type defines all type-related capabilities
 */
export function textType(baseType: TypeFn<TextType> | TextType, def: TextTypeDefenition): TextType;
export function textType(baseTypeOrDefenition: TypeFn<TextType> | TextType | TextTypeDefenition,
                         def?: TextTypeDefenition): TextType {
  return declareType(TextTypeImpl, <any>baseTypeOrDefenition, def);
}

/**
 * Constructor for masked text type.
 *
 * @param def definition of the type defines all type-related capabilities
 */
export function maskedTextType(def: MaskedTextTypeDefenition): MaskedTextType;
/**
 * Constructor for masked text type that inherits all settings from the base type
 *
 * @param baseType base type
 * @param def definition of the type defines all type-related capabilities
 */
export function maskedTextType(baseType: TypeFn<MaskedTextType> | MaskedTextType,
                               def: MaskedTextTypeDefenition): MaskedTextType;
export function maskedTextType(baseTypeOrDefenition: TypeFn<MaskedTextType> | MaskedTextType | MaskedTextTypeDefenition,
                               def?: MaskedTextTypeDefenition): MaskedTextType {
  return declareType(MaskedTextTypeImpl, <any>baseTypeOrDefenition, def);
}

/**
 * Constructor for date type.
 *
 * @param def definition of the type defines all type-related capabilities
 */
export function dateTimeType(def: DateTypeDefenition): DateTimeType;
/**
 * Constructor for date type that inherits all settings from the base type
 *
 * @param baseType base type
 * @param def definition of the type defines all type-related capabilities
 */
export function dateTimeType(baseType: TypeFn<DateTimeType> | DateTimeType,
                             def: DateTypeDefenition): DateTimeType;
export function dateTimeType(baseTypeOrDefenition: TypeFn<DateTimeType> | DateTimeType | DateTypeDefenition,
                             def?: DateTypeDefenition): DateTimeType {
  return declareType(DateTimeTypeImpl, <any>baseTypeOrDefenition, def);
}

/**
 * Constructor for number-based quantity type.
 *
 * @param def definition of the type defines all type-related capabilities
 */
export function quantityNumberType(def: QuantityNumberTypeDefenition): QuantityType<number>;
/**
 * Constructor for number-based quantity type that inherits all settings from the base type
 *
 * @param baseType base type
 * @param def definition of the type defines all type-related capabilities
 */
export function quantityNumberType(baseType: TypeFn<QuantityType<number>> | QuantityType<number>,
                                   def: QuantityNumberTypeDefenition): QuantityType<number>;
// tslint:disable-next-line:max-line-length
export function quantityNumberType(baseTypeOrDefenition: TypeFn<QuantityType<number>> | QuantityType<number> | QuantityNumberTypeDefenition,
                                   def?: QuantityNumberTypeDefenition): QuantityType<number> {
  return declareType(QuantityNumberTypeImpl, <any>baseTypeOrDefenition, def);
}

/**
 * Constructor for pattern-based quantity type.
 *
 * @param def definition of the type defines all type-related capabilities
 */
export function quantityPatternType<Q>(def: QuantityPatternTypeDefenition<Q>): QuantityType<Q>;
/**
 * Constructor for pattern-based quantity type that inherits all settings from the base type
 *
 * @param baseType base type
 * @param def definition of the type defines all type-related capabilities
 */
export function quantityPatternType<Q>(baseType: TypeFn<QuantityType<Q>> | QuantityType<Q>,
                                       def: QuantityPatternTypeDefenition<Q>): QuantityType<Q>;
// tslint:disable-next-line:max-line-length
export function quantityPatternType<Q>(baseTypeOrDefenition: TypeFn<QuantityType<Q>> | QuantityType<Q> | QuantityPatternTypeDefenition<Q>,
                                       def?: QuantityPatternTypeDefenition<Q>): QuantityType<Q> {
  return declareType<QuantityPatternTypeImpl<Q>, QuantityPatternTypeDefenition<Q>>(
    QuantityPatternTypeImpl,
    <any>baseTypeOrDefenition, def);
}

/**
 * Declares new type. This operation either define root type or specialize (extend from) existing type.
 *
 * @param typeConstructor constructor for the type
 * @param baseTypeOrDefenition base type
 * @param def definition of the type
 *
 * @returns {T}
 */
function declareType<T extends AbstractType<any>, D extends TypeDefenition>(typeConstructor: TypeConstructor<T>,
                                                                            baseTypeOrDefenition: TypeFn<T> | T | D,
                                                                            def?: D): T {
  let baseType: Maybe<T> = undefined;

  if (typeof baseTypeOrDefenition === 'function') {
    baseType = baseTypeOrDefenition();
  } else if (baseTypeOrDefenition instanceof AbstractType) {
    baseType = baseTypeOrDefenition;
  } else {
    def = def || baseTypeOrDefenition;
  }

  let type: T;

  type = baseType ? specializeType(typeConstructor, baseType, def) : defineRootType(typeConstructor, def);

  return type;
}

/**
 * Defines root type
 */
function defineRootType<T extends AbstractType<any>, D extends TypeDefenition>(typeConstructor: TypeConstructor<T>,
                                                                               def?: D): T {
  return new typeConstructor(
    def || {},
  );
}

/**
 * Specializes (extend from) existing type
 */
function specializeType<T extends AbstractType<any>, D extends TypeDefenition>(typeConstructor: TypeConstructor<T>,
                                                                               baseType: T, def?: D): T {
  return new typeConstructor(
    inheritDef(def || {}, baseType.defenition),
  );
}

/**
 * Helper method to inherit type definitions
 */
function inheritDef(def: TypeDefenition, base: TypeDefenition): TypeDefenition {
  def.constraints = inherit(base.constraints, def.constraints);
  def.messages = inherit(base.messages, def.messages);
  def.format = inherit(base.format, def.format);

  return def;
}

/**
 * Provides simple inheritance using prototype approach
 */
function inherit<T extends object>(base: T, sub: T): T {
  if (base && sub) {
    const result = Object.create(base);
    extend(result, sub);
    return result;
  } else {
    return base || sub;
  }
}
