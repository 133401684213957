/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { createErrorQuantity, createQuantity, Enum, getQuantityValue, isQuantityValid, Quantity } from '@gh/core-data';
import { LocalizationProvider } from '@gh/core-mls';
import { isNotNil, nullate } from '@gh/core-util';
import { find, fromPairs, isNil } from 'lodash';
import { GtoUom } from '../../../builder-shared-data/take-off-params';
import {
  DecomposedQuantity, EMPTY_DECOMPOSED_QUANTITY,
  QuantityFormatQuery,
  QuantityNumberTypeDefenition,
  QuantityPatternTypeDefenition,
  QuantityTypeBase,
} from '../type.interfaces';
import { AbstractPrimitiveType, AbstractType, DecoratedType } from './abstract-type';
import { NumberTypeImpl } from './number-type';

export class QuantityNumberTypeImpl extends DecoratedType<Quantity<number>, number> {
  readonly validator: ValidatorFn;

  base = QuantityTypeBase.Number;

  constructor(private quantityDefenition: QuantityNumberTypeDefenition) {
    super(new NumberTypeImpl(quantityDefenition));

    this.validator = (control) => nullate(this.validate(control.value));
  }

  parse(str: string): Quantity<number> {
    return createQuantity(this.baseType.parse(str), this.name);
  }

  format(q: Quantity<number>, { noLabels }: QuantityFormatQuery = { noLabels: true }): string {
    if (isNil(q)) {
      return '';
    }

    const formattedNumber = this.baseType.format(getQuantityValue(q));
    if (noLabels || isNil(this.quantityDefenition.label)) {
      return formattedNumber;
    } else {
      return `${formattedNumber}${this.quantityDefenition.label}`;
    }
  }

  validate(q: Quantity<number>): Maybe<ValidationErrors> {
    return isNotNil(q) ? this.baseType.validate(getQuantityValue(q)) : void 0;
  }
}

export class QuantityPatternTypeImpl<Q> extends AbstractPrimitiveType<Quantity<Q>> {
  base = QuantityTypeBase.Pattern;
  private formatElements: QuantityFormatCompiledElement[];

  constructor(private quantityDefenition: QuantityPatternTypeDefenition<Q>) {
    super(quantityDefenition, {});
  }

  parse(str: string): Quantity<Q> {
    const result = this.preParse(str);

    return result.success
      ? this.quantityDefenition.mappings.parse(result)
      : createErrorQuantity<Q>(str);
  }

  format(value: Quantity<Q>, query?: QuantityFormatQuery): string {
    const noLabels = query && query.noLabels || false;
    if (isQuantityValid(value)) {
      const { negative, elements } = this.quantityDefenition.mappings.format(value) || EMPTY_DECOMPOSED_QUANTITY;

      const notZeroElements = this.formatElements.filter(({ name }) => elements[name]);
      // if all values are zero => take only the first 0 element
      const positivePart = (notZeroElements.length === 0 ? [this.formatElements[0]] : notZeroElements)
        .map(({ name, label, type }) => `${type.format(elements[name])}${noLabels ? '' : label}`)
        .join(' ');
      return negative && notZeroElements.length > 0 ? `-${positivePart}` : positivePart;
    } else {
      return value.error;
    }
  }

  validateFormat(str: string): Maybe<ValidationErrors> {
    const result = this.preParse(str);
    return result.success
      ? void 0
      : { format: { messageId: 'msg_field_invalid_format' } };
  }

  validate(value: Quantity<Q>): Maybe<ValidationErrors> {
    if (isNotNil(value)) {
      return isQuantityValid(value) ? super.validate(value) : { format: { messageId: 'msg_field_invalid_format' } };
    }
  }

  init(name: string, localizationProvider: LocalizationProvider): void {
    super.init(name, localizationProvider);

    const format = localizationProvider.getQuantityLocalization().formats[this.quantityDefenition.format];
    if (isNil(format)) {
      throw new Error(`QuantityPatternType: format '${this.quantityDefenition.format}' could not be found`);
    }

    this.formatElements = format.map((element) => ({
      name: element.name,
      label: element.label,
      type: this.getElementType(element.name),
    }));

    this.formatElements.forEach((element) => element.type.init(name, localizationProvider));
  }

  private preParse(str: string): QuantityParseResult {
    // split string on raw values (unparsed)
    const trimmedStr = str.trim();
    const isNegative = trimmedStr.startsWith('-');
    const numberPartStr = isNegative ? trimmedStr.substring(1).trim() : str.trim();
    const rawValues = numberPartStr.split(/\s+/);
    if (rawValues.length > this.formatElements.length) {
      return { success: false, elements: {}, negative: false };
    }

    // validate raw values according to the format of given type
    const rawValueWithElements = rawValues.map((part, i) => {
      const element = find(this.formatElements, ({ label }) => part.endsWith(label));
      if (element) {
        return { element, rawValue: part.substring(0, part.length - element.label.length) };
      } else {
        return { element: this.formatElements[i], rawValue: part };
      }
    });
    const isFormatValid = rawValueWithElements
      .every(({ element, rawValue }) => isNil(element.type.validateFormat(rawValue)));
    if (!isFormatValid) {
      return { success: false, elements: {}, negative: false };
    }

    // validate parsed values
    const valueWithElements = rawValueWithElements
      .map(({ element, rawValue }) => ({ element, value: element.type.parse(rawValue) }));
    const isValid = valueWithElements.every(({ element, value }) => isNil(element.type.validate(value)));
    if (!isValid) {
      return { success: false, elements: {}, negative: false };
    }

    return {
      success: true,
      negative: isNegative,
      elements: fromPairs(rawValueWithElements.map(({ rawValue, element }) => [
        element.name,
        element.type.parse(rawValue),
      ])),
    };
  }

  private getElementType(name: string): AbstractType<number> {
    const type = this.quantityDefenition.types[name];

    if (isNil(type)) {
      throw new Error(`QuantityType: element type '${name}' is not defined`);
    }

    return <AbstractType<number>>type;
  }
}

type QuantityFormatCompiledElement = {
  name: string;
  label: string;
  type: AbstractType<number>;
};

type QuantityParseResult = DecomposedQuantity & { success: boolean };
