/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ApplicationComponentState } from './application-component-state.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./application-component-state.service";
var AppRouterOutletDirective = /** @class */ (function () {
    function AppRouterOutletDirective(native, applicationComponentState) {
        this.native = native;
        this.applicationComponentState = applicationComponentState;
    }
    AppRouterOutletDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.native.activateEvents.subscribe(function (component) { return _this.applicationComponentState.activate(component); });
        this.native.deactivateEvents.subscribe(function (component) { return _this.applicationComponentState.deactivate(component); });
    };
    AppRouterOutletDirective.ɵfac = function AppRouterOutletDirective_Factory(t) { return new (t || AppRouterOutletDirective)(i0.ɵɵdirectiveInject(i1.RouterOutlet), i0.ɵɵdirectiveInject(i2.ApplicationComponentState)); };
    AppRouterOutletDirective.ɵdir = i0.ɵɵdefineDirective({ type: AppRouterOutletDirective, selectors: [["router-outlet"]] });
    return AppRouterOutletDirective;
}());
export { AppRouterOutletDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppRouterOutletDirective, [{
        type: Directive,
        args: [{
                // tslint:disable-next-line:directive-selector
                selector: 'router-outlet',
            }]
    }], function () { return [{ type: i1.RouterOutlet }, { type: i2.ApplicationComponentState }]; }, null); })();
