/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive } from '@angular/core';
import { MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material/core';

/**
 * This is a temporarily solution until we decide
 * whether we will use always floated labels in the whole application or will not.
 * Alternative is <code>MAT_LABEL_GLOBAL_OPTIONS</code> on the root module.
 */
@Directive({
  selector: '[ghFloatLabelAlways]',
  providers: [{ provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'always' } }],
})
export class FloatLabelAlwaysDirective {
}
