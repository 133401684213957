/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge$ } from '@gh/rx';
import { map$, tap$ } from '@gh/rx/operators';

import { noop } from 'lodash';

import { Observable } from 'rxjs';
import { Locale } from '../locale';

import { clearPendings, getPendings } from './json-translation-bundle-registry';

import { TranslationMapLoader } from './translation-map-loader';
import { TranslationStorage } from './translation-storage.service';
import { TranslationMap } from './translation.interfaces';

@Injectable()
export class JSONTranslationMapLoader extends TranslationMapLoader {
  private ready: Promise<any>;

  constructor(private storage: TranslationStorage,
              private httpClient: HttpClient) {
    super();
  }

  load(locale: Locale): Promise<any> {
    const localeStr = `${locale.language}_${locale.country}`;
    const pendings = getPendings();

    if (pendings.length > 0) {
      clearPendings();

      return this.ready = new Promise((resolve, reject) => {
        merge$.apply(Observable, pendings.map((source) => this.httpClient.get(source[localeStr], {
            observe: 'response',
          })))
          .pipe(
            map$((response: HttpResponse<any>) => response.body),
            tap$((map: TranslationMap) => this.storage.putAll(map)))
          .subscribe(noop, reject, resolve);
      });
    } else {
      return this.ready || Promise.resolve();
    }
  }
}
