/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, Optional, SkipSelf } from '@angular/core';
import { distinctUntilChanged$, map$, scan$ } from '@gh/rx/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { createWaitingProcess, WaitingProcess } from './waiting-process';

/**
 * Hidden waiting mode means that loading content should be hidden
 * Blocked waiting mode means that loading content should be blocked for user interactions
 */
export enum WaitingMode {
  None,
  Blocked,
  Hidden,
  Background,
}

export interface WaitingState {
  counter: number;
  hidden: number;
  blocked: number;
}

export interface WaitingEvent {
  waiting: boolean;
  mode: WaitingMode;
}

function isWaiting(state: WaitingState): boolean {
  return state.counter !== 0;
}

function getMode(state: WaitingState): WaitingMode {
  if (state.hidden) {
    return WaitingMode.Hidden;
  } else if (state.blocked) {
    return WaitingMode.Blocked;
  } else if (state.counter) {
    return WaitingMode.Background;
  } else {
    return WaitingMode.None;
  }
}

/**
 * {@link WaitingContext} manages waiting lifecycle (like starting or stopping).
 * {@link WaitingContext}s can be organized in tree. Triggering of waiting for child node
 * triggers waiting for all parent nodes.
 *
 * Waiting can be run in two modes: Hidden and Blocked.
 * - in Hidden mode content should be hidden
 * - in Blocked mode content is blocked for user interactions.
 *
 */
@Injectable()
export class WaitingContext {
  readonly waiting$: Observable<WaitingEvent>;

  private actions$$ = new BehaviorSubject<WaitingState>({
    counter: 0,
    hidden: 0,
    blocked: 0,
  });

  private _waiting: boolean = false;
  private _mode: WaitingMode = WaitingMode.Blocked;

  /**
   * Creates new waiting context and finds parent waiting context if it exists
   *
   * @param parent
   */
  constructor(@Optional() @SkipSelf() private parent: WaitingContext) {
    this.waiting$ = this.actions$$.pipe(
      scan$(
        (state, next) => ({
          counter: state.counter + next.counter,
          hidden: state.hidden + next.hidden,
          blocked: state.blocked + next.blocked,
        }),
        { counter: 0, hidden: 0, blocked: 0 }),
      map$((state) => ({
        waiting: isWaiting(state),
        mode: getMode(state),
      })));

    this.waiting$.subscribe((event) => {
      this._waiting = event.waiting;
      this._mode = event.mode;
    });
  }

  /**
   * Returns true if waiting is running currently, otherwise false
   *
   * @returns {boolean}
   */
  get waiting(): boolean {
    return this._waiting;
  }

  /**
   * Returns current waiting mode
   *
   * @returns {WaitingMode}
   */
  get mode(): WaitingMode {
    return this._mode;
  }

  /**
   * Returns stream notifying about activity of specific waiting mode
   */
  modeAsStream(targetMode: WaitingMode): Observable<boolean> {
    return this.waiting$.pipe(
      map$(({ mode, waiting }) => mode === targetMode && waiting),
      distinctUntilChanged$());
  }

  /**
   * Starts waiting process and returns {@link WaitingProcess} to manage its lifecycle.
   *
   * @param name
   * @param mode
   * @returns {{stop: (()=>undefined)}}
   */
  start(mode: WaitingMode, name: string = 'default'): WaitingProcess {
    let parentProcess: Maybe<WaitingProcess> = undefined;

    this.actions$$.next({
      counter: 1,
      hidden: mode === WaitingMode.Hidden ? 1 : 0,
      blocked: mode === WaitingMode.Blocked ? 1 : 0,
    });

    if (this.parent) {
      parentProcess = this.parent.start(WaitingMode.Blocked, name && `${name}-parent`);
    }

    return createWaitingProcess(name, () => {
      if (parentProcess) {
        parentProcess.stop();
      }

      this.actions$$.next({
        counter: -1,
        hidden: mode === WaitingMode.Hidden ? -1 : 0,
        blocked: mode === WaitingMode.Blocked ? -1 : 0,
      });
    });
  }
}
