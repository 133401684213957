var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { AbstractRestService, identity, none, RestSettings } from '@gh/core-data';
import { CustomerUserProfileMapper } from './profiles.mapper';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-data";
var CustomerUserProfileRestService = /** @class */ (function (_super) {
    __extends(CustomerUserProfileRestService, _super);
    function CustomerUserProfileRestService(settings) {
        var _this = _super.call(this, settings) || this;
        _this.settings = settings;
        return _this;
    }
    CustomerUserProfileRestService.prototype.get = function () {
        return this.httpGet('/profiles/me', CustomerUserProfileMapper);
    };
    CustomerUserProfileRestService.prototype.logout = function () {
        return this.httpGet('/profiles/logout', identity());
    };
    CustomerUserProfileRestService.prototype.acceptInvitation = function (officeId) {
        return this.httpGet("/profiles/accept-invitation/" + officeId, none());
    };
    CustomerUserProfileRestService.ɵfac = function CustomerUserProfileRestService_Factory(t) { return new (t || CustomerUserProfileRestService)(i0.ɵɵinject(i1.RestSettings)); };
    CustomerUserProfileRestService.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerUserProfileRestService, factory: CustomerUserProfileRestService.ɵfac });
    return CustomerUserProfileRestService;
}(AbstractRestService));
export { CustomerUserProfileRestService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerUserProfileRestService, [{
        type: Injectable
    }], function () { return [{ type: i1.RestSettings }]; }, null); })();
