/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { Quantity } from '@gh/core-data';
import { TranslationTupleOrId } from '@gh/core-mls';

export interface TypeConstructor<T> extends Function {
  new(...args: any[]): T;
}

export interface QuantityFormatQuery {
  noLabels?: boolean;
}

/**
 * Type defines following type-related capabilities:
 * - parsing
 * - formatting
 * - validators against set of constraints
 */
export interface Type<T> {
  /**
   * Validator encapsulates all type-specific validations.
   * Validator is necessary for angular components/directives,
   * like ngModel directives, form* directives and Reactive Forms
   */
  readonly validator: ValidatorFn;
  /**
   * Defenition this type is based on
   */
  readonly defenition: TypeDefenition;

  /**
   * Parses given string into typed value
   * @param str
   */
  parse(str: string): T;

  /**
   * Formats typed value into string
   * @param value
   * @param options
   */
  format(value: T, options?: any): string;

  /**
   * Validates typed value and returns set of failed constraints.
   * If all constraints are satisfied returns undefined.
   *
   * @param value
   */
  validate(value: T): Maybe<ValidationErrors>;

  /**
   * Validates format of value. If format is wrong method returns set of errors, otherwise undefined
   * @param str
   */
  validateFormat(str: string): Maybe<ValidationErrors>;
}

/**
 * Type encapsulates all text type related capabilities
 */
export interface TextType extends Type<string> {
  defenition: TextTypeDefenition;
  readonly maxLength?: number;
  readonly pattern?: RegExp;
}

/**
 * Type encapsulates all masked-text type related capabilities
 */
export interface MaskedTextType extends TextType {
  defenition: MaskedTextTypeDefenition;
  readonly mask?: any;
}

/**
 * Type encapsulates all number type related capabilities
 */
export interface NumberType extends Type<number> {
  defenition: NumberTypeDefenition;

  readonly minFractionDigits?: number;
  readonly maxFractionDigits?: number;

  readonly min?: number;
  readonly max?: number;
}

/**
 * Type encapsulates all date type related capabilities
 */
export interface DateTimeType extends Type<Date> {
  defenition: DateTypeDefenition;
}

export enum QuantityTypeBase { Number, Pattern }

export interface QuantityType<Q> extends Type<Quantity<Q>> {
  defenition: QuantityTypeDefenition;
  base: QuantityTypeBase;
}

/**
 * Abstract definition for all types
 */
export interface TypeDefenition {
  format?: any;
  constraints?: any;
  messages?: any;
  validators?: any;
}

export type TextRangeLengthConstraint = { min: number; max: number };

/**
 * Text type definition
 */
export interface TextTypeDefenition extends TypeDefenition {
  constraints?: Partial<{
    maxLength: number;
    rangeLength: TextRangeLengthConstraint;
    pattern: RegExp;
    [name: string]: any;
  }>;
  messages?: Partial<{
    maxLength: TranslationTupleOrId;
    rangeLength: TranslationTupleOrId;
    pattern: TranslationTupleOrId;
    format: TranslationTupleOrId;
    [name: string]: TranslationTupleOrId;
  }>;
}

/**
 * Masked text type definition
 */
export interface MaskedTextTypeDefenition extends TextTypeDefenition {
  mask?: any;
}

export type NumberValueConstraint = { value: number; include: boolean };
export type NumberRangeConstraint = { min: number; max: number; includeMin: boolean; includeMax: boolean };

/**
 * Number type definition
 */
export interface NumberTypeDefenition extends TypeDefenition {
  format?: {
    minFractionDigits?: number;
    maxFractionDigits?: number;
  };
  constraints?: Partial<{
    integral: boolean;
    range: NumberRangeConstraint;
    min: NumberValueConstraint;
    max: NumberValueConstraint;
    [name: string]: any;
  }>;
  messages?: Partial<{
    integral: TranslationTupleOrId;
    range: TranslationTupleOrId;
    min: TranslationTupleOrId;
    max: TranslationTupleOrId;
    [name: string]: Maybe<TranslationTupleOrId>;
  }>;
}

/**
 * Date type definition
 */
export interface DateTypeDefenition extends TypeDefenition {
  format?: string;
  inputFormat?: string;
}

export interface QuantityNumberTypeDefenition extends NumberTypeDefenition {
  label?: string;
}

export interface DecomposedQuantity {
  negative: boolean;
  elements: Hash<number>;
}

export interface QuantityPatternTypeDefenition<Q> extends TypeDefenition {
  format: string;

  types: Hash<Type<number>>;

  mappings: {
    parse: TransformFn<DecomposedQuantity, Quantity<Q>>;
    format: TransformFn<Quantity<Q>, Maybe<DecomposedQuantity>>;
  };
}

export type QuantityTypeDefenition = QuantityNumberTypeDefenition | QuantityPatternTypeDefenition<any>;

export const EMPTY_DECOMPOSED_QUANTITY: DecomposedQuantity = { negative: false, elements: {} };
