/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

// 400 - 499 errors
export const BUSINESS_ERROR_KEYS = {
  BadRequest: statusToName(400),
  Unauthorized: statusToName(401),
  PaymentRequired: statusToName(402),
  Forbidden: statusToName(403),
  NotFound: statusToName(404),
  MethodNotAllowed: statusToName(405),
  NotAcceptable: statusToName(406),
  ProxyAuthenticationRequired: statusToName(407),
  RequestTimeOut: statusToName(408),
  Conflict: statusToName(409),
  Gone: statusToName(410),
  LengthRequired: statusToName(411),
  PreconditionFailed: statusToName(412),
  PayloadTooLarge: statusToName(413),
  URITooLong: statusToName(414),
  UnsupportedMediaType: statusToName(415),
  RangeNotSatisfiable: statusToName(416),
  ExpectationFailed: statusToName(417),
  IAmATeapot: statusToName(418),
  MisdirectedRequest: statusToName(421),
  UnprocessableEntity: statusToName(422),
  Locked: statusToName(423),
  FailedDependency: statusToName(424),
  UpgradeRequired: statusToName(426),
  PreconditionRequired: statusToName(428),
  TooManyRequests: statusToName(429),
  RequestHeaderFieldsTooLarge: statusToName(431),
  UnavailableForLegalReasons: statusToName(451),
  Unknown: 'business-error-unknown',
};

// 500 - 599 errors
export const TECHNICAL_ERROR_KEYS = {
  // 500
  InternalServerError: statusToName(500),
  NotImplemented: statusToName(501),
  BadGateway: statusToName(502),
  ServiceUnavailable: statusToName(503),
  GatewayTimeOut: statusToName(504),
  HTTPVersionNotSupported: statusToName(505),
  VariantAlsoNegotiates: statusToName(506),
  InsufficientStorage: statusToName(507),
  LoopDetected: statusToName(508),
  NotExtended: statusToName(510),
  NetworkAuthenticationRequired: statusToName(511),
  Unknown: 'technical-error-unknown',
};

export function statusToErrorKey(status: number): string | undefined {
  return statusToErrorKeyIndex[statusToName(status)];
}

const statusToErrorKeyIndex: Hash<string> = buildStatusIndex(
  buildStatusIndex({}, BUSINESS_ERROR_KEYS),
  TECHNICAL_ERROR_KEYS);

function buildStatusIndex(accumulator: Hash<string>, keys: Hash<string>): Hash<string> {
  return Object.keys(keys).reduce(
    (index, key) => {
      index[keys[key]] = key;
      return index;
    },
    accumulator);
}

function statusToName(status: number): string {
  return `S_${status}`;
}
