import { Injectable } from '@angular/core';
import { HTTP_STATUS_SERVER_UNAVAILABLE_ERROR } from '@gh/core-data';
import { createMessageBasketFromResponse } from '@gh/core-messages';
import { tap$ } from '@gh/rx/operators';
import { ApplicationComponentState } from '../routing';
import * as i0 from "@angular/core";
import * as i1 from "../routing";
var MaintenanceHttpResponseInterceptor = /** @class */ (function () {
    function MaintenanceHttpResponseInterceptor(applicationComponentState) {
        this.applicationComponentState = applicationComponentState;
    }
    MaintenanceHttpResponseInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(tap$({
            error: function (response) {
                if (response.status === HTTP_STATUS_SERVER_UNAVAILABLE_ERROR) {
                    var basket = createMessageBasketFromResponse(response);
                    if (basket.has('msg_service_is_not_available')) {
                        // if backend is not available => we suppose that index-maintenance.html already substitutes index.html,
                        // this way we try to refresh page ignoring any dirty state (to avoid handling of beforeUnload event)
                        _this.applicationComponentState.setIgnoreDirty();
                        location.reload();
                    }
                }
            },
        }));
    };
    MaintenanceHttpResponseInterceptor.ɵfac = function MaintenanceHttpResponseInterceptor_Factory(t) { return new (t || MaintenanceHttpResponseInterceptor)(i0.ɵɵinject(i1.ApplicationComponentState)); };
    MaintenanceHttpResponseInterceptor.ɵprov = i0.ɵɵdefineInjectable({ token: MaintenanceHttpResponseInterceptor, factory: MaintenanceHttpResponseInterceptor.ɵfac });
    return MaintenanceHttpResponseInterceptor;
}());
export { MaintenanceHttpResponseInterceptor };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MaintenanceHttpResponseInterceptor, [{
        type: Injectable
    }], function () { return [{ type: i1.ApplicationComponentState }]; }, null); })();
