/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { EXT_INFINITE_SCROLL_DIMENSIONS } from '../ext-infinite-scroll.interfaces';
import { getQueryItemIndex } from '../infinite-mat-data-source';
import * as i0 from "@angular/core";
var ExtInfiniteRowDirective = /** @class */ (function () {
    function ExtInfiniteRowDirective(elementRef, dimensions) {
        this.elementRef = elementRef;
        this.dimensions = dimensions;
    }
    Object.defineProperty(ExtInfiniteRowDirective.prototype, "index", {
        set: function (data) {
            var top = this.dimensions.offsetTop + this.dimensions.itemHeight * getQueryItemIndex(data);
            this.elementRef.nativeElement.style.top = top + "px";
        },
        enumerable: true,
        configurable: true
    });
    ExtInfiniteRowDirective.ɵfac = function ExtInfiniteRowDirective_Factory(t) { return new (t || ExtInfiniteRowDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(EXT_INFINITE_SCROLL_DIMENSIONS)); };
    ExtInfiniteRowDirective.ɵdir = i0.ɵɵdefineDirective({ type: ExtInfiniteRowDirective, selectors: [["", "ghExtInfiniteRow", ""]], hostAttrs: [1, "ext-infinite-row"], inputs: { index: ["ghExtInfiniteRow", "index"] } });
    return ExtInfiniteRowDirective;
}());
export { ExtInfiniteRowDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtInfiniteRowDirective, [{
        type: Directive,
        args: [{
                selector: '[ghExtInfiniteRow]',
                // tslint:disable-next-line:use-host-property-decorator
                host: {
                    'class': 'ext-infinite-row',
                },
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: undefined, decorators: [{
                type: Inject,
                args: [EXT_INFINITE_SCROLL_DIMENSIONS]
            }] }]; }, { index: [{
            type: Input,
            args: ['ghExtInfiniteRow']
        }] }); })();
