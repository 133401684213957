/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name no-console no-magic-numbers no-null-keyword

import { mockRegistry } from '@gh/core-data';

import { address, name, phone } from 'faker';
import { range } from 'lodash';

const TEST_LONG_LIST = range(1000).map(() => ({
  name: name.lastName(),
  phone: phone.phoneNumber(),
  address: address.streetAddress(),
}));

export const CUSTOMER_MOCK_DATA = mockRegistry(
  // mockRequest({ url: '/cp/rest/profiles/me', method: 'get', data: { email: 'a@sdfs.com' } }),
  // mockPagination({ url: '/cp/rest/long-list', data: TEST_LONG_LIST }),
);
