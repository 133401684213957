/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Input } from '@angular/core';
import * as i0 from "@angular/core";
var MessageDirective = /** @class */ (function () {
    function MessageDirective(elementRef) {
        this.elementRef = elementRef;
    }
    Object.defineProperty(MessageDirective.prototype, "value", {
        get: function () {
            return this.elementRef.nativeElement.textContent;
        },
        enumerable: true,
        configurable: true
    });
    MessageDirective.ɵfac = function MessageDirective_Factory(t) { return new (t || MessageDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    MessageDirective.ɵdir = i0.ɵɵdefineDirective({ type: MessageDirective, selectors: [["gh-message"]], inputs: { key: "key" } });
    return MessageDirective;
}());
export { MessageDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MessageDirective, [{
        type: Directive,
        args: [{
                selector: 'gh-message',
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { key: [{
            type: Input
        }] }); })();
