/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  CONTEXT_PATH,
  KEYCLOAK_AUTH_SERVER_URL,
  KEYCLOAK_JSON_URL,
  KEYCLOAK_MIN_VALIDITY,
  KEYCLOAK_REALM,
  KEYCLOAK_RESOURCE,
  KEYCLOAK_SECRET,
} from '@gh/config';

import { CoreKeycloakAuthModule, KeycloakConfiguration } from '@gh/core-auth-keycloak';
import { CoreAuthMockModule } from '@gh/core-auth-mock';
import { NoopModule } from '@gh/core-util';

export function logoutOptionsFactory(): KeycloakModule.RedirectUriOptions {
  return {
    redirectUri: window.location.origin + CONTEXT_PATH,
  };
}

export const configuration: KeycloakConfiguration = {
  configuration: KEYCLOAK_JSON_URL ? KEYCLOAK_JSON_URL : {
    url: KEYCLOAK_AUTH_SERVER_URL,
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_RESOURCE,
    credentials: !KEYCLOAK_SECRET ? undefined : {
      secret: KEYCLOAK_SECRET,
    },
  },
  initOptions: {
    onLoad: 'check-sso',
  },
  minValidity: KEYCLOAK_MIN_VALIDITY,
  logoutOptions: logoutOptionsFactory,
};

@NgModule({
  imports: [
    CoreKeycloakAuthModule.asStandalone(configuration),
  ],
})
export class CustomerAuthKeycloakModule {

}

@NgModule({
  imports: [
    CoreKeycloakAuthModule.asDecorated(configuration),
    CoreAuthMockModule.asDecorating(),
  ],
})
export class CustomerAuthKeycloakWithMockModule {

}

@NgModule({
  imports: [
    CoreAuthMockModule.asStandalone({
      fields: ['email'],
      width: 400,
    }),
  ],
})
export class CustomerAuthMockModule {

}

export class CustomerAuthConfigModule {
  static process: any;

  static useKeycloak(): ModuleWithProviders {
    return { ngModule: CustomerAuthKeycloakModule };
  }

  static useKeycloakWithMock(): ModuleWithProviders {
    return { ngModule: CustomerAuthKeycloakWithMockModule };
  }

  static useMock(): ModuleWithProviders {
    return { ngModule: CustomerAuthMockModule };
  }

  static none(): ModuleWithProviders {
    return { ngModule: NoopModule };
  }
}
