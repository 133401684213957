/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray } from '@angular/forms';
var AppFormArray = /** @class */ (function (_super) {
    __extends(AppFormArray, _super);
    function AppFormArray(schema, controls, validator, asyncValidator) {
        var _this = _super.call(this, controls, validator, asyncValidator) || this;
        _this.schema = schema;
        _this.key = schema.key;
        return _this;
    }
    AppFormArray.prototype.updateSchema = function (config, packedValue) {
        this.schema.update(config);
        this.schema.applyTo(this, packedValue);
    };
    return AppFormArray;
}(FormArray));
export { AppFormArray };
