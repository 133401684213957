/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Params, Router, UrlTree } from '@angular/router';
import { translationTuple, TranslationTuple } from '@gh/core-mls';
import { parametrizeUrl } from '@gh/core-util';
import { filter$ } from '@gh/rx/operators';
import { omit } from 'lodash';
import { AppRouterState } from './app-router-state.service';

/**
 * Allows to provide additional application-specific parameters into navigation methods
 */
export interface AppNavigationExtras extends NavigationExtras {
  parentTitle?: TranslationTuple | string;
}

/**
 * This service is a wrapper around angular {@link Router} service.
 * It has similar capabilities, but also allows to manage parent-child relations between routes.
 *
 * This router tracks current user route (route displayed in the browser toolbar).
 * What does this mean?
 * Some pages have internal routes. For example, multi-tab form has a separate route for each tab.
 * Nevertheless only one route (for whole page) is displayed in the browser toolbar,
 * there is no sense for user to bookmark each separate tab.
 *
 * This service helps to track only route important for user.
 * Developer can get current state of the router via `state` field.
 *
 */
@Injectable()
export class AppRouter {
  constructor(private router: Router,
              private location: Location,
              readonly state: AppRouterState) {
    // initialize state by the first opened route
    router.events.pipe(
      filter$((event) => event instanceof NavigationEnd))
      .subscribe(() => this.state._update());
  }

  get url(): string {
    return this.router.url;
  }

  get parentRoute(): Maybe<string> {
    return this.state.parentRoute;
  }

  /**
   * Navigates to child route. Child route gets the following parameters:
   *
   * * `parentRoute` query parameter that points to the parent route
   * * `parentTitle` for title of parent page. Optional.
   *
   * @param commands the same as for {@link Router#navigate}
   * @param extras allows to provide title of parent router (parentTitle)
   */
  navigateToChild(commands: any[], extras?: AppNavigationExtras): Promise<boolean> {
    extras = extras || {};

    const parentTitle = extras.parentTitle && translationTuple(extras.parentTitle);

    const queryParams = {
      parentRoute: this.location.path(),
      parentTitle: parentTitle && parentTitle.id,
      parentTitleParams: parentTitle && JSON.stringify(parentTitle.params),
    };

    return this.router.navigate(commands, {
      ...<NavigationExtras>omit(extras, 'parentTitle'),
      queryParams: extras.queryParams ? {
        ...extras.queryParams,
        ...queryParams,
      } : queryParams,
    }).then(this.createUpdater(extras));
  }

  /**
   * Replaces current route by the provided one and saves all query parameters.
   *
   * @param commands the same as for {@link Router#navigate}
   * @param extras allows to provide set of query parameters to omit (omitQueryParams)
   *               and set of query parameters to replace old one
   */
  switchTo(commands: any[], extras?: { queryParams?: Params; omitQueryParams?: string[] }): Promise<boolean> {
    const omitQueryParams = extras && extras.omitQueryParams || [];
    const queryParams = extras && extras.queryParams || {};
    return this.router.navigate(commands, {
      queryParams: omit({ ...this.state.queryParams, ...queryParams }, omitQueryParams),
      replaceUrl: true,
    }).then(this.createUpdater());
  }

  /**
   * Navigates to the parent route, if it exists.
   *
   * @param queryParams
   * @returns {Promise<boolean>}
   */
  navigateBack(queryParams?: Params): Promise<boolean> {
    if (!this.state.parentRoute) {
      throw new Error('Parent route is not defined');
    }

    return this.router.navigateByUrl(
      queryParams ? parametrizeUrl(this.state.parentRoute, queryParams) : this.state.parentRoute,
    ).then(this.createUpdater());
  }

  /**
   * Navigates to the provided route.
   *
   * @param url
   * @param extras
   * @returns {Promise<boolean>}
   */
  navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigateByUrl(url, extras)
      .then(this.createUpdater(extras));
  }

  /**
   * Answers if provided route is current.
   *
   * @param url
   * @param exact
   * @returns {boolean}
   */
  isActive(url: string | UrlTree, exact: boolean): boolean {
    return this.router.isActive(url, exact);
  }

  /**
   * Creates a function that updates state if it is necessary.
   *
   * This function is useful for promises.
   *
   * @param extras
   * @returns {(value:any)=>boolean}
   */
  private createUpdater(extras?: AppNavigationExtras): (value: boolean) => boolean {
    return (value) => {
      // update state only if location is changed
      // this router tracks only routes important for user.
      if (!extras || !extras.skipLocationChange) {
        this.state._update();
      }
      return value;
    };
  }
}
