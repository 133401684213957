/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

export interface DeepLink {
  commands: any[];
  extras?: NavigationExtras;
}

export interface DeepLinkFactory {
  (route: ActivatedRouteSnapshot): Maybe<DeepLink>;
}

export const DEEP_LINK_FACTORY = new InjectionToken<DeepLinkFactory>('DeepLinks');

@Injectable()
export class DeepLinkActivateService implements CanActivate, CanActivateChild {

  constructor(private router: Router,
              @Inject(DEEP_LINK_FACTORY) private deepLinkFactory: DeepLinkFactory) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.navigateToDeepLink(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.navigateToDeepLink(childRoute);
  }

  private navigateToDeepLink(route: ActivatedRouteSnapshot): boolean {
    const deepLink = this.deepLinkFactory(route);

    if (deepLink) {
      this.router.navigate(deepLink.commands, deepLink.extras);
    } else {
      throw new Error(`Deep link cannot be found for url '${route.url}'`);
    }

    return false;
  }
}
