/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ID } from '@gh/common-shared-data';
import { difference, isNil, keyBy, map, sortBy, sortedIndex, sortedIndexOf } from 'lodash';

export interface SelectionModel<T> {
  hasNew(id: ID): boolean;

  getNewIds(): ID[];

  getNewEntities(): T[];

  hasOld(id: ID): boolean;

  getOldIds(): ID[];

  getOldEntities(): T[];

  hasRemoved(id: ID): boolean;

  getRemovedIds(): ID[];

  getRemovedEntities(): T[];

  has(id: ID): boolean;

  getIds(): ID[];

  getEntities(): T[];

  hasChanges(): boolean;

  clear(): void;

  size(): number;
}

export function newSelectionModel<T = any>(ids: ID[]): SelectionModel<T>;
export function newSelectionModel<T>(entities: T[], getId: TransformFn<T, ID>): SelectionModel<T>;
export function newSelectionModel(idsOrEntities: any[], getId?: TransformFn<any, ID>): SelectionModel<any> {
  return new InitialSelectionModel(idsOrEntities, getId);
}

export function getAllSelectedEntities<T>(selection: SelectionModel<T>): T[] {
  return selection.getEntities();
}

export function getAllSelectedIds<T>(selection: SelectionModel<T>): ID[] {
  return selection.getIds();
}

export function getAllNewSelectedEntities<T>(selection: SelectionModel<T>): T[] {
  return selection.getNewEntities();
}

export function getSingleNewSelectedEntity<T>(selection: SelectionModel<T>): T {
  return selection.getNewEntities()[0];
}

export function getAllNewSelectedIds<T>(selection: SelectionModel<T>): ID[] {
  return selection.getNewIds();
}

export class InitialSelectionModel<T> implements SelectionModel<T> {
  private ids: ID[];
  private entities?: T[];

  constructor(idsOrEntities: any[], getId?: TransformFn<any, ID>) {
    this.ids = sortBy(getId ? map(idsOrEntities, getId) : idsOrEntities);
    this.entities = getId ? idsOrEntities : void 0;
  }

  hasNew(id: ID): boolean {
    return false;
  }

  getNewIds(): ID[] {
    return [];
  }

  getNewEntities(): T[] {
    return [];
  }

  hasRemoved(id: ID): boolean {
    return false;
  }

  getRemovedIds(): ID[] {
    return [];
  }

  getRemovedEntities(): T[] {
    return [];
  }

  hasOld(id: ID): boolean {
    return sortedIndexOf(this.ids, id) >= 0;
  }

  getOldIds(): ID[] {
    return this.ids;
  }

  getOldEntities(): T[] {
    const { entities } = this;
    if (isNil(entities)) {
      throw new Error('This instance of InitialSelectionModel was created without a list of selected entities');
    }
    return entities;
  }

  has(id: ID): boolean {
    return this.hasOld(id);
  }

  getIds(): ID[] {
    return this.getOldIds();
  }

  getEntities(): T[] {
    return this.getOldEntities();
  }

  hasChanges(): boolean {
    return false;
  }

  clear(): void {
    // nothing to do
  }

  size(): number {
    return this.ids.length;
  }
}

export class MutableSelectionModel<T> implements SelectionModel<T> {
  private newIds: ID[] = [];
  private newEntities: Hash<T> = {};
  private removedIds: ID[] = [];

  constructor(private baseModel: SelectionModel<T>, private getId: TransformFn<T, ID>) {
  }

  add(entity: T): void {
    const id = this.getId(entity);
    const removedIndex = sortedIndexOf(this.removedIds, id);
    if (removedIndex >= 0) {
      // if it is one of removed entities, then remove it back to life
      this.removedIds.splice(removedIndex, 1);
    } else if (!this.baseModel.has(id)) {
      const existingIndex = sortedIndexOf(this.newIds, id);
      // if this entity was not added earlier, then add it now
      if (existingIndex < 0) {
        const newIndex = sortedIndex(this.newIds, id);
        this.newIds.splice(newIndex, 0, id);
        this.newEntities[id] = entity;
      }
    }
  }

  remove(id: ID): void {
    const existingIndex = sortedIndexOf(this.newIds, id);
    if (existingIndex >= 0) {
      // remove from newly added entities
      this.newIds.splice(existingIndex, 1);
      delete this.newEntities[id];
    } else if (this.baseModel.has(id)) {
      const removedIndex = sortedIndexOf(this.removedIds, id);
      // if this entity was not removed earlier, then remove it now
      if (removedIndex < 0) {
        const newRemovedIndex = sortedIndex(this.removedIds, id);
        this.removedIds.splice(newRemovedIndex, 0, id);
      }
    }

  }

  hasNew(id: ID): boolean {
    return sortedIndexOf(this.newIds, id) >= 0;
  }

  getNewIds(): ID[] {
    return this.newIds;
  }

  getNewEntities(): T[] {
    return map(this.newEntities);
  }

  hasRemoved(id: ID): boolean {
    return sortedIndexOf(this.removedIds, id) >= 0;
  }

  getRemovedIds(): ID[] {
    return this.removedIds;
  }

  getRemovedEntities(): T[] {
    // returns all entities that was not removed
    const baseEntities = keyBy(this.baseModel.getEntities(), this.getId);
    return this.getRemovedIds().map((id) => baseEntities[id]);
  }

  hasOld(id: ID): boolean {
    return this.baseModel.has(id);
  }

  getOldIds(): ID[] {
    return this.baseModel.getIds();
  }

  getOldEntities(): T[] {
    return this.baseModel.getEntities();
  }

  has(id: ID): boolean {
    return this.hasNew(id) || this.hasInBaseModel(id);
  }

  getIds(): ID[] {
    return this.getNewIds().concat(this.getIdsFromBaseModel());
  }

  getEntities(): T[] {
    return this.getNewEntities().concat(this.getEntitiesFromBaseModel());
  }

  hasChanges(): boolean {
    return this.newIds.length > 0 || this.removedIds.length > 0;
  }

  clear(): void {
    this.newIds = [];
    this.newEntities = {};
    this.removedIds = [];
  }

  size(): number {
    const oldIds = this.baseModel.getOldIds();
    const { newIds, removedIds } = this;
    return newIds.length + difference(oldIds, removedIds).length;
  }

  private hasInBaseModel(id: ID): boolean {
    // returns true only if entity was not removed and exists in base model
    return sortedIndexOf(this.removedIds, id) < 0 && this.baseModel.has(id);
  }

  private getIdsFromBaseModel(): ID[] {
    // returns all not removed ids
    return difference(this.baseModel.getIds(), this.removedIds);
  }

  private getEntitiesFromBaseModel(): T[] {
    // returns all entities that was not removed
    const baseEntities = keyBy(this.baseModel.getEntities(), this.getId);
    return this.getIdsFromBaseModel().map((id) => baseEntities[id]);
  }
}

export class DecoratingReadOnlySelectionModel<T> implements SelectionModel<T> {
  constructor(private base: SelectionModel<T>) {
  }

  hasNew(id: ID): boolean {
    return this.base.hasNew(id);
  }

  getNewIds(): ID[] {
    return this.base.getNewIds();
  }

  getNewEntities(): T[] {
    return this.base.getNewEntities();
  }

  hasOld(id: ID): boolean {
    return this.base.hasOld(id);
  }

  getOldIds(): ID[] {
    return this.base.getOldIds();
  }

  getOldEntities(): T[] {
    return this.base.getOldEntities();
  }

  hasRemoved(id: ID): boolean {
    return this.base.hasRemoved(id);
  }

  getRemovedIds(): ID[] {
    return this.base.getRemovedIds();
  }

  getRemovedEntities(): T[] {
    return this.base.getRemovedEntities();
  }

  has(id: ID): boolean {
    return this.base.has(id);
  }

  getIds(): ID[] {
    return this.base.getIds();
  }

  getEntities(): T[] {
    return this.base.getEntities();
  }

  hasChanges(): boolean {
    return this.base.hasChanges();
  }

  clear(): void {
    this.base.clear();
  }

  size(): number {
    return this.base.size();
  }
}
