/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { of$ } from '@gh/rx';
import { filter$, first$, mapTo$ } from '@gh/rx/operators';
import { isNil } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import {
  isUploadFinalEvent,
  isUploadCompleted,
  isUploadStarted,
  UploadEngine,
  UploadEvent,
  UploadItem,
  UploadStatus,
} from './upload.interfaces';

/**
 * {@link UploadSlot} allows to track single upload process. This class allows to detach upload process from the UI
 * and rebind upload process to the UI component using ghUploadSlot directive.
 *
 * Also {@link UploadSlot} can be bound to the {@link AbstractControl} to update by the current state
 * of an upload process.
 */
export class UploadSlot {
  item?: UploadItem;
  event$: Observable<UploadEvent>;

  private control?: AbstractControl;
  private itemSubscription?: Subscription;

  constructor(private engine: UploadEngine, private uploadParams: Hash<any>) {
    this.event$ = this.engine.event$.pipe(
      filter$((event) => this.item ? event.item.uploadId === this.item.uploadId : false));
  }

  get assigned(): boolean {
    return !isNil(this.item);
  }

  /**
   * Assignes new upload process. Old upload process will be reset (cancelled)
   * @param item
   */
  assign(item: UploadItem): void {
    this.reset();

    this.setItem(item);
    this._subscribe();
  }

  /**
   * Sets undelrying control. This control will be updated by the current state of upload process.
   * @param control
   */
  setControl(control: AbstractControl): void {
    this.control = void 0;
    this.assign(control.value);
    this.control = control;
  }

  start(): void {
    if (this.item) {
      this.engine.start(this.item, this.uploadParams);
    }
  }

  cancel(): void {
    const { item } = this;

    if (isNil(item)) {
      return;
    }

    if (isUploadStarted(item) && !isUploadCompleted(item)) {
      this.engine.cancel(item);
    } else {
      this._unsubscribe();
    }

    this.setItem(void 0);
  }

  reset(): void {
    this.cancel();
  }

  destroy(): void {
    this._unsubscribe();
  }

  /**
   * Provides form validator for the assigned control.
   */
  formValidator(): AsyncValidatorFn {
    return (control) => {
      const { value } = control;

      if (isNil(value) || value.status !== UploadStatus.Uploading) {
        // tslint:disable-next-line:no-null-keyword
        return of$(null);
      }

      return this.event$.pipe(
        first$(isUploadFinalEvent),
        // tslint:disable-next-line:no-null-keyword
        mapTo$(null));
    };
  }

  private setItem(item?: UploadItem): void {
    const { control } = this;

    this.item = item;
    if (control) {
      control.setValue(item);
      control.markAsDirty();
    }
  }

  private _subscribe(): void {
    this.itemSubscription = this.event$.subscribe((event) => {
      const { item } = event;

      if (this.item && this.item.uploadId === item.uploadId) {
        this.setItem(item);
      }

      if (isUploadFinalEvent(event)) {
        this._unsubscribe();
      }
    });
  }

  private _unsubscribe(): void {
    if (this.itemSubscription) {
      this.itemSubscription.unsubscribe();
      this.itemSubscription = void 0;
    }
  }
}
