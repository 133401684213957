<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<h2 class="dialog-title">Login</h2>
<div class="overflow-x-auto">
  <form novalidate="" class="m-column" (submit)="onLogin($event)" [style.minWidth.px]="dialogWidth">
    <mat-table *ngIf="isMultipleFields" class="m-column" [dataSource]="dataSource">
      <ng-container cdkColumnDef="radio">
        <mat-header-cell *cdkHeaderCellDef="" class="user-cell--radio"></mat-header-cell>
        <mat-cell *cdkCellDef="let user" class="user-cell--radio">
          <mat-radio-button [checked]="selectedUser === user" (click)="onSelect(user)" color="primary"></mat-radio-button>
        </mat-cell>
      </ng-container>

      <ng-container *ngFor="let field of options.fields">
        <ng-container [cdkColumnDef]="field">
          <mat-header-cell *cdkHeaderCellDef="">{{ stringifyField(field) }}</mat-header-cell>
          <mat-cell *cdkCellDef="let user">{{ user[field] }}</mat-cell>
        </ng-container>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let user; columns: displayedColumns;" class="user-row" (click)="onSelect(user)"></mat-row>
    </mat-table>
    <div *ngIf="!isMultipleFields" class="m-column user-panel">
      <mat-radio-button *ngFor="let user of users" [checked]="selectedUser === user" (click)="onSelect(user)" color="primary">{{ user[firstFieldName] }}</mat-radio-button>
    </div>
    <div class="m-column user-panel">
      <mat-radio-button color="primary" [checked]="selectedUser === another" (click)="onAnotherUser()">Another User</mat-radio-button>
      <mat-form-field>
        <input matInput="" #anotherInput="" type="text" name="anotherUser" [(ngModel)]="anotherUserId" [disabled]="selectedUser !== another">
        <mat-error *ngIf="anotherUserError">Enter email of the target user</mat-error>
      </mat-form-field>
    </div>
    <div class="dialog-buttons">
      <button mat-raised-button="" color="primary" type="submit" [disabled]="anotherUserError">Log In</button>
    </div>
  </form>
</div>