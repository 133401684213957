/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { S3UploadConfig, S3UploadItem } from './s3-upload-engine.service';
import { UploadEvent, UploadEventType, UploadItem, UploadStatus } from './upload.interfaces';

export function s3UploadXhr(config: S3UploadConfig,
                            item: S3UploadItem,
                            params: Hash<any>): Observable<UploadEvent> {

  return new Observable((subscriber) => {
    const xhr = new XMLHttpRequest();
    const body = buildBody(xhr, config, item);
    xhr.open('POST', getPostUrl(config), true);
    xhr.withCredentials = true;
    xhr.responseType = 'document';

    xhr.upload.onprogress = (event: ProgressEvent) => {
      const progress = event.lengthComputable ? event.loaded / event.total : 0;
      subscriber.next({
        item: <UploadItem>{
          ...item,
          status: UploadStatus.Uploading,
          progress,
        },
        type: UploadEventType.Progress,
      });
    };

    xhr.onload = () => {
      if (isSuccessResponse(xhr)) {
        subscriber.next({
          item: <UploadItem>{
            ...item,
            status: UploadStatus.Completed,
            ...parseSuccessResponse(config, xhr),
            progress: 1,
          },
          type: UploadEventType.Completed,
        });
        subscriber.complete();
      } else {
        subscriber.next({
          item: <UploadItem>{
            ...item,
            status: UploadStatus.Error,
            progress: void 0,
          },
          type: UploadEventType.Error,
        });
        subscriber.error();
      }
    };

    xhr.onerror = () => {
      subscriber.next({
        item: <UploadItem>{
          ...item,
          status: UploadStatus.Error,
          progress: void 0,
        },
        type: UploadEventType.Error,
      });
      subscriber.error();
    };

    xhr.onabort = () => {
      subscriber.next({
        item: <UploadItem>{
          ...item,
          status: UploadStatus.NotStarted,
          progress: void 0,
        },
        type: UploadEventType.Cancelled,
      });
      subscriber.complete();
    };

    xhr.send(body);

    return () => {
      xhr.abort();
    };
  });
}

function isSuccessResponse(xhr: XMLHttpRequest): boolean {
  const { status } = xhr;
  return status >= 200 && status < 400; // tslint:disable-line:no-magic-numbers
}

function parseSuccessResponse(config: S3UploadConfig, xhr: XMLHttpRequest): Partial<S3UploadItem> {
  const { key, location } = config;

  return { key, location };
}

function getPostUrl(config: S3UploadConfig): string {
  return `${location.protocol}//${config.bucket}.s3.amazonaws.com/`;
}

function buildBody(xhr: XMLHttpRequest, config: S3UploadConfig, item: S3UploadItem): any {
  const { blob } = item;

  if (isNil(blob)) {
    throw new Error('Uploading process cannot be started, because Blob data to be uploaded is not defined');
  }

  const formData = new FormData();

  formData.append('key', config.key);
  formData.append('acl', config.acl);
  formData.append('Content-Type', item.mimeType || blob.type);
  formData.append('X-Amz-Credential', config.credentials);
  formData.append('X-Amz-Algorithm', config.algorithm);
  formData.append('X-Amz-Date', config.date);
  formData.append('Policy', config.policy);
  formData.append('X-AMZ-Signature', config.signature);
  formData.append('file', blob);

  return formData;
}
