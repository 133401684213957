/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AfterContentInit, Directive, HostBinding, NgZone, OnDestroy } from '@angular/core';
import { first$ } from '@gh/rx/operators';
import { ContentRulerFactory, TextRuler } from '../../../common';
import { CollapsibleInputDirective } from './collapsible-input.directive';
import { CollapsibleLabelComponent } from './collapsible-label.component';

@Directive({
  selector: '[ghCollapsibleContainer]',
})
export class CollapsibleContainerDirective implements AfterContentInit, OnDestroy {
  @HostBinding('class.container-collapsed')
  collapsed = false;

  input: CollapsibleInputDirective;

  label: CollapsibleLabelComponent;

  private visibleWidth: number;
  private initialized: boolean = true;
  private textRuler: TextRuler;

  constructor(private ngZone: NgZone,
              private contentRulerFactory: ContentRulerFactory) {

  }

  ngAfterContentInit(): void {
    this.textRuler = this.contentRulerFactory.createTextRuler(this.input.element);
    // this.recalculate();

    this.initialized = true;
  }

  ngOnDestroy(): void {
    if (this.textRuler) {
      this.contentRulerFactory.releaseRuler(this.textRuler);
    }
  }

  checkSize(text: string): void {
    this.collapsed = this.textRuler.getTextWidth(text) > this.visibleWidth;
  }

  recalculate(): void {
    if (this.initialized) {
      this.collapsed = false;
      this.ngZone.onMicrotaskEmpty.pipe(first$()).subscribe(() => {
        this.visibleWidth = this.input.element.offsetWidth;
        this.checkSize(this.input.value);
      });
    }
  }
}
