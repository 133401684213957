/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, ValueProvider } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreAuthModule, PermissionNameSetProvider, PermissionProvider } from '@gh/core-auth';
import { CoreDataModule } from '@gh/core-data';
import { CoreMlsModule, SubstitutionCompiler } from '@gh/core-mls';
import { CoreTypeModule, TYPE_SET } from '@gh/core-type';
import { S3UploadEngine, UploadEngine } from '@gh/core-ui';

import { AllowForDirective, ForbidForDirective, HasPermissionPipe } from './auth';
import { DictionaryContext, DictionaryService } from './dictionary';
import { HelpIdDirective, HelpProvider } from './help';
import { DurationPipe } from './pipes';
import {
  ApplicationComponentState,
  ApplicationDialogHooks,
  AppRouter,
  AppRouterOutletDirective,
  AppRouterState,
  AuthGuard,
  DeepLinkActivateService,
  InitDialogViewResolver,
  InitViewResolver,
  LeaveGuard,
  RouterBackDirective,
} from './routing';
import { defaultErrorHandler } from './sentry';
import {
  AppSubstitutionCompiler,
  ConfigurationHttpRequestInterceptor,
  LocalizationBusinessService,
  MaintenanceHttpResponseInterceptor,
  TitleManager,
} from './services';
import {
  ContainerAsyncDisabledDirective,
  ContainerStateDirective,
  FormStateBuilder,
  IfControlVisibleDirective,
  IsControlRequiredPipe,
  IsControlVisiblePipe,
} from './state';
import { DateTimeTypes, MaskedTypes, NumberTypes, QuantityTypes, TextTypes } from './types';

export function typeProviders(): ValueProvider[] {
  return [
    { provide: TYPE_SET, useValue: DateTimeTypes, multi: true },
    { provide: TYPE_SET, useValue: MaskedTypes, multi: true },
    { provide: TYPE_SET, useValue: NumberTypes, multi: true },
    { provide: TYPE_SET, useValue: QuantityTypes, multi: true },
    { provide: TYPE_SET, useValue: TextTypes, multi: true },
  ];
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreDataModule.forChild(),
    CoreMlsModule.forChild(),
    CoreAuthModule.forChild(),
    CoreTypeModule.forChild(),
  ],
  declarations: [
    AllowForDirective,
    ForbidForDirective,
    HasPermissionPipe,

    ContainerStateDirective,
    ContainerAsyncDisabledDirective,
    IfControlVisibleDirective,
    IsControlRequiredPipe,
    IsControlVisiblePipe,

    RouterBackDirective,

    AppRouterOutletDirective,

    DurationPipe,

    HelpIdDirective,
  ],
  exports: [
    CommonModule,
    RouterModule,

    CoreAuthModule,
    CoreMlsModule,
    CoreTypeModule,

    AllowForDirective,
    ForbidForDirective,
    HasPermissionPipe,
    ContainerStateDirective,
    ContainerAsyncDisabledDirective,
    IfControlVisibleDirective,
    IsControlRequiredPipe,
    IsControlVisiblePipe,

    RouterBackDirective,

    AppRouterOutletDirective,

    DurationPipe,

    HelpIdDirective,
  ],
})
export class CommonSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CommonSharedModule,
      providers: [
        defaultErrorHandler(),
        typeProviders(),
        { provide: PermissionProvider, useExisting: PermissionNameSetProvider },
        ConfigurationHttpRequestInterceptor,
        MaintenanceHttpResponseInterceptor,
        DictionaryService,
        DictionaryContext,
        AuthGuard,
        LeaveGuard,
        AppRouter,
        AppRouterState,
        InitViewResolver,
        InitDialogViewResolver,
        ApplicationComponentState,
        ApplicationDialogHooks,
        FormStateBuilder,
        { provide: UploadEngine, useClass: S3UploadEngine },
        DeepLinkActivateService,
        { provide: SubstitutionCompiler, useClass: AppSubstitutionCompiler },
        HelpProvider,
        LocalizationBusinessService,
        TitleManager,
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: CommonSharedModule,
      providers: [
        InitViewResolver,
        InitDialogViewResolver,
      ],
    };
  }
}
