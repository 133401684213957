/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { dateTimeType } from '@gh/core-type';

export const DateTimeTypes = {
  Timestamp: dateTimeType({
    format: 'shortDatetime',
  }),
  DateTime: dateTimeType({
    format: 'shortDatetime',
  }),
  DayMonth: dateTimeType({
    format: 'dayMonth',
    inputFormat: 'date',
  }),
  DayMonthYear: dateTimeType({
    format: 'dayMonthYear',
    inputFormat: 'date',
  }),
  ShortMonthYear: dateTimeType({
    format: 'shortMonthYear',
    inputFormat: 'date',
  }),
  ShortDate: dateTimeType({
    format: 'shortDate',
    inputFormat: 'date',
  }),
  MediumDateAndWeekday: dateTimeType({
    format: 'mediumDateAndWeekday',
  }),
  ShortTime: dateTimeType({
    format: 'shortTime',
    inputFormat: 'time',
  }),
};
