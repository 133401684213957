/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var SanitizeHtmlPipe = /** @class */ (function () {
    function SanitizeHtmlPipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SanitizeHtmlPipe.prototype.transform = function (value) {
        return this.sanitizer.bypassSecurityTrustHtml(value || '');
    };
    SanitizeHtmlPipe.ɵfac = function SanitizeHtmlPipe_Factory(t) { return new (t || SanitizeHtmlPipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer)); };
    SanitizeHtmlPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "sanitizeHtml", type: SanitizeHtmlPipe, pure: true });
    return SanitizeHtmlPipe;
}());
export { SanitizeHtmlPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SanitizeHtmlPipe, [{
        type: Pipe,
        args: [{
                name: 'sanitizeHtml',
            }]
    }], function () { return [{ type: i1.DomSanitizer }]; }, null); })();
var SanitizeUrlPipe = /** @class */ (function () {
    function SanitizeUrlPipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SanitizeUrlPipe.prototype.transform = function (value) {
        return this.sanitizer.bypassSecurityTrustUrl(value || '');
    };
    SanitizeUrlPipe.ɵfac = function SanitizeUrlPipe_Factory(t) { return new (t || SanitizeUrlPipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer)); };
    SanitizeUrlPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "sanitizeUrl", type: SanitizeUrlPipe, pure: true });
    return SanitizeUrlPipe;
}());
export { SanitizeUrlPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SanitizeUrlPipe, [{
        type: Pipe,
        args: [{
                name: 'sanitizeUrl',
            }]
    }], function () { return [{ type: i1.DomSanitizer }]; }, null); })();
