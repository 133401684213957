/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { eversignDialogRoute, isDataSourceEmpty } from '@gh/common-external-shared';
import { getRouteInitData, getRouteParam } from '@gh/common-shared';

import { ID } from '@gh/common-shared-data';
import { TranslationService } from '@gh/core-mls';
import { DialogService, WaitingService } from '@gh/core-ui';
import { isNotNil, openInNewWindow } from '@gh/core-util';
import { CustomerUserContext } from '@gh/customer-shared';
import { OfficeStatus } from '@gh/customer-shared-data';
import { filter$, skip$ } from '@gh/rx/operators';

import { includes } from 'lodash';

import { CustomerEDocumentBusinessService } from '../../resources/customer-e-document-business.service';
import { EDocumentListItem } from '../../resources/customer-e-document.model';

@Component({
  selector: 'gh-customer-e-documents-list',
  templateUrl: 'customer-e-documents-list.component.html',
  styleUrls: ['customer-e-documents-list.component.scss'],
})
export class CustomerEDocumentsListComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  jobId: ID;
  displayedColumns: string[] = ['sentDate', 'documents', 'isSigned', 'action'];
  eDocumentListDataSource: MatTableDataSource<EDocumentListItem> = new MatTableDataSource([]);

  constructor(private businessService: CustomerEDocumentBusinessService,
              private route: ActivatedRoute,
              private waitingService: WaitingService,
              private translationService: TranslationService,
              private dialogs: DialogService,
              private userContext: CustomerUserContext) {
  }

  ngOnInit(): void {
    const { eDocumentList } = getRouteInitData(this.route);
    this.jobId = getRouteParam(this.route, 'jobId');
    this.initEDocumentList(eDocumentList);
    this.eDocumentListDataSource.sort = this.sort;
    this.route.paramMap.pipe(
      skip$(1))
      .subscribe(({ params }: any) => {
        this.jobId = params.jobId;
        this.loadEDocuments(this.jobId);
      });
  }

  isDocumentsExist(): boolean {
    return !isDataSourceEmpty(this.eDocumentListDataSource);
  }

  isEDocumentSigned({ isSigned }: EDocumentListItem): boolean {
    return isSigned;
  }

  signEDocument({ embeddedSigningUrl }: EDocumentListItem): void {
    if (embeddedSigningUrl) {
      this.dialogs.openWithInput(eversignDialogRoute, {
        url: embeddedSigningUrl,
      }).afterClosed().pipe(
        filter$(isNotNil))
        .subscribe(() => this.loadEDocuments(this.jobId));
    }
  }

  viewEDocument({ viewUrl }: EDocumentListItem): void {
    if (viewUrl) {
      openInNewWindow(viewUrl);
    }
  }

  isEDocumentActive({ isSigned }: EDocumentListItem): boolean {
    return !isSigned
      && includes([OfficeStatus.Active, OfficeStatus.Blocked], this.userContext.currentJob.officeStatus)
      && !(this.userContext.currentJob.isFinalized || this.userContext.currentJob.isNotProceeding);
  }

  statusColumnValue({ isSigned }: EDocumentListItem): Maybe<string> {
    return isSigned
      ? this.translationService.get('lbl_signed')
      : this.translationService.get('lbl_waiting_for_signing');
  }

  getEDocumentId(index: number, eDocument: EDocumentListItem): ID {
    return eDocument.id;
  }

  private loadEDocuments(jobId: ID): void {
    this.businessService
      .findAll(jobId).pipe(
      this.waitingService.operator())
      .subscribe(this.initEDocumentList.bind(this));
  }

  private initEDocumentList(eDocumentList: EDocumentListItem[]): void {
    this.eDocumentListDataSource.data = eDocumentList;
  }
}
