/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input } from '@angular/core';
import { WaitingContext, WaitingMode } from './waiting-context.service';
import * as i0 from "@angular/core";
import * as i1 from "./waiting-context.service";
var toWaitingMode = function (mode) {
    switch (mode) {
        case 'background':
            return WaitingMode.Background;
        case 'blocked':
            return WaitingMode.Blocked;
        default:
            return WaitingMode.None;
    }
};
var WaitDirective = /** @class */ (function () {
    function WaitDirective(context) {
        this.context = context;
        this._show = false;
    }
    Object.defineProperty(WaitDirective.prototype, "show", {
        get: function () {
            return this._show;
        },
        set: function (show) {
            if (show !== this._show) {
                this.setShow(show);
            }
        },
        enumerable: true,
        configurable: true
    });
    WaitDirective.prototype.ngOnDestroy = function () {
        this.setShow(false);
    };
    WaitDirective.prototype.setShow = function (show) {
        if (show) {
            this.process = this.context.start(toWaitingMode(this.mode), 'directive');
        }
        else if (this.process) {
            this.process.stop();
        }
        this._show = show;
    };
    WaitDirective.ɵfac = function WaitDirective_Factory(t) { return new (t || WaitDirective)(i0.ɵɵdirectiveInject(i1.WaitingContext)); };
    WaitDirective.ɵdir = i0.ɵɵdefineDirective({ type: WaitDirective, selectors: [["gh-wait"]], inputs: { mode: "mode", show: "show" } });
    return WaitDirective;
}());
export { WaitDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(WaitDirective, [{
        type: Directive,
        args: [{
                selector: 'gh-wait',
            }]
    }], function () { return [{ type: i1.WaitingContext }]; }, { mode: [{
            type: Input
        }], show: [{
            type: Input
        }] }); })();
