/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { entity } from '@gh/core-data';
import { CustomerUserProfileDto } from './profiles.dto';

export const CustomerUserProfileMapper = entity<CustomerUserProfileDto>({});
