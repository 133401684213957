/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil, isString, noop } from 'lodash';
import { createTextMaskInputElement } from 'text-mask-core';
import { MaskedTextType } from '../core/type.interfaces';
import { TypeService } from '../services/type.service';

/**
 * This module declares directive for masked input component. We had to define own implementation because it provides
 * better integration with masked type approach (core-type package)
 *
 * Implementation was taken from this repository https://github.com/text-mask/text-mask.
 * File: https://github.com/text-mask/text-mask/blob/master/angular2/src/angular2TextMask.ts
 */
@Directive({
  selector: '[ghMaskedType]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaskedTypeDirective,
    multi: true,
  }],
})
export class MaskedTypeDirective implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input('ghMaskedTypeConfig') config: any = {
    guide: false,
    placeholderChar: '_',
    pipe: void 0,
    keepCharPositions: false,
    onReject: void 0,
    onAccept: void 0,
  };

  private textMaskInputElement: any;
  private inputElement: HTMLInputElement;

  // stores the last value for comparison
  private lastValue: any;

  private _type: MaskedTextType;

  private _onChange = noop;
  private _onTouched = noop;

  constructor(private renderer: Renderer2, private element: ElementRef, private typeService: TypeService) {
  }

  @Input('ghMaskedType')
  set type(type: MaskedTextType | string) {
    this._type = isString(type) ? this.typeService.get(type) : type;
  }

  ngOnInit(): void {
    this.setupMask();
    if (this._type['maxLength']) {
      this.renderer.setAttribute(this.element.nativeElement, 'maxlength', String(this._type['maxLength']));
    }
  }

  ngAfterViewInit(): void {
    if (!this.inputElement) {
      // the element was not found when ngOnInit ran, let's try to find it again
      this.setupMask();
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(str: string): void {
    this.textMaskInputElement.update(str);

    const nextValue = this.element.nativeElement.value;

    // check against the last value to prevent firing ngModelChange despite no changes
    if (this.lastValue !== nextValue) {
      this.lastValue = nextValue;
      this._onChange(nextValue);
    }
  }

  @HostListener('blur')
  onTouched(): void {
    this._onTouched();
  }

  writeValue(value: any): void {
    if (!isNil(value) && this.textMaskInputElement !== void 0) {
      this.textMaskInputElement.update(value);
    } else {
      this.element.nativeElement.value = '';
    }
    this.lastValue = this.element.nativeElement.value;
  }

  registerOnChange(fn: (value: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
  }

  private setupMask(): void {
    // tslint:disable-next-line:prefer-conditional-expression
    if (this.element.nativeElement.tagName === 'INPUT') {
      // `textMask` directive is used directly on an input element
      this.inputElement = this.element.nativeElement;
    } else {
      // `textMask` directive is used on an abstracted input element, `ion-input`, `mat-input`, etc
      this.inputElement = this.element.nativeElement.getElementsByTagName('INPUT')[0];
    }

    if (this.inputElement) {
      this.textMaskInputElement = createTextMaskInputElement({
        ...this.config,
        inputElement: this.inputElement,
        mask: this._type.mask,
      });
    }
  }
}
