/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:directive-selector use-host-property-decorator
import { Directive, Input } from '@angular/core';
import * as i0 from "@angular/core";
/**
 * This directive allows to define calculatable attribute for automation testing.
 *
 * TODO: It is necessary to create custom webpack loader in order to remove all data-automation-id directives
 *       from all templates
 */
var AutomationIDDirective = /** @class */ (function () {
    function AutomationIDDirective() {
    }
    AutomationIDDirective.ɵfac = function AutomationIDDirective_Factory(t) { return new (t || AutomationIDDirective)(); };
    AutomationIDDirective.ɵdir = i0.ɵɵdefineDirective({ type: AutomationIDDirective, selectors: [["", "automation-id", ""]], hostVars: 1, hostBindings: function AutomationIDDirective_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵattribute("data-automation-id", ctx.automationId);
        } }, inputs: { automationId: ["automation-id", "automationId"] } });
    return AutomationIDDirective;
}());
export { AutomationIDDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AutomationIDDirective, [{
        type: Directive,
        args: [{
                selector: '[automation-id]',
                host: {
                    '[attr.data-automation-id]': 'automationId',
                },
            }]
    }], null, { automationId: [{
            type: Input,
            args: ['automation-id']
        }] }); })();
