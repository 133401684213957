/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { MessageSeverity } from '@gh/core-messages';
import { TranslationParams, TranslationService } from '@gh/core-mls';
import { DialogInput } from '../dialog-input';
import { isDetailedMessage, splitByLines } from '../dialog.utils';

export interface MessageDialogConfig {
  severity: MessageSeverity;
  message?: string;
  messageId?: string;
  messageParams?: TranslationParams;
  disableClose?: boolean;
  panelClass?: string;
  btnColor?: string;
}

export class MessageDialogBaseComponent implements OnInit, DialogInput<MessageDialogConfig> {
  input: MessageDialogConfig;
  detailed: boolean = false;
  message?: string;
  title?: string;
  description?: string;

  constructor(private translationService: TranslationService,
              public dialogRef: MatDialogRef<MessageDialogBaseComponent>,
              private elementRef: ElementRef,
              private renderer: Renderer2) {
  }

  get typeInfo(): boolean {
    const { severity } = this.input;
    return severity === MessageSeverity.Info;
  }

  get typeSuccess(): boolean {
    const { severity } = this.input;
    return severity === MessageSeverity.Success;
  }

  get typeErrorOrWarning(): boolean {
    const { severity } = this.input;
    return severity === MessageSeverity.Error || severity === MessageSeverity.Warning;
  }

  get btnColor(): string {
    const { btnColor } = this.input;

    if (btnColor) {
      return btnColor;
    }

    return this.typeSuccess || this.typeInfo ? 'primary' : 'accent';
  }

  ngOnInit(): void {
    const { messageId, messageParams, message } = this.input;

    if (messageId) {
      const rawMessage = this.translationService.getRaw(messageId);

      if (isDetailedMessage(rawMessage)) {
        this.title = splitByLines(this.translationService.get([messageId, 'title'].join('.'), messageParams));
        // tslint:disable:max-line-length
        this.description = splitByLines(this.translationService.get(
          [messageId, 'description'].join('.'),
          messageParams,
        ));
        this.detailed = true;
      } else {
        this.message = splitByLines(this.translationService.get(messageId, messageParams));
      }
    } else if (message) {
      this.message = splitByLines(message);
    }

    this.renderer.addClass(this.elementRef.nativeElement, this.detailed
      ? 'message-dialog--detailed' : 'message-dialog--simple');
  }
}
