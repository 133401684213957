/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'lodash';
import * as i0 from "@angular/core";
var UppercaseDirective = /** @class */ (function () {
    function UppercaseDirective(elementRef) {
        this.elementRef = elementRef;
        this._onChange = noop;
        this._onTouched = noop;
    }
    UppercaseDirective.prototype.writeValue = function (value) {
        this.updateInputValue(value);
        this.lastValue = value;
    };
    UppercaseDirective.prototype.registerOnChange = function (onChange) {
        this._onChange = onChange;
    };
    UppercaseDirective.prototype.registerOnTouched = function (onTouched) {
        this._onTouched = onTouched;
    };
    UppercaseDirective.prototype.setDisabledState = function (isDisabled) {
        this.elementRef.nativeElement.disabled = isDisabled;
    };
    UppercaseDirective.prototype.onInput = function (value) {
        var uppercased = this.updateInputValue(value);
        if (this.lastValue !== uppercased) {
            this.lastValue = uppercased;
            this._onChange(uppercased);
        }
    };
    UppercaseDirective.prototype.onBlur = function () {
        this._onTouched();
    };
    UppercaseDirective.prototype.updateInputValue = function (value) {
        var uppercased = value && value.toUpperCase() || '';
        this.elementRef.nativeElement.value = uppercased;
        return uppercased;
    };
    UppercaseDirective.ɵfac = function UppercaseDirective_Factory(t) { return new (t || UppercaseDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    UppercaseDirective.ɵdir = i0.ɵɵdefineDirective({ type: UppercaseDirective, selectors: [["input", "ghUppercase", ""]], hostBindings: function UppercaseDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("input", function UppercaseDirective_input_HostBindingHandler($event) { return ctx.onInput($event.target.value); })("blur", function UppercaseDirective_blur_HostBindingHandler() { return ctx.onBlur(); });
        } }, features: [i0.ɵɵProvidersFeature([{ provide: NG_VALUE_ACCESSOR, useExisting: UppercaseDirective, multi: true }])] });
    return UppercaseDirective;
}());
export { UppercaseDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UppercaseDirective, [{
        type: Directive,
        args: [{
                selector: 'input[ghUppercase]',
                providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UppercaseDirective, multi: true }],
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { onInput: [{
            type: HostListener,
            args: ['input', ['$event.target.value']]
        }], onBlur: [{
            type: HostListener,
            args: ['blur']
        }] }); })();
