/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { InfiniteMatDataSource } from '@gh/common-external-shared';

import { getRouteInitData } from '@gh/common-shared';
import { ID } from '@gh/common-shared-data';
import { openInNewWindow } from '@gh/core-util';

import { SharedDocumentListItem } from '../../resources';
import { CustomerSharedDocumentBusinessService } from '../../resources/customer-shared-document-business.service';

@Component({
  selector: 'gh-customer-shared-document-list',
  templateUrl: 'customer-shared-document-list.component.html',
})
export class CustomerSharedDocumentListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  jobId: ID;
  displayedColumns: string[] = ['fileName', 'action'];

  sharedDocumentDataSource: InfiniteMatDataSource<SharedDocumentListItem>;

  constructor(private businessService: CustomerSharedDocumentBusinessService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const { sharedDocumentDataSource } = getRouteInitData(this.route);
    this.sharedDocumentDataSource = InfiniteMatDataSource.wrap(sharedDocumentDataSource);
    this.route.paramMap
      .subscribe(({ params }: any): void => {
        this.jobId = params.jobId;
        this.loadSharedDocuments(this.jobId);
      });
  }

  ngAfterViewInit(): void {
    this.sharedDocumentDataSource.sort = this.sort;
  }

  viewSharedDocument({ fileUrl }: SharedDocumentListItem): void {
    if (fileUrl) {
      openInNewWindow(fileUrl);
    }
  }

  getSharedDocumentId(index: number, sharedDocument: SharedDocumentListItem): ID {
    return sharedDocument.id;
  }

  private loadSharedDocuments(jobId: ID): void {
    this.sharedDocumentDataSource = InfiniteMatDataSource.wrap(this.businessService.findAll(jobId));
    this.sharedDocumentDataSource.sort = this.sort;
  }
}
