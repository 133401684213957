/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { identity } from '@gh/core-data';

export const EtagDictionaryMapper = identity();
