/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { of$, throwError$ } from '@gh/rx';
import * as i0 from "@angular/core";
var DictionaryContext = /** @class */ (function () {
    function DictionaryContext() {
        this.cached = {};
    }
    DictionaryContext.prototype.get = function (code) {
        if (this.cached[code]) {
            return of$(this.cached[code]);
        }
        else {
            return throwError$("Enum is not defined for code " + code);
        }
    };
    DictionaryContext.prototype.put = function (code, dictionary) {
        this.cached[code] = dictionary;
    };
    DictionaryContext.ɵfac = function DictionaryContext_Factory(t) { return new (t || DictionaryContext)(); };
    DictionaryContext.ɵprov = i0.ɵɵdefineInjectable({ token: DictionaryContext, factory: DictionaryContext.ɵfac });
    return DictionaryContext;
}());
export { DictionaryContext };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DictionaryContext, [{
        type: Injectable
    }], null, null); })();
