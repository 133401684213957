/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { flatten, isNil, memoize } from 'lodash';
import { isNotNil } from './data-util';
export function domOffset(el) {
    var box = el.getBoundingClientRect();
    var win = window;
    var docElem = document.documentElement; // tslint:disable-line:no-non-null-assertion
    return {
        left: box.left + (win.pageXOffset || docElem.scrollLeft) - (docElem.clientLeft || 0),
        top: box.top + (win.pageYOffset || docElem.scrollTop) - (docElem.clientTop || 0),
    };
}
export function domOffsetUntil(el, lastEl) {
    var left = 0;
    var top = 0;
    var currentEl = el;
    while (currentEl && currentEl !== lastEl) {
        left += currentEl.offsetLeft;
        top += currentEl.offsetTop;
        currentEl = currentEl.offsetParent;
    }
    if (isNil(currentEl)) {
        return;
    }
    return { left: left, top: top };
}
export function stopEvent(event) {
    stopPropagation(event);
    preventDefault(event);
}
export function stopPropagation(event) {
    event.stopPropagation();
}
export function preventDefault(event) {
    event.preventDefault();
}
export function toPx(n) {
    return n + "px";
}
function getParentContainClass(element, classNames) {
    var parentElement = element && element.parentElement && element.parentElement;
    while (parentElement) {
        if (classNames.some(function (className) { return parentElement.classList.contains(className); })) {
            return parentElement;
        }
        else {
            parentElement = parentElement.parentElement;
        }
    }
}
export function getClosest(element, classNameToCheck) {
    var classNames = classNameToCheck.split(',');
    if (element && classNames.some(function (className) { return element.classList.contains(className); })) {
        return element;
    }
    else {
        return getParentContainClass(element, classNames);
    }
}
export function hasClosest(element, classNameToCheck) {
    return isNotNil(getClosest(element, classNameToCheck));
}
export function getVScrollbarWidth(el) {
    return el.offsetWidth - el.clientWidth;
}
export function isElementFocused(el) {
    return el === document.activeElement;
}
export var getScrollbarWidth = function () { return getScrollbarSettings().width; };
export var getScrollbarHeight = function () { return getScrollbarSettings().height; };
export var mergeClassNames = function () {
    var classNames = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classNames[_i] = arguments[_i];
    }
    return flatten(classNames.filter(Boolean));
};
/**
 * Returns browser specific dimensions of scroll.
 * Implementation of this method was copied from ag-grid library (utils.ts#getScrollbarWidth),
 * but was generalized for width and height calculation
 */
var getScrollbarSettings = memoize(function () {
    var outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.height = '100px';
    // outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);
    var widthNoScroll = outer.offsetWidth;
    var heightNoScroll = outer.offsetHeight;
    // force scrollbars
    outer.style.overflow = 'scroll';
    // add innerdiv
    var inner = document.createElement('div');
    inner.style.width = '100%';
    inner.style.height = '100%';
    outer.appendChild(inner);
    var widthWithScroll = inner.offsetWidth;
    var heightWithScroll = inner.offsetHeight;
    // remove divs
    outer.parentNode.removeChild(outer); // tslint:disable-line:no-non-null-assertion
    return {
        width: widthNoScroll - widthWithScroll,
        height: heightNoScroll - heightWithScroll,
    };
});
export function isDomElement(node) {
    return node.nodeType === Node.ELEMENT_NODE;
}
export function createScriptElement(url) {
    var node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.charset = 'utf-8';
    return node;
}
export function createLinkElement(url) {
    var node = document.createElement('link');
    node.href = url;
    node.type = 'text/css';
    node.rel = 'stylesheet';
    return node;
}
export function createStyleElement() {
    var node = document.createElement('style');
    node.type = 'text/css';
    return node;
}
export function appendElementToHead(element) {
    document.getElementsByTagName('head')[0].appendChild(element);
}
export function destroyElement(el) {
    var parentElement = el.parentElement;
    if (parentElement) {
        parentElement.removeChild(el);
    }
}
export function getStyle(el, ruleName) {
    return getComputedStyle(el)[ruleName];
}
