/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { pluck$ } from '@gh/rx/operators';
import * as i0 from "@angular/core";
var Pluck$Pipe = /** @class */ (function () {
    function Pluck$Pipe() {
    }
    Pluck$Pipe.prototype.transform = function (ob$) {
        var properties = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            properties[_i - 1] = arguments[_i];
        }
        if (!ob$) {
            return ob$;
        }
        return ob$.pipe(pluck$.apply(void 0, properties));
    };
    Pluck$Pipe.ɵfac = function Pluck$Pipe_Factory(t) { return new (t || Pluck$Pipe)(); };
    Pluck$Pipe.ɵpipe = i0.ɵɵdefinePipe({ name: "pluck$", type: Pluck$Pipe, pure: true });
    return Pluck$Pipe;
}());
export { Pluck$Pipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(Pluck$Pipe, [{
        type: Pipe,
        args: [{ name: 'pluck$', pure: true }]
    }], null, null); })();
