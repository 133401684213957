/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, HostBinding, Input } from '@angular/core';
import * as i0 from "@angular/core";
var _c0 = ["*"];
var ExtDialogButtonBarComponent = /** @class */ (function () {
    function ExtDialogButtonBarComponent() {
        this.align = 'none';
    }
    Object.defineProperty(ExtDialogButtonBarComponent.prototype, "isAlignNone", {
        get: function () {
            return this.align === 'none';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtDialogButtonBarComponent.prototype, "isAlignCenter", {
        get: function () {
            return this.align === 'center';
        },
        enumerable: true,
        configurable: true
    });
    ExtDialogButtonBarComponent.ɵfac = function ExtDialogButtonBarComponent_Factory(t) { return new (t || ExtDialogButtonBarComponent)(); };
    ExtDialogButtonBarComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtDialogButtonBarComponent, selectors: [["gh-ext-dialog-button-bar"]], hostVars: 4, hostBindings: function ExtDialogButtonBarComponent_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵclassProp("m-is-align-none", ctx.isAlignNone)("m-is-align-center", ctx.isAlignCenter);
        } }, inputs: { align: "align" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function ExtDialogButtonBarComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵprojection(0);
        } }, styles: ["[_nghost-%COMP%] {\n  border-top: 1px solid #eaeaea; }\n\n.m-is-align-center[_nghost-%COMP%] {\n  display: flex;\n  flex-direction: row;\n  justify-content: center; }\n\n@media (max-width: 959px) {\n  [_nghost-%COMP%] {\n    padding-top: 10px;\n    margin: 0 15px 25px; } }\n\n@media (min-width: 960px) {\n  [_nghost-%COMP%] {\n    padding-top: 20px;\n    margin: 0 30px 40px; } }"] });
    return ExtDialogButtonBarComponent;
}());
export { ExtDialogButtonBarComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtDialogButtonBarComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-dialog-button-bar',
                template: '<ng-content></ng-content>',
                styleUrls: ['ext-dialog-button-bar.component.scss'],
            }]
    }], null, { align: [{
            type: Input
        }], isAlignNone: [{
            type: HostBinding,
            args: ['class.m-is-align-none']
        }], isAlignCenter: [{
            type: HostBinding,
            args: ['class.m-is-align-center']
        }] }); })();
