/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { entity } from '@gh/core-data';
import { DictionaryItemDto } from './dictionary-item.dto';

export const DictionaryItemMapper = entity<DictionaryItemDto>();
