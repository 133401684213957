/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { coalesce, isNotNil } from '@gh/core-util';
import { invert, isNil, mapKeys, mapValues, noop } from 'lodash';
import { Enum } from '../enum';
import { ArrayDataMapper } from './array-data-mapper';
import { BitSetMapper } from './bit-set-mapper';
import { ConstantBodyHttpDataMapper } from './constant-body-http-data-mapper';
import { getDateMapper } from './date-mapper';
import { EntityMapper } from './entity-mapper';
import { EnumMapper } from './enum-mapper';
import { FileHttpDataMapper } from './file-http-data-mapper';
import { IdentityEntityMapper } from './identity-entity-mapper';
import { NoAuthRefreshHttpDataMapper } from './no-auth-refresh-http-data-mapper';
import { OptimisticLockingHttpDataMapper } from './optimistic-locking-http-data-mapper';
import { PrivilegedTokenHttpDataMapper } from './privileged-token-http-data-mapper';
/**
 * Creates mapper for the given entity, having a set of fields returned by `mappings` function (the second parameter).
 * There is no sense to declare all fields in the `mappings` function.
 * Developer MUST define only fields with the special mapping.
 * All other fields will be mapped as is.
 *
 * @param type
 * @param mappings
 */
export function entity(mappings) {
    if (mappings === void 0) { mappings = {}; }
    return new EntityMapper(mappings);
}
/**
 * Creates mapper for the given entity, having a set of fields defined by `mappings` (the second parameter).
 * There is no sense to declare all fields in the `mappings`.
 * Developer MUST define only fields with the special mapping.
 * All other fields will be mapped as is.
 *
 * @param type
 * @param mappings
 */
export function subentity(mappings) {
    if (mappings === void 0) { mappings = {}; }
    return new EntityMapper(mappings);
}
/**
 * Creates a mapper for a list of entities. Each entity is mapped using provided mapper.
 *
 * @param mapper
 */
export function arrayOf(mapper) {
    return new ArrayDataMapper(mapper);
}
/**
 * Creates a mapper with optimistic locking support.
 * This mapper cares about correct handling of ETag and If-Match headers.
 * This mapper returns {@link HttpDataMapper}.
 * This mapper MUST be added as a final stage of mapping, but can be combined with other {@link HttpDataMapper}s.
 *
 * Target entity MUST implement {@link OptimisticLockingSupport}.
 *
 * @param mapper
 */
export function optimisticLockingOf(mapper) {
    return new OptimisticLockingHttpDataMapper(mapper);
}
/**
 * Creates a mapper with privileged token support.
 * This mapper cares about correct handling of ETag and If-Match headers.
 * This mapper returns {@link HttpDataMapper}.
 * This mapper MUST be added as a final stage of mapping, but can be combined with other {@link HttpDataMapper}s.
 *
 * Target entity MUST implement {@link PrivilegedTokenSupport}.
 *
 * @param mapper
 */
export function privilegedTokenOf(mapper) {
    return new PrivilegedTokenHttpDataMapper(mapper);
}
/**
 * When this mapper is used, auth token is not tried to be refreshed.
 *
 * @param mapper
 */
export function noAuthRefreshOf(mapper) {
    return new NoAuthRefreshHttpDataMapper(mapper);
}
/**
 * Creates a mapper which always generates empty body and creates empty entity.
 *
 * @param mapper
 * @param emptyValue value used as empty value
 * @returns {ConstantBodyHttpDataMapper}
 */
export function emptyOf(mapper, emptyValue) {
    if (emptyValue === void 0) { emptyValue = {}; }
    return new ConstantBodyHttpDataMapper(mapper, emptyValue);
}
/**
 * Creates a mapper for given enumeration.
 *
 * @param type
 */
export function enumeration(type) {
    var Mapper = /** @class */ (function (_super) {
        __extends(Mapper, _super);
        function Mapper() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.enumeration = type;
            return _this;
        }
        return Mapper;
    }(EnumMapper));
    return new Mapper();
}
/**
 * Creates a mapper for {@link Date} value.
 *
 * @param format
 */
export function date(format) {
    return getDateMapper(format);
}
/**
 * Creates a mapper for {@link BitSet} value
 *
 * @param length
 */
export function bitSet(length) {
    return new BitSetMapper(length);
}
/**
 * Creates identity mapper which returns original data for serialize and deserialize operations.
 */
export function identity(clone) {
    if (clone === void 0) { clone = true; }
    return new IdentityEntityMapper(clone);
}
/**
 * Returns mapper that aloways returns undefined for serialization and deserialization operations
 */
export function none() {
    return custom({
        serialize: noop,
        deserialize: noop,
    });
}
/**
 * On serialization if object is nil this method passes given object in the underlying mapper.
 * On deserialization if deserialized object is nil this method returns given object.
 *
 * @param mapper
 * @param value
 */
export function coalesceOf(mapper, value) {
    return custom({
        serialize: function (obj) { return mapper.serialize(coalesce(obj, value)); },
        deserialize: function (obj) { return coalesce(mapper.deserialize(obj), value); },
    });
}
/**
 * Creates mapper which serializes/deserializes values according to the provided map.
 * Fields of map define serialization rules,
 * where key represents serialized value and value represents deserialized value.
 *
 * @param serializedToValue
 */
export function constantMapper(serializedToValue) {
    var valueToSerialized = invert(serializedToValue);
    return custom({
        serialize: function (obj) {
            if (isNotNil(obj)) {
                var serialized = valueToSerialized[obj];
                if (isNil(serialized)) {
                    throw new Error("ConstantMapper: cannot find serialized value for \"" + obj + "\"");
                }
                return serialized;
            }
        },
        deserialize: function (serialized) {
            if (isNotNil(serialized)) {
                var value = serializedToValue[serialized];
                if (isNil(value)) {
                    throw new Error("ConstantMapper: cannot find deserialized value for \"" + serialized + "\"");
                }
                return value;
            }
        },
    });
}
/**
 * Allows to create custom mapper. Actually this method does nothing,
 * but I believe allows to write more ideologically correct code.
 *
 * @param mapper
 */
export function custom(mapper) {
    return mapper;
}
/**
 * Creates a mapper for an object value where keys of object are values of given enum
 * and values are entities mapped by provided mapper.
 *
 * @param type
 * @param mapper
 */
export function enumMap(type, mapper) {
    var Mapper = /** @class */ (function () {
        function Mapper() {
        }
        Mapper.prototype.serialize = function (map) {
            return mapValues(mapKeys(map, function (ignore, key) { return Enum.toValue(type, key); }), function (value) { return mapper.serialize(value); });
        };
        Mapper.prototype.deserialize = function (json) {
            return mapValues(mapKeys(json, function (ignore, key) { return Enum.byValue(type, key); }), function (value) { return mapper.deserialize(value); });
        };
        return Mapper;
    }());
    return new Mapper();
}
export function file(mimeType) {
    if (mimeType === void 0) { mimeType = 'application/octet-stream'; }
    return new FileHttpDataMapper(identity(false), mimeType);
}
