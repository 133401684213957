/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthProvider, AuthState } from './auth-provider';

export const DECORATED_AUTH_PROVIDER = new InjectionToken<AuthProvider>('DECORATED_AUTH_PROVIDER');
export const DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR =
  new InjectionToken<AuthProvider>('DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR');

export abstract class DecoratableAuthProvider extends AuthProvider {
  readonly use: boolean;
}

export class DecoratingAuthProvider extends AuthProvider {
  constructor(private decoratable: DecoratableAuthProvider, private decorated: AuthProvider) {
    super();
  }

  get authState(): AuthState {
    if (this.decoratable.use) {
      return this.decoratable.authState;
    } else {
      return this.decorated.authState;
    }
  }

  get onAuthState(): Observable<AuthState> {
    if (this.decoratable.use) {
      return this.decoratable.onAuthState;
    } else {
      return this.decorated.onAuthState;
    }
  }

  login(): Promise<boolean> {
    if (this.decoratable.use) {
      return this.decoratable.login();
    } else {
      return this.decorated.login();
    }
  }

  logout(): Promise<boolean> {
    if (this.decoratable.use) {
      return this.decoratable.logout();
    } else {
      return this.decorated.logout();
    }
  }
}

export class DecoratingAuthHttpRequestInterceptor implements HttpInterceptor {

  constructor(private authProvider: DecoratableAuthProvider,
              private decoratable: HttpInterceptor,
              private decorated: HttpInterceptor) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authProvider.use) {
      return this.decoratable.intercept(req, next);
    } else {
      return this.decorated.intercept(req, next);
    }
  }
}
