var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LazyRegistry } from '@gh/core-lazy';
import { MessageSeverity } from '@gh/core-messages';
import { mergeClassNames } from '@gh/core-util';
import { combineLatest$, of$ } from '@gh/rx';
import { map$, share$, switchMap$ } from '@gh/rx/operators';
import * as invariant from 'invariant';
import { assign, defaults, find, isEqual, isFunction, isNil, noop, remove } from 'lodash';
import { DialogRef } from '../dialog-ref';
import { responsiveDialogOverlayPaneClass } from '../dialog.utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@gh/core-lazy";
export var DIALOG_HOOKS = new InjectionToken('DialogHooks');
export var DIALOG_STANDARD_SET = new InjectionToken('DialogStandardSet');
/**
 * Each lazy routed module use separate instance of {@link DialogService}.
 * {@link DialogService} holds its state in this service shared between all lazy routed modules.
 */
var DialogServiceState = /** @class */ (function () {
    function DialogServiceState() {
        this._openedDialogs = [];
    }
    Object.defineProperty(DialogServiceState.prototype, "openedDialogs", {
        get: function () {
            return this._openedDialogs.map(function (_a) {
                var ref = _a.ref;
                return ref;
            });
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Adds {@link DialogRef} into internal set of dialog refs
     *
     * @param ref
     * @param route
     * @param input
     */
    DialogServiceState.prototype.addDialogRef = function (ref, route, input) {
        this._openedDialogs.push({ ref: ref, route: route, input: input });
    };
    /**
     * Removes {@link DialogRef} from internal set of dialog refs
     *
     * @param refToBeRemoved
     */
    DialogServiceState.prototype.removeDialogRef = function (refToBeRemoved) {
        remove(this._openedDialogs, function (_a) {
            var ref = _a.ref;
            return ref === refToBeRemoved;
        });
    };
    /**
     * Tries to find {@link DialogRef} for the specified route.
     *
     * @param route
     * @param input
     * @returns {T}
     */
    DialogServiceState.prototype.findOpenedDialog = function (route, input) {
        var found = find(this._openedDialogs, { route: route });
        return !isNil(found) && isEqual(input, found.input) ? found.ref : void 0;
    };
    DialogServiceState.ɵfac = function DialogServiceState_Factory(t) { return new (t || DialogServiceState)(); };
    DialogServiceState.ɵprov = i0.ɵɵdefineInjectable({ token: DialogServiceState, factory: DialogServiceState.ɵfac });
    return DialogServiceState;
}());
export { DialogServiceState };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DialogServiceState, [{
        type: Injectable
    }], null, null); })();
/**
 * {@link DialogService} covers common dialog operations.
 */
var DialogService = /** @class */ (function () {
    function DialogService(injector, dialogService, state, lazyRegistry, standardSet, hooks) {
        this.injector = injector;
        this.dialogService = dialogService;
        this.state = state;
        this.lazyRegistry = lazyRegistry;
        this.standardSet = standardSet;
        this.hooks = hooks;
        this.hooks = defaults({}, this.hooks || {}, {
            open: noop,
            close: noop,
        });
    }
    Object.defineProperty(DialogService.prototype, "openedDialogs", {
        /**
         * Returns all currently opened dialogs
         */
        get: function () {
            return this.state.openedDialogs;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Shows message box having given configuration. Configuration defines severity and text of message.
     *
     * @param config
     * @returns {Observable<any>}
     */
    DialogService.prototype.message = function (config) {
        var dialog = this.dialogService.open(this.standardSet.messageDialogType, this.decorateDialogConfig({
            disableClose: config.disableClose,
            panelClass: mergeClassNames(config.panelClass, responsiveDialogOverlayPaneClass()),
        }));
        dialog.componentInstance.input = config;
        return dialog.afterClosed().pipe(map$(function () { return true; }));
    };
    /**
     * Shows success message box with the given text.
     *
     * @param messageId
     * @param messageParams
     * @returns {Observable<boolean>}
     */
    DialogService.prototype.success = function (messageId, messageParams) {
        return this.message({
            severity: MessageSeverity.Success,
            messageId: messageId,
            messageParams: messageParams,
        });
    };
    /**
     * Shows warning message box with the given text.
     *
     * @param messageId
     * @param messageParams
     * @returns {Observable<boolean>}
     */
    DialogService.prototype.warning = function (messageId, messageParams) {
        return this.message({
            severity: MessageSeverity.Warning,
            messageId: messageId,
            messageParams: messageParams,
        });
    };
    /**
     * Shows error message box with the given text
     * @param messageId
     * @param messageParams
     * @returns {Observable<boolean>}
     */
    DialogService.prototype.error = function (messageId, messageParams) {
        return this.message({
            severity: MessageSeverity.Error,
            messageId: messageId,
            messageParams: messageParams,
        });
    };
    /**
     * Shows confirmation dialog. Confirmation dialog asks an question and waits for user's answer.
     * To answer user has to push one of available buttons: Yes, No or Cancel.
     *
     * Config object (@link ConfirmationDialogConfig} passed into this function defines:
     * - set of available buttons: yes, no and cancel.
     * - primary button. Primary button is the main focused button.
     * - text of question
     *
     * @param config
     * @returns {Observable<R>}
     */
    DialogService.prototype.confirmation = function (config) {
        invariant(config.message || config.messageId, 'Message for confirmation dialog is not provided');
        return this.openWithInput(this.standardSet.confirmationDialogType, config, this.decorateDialogConfig({
            disableClose: true,
            autoFocus: false,
            panelClass: mergeClassNames('responsive-dialog-overlay-pane-center', responsiveDialogOverlayPaneClass()),
        }))
            .afterClosed();
    };
    /**
     * Opens dialog passed into this method.
     *
     * @param component
     * @param config
     * @returns {MatDialogRef<T>}
     */
    DialogService.prototype.open = function (component, config) {
        return this.dialogService.open(component, this.decorateDialogConfig(config));
    };
    DialogService.prototype.openWithInput = function (componentOrRoute, input, config) {
        var route = isFunction(componentOrRoute) ? { component: componentOrRoute } : componentOrRoute;
        return this.requestDialog(route, input, config);
    };
    /**
     * Closes each opened dialog according to State
     */
    DialogService.prototype.closeDialogs = function () {
        this.dialogService.closeAll();
    };
    /**
     * Opens new dialog if it is necessary.
     * This service tracks all opened dialog and does not allow to open dialog for route having the same input.
     *
     * @param route
     * @param input
     * @param config
     * @returns {DialogRef}
     */
    DialogService.prototype.requestDialog = function (route, input, config) {
        var dialogRef = this.state.findOpenedDialog(route, input);
        if (dialogRef) {
            dialogRef.close();
        }
        return this.openDialog(route, input, config);
    };
    /**
     * Opens new dialog and adds created {@link DialogRef} into internal set of {@link DialogRef}s
     * (in order to track all opened dialogs).
     *
     * @param route
     * @param input
     * @param config
     * @returns {DialogRef}
     */
    DialogService.prototype.openDialog = function (route, input, overridenConfig) {
        var _this = this;
        var lazy = route.lazy;
        // take initial route or lazy load it from the registry
        var route$ = lazy ? this.lazyRegistry.load(lazy.module, lazy.name).pipe(share$())
            : of$({ injector: this.injector, symbol: route });
        // call resolve service if it exists
        var resolve$ = route$.pipe(switchMap$(function (_a) {
            var loadedRoute = _a.symbol, injector = _a.injector;
            var resolve = loadedRoute.resolve;
            if (isNil(resolve)) {
                return [{}];
            }
            var resolver = injector.get(resolve);
            return resolver.resolve({
                routeConfig: loadedRoute,
                input: input,
            });
        }));
        var nativeRef = undefined;
        // show dialog and construct native MatDialogRef
        var nativeDialogRef$ = combineLatest$(route$, resolve$).pipe(map$(function (_a) {
            var _b = _a[0], loadedRoute = _b.symbol, injector = _b.injector, data = _a[1];
            var component = loadedRoute.component, defaultConfig = loadedRoute.config;
            var config = assign({}, defaultConfig, overridenConfig);
            // tslint:disable-next-line:no-non-null-assertion
            nativeRef = injector.get(MatDialog).open(component, _this.decorateDialogConfig(__assign(__assign({}, (config || {})), { data: __assign(__assign({}, data), { input: input }) })));
            nativeRef.componentInstance.input = input;
            _this.hooks.open(nativeRef);
            return nativeRef;
        }));
        var dialogRef = new DialogRef(nativeDialogRef$);
        var removeRef = function () {
            if (nativeRef) {
                _this.hooks.close(nativeRef);
            }
            _this.state.removeDialogRef(dialogRef);
        };
        this.state.addDialogRef(dialogRef, route, input);
        dialogRef.afterClosed().subscribe(noop, removeRef, removeRef);
        return dialogRef;
    };
    /**
     * Generates {@link MatDialogConfig} for the next dialog.
     */
    DialogService.prototype.decorateDialogConfig = function (config) {
        // make backdrop transparent for 2nd and next layers
        // it is necessary to avoid black background when user sometimes open too much dialogs [GJG-1050]
        // FIXME: this fix is inappropriate, because it has very bad UX. We have to think about this problem later.
        // return this.dialogService.openDialogs.length > 0 ? {
        //   backdropClass: 'dialog-overlay-transparent',
        // } : {};
        return __assign(__assign({}, config), { panelClass: config && config.panelClass || 'dialog-overlay-pane' });
    };
    DialogService.ɵfac = function DialogService_Factory(t) { return new (t || DialogService)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(DialogServiceState), i0.ɵɵinject(i2.LazyRegistry, 8), i0.ɵɵinject(DIALOG_STANDARD_SET, 8), i0.ɵɵinject(DIALOG_HOOKS, 8)); };
    DialogService.ɵprov = i0.ɵɵdefineInjectable({ token: DialogService, factory: DialogService.ɵfac });
    return DialogService;
}());
export { DialogService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DialogService, [{
        type: Injectable
    }], function () { return [{ type: i0.Injector }, { type: i1.MatDialog }, { type: DialogServiceState }, { type: i2.LazyRegistry, decorators: [{
                type: Optional
            }] }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [DIALOG_STANDARD_SET]
            }] }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [DIALOG_HOOKS]
            }] }]; }, null); })();
