/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { ID } from '@gh/common-shared-data';
import { logError } from '@gh/core-util';

import { isNil } from 'lodash';

import { UserContext } from '../user/user-context';

export type HelpID = ID;

export const HELP_SYSTEM = new InjectionToken<HelpSystem>('HelpSystem');

export interface HelpSystem {
  open(helpId?: ID): void;

  has(helpId: ID): boolean;
}

@Injectable()
export class HelpProvider {
  private helpStack: HelpID[] = [];

  constructor(private userContext: UserContext,
              @Optional() @Inject(HELP_SYSTEM) private helpSystem: HelpSystem) {

  }

  getCurrent(): Maybe<HelpID> {
    const { helpStack } = this;
    return helpStack[helpStack.length - 1];
  }

  setCurrent(current: HelpID): void {
    if (!this.helpSystem.has(current)) {
      logError(`HelpProvider: there is no page associated with help id \"${current}\"`);
    }
    this.helpStack.push(current);
  }

  resetCurrent(): void {
    this.helpStack.pop();
  }

  hasForCurrent(): boolean {
    const current = this.getCurrent();
    return current ? this.has(current) : false;
  }

  has(helpId: HelpID): boolean {
    return this.helpSystem.has(helpId);
  }

  open(helpId?: HelpID): void {
    if (isNil(this.helpSystem)) {
      throw new Error('Help System is not supported');
    }
    this.helpSystem.open(helpId);
  }
}
