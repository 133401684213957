/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WaitingContext, WaitingMode } from './waiting-context.service';
import { waitingOperator } from './waiting-rx-operator';

/**
 * This service is intended to manage waiting effect.
 */
@Injectable()
export class WaitingService {
  constructor(private context: WaitingContext) {

  }

  operator<T>(mode: WaitingMode = WaitingMode.Blocked): (ob: Observable<T>) => Observable<T> {
    return waitingOperator(() => this.context.start(mode, 'operator'));
  }
}
