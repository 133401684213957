/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { WaitingContext, WaitingMode } from './waiting-context.service';
import { waitingOperator } from './waiting-rx-operator';
import * as i0 from "@angular/core";
import * as i1 from "./waiting-context.service";
/**
 * This service is intended to manage waiting effect.
 */
var WaitingService = /** @class */ (function () {
    function WaitingService(context) {
        this.context = context;
    }
    WaitingService.prototype.operator = function (mode) {
        var _this = this;
        if (mode === void 0) { mode = WaitingMode.Blocked; }
        return waitingOperator(function () { return _this.context.start(mode, 'operator'); });
    };
    WaitingService.ɵfac = function WaitingService_Factory(t) { return new (t || WaitingService)(i0.ɵɵinject(i1.WaitingContext)); };
    WaitingService.ɵprov = i0.ɵɵdefineInjectable({ token: WaitingService, factory: WaitingService.ɵfac });
    return WaitingService;
}());
export { WaitingService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(WaitingService, [{
        type: Injectable
    }], function () { return [{ type: i1.WaitingContext }]; }, null); })();
