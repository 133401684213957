/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { InjectionToken } from '@angular/core';
import { FormMode } from '@gh/core-ui';
import { Observable } from 'rxjs';

export const PAGE_STATE = new InjectionToken<ContainerState<any>>('PageState');

export interface ContainerState<T> {
  submitValue: T;
  dirty: boolean;
  mode: FormMode;
  createMode: boolean;
  invalid: boolean;
  pending: boolean;
  editMode: boolean;
  viewMode: boolean;
  editable: boolean;
  changes?: Observable<void>;

  build(...args: any[]): void;

  highlightAll?(): void;

  resetHighlight?(): void;
}
