<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<a *ngIf="isTypeLink" matRipple="" class="m-text" [class.m-is-disabled]="disabled" [class.m-is-active]="isLinkActive" [routerLink]="disabled ? undefined : link">
  <mat-icon fxHide="" [fxShow.md]="icon" [svgIcon]="icon"></mat-icon>
  <span fxShow="" [fxHide.md]="icon">{{ title }}</span>
</a>
<span *ngIf="isTypeButton" matRipple="" class="m-text m-text-button">
  <mat-icon fxHide="" [fxShow.md]="icon" [svgIcon]="icon"></mat-icon>
  <span fxShow="" [fxHide.md]="icon">{{ title }}</span>
</span>
<span *ngIf="isTypeOption">
  <ng-content></ng-content>
</span>