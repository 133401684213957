/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:no-submodule-imports
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonExternalSharedModule, ExtConfirmationDialogComponent, ExtMessageDialogComponent, MaterialExternalModule, } from '@gh/common-external-shared';
import { CommonSharedModule } from '@gh/common-shared';
import { CommonSharedDataModule } from '@gh/common-shared-data';
import { AUTH_MODE, KEYCLOAK_AUTH_MODE, KEYCLOAK_WITH_MOCK_AUTH_MODE, MOCK_AUTH_MODE, MOCK_BACKEND_DEV_MODULE, } from '@gh/config';
import { CoreDataModule, CoreMockDataModule } from '@gh/core-data';
import { CoreExternalCommonModule } from '@gh/core-external';
import { CoreMessagesModule } from '@gh/core-messages';
import { CoreTypeModule } from '@gh/core-type';
import { CoreUiCommonModule, CoreUiDialogsModule, CoreUiWaitingModule } from '@gh/core-ui';
import { CustomerSharedModule } from '@gh/customer-shared';
import { CustomerSharedDataModule } from '@gh/customer-shared-data';
import { CustomerAppComponent, CustomerEDocumentsListComponent, CustomerFinancialRecordPaymentListComponent, CustomerFinancialRecordsListComponent, CustomerGalleryComponent, CustomerJobInfoComponent, CustomerJobInfoDialogComponent, CustomerSharedDocumentListComponent, } from './components';
import { CustomerAuthConfigModule, CustomerHttpClientConfigModule, CustomerMlsConfigModule } from './config';
import { CustomerBootstrapService } from './config/customer-bootstrap.service';
import { CustomerAppRoutingModule } from './customer-app-routing.module';
import { CUSTOMER_MOCK_DATA } from './mock/customer-mock-data';
import { CustomerEDocumentBusinessService, CustomerFinancialRecordBusinessService, CustomerGalleryBusinessService, CustomerSharedDocumentBusinessService, } from './resources';
import { CustomerJobActivator } from './services';
import * as i0 from "@angular/core";
import * as i1 from "../common-external-shared/material-external.module";
import * as i2 from "../core-data/core-data.module";
import * as i3 from "../core-type/core-type.module";
import * as i4 from "../core-ui/common/core-ui-common.module";
import * as i5 from "../core-ui/dialogs/core-ui-dialogs.module";
import * as i6 from "../core-ui/waiting/core-ui-waiting.module";
import * as i7 from "../core-external/common/core-external-common.module";
import * as i8 from "../common-shared-data/common-shared-data.module";
import * as i9 from "../common-shared/common-shared.module";
import * as i10 from "../common-external-shared/common-external-shared.module";
import * as i11 from "./config/customer-auth-config.module";
import * as i12 from "../customer-shared-data/customer-shared-data.module";
import * as i13 from "../customer-shared/customer-shared.module";
var CustomerAppModule = /** @class */ (function () {
    function CustomerAppModule() {
    }
    CustomerAppModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomerAppModule, bootstrap: [CustomerAppComponent] });
    CustomerAppModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomerAppModule_Factory(t) { return new (t || CustomerAppModule)(); }, providers: [
            CustomerBootstrapService, {
                provide: APP_INITIALIZER,
                useFactory: function (bootstap) { return function () { return bootstap.load(); }; },
                deps: [CustomerBootstrapService],
                multi: true,
            },
            CustomerJobActivator,
            CustomerEDocumentBusinessService,
            CustomerSharedDocumentBusinessService,
            CustomerFinancialRecordBusinessService,
            CustomerGalleryBusinessService,
        ], imports: [[
                BrowserModule,
                BrowserAnimationsModule,
                MaterialExternalModule.forRoot(),
                // core modules
                CoreMessagesModule,
                CoreDataModule.forRoot(),
                CoreTypeModule.forRoot(),
                CoreUiCommonModule.forRoot(),
                CoreUiDialogsModule.forRoot({
                    standardSet: {
                        messageDialogType: ExtMessageDialogComponent,
                        confirmationDialogType: ExtConfirmationDialogComponent,
                    },
                }),
                CoreUiWaitingModule.forRoot(),
                CoreExternalCommonModule.forRoot(),
                // common modules
                CommonSharedDataModule.forRoot(),
                CommonSharedModule.forRoot(),
                CommonExternalSharedModule.forRoot(),
                // configuration modules
                (AUTH_MODE === KEYCLOAK_AUTH_MODE ? CustomerAuthConfigModule.useKeycloak() :
                    AUTH_MODE === KEYCLOAK_WITH_MOCK_AUTH_MODE ? CustomerAuthConfigModule.useKeycloakWithMock() :
                        AUTH_MODE === MOCK_AUTH_MODE ? CustomerAuthConfigModule.useMock() : CustomerAuthConfigModule.none()),
                CustomerHttpClientConfigModule,
                CustomerMlsConfigModule,
                // customer modules
                CustomerSharedDataModule.forRoot(),
                CustomerSharedModule.forRoot(),
                CustomerAppRoutingModule,
                MOCK_BACKEND_DEV_MODULE ? CoreMockDataModule.withMockData(CUSTOMER_MOCK_DATA) : [],
            ]] });
    return CustomerAppModule;
}());
export { CustomerAppModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomerAppModule, { declarations: [CustomerAppComponent,
        CustomerJobInfoDialogComponent,
        CustomerJobInfoComponent,
        CustomerEDocumentsListComponent,
        CustomerSharedDocumentListComponent,
        CustomerFinancialRecordsListComponent,
        CustomerFinancialRecordPaymentListComponent,
        CustomerGalleryComponent], imports: [BrowserModule,
        BrowserAnimationsModule, i1.MaterialExternalModule, 
        // core modules
        CoreMessagesModule, i2.CoreDataModule, i3.CoreTypeModule, i4.CoreUiCommonModule, i5.CoreUiDialogsModule, i6.CoreUiWaitingModule, i7.CoreExternalCommonModule, i8.CommonSharedDataModule, i9.CommonSharedModule, i10.CommonExternalSharedModule, i11.CustomerAuthKeycloakModule, CustomerHttpClientConfigModule,
        CustomerMlsConfigModule, i12.CustomerSharedDataModule, i13.CustomerSharedModule, CustomerAppRoutingModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerAppModule, [{
        type: NgModule,
        args: [{
                imports: [
                    BrowserModule,
                    BrowserAnimationsModule,
                    MaterialExternalModule.forRoot(),
                    // core modules
                    CoreMessagesModule,
                    CoreDataModule.forRoot(),
                    CoreTypeModule.forRoot(),
                    CoreUiCommonModule.forRoot(),
                    CoreUiDialogsModule.forRoot({
                        standardSet: {
                            messageDialogType: ExtMessageDialogComponent,
                            confirmationDialogType: ExtConfirmationDialogComponent,
                        },
                    }),
                    CoreUiWaitingModule.forRoot(),
                    CoreExternalCommonModule.forRoot(),
                    // common modules
                    CommonSharedDataModule.forRoot(),
                    CommonSharedModule.forRoot(),
                    CommonExternalSharedModule.forRoot(),
                    // configuration modules
                    (AUTH_MODE === KEYCLOAK_AUTH_MODE ? CustomerAuthConfigModule.useKeycloak() :
                        AUTH_MODE === KEYCLOAK_WITH_MOCK_AUTH_MODE ? CustomerAuthConfigModule.useKeycloakWithMock() :
                            AUTH_MODE === MOCK_AUTH_MODE ? CustomerAuthConfigModule.useMock() : CustomerAuthConfigModule.none()),
                    CustomerHttpClientConfigModule,
                    CustomerMlsConfigModule,
                    // customer modules
                    CustomerSharedDataModule.forRoot(),
                    CustomerSharedModule.forRoot(),
                    CustomerAppRoutingModule,
                    MOCK_BACKEND_DEV_MODULE ? CoreMockDataModule.withMockData(CUSTOMER_MOCK_DATA) : [],
                ],
                providers: [
                    CustomerBootstrapService, {
                        provide: APP_INITIALIZER,
                        useFactory: function (bootstap) { return function () { return bootstap.load(); }; },
                        deps: [CustomerBootstrapService],
                        multi: true,
                    },
                    CustomerJobActivator,
                    CustomerEDocumentBusinessService,
                    CustomerSharedDocumentBusinessService,
                    CustomerFinancialRecordBusinessService,
                    CustomerGalleryBusinessService,
                ],
                declarations: [
                    CustomerAppComponent,
                    CustomerJobInfoDialogComponent,
                    CustomerJobInfoComponent,
                    CustomerEDocumentsListComponent,
                    CustomerSharedDocumentListComponent,
                    CustomerFinancialRecordsListComponent,
                    CustomerFinancialRecordPaymentListComponent,
                    CustomerGalleryComponent,
                ],
                entryComponents: [
                    CustomerJobInfoDialogComponent,
                ],
                bootstrap: [CustomerAppComponent],
            }]
    }], null, null); })();
