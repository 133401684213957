/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';

import { AuthState, DecoratableAuthProvider } from '@gh/core-auth';
import { denull, parseQueryString } from '@gh/core-util';
import { delay$, map$ } from '@gh/rx/operators';

import { isNil } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { MockAuthProvider } from '../mock-auth-provider.service';

function getAuthState(): Maybe<string> {
  const { mockUserEmail } = parseQueryString(window.location.search);

  return denull(<Nullable<string>>mockUserEmail);
}

@Injectable()
export class SecondaryMockAuthProvider extends MockAuthProvider implements DecoratableAuthProvider {
  readonly onAuthState: Observable<AuthState>;
  private userKey$$ = new BehaviorSubject<Maybe<string>>(getAuthState());
  private _authState: AuthState;

  constructor() {
    super();

    this.onAuthState = this.userKey$$.pipe(
      map$((key) => key ? AuthState.LoggedIn : AuthState.LogggedOut),
      delay$(0));

    this.onAuthState.subscribe((state) => {
      this._authState = state;
    });
  }

  get authState(): AuthState {
    return this._authState;
  }

  get userKey(): string {
    const { value } = this.userKey$$;

    if (value) {
      return value;
    } else {
      throw new Error('User is not logged in');
    }
  }

  get use(): boolean {
    return !isNil(this.userKey$$.value);
  }

  login(): Promise<boolean> {
    throw new Error('Login is not implemented for SecondaryMockAuthProvider');
  }

  logout(): Promise<boolean> {
    throw new Error('Logout is not implemented for SecondaryMockAuthProvider');
  }
}
