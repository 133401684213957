var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { translationTuple } from '@gh/core-mls';
import { parametrizeUrl } from '@gh/core-util';
import { filter$ } from '@gh/rx/operators';
import { omit } from 'lodash';
import { AppRouterState } from './app-router-state.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./app-router-state.service";
/**
 * This service is a wrapper around angular {@link Router} service.
 * It has similar capabilities, but also allows to manage parent-child relations between routes.
 *
 * This router tracks current user route (route displayed in the browser toolbar).
 * What does this mean?
 * Some pages have internal routes. For example, multi-tab form has a separate route for each tab.
 * Nevertheless only one route (for whole page) is displayed in the browser toolbar,
 * there is no sense for user to bookmark each separate tab.
 *
 * This service helps to track only route important for user.
 * Developer can get current state of the router via `state` field.
 *
 */
var AppRouter = /** @class */ (function () {
    function AppRouter(router, location, state) {
        var _this = this;
        this.router = router;
        this.location = location;
        this.state = state;
        // initialize state by the first opened route
        router.events.pipe(filter$(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function () { return _this.state._update(); });
    }
    Object.defineProperty(AppRouter.prototype, "url", {
        get: function () {
            return this.router.url;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppRouter.prototype, "parentRoute", {
        get: function () {
            return this.state.parentRoute;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Navigates to child route. Child route gets the following parameters:
     *
     * * `parentRoute` query parameter that points to the parent route
     * * `parentTitle` for title of parent page. Optional.
     *
     * @param commands the same as for {@link Router#navigate}
     * @param extras allows to provide title of parent router (parentTitle)
     */
    AppRouter.prototype.navigateToChild = function (commands, extras) {
        extras = extras || {};
        var parentTitle = extras.parentTitle && translationTuple(extras.parentTitle);
        var queryParams = {
            parentRoute: this.location.path(),
            parentTitle: parentTitle && parentTitle.id,
            parentTitleParams: parentTitle && JSON.stringify(parentTitle.params),
        };
        return this.router.navigate(commands, __assign(__assign({}, omit(extras, 'parentTitle')), { queryParams: extras.queryParams ? __assign(__assign({}, extras.queryParams), queryParams) : queryParams })).then(this.createUpdater(extras));
    };
    /**
     * Replaces current route by the provided one and saves all query parameters.
     *
     * @param commands the same as for {@link Router#navigate}
     * @param extras allows to provide set of query parameters to omit (omitQueryParams)
     *               and set of query parameters to replace old one
     */
    AppRouter.prototype.switchTo = function (commands, extras) {
        var omitQueryParams = extras && extras.omitQueryParams || [];
        var queryParams = extras && extras.queryParams || {};
        return this.router.navigate(commands, {
            queryParams: omit(__assign(__assign({}, this.state.queryParams), queryParams), omitQueryParams),
            replaceUrl: true,
        }).then(this.createUpdater());
    };
    /**
     * Navigates to the parent route, if it exists.
     *
     * @param queryParams
     * @returns {Promise<boolean>}
     */
    AppRouter.prototype.navigateBack = function (queryParams) {
        if (!this.state.parentRoute) {
            throw new Error('Parent route is not defined');
        }
        return this.router.navigateByUrl(queryParams ? parametrizeUrl(this.state.parentRoute, queryParams) : this.state.parentRoute).then(this.createUpdater());
    };
    /**
     * Navigates to the provided route.
     *
     * @param url
     * @param extras
     * @returns {Promise<boolean>}
     */
    AppRouter.prototype.navigateByUrl = function (url, extras) {
        return this.router.navigateByUrl(url, extras)
            .then(this.createUpdater(extras));
    };
    /**
     * Answers if provided route is current.
     *
     * @param url
     * @param exact
     * @returns {boolean}
     */
    AppRouter.prototype.isActive = function (url, exact) {
        return this.router.isActive(url, exact);
    };
    /**
     * Creates a function that updates state if it is necessary.
     *
     * This function is useful for promises.
     *
     * @param extras
     * @returns {(value:any)=>boolean}
     */
    AppRouter.prototype.createUpdater = function (extras) {
        var _this = this;
        return function (value) {
            // update state only if location is changed
            // this router tracks only routes important for user.
            if (!extras || !extras.skipLocationChange) {
                _this.state._update();
            }
            return value;
        };
    };
    AppRouter.ɵfac = function AppRouter_Factory(t) { return new (t || AppRouter)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Location), i0.ɵɵinject(i3.AppRouterState)); };
    AppRouter.ɵprov = i0.ɵɵdefineInjectable({ token: AppRouter, factory: AppRouter.ɵfac });
    return AppRouter;
}());
export { AppRouter };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppRouter, [{
        type: Injectable
    }], function () { return [{ type: i1.Router }, { type: i2.Location }, { type: i3.AppRouterState }]; }, null); })();
