var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injectable } from '@angular/core';
import { BasicAuthHttpRequestInterceptor } from '@gh/core-auth';
import { MockAuthProvider } from './mock-auth-provider.service';
import * as i0 from "@angular/core";
import * as i1 from "./mock-auth-provider.service";
var MockAuthHttpRequestInterceptor = /** @class */ (function (_super) {
    __extends(MockAuthHttpRequestInterceptor, _super);
    function MockAuthHttpRequestInterceptor(authProvider) {
        var _this = _super.call(this) || this;
        _this.authProvider = authProvider;
        return _this;
    }
    MockAuthHttpRequestInterceptor.prototype.interceptWithRefresh = function (req, next) {
        return this.interceptMock(req, next);
    };
    MockAuthHttpRequestInterceptor.prototype.interceptWithoutRefresh = function (req, next) {
        return this.interceptMock(req, next);
    };
    MockAuthHttpRequestInterceptor.prototype.interceptMock = function (req, next) {
        return next.handle(req.clone({ headers: req.headers.set('X-MockUserEmail', this.authProvider.userKey) }));
    };
    MockAuthHttpRequestInterceptor.ɵfac = function MockAuthHttpRequestInterceptor_Factory(t) { return new (t || MockAuthHttpRequestInterceptor)(i0.ɵɵinject(i1.MockAuthProvider)); };
    MockAuthHttpRequestInterceptor.ɵprov = i0.ɵɵdefineInjectable({ token: MockAuthHttpRequestInterceptor, factory: MockAuthHttpRequestInterceptor.ɵfac });
    return MockAuthHttpRequestInterceptor;
}(BasicAuthHttpRequestInterceptor));
export { MockAuthHttpRequestInterceptor };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MockAuthHttpRequestInterceptor, [{
        type: Injectable
    }], function () { return [{ type: i1.MockAuthProvider }]; }, null); })();
