/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { assign, omit } from 'lodash';
import * as querystring from 'query-string';
var URL_RE = /^([^?#]+)(\?([^#]+)?)?(#(\.+))?$/;
export function mergePath() {
    var segments = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        segments[_i] = arguments[_i];
    }
    return segments.slice(1).reduce(function (path, segment) { return path.replace(/(\/)$/, '') + segment.replace(/^([^/])/, '/$1'); }, segments[0]);
}
/**
 * Adds specified parameters to the given URL.
 *
 * @param url
 * @param params
 * @returns {string}
 */
export function addQueryParameters(url, params) {
    if (params) {
        return "" + url + (url.includes('?') ? '&' : '?') + params;
    }
    else {
        return url;
    }
}
/**
 * Merges specified parameters and the given URL. This method can overwrite parameters set on the URL.
 *
 * @param url
 * @param params
 * @returns {string}
 */
export function setQueryParameters(url, params) {
    var parsed = parseUrl(url);
    assign(parsed.query, params);
    return stringifyUrl(parsed);
}
/**
 * Removes specified parameters from the given URL.
 *
 * @param url
 * @param params
 * @returns {string}
 */
export function removeQueryParameters(url, params) {
    var parsed = parseUrl(url);
    parsed.query = omit(parsed.query, params);
    return stringifyUrl(parsed);
}
/**
 * Merges specified parameters and the given URL. This method can overwrite parameters set on the URL.
 *
 * @param baseUrl
 * @param params
 * @returns {string}
 */
export function parametrizeUrl(baseUrl, params) {
    return setQueryParameters(baseUrl, params);
}
/**
 * Parses given URL and returns destructured view of this URL.
 *
 * Note!!! Currently this method works only for relative URLs
 *
 * @param url
 */
export function parseUrl(url) {
    var match = URL_RE.exec(url);
    if (match) {
        return {
            pathname: match[1],
            query: parseQueryString(match[3]),
            hash: match[5],
        };
    }
    else {
        return {
            pathname: url,
            query: {},
            hash: '',
        };
    }
}
/**
 * Stringifies parsed URL
 *
 * @param info
 */
export function stringifyUrl(info) {
    return [
        info.pathname,
        info.query ? '?' + querystring.stringify(info.query) : '',
        info.hash ? '#' + info.hash : '',
    ].join('');
}
/**
 * Parses given query string and returns dictionary for set parameters
 *
 * @param query
 */
export function parseQueryString(query) {
    return querystring.parse(query);
}
/**
 * Returns path of the given URL. Currently this method works only for relative URL
 *
 * @param url
 * @returns {string}
 */
export function getUrlPath(url) {
    return url.split('?')[0];
}
/**
 * Replaces url by the given segment
 * @param url
 * @param order
 * @param segment
 */
export function replaceUrlSegment(url, order, segment) {
    var parsedUrl = parseUrl(url);
    var pathname = parsedUrl.pathname;
    var isStartFromSlash = /^\//.test(pathname);
    var normalizedPathName = isStartFromSlash ? pathname.substring(1) : pathname;
    var segments = normalizedPathName.split('/');
    if (order > segments.length) {
        throw new Error("Url \"" + url + "\" does not have enough segments. Target segment order: " + order);
    }
    return stringifyUrl(__assign(__assign({}, parsedUrl), { pathname: (isStartFromSlash ? [''] : [])
            .concat(segments.slice(0, order))
            .concat([segment])
            .concat(segments.slice(order + 1))
            .join('/') }));
}
/**
 * Returns specified URL segment
 * @param url
 * @param order
 */
export function getUrlSegment(url, order) {
    var parsedUrl = parseUrl(url);
    var pathname = parsedUrl.pathname;
    var isStartFromSlash = /^\//.test(pathname);
    var normalizedPathName = isStartFromSlash ? pathname.substring(1) : pathname;
    var segments = normalizedPathName.split('/');
    if (order > segments.length) {
        throw new Error("Url \"" + url + "\" does not have enough segments. Target segment order: " + order);
    }
    return segments[order];
}
