/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { TypeService } from '@gh/core-type';
import { createDateTime, nullate } from '@gh/core-util';
import { combineLatest$ } from '@gh/rx';
import { filter$, skip$, startWith$ } from '@gh/rx/operators';
import { isEmpty, pick } from 'lodash';
import { DateTimePickerInputDirective } from './date-time-picker-input.directive';
import * as i0 from "@angular/core";
import * as i1 from "./date-time-picker-input.directive";
import * as i2 from "@gh/core-type";
import * as i3 from "@angular/forms";
var DateTimePickerDirective = /** @class */ (function () {
    function DateTimePickerDirective(input, types, control) {
        this.input = input;
        this.types = types;
        this.control = control;
        this.dateControl = new FormControl(void 0, [this.types.get('ShortDate').validator]);
        this.timeControl = new FormControl(void 0, [this.types.get('ShortTime').validator]);
        this._touched = false;
        this._disabled = false;
        this.ignoreValueChange = false;
        this.input.registerOnSetDisabledState(this.setDisabledState.bind(this));
        this.input.registerOnWriteValue(this.writeValue.bind(this));
    }
    DateTimePickerDirective.prototype.ngOnInit = function () {
        var _this = this;
        var _a = this, dateControl = _a.dateControl, timeControl = _a.timeControl;
        this.valueSubscription = combineLatest$(dateControl.valueChanges.pipe(startWith$(dateControl.value)), timeControl.valueChanges.pipe(startWith$(timeControl.value))).pipe(skip$(1), filter$(function () { return !_this.ignoreValueChange; }))
            .subscribe(function (_a) {
            var date = _a[0], time = _a[1];
            _this.input.onChange(date || time ? createDateTime(date, time) : void 0);
        });
        if (this.control.statusChanges) {
            this.control.statusChanges.subscribe(function (status) {
                if (status === 'INVALID') {
                    var errors = _this.control.errors;
                    if (errors) {
                        var dateErrors = pick(errors, 'date', 'datetime');
                        var timeErrors = pick(errors, 'time', 'datetime');
                        _this.dateControl.setErrors(isEmpty(dateErrors) ? nullate(void 0) : dateErrors);
                        _this.timeControl.setErrors(isEmpty(timeErrors) ? nullate(void 0) : timeErrors);
                    }
                }
                else {
                    _this.dateControl.setErrors(nullate(void 0));
                    _this.timeControl.setErrors(nullate(void 0));
                }
                _this.dateControl.markAsTouched();
                _this.timeControl.markAsTouched();
            });
        }
    };
    DateTimePickerDirective.prototype.ngOnDestroy = function () {
        if (this.valueSubscription) {
            this.valueSubscription.unsubscribe();
        }
    };
    DateTimePickerDirective.prototype.ngDoCheck = function () {
        var touched = this.dateControl.touched || this.timeControl.touched;
        if (touched === this._touched) {
            return;
        }
        if (touched) {
            this.input.onTouched();
        }
    };
    DateTimePickerDirective.prototype.setDisabledState = function (isDisabled) {
        if (this._disabled === isDisabled) {
            return;
        }
        if (isDisabled) {
            this.dateControl.disable();
            this.timeControl.disable();
        }
        else {
            this.dateControl.enable();
            this.timeControl.enable();
        }
    };
    DateTimePickerDirective.prototype.writeValue = function (datetime) {
        var _a = datetime ? datetime : { date: void 0, time: void 0 }, date = _a.date, time = _a.time;
        this.ignoreValueChange = true;
        this.dateControl.setValue(date);
        this.timeControl.setValue(time);
        this.ignoreValueChange = false;
    };
    DateTimePickerDirective.ɵfac = function DateTimePickerDirective_Factory(t) { return new (t || DateTimePickerDirective)(i0.ɵɵdirectiveInject(i1.DateTimePickerInputDirective), i0.ɵɵdirectiveInject(i2.TypeService), i0.ɵɵdirectiveInject(i3.NgControl)); };
    DateTimePickerDirective.ɵdir = i0.ɵɵdefineDirective({ type: DateTimePickerDirective, selectors: [["", "ghDateTimePicker", ""]], exportAs: ["ghDateTimePicker"] });
    return DateTimePickerDirective;
}());
export { DateTimePickerDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DateTimePickerDirective, [{
        type: Directive,
        args: [{
                selector: '[ghDateTimePicker]',
                exportAs: 'ghDateTimePicker',
            }]
    }], function () { return [{ type: i1.DateTimePickerInputDirective }, { type: i2.TypeService }, { type: i3.NgControl }]; }, null); })();
