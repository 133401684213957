/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { MessageDirective, MessageRepositoryComponent } from './components/message-repository';
import { LocalizationService } from './localization';
import { FormMessageService } from './message';
import { JSONTranslationMapLoader, TranslatePipe, TranslationCompiler, TranslationService, TranslationStorage, } from './translation';
import * as i0 from "@angular/core";
var CoreMlsModule = /** @class */ (function () {
    function CoreMlsModule() {
    }
    CoreMlsModule.forRoot = function () {
        return {
            ngModule: CoreMlsModule,
            providers: [
                FormMessageService,
                TranslationStorage,
                TranslationService,
                JSONTranslationMapLoader,
                LocalizationService,
                TranslationCompiler,
            ],
        };
    };
    CoreMlsModule.forChild = function () {
        return {
            ngModule: CoreMlsModule,
            providers: [],
        };
    };
    CoreMlsModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreMlsModule });
    CoreMlsModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreMlsModule_Factory(t) { return new (t || CoreMlsModule)(); } });
    return CoreMlsModule;
}());
export { CoreMlsModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreMlsModule, { declarations: [TranslatePipe,
        MessageRepositoryComponent,
        MessageDirective], exports: [TranslatePipe,
        MessageRepositoryComponent,
        MessageDirective] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreMlsModule, [{
        type: NgModule,
        args: [{
                declarations: [
                    TranslatePipe,
                    MessageRepositoryComponent,
                    MessageDirective,
                ],
                exports: [
                    TranslatePipe,
                    MessageRepositoryComponent,
                    MessageDirective,
                ],
            }]
    }], null, null); })();
