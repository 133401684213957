/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { concat$, EMPTY$, of$ } from '@gh/rx';
import { catchError$, first$, switchMap$, takeUntil$ } from '@gh/rx/operators';
import { identity } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbstractUploadEngine, UploadProcess } from './abstract-upload-engine';
import { s3UploadXhr } from './s3-upload-xhr';
import { UploadEvent, UploadEventType, UploadItem, UploadStatus } from './upload.interfaces';

export interface S3UploadItem extends UploadItem {
  key?: string;
}

export interface S3UploadConfig {
  date: string;
  signature: string;
  algorithm: string;
  credentials: string;
  bucket: string;
  key: string;
  acl: string;
  policy: string;
  location: string;
}

export abstract class S3UploadAuthService {
  abstract getConfig(item: S3UploadItem, params: Hash<any>): Observable<S3UploadConfig>;
}

export class S3UploadProcess implements UploadProcess {
  event$: Observable<UploadEvent>;
  private cancel$$ = new BehaviorSubject<boolean>(false);

  constructor(uploadAuth: S3UploadAuthService, item: UploadItem, params: Hash<any>) {
    // emits an event about starting of uploading
    const start$ = of$({
      item: {
        ...item,
        status: UploadStatus.Uploading,
      },
      type: UploadEventType.Started,
    });
    // emits a value if we need to start uploading
    const cancelled$ = this.cancel$$.pipe(first$(identity));
    const uploading$ = of$(true).pipe(
      takeUntil$(cancelled$),
      switchMap$(() => uploadAuth.getConfig(item, params)),
      switchMap$((config) => s3UploadXhr(config, item, params)),
      catchError$(() => of$({
        item: {
          ...item,
          status: UploadStatus.Error,
          progress: 0,
        },
        type: UploadEventType.Error,
      })));
    // emits event only if uploading was cancelled
    const cancel$ = this.cancel$$.pipe(
      first$(),
      switchMap$((cancelled: boolean) => !cancelled ? EMPTY$ : of$({
        item: {
          ...item,
          status: UploadStatus.NotStarted,
          progress: 0,
        },
        type: UploadEventType.Cancelled,
      })));

    this.event$ = concat$(start$, uploading$, cancel$);
  }

  cancel(): void {
    this.cancel$$.next(true);
  }
}

@Injectable()
export class S3UploadEngine extends AbstractUploadEngine {

  constructor(private uploadAuth: S3UploadAuthService) {
    super();
  }

  protected createUploadProcess(item: UploadItem, params: Hash<any>): UploadProcess {
    return new S3UploadProcess(this.uploadAuth, item, params);
  }
}
