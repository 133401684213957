/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, OnDestroy } from '@angular/core';
import { pull } from 'lodash';

export const FONT_AFFECTING_PROPERTIES = ['fontSize', 'fontFamily', 'fontWeight', 'fontStyle'];

export interface ContentRuler {
  dispose(): void;
}

export class TextRuler implements ContentRuler {
  private textRulerEl: HTMLElement;

  constructor(private rootEl: HTMLElement, private protoEl: HTMLElement) {
    const rulerEl = document.createElement('div');
    rulerEl.classList.add('text-ruler');
    rootEl.appendChild(rulerEl);
    this.textRulerEl = rulerEl;

    this.recalculate();
  }

  recalculate(): void {
    const { protoEl, textRulerEl } = this;
    const computedStyles = getComputedStyle(protoEl);

    FONT_AFFECTING_PROPERTIES.forEach((property) => {
      textRulerEl.style[property] = computedStyles[property];
    });
  }

  getTextWidth(text: string): number {
    const { textRulerEl } = this;
    textRulerEl.textContent = text;
    const textWidth = textRulerEl.offsetWidth;
    textRulerEl.textContent = '';

    return textWidth;
  }

  dispose(): void {
    this.rootEl.removeChild(this.textRulerEl);
  }
}

@Injectable()
export class ContentRulerFactory implements OnDestroy {
  private contentRulerRootEl: HTMLElement;
  private rulers: ContentRuler[] = [];

  constructor() {
    this.initRootElement();
  }

  createTextRuler(element: HTMLElement): TextRuler {
    const ruler = new TextRuler(this.contentRulerRootEl, element);

    this.rulers.push(ruler);

    return ruler;
  }

  releaseRuler(ruler: ContentRuler): void {
    ruler.dispose();

    pull(this.rulers, ruler);
  }

  ngOnDestroy(): void {
    document.body.removeChild(this.contentRulerRootEl);
  }

  private initRootElement(): void {
    const rootEl = document.createElement('div');
    rootEl.classList.add('content-ruler-root');
    document.body.appendChild(rootEl);

    this.contentRulerRootEl = rootEl;
  }
}
