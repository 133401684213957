/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { tap$ } from '@gh/rx/operators';

import { Observable } from 'rxjs';
import { Message } from './message';

import { MessagePushService } from './message-push.service';
import { MessageMapper } from './message.mapper';

export const MESSAGE_HEADER = new InjectionToken('MessageHeader');

const DEFAULT_MESSAGE_HEADER = 'X-AppMessage';

@Injectable()
export class MessageHttpResponseInterceptor implements HttpInterceptor {

  constructor(private notificationService: MessagePushService,
              @Optional() @Inject(MESSAGE_HEADER) private messageHeader: string) {
    this.messageHeader = messageHeader || DEFAULT_MESSAGE_HEADER;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap$((response: HttpResponse<any>) => {
      const { headers } = response;
      const messageHeader = headers ? headers.get(this.messageHeader) : void 0;

      if (messageHeader) {
        JSON.parse(messageHeader.trim())
          .map((message: any) => MessageMapper.deserialize(message))
          .forEach((message: Message) => this.notificationService.pushMessage(message));
      }
    }));
  }
}
