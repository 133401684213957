/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNotNil } from '@gh/core-util';
import { isString, noop } from 'lodash';
import { createTextMaskInputElement } from 'text-mask-core';
import { InputState, TypeService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services";
var FOCUSED_NUMBER_FORMAT_QUERY = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
};
function isMinusOnly(str) {
    return str === '-';
}
/**
 * This module declares directive for number inputs. We had to define own implementation because it provides
 * better integration with number type approach (core-type package)
 *
 * Implementation was taken from this repository https://github.com/text-mask/text-mask.
 * File: https://github.com/text-mask/text-mask/blob/master/angular2/src/angular2TextMask.ts
 */
var NumberTypeDirective = /** @class */ (function () {
    function NumberTypeDirective(renderer, element, typeService, inputState) {
        this.renderer = renderer;
        this.element = element;
        this.typeService = typeService;
        this.inputState = inputState;
        this.formatOnFocus = true;
        this._onTouched = noop;
        this._onChange = noop;
    }
    Object.defineProperty(NumberTypeDirective.prototype, "type", {
        set: function (type) {
            this._type = isString(type) ? this.typeService.get(type) : type;
        },
        enumerable: true,
        configurable: true
    });
    NumberTypeDirective.prototype.ngOnInit = function () {
        this.setupMask();
    };
    NumberTypeDirective.prototype.onInput = function (str) {
        this.input(str);
    };
    NumberTypeDirective.prototype.onFocus = function () {
        var element = this.element.nativeElement;
        if (this.formatOnFocus && isNotNil(this.lastValue)) {
            element.value = this._type.format(this.lastValue, FOCUSED_NUMBER_FORMAT_QUERY);
            // Default browser behavior is to select whole value on focus,
            // but value is not selected when it is updated by statement above.
            // So, we have to select value manually (excluding the case when user focuses element using mouse)
            if (!this.inputState.mouseDown) {
                element.select();
            }
        }
    };
    NumberTypeDirective.prototype.onBlur = function () {
        if (isNotNil(this.lastValue)) {
            this.element.nativeElement.value = this._type.format(this.lastValue);
        }
        else if (isMinusOnly(this.lastStrValue)) {
            this.element.nativeElement.value = '';
        }
        this._onTouched();
    };
    NumberTypeDirective.prototype.writeValue = function (value) {
        this.lastValue = value;
        if (isNotNil(value) && this.textMaskInputElement !== void 0) {
            this.textMaskInputElement.update(this._type.format(value));
        }
        else {
            this.element.nativeElement.value = '';
        }
        this.lastStrValue = this.element.nativeElement.value;
    };
    /**
     * This method allows to simulate typing into the field.
     *
     * Note!!! This is low-level method.
     * Be patient using this method, because you can break field content.
     * To guarantee correct behavior it is better to focus the field after it was updated using this method.
     *
     * @param str string content to set into the field
     * @param emitEvent whether to emit onChange event
     */
    NumberTypeDirective.prototype.input = function (str, _a) {
        var emitEvent = (_a === void 0 ? { emitEvent: true } : _a).emitEvent;
        this.textMaskInputElement.update(str);
        var nextStrValue = this.element.nativeElement.value;
        if (this.lastStrValue !== nextStrValue) {
            this.lastStrValue = nextStrValue;
            if (nextStrValue && !isMinusOnly(nextStrValue)) {
                var nextValue = this._type.parse(nextStrValue);
                if (this.lastValue !== nextValue) {
                    this.lastValue = nextValue;
                    if (emitEvent) {
                        this._onChange(nextValue);
                    }
                }
            }
            else {
                this.lastValue = void 0;
                if (emitEvent) {
                    this._onChange(void 0);
                }
            }
        }
    };
    NumberTypeDirective.prototype.registerOnChange = function (fn) {
        this._onChange = fn;
    };
    NumberTypeDirective.prototype.registerOnTouched = function (fn) {
        this._onTouched = fn;
    };
    NumberTypeDirective.prototype.setDisabledState = function (isDisabled) {
        this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
    };
    NumberTypeDirective.prototype.setupMask = function () {
        this.textMaskInputElement = createTextMaskInputElement({
            inputElement: this.element.nativeElement,
            mask: this._type['mask'],
        });
    };
    NumberTypeDirective.ɵfac = function NumberTypeDirective_Factory(t) { return new (t || NumberTypeDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.TypeService), i0.ɵɵdirectiveInject(i1.InputState)); };
    NumberTypeDirective.ɵdir = i0.ɵɵdefineDirective({ type: NumberTypeDirective, selectors: [["", "ghNumberType", ""]], hostBindings: function NumberTypeDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("input", function NumberTypeDirective_input_HostBindingHandler($event) { return ctx.onInput($event.target.value); })("focus", function NumberTypeDirective_focus_HostBindingHandler() { return ctx.onFocus(); })("blur", function NumberTypeDirective_blur_HostBindingHandler() { return ctx.onBlur(); });
        } }, inputs: { formatOnFocus: ["ghNumberTypeFormatOnFocus", "formatOnFocus"], type: ["ghNumberType", "type"] }, features: [i0.ɵɵProvidersFeature([{
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: NumberTypeDirective,
                    multi: true,
                }])] });
    return NumberTypeDirective;
}());
export { NumberTypeDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NumberTypeDirective, [{
        type: Directive,
        args: [{
                selector: '[ghNumberType]',
                providers: [{
                        provide: NG_VALUE_ACCESSOR,
                        useExisting: NumberTypeDirective,
                        multi: true,
                    }],
            }]
    }], function () { return [{ type: i0.Renderer2 }, { type: i0.ElementRef }, { type: i1.TypeService }, { type: i1.InputState }]; }, { formatOnFocus: [{
            type: Input,
            args: ['ghNumberTypeFormatOnFocus']
        }], type: [{
            type: Input,
            args: ['ghNumberType']
        }], onInput: [{
            type: HostListener,
            args: ['input', ['$event.target.value']]
        }], onFocus: [{
            type: HostListener,
            args: ['focus']
        }], onBlur: [{
            type: HostListener,
            args: ['blur']
        }] }); })();
