/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DatePipe, DecimalPipe } from '@angular/common';
import { ModuleWithProviders, NgModule, ValueProvider } from '@angular/core';
import { Type } from './core';
import {
  DataTypeDirective,
  DateTypeDirective,
  MaskedTypeDirective,
  NumberTypeDirective,
  QuantityTypeDirective,
} from './directives';
import { TypeFormatPipe } from './pipes';
import { InputState, TYPE_SET, TypeService } from './services';

export function injectTypeSet(typeSet: Hash<Type<any>>): ValueProvider {
  return {
    provide: TYPE_SET,
    useValue: typeSet,
    multi: true,
  };
}

export function injectTypeSets(typeSets?: Hash<Type<any>>[]): ValueProvider[] {
  return (typeSets || <Hash<Type<any>>[]>[]).map(injectTypeSet);
}

@NgModule({
  declarations: [
    DataTypeDirective,
    MaskedTypeDirective,
    NumberTypeDirective,
    DateTypeDirective,
    QuantityTypeDirective,
    TypeFormatPipe,
  ],
  exports: [
    DataTypeDirective,
    MaskedTypeDirective,
    NumberTypeDirective,
    DateTypeDirective,
    QuantityTypeDirective,
    TypeFormatPipe,
  ],
})
export class CoreTypeModule {
  static forRoot(typeSets?: Hash<Type<any>>[]): ModuleWithProviders {
    return {
      ngModule: CoreTypeModule,
      providers: [
        // define both these pipes as providers in order to inject them in service
        // revise this solution when feature https://github.com/angular/angular/issues/20536 will be ready
        DecimalPipe, DatePipe,
        TypeService,
        InputState,
        injectTypeSets(typeSets),
      ],
    };
  }

  static forChild(typeSets?: Hash<Type<any>>[]): ModuleWithProviders {
    return {
      ngModule: CoreTypeModule,
      providers: injectTypeSets(typeSets),
    };
  }
}
