/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Locale } from '../locale';

const toLocaleLowerCase = String.prototype.toLocaleLowerCase;
const toLocaleUpperCase = String.prototype.toLocaleUpperCase;

/**
 * This class provides locale-aware operations for strings.
 */
export class StringL10nHelper {
  private code: string;

  constructor(locale: Locale) {
    this.code = locale.code;
  }

  toLowerCase(str: string): string {
    // this code uses ugly syntax, because type definitions do not have right definition for this method
    return toLocaleLowerCase.apply(str, [this.code]);
  }

  toUpperCase(str: string): string {
    // this code uses ugly syntax, because type definitions do not have right definition for this method
    return toLocaleUpperCase.apply(str, [this.code]);
  }

  includesIgnoreCase(str: string, substring: string): boolean {
    return this.toUpperCase(str).includes(this.toUpperCase(substring));
  }
}
