/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { isNil, isString, mapValues } from 'lodash';
import { getMergedState, StateMerger } from './action-util';
import { Action, ActionDispatcher, ActionExecutor, ConstantAction, LogicalAction, Step } from './action.interfaces';

export const STEP_START = 'start';
export const STEP_PUSH_CONTEXT = '$pushContext';
export const STEP_POP_CONTEXT = '$popContext';
export const STEP_COMPLETE = '$complete';
export const STEP_FINISH = '@finish';

export function goToStep<FS>(step: (state: FS) => string): Action<FS>;
export function goToStep<FS>(step: string): Action<FS>;
export function goToStep<FS>(step: string | (((state: FS) => string))): Action<FS> {
  return (dispatch: ActionDispatcher, flowState: FS) => {
    const nextStep = isString(step) ? step : step(flowState);
    if (isNil(nextStep)) {
      throw new Error('Next step is undetermined');
    }
    dispatch(nextStep, flowState);
  };
}

export function logicalNoop<FS>(): LogicalAction<FS> {
  return logicalAction(
    'success',
    'fail',
    (dispatch: ActionDispatcher, flowState: FS) => dispatch('success', flowState));
}

export function constantNoop<FS>(): ConstantAction<FS> {
  return constantAction(
    'next',
    (dispatch: ActionDispatcher, flowState: FS) => dispatch('next', flowState));
}

export function exitAction<FS>(name: string, merge?: StateMerger<FS>): Action<FS> {
  return (dispatch: ActionDispatcher, state: FS) => {
    dispatch({ type: STEP_POP_CONTEXT, nextStep: name }, getMergedState(state, merge));
  };
}

export function exitFlow<FS>(merge?: StateMerger<FS>): Action<FS> {
  return completeFlow<FS>(merge);
}

export function completeFlow<FS>(merge?: StateMerger<FS>): Action<FS> {
  return exitAction(STEP_FINISH, merge);
}

export function throwError<FS>(message: string): Action<FS> {
  return () => {
    throw new Error(message);
  };
}

export function constantAction<FS>(actionName: string, action: Action<FS>): ConstantAction<FS> {
  // tslint:disable-next-line:no-unnecessary-callback-wrapper
  const newAction: any = (queueResolver: ActionDispatcher, flowState: FS) => action(queueResolver, flowState);

  newAction.bindAs = (nextActionName: string) =>
    constantAction(nextActionName, mapActionSteps(action, {
      [actionName]: nextActionName,
    }));

  return newAction;
}

export function logicalAction<FS>(success: string, fail: string, action: Action<FS>): LogicalAction<FS> {
  // tslint:disable-next-line:no-unnecessary-callback-wrapper
  const newAction: any = (dispatch: ActionDispatcher, flowState: FS) => action(dispatch, flowState);

  newAction.bindAs = (nextSuccess: string, nextFail: string) =>
    logicalAction(nextSuccess, nextFail, mapActionSteps(action, {
      [success]: nextSuccess,
      [fail]: nextFail,
    }));

  return newAction;
}

export function compoundAction<FS>(actions: Hash<ActionExecutor<FS>>): Action<FS> {
  return (dispatch: ActionDispatcher, flowState: FS) => {
    dispatch({
      type: STEP_PUSH_CONTEXT,
      actions,
    },       flowState);
  };
}

export function compoundLogicalAction<FS>(success: string, fail: string,
                                          actions: Hash<ActionExecutor<FS>>): LogicalAction<FS> {
  return logicalAction(success, fail, compoundAction(actions));
}

export function compoundConstantAction<FS>(actionName: string, actions: Hash<ActionExecutor<FS>>): LogicalAction<FS> {
  return constantAction(actionName, compoundAction(actions));
}

function mapActionSteps(action: Action<any>, nameMapping: Hash<string>): Action<any> {
  return (dispatch: ActionDispatcher, flowState: any) => {
    dispatch({
      type: STEP_PUSH_CONTEXT,
      actions: {
        start: action,
        ...mapValues(nameMapping, exitAction),
      },
    },       flowState);
  };
}

export function mapFlowStateAction<C, N, CA extends ConstantAction<C>, NA extends ConstantAction<N>>(toNext: TransformFn<C, N>,
                                                                                                     fromNext: (current: C,
                                                                                                                next: N) => C,
                                                                                                     action: NA): CA;
export function mapFlowStateAction<C, N, CA extends LogicalAction<C>, NA extends LogicalAction<N>>(toNext: TransformFn<C, N>,
                                                                                                   fromNext: (current: C,
                                                                                                              next: N) => C,
                                                                                                   action: NA): CA;
export function mapFlowStateAction<C, N, CA extends Action<C>, NA extends Action<N>>(toNext: TransformFn<C, N>,
                                                                                     fromNext: (current: C,
                                                                                                next: N) => C,
                                                                                     action: NA): CA;
export function mapFlowStateAction(toNext: TransformFn<any, any>,
                                   fromNext: (current: any, next: any) => any,
                                   decoratedAction: Action<any>): Action<any> {
  const newAction = mapStateActionFactory(decoratedAction);

  const bindAs = decoratedAction['bindAs'];

  if (bindAs) {
    newAction['bindAs'] = function(): Action<any> { // tslint:disable-line:only-arrow-functions
      // we use anonymous function here instead of lambda, because we need to access arguments
      return mapStateActionFactory(bindAs.apply(void 0, arguments));
    };
  }

  return newAction;

  function mapStateActionFactory(action: Action<any>): Action<any> {
    return (dispatch: ActionDispatcher, cState: any) => {
      dispatch(
        {
          type: STEP_PUSH_CONTEXT,
          actions: {
            // call original action on start step
            start: (innterDispatch: ActionDispatcher) => action(innterDispatch, toNext(cState)),
            // exit to the requested step on any step (end step)
            '*': (innerDispatch: ActionDispatcher, nState: any, step: Step) =>
              innerDispatch({ type: STEP_POP_CONTEXT, nextStep: step }, fromNext(cState, nState)),
          },
        },
        cState);
    };
  }
}
