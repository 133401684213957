/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { TypeService } from '@gh/core-type';
import { createDateTime, DateTime, nullate } from '@gh/core-util';
import { combineLatest$ } from '@gh/rx';
import { filter$, skip$, startWith$ } from '@gh/rx/operators';
import { isEmpty, pick } from 'lodash';
import { Subscription } from 'rxjs';
import { DateTimePickerInputDirective } from './date-time-picker-input.directive';

@Directive({
  selector: '[ghDateTimePicker]',
  exportAs: 'ghDateTimePicker',
})
export class DateTimePickerDirective implements OnInit, OnDestroy, DoCheck {
  dateControl = new FormControl(void 0, [this.types.get('ShortDate').validator]);
  timeControl = new FormControl(void 0, [this.types.get('ShortTime').validator]);

  private _touched = false;
  private _disabled = false;
  private ignoreValueChange = false;
  private valueSubscription: Subscription;

  constructor(private input: DateTimePickerInputDirective,
              private types: TypeService,
              private control: NgControl) {
    this.input.registerOnSetDisabledState(this.setDisabledState.bind(this));
    this.input.registerOnWriteValue(this.writeValue.bind(this));
  }

  ngOnInit(): void {
    const { dateControl, timeControl } = this;
    this.valueSubscription = combineLatest$(
      dateControl.valueChanges.pipe(startWith$(dateControl.value)),
      timeControl.valueChanges.pipe(startWith$(timeControl.value))).pipe(
      skip$(1),
      filter$(() => !this.ignoreValueChange))
      .subscribe(([date, time]) => {
        this.input.onChange(date || time ? createDateTime(date, time) : void 0);
      });
    if (this.control.statusChanges) {
      this.control.statusChanges.subscribe((status) => {
        if (status === 'INVALID') {
          const { errors } = this.control;

          if (errors) {
            const dateErrors = pick(errors, 'date', 'datetime');
            const timeErrors = pick(errors, 'time', 'datetime');
            this.dateControl.setErrors(isEmpty(dateErrors) ? nullate(void 0) : dateErrors);
            this.timeControl.setErrors(isEmpty(timeErrors) ? nullate(void 0) : timeErrors);
          }
        } else {
          this.dateControl.setErrors(nullate(void 0));
          this.timeControl.setErrors(nullate(void 0));
        }
        this.dateControl.markAsTouched();
        this.timeControl.markAsTouched();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  ngDoCheck(): void {
    const touched = this.dateControl.touched || this.timeControl.touched;
    if (touched === this._touched) {
      return;
    }

    if (touched) {
      this.input.onTouched();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (this._disabled === isDisabled) {
      return;
    }

    if (isDisabled) {
      this.dateControl.disable();
      this.timeControl.disable();
    } else {
      this.dateControl.enable();
      this.timeControl.enable();
    }
  }

  writeValue(datetime?: DateTime): void {
    const { date, time } = datetime ? datetime : { date: void 0, time: void 0 };
    this.ignoreValueChange = true;
    this.dateControl.setValue(date);
    this.timeControl.setValue(time);
    this.ignoreValueChange = false;
  }
}
