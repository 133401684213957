/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[ghCaptureEvent]',
})
export class CaptureEventDirective implements OnInit, OnDestroy {
  @Input('ghCaptureEvent') event: string;

  @Output('ghCaptureEventListener') listener = new EventEmitter<Event>();

  constructor(private elementRef: ElementRef) {
    this.onEvent = this.onEvent.bind(this);
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener(this.event, this.onEvent, true);
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener(this.event, this.onEvent);
  }

  private onEvent(event: Event): void {
    this.listener.emit(event);
  }
}
