/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { of$, throwError$ } from '@gh/rx/index';
import { filter$, first$, switchMap$ } from '@gh/rx/operators';
import { assign, omit, values } from 'lodash';
import { UploadMultiSlot } from './upload-multi-slot';
import { UploadSlot } from './upload-slot';
import { isUploadFinalEvent, isUploadCompleted, UploadEngine, UploadEventType, } from './upload.interfaces';
import * as i0 from "@angular/core";
import * as i1 from "./upload.interfaces";
/**
 * This service controls upload processes. Actually this service is a facade around {@link UploadEngine}
 * which provides actual upload processing. {@link UploadContext} provides additional convenient features
 * for application, like scoped uploads or upload slots.
 *
 * Each upload process has corresponding id (upload ID) assigned by the underlying {@link UploadEngine}.
 * Upload ID allows {@link UploadEngine} to track upload process and associate process with the corresponding entity.
 *
 * Upload process is not restricted by the scope of uploader directive/component.
 * It means upload process should not stop when corresponding component is destroyed.
 *
 * This service can be scoped to the page component. In this case all upload processes will be destroyed
 * as soon as user leaves from the page. In order to scope this service to page component just define it
 * in providers/viewProviders section of the corresponding component.
 */
var UploadContext = /** @class */ (function () {
    function UploadContext(engine) {
        this.engine = engine;
        this.started = {};
        this.init();
    }
    UploadContext.prototype.createUploadSlot = function (params) {
        if (params === void 0) { params = {}; }
        return new UploadSlot(this.engine, params);
    };
    UploadContext.prototype.createUploadMultiSlot = function (params) {
        if (params === void 0) { params = {}; }
        return new UploadMultiSlot(this.engine, params);
    };
    UploadContext.prototype.upload = function (item, params) {
        if (params === void 0) { params = {}; }
        var slot = this.createUploadSlot(params);
        slot.assign(item);
        slot.start();
        return slot.event$.pipe(filter$(isUploadFinalEvent), 
        // first() is used here just in case to fix potential reason for GJG-11163
        // There is no full confidence that underlying uploading engine fires error/cancelled events only once
        first$(), switchMap$(function (event) { return isUploadCompleted(event.item) ? of$(event.item) : throwError$(event.error); }));
    };
    UploadContext.prototype.create = function (file) {
        return this.engine.create(file);
    };
    UploadContext.prototype.start = function (item, params) {
        this.engine.start(item, params);
    };
    UploadContext.prototype.cancel = function (item) {
        this.engine.cancel(item);
    };
    /**
     * Upload context can be scoped to the page component.
     * In this case when attached component is destroyed Angular will also call this method.
     */
    UploadContext.prototype.ngOnDestroy = function () {
        var _this = this;
        values(this.started).forEach(function (item) { return _this.cancel(item); });
    };
    UploadContext.prototype.init = function () {
        var _this = this;
        var event$ = this.engine.event$;
        var started$ = event$.pipe(filter$(function (event) { return event.type === UploadEventType.Started; }));
        var completed$ = event$.pipe(filter$(isUploadFinalEvent));
        started$.subscribe(function (_a) {
            var _b;
            var item = _a.item;
            assign(_this.started, (_b = {}, _b[item.uploadId] = item, _b));
        });
        completed$.subscribe(function (_a) {
            var item = _a.item;
            _this.started = omit(_this.started, item.uploadId);
        });
        this.event$ = event$;
    };
    UploadContext.ɵfac = function UploadContext_Factory(t) { return new (t || UploadContext)(i0.ɵɵinject(i1.UploadEngine)); };
    UploadContext.ɵprov = i0.ɵɵdefineInjectable({ token: UploadContext, factory: UploadContext.ɵfac });
    return UploadContext;
}());
export { UploadContext };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UploadContext, [{
        type: Injectable
    }], function () { return [{ type: i1.UploadEngine }]; }, null); })();
