/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl, FormGroup } from '@angular/forms';
import {
  collectValues,
  FormMode,
  getControl,
  mapControlTree,
  traverseControlTree,
  updateControlTree,
} from '@gh/core-ui';
import { startWith$ } from '@gh/rx/operators';
import { constant, find, isEqual, isNil, omitBy, remove } from 'lodash';
import { Observable } from 'rxjs';
import { ContainerState } from '../container-state';
import { toAppFormGroup } from './controls';
import { AppControl } from './controls/app-control';
import { collectVisibleValues } from './controls/app-control-util';
import { AppFormGroup } from './controls/app-form-group';
import { FormConfirmationState } from './form-confirmation-state';
import {
  controlPackedValue,
  ControlSchemaPackedValue,
  FormRootSchemaConfig,
  FormSchemaFactory,
  FormStateSchema,
} from './form-schema';
import { FormRootSchema } from './form-schema/form-root-schema';
import { FormStateSnapshot, toControlStateEntry } from './form-state-snapshot';

export type FormAndName = { name: string; form: FormState<any> };

/**
 * Form state defines form structure and simplifies common form-related operations.
 */
export class FormState<T> implements ContainerState<T> {
  schema: FormStateSchema;
  private _mode: FormMode;
  private _rootSchema: FormRootSchema;

  private _confirmations: FormConfirmationState;

  /**
   * This field holds all child forms in order of inserting
   * @type {Array}
   */
  private childFormSeq: FormAndName[];
  /**
   * This field holds all child forms in index structure
   * @type {any}
   */
  private childFormIndex: Hash<FormState<any>>;

  private _group: FormGroup;

  constructor(schemaFactory?: FormSchemaFactory) {
    if (schemaFactory) {
      this.schema = schemaFactory.create();
    }
  }

  get group(): FormGroup {
    return this._group;
  }

  get confirmations(): FormConfirmationState {
    return this._confirmations;
  }

  /**
   * Returns valueChanges stream of underlying form group
   * @returns {Observable<any>}
   */
  get valueChanges(): Observable<T> {
    return this._group.valueChanges;
  }

  get pending(): boolean {
    return this._group.pending || this._confirmations.pending;
  }

  /**
   * Returns all child forms with their names
   * @returns {FormAndName[]}
   */
  get childForms(): FormAndName[] {
    return this.childFormSeq;
  }

  get submitValue(): T {
    return collectVisibleValues(<AppFormGroup>this.group);
  }

  get pristine(): boolean {
    return this.group.pristine;
  }

  set mode(_: FormMode) {
    throw new Error('FormState.mode setter was deprecated: use setMode function instead');
  }

  get mode(): FormMode {
    return this._mode;
  }

  get createMode(): boolean {
    return this.mode === FormMode.CreateMode;
  }

  get editMode(): boolean {
    return this.mode === FormMode.EditMode;
  }

  get viewMode(): boolean {
    return this.mode === FormMode.ViewMode;
  }

  get editable(): boolean {
    const { mode } = this;
    return mode === FormMode.CreateMode || mode === FormMode.EditMode;
  }

  get invalid(): boolean {
    return this.group.invalid;
  }

  get dirty(): boolean {
    return this.group.dirty;
  }

  /**
   * Returns underlying form value
   *
   * @returns {any}
   */
  get value(): T {
    return this._group.value;
  }

  /**
   * This method should build form group in subclasses
   */
  build(...args: any[]): void {
    this.initSchema(args[0]);
  }

  /**
   * Returns control from the underlying form group
   * @param name
   * @returns {AbstractControl}
   */
  getControl<K extends keyof T>(name: K): AbstractControl {
    return this._group.controls[<string>name];
  }

  /**
   * Sets control of the underlying form group
   *
   * @param name
   * @param control
   */
  setControl<K extends keyof T>(name: K, control: AbstractControl): void {
    this._group.setControl(<string>name, control);
  }

  /**
   * Returns control from the underlying form group by the given path
   *
   * @param path
   * @returns {AbstractControl}
   */
  get(path: string[] | number[] | string): AppControl {
    return <AppControl>this.group.get(path);
  }

  /**
   * Finds child form using given predicate
   *
   * @param predicate
   * @returns {T}
   */
  findChildForm<K extends keyof T>(predicate: (form: FormState<T[K]>,
                                               name: string) => boolean): Maybe<FormAndName> {
    return find(this.childForms, (form) => predicate(form.form, form.name));
  }

  findNestedForm(predicate: (form: FormState<any>, name: string) => boolean): FormAndName[] {
    const path: FormAndName[] = [];

    let found: Maybe<FormAndName> = {
      form: this,
      name: '',
    };

    if (!predicate(found.form, found.name)) {
      return [];
    }

    do {
      path.push(found);
      found = found.form.findChildForm(predicate);
    } while (found);

    return path;
  }

  /**
   * Returns child form by name
   *
   * @param name
   * @returns {any}
   */
  getChildForm(name: string): FormState<any> {
    return this.childFormIndex[name];
  }

  /**
   * Adds child form under <code>name</code> control of the underlying form group
   *
   * @param name
   * @param form
   */
  setChildForm(name: string, form: FormState<any>): void {
    this.childFormSeq.push({ name, form });
    this.childFormIndex[name] = form;
    // this._group.setControl(name, form.group);

    form.confirmations.setParent(this.confirmations);
  }

  /**
   * Removes child form for the given <code>name</code>
   *
   * @param name
   */
  removeChildForm(name: string): void {
    const form = this.childFormIndex[name];

    if (form) {
      this.childFormSeq = remove(this.childFormSeq, { name });
      this.childFormIndex = <any>omitBy(this.childFormIndex, <any>form);
      // this._group.setControl(name, form.group);

      form.confirmations.resetParent();
    }
  }

  /**
   * Builds form according to the schema.
   *
   * @param originalValue
   */
  initSchema(originalValue: T): void {
    const schema = this.schema;
    if (isNil(schema)) {
      // tslint:disable-next-line:max-line-length
      throw new Error('FormState.initSchema could be called only when FormState is initialized using FormSchemaFactory');
    }

    const rootSchema = this.buildSchema(originalValue, this.schema);

    const isNewSchema = isNil(this._rootSchema);

    if (isNewSchema) {
      rootSchema.init(this);
      this.setGroup(rootSchema.build(originalValue));
      this._rootSchema = rootSchema;
    } else {
      rootSchema.use(this._rootSchema);
      this._rootSchema.update(rootSchema);
      this._rootSchema.applyTo(toAppFormGroup(this.group), controlPackedValue(originalValue));
      this.markAllAsUnchanged();
    }

    this.childFormSeq = [];
    this.childFormIndex = {};
  }

  updateSchema(schema: FormRootSchemaConfig, packedValue?: ControlSchemaPackedValue): void {
    toAppFormGroup(this._group).updateSchema(<FormRootSchemaConfig>schema, packedValue);
  }

  setMode(mode: FormMode): void {
    this._mode = mode;
  }

  /**
   * Sets value for the underlying form group and updates all tree of form controls.
   *
   * Before this method changes underlying form values it calls {@link #beforeSetValue} to allow
   * form delegator to adapt form model to data.
   *
   * After this method have changed underlyling form values it calls {@link #afterSetValue}
   * for some post processing (is it necessary?).
   *
   * @param entity
   * @param extra
   */
  setValue(entity: T, extra: {
    strict?: boolean;
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  } = {}): void {
    this._group.setValue(this.getFormProjection(entity, extra.strict), extra);
  }

  calculatable(control: AbstractControl): Observable<any> {
    return control.valueChanges.pipe(startWith$(control.value));
  }

  markAsPristine(): void {
    this.group.markAsPristine();
  }

  makeSnapshot(): FormStateSnapshot<T> {
    const { group } = this;

    return {
      value: mapControlTree(group, (control) => control.value),
      controlState: mapControlTree(group, toControlStateEntry),
    };
  }

  modifyValue(valueFn: IdentityFn<T>): void {
    const newValue = valueFn(collectValues(this._group));
    this.updateSchema(this.buildSchema(newValue, this.schema), controlPackedValue(newValue));
  }

  refresh(): void {
    this.updateSchema(this.buildSchema(collectValues(this._group), this.schema));
  }

  restore(snapshot: FormStateSnapshot<T>): void {
    updateControlTree(this.group, snapshot.value, (control, value) => {
      if (!isEqual(control.value, value)) {
        control.setValue(value);
      }
    });
    if (snapshot.controlState) {
      updateControlTree(this.group, snapshot.controlState, (control, state) => {
        if (!control.dirty && state && state.dirty) {
          control.markAsDirty();
        }
        if (!control.touched && state && state.touched) {
          control.markAsTouched();
        }
        if (!control.errors && state && state.errors) {
          control.setErrors(state.errors);
        }
      });
    }
  }

  highlightAll(): void {
    this.markAllAsTouched();
  }

  /**
   * Marks all tree of form controls as touched.
   */
  markAllAsTouched(): this {
    traverseControlTree(this._group, (control) => control.markAsTouched());
    return this;
  }

  resetHighlight(): void {
    this.markAsPristine();
  }

  /**
   * Disables form group
   */
  disable(): this {
    this._group.disable();
    return this;
  }

  /**
   * Disables list of controls form group
   */
  disablePartial(controlsNames: string[]): this {
    controlsNames.forEach((name: string) => getControl(this._group, name).disable());
    return this;
  }

  /**
   * Disables list of controls form group
   */
  enablePartial(controlsNames: string[]): this {
    controlsNames.forEach((name: string) => getControl(this._group, name).enable());
    return this;
  }

  /**
   * Enables form group
   */
  enable(): this {
    this._group.enable();
    return this;
  }

  protected buildSchema(value: T, schema: FormStateSchema): FormRootSchema {
    throw new Error('FormState.buildSchema should be implemented in the subclass');
  }

  protected setGroup(group: FormGroup): void {
    this._group = group;

    this._confirmations = new FormConfirmationState(group);
  }

  /**
   * Returns projected values from the entity. Projected entity corresponds to the form structure.
   *
   * @param entity
   * @param strict
   * @returns {{}}
   */
  private getFormProjection(entity: T, strict?: boolean): { [name: string]: any } {
    return Object.keys(this._group.controls).reduce(
      (value, name) => {
        const fieldValue = entity[name];

        if (strict) {
          value[name] = fieldValue;
        } else if (fieldValue === undefined) {
          value[name] = null; // tslint:disable-line:no-null-keyword
        } else {
          value[name] = fieldValue;
        }

        return value;
      },
      {});
  }

  /**
   * Marks all tree of form controls as untouched.
   */
  private markAllAsUnchanged(): this {
    traverseControlTree(this._group, (control) => {
      control.markAsUntouched();
      control.markAsPristine();
    });
    return this;
  }
}
