/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isBoolean, isNil } from 'lodash';

@Pipe({ name: 'isControlRequired', pure: false }) // tslint:disable-line:pipe-impure
export class IsControlRequiredPipe implements PipeTransform {

  transform(control: Nilable<AbstractControl>): boolean {
    if (isNil(control)) {
      return false;
    }

    const required = control['required'];

    if (isBoolean(required)) {
      return required;
    } else {
      throw new Error('Control does not have required flag. Looks like you do not use form schema to build form');
    }
  }
}
