/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

const DEFAULT_LOAD_DISTANCE_PX = 50;

@Directive({
  selector: '[ghInfiniteScroll]',
  providers: [NgModel],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(scroll)': 'onScroll($event)',
  },
})
export class InfiniteScrollDirective {

  // Infinity scroll Element reference (e.g. )
  _element: any;

  // Distance from bottom in px, when triggered new data loading
  @Input('loadDistance') loadDistance: number = DEFAULT_LOAD_DISTANCE_PX;

  // Action triggered when user scrolled to bottom with load distance
  @Output('ghInfiniteScroll') scroll = new EventEmitter<any>();

  constructor(public element: ElementRef) {
    this._element = this.element.nativeElement;
    if (!this.loadDistance) {
      this.loadDistance = DEFAULT_LOAD_DISTANCE_PX;
    }
  }

  onScroll(): void {
    const { _element, loadDistance, scroll } = this;
    const { scrollTop, clientHeight, scrollHeight } = _element;

    const userScrolledToBottomWithLoadDistance =
      scrollTop + clientHeight + loadDistance >= scrollHeight;
    if (userScrolledToBottomWithLoadDistance) {
      scroll.emit(void 0);
    }
  }
}
