/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { isRouterStateLazyActivator } from '@gh/core-lazy';
import { DialogService } from '@gh/core-ui';
import { wrapInObservable } from '@gh/core-util';
import { FALSE$, interval$, of$, throwError$, TRUE$ } from '@gh/rx/index';
import { catchError$, concatMap$, filter$, first$, mapTo$, scan$, switchMap$ } from '@gh/rx/operators';
import * as invariant from 'invariant';
import { isFunction, isNil, reverse } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-ui";
var LeaveGuard = /** @class */ (function () {
    function LeaveGuard(dialogs) {
        this.dialogs = dialogs;
    }
    LeaveGuard.prototype.canDeactivate = function (component, route, _, nextState) {
        // always allow any lazy activator route as it never results to location change
        if (nextState && isRouterStateLazyActivator(nextState)) {
            return true;
        }
        // try to close all dialogs first
        return this.closeAllDialogs().pipe(switchMap$(function (allowToClose) {
            if (!allowToClose) {
                return FALSE$;
            }
            // if current route supports leave flow then delegate deactivate logic to component itself
            var supportsLeave = route.data['leave'];
            if (supportsLeave) {
                invariant(isFunction(component.canDeactivate), "Component for route " + route.toString() + " does not have canDeactivate function");
                return interval$(1).pipe(first$(), switchMap$(function () { return wrapInObservable(component.canDeactivate(nextState)); }));
            }
            else {
                return TRUE$;
            }
        }));
    };
    LeaveGuard.prototype.closeAllDialogs = function () {
        // close dialogs starting from the last one
        var openedDialogs = this.dialogs.openedDialogs;
        if (openedDialogs.length === 0) {
            return TRUE$;
        }
        return of$.apply(void 0, reverse(openedDialogs)).pipe(concatMap$(function (dialogRef) {
            var componentInstance = dialogRef.componentInstance;
            // if dialog is in loading state => complete loading
            if (isNil(componentInstance)) {
                dialogRef.close();
                return TRUE$;
            }
            // if dialog is not deactivatable => close dialog
            var isDeactivatable = componentInstance['canDeactivate'];
            if (!isDeactivatable) {
                dialogRef.close();
                return TRUE$;
            }
            // if dialog is deactivatable => run deactivate flow
            return wrapInObservable(componentInstance.canDeactivate()).pipe(switchMap$(function (allowToClose) {
                if (allowToClose) {
                    dialogRef.close();
                    return TRUE$;
                }
                else {
                    // if dialog cannot be closed => stop closeDialogs flow
                    return throwError$('break');
                }
            }));
        }), scan$(function (sum, _) { return sum + 1; }, 0), filter$(function (sum) { return sum === openedDialogs.length; }), mapTo$(true), catchError$(function () { return FALSE$; }));
    };
    LeaveGuard.ɵfac = function LeaveGuard_Factory(t) { return new (t || LeaveGuard)(i0.ɵɵinject(i1.DialogService)); };
    LeaveGuard.ɵprov = i0.ɵɵdefineInjectable({ token: LeaveGuard, factory: LeaveGuard.ɵfac });
    return LeaveGuard;
}());
export { LeaveGuard };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LeaveGuard, [{
        type: Injectable
    }], function () { return [{ type: i1.DialogService }]; }, null); })();
