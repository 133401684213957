/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'keys',
  pure: true,
})
export class KeysPipe implements PipeTransform {

  transform(value: any): any {
    if (isNil(value)) {
      return;
    } else if (typeof value === 'object') {
      return Object.keys(value);
    }
  }
}
