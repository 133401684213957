/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { isNil } from 'lodash';
import * as i0 from "@angular/core";
var KeysPipe = /** @class */ (function () {
    function KeysPipe() {
    }
    KeysPipe.prototype.transform = function (value) {
        if (isNil(value)) {
            return;
        }
        else if (typeof value === 'object') {
            return Object.keys(value);
        }
    };
    KeysPipe.ɵfac = function KeysPipe_Factory(t) { return new (t || KeysPipe)(); };
    KeysPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "keys", type: KeysPipe, pure: true });
    return KeysPipe;
}());
export { KeysPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(KeysPipe, [{
        type: Pipe,
        args: [{
                name: 'keys',
                pure: true,
            }]
    }], null, null); })();
