/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';
import { LocalizationBusinessService } from '../services';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  constructor(private localization: LocalizationBusinessService) {
  }

  transform(value: any): any {
    return isNil(value) ? value : this.localization.formatHoursDuration(value);
  }
}
