/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonExternalSharedModule } from '@gh/common-external-shared';
import { UserContext } from '@gh/common-shared';
import { CustomerUserContext, CustomerUserContextService, CustomerUserProfileStore } from './user';

@NgModule({
  imports: [
    CommonExternalSharedModule.forChild(),
  ],
  declarations: [],
  exports: [
    CommonExternalSharedModule,
  ],
})
export class CustomerSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CustomerSharedModule,
      providers: [
        { provide: CustomerUserContext, useClass: CustomerUserContextService },
        { provide: UserContext, useExisting: CustomerUserContext },
        CustomerUserProfileStore,
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: CustomerSharedModule,
      providers: [],
    };
  }
}
