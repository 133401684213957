/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input, IterableDiffers, Optional, TemplateRef, ViewContainerRef, } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective, NgControl, NgForm, } from '@angular/forms';
import { FormMessageService } from '@gh/core-mls';
import { denull } from '@gh/core-util';
import { isNil, uniqBy } from 'lodash';
import { ErrorStateMatcher } from '@angular/material/core';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
import * as i2 from "@angular/material/core";
import * as i3 from "@angular/forms";
function errorTrackBy(index, message) {
    return message.key;
}
var MAT_ERROR_RE = /^mat[A-Za-z]/;
var MessagesForDirective = /** @class */ (function () {
    function MessagesForDirective(messageService, viewContainerRef, differs, templateRef, errorStateMatcher, parentForm, parentFormGroup, controlContainer) {
        this.messageService = messageService;
        this.viewContainerRef = viewContainerRef;
        this.differs = differs;
        this.templateRef = templateRef;
        this.errorStateMatcher = errorStateMatcher;
        this.parentForm = parentForm;
        this.parentFormGroup = parentFormGroup;
        this.controlContainer = controlContainer;
        /**
         * This options allows to enable/disable displaying of standard material errors
         * (matDatepickerFilter, matDatepickerMin, etc)
         */
        this.matErrors = true;
        this._messageRepository = messageService;
    }
    Object.defineProperty(MessagesForDirective.prototype, "ghMessagesFor", {
        set: function (target) {
            this.setTarget(target);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessagesForDirective.prototype, "ghMessagesForOf", {
        set: function (target) {
            this.setTarget(target);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessagesForDirective.prototype, "messageRepository", {
        set: function (repository) {
            this._messageRepository = repository ? this.messageService.createNested(repository) : this.messageService;
        },
        enumerable: true,
        configurable: true
    });
    MessagesForDirective.prototype.ngDoCheck = function () {
        this.checkErrors();
        if (this.differ) {
            var changes = this.differ.diff(this.errors || []);
            if (changes) {
                this.applyChanges(changes);
            }
        }
    };
    MessagesForDirective.prototype.setTarget = function (target) {
        var control = undefined;
        if (target instanceof FormControl) {
            control = target;
        }
        else if (target instanceof NgControl) {
            control = denull(target.control);
        }
        else if (target) {
            control = this.getChildControl(target);
        }
        this.control = control;
    };
    MessagesForDirective.prototype.applyChanges = function (changes) {
        var _this = this;
        changes.forEachOperation(function (record, adjustedPreviousIndex, currentIndex) {
            if (isNil(record.previousIndex)) {
                var view = _this.viewContainerRef.createEmbeddedView(_this.templateRef, {}, currentIndex);
                view.context.$implicit = record.item;
            }
            else if (isNil(currentIndex)) {
                _this.viewContainerRef.remove(adjustedPreviousIndex);
            }
            else {
                var view = _this.viewContainerRef.get(adjustedPreviousIndex);
                _this.viewContainerRef.move(view, currentIndex);
                view.context.$implicit = record.item;
            }
        });
        changes.forEachIdentityChange(function (record) {
            var viewRef = _this.viewContainerRef.get(record.currentIndex);
            viewRef.context.$implicit = record.item;
        });
    };
    MessagesForDirective.prototype.getChildControl = function (index) {
        var control = this.controlContainer.control;
        if (control instanceof FormGroup) {
            return control.controls[index];
        }
        else if (control instanceof FormArray) {
            return control.controls[index];
        }
        throw new Error('Wrong parent control');
    };
    MessagesForDirective.prototype.checkErrors = function () {
        var _this = this;
        var errorMap = this.control && this.control.errors;
        if (!errorMap || !this.canDisplayMessages()) {
            this.errors = [];
            return;
        }
        else if (this.errorMap === errorMap) {
            return;
        }
        var errors = Object.keys(errorMap).map(function (key) { return ({
            key: key,
            params: errorMap[key],
        }); });
        errors.forEach(function (error) {
            error.text = _this._messageRepository.getText(error.key, error.params);
        });
        if (errors.length > 1) {
            errors = errors.filter(function (error) { return error.key !== 'required'; });
            errors = uniqBy(errors, function (error) { return error.text; });
        }
        if (!this.matErrors) {
            errors = errors.filter(function (error) { return !MAT_ERROR_RE.test(error.key); });
        }
        this.errors = errors;
        this.errorMap = errorMap;
        if (!this.differ) {
            this.differ = this.differs.find(errors).create(errorTrackBy);
        }
    };
    MessagesForDirective.prototype.canDisplayMessages = function () {
        var control = this.control;
        if (!control) {
            return false;
        }
        // tslint:disable-next-line:no-null-keyword
        return this.errorStateMatcher.isErrorState(control || null, this.parentFormGroup || this.parentForm);
    };
    MessagesForDirective.ɵfac = function MessagesForDirective_Factory(t) { return new (t || MessagesForDirective)(i0.ɵɵdirectiveInject(i1.FormMessageService), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.IterableDiffers), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i2.ErrorStateMatcher), i0.ɵɵdirectiveInject(i3.NgForm, 8), i0.ɵɵdirectiveInject(i3.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i3.ControlContainer, 8)); };
    MessagesForDirective.ɵdir = i0.ɵɵdefineDirective({ type: MessagesForDirective, selectors: [["", "ghMessagesFor", ""]], inputs: { matErrors: ["ghMessagesForMatErrors", "matErrors"], ghMessagesFor: "ghMessagesFor", ghMessagesForOf: "ghMessagesForOf", messageRepository: ["ghMessagesForRepository", "messageRepository"] } });
    return MessagesForDirective;
}());
export { MessagesForDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MessagesForDirective, [{
        type: Directive,
        args: [{
                selector: '[ghMessagesFor]',
            }]
    }], function () { return [{ type: i1.FormMessageService }, { type: i0.ViewContainerRef }, { type: i0.IterableDiffers }, { type: i0.TemplateRef }, { type: i2.ErrorStateMatcher }, { type: i3.NgForm, decorators: [{
                type: Optional
            }] }, { type: i3.FormGroupDirective, decorators: [{
                type: Optional
            }] }, { type: i3.ControlContainer, decorators: [{
                type: Optional
            }] }]; }, { matErrors: [{
            type: Input,
            args: ['ghMessagesForMatErrors']
        }], ghMessagesFor: [{
            type: Input
        }], ghMessagesForOf: [{
            type: Input
        }], messageRepository: [{
            type: Input,
            args: ['ghMessagesForRepository']
        }] }); })();
