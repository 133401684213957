/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, HostBinding, Input } from '@angular/core';

export type ExtDialogButtonBarAlign = 'none' | 'center';

@Component({
  selector: 'gh-ext-dialog-button-bar',
  template: '<ng-content></ng-content>',
  styleUrls: ['ext-dialog-button-bar.component.scss'],
})
export class ExtDialogButtonBarComponent {
  @Input() align: ExtDialogButtonBarAlign = 'none';

  @HostBinding('class.m-is-align-none')
  get isAlignNone(): boolean {
    return this.align === 'none';
  }

  @HostBinding('class.m-is-align-center')
  get isAlignCenter(): boolean {
    return this.align === 'center';
  }
}
