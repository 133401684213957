/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { WaitDirective } from './wait.directive';
import { WaitingContext } from './waiting-context.service';
import { WaitingScopeDirective } from './waiting-scope.directive';
import { WaitingService } from './waiting.service';

@NgModule({
  declarations: [
    WaitingScopeDirective,
    WaitDirective,
  ],
  exports: [
    WaitingScopeDirective,
    WaitDirective,
  ],
})
export class CoreUiWaitingModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreUiWaitingModule,
      providers: [
        WaitingService,
        WaitingContext,
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: CoreUiWaitingModule,
    };
  }
}
