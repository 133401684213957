/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { DATA_SERVICES } from './services';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
})
export class CommonSharedDataModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CommonSharedDataModule,
      providers: DATA_SERVICES,
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: CommonSharedDataModule,
      providers: [],
    };
  }
}
