/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input } from '@angular/core';
import { HelpProvider } from './help-provider.service';
import * as i0 from "@angular/core";
import * as i1 from "./help-provider.service";
var HelpIdDirective = /** @class */ (function () {
    function HelpIdDirective(helpProvider) {
        this.helpProvider = helpProvider;
    }
    Object.defineProperty(HelpIdDirective.prototype, "helpId", {
        get: function () {
            return this._helpId;
        },
        set: function (helpId) {
            if (this._helpId) {
                this.helpProvider.resetCurrent();
            }
            this._helpId = helpId;
            if (helpId) {
                this.helpProvider.setCurrent(helpId);
            }
        },
        enumerable: true,
        configurable: true
    });
    HelpIdDirective.prototype.ngOnDestroy = function () {
        var helpId = this.helpId;
        if (helpId) {
            this.helpProvider.resetCurrent();
        }
    };
    HelpIdDirective.ɵfac = function HelpIdDirective_Factory(t) { return new (t || HelpIdDirective)(i0.ɵɵdirectiveInject(i1.HelpProvider)); };
    HelpIdDirective.ɵdir = i0.ɵɵdefineDirective({ type: HelpIdDirective, selectors: [["", "ghHelpId", ""]], inputs: { helpId: ["ghHelpId", "helpId"] } });
    return HelpIdDirective;
}());
export { HelpIdDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(HelpIdDirective, [{
        type: Directive,
        args: [{
                selector: '[ghHelpId]',
            }]
    }], function () { return [{ type: i1.HelpProvider }]; }, { helpId: [{
            type: Input,
            args: ['ghHelpId']
        }] }); })();
