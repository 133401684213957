/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NumberTypeValidators } from '../validators';
import { AbstractPrimitiveType } from './abstract-type';
import { createNumberMask } from './create-number-mask';
var NumberTypeImpl = /** @class */ (function (_super) {
    __extends(NumberTypeImpl, _super);
    function NumberTypeImpl(defenition) {
        var _this = _super.call(this, defenition, NumberTypeValidators) || this;
        _this.formatQuery = {
            minimumFractionDigits: _this.defenition.format && _this.defenition.format.minFractionDigits,
            maximumFractionDigits: _this.defenition.format && _this.defenition.format.maxFractionDigits,
        };
        return _this;
    }
    NumberTypeImpl.prototype.parse = function (str) {
        return this.numberParser.parse(str);
    };
    NumberTypeImpl.prototype.format = function (value, query) {
        return this.numberFormat.format(query || this.formatQuery, value);
    };
    NumberTypeImpl.prototype.validateFormat = function (str) {
        if (!this.numberParser.validate(str)) {
            return {
                format: {
                    messageId: 'msg_field_invalid_format',
                },
            };
        }
    };
    NumberTypeImpl.prototype.init = function (name, localizationProvider) {
        _super.prototype.init.call(this, name, localizationProvider);
        this.localization = localizationProvider.getNumberLocalization();
        this.numberFormat = localizationProvider.getNumberFormat();
        this.numberParser = localizationProvider.getNumberParser();
        var constraints = this.defenition.constraints || {};
        this.mask = createNumberMask({
            prefix: '',
            suffix: '',
            includeThousandsSeparator: this.localization.groupSeparator ? true : false,
            thousandsSeparatorSymbol: this.localization.groupSeparator,
            allowNegative: constraints.min && constraints.min.value < 0
                || constraints.range && constraints.range.min < 0
                || false,
            allowDecimal: this.defenition.format && this.defenition.format.maxFractionDigits > 0 || false,
            decimalSymbol: this.localization.decimalSeparator,
            decimalLimit: this.defenition.format && this.defenition.format.maxFractionDigits || false,
            requireDecimal: false,
        });
    };
    return NumberTypeImpl;
}(AbstractPrimitiveType));
export { NumberTypeImpl };
