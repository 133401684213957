/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { ContextMenuTriggerDirective } from './context-menu-trigger.directive';

@NgModule({
  declarations: [ContextMenuTriggerDirective],
  exports: [ContextMenuTriggerDirective],
})
export class CoreUiMenuModule {
  static forRoot(): ModuleWithProviders {
    return { ngModule: CoreUiMenuModule };
  }

  static forChild(): ModuleWithProviders {
    return { ngModule: CoreUiMenuModule };
  }
}
