/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { distinctUntilChanged$, filter$ } from '@gh/rx/operators';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export var UserLoginStatus;
(function (UserLoginStatus) {
    UserLoginStatus[UserLoginStatus["Unknown"] = 0] = "Unknown";
    UserLoginStatus[UserLoginStatus["LoggedIn"] = 1] = "LoggedIn";
    UserLoginStatus[UserLoginStatus["LoggedOut"] = 2] = "LoggedOut";
})(UserLoginStatus || (UserLoginStatus = {}));
var CommonUserProfileStore = /** @class */ (function () {
    function CommonUserProfileStore() {
        this.status$$ = new BehaviorSubject(UserLoginStatus.Unknown);
        this.user$$ = new BehaviorSubject(void 0);
    }
    Object.defineProperty(CommonUserProfileStore.prototype, "user", {
        get: function () {
            return this.user$$.hasError ? void 0 : this.user$$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUserProfileStore.prototype, "user$", {
        get: function () {
            // @ts-ignore
            return this.user$$.pipe(filter$(Boolean));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUserProfileStore.prototype, "status$", {
        get: function () {
            return this.status$$.asObservable().pipe(distinctUntilChanged$());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUserProfileStore.prototype, "initialized", {
        get: function () {
            return !this.user$$.hasError && Boolean(this.user$$.value);
        },
        enumerable: true,
        configurable: true
    });
    CommonUserProfileStore.prototype.setLoggedInUser = function (user) {
        this.status$$.next(UserLoginStatus.LoggedIn);
        this.user$$.next(user);
    };
    CommonUserProfileStore.prototype.setLoginError = function (error) {
        this.user$$.error(error);
    };
    CommonUserProfileStore.prototype.setLoggedOut = function () {
        this.status$$.next(UserLoginStatus.LoggedOut);
    };
    CommonUserProfileStore.ɵfac = function CommonUserProfileStore_Factory(t) { return new (t || CommonUserProfileStore)(); };
    CommonUserProfileStore.ɵprov = i0.ɵɵdefineInjectable({ token: CommonUserProfileStore, factory: CommonUserProfileStore.ɵfac });
    return CommonUserProfileStore;
}());
export { CommonUserProfileStore };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CommonUserProfileStore, [{
        type: Injectable
    }], null, null); })();
