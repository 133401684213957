/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from$, throwError$ } from '@gh/rx';
import { catchError$, map$, switchMap$ } from '@gh/rx/operators';

import { Observable } from 'rxjs';

const reqWithoutNoRefreshHeader = (req: HttpRequest<any>) =>
  req.clone({ headers: req.headers.delete('X-No-Auth-Refresh') });

const responseWithNoRefreshHeader = (resp: HttpResponse<any>) =>
  resp.clone({ headers: resp.headers.set('X-No-Auth-Refresh', 'true') });

const responseWithNoRefreshHeader$ = (event$: Observable<HttpEvent<any>>) =>
  event$.pipe(
    map$((event) => {
      if (event.type === HttpEventType.Response) {
        return responseWithNoRefreshHeader(event);
      } else {
        return event;
      }
    }),
    catchError$((response) => {
      if (response) {
        return throwError$(responseWithNoRefreshHeader(response));
      } else {
        return throwError$(response);
      }
    }));

export abstract class BasicAuthHttpRequestInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('X-No-Auth-Refresh')) {
      const hasNoAuth = req.headers.get('X-No-Auth-Refresh') === 'true';
      if (hasNoAuth) {
        return this.interceptWithoutRefresh(reqWithoutNoRefreshHeader(req), next).pipe(responseWithNoRefreshHeader$);
      } else {
        return this.interceptWithRefresh(reqWithoutNoRefreshHeader(req), next).pipe(responseWithNoRefreshHeader$);
      }
    } else {
      return this.interceptWithRefresh(req, next);
    }
  }

  protected abstract interceptWithoutRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;

  protected abstract interceptWithRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;
}
