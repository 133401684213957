/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { ValidationErrors } from '@angular/forms';
import { createEnum, EnumSet, WithOptimisticLock } from '@gh/core-data';
import { FormMode } from '@gh/core-ui';
import { setField } from '@gh/core-util';

import { negate } from 'lodash';

// We need id type to avoid problems in future.
// On the moment of writing all IDs were numbers.
// In future ID will be a hashed string.
// By using this type we can explicitly understand what is the nature of entity identifier.
export type ID = string;

export type EntityVersion = number;

export const DirtyType = createEnum('DIRTY_TYPE', {
  New: 'NEW',
  Removed: 'DELETED',
  Updated: 'UPDATED',
});
export type DirtyType = EnumSet<typeof DirtyType>;

export interface DirtyTypeSupport {
  dirtyType?: DirtyType;
}

export const ID = String;

export type EntityFieldState = { required: boolean; editable: boolean; errors?: ValidationErrors };
export type EntityState = { [name: string]: EntityFieldState };
export type WithEntityState<T> = T & { entityState?: EntityState };
export type WithFormMode<T> = T & { formMode: FormMode };
export type WithDirty<T> = T & { isDirty?: boolean };
export type WithDirtyType<T> = T & { dirtyType?: DirtyType };
export type WithVersion<T> = T & { version?: EntityVersion };
export type WithRecalculationNeeded<T> = T & { isRecalculationNeeded?: boolean };
export type WithUniqueId<T> = T & { uniqueId: string };
export type WithCreatedDate<T> = T & { createdDate?: Date };
export type WithNewItemId<T> = T & { newItemId?: number };

export const EMPTY_ID: ID = '';

export const PrintEntryPoint = createEnum('QUOTATION_PRINT_EP', {
  Produce: 'PRODUCE',
  Preview: 'PREVIEW',
});

export type PrintEntryPoint = EnumSet<typeof PrintEntryPoint>;

export type IdWithOptimisticLock = WithOptimisticLock<{ id: ID }>;

/**
 * Returns assigned entity state
 * @param entity
 */
export function getEntityState<T extends WithEntityState<E>, E>(entity: T): EntityState {
  return entity['entityState'] || {};
}

/**
 * Sets entity state
 * @param entity
 * @param entityState
 */
export function setEntityState<T extends WithEntityState<E>, E>(entity: T, entityState: EntityState): T {
  return setField(entity, 'entityState', entityState);
}

/**
 * Returns state of entity field
 * @param entity
 * @param field
 */
export function getEntityFieldState<T extends WithEntityState<E>, E>(entity: T, field: string): EntityFieldState {
  return getEntityState(entity)[field] || { required: false, editable: true };
}

/**
 * Sets state of the given field
 * @param entity
 * @param field
 * @param fieldState
 */
export function setEntityFieldState<T extends WithEntityState<E>, E>(entity: T,
                                                                     field: string,
                                                                     fieldState: EntityFieldState): T {
  const entityState = getEntityState(entity);

  return setEntityState(entity, {
    ...entityState,
    [field]: fieldState,
  });
}

export type ProduceStatusDto = {
  processed: number;
  total: number;
};

export type ReportStatusDto = {
  processed: boolean;
};

export type MetedataLinkDto = {
  s3Link: string;
  processed: number;
  total: number;
};
export type ProduceStatus = ProduceStatusDto;
export type ReportStatus = ReportStatusDto;
export type WithProduceStatus<T> = T & { produceStatus: ProduceStatus };

export const EMPTY_PRODUCE_STATUS = { processed: 0, total: 0 };
export const EMPTY_REPORT_STATUS = { processed: false };

export const isProduceStatusInProgress = ({ total, processed }: ProduceStatus) =>
  total > 0 && processed >= 0 && total !== processed;

export const isReportStatusInProgress = ({ processed }: ReportStatus) =>
  !processed;

export const isProduceStatusCompleted = negate(isProduceStatusInProgress);
