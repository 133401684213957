import { Injectable } from '@angular/core';
import { isClientErrorStatus, isServerErrorStatus } from '@gh/core-data';
import { throwError$ } from '@gh/rx';
import { catchError$ } from '@gh/rx/operators';
import { isNil } from 'lodash';
import { MessageBasket } from './message-basket';
import { createMessageBasketFromResponse } from './message.utils';
import * as i0 from "@angular/core";
var MessageHttpErrorInterceptor = /** @class */ (function () {
    function MessageHttpErrorInterceptor() {
    }
    MessageHttpErrorInterceptor.prototype.intercept = function (req, next) {
        return next.handle(req).pipe(catchError$(function (response) {
            if (isNil(response) || isServerErrorStatus(response.status)) {
                return throwError$(MessageBasket.empty());
            }
            else if (isClientErrorStatus(response.status)) {
                return throwError$(createMessageBasketFromResponse(response));
            }
            return throwError$(response);
        }));
    };
    MessageHttpErrorInterceptor.ɵfac = function MessageHttpErrorInterceptor_Factory(t) { return new (t || MessageHttpErrorInterceptor)(); };
    MessageHttpErrorInterceptor.ɵprov = i0.ɵɵdefineInjectable({ token: MessageHttpErrorInterceptor, factory: MessageHttpErrorInterceptor.ɵfac });
    return MessageHttpErrorInterceptor;
}());
export { MessageHttpErrorInterceptor };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MessageHttpErrorInterceptor, [{
        type: Injectable
    }], null, null); })();
