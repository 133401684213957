/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MessageSeverity } from '@gh/core-messages';
import { TranslationParams, TranslationService } from '@gh/core-mls';
import { DialogService, NotificationConfig, NotificationRef, NotificationService } from '@gh/core-ui';
import { Observable } from 'rxjs';

export class ExtNotificationRef implements NotificationRef {
  get afterClosed(): Observable<any> {
    return this.snackBarRef.afterDismissed();
  }

  get clicks(): Observable<void> {
    return this.snackBarRef.onAction();
  }

  constructor(private snackBarRef: MatSnackBarRef<any>) {
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}

@Injectable()
export class ExtNotificationService extends NotificationService {
  constructor(private snackBar: MatSnackBar,
              private dialogs: DialogService,
              private translations: TranslationService) {
    super();
  }

  error(id: string, params?: TranslationParams): NotificationRef {
    return this.show({
      messageId: id,
      messageParams: params,
      severity: MessageSeverity.Error,
      hideDelay: 5000,
    });
  }

  success(id: string, params?: TranslationParams): NotificationRef {
    return this.show({
      messageId: id,
      messageParams: params,
      severity: MessageSeverity.Error,
      hideDelay: 5000,
    });
  }

  warning(id: string, params?: TranslationParams): NotificationRef {
    return this.show({
      messageId: id,
      messageParams: params,
      severity: MessageSeverity.Error,
      hideDelay: 5000,
    });
  }

  show(config: NotificationConfig): NotificationRef {
    const snackBarRef = this.snackBar.open(
      this.translations.get(config.messageId, config.messageParams),
      this.translations.get('lbl_open'),
      {
        duration: config.hideDelay,
      });

    const notificationRef = new ExtNotificationRef(snackBarRef);
    notificationRef.clicks
      .subscribe(() => this.dialogs.message({
        messageId: config.messageId,
        messageParams: config.messageParams,
        severity: config.severity,
      }));
    return notificationRef;
  }

  closeAll(): void {
    this.snackBar.dismiss();
  }
}
