/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Output, } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/material/icon";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "../../../../core-mls/translation/translate.pipe";
function AutocompleteActionIconComponent_mat_icon_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-icon", 1);
    i0.ɵɵpipe(1, "translate");
} if (rf & 2) {
    i0.ɵɵpropertyInterpolate("matTooltip", i0.ɵɵpipeBind1(1, 1, "lbl_clear"));
} }
var AutocompleteActionIconComponent = /** @class */ (function () {
    function AutocompleteActionIconComponent(_element, changeDetectorRef) {
        this._element = _element;
        this.changeDetectorRef = changeDetectorRef;
        this.trigger = new EventEmitter();
        this.disabled = false;
        this.display = false;
    }
    AutocompleteActionIconComponent.prototype.handleClick = function ($event) {
        if (this._element.nativeElement.parentElement.firstElementChild.disabled) {
            return;
        }
        $event.stopPropagation();
        $event.preventDefault();
        this.display = !this.display;
        this.trigger.emit($event);
        this.changeDetectorRef.markForCheck();
    };
    AutocompleteActionIconComponent.prototype.setDisplay = function (isDisplay) {
        this.display = isDisplay;
        this.changeDetectorRef.markForCheck();
    };
    AutocompleteActionIconComponent.prototype.setDisabled = function (isDisabled) {
        this.disabled = isDisabled;
        this.changeDetectorRef.markForCheck();
    };
    AutocompleteActionIconComponent.ɵfac = function AutocompleteActionIconComponent_Factory(t) { return new (t || AutocompleteActionIconComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
    AutocompleteActionIconComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AutocompleteActionIconComponent, selectors: [["gh-autocomplete-action-icon"]], hostBindings: function AutocompleteActionIconComponent_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("click", function AutocompleteActionIconComponent_click_HostBindingHandler($event) { return ctx.handleClick($event); });
        } }, outputs: { trigger: "trigger" }, decls: 1, vars: 1, consts: [["svgIcon", "gh:close", "class", "s16 gh-autocomplete-clear-icon", 3, "matTooltip", 4, "ngIf"], ["svgIcon", "gh:close", 1, "s16", "gh-autocomplete-clear-icon", 3, "matTooltip"]], template: function AutocompleteActionIconComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, AutocompleteActionIconComponent_mat_icon_0_Template, 2, 3, "mat-icon", 0);
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", ctx.display && !ctx.disabled);
        } }, directives: [i1.NgIf, i2.MatIcon, i3.MatTooltip], pipes: [i4.TranslatePipe], styles: [".gh-autocomplete-clear-icon[_ngcontent-%COMP%] {\n  position: absolute;\n  top: 6px;\n  left: calc(100% - 20px);\n  color: rgba(0, 0, 0, 0.54);\n  cursor: pointer; }"], changeDetection: 0 });
    return AutocompleteActionIconComponent;
}());
export { AutocompleteActionIconComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AutocompleteActionIconComponent, [{
        type: Component,
        args: [{
                moduleId: module.id,
                selector: 'gh-autocomplete-action-icon',
                templateUrl: 'autocomplete-action-icon.component.html',
                styleUrls: ['autocomplete-icon.component.scss'],
                changeDetection: ChangeDetectionStrategy.OnPush,
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.ChangeDetectorRef }]; }, { trigger: [{
            type: Output
        }], handleClick: [{
            type: HostListener,
            args: ['click', ['$event']]
        }] }); })();
