/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, InjectionToken, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APPLICATION, BACKEND, REGION_CODE } from '@gh/config';
import { AuthState } from '@gh/core-auth';
import { denull } from '@gh/core-util';
import { delay$, publishReplay$, refCount$, scan$, tap$ } from '@gh/rx/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { MockAuthProvider } from '../mock-auth-provider.service';
import { MockLoginDialogComponent } from './mock-login-dialog/mock-login-dialog.component';

const STORAGE_KEY = `mock-auth:${APPLICATION}:${BACKEND}:${REGION_CODE}:user-key`;

function getUserKey(): Maybe<string> {
  return denull(localStorage.getItem(STORAGE_KEY));
}

function setUserKey(userKey: string): void {
  localStorage.setItem(STORAGE_KEY, userKey);
}

function removeUserKey(): void {
  localStorage.removeItem(STORAGE_KEY);
}

function updateUserKey(userKey?: string): void {
  if (userKey) {
    setUserKey(userKey);
  } else {
    removeUserKey();
  }
}

export type PrimaryMockOptions = {
  fields: string[];
  width: number;
};

export const PRIMARY_MOCK_OPTIONS = new InjectionToken<PrimaryMockOptions>('PrimaryMockOptions');

@Injectable()
export class PrimaryMockAuthProvider extends MockAuthProvider {
  readonly onAuthState: Observable<AuthState>;

  private _authState: AuthState;
  private userKey$$ = new BehaviorSubject<Maybe<string>>(getUserKey());

  constructor(private injector: Injector) {
    super();

    this.onAuthState = this.userKey$$.pipe(
      tap$(updateUserKey),
      scan$(
        (state: AuthState, userKey: Maybe<string>) => {
          if (userKey) {
            return AuthState.LoggedIn;
          } else if (state === AuthState.LoggedIn) {
            return AuthState.LogggedOut;
          } else {
            return AuthState.Unknown;
          }
        },
        AuthState.Unknown),
      publishReplay$(1),
      refCount$(),
      // Delay is necessary to make this stream asynchronous.
      // This stream can be read under APP_INITIALIZER service.
      // When stream is synchronous ApplicationRef may be not initialized when dependent code needs it.
      delay$(0));

    this.onAuthState.subscribe((state) => {
      this._authState = state;
    });
  }

  get dialogService(): MatDialog {
    return this.injector.get(MatDialog);
  }

  get authState(): AuthState {
    return this._authState;
  }

  get userKey(): string {
    const { value } = this.userKey$$;

    if (value) {
      return value;
    } else {
      throw new Error('User is not logged in');
    }
  }

  login(): Promise<boolean> {
    return this.dialogService.open(MockLoginDialogComponent, { disableClose: true })
      .afterClosed().toPromise().then((userKey) => {
        this.userKey$$.next(userKey);
        window.location.reload();
        return Boolean(userKey);
      });
  }

  logout(): Promise<boolean> {
    this.userKey$$.next(void 0);
    window.location.reload();
    return Promise.resolve(true);
  }
}
