var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { CommonUserProfileStore } from '@gh/common-shared';
import { EMPTY_ID } from '@gh/common-shared-data';
import { DEFAULT_LOCALE } from '@gh/config';
import { Locale } from '@gh/core-mls';
import { CustomerUserProfileRestService } from '@gh/customer-shared-data';
import * as i0 from "@angular/core";
import * as i1 from "@gh/customer-shared-data";
function customerUserDataFromDto(dto) {
    return {
        backendBuildNumber: '',
        firstName: '',
        lastName: '',
        id: EMPTY_ID,
        locale: Locale.createFromStandard(DEFAULT_LOCALE),
        fullName: '',
        zoneId: '',
        login: dto.email,
    };
}
var CustomerUserProfileStore = /** @class */ (function (_super) {
    __extends(CustomerUserProfileStore, _super);
    function CustomerUserProfileStore(userProfileRest) {
        var _this = _super.call(this) || this;
        _this.userProfileRest = userProfileRest;
        return _this;
    }
    CustomerUserProfileStore.prototype.load = function (params) {
        var _this = this;
        this.userProfileRest.get()
            .subscribe(function (user) { return _this.setLoggedInUser(customerUserDataFromDto(user)); }, function (error) { return _this.setLoginError(error); });
    };
    CustomerUserProfileStore.prototype.logout = function () {
        var _this = this;
        this.userProfileRest.logout()
            .subscribe(function () { return _this.setLoggedOut(); });
    };
    CustomerUserProfileStore.prototype.updateLocale = function (locale) {
        throw new Error('Customer Portal does not support changing of locale');
    };
    CustomerUserProfileStore.ɵfac = function CustomerUserProfileStore_Factory(t) { return new (t || CustomerUserProfileStore)(i0.ɵɵinject(i1.CustomerUserProfileRestService)); };
    CustomerUserProfileStore.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerUserProfileStore, factory: CustomerUserProfileStore.ɵfac });
    return CustomerUserProfileStore;
}(CommonUserProfileStore));
export { CustomerUserProfileStore };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerUserProfileStore, [{
        type: Injectable
    }], function () { return [{ type: i1.CustomerUserProfileRestService }]; }, null); })();
