/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  Host,
  HostBinding,
  HostListener, Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

import { coalesce, denull, isElementFocused, stopEvent } from '@gh/core-util';
import { fromEvent$, interval$, merge$ } from '@gh/rx';
import { debounce$, filter$, first$, map$, mapTo$, switchMap$ } from '@gh/rx/operators';

import { compact, flatten, isNil, noop } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';

import { KEY_DOWN, KEY_ENTER, KEY_ESCAPE, KEY_PAGE_DOWN, KEY_PAGE_UP, KEY_UP } from '../../../utils';
import { AutocompleteActionIconComponent } from './autocomplete-action-icon.component';
import { AutocompleteArrowIconComponent } from './autocomplete-arrow-icon.component';
import { AutocompleteMaskedTypeDirective } from './autocomplete-masked-type.directive';
import { Option } from './autocomplete-option.model';
import { AutocompleteComponent } from './autocomplete.component';
import { PLACEHOLDER_PADDING_HORIZONTAL } from './autocomplete.constants';
import { DOCUMENT } from '@angular/common';

enum AutocompleteActionType { Focus, Blur }

type AutocompleteAction = {
  type: AutocompleteActionType;
  [name: string]: any;
};

function findFormField(element: Node): Maybe<Element> {
  let parent = element.parentElement;
  while (parent && parent.tagName !== 'MAT-FORM-FIELD') {
    parent = parent.parentElement;
  }
  return denull(parent);
}

const PAGE_SIZE = 6; // tslint:disable-line:no-magic-numbers

@Directive({
  selector: '[ghAutocomplete]',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'autocomplete-input-element',
    'autocomplete': 'off',
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: AutocompleteDirective,
    multi: true,
  }],
  exportAs: 'ghAutocompleteInput',
})
export class AutocompleteDirective implements OnInit, DoCheck, OnDestroy, ControlValueAccessor {
  @Output() clear: EventEmitter<any> = new EventEmitter();
  @Output() panelOpened = new EventEmitter();
  @Output() panelClosed = new EventEmitter();

  @Input('ghAutocomplete') autocomplete: AutocompleteComponent;
  @Input() unbound: boolean = false;
  @Input() clearable: boolean = true;

  /**
   * If ignoreFirstFocus is set, autocomplete panel will not be shown when input is focused first time.
   * This is necessary in dialogs to avoid opening of autocomplete panel when input is focused as first dialog input.
   */
  @Input()
  set ignoreFirstFocus(ignore: any) {
    this._ignoreFirstFocus = coerceBooleanProperty(ignore);
  }

  clearButton: ComponentRef<AutocompleteActionIconComponent>;
  autocompleteToggleButton: ComponentRef<AutocompleteArrowIconComponent>;
  mdInputIconElement?: Element;

  private _ignoreFirstFocus: boolean;
  private action$$ = new Subject<AutocompleteAction>();

  private anchorRef: ElementRef;
  private _inputSubscription: Subscription;
  private _overlayRef: OverlayRef;
  private _portal: TemplatePortal<any>;
  private _positionStrategy: FlexibleConnectedPositionStrategy;
  private positionChangeSubscription: Subscription;
  private onTouchedCallback: () => void = noop;
  private onChange = noop;
  private _lastPanelVisible = false;
  private disabled: boolean = false;

  constructor(public _element: ElementRef,
              private _overlay: Overlay,
              private _renderer: Renderer2,
              private _viewContainerRef: ViewContainerRef,
              private _zone: NgZone,
              private _resolver: ComponentFactoryResolver,
              @Optional() @Inject(DOCUMENT) private _document: any,
              @Optional() @Host() private _inputContainer: MatFormField,
              @Optional() private maskedType?: AutocompleteMaskedTypeDirective) {
  }

  @HostBinding('class.autocomplete-panel-visible')
  get panelVisible(): boolean {
    return this.autocomplete.displayMenu && (this._overlayRef && this._overlayRef.hasAttached());
  }

  ngOnInit(): void {
    // this.autocomplete.setAutocompleteDirective(this);
    this.autocomplete.setUnbound(this.unbound);

    // z-index mat-form-field management. Interact with gh-autocomplete component
    const formFieldEl = findFormField(this._element.nativeElement);

    this.anchorRef = formFieldEl && new ElementRef(formFieldEl) || this._element;

    // hack for icon positioning
    this.mdInputIconElement = formFieldEl ? denull(formFieldEl.querySelector('mat-icon')) : void 0;
    // arrow and close icon dynamic component
    const factoryArrowIcon = this._resolver.resolveComponentFactory(AutocompleteArrowIconComponent);
    this.autocompleteToggleButton = this._viewContainerRef.createComponent(factoryArrowIcon);
    this.autocompleteToggleButton.instance.setDisabled(this.disabled);
    if (this.clearable) {
      const factoryActionIcon = this._resolver.resolveComponentFactory(AutocompleteActionIconComponent);
      this.clearButton = this._viewContainerRef.createComponent(factoryActionIcon);
      this.clearButton.instance.setDisabled(this.disabled);
      this.clearButton.instance.trigger.subscribe(() => {
        this._clear(true);
      });
    }

    this.autocompleteToggleButton.instance.trigger.subscribe(($event: any) => {
      if (this.autocomplete.opened) {
        this.action$$.next({ type: AutocompleteActionType.Blur, event: $event });
      } else {
        this.action$$.next({ type: AutocompleteActionType.Focus, event: $event });
      }
    });

    const action$ = this.action$$.pipe(
      switchMap$((action) => {
        if (action.type === AutocompleteActionType.Blur) {
          return interval$(200).pipe(first$(), mapTo$(action)); // tslint:disable-line:no-magic-numbers
        } else {
          return [action];
        }
      }));

    action$.pipe(
      filter$(({ type }) => type === AutocompleteActionType.Focus))
      .subscribe((action) => {
        this._element.nativeElement.focus();
        if (this._ignoreFirstFocus) {
          this._ignoreFirstFocus = false;
        } else {
          this.openPanel();
        }
      });

    this._inputSubscription = fromEvent$(this._element.nativeElement, 'input').pipe(
      filter$(({ target }) => isElementFocused(target)),
      // we use debounce (rather than debounceTime) here,
      // because debounceTime may be not initialized due to order of component initialization
      debounce$(() => interval$(this.autocomplete.debounceTime).pipe(first$())),
      map$((event: any) => event.target.value))
      .subscribe((value) => {
        if (!this.autocomplete.opened) {
          this.openPanel();
        }
        this.autocomplete.typingResults(value);
      });

    this.autocomplete.selectedOptionChange.pipe(
      filter$(Boolean))
      .subscribe((option: Option) => {
        this.setSelectedOption(option);
        if (!this.autocomplete.hasChips) {
          this.onChange(this.unbound ? option.text : option.value);
        }
      });

    this.autocomplete.suggestionListHasChanged
      .subscribe(() => this._positionStrategy.apply());
  }

  ngOnDestroy(): void {
    this.closePanel();
    this._inputSubscription.unsubscribe();
    if (this.positionChangeSubscription) {
      this.positionChangeSubscription.unsubscribe();
    }
  }

  ngDoCheck(): void {
    if (this._lastPanelVisible !== this.panelVisible) {
      this.panelVisiblityChanged();
    }
  }

  writeValue(value: any): void {
    if (isNil(value) && this.autocomplete.value) {
      this._clear(false);
    } else {
      this.autocomplete.setValue(this.maskedType ? this.maskedType.update(value) : value).pipe(
        filter$(Boolean))
        .subscribe((option: any) => {
          this.setSelectedOption(option);
        });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.autocompleteToggleButton.instance.setDisabled(isDisabled);
    if (this.clearButton) {
      this.clearButton.instance.setDisabled(isDisabled);
    }
  }

  @HostListener('focus', ['$event'])
  onInputFocus($event: any): void {
    this.action$$.next({ type: AutocompleteActionType.Focus, event: $event });
  }

  @HostListener('input', ['$event'])
  onInput($event: any): void {
    if (this.maskedType) {
      this.maskedType.update($event.target.value);
    }
    if (this.unbound) {
      this.onChange($event.target.value);
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): void {
    const { keyCode } = $event;
    const { autocomplete } = this;
    const { displayMenu } = autocomplete;

    switch (keyCode) {
      case KEY_UP:
        stopEvent($event);
        if (displayMenu) {
          autocomplete.moveFocus(-1);
        } else {
          this.onInputFocus($event);
        }
        break;
      case KEY_DOWN:
        stopEvent($event);
        if (displayMenu) {
          autocomplete.moveFocus(1);
        } else {
          this.onInputFocus($event);
        }
        break;
      case KEY_PAGE_UP:
        stopEvent($event);
        if (displayMenu) {
          autocomplete.moveFocus(-PAGE_SIZE);
        }
        break;
      case KEY_PAGE_DOWN:
        stopEvent($event);
        if (displayMenu) {
          autocomplete.moveFocus(PAGE_SIZE);
        }
        break;
      case KEY_ENTER:
        if (displayMenu) {
          stopEvent($event);
          autocomplete.selectByIndex(autocomplete.focusedIndex);
        }
        break;
      case KEY_ESCAPE:
        if (displayMenu) {
          stopEvent($event);
          autocomplete.close();
          this.closePanel();

          if (!this.unbound) {
            this.rollbackInputText();
          }
        }
        break;
      default:
        break;
    }
  }

  openPanel(): void {
    this.createPanel();
    this.autocomplete.open();
  }

  /** Opens the autocomplete suggestion panel. */
  createPanel(): void {
    if (!this._overlayRef) {
      this._createOverlay();
    }
    this.updateOverlayDimensions();
    if (!this._overlayRef.hasAttached()) {
      this._overlayRef.attach(this._portal);
    }
    this._overlayRef.backdropClick().subscribe(() => {
      this.autocomplete.close();
    });
    if (this.autocomplete.activeWidth) {
      this._renderer.setStyle(this._element.nativeElement, 'width', this.autocomplete.controlWidth());
    }

    this.updateAnchorBackground();
  }

  /** Closes the autocomplete suggestion panel. */
  closePanel(): void {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }
    if (this.autocomplete.activeWidth) {
      this._renderer.setStyle(this._element.nativeElement, 'width', '100%');
    }

    this.updateAnchorBackground();
  }

  private updateOverlayDimensions(): void {
    const rect = this.anchorRef.nativeElement.getBoundingClientRect();

    this.autocomplete.setAnchorDimensions(rect.width, rect.height);
  }

  private _createOverlay(): void {
    this._positionStrategy = this._createOverlayPosition();
    this._portal = new TemplatePortal(this.autocomplete.template, this._viewContainerRef);
    this._overlayRef = this._overlay.create(this._getOverlayConfig());
    this._overlayRef.hostElement.classList.add('autocomplete-overlay-box');

    this.positionChangeSubscription = this._positionStrategy.positionChanges.subscribe((event) => {
      this.autocomplete.setPlaceholderPosition(<any>event.connectionPair.overlayY);
    });

    this._getOutsideClickStream().subscribe((event) => {
        if (!this.unbound) {
          this.rollbackInputText();
        }
        this.autocomplete.close();
        this.closePanel();
    });

    this._overlayRef.attachments().subscribe(() => this.panelOpened.emit());
    this._overlayRef.detachments().subscribe(() => this.panelClosed.emit());
  }

  private _getOverlayConfig(): OverlayConfig {
    const overlayConfig = new OverlayConfig();
    overlayConfig.positionStrategy = this._positionStrategy;
    overlayConfig.width = 1;
    overlayConfig.panelClass = compact(flatten(<string[]>[
      'autocomplete-overlay-panel',
      this.autocomplete.panelClass && this.autocomplete.panelClass.split(/\s+/),
    ]));
    return overlayConfig;
  }

  private _createOverlayPosition(): FlexibleConnectedPositionStrategy {
    return this._overlay.position()
      .flexibleConnectedTo(this.anchorRef)
      .withPositions([
        { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
        { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
      ])
      .withDefaultOffsetX(-PLACEHOLDER_PADDING_HORIZONTAL);
  }

  private _clear(fireEvent: boolean): void {
    if (fireEvent) {
      this.onChange(this.unbound ? '' : void 0);
    }

    this._element.nativeElement.value = '';
    this.clearButton.instance.setDisplay(false);
    this.autocompleteToggleButton.instance.setDisplay(true);

    this.autocomplete.resetTo(void 0);
  }

  private panelVisiblityChanged(): void {
    this._lastPanelVisible = this.panelVisible;
    this.updateAnchorBackground();
  }

  private updateAnchorBackground(): void {
    this.anchorRef.nativeElement.style.backgroundColor = this.panelVisible ? '#fff' : 'transparent';
  }

  private setSelectedOption(option: Option): void {
    if (this.clearButton) {
      this.clearButton.instance.setDisplay(!!option.text);
      this.autocompleteToggleButton.instance.setDisplay(!option.text);
    }
    this._element.nativeElement.value = !this.autocomplete.hasChips
      ? option.text || ''
      : '';
  }

  private rollbackInputText(): void {
    const { selectedOption } = this.autocomplete;
    this._element.nativeElement.value = coalesce(selectedOption ? selectedOption.text : void 0, '');
  }

  private _getOutsideClickStream(): Observable<any> {
    return merge$(
      fromEvent$(this._document, 'click') as Observable<MouseEvent>,
      fromEvent$(this._document, 'touchend') as Observable<TouchEvent>)
      .pipe(filter$((event) => {
        const clickTarget =
          (event.composedPath ? event.composedPath()[0] :
            event.target) as HTMLElement;
        return this._overlay && clickTarget !== this._element.nativeElement &&
          (!!this._overlayRef && !this._overlayRef.overlayElement.contains(clickTarget));
      }));
  }
}
