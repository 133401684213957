/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl } from '@angular/forms';
import { REGION_CODE } from '@gh/config';
import { Permission } from '@gh/core-auth';
import { FormMode } from '@gh/core-ui';
import { denull } from '@gh/core-util';
import { constant, flatten, isArray, isBoolean, isFunction, isNil, map, overEvery, overSome, toArray } from 'lodash';
import { REGION_AU, REGION_NZ, REGION_US } from '../../../regions';
import { FormState } from '../form-state';
import { ApplicationState, ApplicationStatePredicate, ApplicationStatePredicateSet } from './application-state';

export function getForm(snapshot: ApplicationState): FormState<any> {
  if (isNil(snapshot.currentForm)) {
    throw new Error('Current application state does not have form assigned');
  }

  return snapshot.currentForm;
}

export function getFormControl(snapshot: ApplicationState, path: string): Maybe<AbstractControl> {
  const form = getForm(snapshot);

  if (isNil(form.group)) {
    return void 0;
  }

  return denull(form.group.get(path));
}

export function whenOneOf(...args: ApplicationStatePredicateSet[]): ApplicationStatePredicate {
  return overSome(toApplicationStatePredicates(...flatten(args)));
}

export function whenAllOf(...args: ApplicationStatePredicateSet[]): ApplicationStatePredicate {
  return overEvery(toApplicationStatePredicates(...flatten(args)));
}

export function whenModeEq(mode: FormMode): ApplicationStatePredicate {
  return (snapshot) => getForm(snapshot).mode === mode;
}

export function whenControlHasExactValue(path: string, value: any): ApplicationStatePredicate {
  return (snapshot) => {
    const control = getFormControl(snapshot, path);

    if (isNil(control)) {
      return false;
    }

    return control.value === value;
  };
}

export function whenControlHasAnyValue(path: string): ApplicationStatePredicate {
  return (snapshot) => {
    const control = getFormControl(snapshot, path);

    if (isNil(control)) {
      return false;
    }

    return !isNil(control.value);
  };
}

export function whenRegionIn(...regions: string[]): ApplicationStatePredicate {
  return (snapshot) => toArray(regions).includes(REGION_CODE);
}

export function whenHasPermission(permission: Permission): ApplicationStatePredicate {
  return (snapshot) => snapshot.hasPermission(permission);
}

export function whenHasPrivilegedToken(entryPoint: string): ApplicationStatePredicate {
  return (snapshot) => {
    const control = getFormControl(snapshot, 'privilegedTokens');

    return control && !isNil(control.value[entryPoint]) || false;
  };
}

export function whenHasOneOfPrivilegedTokens(entryPoint: string[]): ApplicationStatePredicate {
  const predicates = entryPoint.map(whenHasPrivilegedToken);

  return (snapshot) => predicates.reduce((has, predicate) => has ? has : predicate(snapshot), false);
}

export const alwaysTrue = () => true;
export const alwaysFalse = () => false;
export const whenViewMode = constant(whenModeEq(FormMode.ViewMode));
export const whenEditMode = constant(whenModeEq(FormMode.EditMode));
export const whenCreateMode = constant(whenModeEq(FormMode.CreateMode));
export const whenCreateOrEditMode = constant(whenOneOf(whenCreateMode(), whenEditMode()));
export const whenEditOrViewMode = constant(whenOneOf(whenEditMode(), whenViewMode()));

export const whenHasAnyPrivilegedToken = constant(whenControlHasAnyValue('privilegedTokens'));

export const whenUsRegion = constant(whenRegionIn(REGION_US));
export const whenAuRegion = constant(whenRegionIn(REGION_AU));
export const whenNzRegion = constant(whenRegionIn(REGION_NZ));
export const whenUsOrAuRegion = constant(whenRegionIn(REGION_US, REGION_AU));
export const whenAuOrNzRegion = constant(whenRegionIn(REGION_AU, REGION_NZ));

function toApplicationStatePredicates(...args: ApplicationStatePredicateSet[]): ApplicationStatePredicate[] {
  return map(args, (predicate) => {
    if (isFunction(predicate)) {
      return predicate;
    } else if (isArray(predicate)) {
      return whenOneOf(...predicate);
    } else if (isBoolean(predicate)) {
      return constant(predicate);
    } else {
      // tslint:disable:max-line-length
      throw new Error(`Predicate is of illegal type: ${predicate} (${!isNil(predicate) ? predicate['constructor'] : 'unknown'})`);
    }
  });
}
