/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ChangeDetectorRef, Component } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var TemplateSiteComponent = /** @class */ (function () {
    function TemplateSiteComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
    }
    TemplateSiteComponent.prototype.setTemplateRef = function (templateRef) {
        this.templateRef = templateRef;
    };
    TemplateSiteComponent.prototype.setContext = function (context) {
        this.context = context;
        this.changeDetectorRef.markForCheck();
    };
    TemplateSiteComponent.ɵfac = function TemplateSiteComponent_Factory(t) { return new (t || TemplateSiteComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
    TemplateSiteComponent.ɵcmp = i0.ɵɵdefineComponent({ type: TemplateSiteComponent, selectors: [["gh-template-site"]], decls: 1, vars: 2, consts: [[3, "ngTemplateOutlet", "ngTemplateOutletContext"]], template: function TemplateSiteComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelement(0, "div", 0);
        } if (rf & 2) {
            i0.ɵɵproperty("ngTemplateOutlet", ctx.templateRef)("ngTemplateOutletContext", ctx.context);
        } }, directives: [i1.NgTemplateOutlet], encapsulation: 2 });
    return TemplateSiteComponent;
}());
export { TemplateSiteComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TemplateSiteComponent, [{
        type: Component,
        args: [{
                selector: 'gh-template-site',
                template: "\n    <div [ngTemplateOutlet]=\"templateRef\" [ngTemplateOutletContext]=\"context\"></div>",
            }]
    }], function () { return [{ type: i0.ChangeDetectorRef }]; }, null); })();
