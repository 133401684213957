/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreAuthModule, PermissionNameSetProvider, PermissionProvider } from '@gh/core-auth';
import { CoreDataModule } from '@gh/core-data';
import { CoreMlsModule, SubstitutionCompiler } from '@gh/core-mls';
import { CoreTypeModule, TYPE_SET } from '@gh/core-type';
import { S3UploadEngine, UploadEngine } from '@gh/core-ui';
import { AllowForDirective, ForbidForDirective, HasPermissionPipe } from './auth';
import { DictionaryContext, DictionaryService } from './dictionary';
import { HelpIdDirective, HelpProvider } from './help';
import { DurationPipe } from './pipes';
import { ApplicationComponentState, ApplicationDialogHooks, AppRouter, AppRouterOutletDirective, AppRouterState, AuthGuard, DeepLinkActivateService, InitDialogViewResolver, InitViewResolver, LeaveGuard, RouterBackDirective, } from './routing';
import { defaultErrorHandler } from './sentry';
import { AppSubstitutionCompiler, ConfigurationHttpRequestInterceptor, LocalizationBusinessService, MaintenanceHttpResponseInterceptor, TitleManager, } from './services';
import { ContainerAsyncDisabledDirective, ContainerStateDirective, FormStateBuilder, IfControlVisibleDirective, IsControlRequiredPipe, IsControlVisiblePipe, } from './state';
import { DateTimeTypes, MaskedTypes, NumberTypes, QuantityTypes, TextTypes } from './types';
import * as i0 from "@angular/core";
import * as i1 from "../core-data/core-data.module";
import * as i2 from "../core-mls/core-mls.module";
import * as i3 from "../core-auth/core-auth.module";
import * as i4 from "../core-type/core-type.module";
export function typeProviders() {
    return [
        { provide: TYPE_SET, useValue: DateTimeTypes, multi: true },
        { provide: TYPE_SET, useValue: MaskedTypes, multi: true },
        { provide: TYPE_SET, useValue: NumberTypes, multi: true },
        { provide: TYPE_SET, useValue: QuantityTypes, multi: true },
        { provide: TYPE_SET, useValue: TextTypes, multi: true },
    ];
}
var CommonSharedModule = /** @class */ (function () {
    function CommonSharedModule() {
    }
    CommonSharedModule.forRoot = function () {
        return {
            ngModule: CommonSharedModule,
            providers: [
                defaultErrorHandler(),
                typeProviders(),
                { provide: PermissionProvider, useExisting: PermissionNameSetProvider },
                ConfigurationHttpRequestInterceptor,
                MaintenanceHttpResponseInterceptor,
                DictionaryService,
                DictionaryContext,
                AuthGuard,
                LeaveGuard,
                AppRouter,
                AppRouterState,
                InitViewResolver,
                InitDialogViewResolver,
                ApplicationComponentState,
                ApplicationDialogHooks,
                FormStateBuilder,
                { provide: UploadEngine, useClass: S3UploadEngine },
                DeepLinkActivateService,
                { provide: SubstitutionCompiler, useClass: AppSubstitutionCompiler },
                HelpProvider,
                LocalizationBusinessService,
                TitleManager,
            ],
        };
    };
    CommonSharedModule.forChild = function () {
        return {
            ngModule: CommonSharedModule,
            providers: [
                InitViewResolver,
                InitDialogViewResolver,
            ],
        };
    };
    CommonSharedModule.ɵmod = i0.ɵɵdefineNgModule({ type: CommonSharedModule });
    CommonSharedModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CommonSharedModule_Factory(t) { return new (t || CommonSharedModule)(); }, imports: [[
                CommonModule,
                RouterModule,
                CoreDataModule.forChild(),
                CoreMlsModule.forChild(),
                CoreAuthModule.forChild(),
                CoreTypeModule.forChild(),
            ],
            CommonModule,
            RouterModule,
            CoreAuthModule,
            CoreMlsModule,
            CoreTypeModule] });
    return CommonSharedModule;
}());
export { CommonSharedModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CommonSharedModule, { declarations: [AllowForDirective,
        ForbidForDirective,
        HasPermissionPipe,
        ContainerStateDirective,
        ContainerAsyncDisabledDirective,
        IfControlVisibleDirective,
        IsControlRequiredPipe,
        IsControlVisiblePipe,
        RouterBackDirective,
        AppRouterOutletDirective,
        DurationPipe,
        HelpIdDirective], imports: [CommonModule,
        RouterModule, i1.CoreDataModule, i2.CoreMlsModule, i3.CoreAuthModule, i4.CoreTypeModule], exports: [CommonModule,
        RouterModule,
        CoreAuthModule,
        CoreMlsModule,
        CoreTypeModule,
        AllowForDirective,
        ForbidForDirective,
        HasPermissionPipe,
        ContainerStateDirective,
        ContainerAsyncDisabledDirective,
        IfControlVisibleDirective,
        IsControlRequiredPipe,
        IsControlVisiblePipe,
        RouterBackDirective,
        AppRouterOutletDirective,
        DurationPipe,
        HelpIdDirective] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CommonSharedModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                    RouterModule,
                    CoreDataModule.forChild(),
                    CoreMlsModule.forChild(),
                    CoreAuthModule.forChild(),
                    CoreTypeModule.forChild(),
                ],
                declarations: [
                    AllowForDirective,
                    ForbidForDirective,
                    HasPermissionPipe,
                    ContainerStateDirective,
                    ContainerAsyncDisabledDirective,
                    IfControlVisibleDirective,
                    IsControlRequiredPipe,
                    IsControlVisiblePipe,
                    RouterBackDirective,
                    AppRouterOutletDirective,
                    DurationPipe,
                    HelpIdDirective,
                ],
                exports: [
                    CommonModule,
                    RouterModule,
                    CoreAuthModule,
                    CoreMlsModule,
                    CoreTypeModule,
                    AllowForDirective,
                    ForbidForDirective,
                    HasPermissionPipe,
                    ContainerStateDirective,
                    ContainerAsyncDisabledDirective,
                    IfControlVisibleDirective,
                    IsControlRequiredPipe,
                    IsControlVisiblePipe,
                    RouterBackDirective,
                    AppRouterOutletDirective,
                    DurationPipe,
                    HelpIdDirective,
                ],
            }]
    }], null, null); })();
