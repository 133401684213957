/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-submodule-imports no-import-side-effect

import { NgModule } from '@angular/core';

import { CoreMlsModule, JSONTranslationMapLoader, provideTranslationMapLoader } from '@gh/core-mls';

import '@gh/customer-shared/mls/customer.mls.json';

@NgModule({
  imports: [
    CoreMlsModule.forRoot(),
  ],
  providers: [
    provideTranslationMapLoader(JSONTranslationMapLoader),
  ],
  exports: [
    CoreMlsModule,
  ],
})
export class CustomerMlsConfigModule {

}
