/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isClientErrorStatus, isServerErrorStatus } from '@gh/core-data';
import { throwError$ } from '@gh/rx';
import { catchError$ } from '@gh/rx/operators';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { MessageBasket } from './message-basket';
import { createMessageBasketFromResponse } from './message.utils';

@Injectable()
export class MessageHttpErrorInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError$((response?: HttpErrorResponse) => {
      if (isNil(response) || isServerErrorStatus(response.status)) {
        return throwError$(MessageBasket.empty());
      } else if (isClientErrorStatus(response.status)) {
        return throwError$(createMessageBasketFromResponse(response));
      }

      return throwError$(response);
    }));
  }

}
