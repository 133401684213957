/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { findPermission, Permission } from '@gh/core-auth';
import * as invariant from 'invariant';
import { isString } from 'lodash';
import { UserContext } from '../user';
import * as i0 from "@angular/core";
import * as i1 from "../user";
var ForbidForDirective = /** @class */ (function () {
    function ForbidForDirective(viewContainerRef, templateRef, userContext) {
        this.viewContainerRef = viewContainerRef;
        this.templateRef = templateRef;
        this.userContext = userContext;
        this.hasView = false;
    }
    Object.defineProperty(ForbidForDirective.prototype, "ghForbidFor", {
        set: function (permission) {
            if (isString(permission)) {
                permission = findPermission(permission);
            }
            invariant(permission instanceof Permission, "Permission '" + permission + "' is not an instance of Permission");
            if (this.userContext.hasPermission(permission) && this.hasView) {
                this.hasView = false;
                this.viewContainerRef.clear();
            }
            else if (!this.userContext.hasPermission(permission) && !this.hasView) {
                this.hasView = true;
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
        },
        enumerable: true,
        configurable: true
    });
    ForbidForDirective.ɵfac = function ForbidForDirective_Factory(t) { return new (t || ForbidForDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i1.UserContext)); };
    ForbidForDirective.ɵdir = i0.ɵɵdefineDirective({ type: ForbidForDirective, selectors: [["", "ghForbidFor", ""]], inputs: { ghForbidFor: "ghForbidFor" } });
    return ForbidForDirective;
}());
export { ForbidForDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ForbidForDirective, [{
        type: Directive,
        args: [{ selector: '[ghForbidFor]' }]
    }], function () { return [{ type: i0.ViewContainerRef }, { type: i0.TemplateRef }, { type: i1.UserContext }]; }, { ghForbidFor: [{
            type: Input
        }] }); })();
