/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { assign, omit } from 'lodash';
import * as querystring from 'query-string';

export type ParamMap = Hash<string | string[] | null>;

export type UrlInfo = {
  pathname: string;
  query: ParamMap;
  hash: string;
};

const URL_RE = /^([^?#]+)(\?([^#]+)?)?(#(\.+))?$/;

export function mergePath(...segments: string[]): string {
  return segments.slice(1).reduce(
    (path, segment) => path.replace(/(\/)$/, '') + segment.replace(/^([^/])/, '/$1'),
    segments[0],
  );
}

/**
 * Adds specified parameters to the given URL.
 *
 * @param url
 * @param params
 * @returns {string}
 */
export function addQueryParameters(url: string, params: string): string {
  if (params) {
    return `${url}${url.includes('?') ? '&' : '?'}${params}`;
  } else {
    return url;
  }
}

/**
 * Merges specified parameters and the given URL. This method can overwrite parameters set on the URL.
 *
 * @param url
 * @param params
 * @returns {string}
 */
export function setQueryParameters(url: string, params: ParamMap): string {
  const parsed = parseUrl(url);

  assign(parsed.query, params);

  return stringifyUrl(parsed);
}

/**
 * Removes specified parameters from the given URL.
 *
 * @param url
 * @param params
 * @returns {string}
 */
export function removeQueryParameters(url: string, params: string[]): string {
  const parsed = parseUrl(url);

  parsed.query = <ParamMap>omit(parsed.query, params);

  return stringifyUrl(parsed);
}

/**
 * Merges specified parameters and the given URL. This method can overwrite parameters set on the URL.
 *
 * @param baseUrl
 * @param params
 * @returns {string}
 */
export function parametrizeUrl(baseUrl: string, params: any): string {
  return setQueryParameters(baseUrl, params);
}

/**
 * Parses given URL and returns destructured view of this URL.
 *
 * Note!!! Currently this method works only for relative URLs
 *
 * @param url
 */
export function parseUrl(url: string): UrlInfo {
  const match = URL_RE.exec(url);

  if (match) {
    return {
      pathname: match[1],
      query: parseQueryString(match[3]), // tslint:disable-line:no-magic-numbers
      hash: match[5], // tslint:disable-line:no-magic-numbers
    };
  } else {
    return {
      pathname: url,
      query: {},
      hash: '',
    };
  }
}

/**
 * Stringifies parsed URL
 *
 * @param info
 */
export function stringifyUrl(info: UrlInfo): string {
  return [
    info.pathname,
    info.query ? '?' + querystring.stringify(info.query) : '',
    info.hash ? '#' + info.hash : '',
  ].join('');
}

/**
 * Parses given query string and returns dictionary for set parameters
 *
 * @param query
 */
export function parseQueryString(query: string): ParamMap {
  return querystring.parse(query);
}

/**
 * Returns path of the given URL. Currently this method works only for relative URL
 *
 * @param url
 * @returns {string}
 */
export function getUrlPath(url: string): string {
  return url.split('?')[0];
}

/**
 * Replaces url by the given segment
 * @param url
 * @param order
 * @param segment
 */
export function replaceUrlSegment(url: string, order: number, segment: string): string {
  const parsedUrl = parseUrl(url);
  const { pathname } = parsedUrl;
  const isStartFromSlash = /^\//.test(pathname);
  const normalizedPathName = isStartFromSlash ? pathname.substring(1) : pathname;
  const segments = normalizedPathName.split('/');
  if (order > segments.length) {
    throw new Error(`Url "${url}" does not have enough segments. Target segment order: ${order}`);
  }
  return stringifyUrl({
    ...parsedUrl,
    pathname: (isStartFromSlash ? [''] : [])
      .concat(segments.slice(0, order))
      .concat([segment])
      .concat(segments.slice(order + 1))
      .join('/'),
  });
}

/**
 * Returns specified URL segment
 * @param url
 * @param order
 */
export function getUrlSegment(url: string, order: number): string {
  const parsedUrl = parseUrl(url);
  const { pathname } = parsedUrl;
  const isStartFromSlash = /^\//.test(pathname);
  const normalizedPathName = isStartFromSlash ? pathname.substring(1) : pathname;
  const segments = normalizedPathName.split('/');
  if (order > segments.length) {
    throw new Error(`Url "${url}" does not have enough segments. Target segment order: ${order}`);
  }
  return segments[order];
}
