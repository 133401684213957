var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { AbstractRestService, arrayOf, RestSettings } from '@gh/core-data';
import { DictionaryItemMapper } from './dictionary-item.mapper';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-data";
var DictionaryRestService = /** @class */ (function (_super) {
    __extends(DictionaryRestService, _super);
    function DictionaryRestService(settings) {
        var _this = _super.call(this, settings) || this;
        _this.settings = settings;
        return _this;
    }
    DictionaryRestService.prototype.get = function (code) {
        return this.httpGet("/dictionaries/" + code, arrayOf(DictionaryItemMapper));
    };
    DictionaryRestService.ɵfac = function DictionaryRestService_Factory(t) { return new (t || DictionaryRestService)(i0.ɵɵinject(i1.RestSettings)); };
    DictionaryRestService.ɵprov = i0.ɵɵdefineInjectable({ token: DictionaryRestService, factory: DictionaryRestService.ɵfac });
    return DictionaryRestService;
}(AbstractRestService));
export { DictionaryRestService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DictionaryRestService, [{
        type: Injectable
    }], function () { return [{ type: i1.RestSettings }]; }, null); })();
