/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/flex-layout/extended";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "../../../core-mls/translation/translate.pipe";
function ExtDialogHeaderComponent_button_6_Template(rf, ctx) { if (rf & 1) {
    var _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function ExtDialogHeaderComponent_button_6_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r3); var ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.onClose(); });
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵelement(2, "mat-icon", 2);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵpropertyInterpolate("matTooltip", i0.ɵɵpipeBind1(1, 1, "lbl_close"));
} }
function ExtDialogHeaderComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r1.title);
} }
var ExtDialogHeaderComponent = /** @class */ (function () {
    function ExtDialogHeaderComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.closableOnDesktop = false;
    }
    ExtDialogHeaderComponent.prototype.onClose = function () {
        this.dialogRef.close();
    };
    ExtDialogHeaderComponent.ɵfac = function ExtDialogHeaderComponent_Factory(t) { return new (t || ExtDialogHeaderComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef)); };
    ExtDialogHeaderComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtDialogHeaderComponent, selectors: [["gh-ext-dialog-header"]], inputs: { title: "title", closableOnDesktop: "closableOnDesktop" }, decls: 8, vars: 5, consts: [["fxHide", "", "fxShow.lt-md", "", 1, "m-header"], ["mat-icon-button", "", "color", "primary", 1, "m-close-btn", 3, "matTooltip", "click"], ["svgIcon", "gh:close"], ["src", "/ef05a4176a1a27c00c8e389eff1cc5c3.gif", 1, "m-logo"], [1, "ext-divider"], ["fxHide.lt-md", "", "class", "m-close-btn", "mat-icon-button", "", "color", "primary", 3, "matTooltip", "click", 4, "ngIf"], ["class", "m-title", 4, "ngIf"], ["fxHide.lt-md", "", "mat-icon-button", "", "color", "primary", 1, "m-close-btn", 3, "matTooltip", "click"], [1, "m-title"]], template: function ExtDialogHeaderComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵelementStart(1, "button", 1);
            i0.ɵɵlistener("click", function ExtDialogHeaderComponent_Template_button_click_1_listener() { return ctx.onClose(); });
            i0.ɵɵpipe(2, "translate");
            i0.ɵɵelement(3, "mat-icon", 2);
            i0.ɵɵelementEnd();
            i0.ɵɵelement(4, "img", 3);
            i0.ɵɵelement(5, "div", 4);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(6, ExtDialogHeaderComponent_button_6_Template, 3, 3, "button", 5);
            i0.ɵɵtemplate(7, ExtDialogHeaderComponent_div_7_Template, 2, 1, "div", 6);
        } if (rf & 2) {
            i0.ɵɵadvance(1);
            i0.ɵɵpropertyInterpolate("matTooltip", i0.ɵɵpipeBind1(2, 3, "lbl_close"));
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("ngIf", ctx.closableOnDesktop);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.title);
        } }, directives: [i2.DefaultShowHideDirective, i3.MatButton, i4.MatTooltip, i5.MatIcon, i6.NgIf], pipes: [i7.TranslatePipe], styles: ["[_nghost-%COMP%] {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  padding: 0 10px; }\n\n.m-header[_ngcontent-%COMP%] {\n  position: relative; }\n\n.m-logo[_ngcontent-%COMP%] {\n  position: relative;\n  display: block;\n  left: 50%;\n  transform: translateX(-50%);\n  padding: 12px 0; }\n\n.m-title[_ngcontent-%COMP%] {\n  text-align: center; }\n\n@media (max-width: 959px) {\n  .m-close-btn[_ngcontent-%COMP%] {\n    position: absolute;\n    left: -5px;\n    top: 5px; }\n  .m-title[_ngcontent-%COMP%] {\n    margin: 10px 0;\n    font-size: 30px; } }\n\n@media (min-width: 960px) {\n  [_nghost-%COMP%] {\n    margin: 60px 0 30px; }\n  .m-close-btn[_ngcontent-%COMP%] {\n    position: absolute;\n    right: 10px;\n    top: -50px; }\n  .m-title[_ngcontent-%COMP%] {\n    font-size: 40px; } }"] });
    return ExtDialogHeaderComponent;
}());
export { ExtDialogHeaderComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtDialogHeaderComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-dialog-header',
                templateUrl: 'ext-dialog-header.component.html',
                styleUrls: ['ext-dialog-header.component.scss'],
            }]
    }], function () { return [{ type: i1.MatDialogRef }]; }, { title: [{
            type: Input
        }], closableOnDesktop: [{
            type: Input
        }] }); })();
