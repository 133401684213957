/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DataMapper } from './data-mapper';
import { HeadersData, HttpDataMapper } from './http-data-mapper';

export class DummyHttpDataMapper<ObjectView> extends HttpDataMapper<ObjectView> {
  constructor(mapper: DataMapper<ObjectView, any>) {
    super(mapper);
  }

  serializeHeaders(headers: HeadersData, obj: ObjectView, json: any): any {
    return json;
  }

  deserializeHeaders(headers: HeadersData, obj: ObjectView): ObjectView {
    return obj;
  }
}
