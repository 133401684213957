var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { TranslationService } from '@gh/core-mls';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
var AppDatepickerIntl = /** @class */ (function (_super) {
    __extends(AppDatepickerIntl, _super);
    function AppDatepickerIntl(translationService) {
        var _this = _super.call(this) || this;
        _this.translationService = translationService;
        _this.calendarLabel = _this.translationService.get('lbl_calendar');
        _this.openCalendarLabel = _this.translationService.get('lbl_open_calendar');
        _this.prevMonthLabel = _this.translationService.get('lbl_prev_month');
        _this.nextMonthLabel = _this.translationService.get('lbl_next_month');
        _this.prevYearLabel = _this.translationService.get('lbl_prev_year');
        _this.nextYearLabel = _this.translationService.get('lbl_next_year');
        _this.switchToMonthViewLabel = _this.translationService.get('lbl_switch_to_month_view');
        _this.switchToYearViewLabel = _this.translationService.get('lbl_switch_to_year_view');
        return _this;
    }
    AppDatepickerIntl.ɵfac = function AppDatepickerIntl_Factory(t) { return new (t || AppDatepickerIntl)(i0.ɵɵinject(i1.TranslationService)); };
    AppDatepickerIntl.ɵprov = i0.ɵɵdefineInjectable({ token: AppDatepickerIntl, factory: AppDatepickerIntl.ɵfac });
    return AppDatepickerIntl;
}(MatDatepickerIntl));
export { AppDatepickerIntl };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppDatepickerIntl, [{
        type: Injectable
    }], function () { return [{ type: i1.TranslationService }]; }, null); })();
