/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { get } from 'lodash';
import * as i0 from "@angular/core";
var CONTEXT_PARAM_RE = /%([A-Za-z_][A-Za-z0-9_]+)%/g;
var InContextPipe = /** @class */ (function () {
    function InContextPipe() {
    }
    InContextPipe.prototype.transform = function (value, context) {
        return value && value.replace(CONTEXT_PARAM_RE, function (match, name) { return get(context, name, match); });
    };
    InContextPipe.ɵfac = function InContextPipe_Factory(t) { return new (t || InContextPipe)(); };
    InContextPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "inContext", type: InContextPipe, pure: true });
    return InContextPipe;
}());
export { InContextPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(InContextPipe, [{
        type: Pipe,
        args: [{
                name: 'inContext',
            }]
    }], null, null); })();
