/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { isNil, isString } from 'lodash';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export var KEYCLOAK_CONFIGURATION = new InjectionToken('KeycloakConfiguration');
export var KEYCLOAK_INIT_OPTIONS = new InjectionToken('KeycloakInitOptions');
export var KEYCLOAK_MIN_VALIDITY = new InjectionToken('KeycloakMinValidity');
function toPromise(promise) {
    return new Promise(function (resolve, reject) {
        promise.success(resolve).error(reject);
    });
}
var KeycloakService = /** @class */ (function () {
    function KeycloakService(configuration, minValidity, initOptions) {
        this.configuration = configuration;
        this.minValidity = minValidity;
        this.initOptions = initOptions;
        this.authSuccessStream = new Subject();
        this.authLogoutStream = new Subject();
        this.authErrorStream = new Subject();
        this.authRefreshSuccessStream = new Subject();
        this.authRefreshErrorStream = new Subject();
    }
    Object.defineProperty(KeycloakService.prototype, "onAuthSuccess", {
        get: function () {
            return this.authSuccessStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KeycloakService.prototype, "onAuthLogout", {
        get: function () {
            return this.authLogoutStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KeycloakService.prototype, "onAuthError", {
        get: function () {
            return this.authErrorStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KeycloakService.prototype, "onAuthRefreshSuccess", {
        get: function () {
            return this.authRefreshSuccessStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KeycloakService.prototype, "onAuthRefreshError", {
        get: function () {
            return this.authRefreshErrorStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KeycloakService.prototype, "token", {
        get: function () {
            return this.keycloak.token;
        },
        enumerable: true,
        configurable: true
    });
    KeycloakService.prototype.init = function () {
        return this.ensureInit();
    };
    KeycloakService.prototype.login = function (options) {
        var _this = this;
        return this.ensureInit().then(function (authenticated) {
            if (authenticated) {
                return Promise.resolve(true);
            }
            else {
                return toPromise(_this.keycloak.login(options));
            }
        });
    };
    KeycloakService.prototype.logout = function (options) {
        return toPromise(this.keycloak.logout(options));
    };
    KeycloakService.prototype.getToken = function (minValidity) {
        var _this = this;
        if (this.keycloak) {
            return toPromise(this.keycloak.updateToken(minValidity || this.minValidity)).then(function () { return _this.keycloak.token; });
        }
        return Promise.reject(new Error('Keycloak integration layer is not initialized'));
    };
    KeycloakService.prototype.isTokenExpired = function () {
        return this.keycloak.isTokenExpired(this.minValidity);
    };
    KeycloakService.prototype.getChangePasswordUrl = function () {
        var configuration = this.configuration;
        if (isString(configuration)) {
            throw new Error('KeycloakService: URL to change password page cannot be calculated ' +
                'based on string-based configuration');
        }
        else {
            var url = configuration.url, realm = configuration.realm;
            // redirectUrl is not a standard supported query parameter here,
            // but our theme does support it
            return url + "/realms/" + realm + "/account/password?redirectUrl=" + window.location.href;
        }
    };
    KeycloakService.prototype.ensureInit = function () {
        var _this = this;
        if (isNil(this.keycloak)) {
            this.keycloak = new Keycloak(this.configuration);
            this.keycloak.onAuthSuccess = function () { return _this.authSuccessStream.next(); };
            this.keycloak.onAuthError = function () { return _this.authErrorStream.next(); };
            this.keycloak.onAuthLogout = function () { return _this.authLogoutStream.next(); };
            this.keycloak.onAuthRefreshSuccess = function () { return _this.authRefreshSuccessStream.next(); };
            this.keycloak.onAuthRefreshError = function () { return _this.authRefreshErrorStream.next(); };
            return toPromise(this.keycloak.init(this.initOptions));
        }
        return Promise.resolve(false);
    };
    KeycloakService.ɵfac = function KeycloakService_Factory(t) { return new (t || KeycloakService)(i0.ɵɵinject(KEYCLOAK_CONFIGURATION), i0.ɵɵinject(KEYCLOAK_MIN_VALIDITY), i0.ɵɵinject(KEYCLOAK_INIT_OPTIONS, 8)); };
    KeycloakService.ɵprov = i0.ɵɵdefineInjectable({ token: KeycloakService, factory: KeycloakService.ɵfac });
    return KeycloakService;
}());
export { KeycloakService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(KeycloakService, [{
        type: Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: Inject,
                args: [KEYCLOAK_CONFIGURATION]
            }] }, { type: undefined, decorators: [{
                type: Inject,
                args: [KEYCLOAK_MIN_VALIDITY]
            }] }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [KEYCLOAK_INIT_OPTIONS]
            }] }]; }, null); })();
