/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import { InjectionToken } from '@angular/core';
import { tap$ } from '@gh/rx/operators';

export type ExtInfiniteScrollRange = {
  top: number;
  bottom: number;
};

export interface ExtInfiniteScrollViewport extends CollectionViewer {
  readonly topRowIndex: number;
  readonly rowCount: number;

  setDimensions(container: HTMLElement, dimensions: ExtInfiniteScrollDimensions): void;

  resetDimensions(container: HTMLElement, dimensions: ExtInfiniteScrollDimensions): void;

  scrollToTop(): void;

  updateRowCount(requestedCount: number, loadedCount: number, firstRowIndex: number): void;
}

export interface ExtInfiniteScrollDimensions {
  offsetTop: number;
  itemHeight: number;
  loadThresholdCount: number;
  pageSize: number;
}

// tslint:disable-next-line:max-line-length
export const EXT_INFINITE_SCROLL_DIMENSIONS = new InjectionToken<ExtInfiniteScrollDimensions>(
  'ExtInfiniteScrollDimensions');
export const EXT_INFINITE_SCROLL_VIEWPORT = new InjectionToken<ExtInfiniteScrollViewport>('ExtInfiniteScrollViewport');

export const сollectionViewerWithSideEffect = (viewer: CollectionViewer,
                                               action: ProcedureFn<ListRange>): CollectionViewer => ({
  viewChange: viewer.viewChange.pipe(tap$(action)),
});
