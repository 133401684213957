/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AfterContentInit, Component, ContentChild, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { getScrollbarWidth } from '@gh/core-util';
import { isNil } from 'lodash';

@Component({
  selector: 'gh-table-layout-header',
  template: '<ng-content></ng-content>',
})
export class TableLayoutHeaderComponent {
}

@Component({
  selector: 'gh-table-layout-body',
  template: '<ng-content></ng-content>',
})
export class TableLayoutBodyComponent {
}

@Component({
  selector: 'gh-table-layout',
  templateUrl: 'table-layout.component.html',
  styleUrls: ['table-layout.component.scss'],
})
export class TableLayoutComponent implements AfterContentInit, OnDestroy {
  @ContentChild(TableLayoutHeaderComponent, { static: false }) header: TableLayoutHeaderComponent;
  @ContentChild(TableLayoutBodyComponent, { static: false }) body: TableLayoutBodyComponent;

  @ViewChild('header', { static: true }) headerEl: ElementRef;
  @ViewChild('body', { static: true }) bodyEl: ElementRef;

  scrollWidth: number = getScrollbarWidth();

  constructor() {
    this.onUpdateScroll = this.onUpdateScroll.bind(this);
  }

  ngAfterContentInit(): void {
    if (isNil(this.header)) {
      throw new Error('TableLayoutComponent: Header does not exist');
    }
    if (isNil(this.body)) {
      throw new Error('TableLayoutComponent: Body does not exist');
    }

    this.getBodyElement().addEventListener('scroll', this.onUpdateScroll);
  }

  ngOnDestroy(): void {
    this.getBodyElement().removeEventListener('scroll', this.onUpdateScroll);
  }

  private onUpdateScroll(): void {
    const headerEl = this.getHeaderElement();
    const bodyEl = this.getBodyElement();
    headerEl.scrollLeft = bodyEl.scrollLeft;
  }

  private getHeaderElement(): HTMLElement {
    return this.headerEl.nativeElement;
  }

  private getBodyElement(): HTMLElement {
    return this.bodyEl.nativeElement;
  }
}
