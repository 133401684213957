/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ValidationErrors } from '@angular/forms';
import { gt, gte, isNil, lt, lte } from 'lodash';
import { NumberRangeConstraint, NumberValueConstraint } from '../type.interfaces';

function max(constraint: NumberValueConstraint, value: number): Maybe<ValidationErrors> {
  if (isNil(value)) {
    return;
  }

  const cmp = constraint.include ? lte : lt;

  if (!cmp(value, constraint.value)) {
    return {
      max: constraint.value,
      actual: value,
    };
  }
}

function min(constraint: NumberValueConstraint, value: number): Maybe<ValidationErrors> {
  if (isNil(value)) {
    return;
  }

  const cmp = constraint.include ? gte : gt;

  if (!cmp(value, constraint.value)) {
    return {
      min: constraint.value,
      actual: value,
    };
  }
}

function range(constraint: NumberRangeConstraint, value: number): Maybe<ValidationErrors> {
  if (isNil(value)) {
    return;
  }

  const maxCmp = constraint.includeMax ? lte : lt;
  const minCmp = constraint.includeMin ? gte : gt;

  if (!(minCmp(value, constraint.min) && maxCmp(value, constraint.max))) {
    return {
      min: constraint.min,
      max: constraint.max,
      actualLength: value,
    };
  }
}

function integral(shouldBeIntegral: boolean, value: number): Maybe<ValidationErrors> {
  if (isNil(value)) {
    return;
  }

  if (shouldBeIntegral && Math.trunc(value) !== value) {
    return { value };
  }
}

// tslint:disable-next-line:variable-name
export const NumberTypeValidators = {
  integral,
  min,
  max,
  range,
};
