/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { entity } from '@gh/core-data';
import { LocaleMapper } from '../common';
import { UserProfileDto } from './user-profile.dto';

export const UserProfileMapper = entity<UserProfileDto>({
  locale: LocaleMapper,
});
