/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { setControlValue } from '@gh/core-ui';
import { denull, wrapInObservable } from '@gh/core-util';
import { each, extend, forEach, isEmpty, omit, pull, values } from 'lodash';
import { Subscription } from 'rxjs';

enum ConfirmationStatus {Pending, Approved, Rejected}

/**
 * In general case one page can consists of hierarchy of form states.
 * Each form state has corresponding state of its confirmations. Also each confirmation has own state.
 *
 * Thus confirmation state is a tree and its nodes belong either to form state or to nested confirmations.
 *
 * This interface defines common operations over node of confirmation state.
 *
 */
export interface ConfirmationStateNode {
  /**
   * Whether this confirmation and all its descendants were approved or not
   */
  approved: boolean;
  /**
   * Merged status of this confirmation and all its descendants: Pending, Approved, Rejected
   */
  status: ConfirmationStatus;
  /**
   * Merged errors of this node and all its descendants
   */
  errors?: ValidationErrors;
}

/**
 * This class defines merged state of form state confirmations and provides helper methods to manage this state.
 */
export class FormConfirmationState implements ConfirmationStateNode {
  /**
   * State of all nested form confirmations
   */
  controls: { [name: string]: ControlConfirmationState } = {};

  /**
   * Merged confirmation status of form state and all its descendants
   */
  status: ConfirmationStatus;
  /**
   * Merged errors of form state and all its descendants
   */
  errors?: ValidationErrors;

  /**
   * Parent form confirmation state, if exists
   */
  private parent?: FormConfirmationState;

  /**
   * All child confirmation form states
   */
  private children: FormConfirmationState[] = [];

  constructor(public form: FormGroup) {

  }

  /**
   * Returns true if current status is {@link ConfirmationStatus#Approved}, otherwise false
   */
  get approved(): boolean {
    return this.status === ConfirmationStatus.Approved;
  }

  /**
   * Returns true if current status is {@link ConfirmationStatus#Pending}, otherwise false
   */
  get pending(): boolean {
    return this.status === ConfirmationStatus.Pending;
  }

  /**
   * Returns true if current status is {@link ConfirmationStatus#Rejected}, otherwise false
   */
  get rejected(): boolean {
    return this.status === ConfirmationStatus.Rejected;
  }

  /**
   * Predefine set of nested confirmations for this form state.
   * Each nested confirmation has name and tracked form control
   *
   * @param controls keys are names of nested confirmations, values are tracked values of form
   */
  define(controls: { [name: string]: AbstractControl }): void {
    forEach(controls, (control, name) => this.ensureControl(<string>name, control));
  }

  /**
   * Marks specified nested confirmation as approved.
   * This method resets errors assigned for the nested confirmation. It can reset all or just one error.
   *
   * @param name name of nested confirmation
   * @param error specific error for this confirmation
   */
  markAsApproved(name: string, error?: string): void {
    this.controls[name].markAsApproved(error);
  }

  /**
   * This method creates nested confirmation state and sets validator for this state.
   * Validation is triggered each time value of the underlying control is changed.
   *
   * Validator should follow the same rules as general Angular Validator from @angular/forms package
   *
   * @param name name of the nested confirmation
   * @param control tracked form control
   * @param validator
   */
  setValidator(name: string, control: AbstractControl, validator: ValidatorFn): void {
    this.ensureControl(name, control).setValidator(validator);
  }

  /**
   * This method creates nested confirmation state and sets async validator for this state.
   * Validation is triggered each time value of the underlying control is changed.
   *
   * Validator should follow the same rules as general Angular AsyncValidator from @angular/forms package
   *
   * @param name name of the nested confirmation
   * @param control tracked form control
   * @param validator asynchronous validator
   */
  setAsyncValidator(name: string, control: AbstractControl, validator: AsyncValidatorFn): void {
    this.ensureControl(name, control).setAsyncValidator(validator);
  }

  /**
   * Resets validator for the given nested confirmation
   *
   * @param name
   */
  clearAsyncValidator(name: string): void {
    const state = this.controls[name];

    if (state) {
      state.clearAsyncValidator();
    }
  }

  /**
   * Clears async validators for all nested confirmations
   */
  clearAllAsyncValidators(): void {
    Object.keys(this.controls).forEach((name) => {
      this.clearAsyncValidator(<any>name);
    });
  }

  /**
   * Recalculates state of confirmation state based on descendants
   *
   * @internal this method should not be used by developers
   */
  _updateState(): void {
    // merged list of all confirmations and form state confirmations
    const nodes = (<ConfirmationStateNode[]>values(this.controls)).concat(this.children);

    // recalculate status based on descendants
    if (nodes.some((node) => node.status === ConfirmationStatus.Pending)) {
      this.status = ConfirmationStatus.Pending;
    } else if (nodes.some((node) => node.status === ConfirmationStatus.Pending)) {
      this.status = ConfirmationStatus.Rejected;
    } else {
      this.status = ConfirmationStatus.Approved;
    }

    // merges all descendant errors
    this.errors = nodes.reduce(
      (errors, node) => {
        const current = node.errors;

        if (current) {
          if (errors) {
            extend(errors, current);
          } else {
            errors = extend({}, current);
          }
        }
        return errors;
      },
      <any>void 0);

    if (this.parent) {
      this.parent._updateState();
    }
  }

  /**
   * Returns state of nested confirmation by its name
   */
  get(name: string): ControlConfirmationState {
    return this.controls[name];
  }

  /**
   * Returns specified error retrieved either from this form confirmation state
   * or from nested confirmation (if name parameter is provided).
   *
   * @param code code of the error
   * @param name name of the nested confirmation.
   *             If name is not provided method returns error from form confirmation state
   */
  getError(code: string, name?: string): any {
    const errors = name ? this.controls[name].errors : this.errors;

    if (errors) {
      return errors[code];
    }
  }

  /**
   * Returns whether error exist either for this form confirmation state
   * or for nested confirmation (if name parameter is provided).
   *
   * @param code code of the error
   * @param name name of the nested confirmation.
   */
  hasError(code: string, name?: string): boolean {
    return !!this.getError(code, name);
  }

  /**
   * Sets parent of this confirmation state
   * @internal this method should not be used by developer
   */
  setParent(parent: FormConfirmationState): void {
    this.parent = parent;
    parent.children.push(this);
  }

  /**
   * Resets parent connection
   */
  resetParent(): void {
    const { parent } = this;

    if (parent) {
      pull(parent.children, this);
    }
    this.parent = void 0;
  }

  /**
   * Validates all controls in this confirmation state
   */
  validate(): void {
    each(this.controls, (control) => control.validate());
  }

  /**
   * Creates nested confirmation state
   *
   * @param name name of nested confirmation state
   * @param control tracked form control
   */
  private ensureControl(name: string, control: AbstractControl): ControlConfirmationState {
    let state: ControlConfirmationState = this.controls[name];

    if (!state) {
      this.controls[name] = state = new ControlConfirmationState(this, name, control);
    }

    return state;
  }
}

/**
 * This class defines state of nested confirmation state.
 */
export class ControlConfirmationState implements ConfirmationStateNode {
  /**
   * Status of this confirmation state
   */
  status: ConfirmationStatus;

  /**
   * Set of errors for this confirmation state
   */
  errors?: ValidationErrors;

  private valueSubscription?: Subscription;
  private validationSubscription?: Subscription;

  private validator?: ValidatorFn;
  private asyncValidator?: AsyncValidatorFn;

  constructor(private parent: FormConfirmationState,
              readonly name: string,
              readonly control: AbstractControl) {

  }

  /**
   * Returns whether this confirmation is approved or not
   */
  get approved(): boolean {
    return this.status === ConfirmationStatus.Approved;
  }

  /**
   * Returns whether this confirmation is in pending status or not
   */
  get pending(): boolean {
    return this.status === ConfirmationStatus.Pending;
  }

  /**
   * Returns whether this confirmation is rejected or not
   */
  get rejected(): boolean {
    return this.status === ConfirmationStatus.Rejected;
  }

  /**
   * Sets validator for this state.
   * Validation is triggered each time value of the underlying control is changed.
   *
   * Validator should follow the same rules as general Angular Validator from @angular/forms package
   *
   * @param validator
   */
  setValidator(validator: ValidatorFn): void {
    this.ensureValueSubscription();

    this.validator = validator;
  }

  /**
   * Sets async validator for this state.
   * Validation is triggered each time value of the underlying control is changed.
   *
   * Validator should follow the same rules as general Angular AsyncValidator from @angular/forms package
   *
   * @param validator asynchronous validator
   */
  setAsyncValidator(validator: AsyncValidatorFn): void {
    this.ensureValueSubscription();

    this.asyncValidator = validator;
  }

  /**
   * Resets validator for this confirmation state
   */
  clearAsyncValidator(): void {
    this.asyncValidator = undefined;

    this.unsubscribeValue();
  }

  /**
   * Marks specified this confirmation state as approved.
   * This method resets errors assigned for this state. It can reset all or just one error.
   *
   * @param error specific error for this confirmation
   */
  markAsApproved(error?: string): void {
    if (this.errors) {
      if (error) {
        const errors = omit(this.errors, error);

        if (isEmpty(errors)) {
          this.clearErrors();
        } else {
          this.setErrors(errors);
        }
      } else {
        this.clearErrors();
      }
    }
  }

  /**
   * Validates this control validation state
   */
  validate(): void {
    this.errors = void 0;
    if (this.validator) {
      this.errors = denull(this.validator(this.control));
    }
    this.status = this.calculateStatus();

    if (this.asyncValidator && this.control.enabled) {
      this.status = ConfirmationStatus.Pending;

      const ob = wrapInObservable(this.asyncValidator(this.control));
      this.cancelValidationSubscription();
      this.validationSubscription = ob.subscribe((errors: any) => this.setErrors(errors));
    }

    this.updateFormState();
  }

  /**
   * Directly sets errors for this confirmation state
   *
   * @param errors
   */
  setErrors(errors?: ValidationErrors): void {
    this.errors = errors;
    this.status = this.calculateStatus();
    this.updateFormState();
  }

  private ensureValueSubscription(): void {
    if (this.valueSubscription) {
      return;
    }

    this.valueSubscription = this.control.valueChanges.subscribe(() => this.validate());
  }

  private unsubscribeValue(): void {
    this.cancelValidationSubscription();

    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
      this.valueSubscription = undefined;
    }
  }

  private cancelValidationSubscription(): void {
    if (this.validationSubscription) {
      this.validationSubscription.unsubscribe();
      this.validationSubscription = undefined;
    }
  }

  private calculateStatus(): ConfirmationStatus {
    if (this.errors) {
      return ConfirmationStatus.Rejected;
    }

    return ConfirmationStatus.Approved;
  }

  private clearErrors(): void {
    this.setErrors();
  }

  /**
   * Updates form controls corresponding to this confirmation
   */
  private updateFormState(): void {
    this.setWarningFormValue({
      [ConfirmationStatus.Rejected]: false,
      [ConfirmationStatus.Approved]: true,
      [ConfirmationStatus.Pending]: false,
    }[this.status]);
    this.parent._updateState();
  }

  private setWarningFormValue(value: boolean): void {
    setControlValue(this.parent.form, `confirmations.${this.name}`, value);
  }
}
