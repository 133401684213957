var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageSeverity } from '@gh/core-messages';
import { TranslationService } from '@gh/core-mls';
import { DialogService, NotificationService } from '@gh/core-ui';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@gh/core-ui";
import * as i3 from "@gh/core-mls";
var ExtNotificationRef = /** @class */ (function () {
    function ExtNotificationRef(snackBarRef) {
        this.snackBarRef = snackBarRef;
    }
    Object.defineProperty(ExtNotificationRef.prototype, "afterClosed", {
        get: function () {
            return this.snackBarRef.afterDismissed();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtNotificationRef.prototype, "clicks", {
        get: function () {
            return this.snackBarRef.onAction();
        },
        enumerable: true,
        configurable: true
    });
    ExtNotificationRef.prototype.close = function () {
        this.snackBarRef.dismiss();
    };
    return ExtNotificationRef;
}());
export { ExtNotificationRef };
var ExtNotificationService = /** @class */ (function (_super) {
    __extends(ExtNotificationService, _super);
    function ExtNotificationService(snackBar, dialogs, translations) {
        var _this = _super.call(this) || this;
        _this.snackBar = snackBar;
        _this.dialogs = dialogs;
        _this.translations = translations;
        return _this;
    }
    ExtNotificationService.prototype.error = function (id, params) {
        return this.show({
            messageId: id,
            messageParams: params,
            severity: MessageSeverity.Error,
            hideDelay: 5000,
        });
    };
    ExtNotificationService.prototype.success = function (id, params) {
        return this.show({
            messageId: id,
            messageParams: params,
            severity: MessageSeverity.Error,
            hideDelay: 5000,
        });
    };
    ExtNotificationService.prototype.warning = function (id, params) {
        return this.show({
            messageId: id,
            messageParams: params,
            severity: MessageSeverity.Error,
            hideDelay: 5000,
        });
    };
    ExtNotificationService.prototype.show = function (config) {
        var _this = this;
        var snackBarRef = this.snackBar.open(this.translations.get(config.messageId, config.messageParams), this.translations.get('lbl_open'), {
            duration: config.hideDelay,
        });
        var notificationRef = new ExtNotificationRef(snackBarRef);
        notificationRef.clicks
            .subscribe(function () { return _this.dialogs.message({
            messageId: config.messageId,
            messageParams: config.messageParams,
            severity: config.severity,
        }); });
        return notificationRef;
    };
    ExtNotificationService.prototype.closeAll = function () {
        this.snackBar.dismiss();
    };
    ExtNotificationService.ɵfac = function ExtNotificationService_Factory(t) { return new (t || ExtNotificationService)(i0.ɵɵinject(i1.MatSnackBar), i0.ɵɵinject(i2.DialogService), i0.ɵɵinject(i3.TranslationService)); };
    ExtNotificationService.ɵprov = i0.ɵɵdefineInjectable({ token: ExtNotificationService, factory: ExtNotificationService.ɵfac });
    return ExtNotificationService;
}(NotificationService));
export { ExtNotificationService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtNotificationService, [{
        type: Injectable
    }], function () { return [{ type: i1.MatSnackBar }, { type: i2.DialogService }, { type: i3.TranslationService }]; }, null); })();
