/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { mergePath, parseUrl } from '@gh/core-util';
import { throwError$ } from '@gh/rx';
import { filter$, map$, switchMap$ } from '@gh/rx/operators';
import { RequestInfo } from 'angular-in-memory-web-api';
import { cloneDeep, find, isEmpty, isFunction, isNil, omit, orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { isServerErrorStatus } from '../http/http.utils';
import {
  decorateEndpoint,
  mockBadRequestResponse,
  MockDataEndpoint,
  MockDataRegistry, mockErrorResponse,
  mockMethodNotAllowedResponse,
  mockNotFoundResponse,
  mockSuccessResponse,
} from './mock-data.interfaces';

const HEADER_RANGE_ITEMS = /^items=(\d+)-(\d+)$/;

function prepareUrlRegExp(url: string): RegExp {
  return new RegExp(`${url.replace(/\/(\*|:[^/]+)/g, '/[^\/]+').replace('/', '\\/')}$`);
}

function parseUrlParams(urlWithParams: string, url: string): Hash<string> {
  const params = {};

  const urlWithParamSegmentRe = /\/(:?)([^\/]+)/g;
  const urlSegmentRe = /\/([^\/]+)/g;
  while (true) {
    const urlWithParamSegment = urlWithParamSegmentRe.exec(urlWithParams);
    const urlSegment = urlSegmentRe.exec(url);
    if (isNil(urlWithParamSegment) || isNil(urlSegment)) {
      break;
    }

    const isParam = !isEmpty(urlWithParamSegment[1]);
    const urlWithParamName = urlWithParamSegment[2];
    const urlName = urlSegment[1];

    if (isParam) {
      params[urlWithParamName] = urlName;
    }
  }

  return params;
}

export type MockRequestInfo = RequestInfo & { params: Hash<string> };
export type MockDataEndpointMethod = 'get' | 'post' | 'patch' | 'put' | 'delete';
export type MockDataProcessor = (info: MockRequestInfo) => Observable<HttpResponse<any>> | HttpResponse<any>;

export function mockRegistry(...endpoints: MockDataEndpoint[]): MockDataRegistry {
  return {
    getEndpoint(url: string, method: string): Maybe<MockDataEndpoint> {
      return find(endpoints, (ep) => ep.supportsUrl(url) && ep.supportsMethod(method));
    },
  };
}

export const createMockEndpoint = (url: string,
                                   methods: MockDataEndpointMethod[],
                                   processor: MockDataProcessor) => new MockDataEndpointImpl(url, methods, processor);

export interface MockDataRequest {
  params: Hash<string>;
  query: Hash<string>;
  body: any;
}

export interface MockDataResourceEndpointConfig {
  url: string;
  withEtag?: boolean;
  data?: Hash<any>;
}

export const createMockDataRequest = (requestInfo: MockRequestInfo): MockDataRequest => ({
  params: requestInfo.params,
  query: <any>requestInfo.query,
  body: requestInfo.utils.getJsonBody(requestInfo.req),
});

export const filterData = (data: any, requestInfo: MockRequestInfo) =>
  isFunction(data) ? data(createMockDataRequest(requestInfo)) : data;

export const mockResource = (config: MockDataResourceEndpointConfig) => {
  const { url } = config;
  return createMockEndpoint(
    mergePath(url, '*'),
    ['get', 'post', 'put'],
    resourceMockDataProcessor(config));
};

export interface MockDataPaginationEndpointConfig {
  url: string;
  method?: MockDataEndpointMethod;
  data: any[] | TransformFn<MockDataRequest, any[]>;
}

export const mockPagination = (config: MockDataPaginationEndpointConfig) => {
  const { url, method } = config;
  return createMockEndpoint(
    url,
    [method || 'get'],
    paginationMockDataProcessor(config));
};

export interface MockDataRequestEndpointConfig {
  url: string;
  status?: number;
  method?: MockDataEndpointMethod;
  headers?: HttpHeaders;
  data?: any | TransformFn<MockDataRequest, any>;
}

export const mockRequest = (config: MockDataRequestEndpointConfig) => {
  const { url, method } = config;
  return createMockEndpoint(
    url,
    [method || 'get'],
    requestMockDataProcessor(config));
};

export interface DecorationRequestHandler<T> {
  (req: HttpRequest<T>, next: (req: HttpRequest<T>) => Observable<HttpResponse<T>>): Observable<HttpResponse<T>>;
}

export interface DecorationRequestEndpointConfig {
  url: string;
  method?: MockDataEndpointMethod;
  handler: DecorationRequestHandler<any>;
}

export interface DecorationResponseEndpointConfig {
  url: string;
  method?: MockDataEndpointMethod;
  map: IdentityFn<HttpResponse<any>>;
}

export interface DecorationResponseBodyEndpointConfig {
  url: string;
  method?: MockDataEndpointMethod;
  map: IdentityFn<any>;
}

export interface MockDataErrorEndpointConfig {
  url: string;
  method?: MockDataEndpointMethod;
  status: number;
  statusText?: string;
}

export const decorateRequest = (config: DecorationRequestEndpointConfig) => {
  const { url, method } = config;
  return createMockEndpoint(
    url,
    [method || 'get'],
    decorateRequestMockDataProcessor(config.handler));
};

export const decorateResponse = ({ url, method, map }: DecorationResponseEndpointConfig) =>
  decorateRequest({
    url,
    method,
    handler: (request, next) => next(request).pipe(switchMap$((response) => {
      const decoratedResponse = map(response);
      if (isServerErrorStatus(decoratedResponse.status)) {
        return throwError$(decoratedResponse);
      } else {
        return [decoratedResponse];
      }
    })),
  });

export const decorateResponseBody = ({ url, method, map }: DecorationResponseBodyEndpointConfig) =>
  decorateRequest({
    url,
    method,
    handler: (request, next) => next(request).pipe(map$((response) => response.clone({ body: map(response.body) }))),
  });

const decorateRequestMockDataProcessor = (handler: DecorationRequestHandler<any>) => (request: MockRequestInfo) =>
  handler(
    <HttpRequest<any>>request.req,
    (req) => request.utils.getPassThruBackend().handle(req).pipe(filter$((event) => event instanceof HttpResponse)));

const requestMockDataProcessor = (config: MockDataRequestEndpointConfig) => (request: MockRequestInfo) =>
  new HttpResponse({
    url: config.url,
    status: config.status || 200,
    body: filterData(config.data, request),
    headers: config.headers,
  });

export const logRequest = (endpoint: MockDataEndpoint) =>
  decorateEndpoint(
    endpoint,
    (request) => {
      // tslint:disable-next-line:no-console
      console.log({
        url: request.url,
        method: request.method,
        query: <any>request.query,
        body: request.utils.getJsonBody(request.req),
      });
      return endpoint.process(request);
    });

const paginationMockDataProcessor = (config: MockDataPaginationEndpointConfig) => (request: MockRequestInfo) => {
  const { data } = config;
  switch (request.method) {
    case 'get':
    case 'post':
      const range = request.req['headers'].get('Range');
      const sortBy = request.query['sortBy'];

      if (range) {
        const match = HEADER_RANGE_ITEMS.exec(range);

        if (match) {
          const filteredData = filterData(data, request);
          const sortedData = sortBy
            ? orderBy(filteredData, [sortBy.substring(1)], [sortBy[0] === '+' ? 'asc' : 'desc'])
            : filteredData;
          const slicedData = sortedData.slice(Number(match[1]), Number(match[2]) + 1);
          return mockSuccessResponse(
            request.resourceUrl,
            new HttpHeaders({
              'Content-Range': `items ${match[1]}-${match[2]}/${sortedData.length}`,
            }),
            slicedData);
        }
      }
      return mockBadRequestResponse(request.resourceUrl);
    default:
      return mockMethodNotAllowedResponse(request.resourceUrl);
  }
};

export const mockError = (config: MockDataErrorEndpointConfig) => {
  const { url, method } = config;
  return createMockEndpoint(
    url,
    [method || 'get'],
    errorMockDataProcessor(config));
};

const errorMockDataProcessor = (config: MockDataErrorEndpointConfig) => () => {
  const { url, status, statusText } = config;
  return mockErrorResponse(url, status, statusText || '');
};

const resourceMockDataProcessor = (config: MockDataResourceEndpointConfig) => (request: MockRequestInfo) => {
  const { data, withEtag } = config;
  const db = data || {};
  switch (request.method) {
    case 'get': {
      const { id } = request;
      const resource = db[id];
      if (resource) {
        return mockSuccessResponse(
          request.resourceUrl,
          withEtag && resource.etag ? new HttpHeaders({ 'ETag': resource.etag }) : void 0,
          cloneDeep(omit(resource, 'etag')));
      } else {
        return mockNotFoundResponse(request.resourceUrl);
      }
    }
    case 'post': {
      const { id } = request;
      const body = JSON.parse(request.utils.getJsonBody(request.req));
      db[id] = withEtag ? { ...body, etag: (<HttpRequest<any>>request.req).headers.get('ETag') } : body;
      return mockSuccessResponse(request.resourceUrl, new HttpHeaders(), body);
    }
    case 'put': {
      const { id } = request;
      const existingResource = db[id];
      if (isNil(existingResource)) {
        return mockNotFoundResponse(request.resourceUrl);
      }

      const body = JSON.parse(request.utils.getJsonBody(request.req));
      const etag = (<HttpRequest<any>>request.req).headers.get('ETag');
      db[id] = withEtag ? { ...body, etag } : body;
      return mockSuccessResponse(request.resourceUrl, new HttpHeaders(), body);
    }
    default:
      return mockMethodNotAllowedResponse(request.resourceUrl);
  }
};

class MockDataEndpointImpl implements MockDataEndpoint {
  private urlRe: RegExp;

  constructor(private urlWithParams: string,
              private methods: MockDataEndpointMethod[],
              private processor: MockDataProcessor) {
    this.urlRe = prepareUrlRegExp(urlWithParams);
  }

  process(request: RequestInfo): Observable<HttpResponse<any>> | HttpResponse<any> {
    const parsedUrl = parseUrl(request.url);
    // tslint:disable-next-line:no-non-null-assertion
    const params = parseUrlParams(this.urlWithParams, this.urlRe.exec(parsedUrl.pathname)![0]);
    return this.processor({ ...request, params });
  }

  supportsMethod(method: string): boolean {
    return this.methods.length === 0 || this.methods.includes(<any>method);
  }

  supportsUrl(url: string): boolean {
    return this.urlRe.test(url);
  }
}
