/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import * as invariant from 'invariant';
import { isBoolean, sortBy, startsWith, uniq } from 'lodash';
import { isPermissionComplete, NESTED_ACTION } from './auth.utils';
import { Permission } from './permission';
import { PermissionProvider } from './permission-provider';

@Injectable()
export class PermissionNameSetProvider extends PermissionProvider {
  private permissionIndex: Hash<boolean>;

  init(names: string[]): void {
    // sorting guarantees that parent permission is included before children
    const sortedNames = uniq(sortBy(names));

    // building of quick-to-check-permission index
    const index = this.permissionIndex = {};
    sortedNames.filter(isPermissionComplete).forEach((permission) => index[permission] = true);
    getNestedPermissions(sortedNames).forEach((permission) => index[`${permission}#${NESTED_ACTION}`] = true);
  }

  hasPermission(permission: Permission): boolean {
    const { permissionIndex } = this;
    invariant(permissionIndex, 'Permission name set is not initialized');

    let cached = permissionIndex[permission.path];

    if (isBoolean(cached)) {
      return cached;
    }

    cached = permission.some((innerPermission) => permissionIndex[innerPermission.path] === true);
    permissionIndex[permission.path] = cached;

    return cached;
  }
}

/**
 * This method creates set of nested permissions.
 * Set of nested permissions has nested permission for permission p in the set P iff P has any permission under p
 * (e. g. p#VIEW, p/x/y).
 *
 * Implementation of this method assumes that input set of permissions is sorted in ascending order.
 *
 * @param names sorted set of permissions
 */
function getNestedPermissions(names: string[]): string[] {
  const nested = [];
  let parent;

  for (const name of names) {
    if (parent && startsWith(name, parent)) {
      nested.push(parent);
    }

    // here, we can forget about the last parent,
    // because we either added it into the set of nested permission or parent does have nested permissions
    if (!isPermissionComplete(name)) {
      parent = name;
    }
  }

  return nested;
}
