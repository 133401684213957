/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { CONTEXT_PATH, KEYCLOAK_AUTH_SERVER_URL, KEYCLOAK_JSON_URL, KEYCLOAK_MIN_VALIDITY, KEYCLOAK_REALM, KEYCLOAK_RESOURCE, KEYCLOAK_SECRET, } from '@gh/config';
import { CoreKeycloakAuthModule } from '@gh/core-auth-keycloak';
import { CoreAuthMockModule } from '@gh/core-auth-mock';
import { NoopModule } from '@gh/core-util';
import * as i0 from "@angular/core";
import * as i1 from "../../core-auth-keycloak/core-keycloak-auth.module";
import * as i2 from "../../core-auth-mock/core-auth-mock.module";
export function logoutOptionsFactory() {
    return {
        redirectUri: window.location.origin + CONTEXT_PATH,
    };
}
export var configuration = {
    configuration: KEYCLOAK_JSON_URL ? KEYCLOAK_JSON_URL : {
        url: KEYCLOAK_AUTH_SERVER_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_RESOURCE,
        credentials: !KEYCLOAK_SECRET ? undefined : {
            secret: KEYCLOAK_SECRET,
        },
    },
    initOptions: {
        onLoad: 'check-sso',
    },
    minValidity: KEYCLOAK_MIN_VALIDITY,
    logoutOptions: logoutOptionsFactory,
};
var CustomerAuthKeycloakModule = /** @class */ (function () {
    function CustomerAuthKeycloakModule() {
    }
    CustomerAuthKeycloakModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomerAuthKeycloakModule });
    CustomerAuthKeycloakModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomerAuthKeycloakModule_Factory(t) { return new (t || CustomerAuthKeycloakModule)(); }, imports: [[
                CoreKeycloakAuthModule.asStandalone(configuration),
            ]] });
    return CustomerAuthKeycloakModule;
}());
export { CustomerAuthKeycloakModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomerAuthKeycloakModule, { imports: [i1.CoreKeycloakAuthModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerAuthKeycloakModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CoreKeycloakAuthModule.asStandalone(configuration),
                ],
            }]
    }], null, null); })();
var CustomerAuthKeycloakWithMockModule = /** @class */ (function () {
    function CustomerAuthKeycloakWithMockModule() {
    }
    CustomerAuthKeycloakWithMockModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomerAuthKeycloakWithMockModule });
    CustomerAuthKeycloakWithMockModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomerAuthKeycloakWithMockModule_Factory(t) { return new (t || CustomerAuthKeycloakWithMockModule)(); }, imports: [[
                CoreKeycloakAuthModule.asDecorated(configuration),
                CoreAuthMockModule.asDecorating(),
            ]] });
    return CustomerAuthKeycloakWithMockModule;
}());
export { CustomerAuthKeycloakWithMockModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomerAuthKeycloakWithMockModule, { imports: [i1.CoreKeycloakAuthModule, i2.CoreSecondaryAuthMockModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerAuthKeycloakWithMockModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CoreKeycloakAuthModule.asDecorated(configuration),
                    CoreAuthMockModule.asDecorating(),
                ],
            }]
    }], null, null); })();
var CustomerAuthMockModule = /** @class */ (function () {
    function CustomerAuthMockModule() {
    }
    CustomerAuthMockModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomerAuthMockModule });
    CustomerAuthMockModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomerAuthMockModule_Factory(t) { return new (t || CustomerAuthMockModule)(); }, imports: [[
                CoreAuthMockModule.asStandalone({
                    fields: ['email'],
                    width: 400,
                }),
            ]] });
    return CustomerAuthMockModule;
}());
export { CustomerAuthMockModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomerAuthMockModule, { imports: [i2.CorePrimaryAuthMockModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerAuthMockModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CoreAuthMockModule.asStandalone({
                        fields: ['email'],
                        width: 400,
                    }),
                ],
            }]
    }], null, null); })();
var CustomerAuthConfigModule = /** @class */ (function () {
    function CustomerAuthConfigModule() {
    }
    CustomerAuthConfigModule.useKeycloak = function () {
        return { ngModule: CustomerAuthKeycloakModule };
    };
    CustomerAuthConfigModule.useKeycloakWithMock = function () {
        return { ngModule: CustomerAuthKeycloakWithMockModule };
    };
    CustomerAuthConfigModule.useMock = function () {
        return { ngModule: CustomerAuthMockModule };
    };
    CustomerAuthConfigModule.none = function () {
        return { ngModule: NoopModule };
    };
    return CustomerAuthConfigModule;
}());
export { CustomerAuthConfigModule };
