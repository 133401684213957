/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { ResourceLoader } from './resource-loader.service';
import * as i0 from "@angular/core";
var CoreExternalCommonModule = /** @class */ (function () {
    function CoreExternalCommonModule() {
    }
    CoreExternalCommonModule.forRoot = function () {
        return {
            ngModule: CoreExternalCommonModule,
            providers: [ResourceLoader],
        };
    };
    CoreExternalCommonModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreExternalCommonModule });
    CoreExternalCommonModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreExternalCommonModule_Factory(t) { return new (t || CoreExternalCommonModule)(); }, providers: [] });
    return CoreExternalCommonModule;
}());
export { CoreExternalCommonModule };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreExternalCommonModule, [{
        type: NgModule,
        args: [{
                providers: [],
            }]
    }], null, null); })();
