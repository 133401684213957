import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MOCK_AUTH_MODE_PREDEFINED_USERS } from '@gh/config';
import { of$ } from '@gh/rx';
import { capitalize, defer, isEmpty, words } from 'lodash';
import { PRIMARY_MOCK_OPTIONS } from '../primary-mock-auth-provider.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/radio";
import * as i5 from "@angular/material/form-field";
import * as i6 from "@angular/material/input";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/material/table";
import * as i9 from "@angular/cdk/table";
var _c0 = ["anotherInput"];
function MockLoginDialogComponent_mat_table_4_mat_header_cell_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-header-cell", 19);
} }
function MockLoginDialogComponent_mat_table_4_mat_cell_3_Template(rf, ctx) { if (rf & 1) {
    var _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-cell", 19);
    i0.ɵɵelementStart(1, "mat-radio-button", 6);
    i0.ɵɵlistener("click", function MockLoginDialogComponent_mat_table_4_mat_cell_3_Template_mat_radio_button_click_1_listener() { i0.ɵɵrestoreView(_r11); var user_r9 = ctx.$implicit; var ctx_r10 = i0.ɵɵnextContext(2); return ctx_r10.onSelect(user_r9); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var user_r9 = ctx.$implicit;
    var ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("checked", ctx_r5.selectedUser === user_r9);
} }
function MockLoginDialogComponent_mat_table_4_ng_container_4_mat_header_cell_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-header-cell");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var field_r12 = i0.ɵɵnextContext().$implicit;
    var ctx_r13 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r13.stringifyField(field_r12));
} }
function MockLoginDialogComponent_mat_table_4_ng_container_4_mat_cell_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-cell");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var user_r16 = ctx.$implicit;
    var field_r12 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(user_r16[field_r12]);
} }
function MockLoginDialogComponent_mat_table_4_ng_container_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementContainerStart(1, 20);
    i0.ɵɵtemplate(2, MockLoginDialogComponent_mat_table_4_ng_container_4_mat_header_cell_2_Template, 2, 1, "mat-header-cell", 21);
    i0.ɵɵtemplate(3, MockLoginDialogComponent_mat_table_4_ng_container_4_mat_cell_3_Template, 2, 1, "mat-cell", 22);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    var field_r12 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("cdkColumnDef", field_r12);
} }
function MockLoginDialogComponent_mat_table_4_mat_header_row_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-header-row");
} }
function MockLoginDialogComponent_mat_table_4_mat_row_6_Template(rf, ctx) { if (rf & 1) {
    var _r20 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-row", 23);
    i0.ɵɵlistener("click", function MockLoginDialogComponent_mat_table_4_mat_row_6_Template_mat_row_click_0_listener() { i0.ɵɵrestoreView(_r20); var user_r18 = ctx.$implicit; var ctx_r19 = i0.ɵɵnextContext(2); return ctx_r19.onSelect(user_r18); });
    i0.ɵɵelementEnd();
} }
function MockLoginDialogComponent_mat_table_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-table", 12);
    i0.ɵɵelementContainerStart(1, 13);
    i0.ɵɵtemplate(2, MockLoginDialogComponent_mat_table_4_mat_header_cell_2_Template, 1, 0, "mat-header-cell", 14);
    i0.ɵɵtemplate(3, MockLoginDialogComponent_mat_table_4_mat_cell_3_Template, 2, 1, "mat-cell", 15);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵtemplate(4, MockLoginDialogComponent_mat_table_4_ng_container_4_Template, 4, 1, "ng-container", 16);
    i0.ɵɵtemplate(5, MockLoginDialogComponent_mat_table_4_mat_header_row_5_Template, 1, 0, "mat-header-row", 17);
    i0.ɵɵtemplate(6, MockLoginDialogComponent_mat_table_4_mat_row_6_Template, 1, 0, "mat-row", 18);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("dataSource", ctx_r0.dataSource);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngForOf", ctx_r0.options.fields);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("cdkHeaderRowDef", ctx_r0.displayedColumns);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("cdkRowDefColumns", ctx_r0.displayedColumns);
} }
function MockLoginDialogComponent_div_5_mat_radio_button_1_Template(rf, ctx) { if (rf & 1) {
    var _r24 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-radio-button", 6);
    i0.ɵɵlistener("click", function MockLoginDialogComponent_div_5_mat_radio_button_1_Template_mat_radio_button_click_0_listener() { i0.ɵɵrestoreView(_r24); var user_r22 = ctx.$implicit; var ctx_r23 = i0.ɵɵnextContext(2); return ctx_r23.onSelect(user_r22); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var user_r22 = ctx.$implicit;
    var ctx_r21 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("checked", ctx_r21.selectedUser === user_r22);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(user_r22[ctx_r21.firstFieldName]);
} }
function MockLoginDialogComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, MockLoginDialogComponent_div_5_mat_radio_button_1_Template, 2, 2, "mat-radio-button", 24);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r1.users);
} }
function MockLoginDialogComponent_mat_error_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1, "Enter email of the target user");
    i0.ɵɵelementEnd();
} }
var ANOTHER_USER = {};
export var MOCK_AUTH_MODE_USERS = MOCK_AUTH_MODE_PREDEFINED_USERS || [];
var MockUserDataSource = /** @class */ (function () {
    function MockUserDataSource() {
    }
    MockUserDataSource.prototype.connect = function (collectionViewer) {
        return of$(MOCK_AUTH_MODE_USERS);
    };
    MockUserDataSource.prototype.disconnect = function (collectionViewer) {
        // nothing to do
    };
    return MockUserDataSource;
}());
var MockLoginDialogComponent = /** @class */ (function () {
    function MockLoginDialogComponent(dialogRef, options) {
        this.dialogRef = dialogRef;
        this.options = options;
        this.anotherUserId = '';
        this.another = ANOTHER_USER;
        this.users = MOCK_AUTH_MODE_USERS;
        this.selectedUser = MOCK_AUTH_MODE_USERS[0] || ANOTHER_USER;
        this.dataSource = new MockUserDataSource();
        this.displayedColumns = ['radio'].concat(this.options.fields);
        this.firstFieldName = this.options.fields[0];
        this.isMultipleFields = this.options.fields.length > 1;
    }
    Object.defineProperty(MockLoginDialogComponent.prototype, "dialogWidth", {
        get: function () {
            return this.options.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MockLoginDialogComponent.prototype, "anotherUserError", {
        get: function () {
            return this.selectedUser === ANOTHER_USER && isEmpty(this.anotherUserId);
        },
        enumerable: true,
        configurable: true
    });
    MockLoginDialogComponent.prototype.stringifyField = function (field) {
        return words(field).map(capitalize).join(' ');
    };
    MockLoginDialogComponent.prototype.onLogin = function (event) {
        event.preventDefault();
        var _a = this, selectedUser = _a.selectedUser, anotherUserId = _a.anotherUserId;
        this.dialogRef.close(selectedUser === ANOTHER_USER ? anotherUserId : selectedUser.id);
    };
    MockLoginDialogComponent.prototype.onSelect = function (user) {
        this.selectedUser = user;
    };
    MockLoginDialogComponent.prototype.onAnotherUser = function () {
        var _this = this;
        this.onSelect(this.another);
        defer(function () { return _this.anotherEl.nativeElement.focus(); });
    };
    MockLoginDialogComponent.ɵfac = function MockLoginDialogComponent_Factory(t) { return new (t || MockLoginDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(PRIMARY_MOCK_OPTIONS)); };
    MockLoginDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: MockLoginDialogComponent, selectors: [["gh-mock-login-dialog"]], viewQuery: function MockLoginDialogComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(_c0, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.anotherEl = _t.first);
        } }, decls: 16, vars: 9, consts: [[1, "dialog-title"], [1, "overflow-x-auto"], ["novalidate", "", 1, "m-column", 3, "submit"], ["class", "m-column", 3, "dataSource", 4, "ngIf"], ["class", "m-column user-panel", 4, "ngIf"], [1, "m-column", "user-panel"], ["color", "primary", 3, "checked", "click"], ["matInput", "", "type", "text", "name", "anotherUser", 3, "ngModel", "disabled", "ngModelChange"], ["anotherInput", ""], [4, "ngIf"], [1, "dialog-buttons"], ["mat-raised-button", "", "color", "primary", "type", "submit", 3, "disabled"], [1, "m-column", 3, "dataSource"], ["cdkColumnDef", "radio"], ["class", "user-cell--radio", 4, "cdkHeaderCellDef"], ["class", "user-cell--radio", 4, "cdkCellDef"], [4, "ngFor", "ngForOf"], [4, "cdkHeaderRowDef"], ["class", "user-row", 3, "click", 4, "cdkRowDef", "cdkRowDefColumns"], [1, "user-cell--radio"], [3, "cdkColumnDef"], [4, "cdkHeaderCellDef"], [4, "cdkCellDef"], [1, "user-row", 3, "click"], ["color", "primary", 3, "checked", "click", 4, "ngFor", "ngForOf"]], template: function MockLoginDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "h2", 0);
            i0.ɵɵtext(1, "Login");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(2, "div", 1);
            i0.ɵɵelementStart(3, "form", 2);
            i0.ɵɵlistener("submit", function MockLoginDialogComponent_Template_form_submit_3_listener($event) { return ctx.onLogin($event); });
            i0.ɵɵtemplate(4, MockLoginDialogComponent_mat_table_4_Template, 7, 4, "mat-table", 3);
            i0.ɵɵtemplate(5, MockLoginDialogComponent_div_5_Template, 2, 1, "div", 4);
            i0.ɵɵelementStart(6, "div", 5);
            i0.ɵɵelementStart(7, "mat-radio-button", 6);
            i0.ɵɵlistener("click", function MockLoginDialogComponent_Template_mat_radio_button_click_7_listener() { return ctx.onAnotherUser(); });
            i0.ɵɵtext(8, "Another User");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(9, "mat-form-field");
            i0.ɵɵelementStart(10, "input", 7, 8);
            i0.ɵɵlistener("ngModelChange", function MockLoginDialogComponent_Template_input_ngModelChange_10_listener($event) { return ctx.anotherUserId = $event; });
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(12, MockLoginDialogComponent_mat_error_12_Template, 2, 0, "mat-error", 9);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(13, "div", 10);
            i0.ɵɵelementStart(14, "button", 11);
            i0.ɵɵtext(15, "Log In");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(3);
            i0.ɵɵstyleProp("min-width", ctx.dialogWidth, "px");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.isMultipleFields);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", !ctx.isMultipleFields);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("checked", ctx.selectedUser === ctx.another);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngModel", ctx.anotherUserId)("disabled", ctx.selectedUser !== ctx.another);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.anotherUserError);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("disabled", ctx.anotherUserError);
        } }, directives: [i2.ɵangular_packages_forms_forms_y, i2.NgControlStatusGroup, i2.NgForm, i3.NgIf, i4.MatRadioButton, i5.MatFormField, i6.MatInput, i2.DefaultValueAccessor, i2.NgControlStatus, i2.NgModel, i7.MatButton, i8.MatTable, i9.CdkColumnDef, i9.CdkHeaderCellDef, i9.CdkCellDef, i3.NgForOf, i9.CdkHeaderRowDef, i9.CdkRowDef, i8.MatHeaderCell, i8.MatCell, i8.MatHeaderRow, i8.MatRow, i5.MatError], styles: ["[_nghost-%COMP%] {\n  display: block; }\n\n.dialog-title[_ngcontent-%COMP%] {\n  padding: 20px 50px;\n  margin: 0; }\n\n.user-row[_ngcontent-%COMP%] {\n  cursor: pointer; }\n\n.user-cell--radio[_ngcontent-%COMP%] {\n  flex: 0.5; }\n  .user-cell--radio[_ngcontent-%COMP%]     .mat-radio-button {\n    margin: 0; }\n\n.user-panel[_ngcontent-%COMP%] {\n  padding: 20px 25px 0; }\n\n.dialog-buttons[_ngcontent-%COMP%] {\n  padding: 10px 20px; }\n\n[_nghost-%COMP%]     .mat-column-email {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap; }\n\n.m-column[_ngcontent-%COMP%] {\n  display: flex;\n  flex-direction: column; }\n\n.dialog-buttons[_ngcontent-%COMP%] {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end; }"] });
    return MockLoginDialogComponent;
}());
export { MockLoginDialogComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MockLoginDialogComponent, [{
        type: Component,
        args: [{
                selector: 'gh-mock-login-dialog',
                templateUrl: 'mock-login-dialog.component.html',
                styleUrls: ['mock-login-dialog.component.scss'],
            }]
    }], function () { return [{ type: i1.MatDialogRef }, { type: undefined, decorators: [{
                type: Inject,
                args: [PRIMARY_MOCK_OPTIONS]
            }] }]; }, { anotherEl: [{
            type: ViewChild,
            args: ['anotherInput', { static: false }]
        }] }); })();
