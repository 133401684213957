/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { fromPairs, max, min } from 'lodash';
import { coalesce } from './data-util';

export const DAY_SATURDAY = 6;
export const DAY_SUNDAY = 0;
export const DAY_MONDAY = 1;
export const DAY_FRIDAY = 5;

export const WEEK_DAYS = 7;

export const MS_IN_DAY = 24 * 60 * 60 * 1000;

export const MONTH_LOGICAL_NAMES = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const MONTH_LOGICAL_NAME_INDEX = fromPairs(MONTH_LOGICAL_NAMES.map((month, i) => [month, i]));
const MS_IN_MIN = 1000 * 60; // tslint:disable-line:no-magic-numbers

export interface TimeSetupOptions {
  serverTime: Date;
  clientTime: Date;
}

export type DateTime = {
  date: Date;
  time: Date;
};

let serverTimeOffset = 0;

function roundToMin(ms: number): number {
  return MS_IN_MIN * Math.round(ms / MS_IN_MIN);
}

export function getMonthOrder(month: string): number {
  return coalesce(MONTH_LOGICAL_NAME_INDEX[month], -1);
}

export function getMonthLogicalName(order: number): string {
  return MONTH_LOGICAL_NAMES[order];
}

export function setupCurrentTimeSync({ serverTime, clientTime }: TimeSetupOptions): void {
  serverTimeOffset = roundToMin(serverTime.getTime() - clientTime.getTime());
}

/**
 * Returns current date
 * @returns {Date}
 */
export function currentDate(): Date {
  const currentClientDate = new Date();
  currentClientDate.setTime(currentClientDate.getTime() + serverTimeOffset);
  return currentClientDate;
}

export function currentNormalizedDate(): Date {
  return normalizeDate(currentDate());
}

export function currentYear(): number {
  return currentDate().getFullYear();
}

export function currentDayOfMonth(): number {
  return currentDate().getDate();
}

export function newDate(y: number, m: number = 0, d: number = 1): Date {
  return new Date(y, m, d);
}

export function newTime(h: number, m: number, s: number = 0, ms: number = 0): Date {
  return new Date(1970, 0, 1, h, m, s, ms); // tslint:disable-line:no-magic-numbers
}

export function offsetDate(date: Date): Date {
  date.setTime(date.getTime() + date.getTimezoneOffset() * MS_IN_MIN);
  return date;
}

/**
 *
 * @param date
 * @returns {boolean}
 */
export function beforeCurrentDate(date: Date): boolean {
  return date < currentDate();
}

/**
 * Returns number of years since the specified date.
 *
 * @param date
 * @returns {number}
 */
export function yearsSince(date: Date): number {
  const current = currentDate();

  const years = current.getFullYear() - date.getFullYear();
  const monthes = current.getMonth() - date.getMonth();
  const dates = current.getDate() - date.getDate();

  if (monthes > 0) {
    return years;
  } else if (monthes === 0) {
    if (dates >= 0) {
      return years;
    } else {
      return years - 1;
    }
  } else {
    return years - 1;
  }
}

export function isDateInvalid(date: Date): boolean {
  return isNaN(date.getTime());
}

export function cloneDate(date: Date): Date {
  return new Date(date.getTime());
}

export function shiftDays(date: Date, count: number): Date {
  const cloned = cloneDate(date);
  cloned.setDate(cloned.getDate() + count);
  return cloned;
}

export function shiftHours(date: Date, count: number): Date {
  const cloned = cloneDate(date);
  cloned.setHours(cloned.getHours() + count);
  return cloned;
}

export function normalizeDate(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function addMonths(date: Date, shift: number): Date {
  const cloned = cloneDate(date);
  cloned.setMonth(cloned.getMonth() + shift);
  return cloned;
}

export function addWeeks(date: Date, shift: number): Date {
  return addDays(normalizeDate(date), shift * WEEK_DAYS);
}

export function addDays(date: Date, shift: number): Date {
  const cloned = cloneDate(date);
  cloned.setDate(cloned.getDate() + shift);
  return cloned;
}

export function setDays(date: Date, days: number): Date {
  const cloned = cloneDate(date);
  cloned.setDate(days);
  return cloned;
}

export function addHours(date: Date, shift: number): Date {
  const cloned = cloneDate(date);
  cloned.setHours(cloned.getHours() + shift);
  return cloned;
}

export function setHours(date: Date, hours: number): Date {
  const cloned = cloneDate(date);
  cloned.setHours(hours);
  return cloned;
}

export function setMinutes(date: Date, minutes: number): Date {
  const cloned = cloneDate(date);
  cloned.setMinutes(minutes);
  return cloned;
}

export function mergeDateAndTime(date: Date, time: Date): Date {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds());
}

export function splitDateAndTime(date: Date): { date: Date; time: Date } {
  return {
    date: newDate(date.getFullYear(), date.getMonth(), date.getDate()),
    time: newTime(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()),
  };
}

export function getTime(date: Date): Date {
  return splitDateAndTime(date).time;
}

const WORKING_HOURS_PER_DAY = 8;

export function daysInWorkingHours(days: number, hoursPerDay: number = WORKING_HOURS_PER_DAY): number {
  return days * hoursPerDay;
}

export function isDateValid(date: Date): boolean {
  return !isDateInvalid(date);
}

export function isDateWeekend(date: Date): boolean {
  const day = date.getDay();
  return day === DAY_SATURDAY || day === DAY_SUNDAY;
}

export function isSameDayOfYear(a: Date, b: Date): boolean {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}

export function diffTimeHours(start: Date, end: Date): number {
  return end.getHours() - start.getHours();
}

export function diffDays(start: Date, end: Date): number {
  return (end.getTime() - start.getTime()) / MS_IN_DAY;
}

export function endOfDay(date: Date): Date {
  return new Date(addDays(normalizeDate(date), 1).getTime() - 1);
}

export function isDatesEquals(firstDate: Date, secondDate: Date): boolean {
  return newDate(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()).getTime()
    === newDate(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate()).getTime();
}

export function lastDayOfMonth(date: Date): Date {
  const nextMonth = addMonths(setDays(normalizeDate(date), 1), 1);
  return normalizeDate(new Date(nextMonth.getTime() - 1));
}

export function firstDayOfMonth(date: Date): Date {
  return setDays(normalizeDate(date), 1);
}

export function invalidDate(): Date {
  return new Date('');
}

export function truncateDateToMinutes(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());
}

export function isDateTimeValid(datetime: DateTime): boolean {
  return datetime.date && datetime.time && isDateValid(datetime.date) && isDateValid(datetime.time) || false;
}

export function createDateTime(date: Date, time: Date): DateTime {
  return { date, time };
}

export function dateTimeToDate({ date, time }: DateTime): Date {
  return mergeDateAndTime(date, time);
}

export function dateTimeFromDate(date: Date): DateTime {
  return splitDateAndTime(date);
}

export function startOfWeek(date: Date, start: number = DAY_MONDAY): Date {
  return addDays(normalizeDate(date), (date.getDay() - start) * -1);
}

export function endOfWeek(date: Date, end: number = DAY_FRIDAY): Date {
  return addDays(startOfWeek(date), end - 1);
}

export function minDate(...dates: Date[]): Maybe<Date> {
  return min(dates);
}

export function maxDate(...dates: Date[]): Maybe<Date> {
  return max(dates);
}
