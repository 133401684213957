/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '@gh/core-mls';
import { MessageDialogBaseComponent } from '@gh/core-ui';

@Component({
  selector: 'gh-ext-message-dialog',
  templateUrl: 'ext-message-dialog.component.html',
  styleUrls: ['ext-message-dialog.component.scss'],
})
export class ExtMessageDialogComponent extends MessageDialogBaseComponent {

  constructor(translationService: TranslationService,
              dialogRef: MatDialogRef<MessageDialogBaseComponent>,
              elementRef: ElementRef,
              renderer: Renderer2) {
    super(translationService, dialogRef, elementRef, renderer);
  }

  get iconName(): string {
    if (this.typeSuccess) {
      return 'done';
    } else if (this.typeErrorOrWarning) {
      return 'error';
    } else {
      return 'info-outline';
    }
  }

  get iconColorName(): string {
    if (this.typeSuccess) {
      return 'success';
    } else if (this.typeErrorOrWarning) {
      return 'error';
    } else {
      return 'info';
    }
  }
}
