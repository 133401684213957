/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ListRange } from '@angular/cdk/collections';
import { Directive, DoCheck, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { domOffset, fromAnimationFrame$ } from '@gh/core-util';
import { fromEvent$ } from '@gh/rx';
import {
  distinctUntilChanged$,
  filter$,
  finalize$,
  map$,
  mapTo$,
  sample$,
  startWith$,
  switchMap$,
  tap$,
} from '@gh/rx/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  EXT_INFINITE_SCROLL_VIEWPORT,
  ExtInfiniteScrollDimensions,
  ExtInfiniteScrollRange,
  ExtInfiniteScrollViewport,
} from './ext-infinite-scroll.interfaces';

@Directive({
  selector: '[ghExtInfiniteScrollViewport]',
  providers: [{ provide: EXT_INFINITE_SCROLL_VIEWPORT, useExisting: ExtInfiniteScrollViewportDirective }],
})
export class ExtInfiniteScrollViewportDirective implements ExtInfiniteScrollViewport, OnInit, OnDestroy, DoCheck {
  visibleRange: Observable<ExtInfiniteScrollRange>;
  visibleItemsRange: Observable<ListRange>;
  viewChange: Observable<ListRange>;

  private lastRequestedStart: number = Number.MAX_SAFE_INTEGER;
  private lastRequestedEnd: number = 0;

  private _rowCount: number = 0;
  private _topRowIndex: number = -1;

  private containerElement?: HTMLElement;
  private containerTopOffset: number = 0;
  private _viewport?: ElementRef | string;
  private viewportElement$$ = new BehaviorSubject<HTMLElement>(this.elementRef.nativeElement);
  private dimensions$$ = new BehaviorSubject<Maybe<ExtInfiniteScrollDimensions>>(void 0);

  private nextDimensions?: ExtInfiniteScrollDimensions;
  private nextContainerElement?: HTMLElement;

  private dimensionsHaveChanged = false;
  private viewportHasChanged = false;

  @Input('ghExtInfiniteScrollViewport')
  set viewport(viewport: Maybe<ElementRef | string>) {
    if (this._viewport !== viewport) {
      this._viewport = viewport;

      this.viewportHasChanged = true;
    }
  }

  get topRowIndex(): number {
    return this._topRowIndex;
  }

  get rowCount(): number {
    return this._rowCount;
  }

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.visibleRange = this.viewportElement$$.pipe(
      switchMap$((element) => element === document.documentElement
        ? fromAnimationFrame$().pipe(
          map$(() => element.scrollTop),
          distinctUntilChanged$(),
          mapTo$(element))
        : fromEvent$(element, 'scroll').pipe(
          sample$(fromAnimationFrame$()),
          startWith$(void 0),
          mapTo$(element))),
      map$((element) => ({ top: element.scrollTop, bottom: element.scrollTop + element.clientHeight })));

    const dimensions$ = this.dimensions$$.pipe(
      filter$(Boolean));

    this.visibleItemsRange = dimensions$.pipe(
      switchMap$(({ itemHeight }) =>
        this.visibleRange.pipe(
          // get rid of anchor offset height
          map$(({ top, bottom }) => {
            const collapseHeight = top > this.containerTopOffset ? this.containerTopOffset : top;
            return {
              top: top - collapseHeight,
              bottom: bottom - collapseHeight,
            };
          }),
          // map to range of items
          map$(({ top, bottom }) => ({
            start: Math.floor(top / itemHeight),
            end: Math.ceil(bottom / itemHeight),
          })))),
      tap$({
        next: ({ start }) => {
          if (!this.dimensionsHaveChanged) {
            this._topRowIndex = start;
          }
        },
        complete: () => {
          this._topRowIndex = -1;
        },
      }));

    this.viewChange = dimensions$.pipe(switchMap$(({ loadThresholdCount, pageSize }) =>
      this.visibleItemsRange.pipe(
        // map to range of items with threshold to load more items
        map$(({ start, end }) => ({
          start: Math.max(0, start - loadThresholdCount),
          end: end + loadThresholdCount,
        })),
        // if we need to load more items
        filter$(({ start, end }) => !(start >= this.lastRequestedStart && end <= this.lastRequestedEnd)),
        // round range to page size
        map$(({ start, end }) => ({
          start: Math.floor(start / pageSize) * pageSize,
          end: Math.ceil(end / pageSize) * pageSize,
        })),
        // update loaded range bounds
        tap$(({ start, end }) => {
          this.lastRequestedStart = start;
          this.lastRequestedEnd = end;
        }),
        // reset loaded range bounds when stream completes/error/unsubscribe
        finalize$(() => {
          this.lastRequestedStart = Number.MAX_SAFE_INTEGER;
          this.lastRequestedEnd = 0;
        }))));
  }

  ngOnDestroy(): void {
    this.dimensions$$.complete();
    this.viewportElement$$.complete();
  }

  ngDoCheck(): void {
    // apply viewport changes
    if (this.viewportHasChanged) {
      this.updateViewportElement();
    }

    // apply dimension changes
    if (this.dimensionsHaveChanged || this.viewportHasChanged) {
      this.dimensions$$.next(this.nextDimensions);
      this.containerElement = this.nextContainerElement;
      this.updateContainerOffset();

      this.nextDimensions = void 0;
      this.nextContainerElement = void 0;

      // restore scroll position
      const viewportElement = this.viewportElement$$.value;
      const dimensions = this.dimensions$$.value;
      if (dimensions) {
        viewportElement.scrollTop = this._topRowIndex === 0
          ? 0
          : this.containerTopOffset + this._topRowIndex * dimensions.itemHeight;
      } else {
        this._rowCount = 0;
        this._topRowIndex = -1;
        this.lastRequestedStart = Number.MAX_SAFE_INTEGER;
        this.lastRequestedEnd = 0;
      }
    }

    if (this.dimensionsHaveChanged || this.viewportHasChanged) {
      this.viewportHasChanged = false;
      this.dimensionsHaveChanged = false;
    }
  }

  scrollToTop(): void {
    this.viewportElement$$.value.scrollTop = 0;
  }

  setDimensions(container: HTMLElement, dimensions: ExtInfiniteScrollDimensions): void {
    if (this.dimensions$$.value && this.nextDimensions) {
      throw new Error('ExtInfiniteScrollViewportDirective: try to set dimensions when it is already set');
    }
    // we postpone changes until changes are not propagated
    this.dimensionsHaveChanged = true;
    this.nextDimensions = dimensions;
    this.nextContainerElement = container;
  }

  resetDimensions(container: HTMLElement, dimensions: ExtInfiniteScrollDimensions): void {
    if (this.dimensions$$.value === dimensions) {
      // we postpone changes until changes are not propagated
      this.dimensionsHaveChanged = true;
      this.nextDimensions = void 0;
      this.nextContainerElement = void 0;
    } else {
      throw new Error('ExtInfiniteScrollViewportDirective: try to reset wrong dimensions');
    }
  }

  updateRowCount(requestedCount: number, loadedCount: number, firstRowIndex: number): void {
    let newRowCount = this._rowCount;
    if (requestedCount === loadedCount) {
      newRowCount = Math.max(this._rowCount, firstRowIndex + loadedCount);
    } else if (requestedCount > loadedCount) {
      newRowCount = firstRowIndex + loadedCount;
    }

    if (newRowCount !== this._rowCount) {
      this._rowCount = newRowCount;
    }
  }

  private updateViewportElement(): void {
    const viewport = this._viewport;
    if (viewport === 'root') {
      this.viewportElement$$.next(document.documentElement!); // tslint:disable-line:no-non-null-assertion
    } else if (viewport instanceof HTMLElement) {
      this.viewportElement$$.next(viewport);
    } else {
      this.viewportElement$$.next(this.elementRef.nativeElement);
    }
  }

  private updateContainerOffset(): void {
    const viewport = this._viewport;
    if (this.containerElement) {
      if (viewport === 'root') {
        this.containerTopOffset = domOffset(this.containerElement).top;
      } else if (viewport instanceof HTMLElement) {
        throw new Error('ExtInfiniteScrollViewportDirective: anchor as HTMLElement is not supported yet');
      } else {
        this.containerTopOffset = domOffset(this.containerElement).top - domOffset(this.elementRef.nativeElement).top;
      }
    } else {
      this.containerTopOffset = 0;
    }
  }
}
