/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[ghLocal],[ghLocalOf]',
})
export class LocalDirective implements OnInit {
  private initialized: boolean = false;
  private _local: any;
  private viewRef: EmbeddedViewRef<any>;

  constructor(private viewContainerRef: ViewContainerRef,
              private changeDetectorRef: ChangeDetectorRef,
              private templateRef: TemplateRef<Object>) {
  }

  @Input('ghLocalOf')
  set local(value: any) {
    if (this.initialized) {
      this.viewRef.context.$implicit = value;
      this.changeDetectorRef.markForCheck();
    } else {
      this._local = value;
    }
  }

  ngOnInit(): void {
    this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, { $implicit: this._local });
    this._local = void 0;
    this.initialized = true;
  }
}
