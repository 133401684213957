/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { MessageHttpErrorInterceptor } from './message-http-error-interceptor';
import { MessageHttpResponseInterceptor } from './message-http-response-interceptor';
import { MessagePushService } from './message-push.service';
import * as i0 from "@angular/core";
var CoreMessagesModule = /** @class */ (function () {
    function CoreMessagesModule() {
    }
    CoreMessagesModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreMessagesModule });
    CoreMessagesModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreMessagesModule_Factory(t) { return new (t || CoreMessagesModule)(); }, providers: [MessagePushService, MessageHttpResponseInterceptor, MessageHttpErrorInterceptor] });
    return CoreMessagesModule;
}());
export { CoreMessagesModule };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreMessagesModule, [{
        type: NgModule,
        args: [{
                providers: [MessagePushService, MessageHttpResponseInterceptor, MessageHttpErrorInterceptor],
            }]
    }], null, null); })();
