/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { ExtRootLayout } from '..';

@Component({
  selector: 'gh-ext-page',
  template: '<ng-content></ng-content>',
  styles: [`
      :host {
          flex: 1;
          display: flex;
          flex-direction: column;
      }`],
})
export class ExtPageComponent {
}

@Component({
  selector: 'gh-ext-page-content',
  template: '<ng-content></ng-content>',
  styles: [`
      :host {
          position: relative;
          flex: 1;
          display: flex;
          flex-direction: column;
          min-height: 100%;
      }`],
})
export class ExtPageContentComponent {
}

@Directive({
  selector: 'gh-ext-page-title', // tslint:disable-line:directive-selector
})
export class ExtPageTitleDirective implements OnInit, OnDestroy {
  @Input() title: string;

  constructor(private rootLayout: ExtRootLayout) {
  }

  ngOnInit(): void {
    this.rootLayout.setTitle(this.title);
  }

  ngOnDestroy(): void {
    this.rootLayout.resetTitle();
  }
}
