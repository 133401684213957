/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogHooks } from '@gh/core-ui';
import { ApplicationComponentState } from './application-component-state.service';

@Injectable()
export class ApplicationDialogHooks implements DialogHooks {

  constructor(private componentState: ApplicationComponentState) {
  }

  open(dialog: MatDialogRef<any>): void {
    this.componentState.activate(dialog.componentInstance);
  }

  close(dialog: MatDialogRef<any>): void {
    this.componentState.deactivate(dialog.componentInstance);
  }
}
