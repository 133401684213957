/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import * as invariant from 'invariant';

import { isString } from 'lodash';

export function isMessage(value: any): value is Message {
  return value instanceof Message;
}

export enum MessageSeverity {
  Success = 1,
  Info = 2,
  Warning = 3,
  Error = 4,
  Confirmation = 5,
}

export function severityToString(severity: MessageSeverity): string {
  switch (severity) {
    case MessageSeverity.Success:
      return 'success';
    case MessageSeverity.Info:
      return 'info';
    case MessageSeverity.Warning:
      return 'warning';
    case MessageSeverity.Error:
      return 'error';
    case MessageSeverity.Confirmation:
      return 'confirmation';
    default:
      throw new Error(`Wrong severity value: '${severity}'`);
  }
}

export function stringToSeverity(severity: string): MessageSeverity {
  switch (severity.toLowerCase()) {
    case 'success':
      return MessageSeverity.Success;
    case 'info':
      return MessageSeverity.Info;
    case 'warning':
      return MessageSeverity.Warning;
    case 'error':
      return MessageSeverity.Error;
    case 'confirmation':
      return MessageSeverity.Confirmation;
    default:
      throw new Error(`Wrong severity string value: '${severity}'`);
  }
}

export class Message {
  static create(key: string, severity: MessageSeverity | string, params?: Hash<any>): Message {
    if (isString(severity)) {
      return new Message(key, stringToSeverity(severity), params);
    } else {
      return new Message(key, severity, params);
    }
  }

  static success(key: string, params?: Hash<any>): Message {
    return Message.create(key, MessageSeverity.Success, params);
  }

  static info(key: string, params?: Hash<any>): Message {
    return Message.create(key, MessageSeverity.Info, params);
  }

  static warning(key: string, params?: Hash<any>): Message {
    return Message.create(key, MessageSeverity.Warning, params);
  }

  static error(key: string, params?: Hash<any>): Message {
    return Message.create(key, MessageSeverity.Error, params);
  }

  static confirmation(key: string, params?: Hash<any>): Message {
    return Message.create(key, MessageSeverity.Confirmation, params);
  }

  constructor(
    public key: string,
    public severity: MessageSeverity,
    public params: Hash<any> = {},
  ) {
    invariant(key, 'Message key cannot be empty value');
    invariant(severity, 'Message severity cannot be empty value');
  }
}
