/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { ID } from '@gh/common-shared-data';
import { createEnum, EnumSet } from '@gh/core-data';

export type EDocumentListItemDto = {
  id: ID;
  externalId: string;
  sentDate: Date;
  documents: string;
  status: EDocumentStatus;
  embeddedSigningUrl: string;
  viewUrl?: string;
  isSigned: boolean;
};

export const EDocumentStatus = createEnum('E_DOCUMENT_STATUS', {
  Sent: 'SENT',
  Signed: 'SIGNED',
  Cancelled: 'CANCELLED',
  Failed: 'FAILED',
});
export type EDocumentStatus = EnumSet<typeof EDocumentStatus>;
