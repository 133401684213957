/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:rule variable-name

import { ID } from '@gh/common-shared-data';
import { createEnum, EnumSet } from '@gh/core-data';

export type FinancialRecordDto = {
  invoicedTotal: number;
  paidTotal: number;
  invoices: FinancialInvoiceListItemDto[];
};

export type FinancialInvoiceListItemDto = {
  amount: number;
  amountWithGst: number;
  invoiceNumber: number;
  seqNumber?: number;
  accountingDate: Date;
  variationId: ID;
  drawRuleStage?: number;
  drawRuleId: ID;
  drawRuleName: string;
  invoiceId: ID;
  type: InvoiceType;
  description: string;
  balance: number;
  externalId?: ID;
  xeroUrl: string;
  payments: FinancialInvoicePaymentDto[];
  invoiceType: InvoiceType;
  status: InvoiceStatus;
  dueDate: Date;
  paymentsTotal: number;
  isNotOpening: boolean;
};

export type FinancialInvoicePaymentDto = {
  invoiceId: ID;
  paymentId: ID;
  paidDate: Date;
  amount: number;
  reference: string;
};

export enum InvoiceStatus {
  Draft = 1,
  Submitted = 2,
  Authorized = 3,
  Deleted = 4,
  Voided = 5,
  Paid = 6,
}

export const InvoiceType = createEnum('INVOICE_TYPE', {
  Draw: 1,
  ChangeOrder: 2,
  CreditNote: 3,
});
export type InvoiceType = EnumSet<typeof InvoiceType>;
