/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'gh-autocomplete-action-icon',
  templateUrl: 'autocomplete-action-icon.component.html',
  styleUrls: ['autocomplete-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteActionIconComponent {
  @Output() trigger: EventEmitter<any> = new EventEmitter();

  disabled: boolean = false;
  display: boolean = false;

  constructor(public _element: ElementRef,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  @HostListener('click', ['$event'])
  handleClick($event: any): void {
    if (this._element.nativeElement.parentElement.firstElementChild.disabled) {
      return;
    }

    $event.stopPropagation();
    $event.preventDefault();
    this.display = !this.display;
    this.trigger.emit($event);
    this.changeDetectorRef.markForCheck();
  }

  setDisplay(isDisplay: boolean): void {
    this.display = isDisplay;
    this.changeDetectorRef.markForCheck();
  }

  setDisabled(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
}
