/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreMlsModule } from '@gh/core-mls';
import { CoreUiCommonModule } from '../common';
import { DIALOG_HOOKS, DIALOG_STANDARD_SET, DialogService, DialogServiceState, } from './services/dialog.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as i0 from "@angular/core";
var CoreUiDialogsModule = /** @class */ (function () {
    function CoreUiDialogsModule() {
    }
    CoreUiDialogsModule.forRoot = function (config) {
        return {
            ngModule: CoreUiDialogsModule,
            providers: [
                config.dialogHooks ? [{ provide: DIALOG_HOOKS, useExisting: config.dialogHooks }] : [],
                config.standardSet ? [{ provide: DIALOG_STANDARD_SET, useValue: config.standardSet }] : [],
                DialogServiceState,
                DialogService,
            ],
        };
    };
    CoreUiDialogsModule.forChild = function () {
        return {
            ngModule: CoreUiDialogsModule,
            providers: [
                // each lazy routed module has own instance of DialogService
                // this is necessary, because dialog service requests other services directly via Injector
                DialogService,
            ],
        };
    };
    CoreUiDialogsModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreUiDialogsModule });
    CoreUiDialogsModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreUiDialogsModule_Factory(t) { return new (t || CoreUiDialogsModule)(); }, imports: [[
                CommonModule,
                MatIconModule,
                MatButtonModule,
                MatInputModule,
                MatFormFieldModule,
                MatTooltipModule,
                CoreMlsModule,
                CoreUiCommonModule,
            ]] });
    return CoreUiDialogsModule;
}());
export { CoreUiDialogsModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreUiDialogsModule, { imports: [CommonModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        CoreMlsModule,
        CoreUiCommonModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreUiDialogsModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                    MatIconModule,
                    MatButtonModule,
                    MatInputModule,
                    MatFormFieldModule,
                    MatTooltipModule,
                    CoreMlsModule,
                    CoreUiCommonModule,
                ],
            }]
    }], null, null); })();
