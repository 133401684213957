/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, ElementRef, Inject, Input } from '@angular/core';
import { EXT_INFINITE_SCROLL_DIMENSIONS } from '../ext-infinite-scroll.interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var ExtInfiniteListItemComponent = /** @class */ (function () {
    function ExtInfiniteListItemComponent(elementRef, dimensions) {
        this.elementRef = elementRef;
        this.dimensions = dimensions;
    }
    Object.defineProperty(ExtInfiniteListItemComponent.prototype, "index", {
        get: function () {
            return this._index;
        },
        set: function (index) {
            var top = this.dimensions.offsetTop + this.dimensions.itemHeight * index;
            this.elementRef.nativeElement.style.top = top + "px";
            this._index = index;
        },
        enumerable: true,
        configurable: true
    });
    ExtInfiniteListItemComponent.prototype.ngOnInit = function () {
        this.context = {
            $implicit: this.data,
            index: this._index,
        };
    };
    ExtInfiniteListItemComponent.ɵfac = function ExtInfiniteListItemComponent_Factory(t) { return new (t || ExtInfiniteListItemComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(EXT_INFINITE_SCROLL_DIMENSIONS)); };
    ExtInfiniteListItemComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtInfiniteListItemComponent, selectors: [["gh-ext-infinite-list-item"]], inputs: { index: "index" }, decls: 1, vars: 2, consts: [[3, "ngTemplateOutlet", "ngTemplateOutletContext"]], template: function ExtInfiniteListItemComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementContainer(0, 0);
        } if (rf & 2) {
            i0.ɵɵproperty("ngTemplateOutlet", ctx.template)("ngTemplateOutletContext", ctx.context);
        } }, directives: [i1.NgTemplateOutlet], styles: ["[_nghost-%COMP%] {\n  position: absolute;\n  left: 0;\n  right: 0; }"] });
    return ExtInfiniteListItemComponent;
}());
export { ExtInfiniteListItemComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtInfiniteListItemComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-infinite-list-item',
                template: "\n    <ng-container [ngTemplateOutlet]=\"template\"\n                  [ngTemplateOutletContext]=\"context\"></ng-container>",
                styleUrls: ['ext-infinite-list-item.component.scss'],
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: undefined, decorators: [{
                type: Inject,
                args: [EXT_INFINITE_SCROLL_DIMENSIONS]
            }] }]; }, { index: [{
            type: Input
        }] }); })();
