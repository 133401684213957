var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '@gh/core-mls';
import { MessageDialogBaseComponent } from '@gh/core-ui';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../ext-dialog/ext-dialog.component";
import * as i4 from "../ext-dialog/ext-dialog-content.component";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/material/icon";
import * as i7 from "@angular/flex-layout/extended";
import * as i8 from "@angular/common";
import * as i9 from "../ext-dialog/ext-dialog-button-bar.component";
import * as i10 from "@angular/material/button";
import * as i11 from "../../../core-mls/translation/translate.pipe";
var ExtMessageDialogComponent = /** @class */ (function (_super) {
    __extends(ExtMessageDialogComponent, _super);
    function ExtMessageDialogComponent(translationService, dialogRef, elementRef, renderer) {
        return _super.call(this, translationService, dialogRef, elementRef, renderer) || this;
    }
    Object.defineProperty(ExtMessageDialogComponent.prototype, "iconName", {
        get: function () {
            if (this.typeSuccess) {
                return 'done';
            }
            else if (this.typeErrorOrWarning) {
                return 'error';
            }
            else {
                return 'info-outline';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtMessageDialogComponent.prototype, "iconColorName", {
        get: function () {
            if (this.typeSuccess) {
                return 'success';
            }
            else if (this.typeErrorOrWarning) {
                return 'error';
            }
            else {
                return 'info';
            }
        },
        enumerable: true,
        configurable: true
    });
    ExtMessageDialogComponent.ɵfac = function ExtMessageDialogComponent_Factory(t) { return new (t || ExtMessageDialogComponent)(i0.ɵɵdirectiveInject(i1.TranslationService), i0.ɵɵdirectiveInject(i2.MatDialogRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2)); };
    ExtMessageDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtMessageDialogComponent, selectors: [["gh-ext-message-dialog"]], features: [i0.ɵɵInheritDefinitionFeature], decls: 9, vars: 6, consts: [["fxLayout", "column", "fxLayoutAlign", "start center"], ["ngClass.lt-md", "s150", "ngClass.gt-sm", "s200", 1, "m-icon", 3, "svgIcon", "ngClass"], [1, "m-message"], ["align", "center", 1, "button-bar", "clearfix"], ["mat-raised-button", "", "color", "primary", 1, "large", 3, "click"]], template: function ExtMessageDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "gh-ext-dialog");
            i0.ɵɵelementStart(1, "gh-ext-dialog-content", 0);
            i0.ɵɵelement(2, "mat-icon", 1);
            i0.ɵɵelementStart(3, "span", 2);
            i0.ɵɵtext(4);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "gh-ext-dialog-button-bar", 3);
            i0.ɵɵelementStart(6, "button", 4);
            i0.ɵɵlistener("click", function ExtMessageDialogComponent_Template_button_click_6_listener() { return ctx.dialogRef.close(); });
            i0.ɵɵtext(7);
            i0.ɵɵpipe(8, "translate");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(2);
            i0.ɵɵpropertyInterpolate1("svgIcon", "gh:", ctx.iconName, "");
            i0.ɵɵpropertyInterpolate1("ngClass", "m-icon-", ctx.iconColorName, "");
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate(ctx.message);
            i0.ɵɵadvance(3);
            i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(8, 4, "lbl_ok"));
        } }, directives: [i3.ExtDialogComponent, i4.ExtDialogContentComponent, i5.DefaultLayoutDirective, i5.DefaultLayoutAlignDirective, i6.MatIcon, i7.DefaultClassDirective, i8.NgClass, i9.ExtDialogButtonBarComponent, i10.MatButton], pipes: [i11.TranslatePipe], styles: ["[_nghost-%COMP%] {\n  overflow: hidden;\n  display: flex;\n  flex-direction: column; }\n  @media (max-width: 959px) {\n    [_nghost-%COMP%] {\n      width: 100vw;\n      min-height: 100vh; } }\n  @media (min-width: 960px) {\n    [_nghost-%COMP%] {\n      min-width: 650px;\n      min-height: auto; } }\n\n.m-icon[_ngcontent-%COMP%] {\n  color: rgba(112, 112, 112, 0.54); }\n\n@media (min-width: 960px) {\n  .m-icon[_ngcontent-%COMP%] {\n    margin: 70px 0; } }\n\n@media (max-width: 959px) {\n  .m-icon[_ngcontent-%COMP%] {\n    margin: 40px 0; } }\n\n.m-message[_ngcontent-%COMP%] {\n  padding: 0 30px;\n  margin-bottom: 75px;\n  text-align: center;\n  font-size: 24px;\n  font-weight: bold; }\n\n.m-icon-success[_ngcontent-%COMP%] {\n  color: green; }\n\n.m-icon-error[_ngcontent-%COMP%] {\n  color: #b7022f; }\n\n.m-icon-info[_ngcontent-%COMP%] {\n  color: rgba(112, 112, 112, 0.54); }\n\n@media (max-height: 500px) {\n  gh-ext-dialog-content[_ngcontent-%COMP%] {\n    flex: 1; }\n  .m-icon[_ngcontent-%COMP%] {\n    margin: 15px 0 0; }\n  .m-message[_ngcontent-%COMP%] {\n    margin-bottom: 15px; } }"] });
    return ExtMessageDialogComponent;
}(MessageDialogBaseComponent));
export { ExtMessageDialogComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtMessageDialogComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-message-dialog',
                templateUrl: 'ext-message-dialog.component.html',
                styleUrls: ['ext-message-dialog.component.scss'],
            }]
    }], function () { return [{ type: i1.TranslationService }, { type: i2.MatDialogRef }, { type: i0.ElementRef }, { type: i0.Renderer2 }]; }, null); })();
