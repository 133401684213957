/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { normalizePermission } from '@gh/core-auth';
import { UserContext } from '../user/user-context';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(protected router: Router,
              protected userContext: UserContext) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const canNavigate = this.verifyRoute(route, state);
    if (!canNavigate) {
      this.router.navigateByUrl('/404');
      return false;
    } else {
      return true;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const { parent } = childRoute;

    const canNavigate = parent ? this.verifyRoute(parent, state) : true;
    if (!canNavigate) {
      this.router.navigateByUrl('/404');
      return false;
    } else {
      return true;
    }
  }

  protected verifyRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permission = route.data['permission'];

    return this.verifyEntity(permission, (p) => this.userContext.hasPermission(normalizePermission(p)));
  }

  protected verifyEntity(entity: any | any[], fn: (entity: any) => boolean): boolean {
    if (Array.isArray(entity)) {
      if (entity.length > 0) {
        return entity.some(fn);
      } else {
        return true;
      }
    } else if (entity) {
      return fn(entity);
    } else {
      return true;
    }
  }
}
