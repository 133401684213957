/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { NgModule } from '@angular/core';

@NgModule()
export class ErrorModule {
  constructor() {
    throw new Error('Angular configuration is wrong. Error module should never come into Angular Configuration');
  }
}

@NgModule()
export class NoopModule {
}
