/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, HostBinding, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialogRef } from '@angular/material/dialog';
import { filter$ } from '@gh/rx/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/flex-layout";
var _c0 = ["*"];
var ExtDialogComponent = /** @class */ (function () {
    function ExtDialogComponent(dialogRef, observer) {
        this.dialogRef = dialogRef;
        this.observer = observer;
    }
    Object.defineProperty(ExtDialogComponent.prototype, "forBreakpoint", {
        set: function (value) {
            this._forBreakpoint = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtDialogComponent.prototype, "layoutClass", {
        get: function () {
            return "m-dialog-layout-" + this.layout;
        },
        enumerable: true,
        configurable: true
    });
    ExtDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this._forBreakpoint) {
            this.mediaSubscription = this.observer
                .media$.pipe(filter$(function () { return !_this.observer.isActive(_this._forBreakpoint); }))
                .subscribe(function () { return _this.dialogRef.close(); });
        }
    };
    ExtDialogComponent.prototype.ngOnDestroy = function () {
        if (this.mediaSubscription) {
            this.mediaSubscription.unsubscribe();
        }
    };
    ExtDialogComponent.ɵfac = function ExtDialogComponent_Factory(t) { return new (t || ExtDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(i2.MediaObserver)); };
    ExtDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtDialogComponent, selectors: [["gh-ext-dialog"]], hostVars: 2, hostBindings: function ExtDialogComponent_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵclassMap(ctx.layoutClass);
        } }, inputs: { layout: "layout", forBreakpoint: "forBreakpoint" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function ExtDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵprojection(0);
        } }, styles: ["[_nghost-%COMP%] {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  border-radius: 10px;\n  background: white;\n  box-shadow: rgba(0, 0, 0, 0.16); }\n\n@media (min-width: 960px) {\n    gh-ext-dialog-content {\n    flex: 1; } }\n\n.m-dialog-layout-center[_nghost-%COMP%]     gh-ext-dialog-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center; }\n\n.m-dialog-layout-text[_nghost-%COMP%]     gh-ext-dialog-content {\n  padding: 15px 10px; }"] });
    return ExtDialogComponent;
}());
export { ExtDialogComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtDialogComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-dialog',
                template: '<ng-content></ng-content>',
                styleUrls: ['ext-dialog.component.scss'],
            }]
    }], function () { return [{ type: i1.MatDialogRef }, { type: i2.MediaObserver }]; }, { layout: [{
            type: Input
        }], forBreakpoint: [{
            type: Input
        }], layoutClass: [{
            type: HostBinding,
            args: ['class']
        }] }); })();
