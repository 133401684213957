/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AuthState } from '@gh/core-auth';
import { EMPTY$, of$ } from '@gh/rx';
import { distinctUntilChanged$, filter$, first$, map$ } from '@gh/rx/operators';
import { noop } from 'lodash';
import { UserLoginStatus } from './common-user-profile-store.service';
import { UserContext } from './user-context';
var CommonUserContextService = /** @class */ (function (_super) {
    __extends(CommonUserContextService, _super);
    function CommonUserContextService(authService, commonStore) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.commonStore = commonStore;
        _this.user$ = _this.commonStore.user$;
        _this.user$.subscribe(function (user) { return _this.updateUserData(user); }, noop);
        _this.commonStore.status$.pipe(filter$(function (status) { return status === UserLoginStatus.LoggedOut; }))
            .subscribe(function () {
            _this.authService.logout();
        });
        return _this;
    }
    Object.defineProperty(CommonUserContextService.prototype, "authState", {
        get: function () {
            return this.authService.authState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUserContextService.prototype, "onAuthState", {
        get: function () {
            return this.authService.onAuthState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUserContextService.prototype, "data", {
        get: function () {
            if (!this.user) {
                return this.userNotInitialized();
            }
            return this.user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUserContextService.prototype, "onData", {
        get: function () {
            return this.user$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUserContextService.prototype, "user", {
        get: function () {
            return this.commonStore.user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUserContextService.prototype, "locale", {
        get: function () {
            return this.data.locale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonUserContextService.prototype, "baseUrl", {
        get: function () {
            return "" + location.origin + this.initParams['baseUrl'];
        },
        enumerable: true,
        configurable: true
    });
    CommonUserContextService.prototype.isInitialized = function () {
        return this.commonStore.initialized;
    };
    CommonUserContextService.prototype.init = function (params) {
        var _this = this;
        if (this.user) {
            return of$(this.user);
        }
        this.initParams = params;
        this.setupHttpHeaders(params);
        var authState$ = this.authService.onAuthState.pipe(distinctUntilChanged$());
        var loggedIn$ = authState$.pipe(first$(function (state) { return state === AuthState.LoggedIn; }));
        loggedIn$.subscribe(function () {
            _this.commonStore.load(params);
        });
        return this.user$.pipe(first$());
    };
    CommonUserContextService.prototype.logout = function () {
        this.commonStore.logout();
        return this.commonStore.status$.pipe(first$(), map$(function (status) { return status === UserLoginStatus.LoggedOut; }));
    };
    CommonUserContextService.prototype.changePassword = function () {
        window.location.href = 'https://myaccount.google.com/security';
        return EMPTY$;
    };
    CommonUserContextService.prototype.hasPermission = function (permission) {
        if (!this.user) {
            return this.userNotInitialized();
        }
        return this.authService.hasPermission(permission);
    };
    CommonUserContextService.prototype.changeLocale = function (locale) {
        this.commonStore.updateLocale(locale).subscribe(function () { return location.reload(); });
    };
    CommonUserContextService.prototype.getInitParam = function (name) {
        return this.initParams[name];
    };
    CommonUserContextService.prototype.checkUserInitialized = function () {
        if (!this.user) {
            return this.userNotInitialized();
        }
    };
    CommonUserContextService.prototype.userNotInitialized = function () {
        throw new Error('UserContext is still not initialized');
    };
    return CommonUserContextService;
}(UserContext));
export { CommonUserContextService };
