/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { getRouteParam, InitViewService } from '@gh/common-shared';

import { ID } from '@gh/common-shared-data';
import { SlicedDataSource } from '@gh/core-data';
import { CustomerSharedDocumentRestService } from '@gh/customer-shared-data';

import { SharedDocumentListItem } from './customer-shared-document.model';

@Injectable()
export class CustomerSharedDocumentBusinessService implements InitViewService {
  constructor(private restService: CustomerSharedDocumentRestService) {
  }

  initView(route: ActivatedRouteSnapshot): any {
    const jobId = getRouteParam(route, 'jobId');
    return {
      sharedDocumentDataSource: this.findAll(jobId),
    };
  }

  findAll(jobId: ID): SlicedDataSource<SharedDocumentListItem> {
    return this.restService.findAll(jobId);
  }
}
