/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-console

export const logWarning = (message: string, ...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn.apply(console, [message].concat(args));
  }
};

export const logError = (message: string, ...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.error.apply(console, [message].concat(args));
  }
};

export const logDebug = (message: string, ...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.debug.apply(console, [message].concat(args));
  }
};
