<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<div class="m-header" fxHide="" fxShow.lt-md="">
  <button class="m-close-btn" mat-icon-button="" color="primary" matTooltip="{{'lbl_close' | translate}}" (click)="onClose()">
    <mat-icon svgIcon="gh:close"></mat-icon>
  </button>
  <img class="m-logo" src="/ef05a4176a1a27c00c8e389eff1cc5c3.gif">
  <div class="ext-divider"></div>
</div>
<button *ngIf="closableOnDesktop" fxHide.lt-md="" class="m-close-btn" mat-icon-button="" color="primary" matTooltip="{{'lbl_close' | translate}}" (click)="onClose()">
  <mat-icon svgIcon="gh:close"></mat-icon>
</button>
<div *ngIf="title" class="m-title">{{ title }}</div>
