var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil, isString, noop } from 'lodash';
import { createTextMaskInputElement } from 'text-mask-core';
import { TypeService } from '../services/type.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/type.service";
/**
 * This module declares directive for masked input component. We had to define own implementation because it provides
 * better integration with masked type approach (core-type package)
 *
 * Implementation was taken from this repository https://github.com/text-mask/text-mask.
 * File: https://github.com/text-mask/text-mask/blob/master/angular2/src/angular2TextMask.ts
 */
var MaskedTypeDirective = /** @class */ (function () {
    function MaskedTypeDirective(renderer, element, typeService) {
        this.renderer = renderer;
        this.element = element;
        this.typeService = typeService;
        this.config = {
            guide: false,
            placeholderChar: '_',
            pipe: void 0,
            keepCharPositions: false,
            onReject: void 0,
            onAccept: void 0,
        };
        this._onChange = noop;
        this._onTouched = noop;
    }
    Object.defineProperty(MaskedTypeDirective.prototype, "type", {
        set: function (type) {
            this._type = isString(type) ? this.typeService.get(type) : type;
        },
        enumerable: true,
        configurable: true
    });
    MaskedTypeDirective.prototype.ngOnInit = function () {
        this.setupMask();
        if (this._type['maxLength']) {
            this.renderer.setAttribute(this.element.nativeElement, 'maxlength', String(this._type['maxLength']));
        }
    };
    MaskedTypeDirective.prototype.ngAfterViewInit = function () {
        if (!this.inputElement) {
            // the element was not found when ngOnInit ran, let's try to find it again
            this.setupMask();
        }
    };
    MaskedTypeDirective.prototype.onInput = function (str) {
        this.textMaskInputElement.update(str);
        var nextValue = this.element.nativeElement.value;
        // check against the last value to prevent firing ngModelChange despite no changes
        if (this.lastValue !== nextValue) {
            this.lastValue = nextValue;
            this._onChange(nextValue);
        }
    };
    MaskedTypeDirective.prototype.onTouched = function () {
        this._onTouched();
    };
    MaskedTypeDirective.prototype.writeValue = function (value) {
        if (!isNil(value) && this.textMaskInputElement !== void 0) {
            this.textMaskInputElement.update(value);
        }
        else {
            this.element.nativeElement.value = '';
        }
        this.lastValue = this.element.nativeElement.value;
    };
    MaskedTypeDirective.prototype.registerOnChange = function (fn) {
        this._onChange = fn;
    };
    MaskedTypeDirective.prototype.registerOnTouched = function (fn) {
        this._onTouched = fn;
    };
    MaskedTypeDirective.prototype.setDisabledState = function (isDisabled) {
        this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
    };
    MaskedTypeDirective.prototype.setupMask = function () {
        // tslint:disable-next-line:prefer-conditional-expression
        if (this.element.nativeElement.tagName === 'INPUT') {
            // `textMask` directive is used directly on an input element
            this.inputElement = this.element.nativeElement;
        }
        else {
            // `textMask` directive is used on an abstracted input element, `ion-input`, `mat-input`, etc
            this.inputElement = this.element.nativeElement.getElementsByTagName('INPUT')[0];
        }
        if (this.inputElement) {
            this.textMaskInputElement = createTextMaskInputElement(__assign(__assign({}, this.config), { inputElement: this.inputElement, mask: this._type.mask }));
        }
    };
    MaskedTypeDirective.ɵfac = function MaskedTypeDirective_Factory(t) { return new (t || MaskedTypeDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.TypeService)); };
    MaskedTypeDirective.ɵdir = i0.ɵɵdefineDirective({ type: MaskedTypeDirective, selectors: [["", "ghMaskedType", ""]], hostBindings: function MaskedTypeDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("input", function MaskedTypeDirective_input_HostBindingHandler($event) { return ctx.onInput($event.target.value); })("blur", function MaskedTypeDirective_blur_HostBindingHandler() { return ctx.onTouched(); });
        } }, inputs: { config: ["ghMaskedTypeConfig", "config"], type: ["ghMaskedType", "type"] }, features: [i0.ɵɵProvidersFeature([{
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: MaskedTypeDirective,
                    multi: true,
                }])] });
    return MaskedTypeDirective;
}());
export { MaskedTypeDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MaskedTypeDirective, [{
        type: Directive,
        args: [{
                selector: '[ghMaskedType]',
                providers: [{
                        provide: NG_VALUE_ACCESSOR,
                        useExisting: MaskedTypeDirective,
                        multi: true,
                    }],
            }]
    }], function () { return [{ type: i0.Renderer2 }, { type: i0.ElementRef }, { type: i1.TypeService }]; }, { config: [{
            type: Input,
            args: ['ghMaskedTypeConfig']
        }], type: [{
            type: Input,
            args: ['ghMaskedType']
        }], onInput: [{
            type: HostListener,
            args: ['input', ['$event.target.value']]
        }], onTouched: [{
            type: HostListener,
            args: ['blur']
        }] }); })();
