/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as i0 from "@angular/core";
var ConnectPipe = /** @class */ (function () {
    function ConnectPipe(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.async = new AsyncPipe(this.changeDetectorRef);
    }
    ConnectPipe.prototype.ngOnDestroy = function () {
        this.async.ngOnDestroy(); // tslint:disable-line:no-life-cycle-call
    };
    ConnectPipe.prototype.transform = function (dataSource) {
        if (!(dataSource instanceof MatTableDataSource)) {
            throw new Error('ConnectPipe: connect pipe works only with instance of MatTableDataSource');
        }
        return this.async.transform(dataSource.connect());
    };
    ConnectPipe.ɵfac = function ConnectPipe_Factory(t) { return new (t || ConnectPipe)(i0.ɵɵinjectPipeChangeDetectorRef()); };
    ConnectPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "connect", type: ConnectPipe, pure: false });
    return ConnectPipe;
}());
export { ConnectPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ConnectPipe, [{
        type: Pipe,
        args: [{
                name: 'connect',
                pure: false,
            }]
    }], function () { return [{ type: i0.ChangeDetectorRef }]; }, null); })();
