/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

import { isNil } from 'lodash';

/**
 * This pipe should be used for safe transforming of control to {@link FormGroup}
 */
@Pipe({ name: 'asFormGroup', pure: false }) // tslint:disable-line:pipe-impure
export class AsFormGroupPipe implements PipeTransform {

  transform(control: Nilable<AbstractControl>): Nilable<FormGroup> {
    if (isNil(control)) {
      return control;
    } else if (control instanceof FormGroup) {
      return control;
    } else {
      throw new Error('Provided control is not a form group');
    }
  }
}

/**
 * This pipe should be used for safe transforming of control to {@link FormArray}
 */
@Pipe({ name: 'asFormArray', pure: false }) // tslint:disable-line:pipe-impure
export class AsFormArrayPipe implements PipeTransform {

  transform(control: Nilable<AbstractControl>): Nilable<FormArray> {
    if (isNil(control)) {
      return control;
    } else if (control instanceof FormArray) {
      return control;
    } else {
      throw new Error('Provided control is not a form array');
    }
  }
}
