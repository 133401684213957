/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component } from '@angular/core';
import * as i0 from "@angular/core";
var _c0 = ["*"];
var ExtDialogContentComponent = /** @class */ (function () {
    function ExtDialogContentComponent() {
    }
    ExtDialogContentComponent.ɵfac = function ExtDialogContentComponent_Factory(t) { return new (t || ExtDialogContentComponent)(); };
    ExtDialogContentComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtDialogContentComponent, selectors: [["gh-ext-dialog-content"]], ngContentSelectors: _c0, decls: 1, vars: 0, template: function ExtDialogContentComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵprojection(0);
        } }, encapsulation: 2 });
    return ExtDialogContentComponent;
}());
export { ExtDialogContentComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtDialogContentComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-dialog-content',
                template: '<ng-content></ng-content>',
                styleUrls: [],
            }]
    }], null, null); })();
