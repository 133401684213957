/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild, } from '@angular/core';
import { NavigationEnd, Router, RouterLinkWithHref } from '@angular/router';
import { filter$ } from '@gh/rx/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/core";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/flex-layout/extended";
function ExtNavigationItemComponent_a_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 3);
    i0.ɵɵelement(1, "mat-icon", 4);
    i0.ɵɵelementStart(2, "span", 5);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("m-is-disabled", ctx_r0.disabled)("m-is-active", ctx_r0.isLinkActive);
    i0.ɵɵproperty("routerLink", ctx_r0.disabled ? undefined : ctx_r0.link);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("fxShow.md", ctx_r0.icon)("svgIcon", ctx_r0.icon);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("fxHide.md", ctx_r0.icon);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r0.title);
} }
function ExtNavigationItemComponent_span_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵelement(1, "mat-icon", 4);
    i0.ɵɵelementStart(2, "span", 5);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("fxShow.md", ctx_r1.icon)("svgIcon", ctx_r1.icon);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("fxHide.md", ctx_r1.icon);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r1.title);
} }
function ExtNavigationItemComponent_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
} }
var _c0 = ["*"];
var ExtNavigationItemComponent = /** @class */ (function () {
    function ExtNavigationItemComponent(router, cdr) {
        this.router = router;
        this.cdr = cdr;
        this.type = 'button';
        this.isLinkActive = false;
    }
    Object.defineProperty(ExtNavigationItemComponent.prototype, "link", {
        get: function () {
            return this._link;
        },
        set: function (link) {
            this._link = link;
            this.recalculateActiveLinkState();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtNavigationItemComponent.prototype, "isTypeLink", {
        get: function () {
            return this.type === 'link';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtNavigationItemComponent.prototype, "isTypeButton", {
        get: function () {
            return this.type === 'button';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtNavigationItemComponent.prototype, "isTypeOption", {
        get: function () {
            return this.type === 'option';
        },
        enumerable: true,
        configurable: true
    });
    ExtNavigationItemComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.isTypeLink) {
            this.recalculateActiveLinkState();
            this.subscription = this.router.events.pipe(filter$(function (event) { return event instanceof NavigationEnd; }))
                .subscribe(function () {
                _this.recalculateActiveLinkState();
            });
        }
    };
    ExtNavigationItemComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    ExtNavigationItemComponent.prototype.recalculateActiveLinkState = function () {
        var _this = this;
        if (this.isTypeLink && this.linkWithHref) {
            Promise.resolve().then(function () {
                _this.isLinkActive = !_this.disabled && _this.router.isActive(_this.linkWithHref.urlTree, false);
                _this.cdr.markForCheck();
            });
        }
    };
    ExtNavigationItemComponent.ɵfac = function ExtNavigationItemComponent_Factory(t) { return new (t || ExtNavigationItemComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
    ExtNavigationItemComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtNavigationItemComponent, selectors: [["gh-ext-navigation-item"]], viewQuery: function ExtNavigationItemComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(RouterLinkWithHref, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.linkWithHref = _t.first);
        } }, hostVars: 2, hostBindings: function ExtNavigationItemComponent_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵclassMap("m-type-" + ctx.type);
        } }, inputs: { type: "type", title: "title", icon: "icon", disabled: "disabled", link: "link" }, ngContentSelectors: _c0, decls: 3, vars: 3, consts: [["matRipple", "", "class", "m-text", 3, "m-is-disabled", "m-is-active", "routerLink", 4, "ngIf"], ["matRipple", "", "class", "m-text m-text-button", 4, "ngIf"], [4, "ngIf"], ["matRipple", "", 1, "m-text", 3, "routerLink"], ["fxHide", "", 3, "fxShow.md", "svgIcon"], ["fxShow", "", 3, "fxHide.md"], ["matRipple", "", 1, "m-text", "m-text-button"]], template: function ExtNavigationItemComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵtemplate(0, ExtNavigationItemComponent_a_0_Template, 4, 9, "a", 0);
            i0.ɵɵtemplate(1, ExtNavigationItemComponent_span_1_Template, 4, 4, "span", 1);
            i0.ɵɵtemplate(2, ExtNavigationItemComponent_span_2_Template, 2, 0, "span", 2);
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", ctx.isTypeLink);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.isTypeButton);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.isTypeOption);
        } }, directives: [i2.NgIf, i1.RouterLinkWithHref, i3.MatRipple, i4.MatIcon, i5.DefaultShowHideDirective], styles: ["@media (max-width: 959px) {\n    gh-ext-navigation-item + gh-ext-navigation-item {\n    margin-top: 4px; }\n  .m-type-option[_nghost-%COMP%] {\n    order: 1000;\n    padding-left: 50px; }\n  .m-text[_ngcontent-%COMP%] {\n    display: block;\n    padding: 7px 25px 7px 50px;\n    border-bottom-right-radius: 20px;\n    border-top-right-radius: 20px;\n    text-decoration: inherit;\n    cursor: pointer; } }\n\n@media (min-width: 960px) {\n    gh-ext-navigation-item + gh-ext-navigation-item {\n    margin-left: 4px; }\n  .m-text[_ngcontent-%COMP%] {\n    padding: 9px 18px;\n    border-radius: 5px; } }\n\n@media (min-width: 960px) and (max-width: 1279px) {\n  [_nghost-%COMP%] {\n    font-size: 16px; }\n  .m-text-button[_ngcontent-%COMP%] {\n    padding: 8px 18px 2px; } }\n\n@media (min-width: 1280px) {\n  .m-text-button[_ngcontent-%COMP%] {\n    padding: 8px 20px; } }\n\n[_nghost-%COMP%] {\n  display: block;\n  font-weight: 300;\n  line-height: 22px;\n  text-align: left; }\n\n.m-type-option[_nghost-%COMP%] {\n  display: flex;\n  flex-direction: row;\n  align-items: center; }\n\n.m-text[_ngcontent-%COMP%] {\n  display: block;\n  color: white;\n  text-decoration: inherit;\n  cursor: pointer;\n  white-space: nowrap; }\n\n.m-text[_ngcontent-%COMP%]:hover {\n  background: rgba(0, 0, 0, 0.12); }\n\n.m-text.m-is-active[_ngcontent-%COMP%] {\n  color: #b7022f;\n  background: white; }\n\n.m-text.m-is-disabled[_ngcontent-%COMP%] {\n  color: rgba(255, 247, 253, 0.5); }"], changeDetection: 0 });
    return ExtNavigationItemComponent;
}());
export { ExtNavigationItemComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtNavigationItemComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-navigation-item',
                templateUrl: 'ext-navigation-item.component.html',
                styleUrls: ['ext-navigation-item.component.scss'],
                // tslint:disable-next-line:use-host-property-decorator
                host: {
                    '[class]': '"m-type-" + type',
                },
                changeDetection: ChangeDetectionStrategy.OnPush,
            }]
    }], function () { return [{ type: i1.Router }, { type: i0.ChangeDetectorRef }]; }, { type: [{
            type: Input
        }], title: [{
            type: Input
        }], icon: [{
            type: Input
        }], disabled: [{
            type: Input
        }], linkWithHref: [{
            type: ViewChild,
            args: [RouterLinkWithHref, { static: false }]
        }], link: [{
            type: Input
        }] }); })();
