/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DataMapper } from './data-mapper';

export class ArrayDataMapper<ObjectView, SerializedView> implements DataMapper<ObjectView[], SerializedView[]> {

  constructor(private elementMapper: Maybe<DataMapper<ObjectView, SerializedView>>) {

  }

  serialize(obj: ObjectView[]): SerializedView[] {
    if (this.elementMapper) {
      const elementMapper = this.elementMapper;
      return obj.map((item) => elementMapper.serialize(item));
    }
    return <any>obj;
  }

  deserialize(json: SerializedView[]): ObjectView[] {
    if (this.elementMapper) {
      const elementMapper = this.elementMapper;
      return json.map((item) => elementMapper.deserialize(item));
    } else {
      return <any>json;
    }
  }
}
