/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import * as i0 from "@angular/core";
var CaptureEventDirective = /** @class */ (function () {
    function CaptureEventDirective(elementRef) {
        this.elementRef = elementRef;
        this.listener = new EventEmitter();
        this.onEvent = this.onEvent.bind(this);
    }
    CaptureEventDirective.prototype.ngOnInit = function () {
        this.elementRef.nativeElement.addEventListener(this.event, this.onEvent, true);
    };
    CaptureEventDirective.prototype.ngOnDestroy = function () {
        this.elementRef.nativeElement.removeEventListener(this.event, this.onEvent);
    };
    CaptureEventDirective.prototype.onEvent = function (event) {
        this.listener.emit(event);
    };
    CaptureEventDirective.ɵfac = function CaptureEventDirective_Factory(t) { return new (t || CaptureEventDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    CaptureEventDirective.ɵdir = i0.ɵɵdefineDirective({ type: CaptureEventDirective, selectors: [["", "ghCaptureEvent", ""]], inputs: { event: ["ghCaptureEvent", "event"] }, outputs: { listener: "ghCaptureEventListener" } });
    return CaptureEventDirective;
}());
export { CaptureEventDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CaptureEventDirective, [{
        type: Directive,
        args: [{
                selector: '[ghCaptureEvent]',
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { event: [{
            type: Input,
            args: ['ghCaptureEvent']
        }], listener: [{
            type: Output,
            args: ['ghCaptureEventListener']
        }] }); })();
