/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { DEFAULT_CURRENCY_TEMPLATE } from '@gh/config';
import { splitLikeNumber, substitute } from '@gh/core-util';
import * as i0 from "@angular/core";
var DefaultCurrencyPipe = /** @class */ (function () {
    function DefaultCurrencyPipe() {
    }
    DefaultCurrencyPipe.prototype.transform = function (price) {
        return price ? substitute(DEFAULT_CURRENCY_TEMPLATE, splitLikeNumber(price)) : '';
    };
    DefaultCurrencyPipe.ɵfac = function DefaultCurrencyPipe_Factory(t) { return new (t || DefaultCurrencyPipe)(); };
    DefaultCurrencyPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "defaultCurrency", type: DefaultCurrencyPipe, pure: true });
    return DefaultCurrencyPipe;
}());
export { DefaultCurrencyPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DefaultCurrencyPipe, [{
        type: Pipe,
        args: [{
                name: 'defaultCurrency',
                pure: true,
            }]
    }], null, null); })();
