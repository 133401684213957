/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { MessageBasket } from '@gh/core-messages';
import { EMPTY$, from$, of$, throwError$ } from '@gh/rx';
import { catchError$, filter$, first$, mapTo$, switchMap$ } from '@gh/rx/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { GoogleApiLoader } from './google-api-loader.service';

enum GoogleAuthStatus { Unknown, Initializing, Initialized, Error}

export interface ExternalGoogleConfig extends gapi.auth2.ClientConfig {
}

export const GOOGLE_CONFIG = new InjectionToken<ExternalGoogleConfig>('ExternalGoogleConfig');

const handleErrors = ({ error }: any) => {
  if (error === 'popup_closed_by_user') {
    return EMPTY$;
  } else {
    return throwError$(MessageBasket.error(`msg_google_auth:${error}`));
  }
};

@Injectable()
export class GoogleAuthService {
  private status$$ = new BehaviorSubject(GoogleAuthStatus.Unknown);

  constructor(private loader: GoogleApiLoader,
              @Inject(GOOGLE_CONFIG) private googleConfig: ExternalGoogleConfig) {

  }

  get accessToken(): string {
    const auth = gapi.auth2.getAuthInstance();

    if (!auth.isSignedIn.get()) {
      throw new Error(`GoogleAuthService: User is not signed into the Google`);
    }

    return auth.currentUser.get().getAuthResponse().access_token;
  }

  signIn(options?: gapi.auth2.SigninOptions): Observable<gapi.auth2.GoogleUser> {
    return this.init().pipe(
      switchMap$(() => {
        const auth = gapi.auth2.getAuthInstance();

        if (auth.isSignedIn.get()) {
          return of$(auth.currentUser.get());
        } else {
          return from$(auth.signIn(options));
        }
      }),
      catchError$(handleErrors));
  }

  grant(options?: gapi.auth2.SigninOptions): Observable<gapi.auth2.GoogleUser> {
    return <Observable<any>>this.init().pipe(
      switchMap$(() => {
        const auth = gapi.auth2.getAuthInstance();

        return from$(auth.currentUser.get().grant(options));
      }),
      catchError$(handleErrors));
  }

  signInOrGrant(options?: gapi.auth2.SigninOptions): Observable<gapi.auth2.GoogleUser> {
    const auth = gapi.auth2.getAuthInstance();

    if (auth.isSignedIn.get()) {
      return this.signIn(options);
    } else {
      return this.grant(options);
    }
  }

  init(): Observable<boolean> {
    switch (this.status$$.value) {
      case GoogleAuthStatus.Initialized:
        return of$(true);
      case GoogleAuthStatus.Initializing:
        return this.status$$.pipe(filter$((status) => status === GoogleAuthStatus.Initialized), mapTo$(true), first$());
      default:
        this.startInit();
        return this.status$$.pipe(filter$((status) => status === GoogleAuthStatus.Initialized), mapTo$(true), first$());
    }
  }

  grantOfflineAccess(): Observable<string> {
    return this.init().pipe(switchMap$(() => {
      const auth = gapi.auth2.getAuthInstance();
      return from$(auth.grantOfflineAccess().then(({ code }) => code)).pipe(catchError$(handleErrors));
    }));
  }

  private startInit(): void {
    const { status$$ } = this;

    status$$.next(GoogleAuthStatus.Initializing);

    this.loader.load('auth2').subscribe(
      () => {
        const auth = gapi.auth2.init(this.googleConfig);

        auth.then(
          () => status$$.next(GoogleAuthStatus.Initialized),
          () => status$$.next(GoogleAuthStatus.Error));
      },
      () => status$$.next(GoogleAuthStatus.Error));
  }
}
