/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { ID } from '@gh/common-shared-data';

import { AbstractRestService, identity, none, RestSettings } from '@gh/core-data';

import { Observable } from 'rxjs';

import { CustomerUserProfileDto } from './profiles.dto';
import { CustomerUserProfileMapper } from './profiles.mapper';

@Injectable()
export class CustomerUserProfileRestService extends AbstractRestService {

  constructor(private settings: RestSettings) {
    super(settings);
  }

  get(): Observable<CustomerUserProfileDto> {
    return this.httpGet('/profiles/me', CustomerUserProfileMapper);
  }

  logout(): Observable<any> {
    return this.httpGet('/profiles/logout', identity());
  }

  acceptInvitation(officeId: ID): Observable<void> {
    return this.httpGet(`/profiles/accept-invitation/${officeId}`, none());
  }
}
