/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
// tslint:disable:no-magic-numbers variable-name
import { REGION_CODE } from '@gh/config';
import { maskedTextType } from '@gh/core-type';
import { MASKED_EN_AU } from './localization/mask-en-au';
import { MASKED_EN_NZ } from './localization/mask-en-nz';
import { MASKED_EN_US } from './localization/mask-en-us';
import { digits, repeat } from './mask-utils';
var MASKED = {
    au: MASKED_EN_AU,
    nz: MASKED_EN_NZ,
    us: MASKED_EN_US,
}[REGION_CODE];
export var BaseMaskedTextType = maskedTextType({
    messages: {
        rangeLength: 'msg_field_invalid_format',
        maxLength: 'msg_max_value',
        pattern: 'msg_field_invalid_format',
    },
});
export var MaskedTypes = {
    Zip: maskedTextType(BaseMaskedTextType, __assign({}, MASKED['Zip'])),
    TaxFileNumber: maskedTextType(BaseMaskedTextType, {
        mask: __spreadArrays(digits(3), ['-'], digits(3), ['-'], digits(3)),
        constraints: {
            pattern: /^\d{3}-\d{3}-\d{3}$/,
        },
    }),
    AuthNumber: maskedTextType(BaseMaskedTextType, {
        mask: repeat(6, /[A-Z1-9]/i),
        constraints: {
            pattern: /^[A-Z1-9]{6}$/i,
        },
    }),
    SourceNumber: maskedTextType(BaseMaskedTextType, {
        mask: __spreadArrays(digits(6)),
        constraints: {
            pattern: /^\d{6}$/,
        },
    }),
    TaxId: maskedTextType(BaseMaskedTextType, __assign({}, MASKED['TaxId'])),
    Gst: maskedTextType(BaseMaskedTextType, __assign({}, MASKED['Gst'])),
    Acn: maskedTextType(BaseMaskedTextType, __assign({}, MASKED['Acn'])),
    Abn: maskedTextType(BaseMaskedTextType, __assign({}, MASKED['Abn'])),
    MasterZoneAbbr: maskedTextType(BaseMaskedTextType, {
        mask: __spreadArrays([/[A-Z0-9\-_\']/i], repeat(15, /[A-Z0-9\.\-_\']/i)),
        constraints: {
            pattern: /^([A-Z0-9\-_\'])([A-Z0-9\-_\']*(\.[A-Z0-9\-_\']*)?)$/i,
            maxLength: 16,
        },
    }),
    GoogleGroupName: maskedTextType(BaseMaskedTextType, {
        mask: __spreadArrays([/[A-Z0-9\-_\']/i], repeat(63, /[A-Z0-9\.\-_\']/i)),
        constraints: {
            pattern: /^([A-Z0-9\-_\'])([A-Z0-9\-_\']*(\.[A-Z0-9\-_\']*)?)$/i,
            maxLength: 64,
        },
    }),
    GoogleUserPassword: maskedTextType(BaseMaskedTextType, {
        mask: repeat(100, /[\x00-\x7F]/i),
        constraints: {
            maxLength: 100,
            pattern: /^[\x00-\x7F]+$/i,
            rangeLength: { min: 8, max: 100 },
        },
        messages: {
            rangeLength: 'msg_password_length',
        },
    }),
    CustomFieldsLength: maskedTextType(BaseMaskedTextType, {
        mask: repeat(3, /[0-9]/i),
    }),
    SocialNumber: maskedTextType(BaseMaskedTextType, __assign({}, MASKED['SocialNumber'])),
    TaxNumber: maskedTextType(BaseMaskedTextType, __assign({}, MASKED['TaxNumber'])),
    NumberFormat: maskedTextType(BaseMaskedTextType, {
        mask: function (value) {
            var parsedMask = [];
            for (var _i = 0, value_1 = value; _i < value_1.length; _i++) {
                var char = value_1[_i];
                if (/^\d+$/.test(char)) {
                    parsedMask.push.apply(parsedMask, digits(1));
                }
                if (char === '.') {
                    parsedMask.push('.');
                }
            }
            return parsedMask;
        },
        constraints: {
            pattern: /^(\d*\.*)*$/,
            maxLength: 16,
        },
    }),
    CostCenterCode: maskedTextType(BaseMaskedTextType, {
        mask: repeat(3, /[0-9]/i),
    }),
    OrderNumber: maskedTextType(BaseMaskedTextType, {
        mask: repeat(2, /[0-9]/i),
    }),
    BsbNumber: maskedTextType(BaseMaskedTextType, __assign({}, MASKED['BsbNumber'])),
    PhoneNumber: maskedTextType(BaseMaskedTextType, __assign({}, MASKED['PhoneNumber'])),
};
