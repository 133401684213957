/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ValidationErrors } from '@angular/forms';

import { DateTimeFormat, DateTimeParser, LocalizationProvider } from '@gh/core-mls';
import { isDateInvalid } from '@gh/core-util';

import { isNil } from 'lodash';

import { DateTimeType, DateTypeDefenition } from '../type.interfaces';
import { AbstractPrimitiveType } from './abstract-type';

export class DateTimeTypeImpl extends AbstractPrimitiveType<Date> implements DateTimeType {
  private dateTimeFormat: DateTimeFormat;
  private dateTimeParser: DateTimeParser;

  constructor(defenition: DateTypeDefenition) {
    super(defenition, {});
  }

  parse(str: string): Date {
    return this.dateTimeParser.parse(this.getInputFormatName(), str);
  }

  format(value: Date): string {
    return this.dateTimeFormat.format(this.defenition.format, value);
  }

  validate(value: Date): Maybe<ValidationErrors> {
    if (isNil(value)) {
      return void 0;
    }

    return isDateInvalid(value) ? { format: { messageId: 'msg_field_invalid_format' } } : super.validate(value);
  }

  validateFormat(str: string): Maybe<ValidationErrors> {
    if (!this.dateTimeParser.validate(this.getInputFormatName(), str)) {
      return {
        format: {
          messageId: 'msg_field_invalid_format',
        },
      };
    }
  }

  init(name: string, localizationProvider: LocalizationProvider): void {
    super.init(name, localizationProvider);

    this.dateTimeFormat = localizationProvider.getDateTimeFormat();
    this.dateTimeParser = localizationProvider.getDateTimeParser();
  }

  private getInputFormatName(): string {
    const format = (<DateTypeDefenition>this.defenition).inputFormat;

    if (isNil(format)) {
      throw new Error('Input format is not specified for date type');
    }

    return format;
  }
}
