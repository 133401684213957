<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<gh-ext-page>
  <gh-ext-page-title title="{{'lbl_shared_docs' | translate}}"></gh-ext-page-title>
  <gh-ext-page-content>
    <ng-container matSort="">
      <!-- Desktop version -->
      <mat-table *ghExtIfQuery="'gt-sm'" ghExtInfiniteTable="" [dataSource]="sharedDocumentDataSource" [tracker]="tracker" [trackBy]="getSharedDocumentId">
        <ng-container matColumnDef="fileName">
          <mat-header-cell fxFlex="85%" *matHeaderCellDef="" mat-sort-header="">{{'lbl_file_name' | translate}}</mat-header-cell>
          <mat-cell fxFlex="85%" *matCellDef="let sharedDocument">{{ sharedDocument.fileName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell fxFlex="15%" *matHeaderCellDef="">
          </mat-header-cell>
          <mat-cell fxFlex="15%" *matCellDef="let sharedDocument">
            <button (click)="viewSharedDocument(sharedDocument)" mat-stroked-button="" type="button" class="small cell-button" color="primary">{{'lbl_view' | translate}}</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let sharedDocument; columns: displayedColumns" [ghExtInfiniteRow]="sharedDocument">
        </mat-row>

      </mat-table>

      <!-- Mobile version -->
      <div *ghExtIfQuery="'lt-md'" fxLayout="column">
        <gh-ext-infinite-list [itemHeight]="145" [tracker]="tracker">
          <ng-template ghExtInfiniteScrollFor="" let-sharedDocument="" [ghExtInfiniteScrollForOf]="sharedDocumentDataSource">
            <mat-card class="card-list-item">
              <mat-card-content>
                <div class="card-content-item full-width">
                  <div class="card-content-item-title">{{'lbl_file_name' | translate}}</div>
                  <div class="card-content-item-value">{{ sharedDocument.fileName }}</div>
                </div>
              </mat-card-content>
              <mat-card-actions>
                <button (click)="viewSharedDocument(sharedDocument)" mat-stroked-button="" type="button" class="small">{{'lbl_view' | translate}}</button>
              </mat-card-actions>
            </mat-card>
          </ng-template>
        </gh-ext-infinite-list>
      </div>
      <gh-ext-load-tracker fxFlex="" #tracker="ghExtLoadTracker"></gh-ext-load-tracker>
    </ng-container>
  </gh-ext-page-content>
</gh-ext-page>