/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  difference,
  forEach,
  forEachRight,
  fromPairs,
  identity,
  intersection,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  keys,
  map,
  sortBy,
  uniq,
} from 'lodash';

/**
 * Applies <code>map</code> function to the given <code>value</code> if <code>value</code> is not nil,
 * otherwise returns provided <code>defaultValue</code> or <code>undefined</code>
 */
export function maybeMap<T, U>(value: Maybe<T>, map: TransformFn<T, U>, defaultValue?: U): U;
export function maybeMap<T, U>(value: Maybe<T>, map: TransformFn<T, U>, defaultValue?: U): Maybe<U>;
export function maybeMap<T, U>(value: Maybe<T>, map: TransformFn<T, U>, defaultValue: U): U;
export function maybeMap(value: any, map: TransformFn<any, any>, defaultValue?: any): any {
  return isNil(value) ? defaultValue : map(value);
}

export function coalesce<T>(value: T | undefined | null, defaultValue: any): T {
  return isNil(value) ? defaultValue : value;
}

export function orDefaultValue<T>(defaultValue: T): TransformFn<Nilable<T>, T> {
  return (value) => isNil(value) ? defaultValue : value;
}

export function isAllTrue(bools: boolean[]): boolean {
  return bools.every(identity);
}

export function isSomeTrue(bools: boolean[]): boolean {
  return bools.some(identity);
}

export function asString(text: string | undefined | null): string {
  return coalesce(text, '');
}

export function toString(text: any): string {
  return isNil(text) ? '' : text.toString();
}

export function isNotNil<T>(value: T | undefined | null): value is T {
  return !isNil(value);
}

export function notNil<T>(value: Nilable<T>): T {
  if (isNil(value)) {
    throw new Error('notNil: value should not be null or undefined');
  }
  return value;
}

export function toBoolean(value: Nilable<boolean>): boolean {
  return isNil(value) ? false : value;
}

export function isNotEmpty(value?: any): boolean {
  return !isEmpty(value);
}

export function differentFieldsWith(a: object, b: object, equals: Function = isEqual): string[] {
  return uniq(Object.keys(a).concat(Object.keys(b))).filter((field) => !equals(a[field], b[field]));
}

export function arrayify<T>(value: T[]): T[];
export function arrayify<T>(value: T): T[];
export function arrayify(value: any): any {
  if (isArray(value)) {
    return value;
  } else if (isNil(value)) {
    return [];
  } else {
    return [value];
  }
}

/**
 * Combines provided predicates to the single predicate using AND relation.
 * Result predicate returns true iff each source predicate returns true.
 */
// tslint:disable-next-line:max-line-length
export function combinePredicatesByAnd<A, T extends PredicateFn<A> | boolean>(...predicates: T[]): PredicateFn<A> | boolean {
  // if we have at least one false constant predicate we always will have false value
  if (predicates.some((p) => p === false)) {
    return false;
  }

  // all constant true predicates can be ignored
  const predicateFns = <PredicateFn<A>[]>predicates.filter(isFunction);

  if (predicateFns.length > 0) {
    return (arg) => predicateFns.reduce((last, predicate) => last && predicate(arg), true);
  } else {
    // if there is no any predicate function we return true iff we have at least one constant true predicate.
    return predicates.length > 0;
  }
}

/**
 * Combines provided predicates to the single predicate using OR relation.
 * Result predicate returns true iff at least one predicate returns true.
 */
// tslint:disable-next-line:max-line-length
export function combinePredicatesByOr<A, T extends PredicateFn<A> | boolean>(...predicates: T[]): PredicateFn<A> | boolean {
  // if we have at least one true constant predicate we always will have true value
  if (predicates.some((p) => p === true)) {
    return true;
  }

  // all constant false predicates can be ignored
  const predicateFns = <PredicateFn<A>[]>predicates.filter(isFunction);

  if (predicateFns.length > 0) {
    return (arg) => predicateFns.reduce((last, predicate) => last || predicate(arg), true);
  } else {
    // if there is no any predicate function we return false as we do not have any true predicate.
    return false;
  }
}

export function isEmptyValue(v: any): boolean {
  return isEmpty(v) && !(v instanceof Date);
}

export function objectDiffKeys(prev: any,
                               next: any,
                               { onNew, onIdentity, onRemove }: {
                                 onNew: ProcedureFn<string>;
                                 onIdentity: ProcedureFn<string>;
                                 onRemove: ProcedureFn<string>;
                               }): void {
  const prevKeys = keys(prev);
  const nextKeys = keys(next);

  difference(nextKeys, prevKeys).forEach(onNew);
  intersection(prevKeys, nextKeys).forEach(onIdentity);
  difference(prevKeys, nextKeys).forEach(onRemove);
}

type ArrayDiffElement = {
  oldValue?: any;
  newValue?: any;
  oldIndex?: number;
  newIndex?: number;
};

export function arrayDiff(prev: any[],
                          next: any[],
                          idProperty: string,
                          { onInsert, onIdentity, onDelete }: {
                            onInsert(oldValue: any, newValue: any, newIndex: number): void;
                            onIdentity(oldValue: any, newValue: any, index: number): void;
                            onDelete(oldValue: any, newValue: any, oldIndex: number): void;
                          }): void {
  const prevKeys = map(prev, idProperty).filter(Boolean);
  const nextKeys = map(next, idProperty).filter(Boolean);
  const allKeys = uniq(prevKeys.concat(nextKeys));

  if (prevKeys.length < prev.length || nextKeys.length < next.length) {
    throw new Error(`arrayDiff: not all compared array items have "key" property defined:` +
      ` in old array ${prevKeys.length} out of ${prev.length}` +
      ` in new array ${nextKeys.length} out of ${next.length}`);
  }

  const prevByKey = fromPairs(map(prev, (item, index) => [item[idProperty], { item, index }]));
  const nextByKey = fromPairs(map(next, (item, index) => [item[idProperty], { item, index }]));

  const diffs = allKeys.map((key) => {
    const prevEntry = prevByKey[key];
    const nextEntry = nextByKey[key];

    return {
      oldValue: prevEntry && prevEntry.item,
      oldIndex: prevEntry && prevEntry.index,
      newValue: nextEntry && nextEntry.item,
      newIndex: nextEntry && nextEntry.index,
    };
  });

  // insert: all new items and all items with modified position
  const toInsert = diffs.filter(({ oldIndex, newIndex }) => isNil(oldIndex) || newIndex >= 0 && oldIndex !== newIndex);
  // remove: all removed items and all items with modified position
  const toRemove = diffs.filter(({ oldIndex, newIndex }) => isNil(newIndex) || oldIndex >= 0 && oldIndex !== newIndex);
  // remains: all items with not-modified position
  const toRemain = diffs.filter(({ oldIndex, newIndex }) => newIndex >= 0 && oldIndex >= 0 && oldIndex === newIndex);

  forEachRight(
    sortBy(toRemove, 'oldIndex'),
    ({ oldIndex, oldValue, newValue }) => onDelete(oldValue, newValue, oldIndex));
  forEach(
    sortBy(toInsert, 'newIndex'),
    ({ newIndex, oldValue, newValue }) => onInsert(oldValue, newValue, newIndex));
  forEach(toRemain, ({ oldValue, newValue, newIndex }) => onIdentity(oldValue, newValue, newIndex));
}
