/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:no-magic-numbers variable-name

import { numberType } from '@gh/core-type';
import {
  getPositiveDecimalParams,
  getPositiveIntegralParams,
  getRangedDecimalParams,
  getRangedIntegralParams,
} from './number-utils';

export const BaseNumberType = numberType({
  messages: {
    min: 'msg_field_invalid_format',
    max: 'msg_field_invalid_format',
    range: 'msg_field_invalid_range',
    integral: 'msg_field_invalid_format',
  },
});

export const PositiveSmallint = numberType(BaseNumberType, {
  ...getPositiveIntegralParams(15),
});

export const NumberTypes = {
  SignedPrice: numberType(BaseNumberType, {
    ...getRangedDecimalParams({
      precision: 11,
      scale: 2,
      includeMax: false,
      minFractionDigits: 2,
      maxFractionDigits: 2,
    }),
    messages: {
      max: 'msg_field_price_invalid_max',
    },
  }),
  Price: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({
      precision: 11,
      scale: 2,
      includeMax: false,
      minFractionDigits: 2,
      maxFractionDigits: 2,
    }),
    messages: {
      max: 'msg_field_price_invalid_max',
    },
  }),
  PriceWithoutDecimals: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({
      precision: 11,
      scale: 2,
      includeMax: false,
      minFractionDigits: 0,
      maxFractionDigits: 2,
    }),
    messages: {
      max: 'msg_field_price_invalid_max',
    },
  }),
  Quantity: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({ precision: 20, scale: 4, includeMax: false }),
  }),
  SignedQuantity: numberType(BaseNumberType, {
    ...getRangedDecimalParams({ precision: 20, scale: 4, includeMax: false }),
  }),
  SignedQuantity_2: numberType(BaseNumberType, {
    ...getRangedDecimalParams({
      precision: 20,
      scale: 4,
      includeMax: false,
      minFractionDigits: 2,
      maxFractionDigits: 4,
    }),
  }),
  Quantity_2: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({
      precision: 14,
      scale: 4,
      includeMax: false,
      minFractionDigits: 2,
      maxFractionDigits: 2,
    }),
  }),
  Dimension: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({ precision: 14, scale: 4, includeMax: false }),
    messages: {
      max: 'msg_field_price_invalid_max',
    },
  }),
  Length: numberType(BaseNumberType, getPositiveDecimalParams({ precision: 14, scale: 2, includeMax: false })),
  Commission: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({
      precision: 10,
      scale: 4,
      includeZero: false,
      includeMax: false,
      minFractionDigits: 2,
      maxFractionDigits: 2,
    }),
    messages: {
      min: 'msg_field_positive',
      max: 'msg_field_price_invalid_max',
    },
  }),
  Fee: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({ precision: 10, scale: 4, max: 10000, minFractionDigits: 2, maxFractionDigits: 2 }),
    messages: {
      max: 'msg_field_fee_invalid_max',
    },
  }),
  Hours: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({ precision: 10, scale: 2, max: 1000000 }),
    messages: {
      min: 'msg_field_positive',
      max: 'msg_field_hours_invalid_max',
    },
  }),
  HoursNotZero: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({ precision: 10, scale: 2, max: 1000000, includeZero: false }),
    messages: {
      min: 'msg_field_positive',
      max: 'msg_field_hours_invalid_max',
    },
  }),
  Duration: numberType(BaseNumberType, {
    ...getPositiveIntegralParams(15, 1000000, false),
    messages: {
      min: 'msg_field_positive',
      max: 'msg_field_hours_invalid_max',
    },
  }),
  DurationZero: numberType(BaseNumberType, {
    ...getPositiveIntegralParams(15, 1000000, true),
    messages: {
      min: 'msg_field_positive',
      max: 'msg_field_hours_invalid_max',
    },
  }),
  DayOfMonth: numberType(BaseNumberType, {
    format: {
      minFractionDigits: 0,
      maxFractionDigits: 0,
    },
    constraints: {
      integral: true,
      range: {
        min: 1,
        includeMin: true,
        max: 31,
        includeMax: true,
      },
    },
    messages: {
      range: 'msg_due_days_error',
    },
  }),
  Days: PositiveSmallint,
  Area: PositiveSmallint,
  Code: PositiveSmallint,
  Count: PositiveSmallint,
  VariationNumber: PositiveSmallint,
  CustomFieldLengthText: numberType(BaseNumberType, {
    ...getRangedDecimalParams({ precision: 3, scale: 0, min: 1, max: 200 }),
    messages: {
      range: 'msg_field_length_invalid_range',
    },
  }),
  CustomFieldLengthNumber: numberType(BaseNumberType, {
    ...getRangedDecimalParams({ precision: 2, scale: 0, min: 1, max: 15 }),
    messages: {
      range: 'msg_field_digits_invalid_range',
    },
  }),
  RoundUp: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({ precision: 10, scale: 4 }),
    messages: {
      // max: 'msg_field_maxlength',
    },
  }),
  Rate: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({
      precision: 5,
      scale: 2,
      includeZero: false,
      includeMax: false,
      minFractionDigits: 0,
      maxFractionDigits: 2,
    }),
    messages: {
      min: 'msg_field_positive',
    },
  }),
  Percents: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({
      max: 100,
      precision: 5,
      scale: 2,
      includeZero: true,
      includeMax: true,
      minFractionDigits: 0,
      maxFractionDigits: 2,
    }),
  }),
  Percents_2: numberType(BaseNumberType, {
    ...getPositiveDecimalParams({
      max: 100,
      precision: 5,
      scale: 2,
      includeZero: true,
      includeMax: true,
      minFractionDigits: 2,
      maxFractionDigits: 2,
    }),
  }),
  PercentsIntegral: numberType(BaseNumberType, {
    ...getPositiveIntegralParams(-1, 100, true, true),
  }),
  QuoteNumber: numberType(BaseNumberType, {
    ...getPositiveIntegralParams(31),
    messages: {},
  }),
  DelayDays: numberType(BaseNumberType, {
    ...getRangedIntegralParams({
      min: 7,
      includeMin: true,
      max: 180,
      includeMax: true,
    }),
    messages: {},
  }),
  TimeExtension: numberType(BaseNumberType, {
    ...getRangedIntegralParams({
      min: 0,
      includeMin: true,
      max: 30,
      includeMax: true,
    }),
    messages: {},
  }),
  LineReserved: numberType(BaseNumberType, {
    ...getRangedIntegralParams({
      min: 1,
      includeMin: true,
      max: 20,
      includeMax: true,
    }),
    messages: {},
  }),
  DimensionIntegral: numberType(BaseNumberType, getPositiveIntegralParams(31)),
  QuantityIntegral: numberType(BaseNumberType, getPositiveIntegralParams(31)),
};
