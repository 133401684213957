/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { AbstractRestService, RestSettings } from '@gh/core-data';
import { Observable } from 'rxjs';

@Injectable()
export class ExtUploaderService extends AbstractRestService {
  constructor(
    private settings: RestSettings,
  ) {
    super(settings);
  }

  upload(url: string, file: File): Observable<Object> {
    const body = new FormData();
    body.append('fileName', file.name);
    body.append('mimeType', file.type);
    body.append('file', file);

    return this.httpClient.post(this.url(url), body);
  }
}
