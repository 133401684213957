/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { getRouteParam, InitViewService } from '@gh/common-shared';
import { ID } from '@gh/common-shared-data';
import { CustomerPhotoRestService } from '@gh/customer-shared-data';
import { map$ } from '@gh/rx/operators';

import { Observable } from 'rxjs';

import { customerPhotoListFromDto, CustomerPhotoListItem } from './customer-gallery.model';

@Injectable()
export class CustomerGalleryBusinessService implements InitViewService {
  constructor(private restService: CustomerPhotoRestService) {
  }

  initView(route: ActivatedRouteSnapshot): any {
    const jobId = getRouteParam(route, 'jobId');

    return {
      photoList: jobId ? this.findAll(jobId) : [],
    };
  }

  findAll(jobId: ID): Observable<CustomerPhotoListItem[]> {
    return this.restService.findAll(jobId).pipe(map$(customerPhotoListFromDto));
  }
}
