/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import * as i0 from "@angular/core";
/**
 * This directive adds ... (three dots) for the given text if it does not fit into its block.
 * Actually CSS has text-overflow property for this purpose, but unfortunately it does not work for multiline text.
 *
 * There is no reliable cross-browser CSS only way to support ellipsis in multiline text.
 *
 * This directive was inspired by this library: https://github.com/dibari/angular-ellipsis.
 *
 * Here provided only the minimalistic implementation that will work for blocks having fixed dimension.
 *
 * Usage:
 * <code>
 * <div ghEllipsis="some very long text"></div>
 * </code>
 */
var EllipsisDirective = /** @class */ (function () {
    function EllipsisDirective(elementRef, renderer) {
        this.elementRef = elementRef;
        this.renderer = renderer;
    }
    Object.defineProperty(EllipsisDirective.prototype, "ghEllipsis", {
        set: function (text) {
            this.setText(text);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * This method tries to fit given text into the target area.
     * If text does not fit into the target area, this method cuts it and adds ... (three dots) in the end.
     *
     * @param text
     */
    EllipsisDirective.prototype.setText = function (text) {
        this.setTextContent(text);
        if (this.isOverflowed()) {
            // split text on words and try to remove word by word to fit the rest of text into the target area.
            var words = splitOnWords(text);
            while (words.length) {
                words.pop();
                this.setTextContent(words.join(' ') + '...');
                if (!this.isOverflowed()) {
                    break;
                }
            }
        }
    };
    EllipsisDirective.prototype.isOverflowed = function () {
        var element = this.elementRef.nativeElement;
        return element.scrollHeight > element.clientHeight;
    };
    EllipsisDirective.prototype.setTextContent = function (text) {
        this.elementRef.nativeElement.textContent = text;
    };
    EllipsisDirective.ɵfac = function EllipsisDirective_Factory(t) { return new (t || EllipsisDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2)); };
    EllipsisDirective.ɵdir = i0.ɵɵdefineDirective({ type: EllipsisDirective, selectors: [["", "ghEllipsis", ""]], inputs: { ghEllipsis: "ghEllipsis" } });
    return EllipsisDirective;
}());
export { EllipsisDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EllipsisDirective, [{
        type: Directive,
        args: [{
                selector: '[ghEllipsis]',
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }]; }, { ghEllipsis: [{
            type: Input
        }] }); })();
function splitOnWords(text) {
    return text.split(' ');
}
