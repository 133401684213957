/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { LocalizationService } from '@gh/core-mls';
import { isElementFocused, isNotNil, newTime } from '@gh/core-util';
import { isEqual, noop, range } from 'lodash';

const TIME_FORMAT_STYLE = 'shortTime';
const TIME_PARSE_PATTERN = 'time';

export class TimepickerBaseComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() step = 30;
  @Input() filter: PredicateFn<Date>;
  @Input() panelClass: string;

  times: Date[];

  value?: Date;
  textValue = '';

  disabled: boolean = false;

  @ViewChild('input', { static: false }) inputEl: ElementRef;
  @ViewChild(MatAutocomplete, { static: false }) autocomplete: MatAutocomplete;

  private _onTouched = noop;
  private _onChange = noop;

  constructor(private localizationService: LocalizationService) {
    this.stringifyTime = this.stringifyTime.bind(this);
  }

  get focused(): boolean {
    return isElementFocused(this.inputEl.nativeElement);
  }

  ngOnInit(): void {
    const { step } = this;
    this.times = range(24 * 60 / step)
      .map((i) => {
        const minutes = i * step;
        return newTime(Math.floor(minutes / 60), minutes % 60, 0, 0);
      });

    const { filter } = this;
    if (filter) {
      this.times = this.times.filter(filter);
    }
  }

  focus(): void {
    this.inputEl.nativeElement.focus();
  }

  writeValue(time?: Date): void {
    this.textValue = this.stringifyTime(time);
    this.value = isNotNil(time) ? time : void 0;
  }

  registerOnChange(fn: (value: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInput(strValue: string): void {
    strValue = strValue.trim();
    // this.textMaskInputElement.update(str);
    const nextValue = strValue ?
      this.localizationService.getDateTimeParser().parse(TIME_PARSE_PATTERN, strValue) : void 0;

    this.textValue = strValue;

    this.setValue(nextValue);
  }

  onTouched(): void {
    this._onTouched();
  }

  onOptionSelected(time?: Date): void {
    this.setValue(time);
    this.textValue = this.stringifyTime(time);
  }

  stringifyTime(time?: Date): string {
    return time ? this.localizationService.getDateTimeFormat().format(TIME_FORMAT_STYLE, time) : '';
  }

  private setValue(value?: Date): void {
    if (!isEqual(this.value, value)) {
      this.value = value;
      if (value) {
        this._onChange(value);
      } else {
        this._onChange(null); // tslint:disable-line:no-null-keyword
      }
    }
  }
}

@Component({
  selector: 'gh-timepicker',
  templateUrl: 'timepicker.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TimepickerComponent, multi: true }],
  exportAs: 'ghTimepicker',
})
export class TimepickerComponent extends TimepickerBaseComponent {
  constructor(localizationService: LocalizationService) {
    super(localizationService);
  }
}

@Component({
  selector: 'gh-inline-timepicker',
  templateUrl: 'inline-timepicker.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: InlineTimepickerComponent, multi: true }],
  exportAs: 'ghInlineTimepicker',
})
export class InlineTimepickerComponent extends TimepickerBaseComponent {
  constructor(localizationService: LocalizationService) {
    super(localizationService);
  }
}
