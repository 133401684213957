/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { MessageDirective, MessageRepositoryComponent } from './components/message-repository';
import { LocalizationService } from './localization';
import { FormMessageService } from './message';
import {
  JSONTranslationMapLoader,
  TranslatePipe,
  TranslationCompiler,
  TranslationService,
  TranslationStorage,
} from './translation';

@NgModule({
  declarations: [
    TranslatePipe,
    MessageRepositoryComponent,
    MessageDirective,
  ],
  exports: [
    TranslatePipe,
    MessageRepositoryComponent,
    MessageDirective,
  ],
})
export class CoreMlsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreMlsModule,
      providers: [
        FormMessageService,
        TranslationStorage,
        TranslationService,
        JSONTranslationMapLoader,
        LocalizationService,
        TranslationCompiler,
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: CoreMlsModule,
      providers: [],
    };
  }
}
