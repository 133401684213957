/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { of$, throwError$ } from '@gh/rx';
import { Observable } from 'rxjs';

/**
 * Simple store that associates data with the given key
 */
@Injectable()
export class RouteDataStore {
  private data: Hash<any> = {};

  /**
   * Associates some data with the given key
   */
  set(key: string, value: any): Observable<any> {
    this.data[key] = value;

    return of$(value);
  }

  /**
   * Returns data associated with the given key.
   * If data is not associated with the given key method returns failed observable.
   */
  get(key: string): Observable<any> {
    const entry = this.data[key];

    if (entry) {
      return of$(entry);
    } else {
      return throwError$(`Entry does not exist for '${key}'`);
    }
  }

  /**
   * Remove state by key
   */
  clear(key: string): void {
    delete this.data[key];
  }
}
