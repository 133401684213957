/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { stopPropagation } from '@gh/core-util';

@Directive({
  selector: '[ghEventStopPropagation]',
})
export class EventStopPropagationDirective implements OnDestroy {
  private trackedEvents: string[] = [];

  constructor(private elementRef: ElementRef) {
  }

  @Input('ghEventStopPropagation')
  set events(events: string[] | string) {
    const trackedEvents = Array.isArray(events) ? events : events.split(/\s+/g);

    this.cleanTrackedEvents();
    this.trackEvents(trackedEvents);
  }

  ngOnDestroy(): void {
    this.cleanTrackedEvents();
  }

  private trackEvents(events: string[]): void {
    const { nativeElement } = this.elementRef;
    events.forEach((event) => nativeElement.addEventListener(event, stopPropagation));
    this.trackedEvents = events;
  }

  private cleanTrackedEvents(): void {
    const { nativeElement } = this.elementRef;
    this.trackedEvents.forEach((event) => nativeElement.removeEventListener(event, stopPropagation));
  }
}
