/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { findPermission, Permission } from '@gh/core-auth';
import * as invariant from 'invariant';
import { isString } from 'lodash';
import { UserContext } from '../user/index';
import * as i0 from "@angular/core";
import * as i1 from "../user/index";
var HasPermissionPipe = /** @class */ (function () {
    function HasPermissionPipe(userContext) {
        this.userContext = userContext;
    }
    HasPermissionPipe.prototype.transform = function (permission) {
        if (isString(permission)) {
            permission = findPermission(permission);
        }
        invariant(permission instanceof Permission, "Permission '" + permission + "' is not an instance of Permission");
        return this.userContext.hasPermission(permission);
    };
    HasPermissionPipe.ɵfac = function HasPermissionPipe_Factory(t) { return new (t || HasPermissionPipe)(i0.ɵɵdirectiveInject(i1.UserContext)); };
    HasPermissionPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "hasPermission", type: HasPermissionPipe, pure: true });
    return HasPermissionPipe;
}());
export { HasPermissionPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(HasPermissionPipe, [{
        type: Pipe,
        args: [{
                name: 'hasPermission',
            }]
    }], function () { return [{ type: i1.UserContext }]; }, null); })();
