/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { ContainerStateDirective } from './container-state.directive';
import * as i0 from "@angular/core";
import * as i1 from "./container-state.directive";
/**
 * This directive helps to handle disabling of controls when form is in 'pending' status.
 * It sets 'disabled' attribute for the element this directive is declared on and adds spinner animation.
 *
 * Note!!! Currently this directive can be assigned only to mat-button and mat-icon-button.
 */
var ContainerAsyncDisabledDirective = /** @class */ (function () {
    function ContainerAsyncDisabledDirective(elementRef, containerState) {
        this.elementRef = elementRef;
        this.containerState = containerState;
        /**
         * If field is disabled due to async validation
         * @type {boolean}
         */
        this.asyncDisabled = false;
        /**
         * If disabled attribute is set
         *
         * @type {boolean}
         */
        this.hostDisabled = false;
    }
    ContainerAsyncDisabledDirective.prototype.ngOnInit = function () {
        var spinnerEl = document.createElement('div');
        spinnerEl.classList.add('spinner', 'spinner-disabled');
        this.elementRef.nativeElement.appendChild(spinnerEl);
        this.spinnerEl = spinnerEl;
    };
    ContainerAsyncDisabledDirective.prototype.ngOnDestroy = function () {
        this.elementRef.nativeElement.removeChild(this.spinnerEl);
        this.spinnerEl = void 0;
    };
    ContainerAsyncDisabledDirective.prototype.ngDoCheck = function () {
        this.asyncDisabled = this.containerState.containerState.pending;
        this.hostDisabled = this.asyncDisabled || this.disabled;
    };
    ContainerAsyncDisabledDirective.ɵfac = function ContainerAsyncDisabledDirective_Factory(t) { return new (t || ContainerAsyncDisabledDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.ContainerStateDirective)); };
    ContainerAsyncDisabledDirective.ɵdir = i0.ɵɵdefineDirective({ type: ContainerAsyncDisabledDirective, selectors: [["", "mat-button", "", "ghContainerAsyncDisabled", ""], ["", "mat-icon-button", "", "ghContainerAsyncDisabled", ""]], hostVars: 3, hostBindings: function ContainerAsyncDisabledDirective_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵhostProperty("disabled", ctx.hostDisabled);
            i0.ɵɵclassProp("form-async-disabled", ctx.asyncDisabled);
        } }, inputs: { disabled: "disabled" } });
    return ContainerAsyncDisabledDirective;
}());
export { ContainerAsyncDisabledDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContainerAsyncDisabledDirective, [{
        type: Directive,
        args: [{
                selector: '[mat-button][ghContainerAsyncDisabled], [mat-icon-button][ghContainerAsyncDisabled]',
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i1.ContainerStateDirective }]; }, { asyncDisabled: [{
            type: HostBinding,
            args: ['class.form-async-disabled']
        }], hostDisabled: [{
            type: HostBinding,
            args: ['disabled']
        }], disabled: [{
            type: Input
        }] }); })();
