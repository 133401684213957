/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';

import { CommonUserProfileStore } from '@gh/common-shared';
import { EMPTY_ID } from '@gh/common-shared-data';
import { DEFAULT_LOCALE } from '@gh/config';

import { Locale } from '@gh/core-mls';
import { CustomerUserProfileDto, CustomerUserProfileRestService } from '@gh/customer-shared-data';
import { Observable } from 'rxjs';
import { CustomerUserData } from './customer-user-context';

function customerUserDataFromDto(dto: CustomerUserProfileDto): CustomerUserData {
  return {
    backendBuildNumber: '',
    firstName: '',
    lastName: '',
    id: EMPTY_ID,
    locale: Locale.createFromStandard(DEFAULT_LOCALE),
    fullName: '',
    zoneId: '',
    login: dto.email,
  };
}

@Injectable()
export class CustomerUserProfileStore extends CommonUserProfileStore<CustomerUserData> {

  constructor(private userProfileRest: CustomerUserProfileRestService) {
    super();
  }

  load(params: Hash<any>): void {
    this.userProfileRest.get()
      .subscribe(
        (user) => this.setLoggedInUser(customerUserDataFromDto(user)),
        (error) => this.setLoginError(error),
      );
  }

  logout(): void {
    this.userProfileRest.logout()
      .subscribe(() => this.setLoggedOut());
  }

  updateLocale(locale: Locale): Observable<any> {
    throw new Error('Customer Portal does not support changing of locale');
  }
}
