/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, Optional } from '@angular/core';
import { constant, isString, isUndefined } from 'lodash';
import { DefaultSubstitutionCompiler, SubstitutionCompiler } from './substitution-compiler.service';
import { TranslationParams } from './translation.interfaces';

const PLACEHOLDER_RE = /(^|[^\\])(\{\s*([a-zA-Z_])\w*\s*})/g;
const PLACEHOLDER_MATCH_EXPR_INDEX = 2;

export interface CompiledTranslation {
  (params?: TranslationParams): string;
}

export interface SubstitutionKey {
  name: string;
  attributes?: Hash<any>;
}

@Injectable()
export class TranslationCompiler {
  private substitutionKeyCache: Hash<SubstitutionKey> = {};

  constructor(@Optional() private substitutionCompiler: SubstitutionCompiler) {
    if (!this.substitutionCompiler) {
      this.substitutionCompiler = new DefaultSubstitutionCompiler();
    }
  }

  compile(text: string | string[]): CompiledTranslation {
    const fullText = isString(text) ? text : text.join('\n');

    PLACEHOLDER_RE.lastIndex = 0;

    const substitutions: Hash<number> = {};

    let cursor = 0;
    const parts: string[] = [];

    while (true) {
      const match = PLACEHOLDER_RE.exec(fullText);
      if (!match) {
        break;
      }

      const expression = match[PLACEHOLDER_MATCH_EXPR_INDEX];
      parts.push(fullText.substring(cursor, PLACEHOLDER_RE.lastIndex - expression.length));

      substitutions[expression.substring(1, expression.length - 1)] = parts.length;

      parts.push('');

      cursor = PLACEHOLDER_RE.lastIndex;
    }

    parts.push(fullText.substring(cursor));

    if (parts.length === 1) {
      return this.createSimpleTranslation(parts[0]);
    } else {
      return this.createMultipartTranslation(parts, substitutions);
    }
  }

  private createSimpleTranslation(text: string): CompiledTranslation {
    return constant(text);
  }

  private createMultipartTranslation(parts: string[], substitutions: Hash<number>): CompiledTranslation {
    return (params) => {
      if (!params) {
        return parts.join('');
      }

      const paramKeys = Object.keys(params);
      if (paramKeys.length === 0) {
        return parts.join('');
      }

      const stage = parts.concat();
      paramKeys.forEach((key) => {
        const parsedKey = this.parseSubstitutionKey(key);
        const sub = substitutions[parsedKey.name];

        if (isUndefined(sub)) {
          console.warn(`Illegal parameter is passed into message: ${stage.join('')}, ${key}`);
          return;
        }

        stage[sub] = this.substitutionCompiler.apply(parsedKey, params[key]);
      });

      return stage.join('');
    };
  }

  private parseSubstitutionKey(key: string): SubstitutionKey {
    let parsed = this.substitutionKeyCache[key];
    if (!parsed) {
      parsed = this.substitutionKeyCache[key] = this.substitutionCompiler.compileKey(key);
    }
    return parsed;
  }

}
