/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Type } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Data,
  Params,
  Route,
  RouterStateSnapshot,
  Routes,
  UrlSegment,
  UrlSegmentGroup,
  Event,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  ChildActivationStart,
  ChildActivationEnd,
  ActivationStart,
  ActivationEnd,
  Scroll,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  RoutesRecognized, GuardsCheckStart, GuardsCheckEnd, ResolveStart, ResolveEnd,
} from '@angular/router';
import { Permission } from '@gh/core-auth';
import { TranslationTuple } from '@gh/core-mls';
import { isNotNil } from '@gh/core-util';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { AuthGuard } from './auth-guard.service';
import { CanLeave } from './can-leave.interfaces';
import { InitViewService } from './init-service';
import { InitViewResolver } from './init-view-resolver.service';
import { LeaveGuard } from './leave-guard.service';

export declare type UrlMatcher = (segments: UrlSegment[], group: UrlSegmentGroup, route: Route) => UrlMatchResult;

export declare type UrlMatchResult = {
  consumed: UrlSegment[];
  posParams?: {
    [name: string]: UrlSegment;
  };
};

export function getUrlSegment(route: ActivatedRouteSnapshot, index: number): UrlSegment {
  return route.url[index];
}

export function getUrlSegmentPath(route: ActivatedRouteSnapshot, index: number): string {
  return getUrlSegment(route, index).path;
}

export function hasUrlSegmentPath(route: ActivatedRouteSnapshot, index: number): boolean {
  return isNotNil(getUrlSegment(route, index));
}

/**
 * Returns true if snapshot is for route creating a new entity, otherwise returns false.
 * Route for page creating new entity should end with 'new' suffix
 *
 * @param route
 * @returns {boolean}
 */
export function isRouteForCreationMode(route: ActivatedRoute | ActivatedRouteSnapshot): boolean {
  const { routeConfig } = getRouteSnapshot(route);
  const path = routeConfig ? routeConfig.path : void 0;

  return path ? /new$/.test(path) : false;
}

/**
 * Returns URL of the specified route
 */
export function getRoutePath(route: ActivatedRoute | ActivatedRouteSnapshot): string {
  const { routeConfig } = getRouteSnapshot(route);
  return routeConfig && routeConfig.path || '';
}

/**
 * Returns current value of the specified parameter for the given route.
 *
 * @param route
 * @param name
 */
export function getRouteParam(route: ActivatedRoute | ActivatedRouteSnapshot, name: string): any {
  const snapshot = getRouteSnapshot(route);

  return snapshot.params[name];
}

/**
 * Returns current value of the specified parameter for the given route or its ancestors.
 *
 * @param route
 * @param name
 */
export function getInheritedRouteParam(route: ActivatedRoute | ActivatedRouteSnapshot, name: string): any {
  let snapshot: Nullable<ActivatedRoute | ActivatedRouteSnapshot> = getRouteSnapshot(route);

  while (snapshot) {
    const param = snapshot.params[name];
    if (isNotNil(param)) {
      return param;
    }
    snapshot = snapshot.parent;
  }
  return void 0;
}

/**
 * Returns current value of the specified parameter for the given route.
 *
 * @param route
 * @param name
 */
export function getRouteQueryParam(route: ActivatedRoute | ActivatedRouteSnapshot, name: string): any {
  const snapshot = getRouteSnapshot(route);

  return snapshot.queryParams[name];
}

export function getRouteQueryParams(route: ActivatedRoute | ActivatedRouteSnapshot): Params {
  const snapshot = getRouteSnapshot(route);

  return snapshot.queryParams;
}

export function getParentRouteUrl(route: ActivatedRoute | ActivatedRouteSnapshot): string {
  return getRouteQueryParam(route, 'parentRoute');
}

/**
 * Returns init data from the given route
 * @param route
 * @returns {any}
 */
export function getRouteInitData(route: ActivatedRoute | ActivatedRouteSnapshot): any {
  const snapshot = getRouteSnapshot(route);

  return snapshot.data['init'];
}

/**
 * Returns static data associated with the given route
 * @param route
 * @returns {any}
 */
export function getRouteStaticData(route: ActivatedRoute | ActivatedRouteSnapshot): any {
  const snapshot = getRouteSnapshot(route);

  return snapshot.data['static'];
}

/**
 * Returns title of parent page
 */
export function getRouteParentTitle(route: ActivatedRoute | ActivatedRouteSnapshot): Maybe<TranslationTuple> {
  const { parentTitle, parentTitleParams } = getRouteSnapshot(route).queryParams;
  return parentTitle ? { id: parentTitle, params: parentTitleParams && JSON.parse(parentTitleParams) } : void 0;
}

export interface BaseRouteConfig {
  path?: string;
  pathMatch?: string;
  component?: Type<any>;
  redirectTo?: string;
  outlet?: string;
  static?: Data;
  children?: Routes;
}

/**
 * This interface defines configuration for secured route.
 */
export interface AuthRouteConfig {
  auth?: {
    applicationView?: string | string[];
    permission?: Permission | Permission[] | string | string[];
  };
}

/**
 * This interface defines configuration for route having optional {@link CanLeave} guard.
 */
export interface LeaveRouteConfig {
  leave?: boolean;
}

/**
 * This interface defines configuration for route having mandatory {@link CanLeave} guard.
 */
export interface CanLeaveRouteConfig<C extends CanLeave> {
  leave: true;
  component: Type<C>;
}

/**
 * This interface defines configuration for route which does not have {@link CanLeave} guard.
 */
export interface CannotLeaveRouteConfig {
  leave?: false;
}

/**
 * This interface defines configuration for view initialization service.
 * Developer should provide
 * either service implementing {@link InitViewService} interface
 * or any service along with selector which retrieves data from the provided service
 */
export interface InitViewRouteConfig<I> {
  init?: Type<InitViewService> | {
    service: Type<I>;
    selector: string | ((service: I, route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) =>
      { [name: string]: Observable<any> } | any);
    noWaiting?: boolean;
  };
}

/**
 * This method creates application-specific routes and guarantees that route configuration is correct.
 *
 * All routes can have
 * - authentication guard ({@link AuthGuard}) that verifies user permissions before route is opened.
 * - leave guard ({@link LeaveGuard}) that allows component to control if user can leave the page.
 * - init data loader that allows to load initial data before view has opened
 *
 * @param route having authentication {@link AuthRouteConfig} and leave {@link LeaveRouteConfig} configuration
 */
// tslint:disable-next-line:max-line-length
export function createRoute<C extends CanLeave, I>(route:
                                                     BaseRouteConfig
                                                     & AuthRouteConfig
                                                     & CanLeaveRouteConfig<C>
                                                     & InitViewRouteConfig<I>): Route;
export function createRoute<V>(route: BaseRouteConfig & AuthRouteConfig & CannotLeaveRouteConfig & InitViewRouteConfig<V>): Route;
export function createRoute<V>(route: BaseRouteConfig & AuthRouteConfig & LeaveRouteConfig & InitViewRouteConfig<V>): Route {
  return {
    path: route.path,
    outlet: route.outlet,
    redirectTo: route.redirectTo,
    pathMatch: route.pathMatch,
    component: route.component,
    canActivate: route.auth ? [AuthGuard] : [],
    canDeactivate: [LeaveGuard],
    resolve: { init: InitViewResolver },
    data: {
      applicationView: route.auth ? route.auth.applicationView : [],
      permission: route.auth ? route.auth.permission : [],
      initView: route.init,
      static: route.static,
      leave: route.leave,
    },
    children: route.children,
  };
}

/**
 * Always returns snapshot of given route
 */
function getRouteSnapshot(route: ActivatedRoute | ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  return route instanceof ActivatedRoute ? route.snapshot : route;
}

/**
 * Returns root {@link ActivatedRouteSnapshot}
 */
export function getRouteRootSnapshot(route: ActivatedRoute | ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  let snapshot = getRouteSnapshot(route);
  while (snapshot.parent) {
    snapshot = snapshot.parent;
  }
  return snapshot;
}

export function getRouterEventName(event: Event): string {
  if (isNil(event)) {
    return 'null';
  } else if (event instanceof RouteConfigLoadStart) {
    return 'RouteConfigLoadStart';
  } else if (event instanceof RouteConfigLoadEnd) {
    return 'RouteConfigLoadEnd';
  } else if (event instanceof ChildActivationStart) {
    return 'ChildActivationStart';
  } else if (event instanceof ChildActivationEnd) {
    return 'ChildActivationEnd';
  } else if (event instanceof ActivationStart) {
    return 'ActivationStart';
  } else if (event instanceof ActivationEnd) {
    return 'ActivationEnd';
  } else if (event instanceof NavigationStart) {
    return 'NavigationStart';
  } else if (event instanceof NavigationEnd) {
    return 'NavigationEnd';
  } else if (event instanceof NavigationCancel) {
    return 'NavigationCancel';
  } else if (event instanceof NavigationError) {
    return 'NavigationError';
  } else if (event instanceof RoutesRecognized) {
    return 'RoutesRecognized';
  } else if (event instanceof GuardsCheckStart) {
    return 'GuardsCheckStart';
  } else if (event instanceof GuardsCheckEnd) {
    return 'GuardsCheckEnd';
  } else if (event instanceof ResolveStart) {
    return 'ResolveStart';
  } else if (event instanceof ResolveEnd) {
    return 'ResolveEnd';
  } else if (event instanceof Scroll) {
    return 'Scroll';
  } else if (event instanceof RouterEvent) {
    return 'RouterEvent';
  } else if (event['constructor']) {
    return event['constructor']['name'];
  } else {
    return 'unknown';
  }
}
