/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { logError } from '@gh/core-util';
import { distinctUntilChanged$, map$, mapTo$, scan$, startWith$, tap$ } from '@gh/rx/operators';
import { BehaviorSubject, Subject } from 'rxjs';
var INIT_CHILD_STATE_EVENT = { type: Symbol('init-child-state-event') };
var State = /** @class */ (function (_super) {
    __extends(State, _super);
    function State(initState, reducer) {
        var _this = _super.call(this, initState) || this;
        _this.initState = initState;
        _this.reducer = reducer;
        _this.events$$ = new Subject();
        _this.dumpOn = false;
        _this.events$$.pipe(scan$(reducer, initState), distinctUntilChanged$())
            .subscribe(function (state) {
            if (_this.dumpOn) {
                _this.dumpState(state);
            }
            _this.next(state);
        }, function (error) { return console.error(error); });
        return _this;
    }
    State.prototype.select = function (selector) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return this.pipe(distinctUntilChanged$(), map$(function (value) { return selector.apply(void 0, __spreadArrays([value], args)); }), distinctUntilChanged$());
    };
    State.prototype.project = function (selector) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return selector.apply(void 0, __spreadArrays([this.value], args));
    };
    State.prototype.mutate = function (event) {
        this.events$$.next(event);
    };
    State.prototype.createChild = function (factory, reducer) {
        var _this = this;
        return this.events$$.pipe(startWith$(INIT_CHILD_STATE_EVENT), scan$(function (state, event) {
            if (event === INIT_CHILD_STATE_EVENT) {
                // calculate initial state
                return factory(_this.value);
            }
            else {
                // transform the last state of calculated state
                return reducer(_this.value, state, event);
            }
        }, void 0));
    };
    State.prototype.dump = function () {
        this.dumpOn = true;
        this.dumpState(this.value);
    };
    State.prototype.dumpState = function (state) {
        console.log(state); // tslint:disable-line:no-console
    };
    return State;
}(BehaviorSubject));
export { State };
export function mutate(state) {
    return function (ob) { return ob.pipe(tap$({
        next: function (event) { return state.mutate(event); },
        error: logError,
    }), mapTo$(void 0)); };
}
