/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { ID } from '@gh/common-shared-data';
import { createEnum, EnumSet } from '@gh/core-data';

export type CustomerJobDto = {
  jobId: ID;
  jobNumber: string;
  officeId: ID;
  officeName: string;
  officeStatus: OfficeStatus;
  officePhone: string;
  siteAddress: string;
  addressee: string;
  contractPrice: number;
  jobAdminName: string;
  supervisorName: string;
  salesPersonName: string;
  isFinalized: boolean;
  isNotProceeding: boolean;
};

export const OfficeStatus = createEnum('OFFICE_STATUS', {
  New: 'NEW',
  Active: 'ACTIVE',
  Blocked: 'BLOCKED',
  Deactivated: 'DEACTIVATED',
});

export type OfficeStatus = EnumSet<typeof OfficeStatus>;
