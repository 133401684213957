var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Injector, Input, NgZone, Optional, Self, } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { coalesceObjectProperty } from '@gh/core-util';
import { filter$, first$ } from '@gh/rx/operators';
import { eq, inRange, isNil, isString, noop, remove } from 'lodash';
import { isUploadFinalEvent, UploadController, UploadEventType } from '../../../uploader';
import * as i0 from "@angular/core";
import * as i1 from "../../../uploader";
var WSC_CLASS_NAMES = ['wsc-spelling-problem', 'wsc-grammar-problem'];
/**
 * Cleans HTML content tree from wsc-* nodes
 * @param tree
 */
export var cleanContentTree = function (tree) {
    var stack = [tree];
    var _loop_1 = function () {
        var current = stack.pop(); // tslint:disable-line:no-non-null-assertion
        var className = current.attr('class');
        if (className && isString(className) && WSC_CLASS_NAMES.some(function (wscClassName) { return className.includes(wscClassName); })) {
            // remove node if it belongs to wsc-* node
            if (current.parent && current.firstChild) {
                stack.push(current.firstChild);
                current.parent.insert(current.firstChild, current);
                current.remove();
            }
        }
        else if (current.firstChild) {
            // traverse only parent elements
            var nextChild = current.firstChild;
            while (nextChild) {
                stack.push(nextChild);
                nextChild = nextChild.next;
            }
        }
    };
    while (stack.length) {
        _loop_1();
    }
    return tree;
};
var TinymceTextareaDirective = /** @class */ (function () {
    function TinymceTextareaDirective(zone, injector, elementRef, uploader) {
        this.zone = zone;
        this.injector = injector;
        this.elementRef = elementRef;
        this.uploader = uploader;
        this.required = false;
        this.disabled = false;
        this.plugins = [];
        this.onChangeCallback = noop;
        this.onTouchedCallback = noop;
        this.placeholders = [];
    }
    Object.defineProperty(TinymceTextareaDirective.prototype, "settings", {
        set: function (settings) {
            this._settings = coalesceObjectProperty(settings);
        },
        enumerable: true,
        configurable: true
    });
    TinymceTextareaDirective.prototype.ngOnInit = function () {
        if (isNil(this._settings)) {
            throw new Error('Settings is not specified for ghTinymce directive');
        }
        this.control = this.injector.get(NgControl);
    };
    TinymceTextareaDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (typeof tinymce === 'undefined') {
            throw new Error('TinymceTextareaDirective: tinymce is not loaded. Preload it using loadTinymce() function');
        }
        var options = __assign(__assign({}, this._settings), { target: this.elementRef.nativeElement, readonly: this.disabled, setup: function (editor) {
                _this.editor = editor;
                editor.on('change keydown', _this.keyboardIteractionsHandler(editor));
                editor.on('blur', function () { return _this.onTouchedCallback(); });
                // initialize tinymce serializer.
                // It is used to serialize HTML content tree manually after it was cleaned from wsc-* nodes
                _this.serializer = tinymce.html.Serializer(options, editor.schema ? editor.schema : tinymce.html.Schema(options));
            }, init_instance_callback: function (editor) {
                editor && _this.value && editor.setContent(_this.value);
            }, images_upload_handler: this.imageUploadHandler.bind(this) });
        tinymce.init(options);
    };
    TinymceTextareaDirective.prototype.ngOnDestroy = function () {
        try {
            var editor = this.editor;
            this.editor = void 0;
            tinymce.remove(editor);
        }
        catch (e) {
            console.warn('Possible Tinymce race condition.');
        }
    };
    Object.defineProperty(TinymceTextareaDirective.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (value) {
            var _this = this;
            if (value !== this.innerValue) {
                this.innerValue = value;
                this.zone.run(function () {
                    _this.onChangeCallback(value);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    TinymceTextareaDirective.prototype.insertPlaceholder = function (id) {
        if (!this.placeholders.includes(id)) {
            this.placeholders.push(id);
        }
    };
    TinymceTextareaDirective.prototype.removePlaceholder = function (id) {
        var placeholders = this.placeholders;
        if (placeholders.includes(id)) {
            this.placeholders = remove(placeholders, function (removeId) { return eq(removeId, id); });
        }
    };
    TinymceTextareaDirective.prototype.getPlaceholders = function () {
        return this.placeholders;
    };
    TinymceTextareaDirective.prototype.cleanPlaceholders = function () {
        this.placeholders = [];
    };
    TinymceTextareaDirective.prototype.writeValue = function (value) {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.editor && this.editor.setContent(value || '');
        }
    };
    TinymceTextareaDirective.prototype.registerOnChange = function (onChange) {
        this.onChangeCallback = onChange;
    };
    TinymceTextareaDirective.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    TinymceTextareaDirective.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
        if (this.editor) {
            this.editor.setMode(isDisabled ? 'readonly' : 'design');
        }
    };
    TinymceTextareaDirective.prototype.change = function (value) {
        this.innerValue = value;
        this.onChangeCallback(value);
    };
    TinymceTextareaDirective.prototype.keyboardIteractionsHandler = function (editor) {
        var _this = this;
        return function (event) {
            var element = editor.selection.getNode(); // current caret node
            // TODO: think about deny removing with next/prev elements
            // const nextElement = elememt.parentNode.parentNode.nextSibling;
            if (element.classList.contains('velocity-code')) {
                if (!inRange(event.keyCode, 37, 41)) { // tslint:disable-line no-magic-numbers
                    event.preventDefault();
                    return false;
                }
            }
            var tree = editor.getContent({ format: 'tree' });
            var content = _this.serializer.serialize(cleanContentTree(tree));
            _this.value = content;
        };
    };
    TinymceTextareaDirective.prototype.imageUploadHandler = function (blobInfo, success, failure) {
        if (this.uploader) {
            this.uploader.event$.pipe(filter$(function (event) { return event.item.uploadId === item_1.uploadId; }), first$(isUploadFinalEvent))
                .subscribe(function (event) {
                if (event.type === UploadEventType.Completed) {
                    success(event.item.location);
                }
                else {
                    failure('Uploading error');
                }
            });
            var item_1 = this.uploader.create({ name: blobInfo.filename(), blob: blobInfo.blob() });
            this.uploader.start(item_1);
        }
    };
    TinymceTextareaDirective.ɵfac = function TinymceTextareaDirective_Factory(t) { return new (t || TinymceTextareaDirective)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.UploadController, 10)); };
    TinymceTextareaDirective.ɵdir = i0.ɵɵdefineDirective({ type: TinymceTextareaDirective, selectors: [["", "ghTinymce", ""]], inputs: { required: "required", disabled: "disabled", plugins: "plugins", settings: ["ghTinymce", "settings"] }, exportAs: ["tinymceTextarea"], features: [i0.ɵɵProvidersFeature([{
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: TinymceTextareaDirective,
                    multi: true,
                }])] });
    return TinymceTextareaDirective;
}());
export { TinymceTextareaDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TinymceTextareaDirective, [{
        type: Directive,
        args: [{
                selector: '[ghTinymce]',
                providers: [{
                        provide: NG_VALUE_ACCESSOR,
                        useExisting: TinymceTextareaDirective,
                        multi: true,
                    }],
                exportAs: 'tinymceTextarea',
            }]
    }], function () { return [{ type: i0.NgZone }, { type: i0.Injector }, { type: i0.ElementRef }, { type: i1.UploadController, decorators: [{
                type: Optional
            }, {
                type: Self
            }] }]; }, { required: [{
            type: Input
        }], disabled: [{
            type: Input
        }], plugins: [{
            type: Input
        }], settings: [{
            type: Input,
            args: ['ghTinymce']
        }] }); })();
