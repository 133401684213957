/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule, ValueProvider } from '@angular/core';

import {
  CoreAuthModule,
  DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR,
  DECORATED_AUTH_PROVIDER,
  PermissionNameSetProvider,
} from '@gh/core-auth';
import { KeycloakAuthHttpRequestInterceptor } from './keycloak-auth-http-request-interceptor';
import { KEYCLOAK_LOGIN_OPTIONS, KEYCLOAK_LOGOUT_OPTIONS, KeycloakAuthProvider } from './keycloak-auth-provider';

import {
  KEYCLOAK_CONFIGURATION,
  KEYCLOAK_INIT_OPTIONS,
  KEYCLOAK_MIN_VALIDITY,
  KeycloakService,
} from './keycloak.service';

export interface KeycloakConfiguration {
  configuration: KeycloakModule.Configuration;
  initOptions?: KeycloakModule.InitOptions;
  loginOptions?: KeycloakModule.LoginOptions;
  logoutOptions?: KeycloakModule.RedirectUriOptions | RetFn<KeycloakModule.RedirectUriOptions>;
  minValidity: number;
}

export function buildKeycloakConfiguration(configuration: KeycloakConfiguration): ValueProvider[] {
  return [
    { provide: KEYCLOAK_CONFIGURATION, useValue: configuration.configuration },
    { provide: KEYCLOAK_INIT_OPTIONS, useValue: configuration.initOptions },
    { provide: KEYCLOAK_LOGIN_OPTIONS, useValue: configuration.loginOptions },
    { provide: KEYCLOAK_LOGOUT_OPTIONS, useValue: configuration.logoutOptions },
    { provide: KEYCLOAK_MIN_VALIDITY, useValue: configuration.minValidity },
  ];
}

@NgModule({
  imports: [
    CoreAuthModule.forRoot({
      authProvider: KeycloakAuthProvider,
      permissionProvider: PermissionNameSetProvider,
      authHttpInterceptor: KeycloakAuthHttpRequestInterceptor,
    }),
  ],
  providers: [
    KeycloakService,
    KeycloakAuthProvider,
    KeycloakAuthHttpRequestInterceptor,
  ],
  exports: [CoreAuthModule],
})
export class CoreKeycloakAuthModule {
  static asStandalone(configuration: KeycloakConfiguration): ModuleWithProviders {
    return {
      ngModule: CoreKeycloakAuthModule,
      providers: [
        buildKeycloakConfiguration(configuration),
      ],
    };
  }

  static asDecorated(configuration: KeycloakConfiguration): ModuleWithProviders {
    return {
      ngModule: CoreKeycloakAuthModule,
      providers: [
        buildKeycloakConfiguration(configuration),
        { provide: DECORATED_AUTH_PROVIDER, useExisting: KeycloakAuthProvider },
        { provide: DECORATED_AUTH_HTTP_REQUEST_INTERCEPTOR, useExisting: KeycloakAuthHttpRequestInterceptor },
      ],
    };
  }
}
