/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

const localeCache = {};

export class Locale {
  static create(str: string): Locale;
  static create(language: string, country: string): Locale;
  static create(language: string, country?: string): Locale {
    const code = language + (country ? '-' + country : '');

    let locale = localeCache[code];

    if (!locale) {
      const parts = code.split('-');
      locale = localeCache[code] = new Locale(parts[0], parts[1]);
    }

    return locale;
  }

  static createFromStandard(str: string): Locale {
    return Locale.create(str.replace(/_/g, '-'));
  }

  readonly code: string;
  private standard: string;

  private constructor(
    readonly language: string,
    readonly country: string,
  ) {
    this.code = this.language + (this.country ? '-' + this.country : '');
    this.standard = this.language + (this.country ? '_' + this.country : '');
  }

  toString(): string {
    return this.code;
  }

  toStandardString(): string {
    return this.standard;
  }
}
