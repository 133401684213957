/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { first$ } from '@gh/rx/operators';
import { Subscription } from 'rxjs';

/**
 * This structure directive allows to manage rendering of some block manually.
 * This is especially convenient when developer needs to refresh non-angular component which does not support
 * reactive data update => developer can refresh content using {@link RendererDirective#refresh} method.
 */
@Directive({
  selector: '[ghRenderer]',
  exportAs: 'ghRenderer',
})
export class RendererDirective implements OnInit, OnDestroy {
  @Output('ghRendererAttached') attached = new EventEmitter<void>();
  @Output('ghRendererDetached') detached = new EventEmitter<void>();

  private _rendered: boolean = true;
  private hasView = false;
  private subscription?: Subscription;

  @Input()
  set rendered(rendered: any) {
    this._rendered = coerceBooleanProperty(rendered);
    if (this._rendered && !this.hasView) {
      this.render();
    } else if (!this._rendered && this.hasView) {
      this.destroy();
    }
  }

  get rendered(): any {
    return this._rendered;
  }

  constructor(private ngZone: NgZone,
              private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this._rendered) {
      this.render();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Refreshes content by destroying and rendering view for attached template.
   */
  refresh(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.destroy();
    this.subscription = this.ngZone.onMicrotaskEmpty.pipe(first$()).subscribe(() => {
      this.render();
      this.subscription = void 0;
    });
  }

  private render(): void {
    if (!this.hasView) {
      this.hasView = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.changeDetectorRef.markForCheck();
      this.attached.emit();
    }
  }

  private destroy(): void {
    if (this.hasView) {
      this.hasView = false;
      this.viewContainerRef.clear();
      this.changeDetectorRef.markForCheck();
      this.detached.emit();
    }
  }
}
