/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import * as i0 from "@angular/core";
var ErrorModule = /** @class */ (function () {
    function ErrorModule() {
        throw new Error('Angular configuration is wrong. Error module should never come into Angular Configuration');
    }
    ErrorModule.ɵmod = i0.ɵɵdefineNgModule({ type: ErrorModule });
    ErrorModule.ɵinj = i0.ɵɵdefineInjector({ factory: function ErrorModule_Factory(t) { return new (t || ErrorModule)(); } });
    return ErrorModule;
}());
export { ErrorModule };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ErrorModule, [{
        type: NgModule
    }], function () { return []; }, null); })();
var NoopModule = /** @class */ (function () {
    function NoopModule() {
    }
    NoopModule.ɵmod = i0.ɵɵdefineNgModule({ type: NoopModule });
    NoopModule.ɵinj = i0.ɵɵdefineInjector({ factory: function NoopModule_Factory(t) { return new (t || NoopModule)(); } });
    return NoopModule;
}());
export { NoopModule };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NoopModule, [{
        type: NgModule
    }], null, null); })();
