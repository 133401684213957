/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[ghExtIfQuery]',
})
export class ExtIfQueryDirective implements OnInit, OnDestroy {
  private _breakpoint: string;
  private rendered: boolean = false;
  private mediaSubscription: Subscription;

  @Input('ghExtIfQuery')
  set breakpoint(breakpoint: string) {
    this._breakpoint = breakpoint;

    this.checkView();
  }

  get breakpoint(): string {
    return this._breakpoint;
  }

  constructor(private observer: MediaObserver,
              private viewContainerRef: ViewContainerRef,
              private template: TemplateRef<any>) {
  }

  ngOnInit(): void {
    this.checkView();
    this.mediaSubscription = this.observer.media$.subscribe(() => this.checkView());
  }

  ngOnDestroy(): void {
    if (this.mediaSubscription) {
      this.mediaSubscription.unsubscribe();
    }
  }

  private checkView(): void {
    const shouldBeRendered = this.observer.isActive(this.breakpoint);
    if (this.rendered === shouldBeRendered) {
      return;
    }

    if (this.rendered) {
      this.viewContainerRef.clear();
    }
    if (shouldBeRendered) {
      this.viewContainerRef.createEmbeddedView(this.template);
    }

    this.rendered = shouldBeRendered;
  }
}
