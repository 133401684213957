/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, } from '@angular/forms';
import * as invariant from 'invariant';
import { isNil, isString, noop } from 'lodash';
import { TypeService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services";
var DataTypeDirective = /** @class */ (function () {
    function DataTypeDirective(renderer, elementRef, typeService) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.typeService = typeService;
        this._onChange = noop;
    }
    Object.defineProperty(DataTypeDirective.prototype, "type", {
        set: function (type) {
            this._type = isString(type) ? this.typeService.get(type) : type;
        },
        enumerable: true,
        configurable: true
    });
    DataTypeDirective.prototype.ngOnInit = function () {
        if (this._type['maxLength']) {
            this.renderer.setAttribute(this.elementRef.nativeElement, 'maxlength', String(this._type['maxLength']));
        }
        invariant(this._type, 'Type is undefined for ghDataType directive');
    };
    DataTypeDirective.prototype.onChange = function (str) {
        if (str) {
            this.formatErrors = this._type.validateFormat(str);
            if (!this.formatErrors) {
                this._onChange(this._type.parse(str));
            }
            else {
                this._onChange(void 0);
            }
        }
        else {
            this.formatErrors = undefined;
            this._onChange(void 0);
        }
    };
    DataTypeDirective.prototype.onTouched = function () {
        // nothing to do
    };
    DataTypeDirective.prototype.writeValue = function (value) {
        var normalizedValue = isNil(value) ? '' : this._type.format(value);
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', normalizedValue);
        this.formatErrors = undefined;
    };
    DataTypeDirective.prototype.registerOnChange = function (fn) {
        this._onChange = fn;
    };
    DataTypeDirective.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    DataTypeDirective.prototype.setDisabledState = function (isDisabled) {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    };
    DataTypeDirective.prototype.validate = function (c) {
        return this.formatErrors;
    };
    DataTypeDirective.ɵfac = function DataTypeDirective_Factory(t) { return new (t || DataTypeDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.TypeService)); };
    DataTypeDirective.ɵdir = i0.ɵɵdefineDirective({ type: DataTypeDirective, selectors: [["", "ghDataType", ""]], hostBindings: function DataTypeDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("input", function DataTypeDirective_input_HostBindingHandler($event) { return ctx.onChange($event.target.value); })("blur", function DataTypeDirective_blur_HostBindingHandler() { return ctx.onTouched(); });
        } }, inputs: { type: ["ghDataType", "type"] }, features: [i0.ɵɵProvidersFeature([{
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: DataTypeDirective,
                    multi: true,
                }, {
                    provide: NG_VALIDATORS,
                    useExisting: DataTypeDirective,
                    multi: true,
                }])] });
    return DataTypeDirective;
}());
export { DataTypeDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DataTypeDirective, [{
        type: Directive,
        args: [{
                selector: '[ghDataType]',
                providers: [{
                        provide: NG_VALUE_ACCESSOR,
                        useExisting: DataTypeDirective,
                        multi: true,
                    }, {
                        provide: NG_VALIDATORS,
                        useExisting: DataTypeDirective,
                        multi: true,
                    }],
            }]
    }], function () { return [{ type: i0.Renderer2 }, { type: i0.ElementRef }, { type: i1.TypeService }]; }, { type: [{
            type: Input,
            args: ['ghDataType']
        }], onChange: [{
            type: HostListener,
            args: ['input', ['$event.target.value']]
        }], onTouched: [{
            type: HostListener,
            args: ['blur']
        }] }); })();
