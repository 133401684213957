/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable, NgZone } from '@angular/core';
import * as i0 from "@angular/core";
var InputState = /** @class */ (function () {
    function InputState(ngZone) {
        var _this = this;
        this.ngZone = ngZone;
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.ngZone.runOutsideAngular(function () {
            document.addEventListener('mousedown', _this.onMouseDown);
            document.addEventListener('mouseup', _this.onMouseUp);
            document.addEventListener('touchstart', _this.onTouchStart);
            document.addEventListener('touchend', _this.onTouchEnd);
        });
    }
    Object.defineProperty(InputState.prototype, "mouseDown", {
        get: function () {
            return this._mouseDown;
        },
        set: function (_) {
            throw new Error('mouseDown field cannot be updated');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputState.prototype, "touchInProcess", {
        get: function () {
            return this._touchInProcess;
        },
        set: function (_) {
            throw new Error('touchInProcess field cannot be updated');
        },
        enumerable: true,
        configurable: true
    });
    InputState.prototype.ngOnDestroy = function () {
        document.removeEventListener('mousedown', this.onMouseDown);
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('touchstart', this.onTouchStart);
        document.removeEventListener('touchend', this.onTouchEnd);
    };
    InputState.prototype.onMouseDown = function () {
        this._mouseDown = true;
    };
    InputState.prototype.onMouseUp = function () {
        this._mouseDown = false;
    };
    InputState.prototype.onTouchStart = function () {
        this._touchInProcess = true;
    };
    InputState.prototype.onTouchEnd = function () {
        this._touchInProcess = false;
    };
    InputState.ɵfac = function InputState_Factory(t) { return new (t || InputState)(i0.ɵɵinject(i0.NgZone)); };
    InputState.ɵprov = i0.ɵɵdefineInjectable({ token: InputState, factory: InputState.ɵfac });
    return InputState;
}());
export { InputState };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(InputState, [{
        type: Injectable
    }], function () { return [{ type: i0.NgZone }]; }, null); })();
