/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { pull, uniqueId } from 'lodash';

const history: WaitingProcess[] = [];

window['waitingHistory'] = history;

/**
 * This interface allows to manage {@link WaitingProcess}.
 */
export interface WaitingProcess {
  id: string;
  /**
   * Stops waiting process
   */
  stop(): void;
}

/**
 * Creates the simplest waiting process using the completion handler.
 * Completion handler can run only one time.
 *
 * @param name
 * @param completer
 * @returns {{stop: (()=>undefined)}}
 */
export function createWaitingProcess(name: string, completer?: () => void): WaitingProcess {
  const id = uniqueId('w:');

  const process = {
    id,
    stop: () => {
      if (completer) {
        completer();

        console.debug('waiting: %s (%s) [stop]', id, name);
        pull(history, process);

        completer = void 0;
      }
    },
  };

  console.debug('waiting: %s (%s) [start]', id, name);
  history.push(process);

  return process;
}
