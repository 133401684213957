/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { logError } from '@gh/core-util';
import { isNil } from 'lodash';
import { UserContext } from '../user/user-context';
import * as i0 from "@angular/core";
import * as i1 from "../user/user-context";
export var HELP_SYSTEM = new InjectionToken('HelpSystem');
var HelpProvider = /** @class */ (function () {
    function HelpProvider(userContext, helpSystem) {
        this.userContext = userContext;
        this.helpSystem = helpSystem;
        this.helpStack = [];
    }
    HelpProvider.prototype.getCurrent = function () {
        var helpStack = this.helpStack;
        return helpStack[helpStack.length - 1];
    };
    HelpProvider.prototype.setCurrent = function (current) {
        if (!this.helpSystem.has(current)) {
            logError("HelpProvider: there is no page associated with help id \"" + current + "\"");
        }
        this.helpStack.push(current);
    };
    HelpProvider.prototype.resetCurrent = function () {
        this.helpStack.pop();
    };
    HelpProvider.prototype.hasForCurrent = function () {
        var current = this.getCurrent();
        return current ? this.has(current) : false;
    };
    HelpProvider.prototype.has = function (helpId) {
        return this.helpSystem.has(helpId);
    };
    HelpProvider.prototype.open = function (helpId) {
        if (isNil(this.helpSystem)) {
            throw new Error('Help System is not supported');
        }
        this.helpSystem.open(helpId);
    };
    HelpProvider.ɵfac = function HelpProvider_Factory(t) { return new (t || HelpProvider)(i0.ɵɵinject(i1.UserContext), i0.ɵɵinject(HELP_SYSTEM, 8)); };
    HelpProvider.ɵprov = i0.ɵɵdefineInjectable({ token: HelpProvider, factory: HelpProvider.ɵfac });
    return HelpProvider;
}());
export { HelpProvider };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(HelpProvider, [{
        type: Injectable
    }], function () { return [{ type: i1.UserContext }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [HELP_SYSTEM]
            }] }]; }, null); })();
