<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<div class="m-content" fxLayout="column" fxLayoutAlign="start center">
  <img class="m-image" fxShow="" [fxHide.lt-md]="!forceIcon" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMjEuNjUgMjIxLjgwNyI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+CiAgICAgICAgICAgIC5jbHMtMXtmaWxsOiNhMmEyYTJ9CiAgICAgICAgPC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJpY25fc2VhcmNoIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODc3IC0yOTcpIj4KICAgICAgICA8cGF0aCBpZD0iUmVjdGFuZ2xlXzE4MyIgZD0iTTAgMGgxNnY4NEgweiIgY2xhc3M9ImNscy0xIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxODMiIHRyYW5zZm9ybT0icm90YXRlKC00NSAxMDY4LjUyNiAtMTAxMS4xMjcpIi8+CiAgICAgICAgPHBhdGggaWQ9IlN1YnRyYWN0aW9uXzEiIGQ9Ik04OCAxODdhODguNjQ5IDg4LjY0OSAwIDAgMS0xNy43MzUtMS43ODhBODcuNTE4IDg3LjUxOCAwIDAgMSAzOC44IDE3MS45NzFhODguMjU4IDg4LjI1OCAwIDAgMS0zMS44ODQtMzguNzE3IDg3LjU1NiA4Ny41NTYgMCAwIDEtNS4xMjgtMTYuNTE5IDg4Ljg1NiA4OC44NTYgMCAwIDEgMC0zNS40N0E4Ny41MTggODcuNTE4IDAgMCAxIDE1LjAyOSA0OS44YTg4LjI1OCA4OC4yNTggMCAwIDEgMzguNzE4LTMxLjg4NSA4Ny41NTcgODcuNTU3IDAgMCAxIDE2LjUxOS01LjEyOCA4OC44NTYgODguODU2IDAgMCAxIDM1LjQ3IDBBODcuNTE4IDg3LjUxOCAwIDAgMSAxMzcuMiAyNi4wMjlhODguMjU4IDg4LjI1OCAwIDAgMSAzMS44ODMgMzguNzE4IDg3LjU1NiA4Ny41NTYgMCAwIDEgNS4xMjggMTYuNTE5IDg4Ljg1NiA4OC44NTYgMCAwIDEgMCAzNS40NyA4Ny41MTggODcuNTE4IDAgMCAxLTEzLjI0IDMxLjQ2NCA4OC4yNTggODguMjU4IDAgMCAxLTM4LjcxOCAzMS44ODMgODcuNTU2IDg3LjU1NiAwIDAgMS0xNi41MTkgNS4xMjhBODguNjQ5IDg4LjY0OSAwIDAgMSA4OCAxODd6bTAtMTYzYTc0LjY1MSA3NC42NTEgMCAwIDAtNDEuOTMzIDEyLjgwOSA3NS4yMTggNzUuMjE4IDAgMCAwLTI3LjE3MyAzMyA3NC45IDc0LjkgMCAwIDAgNi45MTUgNzEuMTI3IDc1LjIyIDc1LjIyIDAgMCAwIDMzIDI3LjE3MyA3NC45IDc0LjkgMCAwIDAgNzEuMTI3LTYuOTE1IDc1LjIxOCA3NS4yMTggMCAwIDAgMjcuMTczLTMzIDc0LjkgNzQuOSAwIDAgMC02LjkxNS03MS4xMjcgNzUuMjE3IDc1LjIxNyAwIDAgMC0zMy0yNy4xNzNBNzQuNTMxIDc0LjUzMSAwIDAgMCA4OCAyNHoiIGNsYXNzPSJjbHMtMSIgZGF0YS1uYW1lPSJTdWJ0cmFjdGlvbiAxIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4NzcgMjg2KSIvPgogICAgPC9nPgo8L3N2Zz4K">
  <div class="m-no-data">
    <span *ngIf="initial" class="ext-primary-color">{{ initial }}</span>
    <span *ngIf="dataName">{{ dataName }}</span>
  </div>
  <div *ngIf="message" class="m-no-data-title">{{ message }}</div>
</div>
