/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { IGNORE_DIRTY_CHANGES } from '@gh/config';
import { TranslationService } from '@gh/core-mls';
import { remove } from 'lodash';
import { isViewDirty } from './can-leave.interfaces';

export type ComponentEntry = {
  component: any;
  ignoreDirty: boolean;
};

@Injectable()
export class ApplicationComponentState {
  private _entries: ComponentEntry[] = [];

  constructor(private translationService: TranslationService) {
    if (!IGNORE_DIRTY_CHANGES) {
      window.addEventListener('beforeunload', (event) => this.onBeforeUnload(event));
    }
  }

  get components(): any[] {
    return this._entries.map((entry) => entry.component);
  }

  activate(component: any): void {
    this._entries.push({ component, ignoreDirty: false });
  }

  deactivate(component: any): void {
    remove(this._entries, (entry) => entry.component === component);
  }

  hasDirtyChanges(): boolean {
    return this._entries.filter((entry) => !entry.ignoreDirty).some((entry) => isViewDirty(entry.component));
  }

  setIgnoreDirty(): void {
    this._entries.forEach((entry) => entry.ignoreDirty = true);
  }

  private onBeforeUnload(event: Event): Maybe<string> {
    if (!this.hasDirtyChanges()) {
      return;
    }

    const message = this.translationService.get('msg_before_unload');
    event.returnValue = <any>message;
    return message;
  }
}
