/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { DATA_SERVICES } from './services';
import * as i0 from "@angular/core";
var CommonSharedDataModule = /** @class */ (function () {
    function CommonSharedDataModule() {
    }
    CommonSharedDataModule.forRoot = function () {
        return {
            ngModule: CommonSharedDataModule,
            providers: DATA_SERVICES,
        };
    };
    CommonSharedDataModule.forChild = function () {
        return {
            ngModule: CommonSharedDataModule,
            providers: [],
        };
    };
    CommonSharedDataModule.ɵmod = i0.ɵɵdefineNgModule({ type: CommonSharedDataModule });
    CommonSharedDataModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CommonSharedDataModule_Factory(t) { return new (t || CommonSharedDataModule)(); }, imports: [[]] });
    return CommonSharedDataModule;
}());
export { CommonSharedDataModule };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CommonSharedDataModule, [{
        type: NgModule,
        args: [{
                imports: [],
                declarations: [],
                exports: [],
            }]
    }], null, null); })();
