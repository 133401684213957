var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { isFunction } from 'lodash';
import { collectVisibleValues, toAppFormGroup } from './controls';
import { FormSchemaFactory } from './form-schema/form-schema-factory';
import { FormState } from './form-state';
import * as i0 from "@angular/core";
import * as i1 from "./form-schema/form-schema-factory";
var PREDEFINED_FORM_SUBMIT_VALUES = {
    'all': function (group) { return group.getRawValue(); },
    'visible': collectVisibleValues,
};
function getFormSubmitValueFn(submitValue) {
    return isFunction(submitValue) ? submitValue : PREDEFINED_FORM_SUBMIT_VALUES[submitValue || 'visible'];
}
var PlainFormState = /** @class */ (function (_super) {
    __extends(PlainFormState, _super);
    function PlainFormState(schemaFactory, rootConfig) {
        var _this = _super.call(this, schemaFactory) || this;
        _this.rootConfig = rootConfig;
        return _this;
    }
    Object.defineProperty(PlainFormState.prototype, "submitValue", {
        get: function () {
            return getFormSubmitValueFn(this.rootConfig.submitValue)(toAppFormGroup(this.group));
        },
        enumerable: true,
        configurable: true
    });
    PlainFormState.prototype.buildSchema = function (value, _a) {
        var root = _a.root;
        return root(this.rootConfig);
    };
    return PlainFormState;
}(FormState));
var FunctionalFormState = /** @class */ (function (_super) {
    __extends(FunctionalFormState, _super);
    function FunctionalFormState(schemaFactory, schemaFn) {
        var _this = _super.call(this, schemaFactory) || this;
        _this.schemaFn = schemaFn;
        return _this;
    }
    FunctionalFormState.prototype.buildSchema = function (value, schema) {
        return this.schemaFn(value, schema);
    };
    return FunctionalFormState;
}(FormState));
var FormStateBuilder = /** @class */ (function () {
    function FormStateBuilder(schemaFactory) {
        this.schemaFactory = schemaFactory;
    }
    FormStateBuilder.prototype.buildPlain = function (root) {
        var state = new PlainFormState(this.schemaFactory, root);
        state.initSchema(root.value);
        if (root.snapshot) {
            state.restore(root.snapshot);
        }
        return state;
    };
    FormStateBuilder.prototype.buildFunctional = function (original, schemaFn) {
        var state = new FunctionalFormState(this.schemaFactory, schemaFn);
        state.build(original);
        return state;
    };
    FormStateBuilder.ɵfac = function FormStateBuilder_Factory(t) { return new (t || FormStateBuilder)(i0.ɵɵinject(i1.FormSchemaFactory)); };
    FormStateBuilder.ɵprov = i0.ɵɵdefineInjectable({ token: FormStateBuilder, factory: FormStateBuilder.ɵfac });
    return FormStateBuilder;
}());
export { FormStateBuilder };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FormStateBuilder, [{
        type: Injectable
    }], function () { return [{ type: i1.FormSchemaFactory }]; }, null); })();
