/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';

export const HTTP_STATUS_NO_CONTENT = 204;
export const HTTP_STATUS_ERROR_START = 400;
export const HTTP_STATUS_ERROR_END = 600;
export const HTTP_STATUS_CLIENT_ERROR_START = 400;
export const HTTP_STATUS_CLIENT_ERROR_END = 500;
export const HTTP_STATUS_SERVER_ERROR_START = 500;
export const HTTP_STATUS_SERVER_ERROR_END = 600;
export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_NOT_AUTHORIZED = 401;
export const HTTP_STATUS_NOT_FOUND_ERROR = 404;
export const HTTP_STATUS_PRECONDITION_FAILED_ERROR = 422;
export const HTTP_STATUS_SERVER_UNAVAILABLE_ERROR = 503;

export enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
  Options = 'OPTIONS',
  Head = 'HEAD',
}

export function isResponseHttpEvent<T>(event: HttpEvent<T>): event is HttpResponse<T>;
export function isResponseHttpEvent<T>(event: HttpResponse<T> | HttpErrorResponse): event is HttpResponse<T>;
export function isResponseHttpEvent<T>(event: HttpEvent<T> | HttpErrorResponse): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

export function isErrorStatus(status: number): boolean {
  return status >= HTTP_STATUS_ERROR_START && status < HTTP_STATUS_ERROR_END;
}

export function isClientErrorStatus(status: number): boolean {
  return status >= HTTP_STATUS_CLIENT_ERROR_START && status < HTTP_STATUS_CLIENT_ERROR_END;
}

export function isServerErrorStatus(status: number): boolean {
  return status === 0 || status >= HTTP_STATUS_SERVER_ERROR_START && status < HTTP_STATUS_SERVER_ERROR_END;
}
