/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { createDialogRoute } from '@gh/common-shared';
import { DialogInput } from '@gh/core-ui';
import { JobInfo } from '@gh/customer-shared';

export interface CustomerJobInfoDialogConfig {
  job: JobInfo;
}

@Component({
  selector: 'gh-customer-job-info-dialog',
  templateUrl: 'customer-job-info-dialog.component.html',
  styleUrls: ['customer-job-info-dialog.component.scss'],
})
export class CustomerJobInfoDialogComponent implements DialogInput<CustomerJobInfoDialogConfig> {
  input: CustomerJobInfoDialogConfig;

  get job(): JobInfo {
    return this.input.job;
  }

  constructor(private dialogRef: MatDialogRef<any>) {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}

export const customerJobInfoDialogRoute = createDialogRoute({
  component: CustomerJobInfoDialogComponent,
  config: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});
