<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<gh-ext-dialog>
    <gh-ext-dialog-content fxLayout="column" fxLayoutAlign="start center">
        <mat-icon svgIcon="gh:{{ iconName }}" class="m-icon" ngClass.lt-md="s150" ngClass.gt-sm="s200" ngClass="m-icon-{{ iconColorName }}"></mat-icon>
        <span class="m-message">{{ message }}</span>
    </gh-ext-dialog-content>

    <gh-ext-dialog-button-bar class="button-bar clearfix" align="center">
        <button mat-raised-button="" color="primary" class="large" (click)="dialogRef.close()">{{'lbl_ok' | translate}}</button>
    </gh-ext-dialog-button-bar>
</gh-ext-dialog>