var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { AuthState } from '@gh/core-auth';
import { denull, parseQueryString } from '@gh/core-util';
import { delay$, map$ } from '@gh/rx/operators';
import { isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { MockAuthProvider } from '../mock-auth-provider.service';
import * as i0 from "@angular/core";
function getAuthState() {
    var mockUserEmail = parseQueryString(window.location.search).mockUserEmail;
    return denull(mockUserEmail);
}
var SecondaryMockAuthProvider = /** @class */ (function (_super) {
    __extends(SecondaryMockAuthProvider, _super);
    function SecondaryMockAuthProvider() {
        var _this = _super.call(this) || this;
        _this.userKey$$ = new BehaviorSubject(getAuthState());
        _this.onAuthState = _this.userKey$$.pipe(map$(function (key) { return key ? AuthState.LoggedIn : AuthState.LogggedOut; }), delay$(0));
        _this.onAuthState.subscribe(function (state) {
            _this._authState = state;
        });
        return _this;
    }
    Object.defineProperty(SecondaryMockAuthProvider.prototype, "authState", {
        get: function () {
            return this._authState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecondaryMockAuthProvider.prototype, "userKey", {
        get: function () {
            var value = this.userKey$$.value;
            if (value) {
                return value;
            }
            else {
                throw new Error('User is not logged in');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecondaryMockAuthProvider.prototype, "use", {
        get: function () {
            return !isNil(this.userKey$$.value);
        },
        enumerable: true,
        configurable: true
    });
    SecondaryMockAuthProvider.prototype.login = function () {
        throw new Error('Login is not implemented for SecondaryMockAuthProvider');
    };
    SecondaryMockAuthProvider.prototype.logout = function () {
        throw new Error('Logout is not implemented for SecondaryMockAuthProvider');
    };
    SecondaryMockAuthProvider.ɵfac = function SecondaryMockAuthProvider_Factory(t) { return new (t || SecondaryMockAuthProvider)(); };
    SecondaryMockAuthProvider.ɵprov = i0.ɵɵdefineInjectable({ token: SecondaryMockAuthProvider, factory: SecondaryMockAuthProvider.ɵfac });
    return SecondaryMockAuthProvider;
}(MockAuthProvider));
export { SecondaryMockAuthProvider };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SecondaryMockAuthProvider, [{
        type: Injectable
    }], function () { return []; }, null); })();
