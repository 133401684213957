/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource as MatDataSource } from '@angular/cdk/table';
import { MatSort, Sort } from '@angular/material/sort';
import { getSlicedData, SlicedDataQuery, SlicedDataSource, SortDirection } from '@gh/core-data';
import { merge$, of$ } from '@gh/rx';
import { map$, mapTo$, switchMap$ } from '@gh/rx/operators';
import { BehaviorSubject, Observable } from 'rxjs';

const TABLE_QUERY_RESULT = Symbol('TableQueryResult');
const QUERY_ITEM_INDEX = Symbol('QueryItemIndex');

export type TableQueryResult<T> = T[] & { query: SlicedDataQuery };

export function createQueryResult<T>(query: SlicedDataQuery, items: T[]): TableQueryResult<T> {
  items.forEach((item, i) => mergeQueryItemIndex(item, query.from + i));
  const result = Object.create(items);
  result.query = query;
  result[TABLE_QUERY_RESULT] = true;
  return result;
}

export function isQueryResult<T>(array: T[]): array is TableQueryResult<T> {
  return array[TABLE_QUERY_RESULT] === true;
}

export function toQueryResult<T>(array: T[]): TableQueryResult<T> {
  if (isQueryResult(array)) {
    return array;
  }
  throw new Error('Provided array is not a TableQueryResult');
}

function mergeQueryItemIndex<T>(item: T, index: number): void {
  item[QUERY_ITEM_INDEX] = index;
}

export function getQueryItemIndex<T>(item: T): number {
  return item[QUERY_ITEM_INDEX];
}

export class InfiniteMatDataSource<T> implements MatDataSource<T> {
  static wrap<T>(dataSource: SlicedDataSource<T>): InfiniteMatDataSource<T> {
    return new InfiniteMatDataSource(dataSource);
  }

  sort?: MatSort;

  private fire$$ = new BehaviorSubject<void>(void 0);

  constructor(private dataSource: SlicedDataSource<T>) {
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    const { sort } = this;
    const sort$ = sort ? merge$<undefined, Sort>(sort.initialized.pipe(mapTo$(void 0)), sort.sortChange) : of$(void 0);
    return collectionViewer.viewChange.pipe(
      switchMap$((range) => sort$.pipe(map$((sortState) => ({ ...range, sort: sortState })))),
      map$(({ start, end, sort: sortState }) => ({
        from: start,
        to: Math.min(end, Number.MAX_SAFE_INTEGER),
        sorting: sortState && sortState.direction
          ? {
            field: sortState.active,
            direction: sortState.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending,
          }
          : void 0,
      })),
      switchMap$((query) => this.fire$$.pipe(mapTo$(query))),
      switchMap$((query) => this.dataSource.select(query).pipe(
        map$(getSlicedData),
        map$((items) => createQueryResult(query, items)))));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  fire(): void {
    this.fire$$.next(void 0);
  }
}
