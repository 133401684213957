/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as invariant from 'invariant';
import { isNil, isString, noop } from 'lodash';
import { TypeService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services";
/**
 * This directive is assigned to date-based control to format value (date value).
 *
 * <b>Note!</b> This directive is not intended to be used for editable inputs.
 * If developer needs to enter date he should use MatDatepicker module from @angular/material library
 */
var DateTypeDirective = /** @class */ (function () {
    function DateTypeDirective(renderer, elementRef, typeService) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.typeService = typeService;
        this.readonly = true;
        this._onChange = noop;
    }
    Object.defineProperty(DateTypeDirective.prototype, "type", {
        set: function (type) {
            this._type = isString(type) ? this.typeService.get(type) : type;
        },
        enumerable: true,
        configurable: true
    });
    DateTypeDirective.prototype.ngOnInit = function () {
        invariant(this._type, 'Type is undefined for ghDataType directive');
    };
    DateTypeDirective.prototype.writeValue = function (value) {
        var normalizedValue = isNil(value) ? '' : this._type.format(value);
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', normalizedValue);
    };
    DateTypeDirective.prototype.registerOnChange = function (fn) {
        this._onChange = fn;
    };
    DateTypeDirective.prototype.registerOnTouched = function (fn) {
        // nothing to do
    };
    DateTypeDirective.ɵfac = function DateTypeDirective_Factory(t) { return new (t || DateTypeDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.TypeService)); };
    DateTypeDirective.ɵdir = i0.ɵɵdefineDirective({ type: DateTypeDirective, selectors: [["", "ghDateType", ""]], hostVars: 1, hostBindings: function DateTypeDirective_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵattribute("readonly", ctx.readonly);
        } }, inputs: { type: ["ghDateType", "type"] }, features: [i0.ɵɵProvidersFeature([{
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: DateTypeDirective,
                    multi: true,
                }])] });
    return DateTypeDirective;
}());
export { DateTypeDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DateTypeDirective, [{
        type: Directive,
        args: [{
                selector: '[ghDateType]',
                providers: [{
                        provide: NG_VALUE_ACCESSOR,
                        useExisting: DateTypeDirective,
                        multi: true,
                    }],
            }]
    }], function () { return [{ type: i0.Renderer2 }, { type: i0.ElementRef }, { type: i1.TypeService }]; }, { readonly: [{
            type: HostBinding,
            args: ['attr.readonly']
        }], type: [{
            type: Input,
            args: ['ghDateType']
        }] }); })();
