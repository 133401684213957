/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { assign, isFunction, isNil, isString } from 'lodash';
import { Step } from './action.interfaces';

export type StateMerger<FS> = Partial<FS> | ((state: FS) => FS);

export function normalizeStep(step: Step | string): Step {
  return isString(step) ? { type: step } : step;
}

export function getMergedState<FS>(state: FS, merge?: StateMerger<FS>): FS {
  if (isFunction(merge)) {
    return merge(state);
  } else if (!isNil(merge)) {
    return assign({}, state, merge);
  } else {
    return state;
  }
}
