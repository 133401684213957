/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Pipe({
  name: 'connect',
  pure: false, // tslint:disable-line:pipe-impure
})
export class ConnectPipe implements PipeTransform, OnDestroy {
  private async = new AsyncPipe(this.changeDetectorRef);

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.async.ngOnDestroy(); // tslint:disable-line:no-life-cycle-call
  }

  transform<T>(dataSource: MatTableDataSource<T>): Nullable<T[]> {
    if (!(dataSource instanceof MatTableDataSource)) {
      throw new Error('ConnectPipe: connect pipe works only with instance of MatTableDataSource');
    }
    return this.async.transform(dataSource.connect());
  }
}
