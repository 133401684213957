var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { AbstractRestService, RestSettings } from '@gh/core-data';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-data";
var ExtUploaderService = /** @class */ (function (_super) {
    __extends(ExtUploaderService, _super);
    function ExtUploaderService(settings) {
        var _this = _super.call(this, settings) || this;
        _this.settings = settings;
        return _this;
    }
    ExtUploaderService.prototype.upload = function (url, file) {
        var body = new FormData();
        body.append('fileName', file.name);
        body.append('mimeType', file.type);
        body.append('file', file);
        return this.httpClient.post(this.url(url), body);
    };
    ExtUploaderService.ɵfac = function ExtUploaderService_Factory(t) { return new (t || ExtUploaderService)(i0.ɵɵinject(i1.RestSettings)); };
    ExtUploaderService.ɵprov = i0.ɵɵdefineInjectable({ token: ExtUploaderService, factory: ExtUploaderService.ɵfac });
    return ExtUploaderService;
}(AbstractRestService));
export { ExtUploaderService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtUploaderService, [{
        type: Injectable
    }], function () { return [{ type: i1.RestSettings }]; }, null); })();
