/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { isNil } from 'lodash';
import * as i0 from "@angular/core";
var ToArrayPipe = /** @class */ (function () {
    function ToArrayPipe() {
    }
    ToArrayPipe.prototype.transform = function (value) {
        if (isNil(value)) {
            return;
        }
        else if (typeof value === 'object') {
            return Object.keys(value).map(function (key) { return ({
                name: key,
                value: value[key],
            }); });
        }
    };
    ToArrayPipe.ɵfac = function ToArrayPipe_Factory(t) { return new (t || ToArrayPipe)(); };
    ToArrayPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "toArray", type: ToArrayPipe, pure: true });
    return ToArrayPipe;
}());
export { ToArrayPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ToArrayPipe, [{
        type: Pipe,
        args: [{
                name: 'toArray',
                pure: true,
            }]
    }], null, null); })();
