/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isBoolean, isNil } from 'lodash';
import * as i0 from "@angular/core";
var IfControlVisibleDirective = /** @class */ (function () {
    function IfControlVisibleDirective(viewContainerRef, templateRef) {
        this.viewContainerRef = viewContainerRef;
        this.templateRef = templateRef;
        this.hasView = false;
    }
    Object.defineProperty(IfControlVisibleDirective.prototype, "control", {
        set: function (control) {
            this._control = control;
        },
        enumerable: true,
        configurable: true
    });
    IfControlVisibleDirective.prototype.ngDoCheck = function () {
        var visible = this.isVisible();
        if (visible && !this.hasView) {
            this.hasView = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
        else if (!visible && this.hasView) {
            this.hasView = false;
            this.viewContainerRef.clear();
        }
    };
    IfControlVisibleDirective.prototype.isVisible = function () {
        if (isNil(this._control)) {
            return false;
        }
        var visible = this._control['visible'];
        return isBoolean(visible) ? visible : true;
    };
    IfControlVisibleDirective.ɵfac = function IfControlVisibleDirective_Factory(t) { return new (t || IfControlVisibleDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef)); };
    IfControlVisibleDirective.ɵdir = i0.ɵɵdefineDirective({ type: IfControlVisibleDirective, selectors: [["", "ghIfControlVisible", ""]], inputs: { control: ["ghIfControlVisible", "control"] } });
    return IfControlVisibleDirective;
}());
export { IfControlVisibleDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IfControlVisibleDirective, [{
        type: Directive,
        args: [{
                selector: '[ghIfControlVisible]',
            }]
    }], function () { return [{ type: i0.ViewContainerRef }, { type: i0.TemplateRef }]; }, { control: [{
            type: Input,
            args: ['ghIfControlVisible']
        }] }); })();
