/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { entity } from '@gh/core-data';
import { AutocompleteItemDto } from './autocomplete-item.dto';

export const AutocompleteItemMapper = entity<AutocompleteItemDto>();
