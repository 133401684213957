/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { isDataSourceEmpty } from '@gh/common-external-shared';

import { EnumDictionary, getRouteInitData } from '@gh/common-shared';
import { ID } from '@gh/common-shared-data';
import { TranslationService } from '@gh/core-mls';
import { WaitingService } from '@gh/core-ui';
import { beforeCurrentDate } from '@gh/core-util';
import { InvoiceType } from '@gh/customer-shared-data';
import { skip$ } from '@gh/rx/operators';

import { each } from 'lodash';

import {
  CustomerFinancialRecordBusinessService,
  FinancialInvoiceListItem,
  FinancialInvoiceTableRowData,
  FinancialRecord,
} from '../../resources';

@Component({
  selector: 'gh-customer-financial-records-list',
  templateUrl: 'customer-financial-records-list.component.html',
  styleUrls: ['customer-financial-records-list.component.scss'],
})
export class CustomerFinancialRecordsListComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  jobId: ID;
  displayedColumns: string[] = [
    'dropDownAction',
    'invoiceNumber',
    'accountingDate',
    'dueDate',
    'type',
    'description',
    'amountWithGst',
    'balance',
  ];
  expandedDisplayedColumns: string[] = ['payments']; // paymentDataSource
  invoiceListDataSource: MatTableDataSource<FinancialInvoiceTableRowData> = new MatTableDataSource([]);
  expandedInvoice: Maybe<FinancialInvoiceListItem>;
  invoiceType: EnumDictionary<InvoiceType>;
  financialRecord: FinancialRecord;

  constructor(private businessService: CustomerFinancialRecordBusinessService,
              private route: ActivatedRoute,
              private waitingService: WaitingService,
              private translationService: TranslationService) {
  }

  get isRecordsExist(): boolean {
    return !isDataSourceEmpty(this.invoiceListDataSource);
  }

  ngOnInit(): void {
    const { financialRecord, invoiceType } = getRouteInitData(this.route);
    this.initFinancialInvoiceList(financialRecord);
    this.invoiceListDataSource.sort = this.sort;
    this.invoiceType = invoiceType;
    this.route.paramMap.pipe(
      skip$(1))
      .subscribe(({ params }: any) => {
        this.jobId = params.jobId;
        this.loadFinancialInvoices(this.jobId);
      });
  }

  onRowExpand(invoiceData: FinancialInvoiceTableRowData): void {
    this.expandedInvoice = !this.isRowExpanded(invoiceData)
      ? invoiceData
      : void 0;
  }

  isInvoiceExpired({ dueDate }: FinancialInvoiceListItem): boolean {
    return beforeCurrentDate(dueDate);
  }

  getInvoiceId(index: number, invoice: FinancialInvoiceTableRowData): ID {
    return invoice.invoiceId;
  }

  isRowExpanded(invoice: FinancialInvoiceTableRowData): boolean {
    return this.expandedInvoice
      ? this.expandedInvoice.invoiceId === invoice.invoiceId
      : false;
  }

  toggleReceiptsActionName(invoice: any): string {
    return this.isRowExpanded(invoice)
      ? this.translationService.get('lbl_hide_receipts')
      : this.translationService.get('lbl_show_receipts');
  }

  getInvoiceTypeLabelId(type: InvoiceType): string {
    switch (type) {
      case InvoiceType.Draw:
        return 'lbl_draw';
      case InvoiceType.ChangeOrder:
        return 'lbl_change_order';
      case InvoiceType.CreditNote:
        return 'lbl_credit_note';
      default:
        return '';
    }
  }

  private loadFinancialInvoices(jobId: ID): void {
    this.businessService
      .get(jobId).pipe(
      this.waitingService.operator())
      .subscribe(this.initFinancialInvoiceList.bind(this));
  }

  private getTableRows(invoiceList: FinancialInvoiceListItem[]): FinancialInvoiceTableRowData[] {
    const rows: FinancialInvoiceTableRowData[] = [];
    each(invoiceList, (invoice: FinancialInvoiceListItem) =>
      rows.push({
        ...invoice,
        dropDownAction: true,
        paymentDataSource: new MatTableDataSource(invoice.payments),
      }));
    return rows;
  }

  private initFinancialInvoiceList(financialRecord: FinancialRecord): void {
    this.financialRecord = financialRecord;
    this.invoiceListDataSource.data = this.getTableRows(financialRecord.invoices);
    this.invoiceListDataSource.sort = this.sort;
  }
}
