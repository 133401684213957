/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { isNil, isString } from 'lodash';
import { Observable, Subject } from 'rxjs';

export const KEYCLOAK_CONFIGURATION = new InjectionToken('KeycloakConfiguration');
export const KEYCLOAK_INIT_OPTIONS = new InjectionToken('KeycloakInitOptions');
export const KEYCLOAK_MIN_VALIDITY = new InjectionToken('KeycloakMinValidity');

function toPromise<T>(promise: KeycloakModule.Promise<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    promise.success(resolve).error(reject);
  });
}

@Injectable()
export class KeycloakService {
  private keycloak: KeycloakModule.Keycloak;

  private authSuccessStream = new Subject<void>();
  private authLogoutStream = new Subject<void>();
  private authErrorStream = new Subject<void>();
  private authRefreshSuccessStream = new Subject<void>();
  private authRefreshErrorStream = new Subject<void>();

  constructor(@Inject(KEYCLOAK_CONFIGURATION) private configuration: KeycloakModule.Configuration,
              @Inject(KEYCLOAK_MIN_VALIDITY) private minValidity: number,
              @Optional() @Inject(KEYCLOAK_INIT_OPTIONS) private initOptions: KeycloakModule.InitOptions) {
  }

  get onAuthSuccess(): Observable<void> {
    return this.authSuccessStream;
  }

  get onAuthLogout(): Observable<void> {
    return this.authLogoutStream;
  }

  get onAuthError(): Observable<void> {
    return this.authErrorStream;
  }

  get onAuthRefreshSuccess(): Observable<void> {
    return this.authRefreshSuccessStream;
  }

  get onAuthRefreshError(): Observable<void> {
    return this.authRefreshErrorStream;
  }

  get token(): string {
    return this.keycloak.token;
  }

  init(): Promise<boolean> {
    return this.ensureInit();
  }

  login(options?: KeycloakModule.LoginOptions): Promise<boolean> {
    return this.ensureInit().then((authenticated) => {
      if (authenticated) {
        return Promise.resolve(true);
      } else {
        return toPromise<boolean>(this.keycloak.login(options));
      }
    });
  }

  logout(options?: KeycloakModule.RedirectUriOptions): Promise<boolean> {
    return toPromise<boolean>(this.keycloak.logout(options));
  }

  getToken(minValidity?: number): Promise<string> {
    if (this.keycloak) {
      return toPromise(this.keycloak.updateToken(minValidity || this.minValidity)).then(() => this.keycloak.token);
    }

    return Promise.reject<string>(new Error('Keycloak integration layer is not initialized'));
  }

  isTokenExpired(): boolean {
    return this.keycloak.isTokenExpired(this.minValidity);
  }

  getChangePasswordUrl(): string {
    const { configuration } = this;
    if (isString(configuration)) {
      throw new Error('KeycloakService: URL to change password page cannot be calculated ' +
        'based on string-based configuration');
    } else {
      const { url, realm } = configuration;
      // redirectUrl is not a standard supported query parameter here,
      // but our theme does support it
      return `${url}/realms/${realm}/account/password?redirectUrl=${window.location.href}`;
    }
  }

  private ensureInit(): Promise<boolean> {
    if (isNil(this.keycloak)) {
      this.keycloak = new Keycloak(this.configuration);

      this.keycloak.onAuthSuccess = () => this.authSuccessStream.next();
      this.keycloak.onAuthError = () => this.authErrorStream.next();
      this.keycloak.onAuthLogout = () => this.authLogoutStream.next();
      this.keycloak.onAuthRefreshSuccess = () => this.authRefreshSuccessStream.next();
      this.keycloak.onAuthRefreshError = () => this.authRefreshErrorStream.next();

      return toPromise<boolean>(this.keycloak.init(this.initOptions));
    }

    return Promise.resolve(false);
  }

}
