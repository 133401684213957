/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i0 from "@angular/core";
var DateTimePickerInputDirective = /** @class */ (function () {
    function DateTimePickerInputDirective() {
    }
    DateTimePickerInputDirective.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    DateTimePickerInputDirective.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    DateTimePickerInputDirective.prototype.registerOnSetDisabledState = function (onSetDisabledState) {
        this.onSetDisabledState = onSetDisabledState;
    };
    DateTimePickerInputDirective.prototype.registerOnWriteValue = function (onWriteValue) {
        this.onWriteValue = onWriteValue;
    };
    DateTimePickerInputDirective.prototype.setDisabledState = function (isDisabled) {
        this.onSetDisabledState(isDisabled);
    };
    DateTimePickerInputDirective.prototype.writeValue = function (datetime) {
        this.onWriteValue(datetime);
    };
    DateTimePickerInputDirective.ɵfac = function DateTimePickerInputDirective_Factory(t) { return new (t || DateTimePickerInputDirective)(); };
    DateTimePickerInputDirective.ɵdir = i0.ɵɵdefineDirective({ type: DateTimePickerInputDirective, selectors: [["", "ghDateTimePicker", ""]], features: [i0.ɵɵProvidersFeature([{ provide: NG_VALUE_ACCESSOR, useExisting: DateTimePickerInputDirective, multi: true }])] });
    return DateTimePickerInputDirective;
}());
export { DateTimePickerInputDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DateTimePickerInputDirective, [{
        type: Directive,
        args: [{
                selector: '[ghDateTimePicker]',
                providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DateTimePickerInputDirective, multi: true }],
            }]
    }], null, null); })();
