/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { DirtyType, getEntityFieldState, getEntityState, setEntityFieldState, setEntityState, } from '@gh/common-shared-data';
import { isCreateFormMode, isEditableFormMode } from '@gh/core-ui';
import { coalesce, currentDate, denull, isNotNil, isSimpleValueEqual, nullate } from '@gh/core-util';
import { assign, fromPairs, identity, isBoolean, isEmpty, isFunction, isNil, isString, isUndefined, iteratee, keyBy, mapValues, merge, omit, omitBy, pick, pickBy, uniqueId, } from 'lodash';
var lastNewItemId = 1;
export function nextNewItemId() {
    return lastNewItemId++;
}
/**
 * Returns error if value is empty
 */
export function requiredValidator(value) {
    return isNil(value) || isString(value) && value.trim() === '' ? { required: true } : void 0;
}
/**
 * Returns error if value does not satisfy corresponding type
 */
export function typeValidator(type) {
    return function (value) { return type.validate(value); };
}
/**
 * Array validator which returns error if one of array entities is invalid.
 */
export function nestedArrayValidator(array) {
    return array.some(isEntityInvalid) ? { nestedArray: true } : void 0;
}
/**
 * Composes validator from the given set of validators. Final errors are merged into single object.
 */
export function composeValidators() {
    var validators = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validators[_i] = arguments[_i];
    }
    return function (value) { return validators.reduce(function (errors, validator) {
        var nextErrors = validator(value);
        return errors ? assign(errors, nextErrors) : nextErrors;
    }, void 0); };
}
export function requiredIfFieldValidator(predicate) {
    return function (entity, name) { return predicate(entity, name) ? requiredValidator(entity[name]) : void 0; };
}
/**
 * Composes validator from the given set of validators. Final errors are merged into single object.
 */
export function composeFieldValidators() {
    var validators = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validators[_i] = arguments[_i];
    }
    return function (entity, name, value) { return validators.reduce(function (errors, validator) {
        var nextErrors = validator(entity, name, value);
        return errors ? assign(errors, nextErrors) : nextErrors;
    }, void 0); };
}
/**
 * Creates validator that validates all fields of the entity
 */
export function entityValidatorFromFields(validators) {
    return function (entity) {
        var errors = omitBy(mapValues(validators, function (validator, name) { return validator(entity, name, entity[name]); }), isUndefined);
        if (Object.keys(errors).length > 0) {
            return errors;
        }
        return void 0;
    };
}
/**
 * Creates validator that can validate each separate entity field
 */
export function combineFieldValidators(validators) {
    return function (entity, name, value) { return validators[name] ? denull(validators[name](entity, name, value)) : void 0; };
}
/**
 * Creates field validator based on the provided value validator
 */
export function fieldValidatorFrom(validator) {
    return function (entity, name, value) { return denull(validator(value)); };
}
/**
 * Merges all setters into a single setter. At least one setter (main setter) should be defined.
 * All other setters will be called only if main setter has transformed original value.
 */
export function composeSetters(main) {
    var otherSetters = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        otherSetters[_i - 1] = arguments[_i];
    }
    return function (entity, field, name) { return otherSetters.reduce(function (previous, setter) {
        if (previous === entity) {
            return entity;
        }
        else {
            return setter(previous, field, name);
        }
    }, main(entity, field, name)); };
}
export function setEntityField(entity, name, valueOrFn) {
    var _a;
    var value = isFunction(valueOrFn) ? valueOrFn.call(void 0, entity[name]) : valueOrFn;
    if (entity && !isSimpleValueEqual(entity[name], value)) {
        return assign({}, entity, (_a = {},
            _a[name] = value,
            _a));
    }
    else {
        return entity;
    }
}
export function setNestedEntityField(entity, path, value) {
    if (path.length > 1) {
        return setEntityField(entity, path[0], setNestedEntityField(entity[path[0]], path.slice(1), value));
    }
    else {
        return setEntityField(entity, path[0], value);
    }
}
/**
 * Immutable setter for the given fields.
 */
export function setEntityFields(entity, values) {
    if (entity) {
        var modifiedFields = Object.keys(values).filter(function (field) { return !isSimpleValueEqual(entity[field], values[field]); });
        if (modifiedFields.length > 0) {
            return assign({}, entity, fromPairs(modifiedFields.map(function (field) { return [field, values[field]]; })));
        }
    }
    return entity;
}
/**
 * Removes given field from the entity. If field does not exist in the entity => returns the same entity
 */
export function removeEntityField(entity, field) {
    // @ts-ignore
    return entity.hasOwnProperty(field) ? omit(entity, field) : entity;
}
/**
 * Removes given set of fields from the entity. If all fields do not exist in the entity => returns the same entity
 */
export function removeEntityFields(entity, fields) {
    // @ts-ignore
    return fields.some(function (field) { return entity.hasOwnProperty(field); }) ? omit(entity, fields) : entity;
}
/**
 * Creates setter that updates validation state of the field
 */
export function fieldValidationSetter(validator) {
    return function (entity, field, value) {
        var errors = validator(entity, field, value);
        var fieldState = getEntityFieldState(entity, field);
        if (fieldState.errors || isNil(fieldState.errors) && errors) {
            return setFieldErrors(entity, field, errors);
        }
        else {
            return entity;
        }
    };
}
/**
 * Creates setter that updates validation state of whole entity
 */
export function entityValidationSetter(validator) {
    return function (entity) {
        var errors = validator(entity);
        var allInvalidFields = (errors ? Object.keys(errors) : []).concat(getInvalidFields(entity));
        return allInvalidFields.reduce(function (updatedEntity, field) {
            var fieldState = getEntityFieldState(updatedEntity, field);
            var fieldErrors = errors && errors[field];
            if (fieldState.errors || isNil(fieldState.errors) && fieldErrors) {
                return setFieldErrors(updatedEntity, field, fieldErrors);
            }
            else {
                return updatedEntity;
            }
        }, entity);
    };
}
/**
 * Merges errors into state of the given field
 * @param entity
 * @param field
 * @param errors
 */
export function setFieldErrors(entity, field, errors) {
    var fieldState = getEntityFieldState(entity, field);
    return setEntityFieldState(entity, field, __assign(__assign({}, fieldState), { errors: errors }));
}
/**
 * Sets or clears corresponding error in the state of the given field
 * @param entity
 * @param field
 * @param errorKey
 * @param errorDetails
 */
export function setFieldError(entity, field, errorKey, errorDetails) {
    var _a;
    var fieldState = getEntityFieldState(entity, field);
    var errors = fieldState.errors;
    var setError = !isNil(errorDetails);
    var hasError = !isNil(errors && errors[errorKey]);
    if (setError && !hasError) {
        var newErrors = __assign(__assign({}, (errors || {})), (_a = {}, _a[errorKey] = errorDetails, _a));
        return setFieldErrors(entity, field, newErrors);
    }
    else if (!setError && hasError) {
        var newErrors = omit(errors, errorKey);
        return setFieldErrors(entity, field, isEmpty(newErrors) ? void 0 : newErrors);
    }
    else {
        return entity;
    }
}
/**
 * Immutable setter for dirty flag
 */
export function setDirty(entity) {
    return setEntityField(entity, 'isDirty', true);
}
/**
 * Immutable setter for dirty flag
 */
export function setDirtyField(entity, isDirty) {
    return setEntityField(entity, 'isDirty', isDirty);
}
/**
 * Immutable resets dirty flag
 */
export function setPristine(entity) {
    return setEntityField(entity, 'isDirty', false);
}
/**
 * Immutable setter for recalculation needed flag
 */
export function setRecalculationNeeded(entity) {
    return setEntityField(entity, 'isRecalculationNeeded', true);
}
/**
 * Creates function that merges entity state calculated by the given functions into an original entity
 *
 * @param sources
 */
// tslint:disable-next-line:max-line-length
export function entityStateMerger() {
    var sources = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        sources[_i] = arguments[_i];
    }
    return function (entity) { return setEntityState(entity, merge.apply(void 0, __spreadArrays([{}], sources.map(function (source) { return source(entity); })))); };
}
/**
 * Create function that calculates entity state based on provided entity validator
 *
 * @param validator
 */
// tslint:disable-next-line:max-line-length
export function errorsEntityState(validator) {
    return function (entity) { return mapValues(validator(entity) || {}, function (errors) { return ({ errors: errors }); }); };
}
// tslint:disable-next-line:max-line-length
export function fieldStateByEntityConfig(entityConfig) {
    return function (entity, field) {
        var _a = entityConfig[field] || {}, required = _a.required, editable = _a.editable;
        return {
            required: coalesce(isFunction(required) ? required(entity, field) : required, false),
            editable: coalesce(isFunction(editable) ? editable(entity, field) : editable, true),
        };
    };
}
/**
 * Creates field validator based on provided {@link EntityConfig}
 */
// tslint:disable-next-line:max-line-length
export function fieldValidatorByEntityConfig(entityConfig) {
    return combineFieldValidators(validatorMap(entityConfig));
}
/**
 * Creates form description for the provided data according to the given {@link EntityConfig}
 */
export function formSchemaBuilderByEntityConfig(data, entityConfig) {
    return mapValues(entityConfig, function (_a, fieldName) {
        var required = _a.required, editable = _a.editable, validator = _a.validator, type = _a.type;
        return ({
            required: isNil(required) || isBoolean(required) ? coalesce(required, false) : required(data, fieldName),
            enabled: isNil(editable) || isBoolean(editable) ? coalesce(editable, true) : editable(data, fieldName),
            validators: isNil(validator) ? void 0 :
                [function (control) { return nullate(validator(control.parent.value, fieldName, control.value)); }],
            type: isFunction(type) ? type(data, fieldName) : type,
        });
    });
}
/**
 * Creates entity validator based on provided {@link EntityConfig}
 */
// tslint:disable-next-line:max-line-length
export function entityValidatorByEntityConfig(entityConfig) {
    return entityValidatorFromFields(validatorMap(entityConfig));
}
/**
 * Creates predicate based on provided entity validator.
 * Predicate returns true iff validator does not return any errors.
 */
export function entityValidatorToPredicate(validator) {
    return function (entity) { return isNotNil(validator(entity)); };
}
/**
 * Creates map of field validators according to the provided {@link EntityConfig}.
 */
// tslint:disable-next-line:max-line-length
export function validatorMap(entityConfig) {
    return pickBy(mapValues(entityConfig, function (fieldConfig) {
        var validators = [];
        if (fieldConfig.required) {
            if (isFunction(fieldConfig.required)) {
                validators.push(requiredIfFieldValidator(fieldConfig.required));
            }
            else {
                validators.push(fieldValidatorFrom(requiredValidator));
            }
        }
        if (fieldConfig.type) {
            validators.push(isFunction(fieldConfig.type)
                // tslint:disable-next-line:max-line-length
                ? function (entity, field, value) { return fieldValidatorFrom(typeValidator(fieldConfig.type(entity, field)))(entity, field, value); }
                : fieldValidatorFrom(typeValidator(fieldConfig.type)));
        }
        if (fieldConfig.validator) {
            validators.push(fieldConfig.validator);
        }
        return validators.length > 1 ? composeFieldValidators.apply(void 0, validators) : validators[0];
    }));
}
/**
 * Generates and merges unique ID into entity
 * @param entity
 */
export function bindUniqueId(entity) {
    return mergeUniqueId(uniqueId('entity:'), entity);
}
/**
 * Merges provided uniqueId into entity
 */
export function mergeUniqueId(uid, entity) {
    return __assign(__assign({}, entity), { uniqueId: uid });
}
/**
 * Returns true if entity state has at least one error for any of fields
 * @param entity
 */
export function hasEntityStateErrors(entity) {
    var entityState = getEntityState(entity);
    var fields = Object.keys(entityState);
    return !fields.every(function (field) { return isNil(entityState[field].errors); });
}
/**
 * Returns all fields having at least one error
 * @param entity
 */
export function getInvalidFields(entity) {
    var entityState = getEntityState(entity);
    var fields = Object.keys(entityState);
    return fields.filter(function (field) { return !isNil(entityState[field].errors); });
}
/**
 * Returns true iff entity is invalid (has errors)
 */
export function isEntityInvalid(entity) {
    var entityState = getEntityState(entity);
    var fields = Object.keys(entityState);
    return fields.some(function (field) { return !isNil(entityState[field].errors); });
}
/**
 * Returns true iff corresponding field of the given entity is invalid (has errors)
 */
export function isFieldInvalid(entity, name) {
    var entityState = getEntityState(entity);
    return !isNil(entityState[name].errors);
}
/**
 * Merges entities in destination array with corresponding entities from the source array.
 * Entities to be merged are determined using <code>getId</code> function.
 * Merging is executed by <code>merger</code> function which accepts destination and source entities.
 *
 * @param {(entity: T) => any} getId
 * @param {(dest: T, source: T) => T} merger
 */
export function mergeEntitiesBy(getId, merger) {
    return function (dest, source) {
        var sourceIndex = keyBy(source, getId);
        return dest.map(function (destEntity) {
            var sourceEntity = sourceIndex[getId(destEntity)];
            if (sourceEntity) {
                return merger(destEntity, sourceEntity);
            }
            else {
                return destEntity;
            }
        });
    };
}
/**
 * Returns true if entity's dirty type is 'New'
 */
export function whenNew(entity) {
    return entity.dirtyType === DirtyType.New;
}
/**
 * Returns true if entity's dirty type is not 'Removed'
 */
export function whenExisting(entity) {
    return entity.dirtyType !== DirtyType.Removed;
}
/**
 * Returns true if entity's dirty type is 'Removed'
 */
export function whenRemoved(entity) {
    return entity.dirtyType === DirtyType.Removed;
}
/**
 * Returns true if entity's dirty type is 'Removed' or entity is undefined
 */
export function whenNotExist(entity) {
    return isNil(entity) || entity.dirtyType === DirtyType.Removed;
}
/**
 * Returns true if entity's dirty type is 'Updated'
 */
export function whenUpdated(entity) {
    return entity.dirtyType === DirtyType.Updated;
}
/**
 * Returns true if entity has any dirty type assigned
 */
export function whenModified(entity) {
    return !isNil(entity.dirtyType);
}
/**
 * Returns true if entity does not have any dirty type assigned
 */
export function whenNotModified(entity) {
    return isNil(entity.dirtyType);
}
/**
 * Returns true if entity has any dirty type assigned
 */
export function whenDirty(entity) {
    return !isNil(entity.dirtyType);
}
/**
 * Marks entity as 'New'
 */
export function setNewDirtyType(entity) {
    return setEntityField(entity, 'dirtyType', DirtyType.New);
}
/**
 * Marks entity as 'Removed'
 */
export function setRemovedDirtyType(entity) {
    return setEntityField(entity, 'dirtyType', DirtyType.Removed);
}
/**
 * Resets dirty type of the provided entity
 */
export function resetDirtyType(entity) {
    return setEntityField(entity, 'dirtyType', void 0);
}
/**
 * Sets dirty type of the provided entity to 'Updated'
 */
export function forceUpdatedDirtyType(entity) {
    return setEntityField(entity, 'dirtyType', DirtyType.Updated);
}
/**
 * Tries to mark entity as modified. This method does not modify dirty type state if entity is already dirty,
 * otherwise entity is marked as 'Updated',
 */
export function setUpdatedDirtyType(entity) {
    if (whenNew(entity) || whenRemoved(entity) || whenUpdated(entity)) {
        return entity;
    }
    else {
        return setEntityField(entity, 'dirtyType', DirtyType.Updated);
    }
}
export function entityFieldsMerger(fields) {
    if (fields === void 0) { fields = []; }
    if (isEmpty(fields)) {
        return identity;
    }
    else {
        return function (oldState, newState) {
            if (oldState && newState) {
                return setEntityFields(oldState, pick(newState, fields));
            }
            else {
                return oldState;
            }
        };
    }
}
/**
 * Creates entity merger which calls provided merger iff both old and new entities are exist,
 * otherwise returns new entity (in this case it returns either full entity or nil)
 */
export function entityReplaceOrMergeWith(merger) {
    return function (oldState, newState, id) { return oldState && newState ? merger(oldState, newState, id) : newState; };
}
export function entityFieldMergeBy(field, fieldMerger) {
    return function (oldState, newState) {
        if (oldState && oldState[field]) {
            return setEntityField(oldState, field, fieldMerger(oldState[field], newState[field]));
        }
        else {
            return oldState;
        }
    };
}
export function composeEntityMergers() {
    var mergers = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        mergers[_i] = arguments[_i];
    }
    return function (oldState, newState, id) { return mergers.reduce(function (nextState, merger) { return merger(nextState, newState, id); }, oldState); };
}
export function onChangeEntityMerger(main) {
    var otherMergers = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        otherMergers[_i - 1] = arguments[_i];
    }
    var composedOtherMerger = composeEntityMergers.apply(void 0, otherMergers);
    return function (oldState, newState, id) {
        var modifiedEntity = main(oldState, newState, id);
        return modifiedEntity === oldState ? modifiedEntity : composedOtherMerger(modifiedEntity, newState, id);
    };
}
export function setCreatedDate(entity) {
    return setEntityField(entity, 'createdDate', currentDate());
}
export function resetCreatedDate(entity) {
    return setEntityField(entity, 'createdDate', void 0);
}
// tslint:disable-next-line:max-line-length
export function newEntityComparator(left, right) {
    var isLeftNew = left.dirtyType === DirtyType.New ? 1 : 0;
    var isRightNew = right.dirtyType === DirtyType.New ? 1 : 0;
    if (isLeftNew && isLeftNew === isRightNew) {
        // tslint:disable-next-line:no-non-null-assertion
        return right.createdDate.getTime() - left.createdDate.getTime();
    }
    return isRightNew - isLeftNew;
}
export function mergeArraysByKey(anchor) {
    var it = iteratee(anchor);
    return function (oldState, newState) {
        var oldStateIndex = keyBy(oldState, it);
        return newState.map(function (newItem) {
            var oldItem = oldStateIndex[it(newItem)];
            if (oldItem) {
                return assign({}, oldItem, newItem);
            }
            else {
                return newItem;
            }
        });
    };
}
export function queryDirty(state) {
    return state.isDirty || false;
}
export function queryRecalculationNeeded(state) {
    return state.isRecalculationNeeded === true;
}
export function queryProduceStatus(state) {
    return state.produceStatus;
}
export function queryFormMode(state) {
    return state.formMode;
}
export function queryFormModeCreate(state) {
    return isCreateFormMode(state.formMode);
}
export function queryFormEditable(state) {
    return isEditableFormMode(queryFormMode(state));
}
export function queryVersion(state) {
    return state.version;
}
export function queryAll(state) {
    return state;
}
export function isDirty(entity) {
    return entity.isDirty || false;
}
export function isDirtyTypeNew(dirtyType) {
    return dirtyType === DirtyType.New;
}
export function isDirtyTypeUpdated(dirtyType) {
    return dirtyType === DirtyType.Updated;
}
export function isDirtyTypeRemoved(dirtyType) {
    return dirtyType === DirtyType.Removed;
}
export function entityComplementor(validator) {
    return entityStateMerger(errorsEntityState(validator));
}
// tslint:disable-next-line:max-line-length
export function mergeAndValidateFields(validator) {
    var complementor = entityComplementor(validator);
    return function (fields) { return function (entity) { return complementor(setEntityFields(entity, fields)); }; };
}
export var setEntityFieldWithDirty = composeSetters(setEntityField, setDirty);
