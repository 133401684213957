/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ComponentFactoryResolver, Directive, ElementRef, EventEmitter, Host, HostBinding, HostListener, Inject, Input, NgZone, Optional, Output, Renderer2, ViewContainerRef, } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { coalesce, denull, isElementFocused, stopEvent } from '@gh/core-util';
import { fromEvent$, interval$, merge$ } from '@gh/rx';
import { debounce$, filter$, first$, map$, mapTo$, switchMap$ } from '@gh/rx/operators';
import { compact, flatten, isNil, noop } from 'lodash';
import { Subject } from 'rxjs';
import { KEY_DOWN, KEY_ENTER, KEY_ESCAPE, KEY_PAGE_DOWN, KEY_PAGE_UP, KEY_UP } from '../../../utils';
import { AutocompleteActionIconComponent } from './autocomplete-action-icon.component';
import { AutocompleteArrowIconComponent } from './autocomplete-arrow-icon.component';
import { AutocompleteMaskedTypeDirective } from './autocomplete-masked-type.directive';
import { AutocompleteComponent } from './autocomplete.component';
import { PLACEHOLDER_PADDING_HORIZONTAL } from './autocomplete.constants';
import { DOCUMENT } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
import * as i2 from "@angular/material/form-field";
import * as i3 from "./autocomplete-masked-type.directive";
var AutocompleteActionType;
(function (AutocompleteActionType) {
    AutocompleteActionType[AutocompleteActionType["Focus"] = 0] = "Focus";
    AutocompleteActionType[AutocompleteActionType["Blur"] = 1] = "Blur";
})(AutocompleteActionType || (AutocompleteActionType = {}));
function findFormField(element) {
    var parent = element.parentElement;
    while (parent && parent.tagName !== 'MAT-FORM-FIELD') {
        parent = parent.parentElement;
    }
    return denull(parent);
}
var PAGE_SIZE = 6; // tslint:disable-line:no-magic-numbers
var AutocompleteDirective = /** @class */ (function () {
    function AutocompleteDirective(_element, _overlay, _renderer, _viewContainerRef, _zone, _resolver, _document, _inputContainer, maskedType) {
        this._element = _element;
        this._overlay = _overlay;
        this._renderer = _renderer;
        this._viewContainerRef = _viewContainerRef;
        this._zone = _zone;
        this._resolver = _resolver;
        this._document = _document;
        this._inputContainer = _inputContainer;
        this.maskedType = maskedType;
        this.clear = new EventEmitter();
        this.panelOpened = new EventEmitter();
        this.panelClosed = new EventEmitter();
        this.unbound = false;
        this.clearable = true;
        this.action$$ = new Subject();
        this.onTouchedCallback = noop;
        this.onChange = noop;
        this._lastPanelVisible = false;
        this.disabled = false;
    }
    Object.defineProperty(AutocompleteDirective.prototype, "ignoreFirstFocus", {
        /**
         * If ignoreFirstFocus is set, autocomplete panel will not be shown when input is focused first time.
         * This is necessary in dialogs to avoid opening of autocomplete panel when input is focused as first dialog input.
         */
        set: function (ignore) {
            this._ignoreFirstFocus = coerceBooleanProperty(ignore);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteDirective.prototype, "panelVisible", {
        get: function () {
            return this.autocomplete.displayMenu && (this._overlayRef && this._overlayRef.hasAttached());
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteDirective.prototype.ngOnInit = function () {
        var _this = this;
        // this.autocomplete.setAutocompleteDirective(this);
        this.autocomplete.setUnbound(this.unbound);
        // z-index mat-form-field management. Interact with gh-autocomplete component
        var formFieldEl = findFormField(this._element.nativeElement);
        this.anchorRef = formFieldEl && new ElementRef(formFieldEl) || this._element;
        // hack for icon positioning
        this.mdInputIconElement = formFieldEl ? denull(formFieldEl.querySelector('mat-icon')) : void 0;
        // arrow and close icon dynamic component
        var factoryArrowIcon = this._resolver.resolveComponentFactory(AutocompleteArrowIconComponent);
        this.autocompleteToggleButton = this._viewContainerRef.createComponent(factoryArrowIcon);
        this.autocompleteToggleButton.instance.setDisabled(this.disabled);
        if (this.clearable) {
            var factoryActionIcon = this._resolver.resolveComponentFactory(AutocompleteActionIconComponent);
            this.clearButton = this._viewContainerRef.createComponent(factoryActionIcon);
            this.clearButton.instance.setDisabled(this.disabled);
            this.clearButton.instance.trigger.subscribe(function () {
                _this._clear(true);
            });
        }
        this.autocompleteToggleButton.instance.trigger.subscribe(function ($event) {
            if (_this.autocomplete.opened) {
                _this.action$$.next({ type: AutocompleteActionType.Blur, event: $event });
            }
            else {
                _this.action$$.next({ type: AutocompleteActionType.Focus, event: $event });
            }
        });
        var action$ = this.action$$.pipe(switchMap$(function (action) {
            if (action.type === AutocompleteActionType.Blur) {
                return interval$(200).pipe(first$(), mapTo$(action)); // tslint:disable-line:no-magic-numbers
            }
            else {
                return [action];
            }
        }));
        action$.pipe(filter$(function (_a) {
            var type = _a.type;
            return type === AutocompleteActionType.Focus;
        }))
            .subscribe(function (action) {
            _this._element.nativeElement.focus();
            if (_this._ignoreFirstFocus) {
                _this._ignoreFirstFocus = false;
            }
            else {
                _this.openPanel();
            }
        });
        this._inputSubscription = fromEvent$(this._element.nativeElement, 'input').pipe(filter$(function (_a) {
            var target = _a.target;
            return isElementFocused(target);
        }), 
        // we use debounce (rather than debounceTime) here,
        // because debounceTime may be not initialized due to order of component initialization
        debounce$(function () { return interval$(_this.autocomplete.debounceTime).pipe(first$()); }), map$(function (event) { return event.target.value; }))
            .subscribe(function (value) {
            if (!_this.autocomplete.opened) {
                _this.openPanel();
            }
            _this.autocomplete.typingResults(value);
        });
        this.autocomplete.selectedOptionChange.pipe(filter$(Boolean))
            .subscribe(function (option) {
            _this.setSelectedOption(option);
            if (!_this.autocomplete.hasChips) {
                _this.onChange(_this.unbound ? option.text : option.value);
            }
        });
        this.autocomplete.suggestionListHasChanged
            .subscribe(function () { return _this._positionStrategy.apply(); });
    };
    AutocompleteDirective.prototype.ngOnDestroy = function () {
        this.closePanel();
        this._inputSubscription.unsubscribe();
        if (this.positionChangeSubscription) {
            this.positionChangeSubscription.unsubscribe();
        }
    };
    AutocompleteDirective.prototype.ngDoCheck = function () {
        if (this._lastPanelVisible !== this.panelVisible) {
            this.panelVisiblityChanged();
        }
    };
    AutocompleteDirective.prototype.writeValue = function (value) {
        var _this = this;
        if (isNil(value) && this.autocomplete.value) {
            this._clear(false);
        }
        else {
            this.autocomplete.setValue(this.maskedType ? this.maskedType.update(value) : value).pipe(filter$(Boolean))
                .subscribe(function (option) {
                _this.setSelectedOption(option);
            });
        }
    };
    AutocompleteDirective.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    AutocompleteDirective.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    AutocompleteDirective.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
        this.autocompleteToggleButton.instance.setDisabled(isDisabled);
        if (this.clearButton) {
            this.clearButton.instance.setDisabled(isDisabled);
        }
    };
    AutocompleteDirective.prototype.onInputFocus = function ($event) {
        this.action$$.next({ type: AutocompleteActionType.Focus, event: $event });
    };
    AutocompleteDirective.prototype.onInput = function ($event) {
        if (this.maskedType) {
            this.maskedType.update($event.target.value);
        }
        if (this.unbound) {
            this.onChange($event.target.value);
        }
    };
    AutocompleteDirective.prototype.onKeyDown = function ($event) {
        var keyCode = $event.keyCode;
        var autocomplete = this.autocomplete;
        var displayMenu = autocomplete.displayMenu;
        switch (keyCode) {
            case KEY_UP:
                stopEvent($event);
                if (displayMenu) {
                    autocomplete.moveFocus(-1);
                }
                else {
                    this.onInputFocus($event);
                }
                break;
            case KEY_DOWN:
                stopEvent($event);
                if (displayMenu) {
                    autocomplete.moveFocus(1);
                }
                else {
                    this.onInputFocus($event);
                }
                break;
            case KEY_PAGE_UP:
                stopEvent($event);
                if (displayMenu) {
                    autocomplete.moveFocus(-PAGE_SIZE);
                }
                break;
            case KEY_PAGE_DOWN:
                stopEvent($event);
                if (displayMenu) {
                    autocomplete.moveFocus(PAGE_SIZE);
                }
                break;
            case KEY_ENTER:
                if (displayMenu) {
                    stopEvent($event);
                    autocomplete.selectByIndex(autocomplete.focusedIndex);
                }
                break;
            case KEY_ESCAPE:
                if (displayMenu) {
                    stopEvent($event);
                    autocomplete.close();
                    this.closePanel();
                    if (!this.unbound) {
                        this.rollbackInputText();
                    }
                }
                break;
            default:
                break;
        }
    };
    AutocompleteDirective.prototype.openPanel = function () {
        this.createPanel();
        this.autocomplete.open();
    };
    /** Opens the autocomplete suggestion panel. */
    AutocompleteDirective.prototype.createPanel = function () {
        var _this = this;
        if (!this._overlayRef) {
            this._createOverlay();
        }
        this.updateOverlayDimensions();
        if (!this._overlayRef.hasAttached()) {
            this._overlayRef.attach(this._portal);
        }
        this._overlayRef.backdropClick().subscribe(function () {
            _this.autocomplete.close();
        });
        if (this.autocomplete.activeWidth) {
            this._renderer.setStyle(this._element.nativeElement, 'width', this.autocomplete.controlWidth());
        }
        this.updateAnchorBackground();
    };
    /** Closes the autocomplete suggestion panel. */
    AutocompleteDirective.prototype.closePanel = function () {
        if (this._overlayRef && this._overlayRef.hasAttached()) {
            this._overlayRef.detach();
        }
        if (this.autocomplete.activeWidth) {
            this._renderer.setStyle(this._element.nativeElement, 'width', '100%');
        }
        this.updateAnchorBackground();
    };
    AutocompleteDirective.prototype.updateOverlayDimensions = function () {
        var rect = this.anchorRef.nativeElement.getBoundingClientRect();
        this.autocomplete.setAnchorDimensions(rect.width, rect.height);
    };
    AutocompleteDirective.prototype._createOverlay = function () {
        var _this = this;
        this._positionStrategy = this._createOverlayPosition();
        this._portal = new TemplatePortal(this.autocomplete.template, this._viewContainerRef);
        this._overlayRef = this._overlay.create(this._getOverlayConfig());
        this._overlayRef.hostElement.classList.add('autocomplete-overlay-box');
        this.positionChangeSubscription = this._positionStrategy.positionChanges.subscribe(function (event) {
            _this.autocomplete.setPlaceholderPosition(event.connectionPair.overlayY);
        });
        this._getOutsideClickStream().subscribe(function (event) {
            if (!_this.unbound) {
                _this.rollbackInputText();
            }
            _this.autocomplete.close();
            _this.closePanel();
        });
        this._overlayRef.attachments().subscribe(function () { return _this.panelOpened.emit(); });
        this._overlayRef.detachments().subscribe(function () { return _this.panelClosed.emit(); });
    };
    AutocompleteDirective.prototype._getOverlayConfig = function () {
        var overlayConfig = new OverlayConfig();
        overlayConfig.positionStrategy = this._positionStrategy;
        overlayConfig.width = 1;
        overlayConfig.panelClass = compact(flatten([
            'autocomplete-overlay-panel',
            this.autocomplete.panelClass && this.autocomplete.panelClass.split(/\s+/),
        ]));
        return overlayConfig;
    };
    AutocompleteDirective.prototype._createOverlayPosition = function () {
        return this._overlay.position()
            .flexibleConnectedTo(this.anchorRef)
            .withPositions([
            { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
            { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
        ])
            .withDefaultOffsetX(-PLACEHOLDER_PADDING_HORIZONTAL);
    };
    AutocompleteDirective.prototype._clear = function (fireEvent) {
        if (fireEvent) {
            this.onChange(this.unbound ? '' : void 0);
        }
        this._element.nativeElement.value = '';
        this.clearButton.instance.setDisplay(false);
        this.autocompleteToggleButton.instance.setDisplay(true);
        this.autocomplete.resetTo(void 0);
    };
    AutocompleteDirective.prototype.panelVisiblityChanged = function () {
        this._lastPanelVisible = this.panelVisible;
        this.updateAnchorBackground();
    };
    AutocompleteDirective.prototype.updateAnchorBackground = function () {
        this.anchorRef.nativeElement.style.backgroundColor = this.panelVisible ? '#fff' : 'transparent';
    };
    AutocompleteDirective.prototype.setSelectedOption = function (option) {
        if (this.clearButton) {
            this.clearButton.instance.setDisplay(!!option.text);
            this.autocompleteToggleButton.instance.setDisplay(!option.text);
        }
        this._element.nativeElement.value = !this.autocomplete.hasChips
            ? option.text || ''
            : '';
    };
    AutocompleteDirective.prototype.rollbackInputText = function () {
        var selectedOption = this.autocomplete.selectedOption;
        this._element.nativeElement.value = coalesce(selectedOption ? selectedOption.text : void 0, '');
    };
    AutocompleteDirective.prototype._getOutsideClickStream = function () {
        var _this = this;
        return merge$(fromEvent$(this._document, 'click'), fromEvent$(this._document, 'touchend'))
            .pipe(filter$(function (event) {
            var clickTarget = (event.composedPath ? event.composedPath()[0] :
                event.target);
            return _this._overlay && clickTarget !== _this._element.nativeElement &&
                (!!_this._overlayRef && !_this._overlayRef.overlayElement.contains(clickTarget));
        }));
    };
    AutocompleteDirective.ɵfac = function AutocompleteDirective_Factory(t) { return new (t || AutocompleteDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ComponentFactoryResolver), i0.ɵɵdirectiveInject(DOCUMENT, 8), i0.ɵɵdirectiveInject(i2.MatFormField, 9), i0.ɵɵdirectiveInject(i3.AutocompleteMaskedTypeDirective, 8)); };
    AutocompleteDirective.ɵdir = i0.ɵɵdefineDirective({ type: AutocompleteDirective, selectors: [["", "ghAutocomplete", ""]], hostAttrs: ["autocomplete", "off", 1, "autocomplete-input-element"], hostVars: 2, hostBindings: function AutocompleteDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("focus", function AutocompleteDirective_focus_HostBindingHandler($event) { return ctx.onInputFocus($event); })("input", function AutocompleteDirective_input_HostBindingHandler($event) { return ctx.onInput($event); })("keydown", function AutocompleteDirective_keydown_HostBindingHandler($event) { return ctx.onKeyDown($event); });
        } if (rf & 2) {
            i0.ɵɵclassProp("autocomplete-panel-visible", ctx.panelVisible);
        } }, inputs: { autocomplete: ["ghAutocomplete", "autocomplete"], unbound: "unbound", clearable: "clearable", ignoreFirstFocus: "ignoreFirstFocus" }, outputs: { clear: "clear", panelOpened: "panelOpened", panelClosed: "panelClosed" }, exportAs: ["ghAutocompleteInput"], features: [i0.ɵɵProvidersFeature([{
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: AutocompleteDirective,
                    multi: true,
                }])] });
    return AutocompleteDirective;
}());
export { AutocompleteDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AutocompleteDirective, [{
        type: Directive,
        args: [{
                selector: '[ghAutocomplete]',
                // tslint:disable-next-line:use-host-property-decorator
                host: {
                    'class': 'autocomplete-input-element',
                    'autocomplete': 'off',
                },
                providers: [{
                        provide: NG_VALUE_ACCESSOR,
                        useExisting: AutocompleteDirective,
                        multi: true,
                    }],
                exportAs: 'ghAutocompleteInput',
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i1.Overlay }, { type: i0.Renderer2 }, { type: i0.ViewContainerRef }, { type: i0.NgZone }, { type: i0.ComponentFactoryResolver }, { type: undefined, decorators: [{
                type: Optional
            }, {
                type: Inject,
                args: [DOCUMENT]
            }] }, { type: i2.MatFormField, decorators: [{
                type: Optional
            }, {
                type: Host
            }] }, { type: i3.AutocompleteMaskedTypeDirective, decorators: [{
                type: Optional
            }] }]; }, { clear: [{
            type: Output
        }], panelOpened: [{
            type: Output
        }], panelClosed: [{
            type: Output
        }], autocomplete: [{
            type: Input,
            args: ['ghAutocomplete']
        }], unbound: [{
            type: Input
        }], clearable: [{
            type: Input
        }], ignoreFirstFocus: [{
            type: Input
        }], panelVisible: [{
            type: HostBinding,
            args: ['class.autocomplete-panel-visible']
        }], onInputFocus: [{
            type: HostListener,
            args: ['focus', ['$event']]
        }], onInput: [{
            type: HostListener,
            args: ['input', ['$event']]
        }], onKeyDown: [{
            type: HostListener,
            args: ['keydown', ['$event']]
        }] }); })();
