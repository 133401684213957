/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { ContextMenuTriggerDirective } from './context-menu-trigger.directive';
import * as i0 from "@angular/core";
var CoreUiMenuModule = /** @class */ (function () {
    function CoreUiMenuModule() {
    }
    CoreUiMenuModule.forRoot = function () {
        return { ngModule: CoreUiMenuModule };
    };
    CoreUiMenuModule.forChild = function () {
        return { ngModule: CoreUiMenuModule };
    };
    CoreUiMenuModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreUiMenuModule });
    CoreUiMenuModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreUiMenuModule_Factory(t) { return new (t || CoreUiMenuModule)(); } });
    return CoreUiMenuModule;
}());
export { CoreUiMenuModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreUiMenuModule, { declarations: [ContextMenuTriggerDirective], exports: [ContextMenuTriggerDirective] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreUiMenuModule, [{
        type: NgModule,
        args: [{
                declarations: [ContextMenuTriggerDirective],
                exports: [ContextMenuTriggerDirective],
            }]
    }], null, null); })();
