/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DataSource as MatDataSource } from '@angular/cdk/collections';
import {
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  Host,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  EXT_INFINITE_SCROLL_VIEWPORT,
  ExtInfiniteScrollViewport,
  сollectionViewerWithSideEffect,
} from '../ext-infinite-scroll.interfaces';
import { toQueryResult } from '../infinite-mat-data-source';
import { ExtInfiniteListItemComponent } from './ext-infinite-list-item.component';
import { ExtInfiniteListComponent } from './ext-infinite-list.component';

@Directive({
  selector: '[ghExtInfiniteScrollFor],[ghExtInfiniteScrollForOf]',
})
export class ExtInfiniteScrollForDirective<T> implements OnInit, OnDestroy {
  private dataSource$$ = new BehaviorSubject<Maybe<MatDataSource<T>>>(void 0);
  private _currentDataSource?: MatDataSource<T>;
  private renderSubscription?: Subscription;
  private itemFactory: ComponentFactory<ExtInfiniteListItemComponent>;

  @Input('ghExtInfiniteScrollForOf')
  set dataSource(dataSource: Maybe<MatDataSource<T>>) {
    this.dataSource$$.next(dataSource);
  }

  get dataSource(): Maybe<MatDataSource<T>> {
    return this.dataSource$$.value;
  }

  constructor(private injector: Injector,
              @Inject(EXT_INFINITE_SCROLL_VIEWPORT) private viewport: ExtInfiniteScrollViewport,
              @Host() private list: ExtInfiniteListComponent,
              private componentFactoryResolver: ComponentFactoryResolver,
              private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef) {
    this.itemFactory = this.componentFactoryResolver.resolveComponentFactory(ExtInfiniteListItemComponent);
  }

  ngOnInit(): void {
    if (this.list.tracker) {
      this.list.tracker.reset();
    }

    this.dataSource$$
      .subscribe(this.switchDataSource.bind(this));
  }

  ngOnDestroy(): void {
    this.dataSource$$.complete();
    this.switchDataSource();
  }

  private switchDataSource(dataSource?: MatDataSource<T>): void {
    if (this._currentDataSource === dataSource) {
      return;
    }
    if (this._currentDataSource) {
      this._currentDataSource.disconnect(this.viewport);
    }
    if (this.renderSubscription) {
      this.renderSubscription.unsubscribe();
    }
    if (dataSource) {
      this.renderSubscription = dataSource
        .connect(сollectionViewerWithSideEffect(
          this.viewport,
          () => this.list.tracker && this.list.tracker.start()))
        .subscribe(this.renderItems.bind(this));
      this._currentDataSource = dataSource;
    }
  }

  private renderItems(items: T[]): void {
    this.viewContainerRef.clear();

    const { query: { from, to } } = toQueryResult(items);
    for (let i = 0; i < items.length; i++) {
      const itemComponent = this.viewContainerRef.createComponent(this.itemFactory);
      itemComponent.instance.template = this.templateRef;
      itemComponent.instance.data = items[i];
      itemComponent.instance.index = from + i;
    }

    this.viewport.updateRowCount(to - from, (items || []).length, from);
    this.list.updateHeight();
    this.list.tracker && this.list.tracker.stop(isEmpty(items));
  }
}
