/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { RestSettings } from './http/rest-settings.service';
import { MOCK_DATA, MockDataService } from './mock';
import { RouteDataService } from './route/route-data.service';
import { RouteDataStore } from './route/route-store.service';
import * as i0 from "@angular/core";
import * as i1 from "angular-in-memory-web-api";
var CoreDataModule = /** @class */ (function () {
    function CoreDataModule() {
    }
    CoreDataModule.forRoot = function () {
        return {
            ngModule: CoreDataModule,
            providers: [RestSettings, RouteDataService, RouteDataStore],
        };
    };
    CoreDataModule.forChild = function () {
        return {
            ngModule: CoreDataModule,
            providers: [],
        };
    };
    CoreDataModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreDataModule });
    CoreDataModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreDataModule_Factory(t) { return new (t || CoreDataModule)(); } });
    return CoreDataModule;
}());
export { CoreDataModule };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreDataModule, [{
        type: NgModule,
        args: [{}]
    }], null, null); })();
var CoreMockDataModule = /** @class */ (function () {
    function CoreMockDataModule() {
    }
    CoreMockDataModule.withMockData = function (registry) {
        return {
            ngModule: CoreMockDataModule,
            providers: [{ provide: MOCK_DATA, useValue: registry }],
        };
    };
    CoreMockDataModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreMockDataModule });
    CoreMockDataModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreMockDataModule_Factory(t) { return new (t || CoreMockDataModule)(); }, imports: [[
                HttpClientInMemoryWebApiModule.forRoot(MockDataService, {
                    delay: 1000,
                    passThruUnknownUrl: true,
                }),
            ]] });
    return CoreMockDataModule;
}());
export { CoreMockDataModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreMockDataModule, { imports: [i1.HttpClientInMemoryWebApiModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreMockDataModule, [{
        type: NgModule,
        args: [{
                imports: [
                    HttpClientInMemoryWebApiModule.forRoot(MockDataService, {
                        delay: 1000,
                        passThruUnknownUrl: true,
                    }),
                ],
            }]
    }], null, null); })();
