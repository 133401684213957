/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { isBoolean, isNil } from 'lodash';
import * as i0 from "@angular/core";
var IsControlRequiredPipe = /** @class */ (function () {
    function IsControlRequiredPipe() {
    }
    IsControlRequiredPipe.prototype.transform = function (control) {
        if (isNil(control)) {
            return false;
        }
        var required = control['required'];
        if (isBoolean(required)) {
            return required;
        }
        else {
            throw new Error('Control does not have required flag. Looks like you do not use form schema to build form');
        }
    };
    IsControlRequiredPipe.ɵfac = function IsControlRequiredPipe_Factory(t) { return new (t || IsControlRequiredPipe)(); };
    IsControlRequiredPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "isControlRequired", type: IsControlRequiredPipe, pure: false });
    return IsControlRequiredPipe;
}());
export { IsControlRequiredPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IsControlRequiredPipe, [{
        type: Pipe,
        args: [{ name: 'isControlRequired', pure: false }]
    }], null, null); })();
