/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreMlsModule } from '@gh/core-mls';
import { CoreTypeModule } from '@gh/core-type';
import { CoreUiCommonModule } from '../common';
import { APP_DATE_FORMATS, AppDateAdapter, AppDatepickerIntl, AutocompleteActionIconComponent, AutocompleteArrowIconComponent, AutocompleteComponent, AutocompleteDirective, AutocompleteMaskedTypeDirective, AutocompleteOptionDirective, ClearComponent, DatepickerInputFixDirective, DatepickerToggleFixDirective, InlineTimepickerComponent, TimepickerComponent, TinymceTextareaDirective, } from './components';
import { CollapsibleContainerDirective, CollapsibleInputDirective, CollapsibleLabelComponent, CompareByDirective, DateTimePickerDirective, DateTimePickerInputDirective, FloatLabelAlwaysDirective, FormAsyncEffectDirective, MapValueDirective, MessagesForDirective, ShowErrorOnDirtyDirective, UppercaseDirective, NoDblClickMatDirective, } from './directives';
import { AsFormArrayPipe, AsFormGroupPipe } from './pipes';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/material/card";
import * as i3 from "@angular/cdk/bidi";
import * as i4 from "@angular/material/list";
import * as i5 from "@angular/material/core";
import * as i6 from "@angular/material/divider";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/material/progress-bar";
import * as i9 from "@angular/material/tooltip";
import * as i10 from "@angular/cdk/scrolling";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/text-field";
import * as i13 from "@angular/material/form-field";
import * as i14 from "@angular/material/input";
import * as i15 from "@angular/material/autocomplete";
import * as i16 from "../common/directives/infinite-scroll.directive";
import * as i17 from "../common/directives/automation-id.directive";
import * as i18 from "../common/directives/ellipsis.directive";
import * as i19 from "../common/directives/focus.directive";
import * as i20 from "../common/directives/focus-on-init.directive";
import * as i21 from "../common/directives/capture-event.directive";
import * as i22 from "../common/directives/event-stop-propagation.directive";
import * as i23 from "../common/directives/renderer.directive";
import * as i24 from "../common/directives/local.directive";
import * as i25 from "../common/components/template-site/template-site.component";
import * as i26 from "../../core-mls/components/message-repository/message-repository.component";
import * as i27 from "../../core-mls/components/message-repository/message.directive";
import * as i28 from "../../core-type/directives/data-type.directive";
import * as i29 from "../../core-type/directives/masked-type.directive";
import * as i30 from "../../core-type/directives/number-type.directive";
import * as i31 from "../../core-type/directives/date-type.directive";
import * as i32 from "../../core-type/directives/quantity-type.directive";
import * as i33 from "../common/pipes/sanitize.pipe";
import * as i34 from "../common/pipes/highlight.pipe";
import * as i35 from "../common/pipes/html-with-urls.pipe";
import * as i36 from "../common/pipes/text-with-urls.pipe";
import * as i37 from "../common/pipes/keys.pipe";
import * as i38 from "../common/pipes/to-array.pipe";
import * as i39 from "../common/pipes/in-context.pipe";
import * as i40 from "../common/pipes/pluck$.pipe";
import * as i41 from "../common/pipes/default-currency.pipe";
import * as i42 from "../common/pipes/log.pipe";
import * as i43 from "../../core-mls/translation/translate.pipe";
import * as i44 from "../../core-type/pipes/type-format.pipe";
var CoreUiFormModule = /** @class */ (function () {
    function CoreUiFormModule() {
    }
    CoreUiFormModule.forRoot = function () {
        return {
            ngModule: CoreUiFormModule,
            providers: [
                { provide: DateAdapter, useClass: AppDateAdapter },
                { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
                { provide: MatDatepickerIntl, useClass: AppDatepickerIntl },
            ],
        };
    };
    CoreUiFormModule.forChild = function () {
        return {
            ngModule: CoreUiFormModule,
        };
    };
    CoreUiFormModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreUiFormModule });
    CoreUiFormModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreUiFormModule_Factory(t) { return new (t || CoreUiFormModule)(); }, imports: [[
                CommonModule,
                MatCardModule,
                MatListModule,
                MatIconModule,
                MatProgressBarModule,
                MatTooltipModule,
                MatButtonModule,
                MatInputModule,
                MatFormFieldModule,
                MatAutocompleteModule,
                CoreUiCommonModule,
                CoreMlsModule,
                CoreTypeModule,
            ]] });
    return CoreUiFormModule;
}());
export { CoreUiFormModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreUiFormModule, { declarations: [TinymceTextareaDirective,
        // autocomplete declarations
        AutocompleteDirective,
        AutocompleteMaskedTypeDirective,
        AutocompleteOptionDirective,
        AutocompleteComponent,
        AutocompleteActionIconComponent,
        AutocompleteArrowIconComponent,
        // clear declaration
        ClearComponent,
        // datepicker declarations
        DatepickerInputFixDirective,
        DatepickerToggleFixDirective,
        // timepicker declarations
        TimepickerComponent,
        InlineTimepickerComponent,
        // helper directives
        MessagesForDirective,
        CollapsibleInputDirective,
        CollapsibleContainerDirective,
        CollapsibleLabelComponent,
        MapValueDirective,
        CompareByDirective,
        FormAsyncEffectDirective,
        FloatLabelAlwaysDirective,
        UppercaseDirective,
        ShowErrorOnDirtyDirective,
        DateTimePickerDirective,
        DateTimePickerInputDirective,
        NoDblClickMatDirective,
        // helper pipes
        AsFormArrayPipe,
        AsFormGroupPipe], imports: [CommonModule,
        MatCardModule,
        MatListModule,
        MatIconModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        CoreUiCommonModule,
        CoreMlsModule,
        CoreTypeModule], exports: [TinymceTextareaDirective,
        // autocomplete declarations
        AutocompleteDirective,
        AutocompleteMaskedTypeDirective,
        AutocompleteOptionDirective,
        AutocompleteComponent,
        AutocompleteActionIconComponent,
        AutocompleteArrowIconComponent,
        // clear declaration
        ClearComponent,
        // datepicker declarations
        DatepickerInputFixDirective,
        DatepickerToggleFixDirective,
        // timepicker declarations
        TimepickerComponent,
        InlineTimepickerComponent,
        // helper directives
        MessagesForDirective,
        CollapsibleInputDirective,
        CollapsibleContainerDirective,
        CollapsibleLabelComponent,
        MapValueDirective,
        CompareByDirective,
        FormAsyncEffectDirective,
        FloatLabelAlwaysDirective,
        UppercaseDirective,
        ShowErrorOnDirtyDirective,
        DateTimePickerDirective,
        DateTimePickerInputDirective,
        NoDblClickMatDirective,
        // helper pipes
        AsFormArrayPipe,
        AsFormGroupPipe] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreUiFormModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                    MatCardModule,
                    MatListModule,
                    MatIconModule,
                    MatProgressBarModule,
                    MatTooltipModule,
                    MatButtonModule,
                    MatInputModule,
                    MatFormFieldModule,
                    MatAutocompleteModule,
                    CoreUiCommonModule,
                    CoreMlsModule,
                    CoreTypeModule,
                ],
                declarations: [
                    TinymceTextareaDirective,
                    // autocomplete declarations
                    AutocompleteDirective,
                    AutocompleteMaskedTypeDirective,
                    AutocompleteOptionDirective,
                    AutocompleteComponent,
                    AutocompleteActionIconComponent,
                    AutocompleteArrowIconComponent,
                    // clear declaration
                    ClearComponent,
                    // datepicker declarations
                    DatepickerInputFixDirective,
                    DatepickerToggleFixDirective,
                    // timepicker declarations
                    TimepickerComponent,
                    InlineTimepickerComponent,
                    // helper directives
                    MessagesForDirective,
                    CollapsibleInputDirective,
                    CollapsibleContainerDirective,
                    CollapsibleLabelComponent,
                    MapValueDirective,
                    CompareByDirective,
                    FormAsyncEffectDirective,
                    FloatLabelAlwaysDirective,
                    UppercaseDirective,
                    ShowErrorOnDirtyDirective,
                    DateTimePickerDirective,
                    DateTimePickerInputDirective,
                    NoDblClickMatDirective,
                    // helper pipes
                    AsFormArrayPipe,
                    AsFormGroupPipe,
                ],
                entryComponents: [
                    AutocompleteActionIconComponent,
                    AutocompleteArrowIconComponent,
                ],
                exports: [
                    TinymceTextareaDirective,
                    // autocomplete declarations
                    AutocompleteDirective,
                    AutocompleteMaskedTypeDirective,
                    AutocompleteOptionDirective,
                    AutocompleteComponent,
                    AutocompleteActionIconComponent,
                    AutocompleteArrowIconComponent,
                    // clear declaration
                    ClearComponent,
                    // datepicker declarations
                    DatepickerInputFixDirective,
                    DatepickerToggleFixDirective,
                    // timepicker declarations
                    TimepickerComponent,
                    InlineTimepickerComponent,
                    // helper directives
                    MessagesForDirective,
                    CollapsibleInputDirective,
                    CollapsibleContainerDirective,
                    CollapsibleLabelComponent,
                    MapValueDirective,
                    CompareByDirective,
                    FormAsyncEffectDirective,
                    FloatLabelAlwaysDirective,
                    UppercaseDirective,
                    ShowErrorOnDirtyDirective,
                    DateTimePickerDirective,
                    DateTimePickerInputDirective,
                    NoDblClickMatDirective,
                    // helper pipes
                    AsFormArrayPipe,
                    AsFormGroupPipe,
                ],
            }]
    }], null, null); })();
i0.ɵɵsetComponentScope(
// timepicker declarations
TimepickerComponent, [i1.NgClass, i1.NgComponentOutlet, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i1.NgSwitch, i1.NgSwitchCase, i1.NgSwitchDefault, i1.NgPlural, i1.NgPluralCase, i2.MatCard, i2.MatCardHeader, i2.MatCardTitleGroup, i2.MatCardContent, i2.MatCardTitle, i2.MatCardSubtitle, i2.MatCardActions, i2.MatCardFooter, i2.MatCardSmImage, i2.MatCardMdImage, i2.MatCardLgImage, i2.MatCardImage, i2.MatCardXlImage, i2.MatCardAvatar, i3.Dir, i4.MatList, i4.MatNavList, i4.MatListItem, i4.MatListAvatarCssMatStyler, i5.MatLine, i4.MatListIconCssMatStyler, i4.MatListSubheaderCssMatStyler, i5.MatPseudoCheckbox, i4.MatSelectionList, i4.MatListOption, i6.MatDivider, i7.MatIcon, i8.MatProgressBar, i9.MatTooltip, i9.TooltipComponent, i10.CdkScrollable, i11.MatButton, i11.MatAnchor, i12.CdkAutofill, i12.CdkTextareaAutosize, i13.MatError, i13.MatFormField, i13.MatHint, i13.MatLabel, i13.MatPlaceholder, i13.MatPrefix, i13.MatSuffix, i14.MatInput, i14.MatTextareaAutosize, i15.MatAutocomplete, i5.MatOption, i5.MatOptgroup, i15.MatAutocompleteTrigger, i15.MatAutocompleteOrigin, i16.InfiniteScrollDirective, i17.AutomationIDDirective, i18.EllipsisDirective, i19.FocusDirective, i20.FocusOnInitDirective, i21.CaptureEventDirective, i22.EventStopPropagationDirective, i23.RendererDirective, i24.LocalDirective, i25.TemplateSiteComponent, i26.MessageRepositoryComponent, i27.MessageDirective, i28.DataTypeDirective, i29.MaskedTypeDirective, i30.NumberTypeDirective, i31.DateTypeDirective, i32.QuantityTypeDirective, TinymceTextareaDirective,
    // autocomplete declarations
    AutocompleteDirective,
    AutocompleteMaskedTypeDirective,
    AutocompleteOptionDirective,
    AutocompleteComponent,
    AutocompleteActionIconComponent,
    AutocompleteArrowIconComponent,
    // clear declaration
    ClearComponent,
    // datepicker declarations
    DatepickerInputFixDirective,
    DatepickerToggleFixDirective,
    // timepicker declarations
    TimepickerComponent,
    InlineTimepickerComponent,
    // helper directives
    MessagesForDirective,
    CollapsibleInputDirective,
    CollapsibleContainerDirective,
    CollapsibleLabelComponent,
    MapValueDirective,
    CompareByDirective,
    FormAsyncEffectDirective,
    FloatLabelAlwaysDirective,
    UppercaseDirective,
    ShowErrorOnDirtyDirective,
    DateTimePickerDirective,
    DateTimePickerInputDirective,
    NoDblClickMatDirective], [i1.AsyncPipe, i1.UpperCasePipe, i1.LowerCasePipe, i1.JsonPipe, i1.SlicePipe, i1.DecimalPipe, i1.PercentPipe, i1.TitleCasePipe, i1.CurrencyPipe, i1.DatePipe, i1.I18nPluralPipe, i1.I18nSelectPipe, i1.KeyValuePipe, i33.SanitizeHtmlPipe, i33.SanitizeUrlPipe, i34.HighlightPipe, i35.HtmlWithUrlsPipe, i36.TextWithUrlsPipe, i37.KeysPipe, i38.ToArrayPipe, i39.InContextPipe, i40.Pluck$Pipe, i41.DefaultCurrencyPipe, i42.LogPipe, i43.TranslatePipe, i44.TypeFormatPipe, 
    // helper pipes
    AsFormArrayPipe,
    AsFormGroupPipe]);
i0.ɵɵsetComponentScope(InlineTimepickerComponent, [i1.NgClass, i1.NgComponentOutlet, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i1.NgSwitch, i1.NgSwitchCase, i1.NgSwitchDefault, i1.NgPlural, i1.NgPluralCase, i2.MatCard, i2.MatCardHeader, i2.MatCardTitleGroup, i2.MatCardContent, i2.MatCardTitle, i2.MatCardSubtitle, i2.MatCardActions, i2.MatCardFooter, i2.MatCardSmImage, i2.MatCardMdImage, i2.MatCardLgImage, i2.MatCardImage, i2.MatCardXlImage, i2.MatCardAvatar, i3.Dir, i4.MatList, i4.MatNavList, i4.MatListItem, i4.MatListAvatarCssMatStyler, i5.MatLine, i4.MatListIconCssMatStyler, i4.MatListSubheaderCssMatStyler, i5.MatPseudoCheckbox, i4.MatSelectionList, i4.MatListOption, i6.MatDivider, i7.MatIcon, i8.MatProgressBar, i9.MatTooltip, i9.TooltipComponent, i10.CdkScrollable, i11.MatButton, i11.MatAnchor, i12.CdkAutofill, i12.CdkTextareaAutosize, i13.MatError, i13.MatFormField, i13.MatHint, i13.MatLabel, i13.MatPlaceholder, i13.MatPrefix, i13.MatSuffix, i14.MatInput, i14.MatTextareaAutosize, i15.MatAutocomplete, i5.MatOption, i5.MatOptgroup, i15.MatAutocompleteTrigger, i15.MatAutocompleteOrigin, i16.InfiniteScrollDirective, i17.AutomationIDDirective, i18.EllipsisDirective, i19.FocusDirective, i20.FocusOnInitDirective, i21.CaptureEventDirective, i22.EventStopPropagationDirective, i23.RendererDirective, i24.LocalDirective, i25.TemplateSiteComponent, i26.MessageRepositoryComponent, i27.MessageDirective, i28.DataTypeDirective, i29.MaskedTypeDirective, i30.NumberTypeDirective, i31.DateTypeDirective, i32.QuantityTypeDirective, TinymceTextareaDirective,
    // autocomplete declarations
    AutocompleteDirective,
    AutocompleteMaskedTypeDirective,
    AutocompleteOptionDirective,
    AutocompleteComponent,
    AutocompleteActionIconComponent,
    AutocompleteArrowIconComponent,
    // clear declaration
    ClearComponent,
    // datepicker declarations
    DatepickerInputFixDirective,
    DatepickerToggleFixDirective,
    // timepicker declarations
    TimepickerComponent,
    InlineTimepickerComponent,
    // helper directives
    MessagesForDirective,
    CollapsibleInputDirective,
    CollapsibleContainerDirective,
    CollapsibleLabelComponent,
    MapValueDirective,
    CompareByDirective,
    FormAsyncEffectDirective,
    FloatLabelAlwaysDirective,
    UppercaseDirective,
    ShowErrorOnDirtyDirective,
    DateTimePickerDirective,
    DateTimePickerInputDirective,
    NoDblClickMatDirective], [i1.AsyncPipe, i1.UpperCasePipe, i1.LowerCasePipe, i1.JsonPipe, i1.SlicePipe, i1.DecimalPipe, i1.PercentPipe, i1.TitleCasePipe, i1.CurrencyPipe, i1.DatePipe, i1.I18nPluralPipe, i1.I18nSelectPipe, i1.KeyValuePipe, i33.SanitizeHtmlPipe, i33.SanitizeUrlPipe, i34.HighlightPipe, i35.HtmlWithUrlsPipe, i36.TextWithUrlsPipe, i37.KeysPipe, i38.ToArrayPipe, i39.InContextPipe, i40.Pluck$Pipe, i41.DefaultCurrencyPipe, i42.LogPipe, i43.TranslatePipe, i44.TypeFormatPipe, 
    // helper pipes
    AsFormArrayPipe,
    AsFormGroupPipe]);
