/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { logDebug } from '@gh/core-util';
import * as invariant from 'invariant';
import { isNil, isObject } from 'lodash';
import { CompiledTranslation, TranslationCompiler } from './translation-compiler.service';
import { TranslationStorage } from './translation-storage.service';
import { TranslationParams, TranslationTuple } from './translation.interfaces';

export interface TranslationProvider {
  get(key: string, params?: TranslationParams): string;

  getRaw(key: string): any;
}

@Injectable()
export class TranslationService implements TranslationProvider {
  private cache: Hash<CompiledTranslation> = {};

  constructor(private storage: TranslationStorage,
              private translationCompiler: TranslationCompiler) {
    this.cache[''] = this.translationCompiler.compile('');
  }

  get(key: string, params?: TranslationParams): string;
  get(tuple: TranslationTuple): string;
  get(keyOrTuple: string | TranslationTuple, params?: TranslationParams): string {
    const isTuple = isObject(keyOrTuple);
    const key: string = isTuple ? keyOrTuple['id'] : keyOrTuple;

    if (isNil(key)) {
      invariant(key, 'Translation key is undefined');
      return key;
    }

    let compiled = this.cache[key];

    if (isNil(compiled)) {
      let text = this.storage.get(key);

      if (isNil(text)) {
        logDebug(`Translation for key '${key}' is missing`);
        text = key;
      }

      this.cache[key] = compiled = this.translationCompiler.compile(text);
    }

    return compiled(isTuple ? keyOrTuple['params'] : params);
  }

  getRaw(key: string): any {
    return this.storage.get(key);
  }
}
