/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { isFunction } from 'lodash';

export function aspectAround(fn: Function | object,
                             name: string,
                             around: (source: Function) => (...args: any[]) => any): void {
  if (isFunction(fn)) {
    const original = fn.prototype[name];

    fn.prototype[name] = around(original);
  } else {
    const original = fn[name];
    fn[name] = around(original);
  }
}
