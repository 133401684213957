/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:no-invalid-this
import { Directive, HostBinding, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatCalendarBody, MatDatepicker, MatDatepickerToggle } from '@angular/material/datepicker';
import { aspectAround } from '@gh/core-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/material/datepicker";
var DatepickerInputFixDirective = /** @class */ (function () {
    function DatepickerInputFixDirective(ngControl) {
        this.ngControl = ngControl;
    }
    Object.defineProperty(DatepickerInputFixDirective.prototype, "matDatepicker", {
        set: function (datepicker) {
            this.datepicker = datepicker;
        },
        enumerable: true,
        configurable: true
    });
    DatepickerInputFixDirective.prototype.ngDoCheck = function () {
        var control = this.ngControl.control;
        if (control && this.disabled !== control.disabled) {
            this.datepicker['openDisabled'] = control.disabled;
            this.disabled = control.disabled;
        }
    };
    DatepickerInputFixDirective.ɵfac = function DatepickerInputFixDirective_Factory(t) { return new (t || DatepickerInputFixDirective)(i0.ɵɵdirectiveInject(i1.NgControl)); };
    DatepickerInputFixDirective.ɵdir = i0.ɵɵdefineDirective({ type: DatepickerInputFixDirective, selectors: [["", "matDatepicker", ""]], inputs: { matDatepicker: "matDatepicker" } });
    return DatepickerInputFixDirective;
}());
export { DatepickerInputFixDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DatepickerInputFixDirective, [{
        type: Directive,
        args: [{
                selector: '[matDatepicker]',
            }]
    }], function () { return [{ type: i1.NgControl }]; }, { matDatepicker: [{
            type: Input
        }] }); })();
var DatepickerToggleFixDirective = /** @class */ (function () {
    function DatepickerToggleFixDirective(toggle) {
        this.toggle = toggle;
        this.disabled = true;
    }
    DatepickerToggleFixDirective.prototype.ngDoCheck = function () {
        this.disabled = this.toggle.datepicker['openDisabled'];
    };
    DatepickerToggleFixDirective.ɵfac = function DatepickerToggleFixDirective_Factory(t) { return new (t || DatepickerToggleFixDirective)(i0.ɵɵdirectiveInject(i2.MatDatepickerToggle)); };
    DatepickerToggleFixDirective.ɵdir = i0.ɵɵdefineDirective({ type: DatepickerToggleFixDirective, selectors: [["mat-datepicker-toggle"]], hostVars: 2, hostBindings: function DatepickerToggleFixDirective_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵclassProp("datepicker-toggle-disabled", ctx.disabled);
        } } });
    return DatepickerToggleFixDirective;
}());
export { DatepickerToggleFixDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DatepickerToggleFixDirective, [{
        type: Directive,
        args: [{
                selector: 'mat-datepicker-toggle',
            }]
    }], function () { return [{ type: i2.MatDatepickerToggle }]; }, { disabled: [{
            type: HostBinding,
            args: ['class.datepicker-toggle-disabled']
        }] }); })();
// extend MatCalendarBody to fix handling of invalid dates
aspectAround(MatCalendarBody, '_focusActiveCell', function (original) { return function () {
    if (this.activeCell < 0) {
        return;
    }
    return original.apply(this, arguments);
}; });
