/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DictionaryItemDto } from '@gh/common-shared-data';
import { Enum } from '@gh/core-data';
import { CollectionComparator, orderByComparator } from '@gh/core-util';

import { constant, fromPairs, isNil, keyBy, mapValues } from 'lodash';

export class DictionaryItem<T> {
  id: T;
  name: string;
}

export interface Dictionary<T> {
  count(): number;

  seq(): DictionaryItem<T>[];

  get(id: T): string;

  filter(predicate: PredicateFn<DictionaryItem<T>>): Dictionary<T>;

  sort(comparator: CollectionComparator<DictionaryItem<T>>): Dictionary<T>;
}

export function dictionaryItemFromDto(dto: DictionaryItemDto): DictionaryItem<string> {
  return {
    id: dto.itemCode,
    name: dto.localization,
  };
}

export class TextDictionary implements Dictionary<string> {
  private _index?: Hash<string>;

  constructor(private items: DictionaryItem<string>[]) {

  }

  count(): number {
    return this.items.length;
  }

  seq(): DictionaryItem<string>[] {
    return this.items;
  }

  get(id: string): string {
    if (isNil(this._index)) {
      this._index = <any>mapValues(keyBy(this.items, 'id'), 'name');
    }

    return (<any>this._index)[id];
  }

  filter(predicate: PredicateFn<DictionaryItem<string>>): TextDictionary {
    return new TextDictionary(this.items.filter(predicate));
  }

  sort(comparator: CollectionComparator<DictionaryItem<string>>): TextDictionary {
    return new TextDictionary(orderByComparator(this.items, comparator));
  }
}

export class EnumDictionary<T> implements Dictionary<T> {
  private _index?: Hash<string>;

  constructor(private enumeration: Enum<any>, private _seq: DictionaryItem<T>[]) {
  }

  count(): number {
    return this._seq.length;
  }

  seq(): DictionaryItem<T>[] {
    return this._seq;
  }

  get(id: T): string {
    if (!this._index) {
      this._index = fromPairs(this._seq.map((item) => [item.id, item.name]));
    }

    return this._index[<any>id];
  }

  filter(predicate: PredicateFn<DictionaryItem<T>>): EnumDictionary<T> {
    return new EnumDictionary(this.enumeration, this._seq.filter(predicate));
  }

  sort(comparator: CollectionComparator<DictionaryItem<T>>): EnumDictionary<T> {
    return new EnumDictionary(this.enumeration, orderByComparator(this._seq, comparator));
  }
}

export const EMPTY_DICTIONARY: Dictionary<any> = {
  count: constant(0),
  seq: constant([]),
  get: constant(''),
  filter: () => EMPTY_DICTIONARY,
  sort: () => EMPTY_DICTIONARY,
};

export const EMPTY_TEXT_DICTIONARY: TextDictionary = <any>EMPTY_DICTIONARY;
export const EMPTY_ENUM_DICTIONARY: EnumDictionary<any> = <any>EMPTY_DICTIONARY;
