/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { isString } from 'lodash';

export type TranslationParams = Hash<string>;
export type TranslationMap = Hash<string>;
export type TranslationTuple = { id: string; params?: TranslationParams };
export type TranslationTupleOrId = string | TranslationTuple;

export const TRANSLATION_ID_EMPTY = '';
export const TRANSLATION_TUPLE_EMPTY: TranslationTuple = { id: TRANSLATION_ID_EMPTY };

export function translationTuple(key: string, params?: TranslationParams): TranslationTuple;
export function translationTuple(tuple: TranslationTuple | string): TranslationTuple;
export function translationTuple(keyOrTuple: TranslationTuple | string, params?: TranslationParams): TranslationTuple {
  if (isString(keyOrTuple)) {
    return { id: keyOrTuple, params };
  } else {
    return keyOrTuple;
  }
}
