/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filter$ } from '@gh/rx/operators';
import { Observable, Subscription } from 'rxjs';
import { UploadContext } from './upload-context.service';
import { UploadSlot } from './upload-slot';
import { FileInfo, isUploadCompletedEvent, UploadController, UploadEvent, UploadItem } from './upload.interfaces';

/**
 * Implementation of {@link UploadController} interface which binds itself to the assigned {@link UploadSlot} instance.
 */
@Directive({
  selector: '[ghUploadSlot]',
  providers: [{ provide: UploadController, useExisting: UploadSlotDirective }],
})
export class UploadSlotDirective extends UploadController implements OnInit, OnDestroy {
  @Input('ghUploadSlot') slot: UploadSlot;

  @Output('ghUploadSlotCompleted') completed = new EventEmitter<UploadItem>();

  private subscription: Subscription;

  constructor(private context: UploadContext) {
    super();
  }

  get event$(): Observable<UploadEvent> {
    return this.slot.event$;
  }

  get items(): UploadItem[] {
    const { item } = this.slot;

    return item ? [item] : [];
  }

  ngOnInit(): void {
    this.subscription = this.event$
      .pipe(filter$(isUploadCompletedEvent))
      .subscribe(({ item }) => this.completed.emit(item));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  create(file: FileInfo): UploadItem {
    const item = this.context.create(file);

    this.slot.assign(item);

    return item;
  }

  start(item: UploadItem): void {
    this.slot.start();
  }

  cancel(item: UploadItem): void {
    this.slot.cancel();
  }
}
