/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { MatTableDataSource } from '@angular/material/table';
import { NumberTypes } from '@gh/common-shared';

import { FinancialInvoiceListItemDto, FinancialInvoicePaymentDto, FinancialRecordDto } from '@gh/customer-shared-data';

import { sumBy } from 'lodash';

export type FinancialRecord = FinancialRecordDto;
export type FinancialInvoiceListItem = FinancialInvoiceListItemDto;
export type FinancialInvoicePayment = FinancialInvoicePaymentDto;

export type FinancialInvoiceTableRowData = FinancialInvoiceListItem & {
  paymentDataSource: MatTableDataSource<FinancialInvoicePayment>;
  dropDownAction: boolean;
};

export const paymentsTotal = (payments: FinancialInvoicePayment[]) =>
  NumberTypes.Price.format(sumBy(payments, 'amount'));
