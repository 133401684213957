/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router, } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export var DEEP_LINK_FACTORY = new InjectionToken('DeepLinks');
var DeepLinkActivateService = /** @class */ (function () {
    function DeepLinkActivateService(router, deepLinkFactory) {
        this.router = router;
        this.deepLinkFactory = deepLinkFactory;
    }
    DeepLinkActivateService.prototype.canActivate = function (route, state) {
        return this.navigateToDeepLink(route);
    };
    DeepLinkActivateService.prototype.canActivateChild = function (childRoute, state) {
        return this.navigateToDeepLink(childRoute);
    };
    DeepLinkActivateService.prototype.navigateToDeepLink = function (route) {
        var deepLink = this.deepLinkFactory(route);
        if (deepLink) {
            this.router.navigate(deepLink.commands, deepLink.extras);
        }
        else {
            throw new Error("Deep link cannot be found for url '" + route.url + "'");
        }
        return false;
    };
    DeepLinkActivateService.ɵfac = function DeepLinkActivateService_Factory(t) { return new (t || DeepLinkActivateService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(DEEP_LINK_FACTORY)); };
    DeepLinkActivateService.ɵprov = i0.ɵɵdefineInjectable({ token: DeepLinkActivateService, factory: DeepLinkActivateService.ɵfac });
    return DeepLinkActivateService;
}());
export { DeepLinkActivateService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DeepLinkActivateService, [{
        type: Injectable
    }], function () { return [{ type: i1.Router }, { type: undefined, decorators: [{
                type: Inject,
                args: [DEEP_LINK_FACTORY]
            }] }]; }, null); })();
