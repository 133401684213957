/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { acceptor, parseByteSize } from '@gh/core-util';
import { map$ } from '@gh/rx/operators';
import { constant, isNil } from 'lodash';
import { Subscription } from 'rxjs';
import {
  createFileInfoFromBlob,
  getUploadItemName,
  isUploadCompleted,
  UploadController,
  UploadEventType,
  UploadItem,
} from './upload.interfaces';

export enum InvalidFileReason { NotAccepted, TooLarge, WrongSize }

export interface FileInvalidEvent {
  reason: InvalidFileReason;
  file: File;
}

/**
 * This component allows to mangage single upload process. It is displayed as typical input with file name.
 *
 * All managing of an upload process is delegated to the {@link UploadController}. It is necessary to detach UI from
 * the way how upload process is managed. It is supposed that this component has assigned directive providing
 * implementation of {@link UploadController} (e.g. ghUploadSlot).
 */
@Component({
  selector: 'gh-single-uploader',
  templateUrl: 'single-uploader.component.html',
  styleUrls: ['single-uploader.component.scss'],
  exportAs: 'ghSingleUploader',
})
export class SingleUploaderComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() deleteIcon = 'gh:delete';
  @Input() openByClick: boolean = true;
  @Input() useUploadIcon: boolean = true;
  @Input() useDownloadIcon: boolean = true;
  @Input() minWidth: number = 0;
  @Input() minHeight: number = 0;
  @Input() checkImageSize: boolean = false;

  // tslint:disable-next-line:no-output-named-after-standard-event
  @Output() invalid = new EventEmitter<FileInvalidEvent>();
  // tslint:disable-next-line:no-output-named-after-standard-event
  @Output() selectionChange = new EventEmitter<Maybe<UploadItem>>();

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  progress?: number;

  private _accept: string = '';
  private acceptor: PredicateFn<File> = constant(true);
  private _maxSize: number;
  private progressSubscription: Subscription;

  constructor(@Optional() @Self() private uploader: UploadController) {

  }

  @Input()
  set maxSize(maxSize: string) {
    this._maxSize = parseByteSize(maxSize);
  }

  @Input()
  set accept(accept: string) {
    this._accept = accept;
    this.acceptor = acceptor(accept);
  }

  get accept(): string {
    return this._accept;
  }

  get item(): Maybe<UploadItem> {
    return this.uploader.items[0];
  }

  get fileName(): string {
    const { item } = this;
    return item && getUploadItemName(item) || '';
  }

  get displayDeleteButton(): boolean {
    return !isNil(this.item) && !this.disabled;
  }

  get displayDownloadButton(): boolean {
    const { item } = this;
    return this.useDownloadIcon && (item ? isUploadCompleted(item) : false);
  }

  ngOnInit(): void {
    if (isNil(this.uploader)) {
      throw new Error('Upload controller is not defined');
    }

    this.progressSubscription = this.uploader.event$
      .pipe(map$((event) => {
        switch (event.type) {
          case UploadEventType.Started:
            return 0;
          case UploadEventType.Progress:
            return Math.round((event.item.progress || 0) * 100); // tslint:disable-line:no-magic-numbers
          case UploadEventType.Error:
            return void 0;
          case UploadEventType.Completed:
            return 100; // tslint:disable-line:no-magic-numbers
          case UploadEventType.Cancelled:
            this._resetSelection(false);
            return void 0;
          default:
            return void 0;
        }
      }))
      .subscribe((progress) => {
        this.progress = progress;
      });
  }

  ngOnDestroy(): void {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }
  }

  open(): void {
    this.fileInput.nativeElement.click();
  }

  onSelect(file?: File): void {
    if (isNil(file)) {
      return;
    }

    if (this.checkImageSize) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if (width < this.minWidth || height < this.minHeight) {
            this.invalid.emit({ reason: InvalidFileReason.WrongSize, file });
          } else if (this._maxSize && file.size > this._maxSize) {
            this.invalid.emit({ reason: InvalidFileReason.TooLarge, file });
          } else if (!this.acceptor(file)) {
            this.invalid.emit({ reason: InvalidFileReason.NotAccepted, file });
          } else {
            this._cancel(false);

            const item = this.uploader.create(createFileInfoFromBlob(file));
            this.uploader.start(item);

            this.selectionChange.emit(item);
          }
        };
      };
      return;
    } else {
      if (this._maxSize && file.size > this._maxSize) {
        this.invalid.emit({ reason: InvalidFileReason.TooLarge, file });
        return;
      } else if (!this.acceptor(file)) {
        this.invalid.emit({ reason: InvalidFileReason.NotAccepted, file });
        return;
      }

      this._cancel(false);

      const item = this.uploader.create(createFileInfoFromBlob(file));
      this.uploader.start(item);

      this.selectionChange.emit(item);
    }
  }

  reset(): void {
    this._cancel(true);
  }

  private _cancel(emitEvent: boolean = false): void {
    if (isNil(this.item)) {
      return;
    }

    this.uploader.cancel(this.item);

    this._resetSelection(emitEvent);
  }

  private _resetSelection(emitEvent: boolean = false): void {
    this.progress = void 0;

    this.fileInput.nativeElement.value = null; // tslint:disable-line:no-null-keyword

    if (emitEvent) {
      this.selectionChange.emit();
    }
  }
}
