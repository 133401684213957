/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map, truncate, uniqueId } from 'lodash';
var MAX_TITLE_LENGTH = 75;
export var customerPhotoListItemFromDto = function (item) { return (__assign(__assign({}, item), { id: uniqueId('photo-list-item:') })); };
export var customerPhotoListFromDto = function (customerPhotoList) {
    return map(customerPhotoList, customerPhotoListItemFromDto);
};
export var shortenedPhotoTitle = function (title) { return truncate(title, {
    length: MAX_TITLE_LENGTH,
}); };
