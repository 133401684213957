/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers
import { digits } from '../mask-utils';

export const MASKED_EN_NZ = {
  Zip: {
    constraints: {
      pattern: /^[0-9]{4}$/,
    },
    mask: digits(4),
  },
  Gst: {
    constraints: {
      pattern: /^([0-9]{2}-[0-9]{3}-[0-9]{3})|([0-9]{3}-[0-9]{3}-[0-9]{3})$/,
    },
    mask: (value: string, config: any) => {
      let mask: any[] = [...digits(2), '-', ...digits(3), '-', ...digits(3)];
      value = value.replace(new RegExp(config.placeholderChar + '|-', 'g'), '');
      if (value.length > 8) {
        mask = [...digits(3), '-', ...digits(3), '-', ...digits(3)];
      }

      return mask;
    },
  },
  BsbNumber: {
    constraints: {
      pattern: /^\d{6}$/,
    },
    mask: digits(6),
  },
  PhoneNumber: {
    constraints: {
      pattern: /^\d{20}$/,
    },
    mask: [...digits(20)],
  },
};
