/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

export type TranslationSource = { [locale: string]: string };

const pending: TranslationSource[] = [];

export function addPending(source: TranslationSource): void {
  pending.push(source);
}

export function getPendings(): TranslationSource[] {
  return pending.concat();
}

export function clearPendings(): void {
  pending.length = 0;
}
