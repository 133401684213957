/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Input } from '@angular/core';
import { stopPropagation } from '@gh/core-util';
import * as i0 from "@angular/core";
var EventStopPropagationDirective = /** @class */ (function () {
    function EventStopPropagationDirective(elementRef) {
        this.elementRef = elementRef;
        this.trackedEvents = [];
    }
    Object.defineProperty(EventStopPropagationDirective.prototype, "events", {
        set: function (events) {
            var trackedEvents = Array.isArray(events) ? events : events.split(/\s+/g);
            this.cleanTrackedEvents();
            this.trackEvents(trackedEvents);
        },
        enumerable: true,
        configurable: true
    });
    EventStopPropagationDirective.prototype.ngOnDestroy = function () {
        this.cleanTrackedEvents();
    };
    EventStopPropagationDirective.prototype.trackEvents = function (events) {
        var nativeElement = this.elementRef.nativeElement;
        events.forEach(function (event) { return nativeElement.addEventListener(event, stopPropagation); });
        this.trackedEvents = events;
    };
    EventStopPropagationDirective.prototype.cleanTrackedEvents = function () {
        var nativeElement = this.elementRef.nativeElement;
        this.trackedEvents.forEach(function (event) { return nativeElement.removeEventListener(event, stopPropagation); });
    };
    EventStopPropagationDirective.ɵfac = function EventStopPropagationDirective_Factory(t) { return new (t || EventStopPropagationDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    EventStopPropagationDirective.ɵdir = i0.ɵɵdefineDirective({ type: EventStopPropagationDirective, selectors: [["", "ghEventStopPropagation", ""]], inputs: { events: ["ghEventStopPropagation", "events"] } });
    return EventStopPropagationDirective;
}());
export { EventStopPropagationDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EventStopPropagationDirective, [{
        type: Directive,
        args: [{
                selector: '[ghEventStopPropagation]',
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { events: [{
            type: Input,
            args: ['ghEventStopPropagation']
        }] }); })();
