/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DictionaryRestService } from './dictionaries';
import { GridRestService } from './grids';

export const DATA_SERVICES = [
  DictionaryRestService,
  GridRestService,
];
