/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { endsWith, includes, isNil, startsWith } from 'lodash';

const BYTE_SIZE_RE = /^\s*(\d+)\s*((mb|kb|gb|b)\s*)?$/i;
const BYTE_SIZE_DIMENSIONS = {
  GB: Math.pow(1024, 3),
  MB: Math.pow(1024, 2),
  KB: 1024,
  B: 1,
};

export function parseByteSize(size: string): number {
  const match = BYTE_SIZE_RE.exec(size);

  if (isNil(match)) {
    return NaN;
  }

  const n = Number(match[1]);
  const dimension = match[3] || 'B';

  return n * BYTE_SIZE_DIMENSIONS[dimension.toUpperCase()];
}

export function acceptor(accept: string): PredicateFn<File> {
  const acceptors = accept.split(',').filter(Boolean).map((condition) => {
    if (includes(condition, '/')) {
      const mimeTypeRE = mimeTypeRegExp(condition);
      return (file: File) => mimeTypeRE.test(file.type);
    } else if (startsWith(condition, '.')) {
      return (file: File) => endsWith(file.name, condition);
    } else {
      throw new Error(`Unknown format of accept condition: ${condition}`);
    }
  });

  return (file) => acceptors.some((nestedAcceptor) => nestedAcceptor(file));
}

export function getFileExtension(fileName: string): string {
  const dotIndex = fileName.lastIndexOf('.');
  return dotIndex > 0 ? fileName.substring(dotIndex + 1) : '';
}

function mimeTypeRegExp(pattern: string): RegExp {
  return new RegExp(`^${pattern.replace('*', '[^/]+')}$`, 'i');
}
