/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-submodule-imports

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  CommonExternalSharedModule,
  ExtConfirmationDialogComponent,
  ExtMessageDialogComponent,
  MaterialExternalModule,
} from '@gh/common-external-shared';
import { CommonSharedModule } from '@gh/common-shared';
import { CommonSharedDataModule } from '@gh/common-shared-data';
import {
  AUTH_MODE, KEYCLOAK_AUTH_MODE, KEYCLOAK_WITH_MOCK_AUTH_MODE, MOCK_AUTH_MODE, MOCK_BACKEND_DEV_MODULE,
} from '@gh/config';
import { CoreDataModule, CoreMockDataModule } from '@gh/core-data';
import { CoreExternalCommonModule } from '@gh/core-external';

import { CoreMessagesModule } from '@gh/core-messages';
import { CoreTypeModule } from '@gh/core-type';
import { CoreUiCommonModule, CoreUiDialogsModule, CoreUiWaitingModule } from '@gh/core-ui';
import { CustomerSharedModule } from '@gh/customer-shared';
import { CustomerSharedDataModule } from '@gh/customer-shared-data';

import {
  CustomerAppComponent,
  CustomerEDocumentsListComponent,
  CustomerFinancialRecordPaymentListComponent,
  CustomerFinancialRecordsListComponent,
  CustomerGalleryComponent,
  CustomerJobInfoComponent,
  CustomerJobInfoDialogComponent,
  CustomerSharedDocumentListComponent,
} from './components';
import { CustomerAuthConfigModule, CustomerHttpClientConfigModule, CustomerMlsConfigModule } from './config';
import { CustomerBootstrapService } from './config/customer-bootstrap.service';
import { CustomerAppRoutingModule } from './customer-app-routing.module';
import { CUSTOMER_MOCK_DATA } from './mock/customer-mock-data';
import {
  CustomerEDocumentBusinessService,
  CustomerFinancialRecordBusinessService,
  CustomerGalleryBusinessService,
  CustomerSharedDocumentBusinessService,
} from './resources';
import { CustomerJobActivator } from './services';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    MaterialExternalModule.forRoot(),

    // core modules
    CoreMessagesModule,
    CoreDataModule.forRoot(),
    CoreTypeModule.forRoot(),
    CoreUiCommonModule.forRoot(),
    CoreUiDialogsModule.forRoot({
      standardSet: {
        messageDialogType: ExtMessageDialogComponent,
        confirmationDialogType: ExtConfirmationDialogComponent,
      },
    }),
    CoreUiWaitingModule.forRoot(),
    CoreExternalCommonModule.forRoot(),

    // common modules
    CommonSharedDataModule.forRoot(),
    CommonSharedModule.forRoot(),
    CommonExternalSharedModule.forRoot(),

    // configuration modules
    (AUTH_MODE === KEYCLOAK_AUTH_MODE ? CustomerAuthConfigModule.useKeycloak() :
      AUTH_MODE === KEYCLOAK_WITH_MOCK_AUTH_MODE ? CustomerAuthConfigModule.useKeycloakWithMock() :
        AUTH_MODE === MOCK_AUTH_MODE ? CustomerAuthConfigModule.useMock() : CustomerAuthConfigModule.none()),

    CustomerHttpClientConfigModule,
    CustomerMlsConfigModule,

    // customer modules
    CustomerSharedDataModule.forRoot(),
    CustomerSharedModule.forRoot(),
    CustomerAppRoutingModule,

    MOCK_BACKEND_DEV_MODULE ? CoreMockDataModule.withMockData(CUSTOMER_MOCK_DATA) : [],
  ],
  providers: [
    CustomerBootstrapService, {
      provide: APP_INITIALIZER,
      useFactory: (bootstap: CustomerBootstrapService) => () => bootstap.load(),
      deps: [CustomerBootstrapService],
      multi: true,
    },
    CustomerJobActivator,
    CustomerEDocumentBusinessService,
    CustomerSharedDocumentBusinessService,
    CustomerFinancialRecordBusinessService,
    CustomerGalleryBusinessService,
  ],
  declarations: [
    CustomerAppComponent,
    CustomerJobInfoDialogComponent,
    CustomerJobInfoComponent,
    CustomerEDocumentsListComponent,
    CustomerSharedDocumentListComponent,
    CustomerFinancialRecordsListComponent,
    CustomerFinancialRecordPaymentListComponent,
    CustomerGalleryComponent,
  ],
  entryComponents: [
    CustomerJobInfoDialogComponent,
  ],
  bootstrap: [CustomerAppComponent],
})
export class CustomerAppModule {
}
