/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';

import { ID } from '@gh/common-shared-data';
import { AbstractRestService, identity, RestSettings } from '@gh/core-data';
import { CustomerPhotoListItemDto } from '@gh/customer-shared-data';

import { Observable } from 'rxjs';

@Injectable()
export class CustomerPhotoRestService extends AbstractRestService {
  constructor(private settings: RestSettings) {
    super(settings);
  }

  findAll(jobId: ID): Observable<CustomerPhotoListItemDto[]> {
    return this.httpGet(`/jobs/${jobId}/gallery`, identity());
  }
}
