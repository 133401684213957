/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import * as i0 from "@angular/core";
//  tslint:disable-next-line:max-line-length
var URL_RE = /(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|org|net|gov|mil|ru|by|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel))|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |\/.,*:;=]|%[a-f\d]{2})*)?/ig;
var TextWithUrlsPipe = /** @class */ (function () {
    function TextWithUrlsPipe() {
    }
    TextWithUrlsPipe.prototype.transform = function (value) {
        return value && value.replace(URL_RE, function (match) { return "<a target=\"_blank\" href=\"" + match + "\">" + match + "</a>"; });
    };
    TextWithUrlsPipe.ɵfac = function TextWithUrlsPipe_Factory(t) { return new (t || TextWithUrlsPipe)(); };
    TextWithUrlsPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "textWithUrls", type: TextWithUrlsPipe, pure: true });
    return TextWithUrlsPipe;
}());
export { TextWithUrlsPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TextWithUrlsPipe, [{
        type: Pipe,
        args: [{ name: 'textWithUrls' }]
    }], null, null); })();
