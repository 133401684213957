/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { EventEmitter, Injectable } from '@angular/core';
import * as i0 from "@angular/core";
/**
 * This service allows to throw messages to any interesting observer.
 */
var MessagePushService = /** @class */ (function () {
    function MessagePushService() {
        this.messages = new EventEmitter();
    }
    Object.defineProperty(MessagePushService.prototype, "onMessage", {
        get: function () {
            return this.messages.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    MessagePushService.prototype.pushMessage = function (message) {
        this.messages.emit(message);
    };
    MessagePushService.ɵfac = function MessagePushService_Factory(t) { return new (t || MessagePushService)(); };
    MessagePushService.ɵprov = i0.ɵɵdefineInjectable({ token: MessagePushService, factory: MessagePushService.ɵfac });
    return MessagePushService;
}());
export { MessagePushService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MessagePushService, [{
        type: Injectable
    }], null, null); })();
