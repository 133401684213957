/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { findPermission, Permission } from '@gh/core-auth';
import * as invariant from 'invariant';
import { isString } from 'lodash';
import { UserContext } from '../user';
import * as i0 from "@angular/core";
import * as i1 from "../user";
var AllowForDirective = /** @class */ (function () {
    function AllowForDirective(viewContainerRef, templateRef, userContext) {
        this.viewContainerRef = viewContainerRef;
        this.templateRef = templateRef;
        this.userContext = userContext;
        this.hasView = false;
    }
    Object.defineProperty(AllowForDirective.prototype, "ghAllowFor", {
        set: function (permission) {
            if (isString(permission)) {
                permission = findPermission(permission);
            }
            invariant(permission instanceof Permission, "Permission '" + permission + "' is not an instance of Permission");
            if (this.userContext.hasPermission(permission) && !this.hasView) {
                this.hasView = true;
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
            else if (!this.userContext.hasPermission(permission) && this.hasView) {
                this.hasView = false;
                this.viewContainerRef.clear();
            }
        },
        enumerable: true,
        configurable: true
    });
    AllowForDirective.ɵfac = function AllowForDirective_Factory(t) { return new (t || AllowForDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i1.UserContext)); };
    AllowForDirective.ɵdir = i0.ɵɵdefineDirective({ type: AllowForDirective, selectors: [["", "ghAllowFor", ""]], inputs: { ghAllowFor: "ghAllowFor" } });
    return AllowForDirective;
}());
export { AllowForDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AllowForDirective, [{
        type: Directive,
        args: [{
                selector: '[ghAllowFor]',
            }]
    }], function () { return [{ type: i0.ViewContainerRef }, { type: i0.TemplateRef }, { type: i1.UserContext }]; }, { ghAllowFor: [{
            type: Input
        }] }); })();
