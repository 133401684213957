var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { AbstractRestService, identity, RestSettings } from '@gh/core-data';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-data";
var CustomerPhotoRestService = /** @class */ (function (_super) {
    __extends(CustomerPhotoRestService, _super);
    function CustomerPhotoRestService(settings) {
        var _this = _super.call(this, settings) || this;
        _this.settings = settings;
        return _this;
    }
    CustomerPhotoRestService.prototype.findAll = function (jobId) {
        return this.httpGet("/jobs/" + jobId + "/gallery", identity());
    };
    CustomerPhotoRestService.ɵfac = function CustomerPhotoRestService_Factory(t) { return new (t || CustomerPhotoRestService)(i0.ɵɵinject(i1.RestSettings)); };
    CustomerPhotoRestService.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerPhotoRestService, factory: CustomerPhotoRestService.ɵfac });
    return CustomerPhotoRestService;
}(AbstractRestService));
export { CustomerPhotoRestService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerPhotoRestService, [{
        type: Injectable
    }], function () { return [{ type: i1.RestSettings }]; }, null); })();
