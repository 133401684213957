/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'gh-ext-no-data',
  templateUrl: 'ext-no-data.component.html',
  styleUrls: ['ext-no-data.component.scss'],
})
export class ExtNoDataComponent {
  @Input() initial: string;
  @Input() dataName: string;
  @Input() message: string;
  @Input() forceIcon: boolean = false;
}
