/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { noop } from 'lodash';

@Directive({
  selector: 'input[ghUppercase]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UppercaseDirective, multi: true }],
})
export class UppercaseDirective implements ControlValueAccessor {
  private _onChange = noop;
  private _onTouched = noop;

  private lastValue: any;

  constructor(private elementRef: ElementRef) {

  }

  writeValue(value: any): void {
    this.updateInputValue(value);
    this.lastValue = value;
  }

  registerOnChange(onChange: any): void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this._onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.elementRef.nativeElement.disabled = isDisabled;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const uppercased = this.updateInputValue(value);
    if (this.lastValue !== uppercased) {
      this.lastValue = uppercased;
      this._onChange(uppercased);
    }
  }

  @HostListener('blur')
  onBlur(): void {
    this._onTouched();
  }

  private updateInputValue(value: any): void {
    const uppercased = value && value.toUpperCase() || '';
    this.elementRef.nativeElement.value = uppercased;
    return uppercased;
  }
}
