/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Observable, Subscriber, Subscription } from 'rxjs';
import { WaitingProcess } from './waiting-process';

export function waitingOperator<T>(processFactory: () => WaitingProcess): (ob: Observable<T>) => Observable<T> {
  return (source: Observable<T>) =>
    new Observable((subscriber: Subscriber<T>) => {
      const process = processFactory();

      const subscription = source.subscribe(
        (value: T) => {
          try {
            subscriber.next(value);
          } catch (err) {
            subscriber.error(err);
          } finally {
            process.stop();
          }
        },
        (err: any) => {
          process.stop();
          subscriber.error(err);
        },
        () => {
          process.stop();
          subscriber.complete();
        });

      return new Subscription(() => {
        process.stop();
        subscription.unsubscribe();
      });
    });
}
