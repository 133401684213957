/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, OnDestroy } from '@angular/core';
import { concatMap$, filter$, share$ } from '@gh/rx/operators';
import { isNil, omit, uniqueId } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { ID } from '../../common-shared-data/common';
import { FileInfo, isUploadFinalEvent, UploadEngine, UploadEvent, UploadItem, UploadStatus } from './upload.interfaces';

export interface UploadProcess {
  event$: Observable<UploadEvent>;

  cancel(): void;
}

/**
 * Abstract implementation of {@link UploadEngine} provides basic managing of upload processes.
 * Concrete implementation should provide implementation of {@link UploadProcess} interface.
 *
 * This implementation constructs an upload queue.
 */
@Injectable()
export abstract class AbstractUploadEngine extends UploadEngine implements OnDestroy {
  private process$$ = new Subject<UploadProcess>();
  private processIndex: Hash<UploadProcess> = {};
  private eventSubscription: Subscription;

  // tslint:disable-next-line:member-ordering
  event$ = this.process$$.pipe(concatMap$((process) => process.event$), share$());

  constructor() {
    super();

    this.event$
      .pipe(filter$(isUploadFinalEvent))
      .subscribe((event) => {
        const { uploadId } = event.item;
        if (uploadId) {
          this.removeUploadProcess(uploadId);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  create(file: FileInfo): UploadItem {
    return {
      name: file.name,
      mimeType: file.mimeType || file.blob.type,
      status: UploadStatus.NotStarted,
      uploadId: uniqueId('upload-item:'),
      blob: file.blob,
    };
  }

  start(item: UploadItem, params: Hash<any>): void {
    if (isNil(item.uploadId)) {
      throw new Error('Upload item does not have id assigned');
    }
    const process = this.createUploadProcess(item, params);
    this.processIndex[item.uploadId] = process;
    this.process$$.next(process);
  }

  cancel(item: UploadItem): void {
    const { uploadId } = item;
    if (isNil(uploadId)) {
      throw new Error('Upload item does not have id assigned');
    }

    const process = this.processIndex[uploadId];
    process.cancel();

    this.removeUploadProcess(uploadId);
  }

  protected abstract createUploadProcess(item: UploadItem, params: Hash<any>): UploadProcess;

  private removeUploadProcess(uploadId: ID): void {
    this.processIndex = <any>omit(this.processIndex, uploadId);
  }
}
