/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { wrapInObservable } from '@gh/core-util';
import { first$, take$ } from '@gh/rx/operators';
import { cond, constant, fromPairs, stubTrue } from 'lodash';
import { compoundLogicalAction, constantAction, exitAction, goToStep, logicalAction } from './actions';
export var trueAction = eitherAction(stubTrue);
/**
 * Creates LogicalAction which runs provided predicate with current flow state.
 * If predicate returns true corresponding action is resolved successfully, otherwise it is failed.
 */
export function eitherAction(predicate) {
    return logicalAction('success', 'fail', function (dispatch, flowState) { return dispatch(predicate(flowState) ? 'success' : 'fail', flowState); });
}
/**
 * Creates ConstantAction which transforms flow state using provided function
 */
export function mapAction(transform) {
    return constantAction('next', function (dispatcher, state) { return dispatcher('next', transform(state)); });
}
/**
 * Creates ConstantAction which runs provided function.
 * Primary goal of this action is to declare explicit side effects.
 */
export function sideEffectAction(procedure) {
    return constantAction('next', function (dispatcher, state) {
        procedure(state);
        dispatcher('next', state);
    });
}
/**
 * Creates LogicalAction which creates Observable using provided function.
 * Action is resolved as soon as first event returned from the observable.
 * Returned event (data) becomes new flow state.
 * If observable is resolved to error state, error is merged into flow state under <code>error</code> name.
 * If observable is resolved without value, error action flow is triggered and flow state is not changed.
 */
export function streamValueAction(wait) {
    return logicalAction('success', 'fail', function (dispatcher, state) {
        var fulfilled = false;
        var fulfill = function (step, nextState) {
            if (!fulfilled) {
                fulfilled = true;
                dispatcher(step, nextState);
            }
        };
        wrapInObservable(wait(state)).pipe(take$(1))
            .subscribe(function (nextState) { return fulfill('success', nextState); }, function (error) { return fulfill('fail', __assign(__assign({}, state), { error: error })); }, function () { return fulfill('fail', state); });
    });
}
/**
 * Creates LogicalAction which creates Observable using provided function.
 * Action is resolved as soon as first event returned from the observable.
 * Returned event (data) becomes new flow state.
 * If observable is resolved to error state, error is merged into flow state under <code>error</code> name.
 * If observable is resolved without value, success action flow is triggered and flow state is not changed.
 */
export function streamOptionalValueAction(wait) {
    return logicalAction('success', 'fail', function (dispatcher, state) {
        var fulfilled = false;
        var fulfill = function (step, nextState) {
            if (!fulfilled) {
                fulfilled = true;
                dispatcher(step, nextState);
            }
        };
        wrapInObservable(wait(state)).pipe(take$(1))
            .subscribe(function (nextState) { return fulfill('success', nextState); }, function (error) { return fulfill('fail', __assign(__assign({}, state), { error: error })); }, function () { return fulfill('success', state); });
    });
}
/**
 * Creates LogicalAction which creates Observable using provided function.
 * Observable should return boolean value.
 * Action is resolved as soon as first value returned from the observable.
 * If value is true corresponding action is resolved successfully, otherwise it is failed.
 */
export function booleanStreamAction(predicate) {
    return logicalAction('success', 'fail', function (dispatcher, state) {
        return predicate(state).pipe(first$())
            .subscribe(function (bool) { return dispatcher(bool ? 'success' : 'fail', state); });
    });
}
/**
 * Creates LogicalAction which resolves successfully when all provided actions are resolved successfully.
 * Otherwise, action is failed.
 */
export function andActions() {
    var actions = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        actions[_i] = arguments[_i];
    }
    var lastIndex = actions.length - 1;
    return compoundLogicalAction('success', 'fail', __assign(__assign({ 'start': goToStep('condition.0') }, fromPairs(actions.map(function (action, i) { return [
        "condition." + i,
        action.bindAs(i === lastIndex ? 'condition.success' : "condition." + (i + 1), 'condition.fail'),
    ]; }))), { 'condition.success': exitAction('success'), '*': exitAction('fail') }));
}
/**
 * Creates LogicalAction which resolves successfully when one of provided actions is resolved successfully.
 * Otherwise, action is failed.
 */
export function orActions() {
    var actions = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        actions[_i] = arguments[_i];
    }
    var lastIndex = actions.length - 1;
    return compoundLogicalAction('success', 'fail', __assign(__assign({ 'start': goToStep('condition.0') }, fromPairs(actions.map(function (action, i) { return [
        "condition." + i,
        action.bindAs('condition.success', i === lastIndex ? 'condition.fail' : "condition." + (i + 1)),
    ]; }))), { 'condition.success': exitAction('success'), '*': exitAction('fail') }));
}
/**
 * Creates Action which starts one of given steps if corresponding predicate returns true.
 */
export function condAction(stepPairs) {
    return function (dispatcher, state) { return cond(__spreadArrays(stepPairs.map(function (_a) {
        var predicate = _a[0], step = _a[1];
        return [predicate, function (fs) { return dispatcher(step, fs); }];
    }), [
        [constant(true), function () {
                throw new Error('Target condition was not found');
            }],
    ]))(state); };
}
