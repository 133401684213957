/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { APP_NAME } from '@gh/config';
import { findLast, isNil, last, once, pull, uniqueId } from 'lodash';
import * as i0 from "@angular/core";
var TitleManager = /** @class */ (function () {
    function TitleManager() {
        this.contexts = [];
    }
    TitleManager.prototype.newContext = function () {
        var _this = this;
        var context = {
            id: uniqueId('context:'),
            elements: [],
        };
        this.contexts.push(context);
        this.updateTitle();
        return {
            destroy: once(function () { return _this.removeContext(context); }),
        };
    };
    TitleManager.prototype.newElement = function (text) {
        var _this = this;
        var context = last(this.contexts);
        if (isNil(context)) {
            throw new Error('Title context is not created');
        }
        var element = {
            id: uniqueId('element:'),
            text: text,
        };
        context.elements.push(element);
        this.updateTitle();
        return {
            setText: function (newText) {
                element.text = newText;
                _this.updateTitle();
            },
            destroy: once(function () { return _this.removeElement(context, element); }),
        };
    };
    TitleManager.prototype.removeContext = function (context) {
        pull(this.contexts, context);
        this.updateTitle();
    };
    TitleManager.prototype.removeElement = function (context, element) {
        pull(context.elements, element);
        this.updateTitle();
    };
    TitleManager.prototype.updateTitle = function () {
        var context = findLast(this.contexts, function (_a) {
            var elements = _a.elements;
            return elements.length > 0;
        });
        document.title = context ? [APP_NAME].concat(context.elements.map(function (_a) {
            var text = _a.text;
            return text;
        })).join(' - ') : APP_NAME;
    };
    TitleManager.ɵfac = function TitleManager_Factory(t) { return new (t || TitleManager)(); };
    TitleManager.ɵprov = i0.ɵɵdefineInjectable({ token: TitleManager, factory: TitleManager.ɵfac });
    return TitleManager;
}());
export { TitleManager };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TitleManager, [{
        type: Injectable
    }], null, null); })();
