/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpDataMapper } from './http-data-mapper';
var DummyHttpDataMapper = /** @class */ (function (_super) {
    __extends(DummyHttpDataMapper, _super);
    function DummyHttpDataMapper(mapper) {
        return _super.call(this, mapper) || this;
    }
    DummyHttpDataMapper.prototype.serializeHeaders = function (headers, obj, json) {
        return json;
    };
    DummyHttpDataMapper.prototype.deserializeHeaders = function (headers, obj) {
        return obj;
    };
    return DummyHttpDataMapper;
}(HttpDataMapper));
export { DummyHttpDataMapper };
