/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { ApplicationComponentState } from './application-component-state.service';
import * as i0 from "@angular/core";
import * as i1 from "./application-component-state.service";
var ApplicationDialogHooks = /** @class */ (function () {
    function ApplicationDialogHooks(componentState) {
        this.componentState = componentState;
    }
    ApplicationDialogHooks.prototype.open = function (dialog) {
        this.componentState.activate(dialog.componentInstance);
    };
    ApplicationDialogHooks.prototype.close = function (dialog) {
        this.componentState.deactivate(dialog.componentInstance);
    };
    ApplicationDialogHooks.ɵfac = function ApplicationDialogHooks_Factory(t) { return new (t || ApplicationDialogHooks)(i0.ɵɵinject(i1.ApplicationComponentState)); };
    ApplicationDialogHooks.ɵprov = i0.ɵɵdefineInjectable({ token: ApplicationDialogHooks, factory: ApplicationDialogHooks.ɵfac });
    return ApplicationDialogHooks;
}());
export { ApplicationDialogHooks };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ApplicationDialogHooks, [{
        type: Injectable
    }], function () { return [{ type: i1.ApplicationComponentState }]; }, null); })();
