/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import { isBoolean, isNil } from 'lodash';
import * as i0 from "@angular/core";
var IsControlVisiblePipe = /** @class */ (function () {
    function IsControlVisiblePipe() {
    }
    IsControlVisiblePipe.prototype.transform = function (control) {
        if (isNil(control)) {
            return false;
        }
        var visible = control['visible'];
        if (isBoolean(visible)) {
            return visible;
        }
        else {
            throw new Error('Control does not have visible flag. Looks like you do not use form schema to build form');
        }
    };
    IsControlVisiblePipe.ɵfac = function IsControlVisiblePipe_Factory(t) { return new (t || IsControlVisiblePipe)(); };
    IsControlVisiblePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "isControlVisible", type: IsControlVisiblePipe, pure: false });
    return IsControlVisiblePipe;
}());
export { IsControlVisiblePipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IsControlVisiblePipe, [{
        type: Pipe,
        args: [{ name: 'isControlVisible', pure: false }]
    }], null, null); })();
