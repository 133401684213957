/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { pull } from 'lodash';
import * as i0 from "@angular/core";
export var FONT_AFFECTING_PROPERTIES = ['fontSize', 'fontFamily', 'fontWeight', 'fontStyle'];
var TextRuler = /** @class */ (function () {
    function TextRuler(rootEl, protoEl) {
        this.rootEl = rootEl;
        this.protoEl = protoEl;
        var rulerEl = document.createElement('div');
        rulerEl.classList.add('text-ruler');
        rootEl.appendChild(rulerEl);
        this.textRulerEl = rulerEl;
        this.recalculate();
    }
    TextRuler.prototype.recalculate = function () {
        var _a = this, protoEl = _a.protoEl, textRulerEl = _a.textRulerEl;
        var computedStyles = getComputedStyle(protoEl);
        FONT_AFFECTING_PROPERTIES.forEach(function (property) {
            textRulerEl.style[property] = computedStyles[property];
        });
    };
    TextRuler.prototype.getTextWidth = function (text) {
        var textRulerEl = this.textRulerEl;
        textRulerEl.textContent = text;
        var textWidth = textRulerEl.offsetWidth;
        textRulerEl.textContent = '';
        return textWidth;
    };
    TextRuler.prototype.dispose = function () {
        this.rootEl.removeChild(this.textRulerEl);
    };
    return TextRuler;
}());
export { TextRuler };
var ContentRulerFactory = /** @class */ (function () {
    function ContentRulerFactory() {
        this.rulers = [];
        this.initRootElement();
    }
    ContentRulerFactory.prototype.createTextRuler = function (element) {
        var ruler = new TextRuler(this.contentRulerRootEl, element);
        this.rulers.push(ruler);
        return ruler;
    };
    ContentRulerFactory.prototype.releaseRuler = function (ruler) {
        ruler.dispose();
        pull(this.rulers, ruler);
    };
    ContentRulerFactory.prototype.ngOnDestroy = function () {
        document.body.removeChild(this.contentRulerRootEl);
    };
    ContentRulerFactory.prototype.initRootElement = function () {
        var rootEl = document.createElement('div');
        rootEl.classList.add('content-ruler-root');
        document.body.appendChild(rootEl);
        this.contentRulerRootEl = rootEl;
    };
    ContentRulerFactory.ɵfac = function ContentRulerFactory_Factory(t) { return new (t || ContentRulerFactory)(); };
    ContentRulerFactory.ɵprov = i0.ɵɵdefineInjectable({ token: ContentRulerFactory, factory: ContentRulerFactory.ɵfac });
    return ContentRulerFactory;
}());
export { ContentRulerFactory };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContentRulerFactory, [{
        type: Injectable
    }], function () { return []; }, null); })();
