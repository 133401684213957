<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<div fxShow="" fxHide.lt-md="" class="m-navigation-header-underlay ext-primary-bg"></div>
<div class="m-root">
  <div *ngIf="isNavigationOpened" fxShow.lt-md="" class="m-navigation-overlay"></div>
  <div class="m-navigation" [class.is-navigation-opened]="isNavigationOpened">
    <div fxLayout="column" fxLayoutAlign="start" fxFlex="" class="m-navigation-root ext-primary-bg">
      <div fxHide="" fxShow.lt-md="" fxLayout="row" fxLayoutAlign="start center" class="m-navigation-header ext-mobile-content ext-primary-contrast-bg">
        <button type="button" mat-icon-button="" color="primary" #closeTooltip="matTooltip" matTooltip="{{'lbl_close' | translate}}" (click)="toggleNavigation()">
          <mat-icon svgIcon="gh:close"></mat-icon>
        </button>
        <div fxFlex="" fxLayout="row" fxLayoutAlign="center center">
          <img src="/ef05a4176a1a27c00c8e389eff1cc5c3.gif">
        </div>
      </div>
      <div class="m-navigation-content" fxFlex="">
        <ng-content select="[ghExtRootLayoutNavigation]"></ng-content>
      </div>
    </div>
    <div fxHide="" fxShow.sm="" fxFlex="" class="m-navigation-placeholder" (click)="closeNavigation()"></div>
  </div>
  <div class="m-top-menu ext-elevated-panel" ngClass.lt-md="ext-primary-bg">
    <div fxShow.lt-md="" fxHide="" fxLayoutAlign="space-between center" class="ext-mobile-content">
      <button type="button" mat-icon-button="" color="accent" (click)="toggleNavigation()" #menuTooltip="matTooltip" matTooltip="{{'lbl_menu' | translate}}">
        <mat-icon svgIcon="gh:menu"></mat-icon>
      </button>
      <div class="m-page-title ext-primary-contrast-color">{{ title }}</div>
      <div>
        <ng-content select="[ghExtRootLayoutMobileBar]"></ng-content>
      </div>
    </div>
    <ng-content select="[ghExtRootLayoutTopMenu]"></ng-content>
    <mat-progress-bar [hidden]="!backgroundWaiting || !isFirstRouteActivated" mode="indeterminate" class="m-preloader"></mat-progress-bar>
  </div>
  <div class="m-content">
    <ng-content select="[ghExtRootLayoutContent]"></ng-content>
  </div>
</div>