/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { ID } from '@gh/common-shared-data';
import { APP_NAME } from '@gh/config';
import { findLast, isNil, last, once, pull, uniqueId } from 'lodash';

export type TitleContextInfo = {
  id: ID;
  elements: TitleElementInfo[];
};

export type TitleElementInfo = {
  id: ID;
  text: string;
};

export interface TitleContext {
  destroy(): void;
}

export interface TitleElement {
  setText(text: string): void;

  destroy(): void;
}

@Injectable()
export class TitleManager {
  private contexts: TitleContextInfo[] = [];

  newContext(): TitleContext {
    const context = {
      id: uniqueId('context:'),
      elements: [],
    };

    this.contexts.push(context);

    this.updateTitle();

    return {
      destroy: once(() => this.removeContext(context)),
    };
  }

  newElement(text: string): TitleElement {
    const context = last(this.contexts);

    if (isNil(context)) {
      throw new Error('Title context is not created');
    }

    const element = {
      id: uniqueId('element:'),
      text,
    };
    context.elements.push(element);

    this.updateTitle();

    return {
      setText: (newText) => {
        element.text = newText;
        this.updateTitle();
      },
      destroy: once(() => this.removeElement(context, element)),
    };
  }

  private removeContext(context: TitleContextInfo): void {
    pull(this.contexts, context);

    this.updateTitle();
  }

  private removeElement(context: TitleContextInfo, element: TitleElementInfo): void {
    pull(context.elements, element);

    this.updateTitle();
  }

  private updateTitle(): void {
    const context = findLast(this.contexts, ({ elements }) => elements.length > 0);

    document.title = context ? [APP_NAME].concat(context.elements.map(({ text }) => text)).join(' - ') : APP_NAME;
  }
}
