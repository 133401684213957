/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { EventEmitter, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Message } from './message';

/**
 * This service allows to throw messages to any interesting observer.
 */
@Injectable()
export class MessagePushService {
  private messages = new EventEmitter<Message>();

  get onMessage(): Observable<Message> {
    return this.messages.asObservable();
  }

  pushMessage(message: Message): void {
    this.messages.emit(message);
  }
}
