/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-invalid-this

import { Directive, DoCheck, HostBinding, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatCalendarBody, MatDatepicker, MatDatepickerToggle } from '@angular/material/datepicker';
import { aspectAround } from '@gh/core-util';

@Directive({
  selector: '[matDatepicker]', // tslint:disable-line:directive-selector
})
export class DatepickerInputFixDirective<D> implements DoCheck {
  private datepicker: MatDatepicker<D>;
  private disabled?: boolean;

  constructor(private ngControl: NgControl) {

  }

  @Input()
  set matDatepicker(datepicker: MatDatepicker<D>) {
    this.datepicker = datepicker;
  }

  ngDoCheck(): void {
    const { control } = this.ngControl;

    if (control && this.disabled !== control.disabled) {
      this.datepicker['openDisabled'] = control.disabled;

      this.disabled = control.disabled;
    }
  }
}

@Directive({
  selector: 'mat-datepicker-toggle', // tslint:disable-line:directive-selector
})
export class DatepickerToggleFixDirective<D> implements DoCheck {
  @HostBinding('class.datepicker-toggle-disabled')
  disabled: boolean = true;

  constructor(private toggle: MatDatepickerToggle<D>) {

  }

  ngDoCheck(): void {
    this.disabled = this.toggle.datepicker['openDisabled'];
  }
}

// extend MatCalendarBody to fix handling of invalid dates
aspectAround(MatCalendarBody, '_focusActiveCell', (original) => function(): void {
  if (this.activeCell < 0) {
    return;
  }
  return original.apply(this, arguments);
});
