/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ComponentType } from '@angular/cdk/portal';
import { Type } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DialogInput, DialogRouteConfig, DialogRouteSnapshot } from '@gh/core-ui';
import { assign } from 'lodash';
import { Observable } from 'rxjs';
import { InitDialogViewService } from './init-service';
import { InitDialogViewResolver } from './init-view-resolver.service';

/**
 * Returns init data from the dialog ref
 */
export function getDialogInitData(dialogData: any): any {
  return dialogData['init'];
}

/**
 * Returns init data from the dialog ref
 */
export function getDialogInput<DialogConfig>(dialogData: any): DialogConfig {
  return dialogData['input'];
}

/**
 * Part of dialog route that defines component
 */
export interface ComponentDialogRoute<C> {
  component: ComponentType<C>;
}

/**
 * This interface defines configuration for dialog initialization service.
 * Developer should provide
 * either service implementing {@link InitDialogViewService} interface
 * or any service along with selector which retrieves data from the provided service
 */
export interface InitDialogViewRoute<V, I> {
  init?: Type<InitDialogViewService<I>> | {
    service: Type<V>;
    selector: string | ((service: V, route?: DialogRouteSnapshot<I>) =>
      { [name: string]: Observable<any> } | any);
    noWaiting?: boolean;
  };
}

/**
 * Defines part of dialog route responsible for storing of default dialog config.
 */
export interface ConfigDialogRoute {
  config?: MatDialogConfig;
}

/**
 * Defines lazy dialog route
 */
export interface LazyDialogRoute<I> {
  /**
   * Lazy loading is based on @gh/core-lazy package
   */
  lazy: {
    /**
     * Defines path to the module having @lazy-activator
     */
    module: string;
    /**
     * Defines name of the symbol available in the scope of lazy loaded module
     */
    name: string;
  };
}

/**
 * This method creates dialog routes and guarantees that route configuration is correct.
 *
 * @param route having authentication {@link AuthRouteConfig} and leave {@link LeaveRouteConfig} configuration
 */
// tslint:disable-next-line:max-line-length
export function createDialogRoute<C extends DialogInput<I>, I, V>(route: ComponentDialogRoute<C> & InitDialogViewRoute<V, I> & ConfigDialogRoute): DialogRouteConfig<C>;
export function createDialogRoute<I>(route: LazyDialogRoute<I>): DialogRouteConfig<DialogInput<I>>;
export function createDialogRoute<C extends DialogInput<I>, I, V>(route: any): DialogRouteConfig<C> {

  const { component, config, lazy } = route;

  const init = route.init ? {
    resolve: InitDialogViewResolver,
    data: {
      initView: route.init,
    },
  } : void 0;

  return assign({ component, config, lazy }, init);
}
