/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TemplateSiteComponent } from './components';
import {
  AutomationIDDirective,
  CaptureEventDirective,
  EllipsisDirective,
  EventStopPropagationDirective, FocusDirective,
  FocusOnInitDirective,
  InfiniteScrollDirective,
  LocalDirective,
  RendererDirective,
} from './directives';
import {
  DefaultCurrencyPipe,
  HighlightPipe,
  HtmlWithUrlsPipe,
  InContextPipe,
  KeysPipe,
  LogPipe,
  Pluck$Pipe,
  SanitizeHtmlPipe,
  SanitizeUrlPipe,
  TextWithUrlsPipe,
  ToArrayPipe,
} from './pipes';
import { Clipboard, ContentRulerFactory, DefaultUiErrorHandlerService, DndManager, UI_ERROR_HANDLER } from './services';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    InfiniteScrollDirective,
    AutomationIDDirective,
    EllipsisDirective,
    FocusDirective,
    FocusOnInitDirective,
    CaptureEventDirective,
    EventStopPropagationDirective,
    RendererDirective,
    LocalDirective,

    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    HighlightPipe,
    HtmlWithUrlsPipe,
    TextWithUrlsPipe,
    KeysPipe,
    ToArrayPipe,
    InContextPipe,
    Pluck$Pipe,
    DefaultCurrencyPipe,
    LogPipe,

    TemplateSiteComponent,
  ],
  entryComponents: [
    TemplateSiteComponent,
  ],
  exports: [
    InfiniteScrollDirective,
    AutomationIDDirective,
    EllipsisDirective,
    FocusDirective,
    FocusOnInitDirective,
    CaptureEventDirective,
    EventStopPropagationDirective,
    RendererDirective,
    LocalDirective,

    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    HighlightPipe,
    HtmlWithUrlsPipe,
    TextWithUrlsPipe,
    KeysPipe,
    ToArrayPipe,
    InContextPipe,
    Pluck$Pipe,
    DefaultCurrencyPipe,
    LogPipe,

    TemplateSiteComponent,
  ],
})
export class CoreUiCommonModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreUiCommonModule,
      providers: [
        ContentRulerFactory,
        DndManager,
        DefaultUiErrorHandlerService,
        Clipboard,
        { provide: UI_ERROR_HANDLER, useExisting: DefaultUiErrorHandlerService },
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return { ngModule: CoreUiCommonModule };
  }
}
