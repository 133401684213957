/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { MaskedTextType, TypeService } from '@gh/core-type';
import { isNotNil } from '@gh/core-util';
import { isString } from 'lodash';
import { createTextMaskInputElement } from 'text-mask-core';

@Directive({
  selector: '[ghAutocompleteMaskedType]',
})
export class AutocompleteMaskedTypeDirective implements OnInit, AfterViewInit {
  @Input('ghAutocompletedMaskedTypeConfig') config: any = {
    guide: false,
    placeholderChar: '_',
    pipe: void 0,
    keepCharPositions: false,
    onReject: void 0,
    onAccept: void 0,
  };

  private textMaskInputElement: any;
  private inputElement: HTMLInputElement;

  // stores the last value for comparison
  private lastValue: any;

  private _type: MaskedTextType;

  constructor(private renderer: Renderer2, private element: ElementRef, private typeService: TypeService) {
  }

  @Input('ghAutocompleteMaskedType')
  set type(type: MaskedTextType | string) {
    this._type = isString(type) ? this.typeService.get(type) : type;
  }

  ngOnInit(): void {
    this.setupMask();
    if (this._type['maxLength']) {
      this.renderer.setAttribute(this.element.nativeElement, 'maxlength', String(this._type['maxLength']));
    }
  }

  ngAfterViewInit(): void {
    if (!this.inputElement) {
      // the element was not found when ngOnInit ran, let's try to find it again
      this.setupMask();
    }
  }

  update(value: string): string {
    if (isNotNil(value) && isNotNil(this.textMaskInputElement)) {
      this.textMaskInputElement.update(value);

      const nextValue = this.element.nativeElement.value;

      // check against the last value to prevent firing ngModelChange despite no changes
      if (this.lastValue !== nextValue) {
        this.lastValue = nextValue;
      }

      return nextValue;
    } else {
      this.element.nativeElement.value = '';
      return '';
    }
  }

  private setupMask(): void {
    // tslint:disable-next-line:prefer-conditional-expression
    if (this.element.nativeElement.tagName === 'INPUT') {
      // `textMask` directive is used directly on an input element
      this.inputElement = this.element.nativeElement;
    } else {
      // `textMask` directive is used on an abstracted input element, `ion-input`, `mat-input`, etc
      this.inputElement = this.element.nativeElement.getElementsByTagName('INPUT')[0];
    }

    if (this.inputElement) {
      this.textMaskInputElement = createTextMaskInputElement({
        ...this.config,
        inputElement: this.inputElement,
        mask: this._type.mask,
      });
    }
  }
}
