/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import * as i0 from "@angular/core";
var MATERIAL_EXTERNAL_MODULES = [
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatRippleModule,
    MatSortModule,
    MatTableModule,
    MatCardModule,
    MatSlideToggleModule,
    CdkTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSnackBarModule,
];
var MaterialExternalModule = /** @class */ (function () {
    function MaterialExternalModule() {
    }
    MaterialExternalModule.forRoot = function () {
        return { ngModule: MaterialExternalModule };
    };
    MaterialExternalModule.forChild = function () {
        return { ngModule: MaterialExternalModule };
    };
    MaterialExternalModule.ɵmod = i0.ɵɵdefineNgModule({ type: MaterialExternalModule });
    MaterialExternalModule.ɵinj = i0.ɵɵdefineInjector({ factory: function MaterialExternalModule_Factory(t) { return new (t || MaterialExternalModule)(); }, imports: [MATERIAL_EXTERNAL_MODULES,
            MatIconModule,
            MatSelectModule,
            MatButtonModule,
            MatFormFieldModule,
            MatTooltipModule,
            MatRippleModule,
            MatSortModule,
            MatTableModule,
            MatCardModule,
            MatSlideToggleModule,
            CdkTableModule,
            MatDialogModule,
            MatProgressSpinnerModule,
            MatProgressBarModule,
            MatSnackBarModule] });
    return MaterialExternalModule;
}());
export { MaterialExternalModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(MaterialExternalModule, { imports: [MatIconModule,
        MatSelectModule,
        MatButtonModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatRippleModule,
        MatSortModule,
        MatTableModule,
        MatCardModule,
        MatSlideToggleModule,
        CdkTableModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSnackBarModule], exports: [MatIconModule,
        MatSelectModule,
        MatButtonModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatRippleModule,
        MatSortModule,
        MatTableModule,
        MatCardModule,
        MatSlideToggleModule,
        CdkTableModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSnackBarModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MaterialExternalModule, [{
        type: NgModule,
        args: [{
                imports: MATERIAL_EXTERNAL_MODULES,
                exports: MATERIAL_EXTERNAL_MODULES,
            }]
    }], null, null); })();
