var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:no-magic-numbers
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { LocalizationService } from '@gh/core-mls';
import { isElementFocused, isNotNil, newTime } from '@gh/core-util';
import { isEqual, noop, range } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
var _c0 = ["input"];
function TimepickerComponent_mat_option_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 6);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "typeFormat");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var time_r3 = ctx.$implicit;
    i0.ɵɵproperty("value", time_r3);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(2, 2, time_r3, "ShortTime"));
} }
function InlineTimepickerComponent_mat_option_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 5);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "typeFormat");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var time_r3 = ctx.$implicit;
    i0.ɵɵproperty("value", time_r3);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(2, 2, time_r3, "ShortTime"));
} }
var TIME_FORMAT_STYLE = 'shortTime';
var TIME_PARSE_PATTERN = 'time';
var TimepickerBaseComponent = /** @class */ (function () {
    function TimepickerBaseComponent(localizationService) {
        this.localizationService = localizationService;
        this.placeholder = '';
        this.required = false;
        this.step = 30;
        this.textValue = '';
        this.disabled = false;
        this._onTouched = noop;
        this._onChange = noop;
        this.stringifyTime = this.stringifyTime.bind(this);
    }
    Object.defineProperty(TimepickerBaseComponent.prototype, "focused", {
        get: function () {
            return isElementFocused(this.inputEl.nativeElement);
        },
        enumerable: true,
        configurable: true
    });
    TimepickerBaseComponent.prototype.ngOnInit = function () {
        var step = this.step;
        this.times = range(24 * 60 / step)
            .map(function (i) {
            var minutes = i * step;
            return newTime(Math.floor(minutes / 60), minutes % 60, 0, 0);
        });
        var filter = this.filter;
        if (filter) {
            this.times = this.times.filter(filter);
        }
    };
    TimepickerBaseComponent.prototype.focus = function () {
        this.inputEl.nativeElement.focus();
    };
    TimepickerBaseComponent.prototype.writeValue = function (time) {
        this.textValue = this.stringifyTime(time);
        this.value = isNotNil(time) ? time : void 0;
    };
    TimepickerBaseComponent.prototype.registerOnChange = function (fn) {
        this._onChange = fn;
    };
    TimepickerBaseComponent.prototype.registerOnTouched = function (fn) {
        this._onTouched = fn;
    };
    TimepickerBaseComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    TimepickerBaseComponent.prototype.onInput = function (strValue) {
        strValue = strValue.trim();
        // this.textMaskInputElement.update(str);
        var nextValue = strValue ?
            this.localizationService.getDateTimeParser().parse(TIME_PARSE_PATTERN, strValue) : void 0;
        this.textValue = strValue;
        this.setValue(nextValue);
    };
    TimepickerBaseComponent.prototype.onTouched = function () {
        this._onTouched();
    };
    TimepickerBaseComponent.prototype.onOptionSelected = function (time) {
        this.setValue(time);
        this.textValue = this.stringifyTime(time);
    };
    TimepickerBaseComponent.prototype.stringifyTime = function (time) {
        return time ? this.localizationService.getDateTimeFormat().format(TIME_FORMAT_STYLE, time) : '';
    };
    TimepickerBaseComponent.prototype.setValue = function (value) {
        if (!isEqual(this.value, value)) {
            this.value = value;
            if (value) {
                this._onChange(value);
            }
            else {
                this._onChange(null); // tslint:disable-line:no-null-keyword
            }
        }
    };
    TimepickerBaseComponent.ɵfac = function TimepickerBaseComponent_Factory(t) { return new (t || TimepickerBaseComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService)); };
    TimepickerBaseComponent.ɵdir = i0.ɵɵdefineDirective({ type: TimepickerBaseComponent, viewQuery: function TimepickerBaseComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(_c0, true);
            i0.ɵɵviewQuery(MatAutocomplete, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputEl = _t.first);
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.autocomplete = _t.first);
        } }, inputs: { placeholder: "placeholder", required: "required", step: "step", filter: "filter", panelClass: "panelClass" } });
    return TimepickerBaseComponent;
}());
export { TimepickerBaseComponent };
var TimepickerComponent = /** @class */ (function (_super) {
    __extends(TimepickerComponent, _super);
    function TimepickerComponent(localizationService) {
        return _super.call(this, localizationService) || this;
    }
    TimepickerComponent.ɵfac = function TimepickerComponent_Factory(t) { return new (t || TimepickerComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService)); };
    TimepickerComponent.ɵcmp = i0.ɵɵdefineComponent({ type: TimepickerComponent, selectors: [["gh-timepicker"]], exportAs: ["ghTimepicker"], features: [i0.ɵɵProvidersFeature([{ provide: NG_VALUE_ACCESSOR, useExisting: TimepickerComponent, multi: true }]), i0.ɵɵInheritDefinitionFeature], decls: 6, vars: 7, consts: [[2, "width", "100%"], ["matInput", "", "type", "text", 3, "required", "placeholder", "disabled", "value", "matAutocomplete", "input", "blur"], ["input", ""], [1, "grid-overlay-panel", 3, "displayWith", "optionSelected"], ["auto", "matAutocomplete"], [3, "value", 4, "ngFor", "ngForOf"], [3, "value"]], template: function TimepickerComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "mat-form-field", 0);
            i0.ɵɵelementStart(1, "input", 1, 2);
            i0.ɵɵlistener("input", function TimepickerComponent_Template_input_input_1_listener($event) { return ctx.onInput($event.target.value); })("blur", function TimepickerComponent_Template_input_blur_1_listener() { return ctx.onTouched(); });
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "mat-autocomplete", 3, 4);
            i0.ɵɵlistener("optionSelected", function TimepickerComponent_Template_mat_autocomplete_optionSelected_3_listener($event) { return ctx.onOptionSelected($event.option.value); });
            i0.ɵɵtemplate(5, TimepickerComponent_mat_option_5_Template, 3, 5, "mat-option", 5);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            var _r1 = i0.ɵɵreference(4);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("required", ctx.required)("placeholder", ctx.placeholder)("disabled", ctx.disabled)("value", ctx.textValue)("matAutocomplete", _r1);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("displayWith", ctx.stringifyTime);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngForOf", ctx.times);
        } }, encapsulation: 2 });
    return TimepickerComponent;
}(TimepickerBaseComponent));
export { TimepickerComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TimepickerComponent, [{
        type: Component,
        args: [{
                selector: 'gh-timepicker',
                templateUrl: 'timepicker.component.html',
                providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TimepickerComponent, multi: true }],
                exportAs: 'ghTimepicker',
            }]
    }], function () { return [{ type: i1.LocalizationService }]; }, null); })();
var InlineTimepickerComponent = /** @class */ (function (_super) {
    __extends(InlineTimepickerComponent, _super);
    function InlineTimepickerComponent(localizationService) {
        return _super.call(this, localizationService) || this;
    }
    InlineTimepickerComponent.ɵfac = function InlineTimepickerComponent_Factory(t) { return new (t || InlineTimepickerComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService)); };
    InlineTimepickerComponent.ɵcmp = i0.ɵɵdefineComponent({ type: InlineTimepickerComponent, selectors: [["gh-inline-timepicker"]], exportAs: ["ghInlineTimepicker"], features: [i0.ɵɵProvidersFeature([{ provide: NG_VALUE_ACCESSOR, useExisting: InlineTimepickerComponent, multi: true }]), i0.ɵɵInheritDefinitionFeature], decls: 5, vars: 7, consts: [["type", "text", 1, "full-width", 3, "required", "placeholder", "disabled", "value", "matAutocomplete", "input", "blur"], ["input", ""], [1, "grid-overlay-panel", 3, "displayWith", "optionSelected"], ["auto", "matAutocomplete"], [3, "value", 4, "ngFor", "ngForOf"], [3, "value"]], template: function InlineTimepickerComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "input", 0, 1);
            i0.ɵɵlistener("input", function InlineTimepickerComponent_Template_input_input_0_listener($event) { return ctx.onInput($event.target.value); })("blur", function InlineTimepickerComponent_Template_input_blur_0_listener() { return ctx.onTouched(); });
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(2, "mat-autocomplete", 2, 3);
            i0.ɵɵlistener("optionSelected", function InlineTimepickerComponent_Template_mat_autocomplete_optionSelected_2_listener($event) { return ctx.onOptionSelected($event.option.value); });
            i0.ɵɵtemplate(4, InlineTimepickerComponent_mat_option_4_Template, 3, 5, "mat-option", 4);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            var _r1 = i0.ɵɵreference(3);
            i0.ɵɵproperty("required", ctx.required)("placeholder", ctx.placeholder)("disabled", ctx.disabled)("value", ctx.textValue)("matAutocomplete", _r1);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("displayWith", ctx.stringifyTime);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngForOf", ctx.times);
        } }, encapsulation: 2 });
    return InlineTimepickerComponent;
}(TimepickerBaseComponent));
export { InlineTimepickerComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(InlineTimepickerComponent, [{
        type: Component,
        args: [{
                selector: 'gh-inline-timepicker',
                templateUrl: 'inline-timepicker.component.html',
                providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: InlineTimepickerComponent, multi: true }],
                exportAs: 'ghInlineTimepicker',
            }]
    }], function () { return [{ type: i1.LocalizationService }]; }, null); })();
