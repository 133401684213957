/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MessageBasket } from '@gh/core-messages';
import { DialogResolve, DialogRouteSnapshot, NotificationService, WaitingMode, WaitingService } from '@gh/core-ui';
import { expandObservable } from '@gh/core-util';
import { throwError$ } from '@gh/rx';
import { catchError$, delay$, map$ } from '@gh/rx/operators';
import { isFunction } from 'lodash';
import { Observable } from 'rxjs';
import { AppRouter } from './app-router.service';

function callInitView(service: any, selector: string | ((...args: string[]) => any), ...args: any[]): any {
  if (isFunction(selector)) {
    return selector(service, ...args);
  } else {
    return service[selector](...args);
  }
}

@Injectable()
export class InitViewResolver implements Resolve<any> {

  constructor(private injector: Injector,
              private waitingService: WaitingService,
              private notificationService: NotificationService,
              private router: AppRouter) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    const initView = route.data['initView'];

    if (initView) {
      const { service, selector } = initView.service
        ? initView
        : { service: initView, selector: void 0 };

      const serviceInstance = this.injector.get(service);
      const ob$ = expandObservable(callInitView(serviceInstance, selector || 'initView', route, state));
      const obWithWaiting$ = initView.noWaiting ?
        ob$
        : ob$.pipe((this.waitingService.operator(WaitingMode.Background)));
      return obWithWaiting$.pipe(
        catchError$((errors) => {
          if (errors instanceof MessageBasket && errors.has('msg_resource_not_found')) {
            this.router.navigateByUrl('/404');
            return [];
          } else {
            this.notificationService.processAll(errors);
            return throwError$(errors);
          }
        }),
        // angular 4.1.2 has a bug: router is crashed when resolver service resolves synchronously
        delay$(0));
    } else {
      return void 0;
    }
  }
}

@Injectable()
export class InitDialogViewResolver<I> implements DialogResolve<I> {

  constructor(private injector: Injector,
              private waitingService: WaitingService,
              private notificationService: NotificationService) {

  }

  resolve(route: DialogRouteSnapshot<I>): Observable<any> | any | void {
    const initView = route.routeConfig.data['initView'];

    if (initView) {
      const { service, selector } = initView.service
        ? initView
        : { service: initView, selector: void 0 };

      const serviceInstance = this.injector.get(service);
      const ob$ = expandObservable(callInitView(serviceInstance, selector || 'initDialogView', route));
      const obWithWaiting$ = initView.noWaiting ?
        ob$
        : ob$.pipe((this.waitingService.operator(WaitingMode.Background)));
      return obWithWaiting$.pipe(
        map$((data: any) => ({
          init: data,
        })),
        catchError$((errors) => {
          this.notificationService.processAll(errors);
          return throwError$(errors);
        }));
    } else {
      return void 0;
    }
  }
}
