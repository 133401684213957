/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable, InjectionToken } from '@angular/core';
import { NotificationService } from '../../notifications/notification.service';
import * as i0 from "@angular/core";
import * as i1 from "../../notifications/notification.service";
export var UI_ERROR_HANDLER = new InjectionToken('UiErrorHandler');
var UiErrorHandler = /** @class */ (function () {
    function UiErrorHandler() {
    }
    return UiErrorHandler;
}());
export { UiErrorHandler };
var DefaultUiErrorHandlerService = /** @class */ (function () {
    function DefaultUiErrorHandlerService(notificationService) {
        this.notificationService = notificationService;
    }
    DefaultUiErrorHandlerService.prototype.raise = function (error) {
        this.notificationService.processAll(error);
    };
    DefaultUiErrorHandlerService.ɵfac = function DefaultUiErrorHandlerService_Factory(t) { return new (t || DefaultUiErrorHandlerService)(i0.ɵɵinject(i1.NotificationService)); };
    DefaultUiErrorHandlerService.ɵprov = i0.ɵɵdefineInjectable({ token: DefaultUiErrorHandlerService, factory: DefaultUiErrorHandlerService.ɵfac });
    return DefaultUiErrorHandlerService;
}());
export { DefaultUiErrorHandlerService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DefaultUiErrorHandlerService, [{
        type: Injectable
    }], function () { return [{ type: i1.NotificationService }]; }, null); })();
