/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, HostBinding, NgZone } from '@angular/core';
import { first$ } from '@gh/rx/operators';
import { ContentRulerFactory } from '../../../common';
import * as i0 from "@angular/core";
import * as i1 from "../../../common";
var CollapsibleContainerDirective = /** @class */ (function () {
    function CollapsibleContainerDirective(ngZone, contentRulerFactory) {
        this.ngZone = ngZone;
        this.contentRulerFactory = contentRulerFactory;
        this.collapsed = false;
        this.initialized = true;
    }
    CollapsibleContainerDirective.prototype.ngAfterContentInit = function () {
        this.textRuler = this.contentRulerFactory.createTextRuler(this.input.element);
        // this.recalculate();
        this.initialized = true;
    };
    CollapsibleContainerDirective.prototype.ngOnDestroy = function () {
        if (this.textRuler) {
            this.contentRulerFactory.releaseRuler(this.textRuler);
        }
    };
    CollapsibleContainerDirective.prototype.checkSize = function (text) {
        this.collapsed = this.textRuler.getTextWidth(text) > this.visibleWidth;
    };
    CollapsibleContainerDirective.prototype.recalculate = function () {
        var _this = this;
        if (this.initialized) {
            this.collapsed = false;
            this.ngZone.onMicrotaskEmpty.pipe(first$()).subscribe(function () {
                _this.visibleWidth = _this.input.element.offsetWidth;
                _this.checkSize(_this.input.value);
            });
        }
    };
    CollapsibleContainerDirective.ɵfac = function CollapsibleContainerDirective_Factory(t) { return new (t || CollapsibleContainerDirective)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.ContentRulerFactory)); };
    CollapsibleContainerDirective.ɵdir = i0.ɵɵdefineDirective({ type: CollapsibleContainerDirective, selectors: [["", "ghCollapsibleContainer", ""]], hostVars: 2, hostBindings: function CollapsibleContainerDirective_HostBindings(rf, ctx) { if (rf & 2) {
            i0.ɵɵclassProp("container-collapsed", ctx.collapsed);
        } } });
    return CollapsibleContainerDirective;
}());
export { CollapsibleContainerDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CollapsibleContainerDirective, [{
        type: Directive,
        args: [{
                selector: '[ghCollapsibleContainer]',
            }]
    }], function () { return [{ type: i0.NgZone }, { type: i1.ContentRulerFactory }]; }, { collapsed: [{
            type: HostBinding,
            args: ['class.container-collapsed']
        }] }); })();
