/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { entity, enumeration } from '@gh/core-data';

import { CustomerJobDto, OfficeStatus } from './jobs.dto';

export const CustomerJobMapper = entity<CustomerJobDto>({
  officeStatus: enumeration(OfficeStatus),
});
