/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, Input, OnDestroy } from '@angular/core';
import { HelpID, HelpProvider } from './help-provider.service';

@Directive({
  selector: '[ghHelpId]',
})
export class HelpIdDirective implements OnDestroy {
  private _helpId?: HelpID;

  @Input('ghHelpId')
  set helpId(helpId: Maybe<HelpID>) {
    if (this._helpId) {
      this.helpProvider.resetCurrent();
    }
    this._helpId = helpId;
    if (helpId) {
      this.helpProvider.setCurrent(helpId);
    }
  }

  get helpId(): Maybe<HelpID> {
    return this._helpId;
  }

  constructor(private helpProvider: HelpProvider) {
  }

  ngOnDestroy(): void {
    const { helpId } = this;
    if (helpId) {
      this.helpProvider.resetCurrent();
    }
  }
}
