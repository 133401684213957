var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Pipe } from '@angular/core';
import * as i0 from "@angular/core";
var LogPipe = /** @class */ (function () {
    function LogPipe() {
    }
    LogPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (args.length) {
            console.log.apply(console, __spreadArrays(args, [value])); // tslint:disable-line:no-console
        }
        else {
            console.log(value); // tslint:disable-line:no-console
        }
        return value;
    };
    LogPipe.ɵfac = function LogPipe_Factory(t) { return new (t || LogPipe)(); };
    LogPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "log", type: LogPipe, pure: true });
    return LogPipe;
}());
export { LogPipe };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LogPipe, [{
        type: Pipe,
        args: [{
                name: 'log',
            }]
    }], null, null); })();
