/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';

import { DictionaryItemDto, DictionaryRestService } from '@gh/common-shared-data';
import { REGION_CODE } from '@gh/config';
import { Enum, EnumSet } from '@gh/core-data';
import { TranslationService } from '@gh/core-mls';
import { of$, throwError$ } from '@gh/rx';
import { catchError$, map$, tap$ } from '@gh/rx/operators';

import { isNil } from 'lodash';
import { Observable } from 'rxjs';

import { Dictionary, DictionaryItem, dictionaryItemFromDto, EnumDictionary, TextDictionary } from './dictionary';
import { DictionaryContext } from './dictionary-context.service';

export type DictionaryParams = Hash<any>;

export interface DictionaryDefinition<D extends Dictionary<any>> {
  get(dictionaryService: DictionaryService, params?: DictionaryParams): Observable<D>;

  filter(dictionary: D, params?: DictionaryParams): D;
}

export type DictionaryDefinitionPredicate<T> = (item: DictionaryItem<T>, i?: number, array?: DictionaryItem<T>,
                                                params?: DictionaryParams) => boolean;

@Injectable()
export class DictionaryService {
  private enumCache: Hash<EnumDictionary<any>> = {};

  constructor(private dictionaryDataService: DictionaryRestService,
              private dictionaryContext: DictionaryContext,
              private translationService: TranslationService) {

  }

  get(code: string): Observable<TextDictionary> {
    return <Observable<TextDictionary>>this.dictionaryContext.get(code).pipe(
      catchError$(() => this.getRawData(code).pipe(
        map$((items: DictionaryItem<any>[]) => new TextDictionary(items)),
        tap$((dictionary: Dictionary<any>) => this.dictionaryContext.put(code, dictionary)))),
      catchError$(() => this.dictionaryDataService
        .get(code).pipe(
          map$((items: DictionaryItemDto[]) => new TextDictionary(items.map(dictionaryItemFromDto))),
          tap$((dictionary: Dictionary<any>) => this.dictionaryContext.put(code, dictionary)))));
  }

  getByEnum<T extends Enum<S>, S>(enumeration: Enum<T>): Observable<EnumDictionary<EnumSet<T>>> {
    const name = Enum.name(enumeration);
    const dictionary = this.enumCache[name];

    if (!isNil(dictionary)) {
      return of$(dictionary);
    }

    return this.getRawData(name).pipe(
      map$((values) => new EnumDictionary(<Enum<any>>enumeration, values.map((item) => ({
        id: Enum.byValue(enumeration, item.id),
        name: item.name,
      })))),
      tap$((newDictionary) => this.enumCache[name] = newDictionary));
  }

  getByDef<D extends Dictionary<any>>(definition: DictionaryDefinition<D>, query?: DictionaryParams): Observable<D> {
    const dictionary$ = definition.get(this);

    return dictionary$.pipe(map$((dictionary) =>
      definition.filter ? definition.filter(dictionary, query) : dictionary));
  }

  private getRawData(name: string): Observable<DictionaryItem<string>[]> {
    const values: Maybe<string[]> = this.findRawData(name);

    if (isNil(values)) {
      return throwError$(`Translation for dictionary '${name}' are not defined`);
    }

    return of$(values.map((value) => ({
      id: value,
      name: this.translationService.get(`dictionary:${name}:${value}`),
    })));
  }

  /**
   * Tries to find raw data using regional and non-regional selector in order.
   * @param {string} name
   * @returns {Maybe<string[]>}
   */
  private findRawData(name: string): Maybe<string[]> {
    const regionalRawData = this.translationService.getRaw(`dictionary:${name}{${REGION_CODE}}`);

    if (regionalRawData) {
      return regionalRawData;
    }

    return this.translationService.getRaw(`dictionary:${name}`);
  }
}
