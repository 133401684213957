/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { MaskedTextType, MaskedTextTypeDefenition } from '../type.interfaces';
import { TextTypeImpl } from './text-type';

export class MaskedTextTypeImpl extends TextTypeImpl implements MaskedTextType {
  mask: any;

  constructor(defenition: MaskedTextTypeDefenition) {
    super(defenition);

    this.mask = defenition.mask;
  }
}
