import { coalesce, isNotNil } from '@gh/core-util';
import { isDetailedMessage, splitByLines } from '../dialog.utils';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
import * as i2 from "@angular/material/dialog";
/**
 * Set of possible answers of confirmation dialog
 */
export var Confirmation;
(function (Confirmation) {
    Confirmation[Confirmation["Cancel"] = 0] = "Cancel";
    Confirmation[Confirmation["No"] = 1] = "No";
    Confirmation[Confirmation["Yes"] = 2] = "Yes";
})(Confirmation || (Confirmation = {}));
var ConfirmationDialogBaseComponent = /** @class */ (function () {
    function ConfirmationDialogBaseComponent(translationService, dialogRef, elementRef, renderer) {
        this.translationService = translationService;
        this.dialogRef = dialogRef;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.detailed = false;
    }
    Object.defineProperty(ConfirmationDialogBaseComponent.prototype, "defaultNoButton", {
        get: function () {
            return this.defaultButton === Confirmation.No;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogBaseComponent.prototype, "defaultYesButton", {
        get: function () {
            return this.defaultButton === Confirmation.Yes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogBaseComponent.prototype, "defaultCancelButton", {
        get: function () {
            return this.defaultButton === Confirmation.Cancel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogBaseComponent.prototype, "closeOnBackdropClick", {
        get: function () {
            return coalesce(this.input.closeOnBackdropClick, true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogBaseComponent.prototype, "yesLabel", {
        get: function () {
            return this.input.yesLabel ? this.input.yesLabel : this.translationService.get('lbl_yes');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogBaseComponent.prototype, "noLabel", {
        get: function () {
            return this.input.noLabel ? this.input.noLabel : this.translationService.get('lbl_no');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationDialogBaseComponent.prototype, "cancelLabel", {
        get: function () {
            return this.input.cancelLabel ? this.input.cancelLabel : this.translationService.get('lbl_cancel');
        },
        enumerable: true,
        configurable: true
    });
    ConfirmationDialogBaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a = this.input, messageId = _a.messageId, messageParams = _a.messageParams, message = _a.message, buttons = _a.buttons, defaultButton = _a.defaultButton;
        if (messageId) {
            var rawMessage = this.translationService.getRaw(messageId);
            if (isDetailedMessage(rawMessage)) {
                this.title = splitByLines(this.translationService.get([messageId, 'title'].join('.'), messageParams));
                // tslint:disable:max-line-length
                this.description = splitByLines(this.translationService.get([messageId, 'description'].join('.'), messageParams));
                this.detailed = true;
            }
            else {
                this.message = splitByLines(this.translationService.get(messageId, messageParams));
            }
        }
        else if (message) {
            this.message = splitByLines(message);
        }
        this.noButton = buttons ? this.hasButton(Confirmation.No) : false;
        this.cancelButton = buttons ? this.hasButton(Confirmation.Cancel) : true;
        if (isNotNil(defaultButton)) {
            this.defaultButton = defaultButton;
        }
        this.renderer.addClass(this.elementRef.nativeElement, this.detailed
            ? 'confirmation-dialog--detailed' : 'confirmation-dialog--simple');
        if (this.closeOnBackdropClick) {
            this.dialogRef
                .backdropClick()
                .subscribe(function () {
                _this.dialogRef.close(_this.input.defaultButton);
            });
        }
    };
    ConfirmationDialogBaseComponent.prototype.onYes = function () {
        this.dialogRef.close(Confirmation.Yes);
    };
    ConfirmationDialogBaseComponent.prototype.onNo = function () {
        this.dialogRef.close(Confirmation.No);
    };
    ConfirmationDialogBaseComponent.prototype.onCancel = function () {
        this.dialogRef.close(Confirmation.Cancel);
    };
    ConfirmationDialogBaseComponent.prototype.onClose = function () {
        if (this.closeOnBackdropClick) {
            this.dialogRef.close(this.input.defaultButton);
        }
    };
    ConfirmationDialogBaseComponent.prototype.hasButton = function (button) {
        if (this.input.buttons) {
            return this.input.buttons.indexOf(button) >= 0;
        }
        else {
            return false;
        }
    };
    ConfirmationDialogBaseComponent.ɵfac = function ConfirmationDialogBaseComponent_Factory(t) { return new (t || ConfirmationDialogBaseComponent)(i0.ɵɵdirectiveInject(i1.TranslationService), i0.ɵɵdirectiveInject(i2.MatDialogRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2)); };
    ConfirmationDialogBaseComponent.ɵdir = i0.ɵɵdefineDirective({ type: ConfirmationDialogBaseComponent });
    return ConfirmationDialogBaseComponent;
}());
export { ConfirmationDialogBaseComponent };
