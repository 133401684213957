var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { concat$, EMPTY$, of$ } from '@gh/rx';
import { catchError$, first$, switchMap$, takeUntil$ } from '@gh/rx/operators';
import { identity } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AbstractUploadEngine } from './abstract-upload-engine';
import { s3UploadXhr } from './s3-upload-xhr';
import { UploadEventType, UploadStatus } from './upload.interfaces';
import * as i0 from "@angular/core";
var S3UploadAuthService = /** @class */ (function () {
    function S3UploadAuthService() {
    }
    return S3UploadAuthService;
}());
export { S3UploadAuthService };
var S3UploadProcess = /** @class */ (function () {
    function S3UploadProcess(uploadAuth, item, params) {
        this.cancel$$ = new BehaviorSubject(false);
        // emits an event about starting of uploading
        var start$ = of$({
            item: __assign(__assign({}, item), { status: UploadStatus.Uploading }),
            type: UploadEventType.Started,
        });
        // emits a value if we need to start uploading
        var cancelled$ = this.cancel$$.pipe(first$(identity));
        var uploading$ = of$(true).pipe(takeUntil$(cancelled$), switchMap$(function () { return uploadAuth.getConfig(item, params); }), switchMap$(function (config) { return s3UploadXhr(config, item, params); }), catchError$(function () { return of$({
            item: __assign(__assign({}, item), { status: UploadStatus.Error, progress: 0 }),
            type: UploadEventType.Error,
        }); }));
        // emits event only if uploading was cancelled
        var cancel$ = this.cancel$$.pipe(first$(), switchMap$(function (cancelled) { return !cancelled ? EMPTY$ : of$({
            item: __assign(__assign({}, item), { status: UploadStatus.NotStarted, progress: 0 }),
            type: UploadEventType.Cancelled,
        }); }));
        this.event$ = concat$(start$, uploading$, cancel$);
    }
    S3UploadProcess.prototype.cancel = function () {
        this.cancel$$.next(true);
    };
    return S3UploadProcess;
}());
export { S3UploadProcess };
var S3UploadEngine = /** @class */ (function (_super) {
    __extends(S3UploadEngine, _super);
    function S3UploadEngine(uploadAuth) {
        var _this = _super.call(this) || this;
        _this.uploadAuth = uploadAuth;
        return _this;
    }
    S3UploadEngine.prototype.createUploadProcess = function (item, params) {
        return new S3UploadProcess(this.uploadAuth, item, params);
    };
    S3UploadEngine.ɵfac = function S3UploadEngine_Factory(t) { return new (t || S3UploadEngine)(i0.ɵɵinject(S3UploadAuthService)); };
    S3UploadEngine.ɵprov = i0.ɵɵdefineInjectable({ token: S3UploadEngine, factory: S3UploadEngine.ɵfac });
    return S3UploadEngine;
}(AbstractUploadEngine));
export { S3UploadEngine };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(S3UploadEngine, [{
        type: Injectable
    }], function () { return [{ type: S3UploadAuthService }]; }, null); })();
