/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CollapsibleContainerDirective } from './collapsible-container.directive';
import * as i0 from "@angular/core";
import * as i1 from "./collapsible-container.directive";
import * as i2 from "@angular/forms";
var CollapsibleInputDirective = /** @class */ (function () {
    function CollapsibleInputDirective(elementRef, container, control) {
        this.elementRef = elementRef;
        this.container = container;
        this.control = control;
    }
    Object.defineProperty(CollapsibleInputDirective.prototype, "element", {
        get: function () {
            return this.elementRef.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    CollapsibleInputDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.container.input = this;
        var valueChanges = this.control.valueChanges;
        this.value = this.control.value;
        if (valueChanges) {
            this.valueSubscription = valueChanges.subscribe(function (value) {
                _this.value = value;
                _this.container.checkSize(value);
            });
        }
    };
    CollapsibleInputDirective.prototype.ngOnDestroy = function () {
        var valueSubscription = this.valueSubscription;
        if (valueSubscription) {
            valueSubscription.unsubscribe();
        }
    };
    CollapsibleInputDirective.ɵfac = function CollapsibleInputDirective_Factory(t) { return new (t || CollapsibleInputDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.CollapsibleContainerDirective), i0.ɵɵdirectiveInject(i2.NgControl)); };
    CollapsibleInputDirective.ɵdir = i0.ɵɵdefineDirective({ type: CollapsibleInputDirective, selectors: [["", "ghCollapsibleInput", ""]] });
    return CollapsibleInputDirective;
}());
export { CollapsibleInputDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CollapsibleInputDirective, [{
        type: Directive,
        args: [{
                selector: '[ghCollapsibleInput]',
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i1.CollapsibleContainerDirective }, { type: i2.NgControl }]; }, null); })();
