/// <reference path="./eversign.d.ts" />
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
/// <reference path="./eversign.d.ts" />
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { EXTERNAL_EVERSIGN_EMBEDDED_JS_LIBRARY_URL } from '@gh/config';
import { ResourceLoader } from '@gh/core-external';
import { UI_ERROR_HANDLER, UiErrorHandler } from '@gh/core-ui';
import { isEmpty, uniqueId } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-external";
import * as i2 from "@gh/core-ui";
import * as i3 from "../ext-preloader/ext-preloader.component";
var ExtEversignDocumentComponent = /** @class */ (function () {
    function ExtEversignDocumentComponent(scriptLoader, changeDetectorRef, errorHandler) {
        this.scriptLoader = scriptLoader;
        this.changeDetectorRef = changeDetectorRef;
        this.errorHandler = errorHandler;
        this.signed = new EventEmitter();
        this.declined = new EventEmitter();
        this.containerId = uniqueId('eversign-container:');
        this.loading = true;
        this.initialized = false;
    }
    Object.defineProperty(ExtEversignDocumentComponent.prototype, "url", {
        get: function () {
            return this._url;
        },
        set: function (url) {
            if (this.initialized) {
                throw new Error('Eversign: URL for signing document cannot be changed after component has been initialized');
            }
            this._url = url;
        },
        enumerable: true,
        configurable: true
    });
    ExtEversignDocumentComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (isEmpty(this.url)) {
            throw new Error('Eversign: URL for signing document is not defined');
        }
        this.scriptLoader.require(EXTERNAL_EVERSIGN_EMBEDDED_JS_LIBRARY_URL)
            .subscribe(function () { return eversign.open({
            url: _this.url,
            containerID: _this.containerId,
            width: '100%',
            height: '100%',
            events: {
                loaded: function () { return _this.finishLoading(); },
                error: function () { return _this.finishLoading(); },
                signed: function () { return _this.signed.emit(); },
                declined: function () { return _this.declined.emit(); },
            },
        }); }, function (error) { return _this.errorHandler.raise(error); });
        this.initialized = true;
    };
    ExtEversignDocumentComponent.prototype.finishLoading = function () {
        this.loading = false;
        this.changeDetectorRef.markForCheck();
    };
    ExtEversignDocumentComponent.ɵfac = function ExtEversignDocumentComponent_Factory(t) { return new (t || ExtEversignDocumentComponent)(i0.ɵɵdirectiveInject(i1.ResourceLoader), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(UI_ERROR_HANDLER)); };
    ExtEversignDocumentComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtEversignDocumentComponent, selectors: [["gh-ext-eversign-document"]], inputs: { url: "url" }, outputs: { signed: "signed", declined: "declined" }, decls: 2, vars: 2, consts: [[1, "m-viewport", 3, "id"], [1, "m-preloader", "stretch", 3, "loading"]], template: function ExtEversignDocumentComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelement(0, "div", 0);
            i0.ɵɵelement(1, "gh-ext-preloader", 1);
        } if (rf & 2) {
            i0.ɵɵproperty("id", ctx.containerId);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("loading", ctx.loading);
        } }, directives: [i3.ExtPreloaderComponent], styles: ["[_nghost-%COMP%] {\n  position: relative;\n  display: flex; }\n\n.m-preloader[_ngcontent-%COMP%] {\n  position: absolute;\n  background: white; }\n\n.m-viewport[_ngcontent-%COMP%] {\n  flex: 1; }\n  .m-viewport[_ngcontent-%COMP%]     iframe {\n    border: none;\n    padding: 0;\n    margin: 0; }"] });
    return ExtEversignDocumentComponent;
}());
export { ExtEversignDocumentComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtEversignDocumentComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-eversign-document',
                templateUrl: 'ext-eversign-document.component.html',
                styleUrls: ['ext-eversign-document.component.scss'],
            }]
    }], function () { return [{ type: i1.ResourceLoader }, { type: i0.ChangeDetectorRef }, { type: i2.UiErrorHandler, decorators: [{
                type: Inject,
                args: [UI_ERROR_HANDLER]
            }] }]; }, { signed: [{
            type: Output
        }], declined: [{
            type: Output
        }], url: [{
            type: Input
        }] }); })();
