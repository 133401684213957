/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:no-submodule-imports no-import-side-effect
import { NgModule } from '@angular/core';
import { CoreMlsModule, JSONTranslationMapLoader, provideTranslationMapLoader } from '@gh/core-mls';
import '@gh/customer-shared/mls/customer.mls.json';
import * as i0 from "@angular/core";
import * as i1 from "../../core-mls/core-mls.module";
var CustomerMlsConfigModule = /** @class */ (function () {
    function CustomerMlsConfigModule() {
    }
    CustomerMlsConfigModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomerMlsConfigModule });
    CustomerMlsConfigModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomerMlsConfigModule_Factory(t) { return new (t || CustomerMlsConfigModule)(); }, providers: [
            provideTranslationMapLoader(JSONTranslationMapLoader),
        ], imports: [[
                CoreMlsModule.forRoot(),
            ],
            CoreMlsModule] });
    return CustomerMlsConfigModule;
}());
export { CustomerMlsConfigModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomerMlsConfigModule, { imports: [i1.CoreMlsModule], exports: [CoreMlsModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerMlsConfigModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CoreMlsModule.forRoot(),
                ],
                providers: [
                    provideTranslationMapLoader(JSONTranslationMapLoader),
                ],
                exports: [
                    CoreMlsModule,
                ],
            }]
    }], null, null); })();
