/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { coalesce, isNotNil } from '@gh/core-util';
import { invert, isNil, mapKeys, mapValues, noop } from 'lodash';
import { BitSet } from '../bit-set';
import { Enum } from '../enum';
import { ArrayDataMapper } from './array-data-mapper';
import { BitSetMapper } from './bit-set-mapper';
import { ConstantBodyHttpDataMapper } from './constant-body-http-data-mapper';
import { DataMapper } from './data-mapper';
import { DateFormat, getDateMapper } from './date-mapper';
import { EntityMapper } from './entity-mapper';
import { EnumMapper } from './enum-mapper';
import { FileHttpDataMapper } from './file-http-data-mapper';
import { HttpDataMapper } from './http-data-mapper';
import { IdentityEntityMapper } from './identity-entity-mapper';
import { NoAuthRefreshHttpDataMapper } from './no-auth-refresh-http-data-mapper';
import { OptimisticLockingHttpDataMapper, OptimisticLockingSupport } from './optimistic-locking-http-data-mapper';
import { PrivilegedTokenHttpDataMapper, PrivilegedTokenSupport } from './privileged-token-http-data-mapper';

/**
 * Creates mapper for the given entity, having a set of fields returned by `mappings` function (the second parameter).
 * There is no sense to declare all fields in the `mappings` function.
 * Developer MUST define only fields with the special mapping.
 * All other fields will be mapped as is.
 *
 * @param type
 * @param mappings
 */
export function entity<T>(mappings: { [P in keyof T]?: DataMapper<T[P], any> } = {}): DataMapper<T, any> {
  return new EntityMapper(mappings);
}

/**
 * Creates mapper for the given entity, having a set of fields defined by `mappings` (the second parameter).
 * There is no sense to declare all fields in the `mappings`.
 * Developer MUST define only fields with the special mapping.
 * All other fields will be mapped as is.
 *
 * @param type
 * @param mappings
 */
export function subentity<T>(mappings: { [P in keyof T]?: DataMapper<T[P], any> } = {}): DataMapper<T, any> {
  return new EntityMapper(mappings);
}

/**
 * Creates a mapper for a list of entities. Each entity is mapped using provided mapper.
 *
 * @param mapper
 */
export function arrayOf<T>(mapper: DataMapper<T, any>): DataMapper<T[], any> {
  return new ArrayDataMapper(mapper);
}

/**
 * Creates a mapper with optimistic locking support.
 * This mapper cares about correct handling of ETag and If-Match headers.
 * This mapper returns {@link HttpDataMapper}.
 * This mapper MUST be added as a final stage of mapping, but can be combined with other {@link HttpDataMapper}s.
 *
 * Target entity MUST implement {@link OptimisticLockingSupport}.
 *
 * @param mapper
 */
export function optimisticLockingOf<T extends OptimisticLockingSupport>(mapper: DataMapper<T, any>): HttpDataMapper<T> {
  return new OptimisticLockingHttpDataMapper(mapper);
}

/**
 * Creates a mapper with privileged token support.
 * This mapper cares about correct handling of ETag and If-Match headers.
 * This mapper returns {@link HttpDataMapper}.
 * This mapper MUST be added as a final stage of mapping, but can be combined with other {@link HttpDataMapper}s.
 *
 * Target entity MUST implement {@link PrivilegedTokenSupport}.
 *
 * @param mapper
 */
export function privilegedTokenOf<T extends PrivilegedTokenSupport>(mapper: DataMapper<T, any>): HttpDataMapper<T> {
  return new PrivilegedTokenHttpDataMapper(mapper);
}

/**
 * When this mapper is used, auth token is not tried to be refreshed.
 *
 * @param mapper
 */
export function noAuthRefreshOf<T>(mapper: DataMapper<T, any>): HttpDataMapper<T> {
  return new NoAuthRefreshHttpDataMapper(mapper);
}

/**
 * Creates a mapper which always generates empty body and creates empty entity.
 *
 * @param mapper
 * @param emptyValue value used as empty value
 * @returns {ConstantBodyHttpDataMapper}
 */
export function emptyOf<T>(mapper: HttpDataMapper<T>, emptyValue: any = {}): HttpDataMapper<T> {
  return new ConstantBodyHttpDataMapper(mapper, emptyValue);
}

/**
 * Creates a mapper for given enumeration.
 *
 * @param type
 */
export function enumeration<E extends Enum<S>, S>(type: E): EnumMapper<E, S> {
  class Mapper extends EnumMapper<E, S> {
    enumeration = type;
  }

  return new Mapper();
}

/**
 * Creates a mapper for {@link Date} value.
 *
 * @param format
 */
export function date(format: DateFormat): DataMapper<Date, string> {
  return getDateMapper(format);
}

/**
 * Creates a mapper for {@link BitSet} value
 *
 * @param length
 */
export function bitSet<T>(length: number): DataMapper<BitSet<T>, string> {
  return new BitSetMapper(length);
}

/**
 * Creates identity mapper which returns original data for serialize and deserialize operations.
 */
export function identity<T>(clone: boolean = true): DataMapper<T, any> {
  return new IdentityEntityMapper<T, any>(clone);
}

/**
 * Returns mapper that aloways returns undefined for serialization and deserialization operations
 */
export function none(): DataMapper<any, void> {
  return custom({
    serialize: noop,
    deserialize: noop,
  });
}

/**
 * On serialization if object is nil this method passes given object in the underlying mapper.
 * On deserialization if deserialized object is nil this method returns given object.
 *
 * @param mapper
 * @param value
 */
export function coalesceOf<T>(mapper: DataMapper<T, any>, value: T): DataMapper<T, any> {
  return custom({
    serialize: (obj) => mapper.serialize(coalesce(obj, value)),
    deserialize: (obj) => coalesce(mapper.deserialize(obj), value),
  });
}

/**
 * Creates mapper which serializes/deserializes values according to the provided map.
 * Fields of map define serialization rules,
 * where key represents serialized value and value represents deserialized value.
 *
 * @param serializedToValue
 */
export function constantMapper<T>(serializedToValue: { [name: string]: T }): DataMapper<T, any> {
  const valueToSerialized = invert(serializedToValue);

  return custom({
    serialize: (obj) => {
      if (isNotNil(obj)) {
        const serialized = valueToSerialized[<any>obj];
        if (isNil(serialized)) {
          throw new Error(`ConstantMapper: cannot find serialized value for "${obj}"`);
        }
        return <any>serialized;
      }
    },
    deserialize: (serialized) => {
      if (isNotNil(serialized)) {
        const value = serializedToValue[serialized];
        if (isNil(value)) {
          throw new Error(`ConstantMapper: cannot find deserialized value for "${serialized}"`);
        }
        return <any>value;
      }
    },
  });
}

/**
 * Allows to create custom mapper. Actually this method does nothing,
 * but I believe allows to write more ideologically correct code.
 *
 * @param mapper
 */
export function custom<T>(mapper: DataMapper<T, any>): DataMapper<T, any> {
  return mapper;
}

/**
 * Creates a mapper for an object value where keys of object are values of given enum
 * and values are entities mapped by provided mapper.
 *
 * @param type
 * @param mapper
 */
export function enumMap<E extends Enum<S>, S, T>(type: E, mapper: DataMapper<T, any>): DataMapper<any, any> {
  class Mapper implements DataMapper<any, any> {

    serialize(map: any): any {
      return mapValues(
        mapKeys(map, (ignore, key) => Enum.toValue(type, <any>key)),
        (value) => mapper.serialize(<any>value));
    }

    deserialize(json: any): any {
      return mapValues(
        mapKeys(json, (ignore, key) => Enum.byValue(type, <any>key)),
        (value) => mapper.deserialize(value));
    }

  }

  return new Mapper();
}

export function file(mimeType: string = 'application/octet-stream'): DataMapper<File, any> {
  return new FileHttpDataMapper(identity(false), mimeType);
}
