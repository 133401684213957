/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InvalidFileReason, NotificationService, WaitingService } from '@gh/core-ui';
import { acceptor, parseByteSize } from '@gh/core-util';
import { constant, isNil } from 'lodash';
import { ExtUploaderService } from '../../services/ext-uploader.service';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-ui";
import * as i2 from "../../services/ext-uploader.service";
import * as i3 from "@angular/material/button";
import * as i4 from "../../../core-mls/translation/translate.pipe";
var ExtUploaderComponent = /** @class */ (function () {
    function ExtUploaderComponent(waitings, notifications, uploaderService) {
        this.waitings = waitings;
        this.notifications = notifications;
        this.uploaderService = uploaderService;
        this.disabled = false;
        // tslint:disable-next-line:no-output-named-after-standard-event
        this.invalid = new EventEmitter();
        // tslint:disable-next-line:no-output-named-after-standard-event
        this.success = new EventEmitter();
        this._accept = '';
        this.acceptor = constant(true);
    }
    Object.defineProperty(ExtUploaderComponent.prototype, "maxSize", {
        set: function (maxSize) {
            this._maxSize = parseByteSize(maxSize);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtUploaderComponent.prototype, "accept", {
        get: function () {
            return this._accept;
        },
        set: function (accept) {
            this._accept = accept;
            this.acceptor = acceptor(accept);
        },
        enumerable: true,
        configurable: true
    });
    ExtUploaderComponent.prototype.onSelect = function (file) {
        var _this = this;
        if (isNil(file)) {
            return;
        }
        if (this._maxSize && file.size > this._maxSize) {
            this.invalid.emit({ reason: InvalidFileReason.TooLarge, file: file });
            return;
        }
        else if (!this.acceptor(file)) {
            this.invalid.emit({ reason: InvalidFileReason.NotAccepted, file: file });
            return;
        }
        this.uploaderService
            .upload(this.url, file).pipe((this.waitings.operator()))
            .subscribe(function (response) { return _this.success.emit(response); }, function (error) { return _this.notifications.processAll(error); });
    };
    ExtUploaderComponent.ɵfac = function ExtUploaderComponent_Factory(t) { return new (t || ExtUploaderComponent)(i0.ɵɵdirectiveInject(i1.WaitingService), i0.ɵɵdirectiveInject(i1.NotificationService), i0.ɵɵdirectiveInject(i2.ExtUploaderService)); };
    ExtUploaderComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExtUploaderComponent, selectors: [["gh-ext-uploader"]], inputs: { label: "label", disabled: "disabled", url: "url", maxSize: "maxSize", accept: "accept" }, outputs: { invalid: "invalid", success: "success" }, decls: 6, vars: 5, consts: [[1, "upload"], ["type", "button", "color", "primary", "mat-raised-button", "", 1, "large", 3, "disabled", "click"], ["hidden", "", "type", "file", 3, "accept", "change"], ["uploader", ""]], template: function ExtUploaderComponent_Template(rf, ctx) { if (rf & 1) {
            var _r1 = i0.ɵɵgetCurrentView();
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵelementStart(1, "button", 1);
            i0.ɵɵlistener("click", function ExtUploaderComponent_Template_button_click_1_listener() { i0.ɵɵrestoreView(_r1); var _r0 = i0.ɵɵreference(5); return _r0.click(); });
            i0.ɵɵtext(2);
            i0.ɵɵpipe(3, "translate");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "input", 2, 3);
            i0.ɵɵlistener("change", function ExtUploaderComponent_Template_input_change_4_listener($event) { return ctx.onSelect($event.target.files[0]); });
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("disabled", ctx.disabled);
            i0.ɵɵadvance(1);
            i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 3, ctx.label), " ");
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("accept", ctx.accept);
        } }, directives: [i3.MatButton], pipes: [i4.TranslatePipe], styles: ["[_nghost-%COMP%]   .upload[_ngcontent-%COMP%] {\n  padding: 30px 20px; }"] });
    return ExtUploaderComponent;
}());
export { ExtUploaderComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExtUploaderComponent, [{
        type: Component,
        args: [{
                selector: 'gh-ext-uploader',
                templateUrl: 'ext-uploader.component.html',
                styleUrls: ['ext-uploader.component.scss'],
            }]
    }], function () { return [{ type: i1.WaitingService }, { type: i1.NotificationService }, { type: i2.ExtUploaderService }]; }, { label: [{
            type: Input
        }], disabled: [{
            type: Input
        }], url: [{
            type: Input
        }], invalid: [{
            type: Output
        }], success: [{
            type: Output
        }], maxSize: [{
            type: Input
        }], accept: [{
            type: Input
        }] }); })();
