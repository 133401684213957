/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';

import { ID } from '@gh/common-shared-data';
import { AbstractRestService, RestSettings } from '@gh/core-data';

import { Observable } from 'rxjs';

import { FinancialRecordDto } from './financial-record.dto';
import { FinancialRecordMapper } from './financial-record.mapper';

@Injectable()
export class CustomerFinancialRecordRestService extends AbstractRestService {
  constructor(private settings: RestSettings) {
    super(settings);
  }

  get(jobId: ID): Observable<FinancialRecordDto> {
    return this.httpGet(`/jobs/${jobId}/financial-records`, FinancialRecordMapper);
  }
}
