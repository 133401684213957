/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CollapsibleContainerDirective } from './collapsible-container.directive';

@Component({
  selector: 'gh-collapsible-label',
  template: '{{ value }}',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleLabelComponent implements OnInit {
  private _value: string;

  constructor(private container: CollapsibleContainerDirective) {

  }

  ngOnInit(): void {
    this.container.label = this;
  }

  @Input()
  set value(value: string) {
    this._value = value;

    this.container.recalculate();
  }

  get value(): string {
    return this._value;
  }
}
