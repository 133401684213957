/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { getRouteParam } from '@gh/common-shared';
import { CustomerPhotoRestService } from '@gh/customer-shared-data';
import { map$ } from '@gh/rx/operators';
import { customerPhotoListFromDto } from './customer-gallery.model';
import * as i0 from "@angular/core";
import * as i1 from "@gh/customer-shared-data";
var CustomerGalleryBusinessService = /** @class */ (function () {
    function CustomerGalleryBusinessService(restService) {
        this.restService = restService;
    }
    CustomerGalleryBusinessService.prototype.initView = function (route) {
        var jobId = getRouteParam(route, 'jobId');
        return {
            photoList: jobId ? this.findAll(jobId) : [],
        };
    };
    CustomerGalleryBusinessService.prototype.findAll = function (jobId) {
        return this.restService.findAll(jobId).pipe(map$(customerPhotoListFromDto));
    };
    CustomerGalleryBusinessService.ɵfac = function CustomerGalleryBusinessService_Factory(t) { return new (t || CustomerGalleryBusinessService)(i0.ɵɵinject(i1.CustomerPhotoRestService)); };
    CustomerGalleryBusinessService.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerGalleryBusinessService, factory: CustomerGalleryBusinessService.ɵfac });
    return CustomerGalleryBusinessService;
}());
export { CustomerGalleryBusinessService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerGalleryBusinessService, [{
        type: Injectable
    }], function () { return [{ type: i1.CustomerPhotoRestService }]; }, null); })();
