/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreMlsModule } from '@gh/core-mls';
import { CoreTypeModule } from '@gh/core-type';
import { CoreUiCommonModule } from '../common';
import {
  APP_DATE_FORMATS,
  AppDateAdapter,
  AppDatepickerIntl,
  AutocompleteActionIconComponent,
  AutocompleteArrowIconComponent,
  AutocompleteComponent,
  AutocompleteDirective,
  AutocompleteMaskedTypeDirective,
  AutocompleteOptionDirective,
  ClearComponent,
  DatepickerInputFixDirective,
  DatepickerToggleFixDirective,
  InlineTimepickerComponent,
  TimepickerComponent,
  TinymceTextareaDirective,
} from './components';
import {
  CollapsibleContainerDirective,
  CollapsibleInputDirective,
  CollapsibleLabelComponent,
  CompareByDirective,
  DateTimePickerDirective,
  DateTimePickerInputDirective,
  FloatLabelAlwaysDirective,
  FormAsyncEffectDirective,
  MapValueDirective,
  MessagesForDirective,
  ShowErrorOnDirtyDirective,
  UppercaseDirective,
  NoDblClickMatDirective,
} from './directives';
import { AsFormArrayPipe, AsFormGroupPipe } from './pipes';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,

    CoreUiCommonModule,
    CoreMlsModule,
    CoreTypeModule,
  ],
  declarations: [
    TinymceTextareaDirective,

    // autocomplete declarations
    AutocompleteDirective,
    AutocompleteMaskedTypeDirective,
    AutocompleteOptionDirective,
    AutocompleteComponent,
    AutocompleteActionIconComponent,
    AutocompleteArrowIconComponent,

    // clear declaration
    ClearComponent,

    // datepicker declarations
    DatepickerInputFixDirective,
    DatepickerToggleFixDirective,

    // timepicker declarations
    TimepickerComponent,
    InlineTimepickerComponent,

    // helper directives
    MessagesForDirective,
    CollapsibleInputDirective,
    CollapsibleContainerDirective,
    CollapsibleLabelComponent,
    MapValueDirective,
    CompareByDirective,
    FormAsyncEffectDirective,
    FloatLabelAlwaysDirective,
    UppercaseDirective,
    ShowErrorOnDirtyDirective,
    DateTimePickerDirective,
    DateTimePickerInputDirective,
    NoDblClickMatDirective,

    // helper pipes
    AsFormArrayPipe,
    AsFormGroupPipe,
  ],
  entryComponents: [
    AutocompleteActionIconComponent,
    AutocompleteArrowIconComponent,
  ],
  exports: [
    TinymceTextareaDirective,

    // autocomplete declarations
    AutocompleteDirective,
    AutocompleteMaskedTypeDirective,
    AutocompleteOptionDirective,
    AutocompleteComponent,
    AutocompleteActionIconComponent,
    AutocompleteArrowIconComponent,

    // clear declaration
    ClearComponent,

    // datepicker declarations
    DatepickerInputFixDirective,
    DatepickerToggleFixDirective,

    // timepicker declarations
    TimepickerComponent,
    InlineTimepickerComponent,

    // helper directives
    MessagesForDirective,
    CollapsibleInputDirective,
    CollapsibleContainerDirective,
    CollapsibleLabelComponent,
    MapValueDirective,
    CompareByDirective,
    FormAsyncEffectDirective,
    FloatLabelAlwaysDirective,
    UppercaseDirective,
    ShowErrorOnDirtyDirective,
    DateTimePickerDirective,
    DateTimePickerInputDirective,
    NoDblClickMatDirective,

    // helper pipes
    AsFormArrayPipe,
    AsFormGroupPipe,
  ],
})
export class CoreUiFormModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreUiFormModule,
      providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: MatDatepickerIntl, useClass: AppDatepickerIntl },
      ],
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: CoreUiFormModule,
    };
  }
}
