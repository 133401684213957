/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getSlicedData, SortDirection } from '@gh/core-data';
import { merge$, of$ } from '@gh/rx';
import { map$, mapTo$, switchMap$ } from '@gh/rx/operators';
import { BehaviorSubject } from 'rxjs';
var TABLE_QUERY_RESULT = Symbol('TableQueryResult');
var QUERY_ITEM_INDEX = Symbol('QueryItemIndex');
export function createQueryResult(query, items) {
    items.forEach(function (item, i) { return mergeQueryItemIndex(item, query.from + i); });
    var result = Object.create(items);
    result.query = query;
    result[TABLE_QUERY_RESULT] = true;
    return result;
}
export function isQueryResult(array) {
    return array[TABLE_QUERY_RESULT] === true;
}
export function toQueryResult(array) {
    if (isQueryResult(array)) {
        return array;
    }
    throw new Error('Provided array is not a TableQueryResult');
}
function mergeQueryItemIndex(item, index) {
    item[QUERY_ITEM_INDEX] = index;
}
export function getQueryItemIndex(item) {
    return item[QUERY_ITEM_INDEX];
}
var InfiniteMatDataSource = /** @class */ (function () {
    function InfiniteMatDataSource(dataSource) {
        this.dataSource = dataSource;
        this.fire$$ = new BehaviorSubject(void 0);
    }
    InfiniteMatDataSource.wrap = function (dataSource) {
        return new InfiniteMatDataSource(dataSource);
    };
    InfiniteMatDataSource.prototype.connect = function (collectionViewer) {
        var _this = this;
        var sort = this.sort;
        var sort$ = sort ? merge$(sort.initialized.pipe(mapTo$(void 0)), sort.sortChange) : of$(void 0);
        return collectionViewer.viewChange.pipe(switchMap$(function (range) { return sort$.pipe(map$(function (sortState) { return (__assign(__assign({}, range), { sort: sortState })); })); }), map$(function (_a) {
            var start = _a.start, end = _a.end, sortState = _a.sort;
            return ({
                from: start,
                to: Math.min(end, Number.MAX_SAFE_INTEGER),
                sorting: sortState && sortState.direction
                    ? {
                        field: sortState.active,
                        direction: sortState.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending,
                    }
                    : void 0,
            });
        }), switchMap$(function (query) { return _this.fire$$.pipe(mapTo$(query)); }), switchMap$(function (query) { return _this.dataSource.select(query).pipe(map$(getSlicedData), map$(function (items) { return createQueryResult(query, items); })); }));
    };
    InfiniteMatDataSource.prototype.disconnect = function (collectionViewer) {
    };
    InfiniteMatDataSource.prototype.fire = function () {
        this.fire$$.next(void 0);
    };
    return InfiniteMatDataSource;
}());
export { InfiniteMatDataSource };
