/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { getRouteInitData } from '@gh/common-shared';
import { ID } from '@gh/common-shared-data';
import { TranslationService } from '@gh/core-mls';
import { WaitingService } from '@gh/core-ui';
import { skip$, switchMap$ } from '@gh/rx/operators';

import { find, first, indexOf, isEmpty, isEqual } from 'lodash';

import { CustomerGalleryBusinessService } from '../../resources/customer-gallery-business.service';
import { CustomerPhotoListItem, shortenedPhotoTitle } from '../../resources/customer-gallery.model';

@Component({
  selector: 'gh-customer-gallery',
  templateUrl: 'customer-gallery.component.html',
  styleUrls: ['customer-gallery.component.scss'],
})
export class CustomerGalleryComponent implements OnInit {
  photoList: CustomerPhotoListItem[];
  currentPhoto: Maybe<CustomerPhotoListItem>;
  isPhotoOverlayOpen: boolean = false;

  @ViewChild('photoItem', { static: false }) photoItemElement: ElementRef;
  @ViewChild('currentPhotoOverlay', { static: false }) currentPhotoOverlay: CdkOverlayOrigin;

  constructor(private businessService: CustomerGalleryBusinessService,
              private route: ActivatedRoute,
              private waitingService: WaitingService,
              private translationService: TranslationService,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
  }

  get navigationInfo(): string {
    return `${this.currentPhotoNumber()} ${this.translationService.get('lbl_of')} ${this.photoListLength()}`;
  }

  get currentPhotoName(): string {
    return this.currentPhoto && this.currentPhoto.name
      ? shortenedPhotoTitle(this.currentPhoto.name)
      : '';
  }

  ngOnInit(): void {
    const { photoList } = getRouteInitData(this.route);
    this.initPhotoList(photoList);
    // Lack of getRouteInitData according to router changing
    this.route.paramMap.pipe(
      skip$(1),
      switchMap$(({ params }: any) => this.businessService.findAll(params.jobId).pipe(
        this.waitingService.operator())))
      .subscribe(this.initPhotoList.bind(this));
  }

  photoListLength(): number {
    return this.photoList.length;
  }

  isPhotoListExist(): boolean {
    return this.photoListLength() > 0;
  }

  currentPhotoNumber(): number {
    return indexOf(this.photoList, this.currentPhoto) + 1;
  }

  isCurrentPhoto(photo: CustomerPhotoListItem): boolean {
    return isEqual(this.currentPhoto, photo);
  }

  hasNextPhoto(): boolean {
    return this.currentPhotoNumber() < this.photoListLength();
  }

  onNextPhoto(): void {
    if (this.hasNextPhoto()) {
      this.currentPhoto = this.photoList[indexOf(this.photoList, this.currentPhoto) + 1];
      const listItemElement = document.getElementById(this.getPhotoListItemHtmlId(this.currentPhoto));
      listItemElement && listItemElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  hasPrevPhoto(): boolean {
    return this.currentPhotoNumber() > 1;
  }

  onPrevPhoto(): void {
    if (this.hasPrevPhoto()) {
      this.currentPhoto = this.photoList[indexOf(this.photoList, this.currentPhoto) - 1];
      const listItemElement = document.getElementById(this.getPhotoListItemHtmlId(this.currentPhoto));
      listItemElement && listItemElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onChangeCurrentPhoto(id: ID, props: { hasOverlay: boolean }): void {
    this.currentPhoto = find(this.photoList, { id });
    if (props.hasOverlay) {
      this.onChangeStatePhotoOverlay();
    }
  }

  onChangeStatePhotoOverlay(isOverlayOpen: boolean = true): void {
    this.changePhotoOverlayState({ open: true });
    setTimeout(() => !isOverlayOpen && this.changePhotoOverlayState({ open: false }), 0);
  }

  getPhotoListItemHtmlId(photo: any): string {
    return `photo-list-item-${photo.id}`;
  }

  onPhotoLoaded($event: any): void {
    const parentNode = $event.target.parentNode;
    const waitingNode = find(Array.from(parentNode.childNodes), { className: 'overlay-item waiting' });
    if (parentNode && waitingNode) {
      this.renderer.removeChild(parentNode, waitingNode);
    }
  }

  private changePhotoOverlayState(state: { open: boolean }): void {
    this.isPhotoOverlayOpen = state.open;
    if (this.isPhotoOverlayOpen) {
      this.renderer.addClass(this.document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(this.document.body, 'no-scroll');
    }
  }

  private initPhotoList(photoList: CustomerPhotoListItem[]): void {
    this.photoList = photoList;
    this.currentPhoto = !isEmpty(this.photoList) ? first(this.photoList) : void 0;
  }
}
