/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as i0 from "@angular/core";
var DEFAULT_LOAD_DISTANCE_PX = 50;
var InfiniteScrollDirective = /** @class */ (function () {
    function InfiniteScrollDirective(element) {
        this.element = element;
        // Distance from bottom in px, when triggered new data loading
        this.loadDistance = DEFAULT_LOAD_DISTANCE_PX;
        // Action triggered when user scrolled to bottom with load distance
        this.scroll = new EventEmitter();
        this._element = this.element.nativeElement;
        if (!this.loadDistance) {
            this.loadDistance = DEFAULT_LOAD_DISTANCE_PX;
        }
    }
    InfiniteScrollDirective.prototype.onScroll = function () {
        var _a = this, _element = _a._element, loadDistance = _a.loadDistance, scroll = _a.scroll;
        var scrollTop = _element.scrollTop, clientHeight = _element.clientHeight, scrollHeight = _element.scrollHeight;
        var userScrolledToBottomWithLoadDistance = scrollTop + clientHeight + loadDistance >= scrollHeight;
        if (userScrolledToBottomWithLoadDistance) {
            scroll.emit(void 0);
        }
    };
    InfiniteScrollDirective.ɵfac = function InfiniteScrollDirective_Factory(t) { return new (t || InfiniteScrollDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    InfiniteScrollDirective.ɵdir = i0.ɵɵdefineDirective({ type: InfiniteScrollDirective, selectors: [["", "ghInfiniteScroll", ""]], hostBindings: function InfiniteScrollDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("scroll", function InfiniteScrollDirective_scroll_HostBindingHandler($event) { return ctx.onScroll($event); });
        } }, inputs: { loadDistance: "loadDistance" }, outputs: { scroll: "ghInfiniteScroll" }, features: [i0.ɵɵProvidersFeature([NgModel])] });
    return InfiniteScrollDirective;
}());
export { InfiniteScrollDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(InfiniteScrollDirective, [{
        type: Directive,
        args: [{
                selector: '[ghInfiniteScroll]',
                providers: [NgModel],
                // tslint:disable-next-line:use-host-property-decorator
                host: {
                    '(scroll)': 'onScroll($event)',
                },
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { loadDistance: [{
            type: Input,
            args: ['loadDistance']
        }], scroll: [{
            type: Output,
            args: ['ghInfiniteScroll']
        }] }); })();
