/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { isEmpty } from 'lodash';
function rangeLength(_a, value) {
    var min = _a.min, max = _a.max;
    if (isEmpty(value)) {
        return;
    }
    if (value.length < min || value.length > max) {
        return {
            min: min,
            max: max,
            actualLength: value.length,
        };
    }
}
function maxLength(constraint, value) {
    if (isEmpty(value)) {
        return;
    }
    if (value.length > constraint) {
        return {
            requiredLength: constraint,
            actualLength: value.length,
        };
    }
}
function pattern(constraint, value) {
    if (isEmpty(value)) {
        return;
    }
    if (!constraint.test(value)) {
        return {
            pattern: constraint,
            actualValue: value,
        };
    }
}
// tslint:disable-next-line:variable-name
export var StringTypeValidators = {
    rangeLength: rangeLength,
    maxLength: maxLength,
    pattern: pattern,
};
