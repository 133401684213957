/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ValidationErrors } from '@angular/forms';
import { LocalizationProvider, NumberFormat, NumberFormatQuery, NumberLocalization, NumberParser } from '@gh/core-mls';
import { NumberType, NumberTypeDefenition } from '../type.interfaces';
import { NumberTypeValidators } from '../validators';
import { AbstractPrimitiveType } from './abstract-type';
import { createNumberMask } from './create-number-mask';

export class NumberTypeImpl extends AbstractPrimitiveType<number> implements NumberType {
  mask: any;

  private localization: NumberLocalization;
  private numberFormat: NumberFormat;
  private numberParser: NumberParser;

  private formatQuery = {
    minimumFractionDigits: this.defenition.format && this.defenition.format.minFractionDigits,
    maximumFractionDigits: this.defenition.format && this.defenition.format.maxFractionDigits,
  };

  constructor(defenition: NumberTypeDefenition) {
    super(defenition, NumberTypeValidators);
  }

  parse(str: string): number {
    return this.numberParser.parse(str);
  }

  format(value: number, query?: NumberFormatQuery): string {
    return this.numberFormat.format(query || this.formatQuery, value);
  }

  validateFormat(str: string): Maybe<ValidationErrors> {
    if (!this.numberParser.validate(str)) {
      return {
        format: {
          messageId: 'msg_field_invalid_format',
        },
      };
    }
  }

  init(name: string, localizationProvider: LocalizationProvider): void {
    super.init(name, localizationProvider);

    this.localization = localizationProvider.getNumberLocalization();
    this.numberFormat = localizationProvider.getNumberFormat();
    this.numberParser = localizationProvider.getNumberParser();

    const constraints = this.defenition.constraints || {};

    this.mask = createNumberMask({
      prefix: '',
      suffix: '',
      includeThousandsSeparator: this.localization.groupSeparator ? true : false,
      thousandsSeparatorSymbol: this.localization.groupSeparator,
      allowNegative: constraints.min && constraints.min.value < 0
        || constraints.range && constraints.range.min < 0
        || false,
      allowDecimal: this.defenition.format && this.defenition.format.maxFractionDigits > 0 || false,
      decimalSymbol: this.localization.decimalSeparator,
      decimalLimit: this.defenition.format && this.defenition.format.maxFractionDigits || false,
      requireDecimal: false,
    });
  }

}
