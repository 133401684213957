/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { first, identity, isNil, isString, iteratee as getIteratee, sortedIndexBy } from 'lodash';

import { isNotNil } from './data-util';

export type CollectionComparator<T> = (left: T, right: T) => number;

export const identityCollectionComparator = valueComparator(identity);

export function* arrayIterator<T>(array: T[]): Iterator<T> {
  for (const item of array) {
    yield item;
  }
}

export function valueComparator<T>(name: string | TransformFn<T, any>): CollectionComparator<T> {
  const computeValue = isString(name) ? (obj: T) => obj && obj[name] : name;
  return (left, right) => {
    const computedLeft = computeValue(left);
    const computedRight = computeValue(right);

    if (isNil(computedLeft)) {
      if (isNil(computedRight)) {
        return 0;
      } else {
        return 1;
      }
    } else if (isNil(computedRight)) {
      return -1;
    }
    if (computedLeft < computedRight) {
      return -1;
    } else if (computedLeft > computedRight) {
      return 1;
    } else {
      return 0;
    }
  };
}

export function negateComparator<T>(comparator: CollectionComparator<T>): CollectionComparator<T> {
  return (left, right) => -comparator(left, right);
}

export function composeComparators<T>(...comparators: CollectionComparator<T>[]): CollectionComparator<T> {
  return (left, right) => comparators.reduce(
    (rank, comparator) => rank === 0 ? comparator(left, right) : rank,
    0);
}

export function sortedIndexOfBy<T>(array: T[], value: T, it: TransformFn<T, any> | string): number {
  const length = isNil(array) ? 0 : array.length;
  if (length) {
    const iteratee = getIteratee(it);
    const index = sortedIndexBy(array, value, iteratee);
    if (index < length && iteratee(array[index]) === iteratee(value)) {
      return index;
    }
  }
  return -1;
}

export function sortedIndexWith<T>(array: T[], value: T, comparator: CollectionComparator<T>): number {
  let low = 0;
  let high = array.length;

  while (low < high) {
    const mid = (low + high) >>> 1; // tslint:disable-line:no-bitwise
    const computed = array[mid];

    if (comparator(computed, value) < 0) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return high;
}

export function orderByComparator<T>(array: T[], comparator: CollectionComparator<T>): T[] {
  const copied = array.concat();
  copied.sort(comparator);
  return copied;
}

export function firstByComparator<T>(items: T[], comparator: CollectionComparator<T>): Maybe<T> {
  return first(orderByComparator(items, comparator));
}

export function withoutIndex<T>(array: T[], index: number): T[] {
  return array.slice(0, index).concat(array.slice(index + 1));
}

export function withValueByIndex<T>(array: T[], index: number, value: T): T[] {
  return array.slice(0, index).concat([value]).concat(array.slice(index));
}

/**
 * This function is an analogous of {@link Array#sort} method,
 * but it guarantees predictable order of items having the same sorting order.
 *
 * Items having the same sorting order will stay in the same order as in the original array.
 */
export function mutableSort<T>(arr: T[], comparator: CollectionComparator<T>): void {
  const arrWithIndex = arr.map((value, index) => ({ value, index }));
  arrWithIndex.sort((left, right) => {
    const byComparator = comparator(left.value, right.value);
    return byComparator === 0 ? left.index - right.index : byComparator;
  });
  arrWithIndex.forEach(({ value }, i) => arr[i] = value);
}

/**
 * Finds maximal array element using given comparator
 */
export function maxWith<T>(arr: T[], comparator: CollectionComparator<T>): Maybe<T> {
  return arr.reduce(
    (max: Maybe<T>, el) => isNotNil(max)
      ? (comparator(max, el) < 0 ? el : max)
      : el,
    void 0);
}
