<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
-->

<div class="table-layout-header" [style.marginRight.px]="scrollWidth" #header="">
  <ng-content select="gh-table-layout-header"></ng-content>
</div>
<div class="table-layout-body-viewport" #body="">
  <div class="table-layout-body">
    <ng-content select="gh-table-layout-body"></ng-content>
  </div>
</div>
