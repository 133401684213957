var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { filter$ } from '@gh/rx/operators';
import { UploadContext } from './upload-context.service';
import { UploadSlot } from './upload-slot';
import { isUploadCompletedEvent, UploadController } from './upload.interfaces';
import * as i0 from "@angular/core";
import * as i1 from "./upload-context.service";
/**
 * Implementation of {@link UploadController} interface which binds itself to the assigned {@link UploadSlot} instance.
 */
var UploadSlotDirective = /** @class */ (function (_super) {
    __extends(UploadSlotDirective, _super);
    function UploadSlotDirective(context) {
        var _this = _super.call(this) || this;
        _this.context = context;
        _this.completed = new EventEmitter();
        return _this;
    }
    Object.defineProperty(UploadSlotDirective.prototype, "event$", {
        get: function () {
            return this.slot.event$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadSlotDirective.prototype, "items", {
        get: function () {
            var item = this.slot.item;
            return item ? [item] : [];
        },
        enumerable: true,
        configurable: true
    });
    UploadSlotDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.event$
            .pipe(filter$(isUploadCompletedEvent))
            .subscribe(function (_a) {
            var item = _a.item;
            return _this.completed.emit(item);
        });
    };
    UploadSlotDirective.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    UploadSlotDirective.prototype.create = function (file) {
        var item = this.context.create(file);
        this.slot.assign(item);
        return item;
    };
    UploadSlotDirective.prototype.start = function (item) {
        this.slot.start();
    };
    UploadSlotDirective.prototype.cancel = function (item) {
        this.slot.cancel();
    };
    UploadSlotDirective.ɵfac = function UploadSlotDirective_Factory(t) { return new (t || UploadSlotDirective)(i0.ɵɵdirectiveInject(i1.UploadContext)); };
    UploadSlotDirective.ɵdir = i0.ɵɵdefineDirective({ type: UploadSlotDirective, selectors: [["", "ghUploadSlot", ""]], inputs: { slot: ["ghUploadSlot", "slot"] }, outputs: { completed: "ghUploadSlotCompleted" }, features: [i0.ɵɵProvidersFeature([{ provide: UploadController, useExisting: UploadSlotDirective }]), i0.ɵɵInheritDefinitionFeature] });
    return UploadSlotDirective;
}(UploadController));
export { UploadSlotDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UploadSlotDirective, [{
        type: Directive,
        args: [{
                selector: '[ghUploadSlot]',
                providers: [{ provide: UploadController, useExisting: UploadSlotDirective }],
            }]
    }], function () { return [{ type: i1.UploadContext }]; }, { slot: [{
            type: Input,
            args: ['ghUploadSlot']
        }], completed: [{
            type: Output,
            args: ['ghUploadSlotCompleted']
        }] }); })();
