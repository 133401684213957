/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash';

function rangeLength({ min, max }: { min: number; max: number }, value: string): Maybe<ValidationErrors> {
  if (isEmpty(value)) {
    return;
  }

  if (value.length < min || value.length > max) {
    return {
      min,
      max,
      actualLength: value.length,
    };
  }
}

function maxLength(constraint: number, value: string): Maybe<ValidationErrors> {
  if (isEmpty(value)) {
    return;
  }

  if (value.length > constraint) {
    return {
      requiredLength: constraint,
      actualLength: value.length,
    };
  }
}

function pattern(constraint: RegExp, value: string): Maybe<ValidationErrors> {
  if (isEmpty(value)) {
    return;
  }

  if (!constraint.test(value)) {
    return {
      pattern: constraint,
      actualValue: value,
    };
  }
}

// tslint:disable-next-line:variable-name
export const StringTypeValidators = {
  rangeLength,
  maxLength,
  pattern,
};
