<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<gh-ext-dialog>
  <gh-ext-dialog-header [closableOnDesktop]="true" title="{{'lbl_e_doc_signing' | translate}}"></gh-ext-dialog-header>
  <gh-ext-dialog-content fxLayout="column">
    <div class="ext-divider"></div>
    <gh-ext-eversign-document fxFlex="" [url]="input.url" (signed)="onSigned()" (declined)="onDeclined()"></gh-ext-eversign-document>
  </gh-ext-dialog-content>
</gh-ext-dialog>