/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { FormStateSchema } from './form-state-schema';

export abstract class FormSchemaFactory {
  abstract create(): FormStateSchema;
}
