/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { assign, fromPairs, isDate, isFunction } from 'lodash';
/**
 * Immutable setter for array element
 */
export function setArrayElement(entities, index, entity) {
    if (entities[index] === entity) {
        return entities;
    }
    else {
        var copied = entities.concat();
        copied[index] = entity;
        return copied;
    }
}
export function setField(entity, name, valueOrFn) {
    var _a;
    var value = isFunction(valueOrFn) ? valueOrFn.call(void 0, entity[name]) : valueOrFn;
    return entity && !isSimpleValueEqual(entity[name], value) ? __assign(__assign({}, entity), (_a = {}, _a[name] = value, _a)) : entity;
}
/**
 * Immutable setter for the given fields.
 */
export function setFields(entity, values) {
    if (entity) {
        var modifiedFields = Object.keys(values).filter(function (field) { return !isSimpleValueEqual(entity[field], values[field]); });
        if (modifiedFields.length > 0) {
            return assign({}, entity, fromPairs(modifiedFields.map(function (field) { return [field, values[field]]; })));
        }
    }
    return entity;
}
export function isSimpleValueEqual(a, b) {
    if (a === b || a == null && a == b) { // tslint:disable-line:triple-equals no-null-keyword
        return true;
    }
    else if (isDate(a) && isDate(b)) {
        return a.getTime() === b.getTime();
    }
    else {
        return false;
    }
}
