/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gh-ext-dialog-header',
  templateUrl: 'ext-dialog-header.component.html',
  styleUrls: ['ext-dialog-header.component.scss'],
})
export class ExtDialogHeaderComponent {
  @Input() title: string;
  @Input() closableOnDesktop: boolean = false;

  constructor(private dialogRef: MatDialogRef<any>) {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
