/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:no-magic-numbers variable-name

import { quantityNumberType } from '@gh/core-type';
import { getPositiveDecimalParams, getPositiveIntegralParams, getRangedDecimalParams } from './number-utils';

export const BaseQuantityNumberType = quantityNumberType({
  messages: {
    min: 'msg_field_invalid_format',
    max: 'msg_field_invalid_format',
    range: 'msg_field_invalid_range',
  },
});

export const QuantityTypes = {
  QGeneric: quantityNumberType(BaseQuantityNumberType, {
    ...getRangedDecimalParams({ precision: 20, scale: 4, includeMin: false, includeMax: false }),
  }),
};
