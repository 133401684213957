/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, EventEmitter, Input, NgZone, Output, TemplateRef, ViewContainerRef, } from '@angular/core';
import { first$ } from '@gh/rx/operators';
import * as i0 from "@angular/core";
/**
 * This structure directive allows to manage rendering of some block manually.
 * This is especially convenient when developer needs to refresh non-angular component which does not support
 * reactive data update => developer can refresh content using {@link RendererDirective#refresh} method.
 */
var RendererDirective = /** @class */ (function () {
    function RendererDirective(ngZone, templateRef, viewContainerRef, changeDetectorRef) {
        this.ngZone = ngZone;
        this.templateRef = templateRef;
        this.viewContainerRef = viewContainerRef;
        this.changeDetectorRef = changeDetectorRef;
        this.attached = new EventEmitter();
        this.detached = new EventEmitter();
        this._rendered = true;
        this.hasView = false;
    }
    Object.defineProperty(RendererDirective.prototype, "rendered", {
        get: function () {
            return this._rendered;
        },
        set: function (rendered) {
            this._rendered = coerceBooleanProperty(rendered);
            if (this._rendered && !this.hasView) {
                this.render();
            }
            else if (!this._rendered && this.hasView) {
                this.destroy();
            }
        },
        enumerable: true,
        configurable: true
    });
    RendererDirective.prototype.ngOnInit = function () {
        if (this._rendered) {
            this.render();
        }
    };
    RendererDirective.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    /**
     * Refreshes content by destroying and rendering view for attached template.
     */
    RendererDirective.prototype.refresh = function () {
        var _this = this;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.destroy();
        this.subscription = this.ngZone.onMicrotaskEmpty.pipe(first$()).subscribe(function () {
            _this.render();
            _this.subscription = void 0;
        });
    };
    RendererDirective.prototype.render = function () {
        if (!this.hasView) {
            this.hasView = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
            this.changeDetectorRef.markForCheck();
            this.attached.emit();
        }
    };
    RendererDirective.prototype.destroy = function () {
        if (this.hasView) {
            this.hasView = false;
            this.viewContainerRef.clear();
            this.changeDetectorRef.markForCheck();
            this.detached.emit();
        }
    };
    RendererDirective.ɵfac = function RendererDirective_Factory(t) { return new (t || RendererDirective)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
    RendererDirective.ɵdir = i0.ɵɵdefineDirective({ type: RendererDirective, selectors: [["", "ghRenderer", ""]], inputs: { rendered: "rendered" }, outputs: { attached: "ghRendererAttached", detached: "ghRendererDetached" }, exportAs: ["ghRenderer"] });
    return RendererDirective;
}());
export { RendererDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(RendererDirective, [{
        type: Directive,
        args: [{
                selector: '[ghRenderer]',
                exportAs: 'ghRenderer',
            }]
    }], function () { return [{ type: i0.NgZone }, { type: i0.TemplateRef }, { type: i0.ViewContainerRef }, { type: i0.ChangeDetectorRef }]; }, { attached: [{
            type: Output,
            args: ['ghRendererAttached']
        }], detached: [{
            type: Output,
            args: ['ghRendererDetached']
        }], rendered: [{
            type: Input
        }] }); })();
