/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { findPermission, Permission } from '@gh/core-auth';
import * as invariant from 'invariant';
import { isString } from 'lodash';

import { UserContext } from '../user';

@Directive({ selector: '[ghForbidFor]' })
export class ForbidForDirective {
  private hasView = false;

  constructor(private viewContainerRef: ViewContainerRef,
              private templateRef: TemplateRef<Object>,
              private userContext: UserContext) {
  }

  @Input()
  set ghForbidFor(permission: any) {
    if (isString(permission)) {
      permission = findPermission(permission);
    }

    invariant(permission instanceof Permission, `Permission '${permission}' is not an instance of Permission`);

    if (this.userContext.hasPermission(permission) && this.hasView) {
      this.hasView = false;
      this.viewContainerRef.clear();
    } else if (!this.userContext.hasPermission(permission) && !this.hasView) {
      this.hasView = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
