/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isFunction, isNil } from 'lodash';
import * as i0 from "@angular/core";
var MapValueDirective = /** @class */ (function () {
    function MapValueDirective(elementRef) {
        this.elementRef = elementRef;
    }
    MapValueDirective.prototype.writeValue = function (value) {
        if (isNil(value)) {
            this.elementRef.nativeElement.value = '';
        }
        else {
            var map = this.map;
            this.elementRef.nativeElement.value = isFunction(map) ? map(value) : value[map];
        }
    };
    MapValueDirective.prototype.registerOnChange = function (fn) {
        // nothing to do
    };
    MapValueDirective.prototype.registerOnTouched = function (fn) {
        // nothing to do
    };
    MapValueDirective.prototype.setDisabledState = function (isDisabled) {
        // nothing to do
    };
    MapValueDirective.ɵfac = function MapValueDirective_Factory(t) { return new (t || MapValueDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    MapValueDirective.ɵdir = i0.ɵɵdefineDirective({ type: MapValueDirective, selectors: [["input", "ghMapValue", ""]], inputs: { map: ["ghMapValue", "map"] }, features: [i0.ɵɵProvidersFeature([{
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: MapValueDirective,
                    multi: true,
                }])] });
    return MapValueDirective;
}());
export { MapValueDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MapValueDirective, [{
        type: Directive,
        args: [{
                selector: 'input[ghMapValue]',
                providers: [{
                        provide: NG_VALUE_ACCESSOR,
                        useExisting: MapValueDirective,
                        multi: true,
                    }],
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { map: [{
            type: Input,
            args: ['ghMapValue']
        }] }); })();
