/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

export type PermissionAction = string;

export class Permission {
  // tslint:disable-next-line:new-parens
  static TRUE = new class extends Permission {
    constructor() {
      super([], 'TRUE');
    }

    some(): boolean {
      return true;
    }

    every(): boolean {
      return true;
    }
  };

  // tslint:disable-next-line:new-parens
  static FALSE = new class extends Permission {
    constructor() {
      super([], 'FALSE');
    }

    some(): boolean {
      return false;
    }

    every(): boolean {
      return false;
    }
  };

  static create(path: string | string[]): Permission;
  static create(name: string | string[], action?: PermissionAction): Permission;
  static create(name: string | string[], action?: PermissionAction): Permission {
    if (!action) {
      const path = name;

      if (Array.isArray(path)) {
        name = path.slice(0, path.length - 1);
        action = path[path.length - 1];
      } else {
        [name, action] = path.split('#');
      }
    }
    return new Permission(name, action);
  }

  get name(): string {
    return this._name;
  }

  get action(): PermissionAction {
    return this._action;
  }

  get path(): string {
    return this._path;
  }

  private _name: string;
  private _path: string;

  constructor(
    name: string[] | string,
    private _action: PermissionAction,
  ) {
    this._name = toString(name);

    this._path = `${this._name}#${this._action}`;
  }

  every(predicate: (permission: Permission) => boolean): boolean {
    return predicate(this);
  }

  some(predicate: (permission: Permission) => boolean): boolean {
    return predicate(this);
  }
}

export class PermissionSet extends Permission {
  constructor(
    name: string[] | string,
    action: PermissionAction,
    readonly permissions: Permission[],
  ) {
    super(name, action);
  }

  every(predicate: (permission: Permission) => boolean): boolean {
    return this.permissions.every(predicate);
  }

  some(predicate: (permission: Permission) => boolean): boolean {
    return this.permissions.some(predicate);
  }
}

export class LazyPermission extends Permission {
  static createLazy(action: PermissionAction): Permission {
    return new LazyPermission(action);
  }

  private constructor(action: PermissionAction) {
    super('', action);
  }

  complete(path: string[] | string): Permission {
    return new Permission(path, this.action);
  }
}

export class LazyPermissionSet extends PermissionSet {
  static createLazy(action: PermissionAction, permissions: Permission[]): PermissionSet {
    return new LazyPermissionSet(action, permissions);
  }

  private constructor(action: PermissionAction, permissions: Permission[]) {
    super('', action, permissions);
  }

  complete(path: string[] | string): Permission {
    return new PermissionSet(path, this.action, this.permissions);
  }
}

function toString(path: string[] | string): string {
  if (typeof path === 'string') {
    return path;
  } else {
    return path.join('/');
  }
}
