/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ContainerStateDirective } from './container-state.directive';

/**
 * This directive helps to handle disabling of controls when form is in 'pending' status.
 * It sets 'disabled' attribute for the element this directive is declared on and adds spinner animation.
 *
 * Note!!! Currently this directive can be assigned only to mat-button and mat-icon-button.
 */
@Directive({
  selector: '[mat-button][ghContainerAsyncDisabled], [mat-icon-button][ghContainerAsyncDisabled]',
})
export class ContainerAsyncDisabledDirective implements OnInit, OnDestroy, DoCheck {
  /**
   * If field is disabled due to async validation
   * @type {boolean}
   */
  @HostBinding('class.form-async-disabled')
  asyncDisabled: boolean = false;

  /**
   * If disabled attribute is set
   *
   * @type {boolean}
   */
  @HostBinding('disabled')
  hostDisabled: boolean = false;

  /**
   * If field is disabled due to disabled field
   */
  @Input() disabled: boolean;

  private spinnerEl?: HTMLElement;

  constructor(private elementRef: ElementRef, private containerState: ContainerStateDirective) {

  }

  ngOnInit(): void {
    const spinnerEl = document.createElement('div');
    spinnerEl.classList.add('spinner', 'spinner-disabled');

    this.elementRef.nativeElement.appendChild(spinnerEl);

    this.spinnerEl = spinnerEl;
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.removeChild(this.spinnerEl);
    this.spinnerEl = void 0;
  }

  ngDoCheck(): void {
    this.asyncDisabled = this.containerState.containerState.pending;
    this.hostDisabled = this.asyncDisabled || this.disabled;
  }
}
