/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { distinctUntilChanged$, filter$, map$ } from '@gh/rx/operators';
import { without } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  DndDragEvent,
  DndDragSource,
  DndDragSourceSubscription,
  DndDropTarget,
  DndDropTargetSubscription,
} from './dnd.interfaces';

interface DndDropTargetEvent {
  selector: string;
  target: DndDropTarget;
}

const getDropTargetGroupKey = (selector: string, engine: string) => `${selector}:${engine}`;

/**
 * {@link DndManager} manages all available drag sources and drop targets. Also it initiates communication between them.
 *
 * Different UI components support DnD operations. Each component can have its own implementation of DnD:
 * <ul>
 *   <li>based on native drag-and-drop browser capabilities</li>
 *   <li>emulated drag-and-drop capabilities by placing layer having fixed/absolute position over the rest page content
 *     and handling mouse/touch events manually.
 *     This solution does not have common approach: each component has its own implementation of DnD operations.</li>
 * </ul>
 * The problem here is to allow drag-and-drop between such components.
 *
 * The main purpose of this service is to allow DnD operations in heterogenous environment,
 * environment where each separate component supports DnD operation (or can support),
 * but uses different technical solution.
 *
 * DndManager manipulates by two kinds of entities: <code>DragSource</code> and <code>DropTarget</code>.
 * <code>DragSource</code> is a component which initiates dragging operation,
 * while <code>DropTarget</code> is a component which serves as a target for drag operation.
 *
 * Each <code>DragSource</code> or <code>DropTarget</code> belongs to specific <code>selector</code>
 * and <code>engine</code>.
 * Dragging between <code>DragSource</code> and <code>DropTarget</code> is possible only if they have the same
 * <code>selector</code> and <code>engine</code>.
 * <ul>
 *   <li><code>engine</code> defines technical stack used by DnD operation.
 *     For example, dragging initiated by tree component MUST BE implemented using native drag-and-drop capabilities,
 *     but dragging initiated by ag-grid component MUST be implemented by providing implementation of some internal
 *     ag-grid interfaces. This means dragging uses different technical stack (engines) under the hood.</li>
 *   <li><code>selector</code> defines direction of DnD operation.
 *     For example, developer has 3 grids and it needs to allow dragging from 1st to 2nd and from 2nd to 3rd,
 *     but dragging between 1st and 3rd is forbidden. In this case it can define separate <code>selector</code>s
 *     for 1st/2nd and for 2nd/3rd groups</li>
 * </ul>
 *
 * DndManager allows to create/remove <code>DragSource</code>s and register/unregister <code>DropTarget</code>s.
 * Each component interested in DnD operations can listen for changes in <code>DragSource<code>s
 * or <code>DropTarget<code>s if the satisfy given <code>selector</code> and/or <code>engine</code>,
 * look at {@link #listenDragSources} and {@link #listenDropTargets}.
 */
@Injectable()
export class DndManager {
  private sources$$ = new BehaviorSubject<Hash<DndDragSource[]>>({});
  private sourceAdded$$ = new Subject<DndDragSource>();
  private sourceRemoved$$ = new Subject<DndDragSource>();
  private dragging$$ = new Subject<DndDragEvent>();

  private targetAdded$$ = new Subject<DndDropTargetEvent>();
  private targetRemoved$$ = new Subject<DndDropTargetEvent>();
  private targets$$ = new BehaviorSubject<Hash<DndDropTarget[]>>({});

  constructor() {
    this.dragging$$.subscribe((event) => {
      const { type, source: { selector, engine } } = event;
      const satisfiedTargets = this.targets$$.value[getDropTargetGroupKey(selector, engine)] || [];
      satisfiedTargets.forEach((target) => {
        switch (type) {
          case 'start':
            target.onDragStart(event);
            break;
          case 'stop':
            target.onDragStop(event);
            break;
          default:
            break;
        }
      });
    });
  }

  /**
   * Creates <code>DragSource</code> for specific <code>selector</code> and <code>engine</code>
   * and notifies all listening subscriptions.
   */
  createDragSource(selector: string, engine: string): DndDragSource {
    const source: DndDragSource = {
      selector, engine,
      startDrag: (element: any) => this.dragging$$.next({ type: 'start', source, element }),
      stopDrag: () => this.dragging$$.next({ type: 'stop', source, element: void 0 }),
    };

    const group = this.sources$$.value[selector] || [];
    if (!group.includes(source)) {
      this.sources$$.next({
        ...this.sources$$.value,
        [selector]: group.concat([source]),
      });
    }

    return source;
  }

  /**
   * Removes given <code>DragSource</code> and notifies all listening subscriptions.
   */
  removeDragSource(source: DndDragSource): void {
    const group = this.sources$$.value[source.selector] || [];

    if (group.includes(source)) {
      this.sources$$.next({
        ...this.sources$$.value,
        [source.selector]: without(group, source),
      });
    }
  }

  /**
   * Create subscription for <code>DragSource</code>-related events.
   */
  listenDragSources(selector: string): DndDragSourceSubscription {
    return {
      sources: this.sources$$.pipe(
        map$((sources) => sources[selector] || []),
        distinctUntilChanged$()),
      sourceAdded: this.sourceAdded$$.pipe(
        filter$((source) => source.selector === selector)),
      sourceRemoved: this.sourceRemoved$$.pipe(
        filter$((source) => source.selector === selector)),
    };
  }

  /**
   * Creates <code>DropTarget</code> for specific <code>selector</code> and <code>engine</code>
   * and notifies all listening subscriptions.
   */
  addDropTarget(selector: string, target: DndDropTarget): void {
    const groupKey = getDropTargetGroupKey(selector, target.engine);
    const group = this.targets$$.value[groupKey] || [];

    if (!group.includes(target)) {
      this.targets$$.next({
        ...this.targets$$.value,
        [groupKey]: group.concat([target]),
      });
    }
  }

  /**
   * Removes given <code>DropTarget</code> and notifies all listening subscriptions.
   */
  removeDropTarget(selector: string, target: DndDropTarget): void {
    const groupKey = getDropTargetGroupKey(selector, target.engine);
    const group = this.targets$$.value[groupKey] || [];

    if (group.includes(target)) {
      this.targets$$.next({
        ...this.targets$$.value,
        [groupKey]: without(group, target),
      });
    }
  }

  /**
   * Create subscription for <code>DropTarget</code>-related events.
   */
  listenDropTargets(selector: string, engine: string): DndDropTargetSubscription {
    return {
      targets: this.targets$$.pipe(
        map$((targets) => targets[getDropTargetGroupKey(selector, engine)] || []),
        distinctUntilChanged$()),
      targetAdded: this.targetAdded$$.pipe(
        filter$((event) => event.selector === selector && event.target.engine === engine),
        map$((event) => event.target)),
      targetRemoved: this.targetRemoved$$.pipe(
        filter$((event) => event.selector === selector && event.target.engine === engine),
        map$((event) => event.target)),
    };
  }
}
