/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { MessageSeverity } from '@gh/core-messages';
import { TranslationParams } from '@gh/core-mls';

import { eq, isNil, isString } from 'lodash';
import { Confirmation, ConfirmationDialogConfig } from './components/confirmation-dialog-base.component';

export const yesAnswer = (answer: number): boolean => eq(answer, Confirmation.Yes);
export const yesAsDefaultAnswer = (answer: number): boolean => eq(answer, Confirmation.Yes) || isNil(answer);
export const noAnswer = (answer: number): boolean => eq(answer, Confirmation.No);
export const noAsDefaultAnswer = (answer: number): boolean => eq(answer, Confirmation.No) || isNil(answer);
export const cancelAnswer = (answer: number): boolean => eq(answer, Confirmation.Cancel);
export const cancelAsDefaultAnswer = (answer: number): boolean => eq(answer, Confirmation.Cancel) || isNil(answer);

export const confirmationWith = (source: ConfirmationDialogConfig, additional: ConfirmationDialogConfig) =>
  ({ ...source, ...additional });

export const yesNoConfirmation = (label: string, translationParams?: TranslationParams) => ({
  messageId: label,
  messageParams: translationParams,
  buttons: [Confirmation.Yes, Confirmation.No],
  defaultButton: Confirmation.No,
});

export const yesCancelConfirmation = (label: string, translationParams?: TranslationParams) => ({
  messageId: label,
  messageParams: translationParams,
  buttons: [Confirmation.Yes, Confirmation.Cancel],
  defaultButton: Confirmation.Cancel,
});

export const yesNoCancelConfirmation = (label: string, translationParams?: TranslationParams) => ({
  messageId: label,
  messageParams: translationParams,
  buttons: [Confirmation.Yes, Confirmation.No, Confirmation.Cancel],
  defaultButton: Confirmation.Cancel,
});

export const errorMessage = (label: string, translationParams?: TranslationParams) => ({
  severity: MessageSeverity.Error,
  messageId: label,
  messageparams: translationParams,
});

export const successMessage = (label: string, translationParams?: TranslationParams) => ({
  severity: MessageSeverity.Success,
  messageId: label,
  messageparams: translationParams,
});

export function isDetailedMessage(message: any): boolean {
  return !isString(message) && !isNil(message);
}

export function splitByLines(text: string): string {
  return text.replace(/\n/g, '<br>');
}

export function responsiveDialogOverlayPaneClass(size: 'sm' = 'sm'): string[] {
  return ['responsive-dialog-overlay-pane', `responsive-dialog-overlay-pane-${size}`];
}
