/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, Injector, NgZone } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ICONS_URL } from '@gh/common-shared';

import { DEFAULT_LOCALE } from '@gh/config';

import { AuthProvider, AuthService, AuthState } from '@gh/core-auth';
import { Message, MessageBasket, MessageSeverity } from '@gh/core-messages';
import { Locale, TranslationMapLoader } from '@gh/core-mls';
import { TypeService } from '@gh/core-type';
import { DialogService } from '@gh/core-ui';
import { CustomerUserContext } from '@gh/customer-shared';

import { getCustomerBootstrapParams } from '../customer-app.utils';

@Injectable()
export class CustomerBootstrapService {
  constructor(private injector: Injector,
              private sanitizer: DomSanitizer,
              private translationLoader: TranslationMapLoader,
              private userContext: CustomerUserContext,
              private authService: AuthService,
              private authProvider: AuthProvider,
              private iconRegistry: MatIconRegistry,
              private ngZone: NgZone) {
    iconRegistry.addSvgIconSetInNamespace('gh', sanitizer.bypassSecurityTrustResourceUrl(ICONS_URL));

    this.authService.onAuthState.subscribe((authState) => {
      if (authState !== AuthState.LoggedIn) {
        this.setPageStatus('is-app-wait-for-login');
      }
    });

    // set this service on global object to be available on the page (for index.html)
    window['bootstrap'] = this;
  }

  get dialogService(): DialogService {
    return this.injector.get(DialogService);
  }

  get router(): Router {
    return this.injector.get(Router);
  }

  login(): Promise<any> {
    // we use here NgZone, because this method can be called outside of angular zone
    return this.ngZone.run(() => this.authProvider.login());
  }

  load(): Promise<any> {
    return this.userContext.init(getCustomerBootstrapParams()).toPromise()
      .then((userData) =>
        Promise.all([
          this.loadTranslation(userData.locale),
        ]).then(() => userData))
      .then((userData) => {
        // init types after localization subsystem is ready
        this.injector.get(TypeService);
        this.setPageStatus('is-app-loaded');

        return userData;
      }).catch((error) => {
        if (error instanceof Error) {
          return Promise.reject(error);
        }

        return this.loadTranslation(Locale.createFromStandard(DEFAULT_LOCALE)).then(() => {
          if (error instanceof MessageBasket) {
            const message = error.at(0) || Message.error('msg_internalservererror');
            this.dialogService.message({
              severity: MessageSeverity.Error,
              messageId: message.key,
              messageParams: message.params,
              disableClose: true,
            }).subscribe(() => this.userContext.logout());
          }

          return Promise.resolve();
        });
      });
  }

  private loadTranslation(locale: Locale): Promise<any> {
    return this.translationLoader.load(locale);
  }

  private setPageStatus(className: string): void {
    document.body.classList.remove('is-app-loading', 'is-app-wait-for-login', 'is-app-loaded');
    document.body.classList.add(className);
  }
}
