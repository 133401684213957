/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';
import { API_SB_URL } from '@gh/config';

@Injectable()
export class ConfigurationHttpRequestInterceptor implements HttpInterceptor {
  private _headers: Hash<string> = {};
  private headerKeys: string[] = [];

  set headers(headers: Hash<string>) {
    this._headers = headers;
    this.headerKeys = Object.keys(headers);
  }

  setHeader(key: string, value: string): void {
    this._headers[key] = value;
    this.headerKeys = uniq(this.headerKeys.concat([key]));
  }

  updateUrl(isSandbox: boolean, url: string): string {
    if (isSandbox) {
      const modifiedUrlArr = url.split('/');
      if (modifiedUrlArr.includes('bp')) {
        modifiedUrlArr[2] = API_SB_URL;
        return modifiedUrlArr.join('/');
      } else {
        return url;
      }
    } else {
      return url;
    }
  }

  intercept(originalReq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedHeaders = this.headerKeys.reduce(
      (headers, key) => headers.set(key, this._headers[key]),
      originalReq.headers);

    const isSandbox = this.headerKeys.includes('X-SandBox-Mode');
    const modifiedUrl =  this.updateUrl(isSandbox, originalReq.url);

    return next.handle(originalReq.clone({
      headers: modifiedHeaders,
      url: modifiedUrl,
    }));
  }
}
