/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';
import { pluck$ } from '@gh/rx/operators';
import { Observable } from 'rxjs';

@Pipe({ name: 'pluck$', pure: true })
export class Pluck$Pipe implements PipeTransform {
  transform(ob$?: Observable<any>, ...properties: string[]): any {
    if (!ob$) {
      return ob$;
    }

    return ob$.pipe(pluck$(...properties));
  }
}
