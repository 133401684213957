/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ComponentType } from '@angular/cdk/portal';
import { Inject, Injectable, InjectionToken, Injector, Optional, Type } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LazyRegistry } from '@gh/core-lazy';

import { MessageSeverity } from '@gh/core-messages';
import { TranslationParams } from '@gh/core-mls';
import { mergeClassNames } from '@gh/core-util';
import { combineLatest$, of$ } from '@gh/rx';
import { map$, share$, switchMap$ } from '@gh/rx/operators';

import * as invariant from 'invariant';

import { assign, defaults, find, isEqual, isFunction, isNil, noop, remove } from 'lodash';
import { Observable } from 'rxjs';
import { Confirmation, ConfirmationDialogConfig } from '../components/confirmation-dialog-base.component';
import { MessageDialogConfig } from '../components/message-dialog-base.component';

import { DialogInput } from '../dialog-input';
import { DialogRef } from '../dialog-ref';
import { DialogRouteConfig } from '../dialog-route';
import { responsiveDialogOverlayPaneClass } from '../dialog.utils';

export interface DialogHooks {
  open(dialog: MatDialogRef<any>): void;

  close(dialog: MatDialogRef<any>): void;
}

export interface DialogStandardSet {
  messageDialogType: Type<any>;
  confirmationDialogType: Type<any>;
}

export const DIALOG_HOOKS = new InjectionToken<DialogHooks>('DialogHooks');
export const DIALOG_STANDARD_SET = new InjectionToken<DialogStandardSet>('DialogStandardSet');

type OpenedDialogState = {
  ref: DialogRef<any>;
  route: DialogRouteConfig<any>;
  input: any;
};

/**
 * Each lazy routed module use separate instance of {@link DialogService}.
 * {@link DialogService} holds its state in this service shared between all lazy routed modules.
 */
@Injectable()
export class DialogServiceState {
  private _openedDialogs: OpenedDialogState[] = [];

  get openedDialogs(): DialogRef<any>[] {
    return this._openedDialogs.map(({ ref }) => ref);
  }

  /**
   * Adds {@link DialogRef} into internal set of dialog refs
   *
   * @param ref
   * @param route
   * @param input
   */
  addDialogRef(ref: DialogRef<any>, route: DialogRouteConfig<any>, input: any): void {
    this._openedDialogs.push({ ref, route, input });
  }

  /**
   * Removes {@link DialogRef} from internal set of dialog refs
   *
   * @param refToBeRemoved
   */
  removeDialogRef(refToBeRemoved: DialogRef<any>): void {
    remove(this._openedDialogs, ({ ref }) => ref === refToBeRemoved);
  }

  /**
   * Tries to find {@link DialogRef} for the specified route.
   *
   * @param route
   * @param input
   * @returns {T}
   */
  findOpenedDialog(route: DialogRouteConfig<any>, input: any): Maybe<DialogRef<any>> {
    const found = find<OpenedDialogState>(this._openedDialogs, { route });

    return !isNil(found) && isEqual(input, found.input) ? found.ref : void 0;
  }
}

/**
 * {@link DialogService} covers common dialog operations.
 */
@Injectable()
export class DialogService {
  constructor(private injector: Injector,
              private dialogService: MatDialog,
              private state: DialogServiceState,
              @Optional() private lazyRegistry: LazyRegistry,
              @Optional() @Inject(DIALOG_STANDARD_SET) private standardSet: DialogStandardSet,
              @Optional() @Inject(DIALOG_HOOKS) private readonly hooks: DialogHooks) {
    this.hooks = defaults({}, this.hooks || {}, {
      open: noop,
      close: noop,
    });
  }

  /**
   * Returns all currently opened dialogs
   */
  get openedDialogs(): DialogRef<any>[] {
    return this.state.openedDialogs;
  }

  /**
   * Shows message box having given configuration. Configuration defines severity and text of message.
   *
   * @param config
   * @returns {Observable<any>}
   */
  message(config: MessageDialogConfig): Observable<boolean> {
    const dialog = this.dialogService.open(this.standardSet.messageDialogType, this.decorateDialogConfig({
      disableClose: config.disableClose,
      panelClass: mergeClassNames(config.panelClass, responsiveDialogOverlayPaneClass()),
    }));
    dialog.componentInstance.input = config;

    return dialog.afterClosed().pipe(map$(() => true));
  }

  /**
   * Shows success message box with the given text.
   *
   * @param messageId
   * @param messageParams
   * @returns {Observable<boolean>}
   */
  success(messageId: string, messageParams?: TranslationParams): Observable<boolean> {
    return this.message({
      severity: MessageSeverity.Success,
      messageId,
      messageParams,
    });
  }

  /**
   * Shows warning message box with the given text.
   *
   * @param messageId
   * @param messageParams
   * @returns {Observable<boolean>}
   */
  warning(messageId: string, messageParams?: TranslationParams): Observable<boolean> {
    return this.message({
      severity: MessageSeverity.Warning,
      messageId,
      messageParams,
    });
  }

  /**
   * Shows error message box with the given text
   * @param messageId
   * @param messageParams
   * @returns {Observable<boolean>}
   */
  error(messageId: string, messageParams?: TranslationParams): Observable<boolean> {
    return this.message({
      severity: MessageSeverity.Error,
      messageId,
      messageParams,
    });
  }

  /**
   * Shows confirmation dialog. Confirmation dialog asks an question and waits for user's answer.
   * To answer user has to push one of available buttons: Yes, No or Cancel.
   *
   * Config object (@link ConfirmationDialogConfig} passed into this function defines:
   * - set of available buttons: yes, no and cancel.
   * - primary button. Primary button is the main focused button.
   * - text of question
   *
   * @param config
   * @returns {Observable<R>}
   */
  confirmation(config: ConfirmationDialogConfig): Observable<Confirmation> {
    invariant(config.message || config.messageId, 'Message for confirmation dialog is not provided');
    return this.openWithInput(
      this.standardSet.confirmationDialogType,
      config,
      this.decorateDialogConfig({
        disableClose: true,
        autoFocus: false,
        panelClass: mergeClassNames('responsive-dialog-overlay-pane-center', responsiveDialogOverlayPaneClass()),
      }))
      .afterClosed();
  }

  /**
   * Opens dialog passed into this method.
   *
   * @param component
   * @param config
   * @returns {MatDialogRef<T>}
   */
  open<T>(component: ComponentType<T>, config?: MatDialogConfig): MatDialogRef<T> {
    return this.dialogService.open(component, this.decorateDialogConfig(config));
  }

  /**
   * This method creates dialog based on the given component and sets its input parameters.
   *
   * @param component
   * @param input
   * @param config
   * @returns {DialogRef<C>}
   */
  openWithInput<C extends DialogInput<I>, I>(component: ComponentType<C>,
                                             input: I,
                                             config?: MatDialogConfig): DialogRef<C>;
  /**
   * This method creates dialog based on the specified route configuration and sets its input parameters.
   *
   * @param route
   * @param input
   * @param config
   * @returns {DialogRef<C>}
   */
  openWithInput<C extends DialogInput<I>, I>(route: DialogRouteConfig<C>,
                                             input: I,
                                             config?: MatDialogConfig): DialogRef<C>;
  openWithInput(componentOrRoute: ComponentType<any> | DialogRouteConfig<any>,
                input: any,
                config?: MatDialogConfig): DialogRef<any> {
    const route = isFunction(componentOrRoute) ? { component: componentOrRoute } : componentOrRoute;

    return this.requestDialog(<DialogRouteConfig<any>>route, input, config);
  }

  /**
   * Closes each opened dialog according to State
   */
  closeDialogs(): void {
    this.dialogService.closeAll();
  }

  /**
   * Opens new dialog if it is necessary.
   * This service tracks all opened dialog and does not allow to open dialog for route having the same input.
   *
   * @param route
   * @param input
   * @param config
   * @returns {DialogRef}
   */
  private requestDialog(route: DialogRouteConfig<any>, input: any, config?: MatDialogConfig): DialogRef<any> {
    const dialogRef = this.state.findOpenedDialog(route, input);

    if (dialogRef) {
      dialogRef.close();
    }

    return this.openDialog(route, input, config);
  }

  /**
   * Opens new dialog and adds created {@link DialogRef} into internal set of {@link DialogRef}s
   * (in order to track all opened dialogs).
   *
   * @param route
   * @param input
   * @param config
   * @returns {DialogRef}
   */
  private openDialog(route: DialogRouteConfig<any>, input: any, overridenConfig?: MatDialogConfig): DialogRef<any> {
    const { lazy } = route;
    // take initial route or lazy load it from the registry
    const route$ = lazy ? this.lazyRegistry.load<DialogRouteConfig<any>>(lazy.module, lazy.name).pipe(share$())
      : of$({ injector: this.injector, symbol: route });
    // call resolve service if it exists
    const resolve$ = route$.pipe(switchMap$(({ symbol: loadedRoute, injector }) => {
      const { resolve } = loadedRoute;

      if (isNil(resolve)) {
        return [{}];
      }

      const resolver = injector.get(resolve);
      return resolver.resolve({
        routeConfig: loadedRoute,
        input,
      });
    }));

    let nativeRef: Maybe<MatDialogRef<any>> = undefined;

    // show dialog and construct native MatDialogRef
    const nativeDialogRef$ = combineLatest$(route$, resolve$).pipe(
      map$(([{ symbol: loadedRoute, injector }, data]) => {
        const { component, config: defaultConfig } = loadedRoute;
        const config = assign({}, defaultConfig, overridenConfig);

        // tslint:disable-next-line:no-non-null-assertion
        nativeRef = injector.get(MatDialog).open(component!, this.decorateDialogConfig({
          ...(config || {}),
          data: { ...(data as {}), input },
        }));
        nativeRef.componentInstance.input = input;

        this.hooks.open(nativeRef);
        return nativeRef;
      }));

    const dialogRef = new DialogRef(nativeDialogRef$);

    const removeRef = () => {
      if (nativeRef) {
        this.hooks.close(nativeRef);
      }
      this.state.removeDialogRef(dialogRef);
    };

    this.state.addDialogRef(dialogRef, route, input);

    dialogRef.afterClosed().subscribe(noop, removeRef, removeRef);

    return dialogRef;
  }

  /**
   * Generates {@link MatDialogConfig} for the next dialog.
   */
  private decorateDialogConfig(config?: MatDialogConfig): MatDialogConfig {
    // make backdrop transparent for 2nd and next layers
    // it is necessary to avoid black background when user sometimes open too much dialogs [GJG-1050]
    // FIXME: this fix is inappropriate, because it has very bad UX. We have to think about this problem later.
    // return this.dialogService.openDialogs.length > 0 ? {
    //   backdropClass: 'dialog-overlay-transparent',
    // } : {};
    return {
      ...config,
      panelClass: config && config.panelClass || 'dialog-overlay-pane',
    };
  }
}
