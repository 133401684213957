import { MessageSeverity } from '@gh/core-messages';
import { isDetailedMessage, splitByLines } from '../dialog.utils';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
import * as i2 from "@angular/material/dialog";
var MessageDialogBaseComponent = /** @class */ (function () {
    function MessageDialogBaseComponent(translationService, dialogRef, elementRef, renderer) {
        this.translationService = translationService;
        this.dialogRef = dialogRef;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.detailed = false;
    }
    Object.defineProperty(MessageDialogBaseComponent.prototype, "typeInfo", {
        get: function () {
            var severity = this.input.severity;
            return severity === MessageSeverity.Info;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageDialogBaseComponent.prototype, "typeSuccess", {
        get: function () {
            var severity = this.input.severity;
            return severity === MessageSeverity.Success;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageDialogBaseComponent.prototype, "typeErrorOrWarning", {
        get: function () {
            var severity = this.input.severity;
            return severity === MessageSeverity.Error || severity === MessageSeverity.Warning;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageDialogBaseComponent.prototype, "btnColor", {
        get: function () {
            var btnColor = this.input.btnColor;
            if (btnColor) {
                return btnColor;
            }
            return this.typeSuccess || this.typeInfo ? 'primary' : 'accent';
        },
        enumerable: true,
        configurable: true
    });
    MessageDialogBaseComponent.prototype.ngOnInit = function () {
        var _a = this.input, messageId = _a.messageId, messageParams = _a.messageParams, message = _a.message;
        if (messageId) {
            var rawMessage = this.translationService.getRaw(messageId);
            if (isDetailedMessage(rawMessage)) {
                this.title = splitByLines(this.translationService.get([messageId, 'title'].join('.'), messageParams));
                // tslint:disable:max-line-length
                this.description = splitByLines(this.translationService.get([messageId, 'description'].join('.'), messageParams));
                this.detailed = true;
            }
            else {
                this.message = splitByLines(this.translationService.get(messageId, messageParams));
            }
        }
        else if (message) {
            this.message = splitByLines(message);
        }
        this.renderer.addClass(this.elementRef.nativeElement, this.detailed
            ? 'message-dialog--detailed' : 'message-dialog--simple');
    };
    MessageDialogBaseComponent.ɵfac = function MessageDialogBaseComponent_Factory(t) { return new (t || MessageDialogBaseComponent)(i0.ɵɵdirectiveInject(i1.TranslationService), i0.ɵɵdirectiveInject(i2.MatDialogRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2)); };
    MessageDialogBaseComponent.ɵdir = i0.ɵɵdefineDirective({ type: MessageDialogBaseComponent });
    return MessageDialogBaseComponent;
}());
export { MessageDialogBaseComponent };
