/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ValidationErrors } from '@angular/forms';
import { arrayOf, isResponseHttpEvent } from '@gh/core-data';
import { throwError$ } from '@gh/rx';
import * as invariant from 'invariant';
import { fromPairs } from 'lodash';
import { Observable } from 'rxjs';
import { BUSINESS_ERROR_KEYS, statusToErrorKey, TECHNICAL_ERROR_KEYS } from './error-keys';
import { Message, MessageSeverity } from './message';

import { isMessageBasket, MessageBasket } from './message-basket';
import { MessageMapper } from './message.mapper';

/**
 * Allows to remap message keys (<code>{ fromKey: toKey }</code>).
 * Creates a function which can be used as parameter for Observable.catch operator.
 */
export function mapErrorKeys<T>(errorCodeMap: Hash<string>): (errors: any) => Observable<T> {
  return (errors: any) =>
    throwError$(isMessageBasket(errors)
      ? errors.map((message) =>
        errorCodeMap.hasOwnProperty(message.key)
          ? Message.create(errorCodeMap[message.key], message.severity, message.params)
          : message)
      : errors);
}

export function basketToFormErrors(basket: MessageBasket): Maybe<ValidationErrors> {
  if (basket.length === 0) {
    return;
  }

  return fromPairs(basket.messages.map((message: Message) => [message.key, {
    messageId: message.key,
    severity: message.severity,
    ...message.params,
  }]));
}

export function createMessageBasketFromResponse(response: HttpResponse<any> | HttpErrorResponse): MessageBasket {
  invariant(
    response.status >= 400 && response.status < 600,
    `Response from '${response.url}' has wrong status ${response.status}`,
  );

  const messages: Message[] = [];
  const serialized = isResponseHttpEvent(response) ? response.body : response.error;

  try {
    let bodyMessages: Message[];
    if (serialized instanceof Array) {
      bodyMessages = arrayOf(MessageMapper).deserialize(serialized);
    } else if (serialized) {
      bodyMessages = [MessageMapper.deserialize(serialized)];
    } else {
      bodyMessages = [];
    }

    messages.push.apply(messages, bodyMessages);
  } catch (err) {
    // nothing to do
  }

  if (messages.length === 0) {
    messages.push(createStatusMessage(response.status, response.url || ''));
  }

  return new MessageBasket(messages);
}

function createStatusMessage(status: number, url: string): Message {
  let errorKey = statusToErrorKey(status);
  if (!errorKey) {
    if (status < 500) {
      errorKey = BUSINESS_ERROR_KEYS.Unknown;
    } else if (status < 600) {
      errorKey = TECHNICAL_ERROR_KEYS.Unknown;
    } else {
      invariant(errorKey, `Error key cannot be undefined: URL: '${url}', status: ${status}`);
      throw new Error(`Error key cannot be undefined: URL: '${url}', status: ${status}`);
    }
  }

  return new Message(errorKey, MessageSeverity.Error);
}

export function mapMessageParams(message: Message, mapParams: IdentityFn<Hash<any>>): Message {
  return new Message(message.key, message.severity, mapParams(message.params));
}
