var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable, Injector } from '@angular/core';
import { MessageBasket } from '@gh/core-messages';
import { NotificationService, WaitingMode, WaitingService } from '@gh/core-ui';
import { expandObservable } from '@gh/core-util';
import { throwError$ } from '@gh/rx';
import { catchError$, delay$, map$ } from '@gh/rx/operators';
import { isFunction } from 'lodash';
import { AppRouter } from './app-router.service';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-ui";
import * as i2 from "./app-router.service";
function callInitView(service, selector) {
    var args = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args[_i - 2] = arguments[_i];
    }
    if (isFunction(selector)) {
        return selector.apply(void 0, __spreadArrays([service], args));
    }
    else {
        return service[selector].apply(service, args);
    }
}
var InitViewResolver = /** @class */ (function () {
    function InitViewResolver(injector, waitingService, notificationService, router) {
        this.injector = injector;
        this.waitingService = waitingService;
        this.notificationService = notificationService;
        this.router = router;
    }
    InitViewResolver.prototype.resolve = function (route, state) {
        var _this = this;
        var initView = route.data['initView'];
        if (initView) {
            var _a = initView.service
                ? initView
                : { service: initView, selector: void 0 }, service = _a.service, selector = _a.selector;
            var serviceInstance = this.injector.get(service);
            var ob$ = expandObservable(callInitView(serviceInstance, selector || 'initView', route, state));
            var obWithWaiting$ = initView.noWaiting ?
                ob$
                : ob$.pipe((this.waitingService.operator(WaitingMode.Background)));
            return obWithWaiting$.pipe(catchError$(function (errors) {
                if (errors instanceof MessageBasket && errors.has('msg_resource_not_found')) {
                    _this.router.navigateByUrl('/404');
                    return [];
                }
                else {
                    _this.notificationService.processAll(errors);
                    return throwError$(errors);
                }
            }), 
            // angular 4.1.2 has a bug: router is crashed when resolver service resolves synchronously
            delay$(0));
        }
        else {
            return void 0;
        }
    };
    InitViewResolver.ɵfac = function InitViewResolver_Factory(t) { return new (t || InitViewResolver)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.WaitingService), i0.ɵɵinject(i1.NotificationService), i0.ɵɵinject(i2.AppRouter)); };
    InitViewResolver.ɵprov = i0.ɵɵdefineInjectable({ token: InitViewResolver, factory: InitViewResolver.ɵfac });
    return InitViewResolver;
}());
export { InitViewResolver };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(InitViewResolver, [{
        type: Injectable
    }], function () { return [{ type: i0.Injector }, { type: i1.WaitingService }, { type: i1.NotificationService }, { type: i2.AppRouter }]; }, null); })();
var InitDialogViewResolver = /** @class */ (function () {
    function InitDialogViewResolver(injector, waitingService, notificationService) {
        this.injector = injector;
        this.waitingService = waitingService;
        this.notificationService = notificationService;
    }
    InitDialogViewResolver.prototype.resolve = function (route) {
        var _this = this;
        var initView = route.routeConfig.data['initView'];
        if (initView) {
            var _a = initView.service
                ? initView
                : { service: initView, selector: void 0 }, service = _a.service, selector = _a.selector;
            var serviceInstance = this.injector.get(service);
            var ob$ = expandObservable(callInitView(serviceInstance, selector || 'initDialogView', route));
            var obWithWaiting$ = initView.noWaiting ?
                ob$
                : ob$.pipe((this.waitingService.operator(WaitingMode.Background)));
            return obWithWaiting$.pipe(map$(function (data) { return ({
                init: data,
            }); }), catchError$(function (errors) {
                _this.notificationService.processAll(errors);
                return throwError$(errors);
            }));
        }
        else {
            return void 0;
        }
    };
    InitDialogViewResolver.ɵfac = function InitDialogViewResolver_Factory(t) { return new (t || InitDialogViewResolver)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.WaitingService), i0.ɵɵinject(i1.NotificationService)); };
    InitDialogViewResolver.ɵprov = i0.ɵɵdefineInjectable({ token: InitDialogViewResolver, factory: InitDialogViewResolver.ɵfac });
    return InitDialogViewResolver;
}());
export { InitDialogViewResolver };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(InitDialogViewResolver, [{
        type: Injectable
    }], function () { return [{ type: i0.Injector }, { type: i1.WaitingService }, { type: i1.NotificationService }]; }, null); })();
