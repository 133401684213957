/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { parametrizeUrl } from '@gh/core-util';
import { of$ } from '@gh/rx';
import { map$ } from '@gh/rx/operators';
import { constant, isFunction, orderBy } from 'lodash';
import { Observable } from 'rxjs';

export enum SortDirection { Ascending, Descending }

export interface SlicedDataQuerySorting {
  field: string;
  direction: SortDirection;
}

export interface SlicedDataQuery {
  from: number;
  to: number;
  sorting?: SlicedDataQuerySorting;
}

export interface SlicedData<T> {
  from: number;
  to: number;
  data: T[];
  total: number;
}

export interface SlicedDataSource<T> {
  select(query: SlicedDataQuery): Observable<SlicedData<T>>;
}

export const EMPTY_SLICED_DATA: SlicedData<any> = { from: 0, to: 0, data: [], total: 0 };

export function serializeSortBy(sorting: SlicedDataQuerySorting): string {
  return `${sorting.direction === SortDirection.Descending ? '-' : '+'}${sorting.field}`;
}

export function mergeSortByParameter(url: string, sorting?: SlicedDataQuerySorting): string {
  return sorting ? parametrizeUrl(url, { sortBy: serializeSortBy(sorting) }) : url;
}

export function emptySlicedDataSource(): any {
  return {
    select: constant(of$(EMPTY_SLICED_DATA)),
  };
}

export function memoryBasedSlicedDataSource<T>(data: T[]): SlicedDataSource<T> {
  return {
    select: ({ from, to, sorting }) => of$({
      from,
      to,
      total: data.length,
      data: (sorting
        ? orderBy(data, [sorting.field], [sorting.direction === SortDirection.Ascending ? 'asc' : 'desc'])
        : data).slice(from, to),
    }),
  };
}

export function observableBasedSlicedDataSource<T>(data$: Observable<T[]>): SlicedDataSource<T> {
  return {
    select: ({ from, to, sorting }) => data$.pipe(map$((data: T[]) => ({
      from,
      to,
      total: data.length,
      data: (sorting
        ? orderBy(data, [sorting.field], [sorting.direction === SortDirection.Ascending ? 'asc' : 'desc'])
        : data).slice(from, to),
    }))),
  };
}

export interface FilteredDataSource<T, F> extends SlicedDataSource<T> {
  select(query: FilteredDataQuery<F>): Observable<SlicedData<T>>;
}

export interface FilteredDataQuery<F> extends SlicedDataQuery {
  filter?: F;
}

export interface MutableDataSource<T> {
  create(entity: T): void;

  update(entity: T): void;

  remove(entity: T): void;
}

export type DataSource<T> = SlicedDataSource<T> | MutableDataSource<T>;

export function getSlicedData<T>(data: SlicedData<T>): T[] {
  return data.data;
}

export function isMutableDataSource<T>(ds: DataSource<T>): ds is MutableDataSource<T> {
  return isFunction(ds['create']);
}

export function createEmptySlicedDataSource<T>(): SlicedDataSource<T> {
  return { select: () => of$(EMPTY_SLICED_DATA) };
}

// tslint:disable-next-line:max-line-length
export function createFilteredDataSource<T, F>(nested: TransformFn<Maybe<F>, SlicedDataSource<T>>): FilteredDataSource<T, F> {
  return { select: (query) => nested(query.filter).select(query) };
}

export function decorateDataSourceQuery<T>(dataSource: SlicedDataSource<T>,
                                           // tslint:disable-next-line:max-line-length
                                           decorator: TransformFn<SlicedDataQuery, SlicedDataQuery>): SlicedDataSource<T> {
  return { select: (query) => dataSource.select(decorator(query)) };
}

export function mapSlicedData<T, U>(original: SlicedDataSource<T>,
                                    transform: TransformFn<SlicedData<T>, SlicedData<U>>): SlicedDataSource<U> {
  return { select: (query) => original.select(query).pipe(map$(transform)) };
}

export function mapSlicedDataItems<T, U>(original: SlicedDataSource<T>,
                                         transform: (value: T, index: number, array: T[]) => U): SlicedDataSource<U> {
  return mapSlicedData(original, (slicedData) => ({ ...slicedData, data: slicedData.data.map(transform) }));
}

export function mergeListWithFilteredData<T, F>(list: T[],
                                                query: FilteredDataQuery<F>,
                                                // tslint:disable-next-line:max-line-length
                                                queried$: TransformFn<FilteredDataQuery<F>, Observable<T[]>>): Observable<T[]> {
  const beginList = list.slice(query.from, query.to);
  return query.to > list.length
    ? queried$({ ...query, to: query.to - list.length }).pipe(map$((data) => beginList.concat(data)))
    : of$(beginList);
}

export function sliceDataByQuery<T>(list: T[], query: SlicedDataQuery): T[] {
  return list.slice(query.from, query.to);
}
