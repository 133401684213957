<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<gh-ext-dialog closable="" (close)="onClose()">
  <gh-ext-dialog-content fxLayout="column" fxLayoutAlign="start center">
    <div class="m-question">?</div>
    <span class="m-message">{{ message }}</span>
  </gh-ext-dialog-content>

  <gh-ext-dialog-button-bar class="button-bar clearfix">
    <button [ghFocusOnInit]="defaultCancelButton" color="primary" mat-button="" class="m-cancel-btn large ext-to-left" (click)="onCancel()" data-automation-id="confirmation-dialog:cancel" *ngIf="cancelButton==true">{{'lbl_cancel' | translate}}</button>
    <button [ghFocusOnInit]="defaultYesButton" mat-raised-button="" color="primary" class="large ext-to-right m-yes-btn" (click)="onYes()" data-automation-id="confirmation-dialog:yes">{{ yesLabel }}
    </button>
    <button [ghFocusOnInit]="defaultNoButton" mat-button="" color="accent" class="m-no-btn ext-to-right large" (click)="onNo()" data-automation-id="confirmation-dialog:no" *ngIf="noButton==true">{{ noLabel }}</button>
  </gh-ext-dialog-button-bar>
</gh-ext-dialog>