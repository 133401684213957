/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:no-console
export var logWarning = function (message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (process.env.NODE_ENV === 'development') {
        console.warn.apply(console, [message].concat(args));
    }
};
export var logError = function (message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (process.env.NODE_ENV === 'development') {
        console.error.apply(console, [message].concat(args));
    }
};
export var logDebug = function (message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (process.env.NODE_ENV === 'development') {
        console.debug.apply(console, [message].concat(args));
    }
};
