/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { distinctUntilChanged$, map$, scan$ } from '@gh/rx/operators';
import { BehaviorSubject } from 'rxjs';
import { createWaitingProcess } from './waiting-process';
import * as i0 from "@angular/core";
/**
 * Hidden waiting mode means that loading content should be hidden
 * Blocked waiting mode means that loading content should be blocked for user interactions
 */
export var WaitingMode;
(function (WaitingMode) {
    WaitingMode[WaitingMode["None"] = 0] = "None";
    WaitingMode[WaitingMode["Blocked"] = 1] = "Blocked";
    WaitingMode[WaitingMode["Hidden"] = 2] = "Hidden";
    WaitingMode[WaitingMode["Background"] = 3] = "Background";
})(WaitingMode || (WaitingMode = {}));
function isWaiting(state) {
    return state.counter !== 0;
}
function getMode(state) {
    if (state.hidden) {
        return WaitingMode.Hidden;
    }
    else if (state.blocked) {
        return WaitingMode.Blocked;
    }
    else if (state.counter) {
        return WaitingMode.Background;
    }
    else {
        return WaitingMode.None;
    }
}
/**
 * {@link WaitingContext} manages waiting lifecycle (like starting or stopping).
 * {@link WaitingContext}s can be organized in tree. Triggering of waiting for child node
 * triggers waiting for all parent nodes.
 *
 * Waiting can be run in two modes: Hidden and Blocked.
 * - in Hidden mode content should be hidden
 * - in Blocked mode content is blocked for user interactions.
 *
 */
var WaitingContext = /** @class */ (function () {
    /**
     * Creates new waiting context and finds parent waiting context if it exists
     *
     * @param parent
     */
    function WaitingContext(parent) {
        var _this = this;
        this.parent = parent;
        this.actions$$ = new BehaviorSubject({
            counter: 0,
            hidden: 0,
            blocked: 0,
        });
        this._waiting = false;
        this._mode = WaitingMode.Blocked;
        this.waiting$ = this.actions$$.pipe(scan$(function (state, next) { return ({
            counter: state.counter + next.counter,
            hidden: state.hidden + next.hidden,
            blocked: state.blocked + next.blocked,
        }); }, { counter: 0, hidden: 0, blocked: 0 }), map$(function (state) { return ({
            waiting: isWaiting(state),
            mode: getMode(state),
        }); }));
        this.waiting$.subscribe(function (event) {
            _this._waiting = event.waiting;
            _this._mode = event.mode;
        });
    }
    Object.defineProperty(WaitingContext.prototype, "waiting", {
        /**
         * Returns true if waiting is running currently, otherwise false
         *
         * @returns {boolean}
         */
        get: function () {
            return this._waiting;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WaitingContext.prototype, "mode", {
        /**
         * Returns current waiting mode
         *
         * @returns {WaitingMode}
         */
        get: function () {
            return this._mode;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Returns stream notifying about activity of specific waiting mode
     */
    WaitingContext.prototype.modeAsStream = function (targetMode) {
        return this.waiting$.pipe(map$(function (_a) {
            var mode = _a.mode, waiting = _a.waiting;
            return mode === targetMode && waiting;
        }), distinctUntilChanged$());
    };
    /**
     * Starts waiting process and returns {@link WaitingProcess} to manage its lifecycle.
     *
     * @param name
     * @param mode
     * @returns {{stop: (()=>undefined)}}
     */
    WaitingContext.prototype.start = function (mode, name) {
        var _this = this;
        if (name === void 0) { name = 'default'; }
        var parentProcess = undefined;
        this.actions$$.next({
            counter: 1,
            hidden: mode === WaitingMode.Hidden ? 1 : 0,
            blocked: mode === WaitingMode.Blocked ? 1 : 0,
        });
        if (this.parent) {
            parentProcess = this.parent.start(WaitingMode.Blocked, name && name + "-parent");
        }
        return createWaitingProcess(name, function () {
            if (parentProcess) {
                parentProcess.stop();
            }
            _this.actions$$.next({
                counter: -1,
                hidden: mode === WaitingMode.Hidden ? -1 : 0,
                blocked: mode === WaitingMode.Blocked ? -1 : 0,
            });
        });
    };
    WaitingContext.ɵfac = function WaitingContext_Factory(t) { return new (t || WaitingContext)(i0.ɵɵinject(WaitingContext, 12)); };
    WaitingContext.ɵprov = i0.ɵɵdefineInjectable({ token: WaitingContext, factory: WaitingContext.ɵfac });
    return WaitingContext;
}());
export { WaitingContext };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(WaitingContext, [{
        type: Injectable
    }], function () { return [{ type: WaitingContext, decorators: [{
                type: Optional
            }, {
                type: SkipSelf
            }] }]; }, null); })();
