/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, TemplateRef } from '@angular/core';

/**
 * This directive allows to specify template for autocomplete option displayed in the overlay.
 *
 * For example,
 * <code>
 *   <gh-autocomplete
 *     ...
 *     optionsTextKey="name"
 *     (loadMore)="loadOptions($event)">
 *     <ng-template ghAutocompleteOption let-option>
 *       <span [class.text-bold]="option.isPreferred">{{ option.name }}</span>
 *     </ng-template>
 *   </gh-autocomplete>
 * </code>
 */
@Directive({
  selector: '[ghAutocompleteOption]',
})
export class AutocompleteOptionDirective {
  constructor(public templateRef: TemplateRef<any>) {
  }
}
