/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Directive, ElementRef, Input, NgZone } from '@angular/core';
import { first$ } from '@gh/rx/operators';
import { isNil } from 'lodash';
import * as i0 from "@angular/core";
/**
 * This directive move focus into assigned element.
 * It is important to note that this directive moves focus only on initialization (how its name states).
 */
var FocusDirective = /** @class */ (function () {
    function FocusDirective(elementRef, ngZone) {
        this.elementRef = elementRef;
        this.ngZone = ngZone;
        this._focus = true;
        this.needsUpdate = false;
    }
    Object.defineProperty(FocusDirective.prototype, "focus", {
        set: function (value) {
            this._focus = !!value;
            this.needsUpdate = true;
            if (!value && this.subscription) {
                this.subscription.unsubscribe();
                this.subscription = void 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    FocusDirective.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    FocusDirective.prototype.ngAfterViewChecked = function () {
        var _this = this;
        if (this.needsUpdate && this._focus && isNil(this.subscription)) {
            this.subscription = this.ngZone.onMicrotaskEmpty.pipe(first$()).subscribe(function () {
                _this.elementRef.nativeElement.focus();
                _this.subscription = void 0;
                _this.needsUpdate = false;
            });
        }
    };
    FocusDirective.ɵfac = function FocusDirective_Factory(t) { return new (t || FocusDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone)); };
    FocusDirective.ɵdir = i0.ɵɵdefineDirective({ type: FocusDirective, selectors: [["", "ghFocus", ""]], inputs: { focus: ["ghFocus", "focus"] } });
    return FocusDirective;
}());
export { FocusDirective };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FocusDirective, [{
        type: Directive,
        args: [{
                selector: '[ghFocus]',
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }]; }, { focus: [{
            type: Input,
            args: ['ghFocus']
        }] }); })();
