/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { NumberTypeDefenition } from '@gh/core-type';
import { coalesce } from '@gh/core-util';
import { memoize } from 'lodash';

export interface RangedDecimalParamsOptions {
  precision: number;
  scale: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  min?: number;
  max?: number;
  includeMin?: boolean;
  includeMax?: boolean;
}

export interface PositiveDecimalParamsOptions {
  precision: number;
  scale: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  max?: number;
  includeZero?: boolean;
  includeMax?: boolean;
}

export interface RangedIntegralParamsOptions {
  min: number;
  max: number;
  includeMin: boolean;
  includeMax: boolean;
}

const decimalKeyResolver = (precision: number, scale: number) => `${precision},${scale}`;

const getDecimalMax = memoize(
  (precision: number, scale: number) => Math.pow(10, precision - scale) - Math.pow(10, -scale),
  decimalKeyResolver,
);

export function getRangedDecimalParams(options: RangedDecimalParamsOptions): NumberTypeDefenition {
  const { precision, scale } = options;

  return {
    format: {
      minFractionDigits: coalesce(options.minFractionDigits, 0),
      maxFractionDigits: coalesce(options.maxFractionDigits, scale),
    },
    constraints: {
      range: {
        min: coalesce(options.min, -getDecimalMax(precision, scale)),
        includeMin: coalesce(options.includeMin, true),
        max: coalesce(options.max, getDecimalMax(precision, scale)),
        includeMax: coalesce(options.includeMax, true),
      },
    },
  };
}

export function getPositiveDecimalParams(options: PositiveDecimalParamsOptions): NumberTypeDefenition {
  const { scale, precision } = options;

  return {
    format: {
      minFractionDigits: coalesce(options.minFractionDigits, 0),
      maxFractionDigits: coalesce(options.maxFractionDigits, scale),
    },
    constraints: {
      min: {
        value: 0,
        include: coalesce(options.includeZero, true),
      },
      max: {
        value: coalesce(options.max, getDecimalMax(precision, scale)),
        include: coalesce(options.includeMax, true),
      },
    },
  };
}

export function getPositiveIntegralParams(bits: number,
                                          max: number = Math.pow(2, bits),
                                          includeZero: boolean = true,
                                          includeMax: boolean = true): NumberTypeDefenition {
  return {
    format: {
      minFractionDigits: 0,
      maxFractionDigits: 0,
    },
    constraints: {
      integral: true,
      min: {
        value: 0,
        include: includeZero,
      },
      max: {
        value: max,
        include: includeMax,
      },
    },
  };
}

export function getRangedIntegralParams(options: RangedIntegralParamsOptions): NumberTypeDefenition {
  const { min, max, includeMin = true, includeMax = true } = options;

  return {
    format: {
      minFractionDigits: 0,
      maxFractionDigits: 0,
    },
    constraints: {
      integral: true,
      min: {
        value: min,
        include: includeMin,
      },
      max: {
        value: max,
        include: includeMax,
      },
    },
  };
}
