<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<gh-ext-page>
  <gh-ext-page-title title="{{'lbl_photos' | translate}}"></gh-ext-page-title>
  <gh-ext-no-data *ngIf="!isPhotoListExist()" fxFlex="" message="{{'lbl_no_entries' | translate}}">
  </gh-ext-no-data>
  <gh-ext-page-content *ngIf="isPhotoListExist()" class="wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start">
    <div fxHide.lt-md="" fxShow.gt-md="" fxLayout="column" fxLayoutAlign="start center" class="main-content">
      <div class="photo-container">
        <div #photoItem="" *ngFor="let photo of photoList" [style.backgroundImage]="'url(' + photo.url + ')'" [hidden]="!isCurrentPhoto(photo)" class="photo-item">
          <div class="overlay-item waiting">
            <gh-ext-preloader class="m-preloader stretch" size="45" [loading]="isCurrentPhoto(photo)"></gh-ext-preloader>
          </div>
          <img (load)="onPhotoLoaded($event)" [src]="photo.url" *ngIf="isCurrentPhoto(photo)" class="photo-item-img">
        </div>
      </div>
      <div class="photo-title">{{ currentPhotoName }}</div>
      <div fxLayout="row" fxLayoutAlign="center space-between" class="gallery-navigation">
        <button (click)="onPrevPhoto()" fxLayoutAlign="start" [class.disabled]="!hasPrevPhoto()" [disabled]="!hasPrevPhoto()" [disableRipple]="true" type="button" mat-button="" class="nav-control prev" matTooltip="{{'lbl_prev' | translate}}">
          <div fxLayout="row">
            <span>{{'lbl_prev' | translate}}</span>
            <mat-icon class="s18" svgIcon="gh:chevron-left"></mat-icon>
          </div>
        </button>

        <div class="nav-control info" fxLayoutAlign="center">
          <div class="nav-info">
            {{ navigationInfo }}
          </div>
        </div>
        <button (click)="onNextPhoto()" fxLayoutAlign="end" [class.disabled]="!hasNextPhoto()" [disabled]="!hasNextPhoto()" [disableRipple]="true" type="button" mat-button="" class="nav-control next" matTooltip="{{'lbl_next' | translate}}">
          <div fxLayout="row">
            <span>{{'lbl_next' | translate}}</span>
            <mat-icon class="s18" svgIcon="gh:chevron-right"></mat-icon>
          </div>
        </button>
      </div>
    </div>
    <!-- Web -->
    <div fxHide.lt-md="" fxShow.gt-md="" fxFlex="0 0 auto" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start start" class="gallery-list">
      <div *ngFor="let photo of photoList" (click)="onChangeCurrentPhoto(photo.id, { hasOverlay: false })" class="gallery-list-item">
        <div class="overlay-item waiting">
          <gh-ext-preloader class="m-preloader stretch" size="45" [loading]="isCurrentPhoto(photo)"></gh-ext-preloader>
        </div>
        <img (load)="onPhotoLoaded($event)" [src]="photo.thumbnailUrl" [id]="getPhotoListItemHtmlId(photo)">
        <div [hidden]="!isCurrentPhoto(photo)" class="overlay-list"></div>
      </div>
    </div>
    <!-- Mobile -->
    <div fxShow.lt-md="" fxHide.gt-sm="" fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start" class="gallery-list">
      <div *ngFor="let photo of photoList" (click)="onChangeCurrentPhoto(photo.id, { hasOverlay: true })" class="gallery-list-item">
        <div class="overlay-item waiting">
          <gh-ext-preloader class="m-preloader stretch" size="45" [loading]="isCurrentPhoto(photo)"></gh-ext-preloader>
        </div>
        <img (load)="onPhotoLoaded($event)" [src]="photo.thumbnailUrl">
        <div [hidden]="!isCurrentPhoto(photo)" class="overlay"></div>
      </div>
    </div>

    <div [hidden]="!isPhotoOverlayOpen" class="current-photo-overlay-content" (click)="onChangeStatePhotoOverlay()">
      <div class="overlay-container">
        <div class="overlay-header">
          <div class="photo-title"></div>
          <button mat-button="" mat-icon-button="" (click)="onChangeStatePhotoOverlay(false)" class="close-button shrinked-icon-button" matTooltip="{{'lbl_close' | translate}}">
            <mat-icon svgIcon="gh:close" class="s40"></mat-icon>
          </button>
        </div>
        <div class="overlay-content">
          <div class="photo-container">
            <div #photoItem="" *ngFor="let photo of photoList" [style.backgroundImage]="'url(' + photo.url + ')'" [hidden]="!isCurrentPhoto(photo)" class="photo-item">
              <div class="overlay-item waiting">
                <gh-ext-preloader class="m-preloader stretch" size="45" [loading]="isCurrentPhoto(photo)"></gh-ext-preloader>
              </div>
              <img (load)="onPhotoLoaded($event)" [src]="photo.url" *ngIf="isCurrentPhoto(photo)" class="photo-item-img">
            </div>
          </div>
        </div>
        <div class="overlay-footer">
          <div class="photo-title">
            {{ currentPhotoName }}
          </div>
          <div fxLayout="row" fxLayoutAlign="center space-between" class="gallery-navigation">
            <button (click)="onPrevPhoto()" fxLayoutAlign="start" [class.disabled]="!hasPrevPhoto()" [disabled]="!hasPrevPhoto()" [disableRipple]="true" type="button" mat-button="" class="nav-control prev mobile" matTooltip="{{'lbl_prev' | translate}}">
              <div fxLayout="row">
                <span>{{'lbl_prev' | translate}}</span>
                <mat-icon class="s18" svgIcon="gh:chevron-left"></mat-icon>
              </div>
            </button>
            <div class="nav-control info mobile" fxLayoutAlign="center">
              <div class="nav-info">
                {{ navigationInfo }}
              </div>
            </div>
            <button (click)="onNextPhoto()" fxLayoutAlign="end" [class.disabled]="!hasNextPhoto()" [disabled]="!hasNextPhoto()" [disableRipple]="true" type="button" mat-button="" class="nav-control next mobile" matTooltip="{{'lbl_next' | translate}}">
              <div fxLayout="row">
                <span>{{'lbl_next' | translate}}</span>
                <mat-icon class="s18" svgIcon="gh:chevron-right"></mat-icon>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

  </gh-ext-page-content>
</gh-ext-page>
