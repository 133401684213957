/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StringTypeValidators } from '../validators';
import { AbstractPrimitiveType } from './abstract-type';
var TextTypeImpl = /** @class */ (function (_super) {
    __extends(TextTypeImpl, _super);
    function TextTypeImpl(defenition) {
        var _this = _super.call(this, defenition, StringTypeValidators) || this;
        if (defenition.constraints) {
            _this.maxLength = defenition.constraints.maxLength;
            _this.pattern = defenition.constraints.pattern;
        }
        return _this;
    }
    TextTypeImpl.prototype.parse = function (str) {
        return str;
    };
    TextTypeImpl.prototype.format = function (value) {
        return value;
    };
    TextTypeImpl.prototype.validateFormat = function (str) {
        return this.validate(str);
    };
    return TextTypeImpl;
}(AbstractPrimitiveType));
export { TextTypeImpl };
