/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { assign } from 'lodash';

/**
 * This service holds the current snapshot of router state.
 */
@Injectable()
export class AppRouterState {
  params: Params;
  queryParams: Params;

  parentRoute?: string;
  parentTitle?: string;

  constructor(private router: Router) {

  }

  /**
   * Updates internal state of this service
   *
   * @internal this method should not be used by developers
   */
  _update(): void {
    const root = this.router.routerState.snapshot.root;

    const queryParams = extractParams(root, (route: ActivatedRouteSnapshot) => route.queryParams);
    const params = extractParams(root, (route: ActivatedRouteSnapshot) => route.params);

    const { parentRoute, parentTitle } = queryParams;

    assign(this, { parentRoute, parentTitle });
    assign(this, { queryParams, params });
  }
}

/**
 * Recursively extracts parameters from route and its children.
 * Finally this method merges parameters into single object.
 *
 * @param route
 * @param extractor
 * @returns {T}
 */
function extractParams<T>(route: ActivatedRouteSnapshot, extractor: (route: ActivatedRouteSnapshot) => T): T {
  return <T>assign({},
                   extractor(route),
                   ...route.children.map((child: ActivatedRouteSnapshot) => extractParams(child, extractor)));
}
