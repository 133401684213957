/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { flatMap, isArray, isNil } from 'lodash';

export function coerceStringArrayProperty(value: string | string[]): string[] {
  if (isArray(value)) {
    return value;
  } else {
    return flatMap(value.split(/\s*,\s*/), (v) => v.split(/\s+/));
  }
}

export function coalesceObjectProperty(value: any): Maybe<object> {
  return (value === '' || isNil(value)) ? void 0 : value;
}
