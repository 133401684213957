/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { Component, DoCheck, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationStart, Router } from '@angular/router';
import { APP_NAME } from '@gh/config';
import { WaitingContext } from '@gh/core-ui';
import { interval$ } from '@gh/rx';
import { distinctUntilChanged$, filter$, map$, throttleTime$ } from '@gh/rx/operators';

import { compact } from 'lodash';
import { Subscription } from 'rxjs';

const MOBILE_TOP_MENU_HEIGHT = 112;
const MOBILE_TOP_MENU_SHOW_THRESHOLD = 150;

export abstract class ExtRootLayout {
  abstract setTitle(title: string): void;

  abstract resetTitle(): void;
}

@Component({
  selector: 'gh-ext-root-layout',
  templateUrl: 'ext-root-layout.component.html',
  styleUrls: ['ext-root-layout.component.scss'],
  exportAs: 'ghExtRootLayout',
  providers: [{ provide: ExtRootLayout, useExisting: ExtRootLayoutComponent }],
})
export class ExtRootLayoutComponent implements OnInit, DoCheck, OnDestroy, ExtRootLayout {
  isNavigationOpened = false;

  @HostBinding('class.is-top-menu')
  isMenuOnTop = true;

  @HostBinding('class.is-fixed-menu')
  isMenuFixed = false;

  @ViewChild('menuTooltip', { static: false }) menuTooltip: MatTooltip;
  @ViewChild('closeTooltip', { static: false }) closeTooltip: MatTooltip;

  backgroundWaiting = false;
  blockedWaiting = false;

  title?: string;

  private htmlEl: Element;
  private lastScrollTop: number = 0;
  private htmlScrollSubscription: Subscription;

  get isFirstRouteActivated(): boolean {
    return this.router.navigated;
  }

  constructor(private router: Router,
              private waitingContext: WaitingContext) {
  }

  ngOnInit(): void {
    this.htmlEl = document.documentElement!; // tslint:disable-line:no-non-null-assertion

    this.htmlScrollSubscription = interval$(90).pipe(
      map$(() => this.htmlEl.scrollTop),
      distinctUntilChanged$(),
      throttleTime$(100))
      .subscribe(() => this.onPageScroll());

    this.router.events.pipe(
      filter$((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.setNavigationOpened(false);
      });
  }

  ngDoCheck(): void {
    const { mode, waiting } = this.waitingContext;

    // display all waiting effects in background
    // this.blockedWaiting = mode !== WaitingMode.Background && waiting;
    this.backgroundWaiting = /*mode === WaitingMode.Background && */waiting;
  }

  ngOnDestroy(): void {
    this.htmlScrollSubscription.unsubscribe();
  }

  toggleNavigation(): void {
    this.setNavigationOpened(!this.isNavigationOpened);
  }

  closeNavigation(): void {
    this.setNavigationOpened(false);
  }

  setTitle(title: string): void {
    this.title = title;
    this.updateDocumentTitle();
  }

  resetTitle(): void {
    this.title = void 0;
    this.updateDocumentTitle();
  }

  private onPageScroll(): void {
    const bodyScrollTop = this.htmlEl.scrollTop;

    if (bodyScrollTop - this.lastScrollTop > 0 || bodyScrollTop === 0) {
      this.isMenuFixed = false;
    } else if (this.lastScrollTop - bodyScrollTop > MOBILE_TOP_MENU_SHOW_THRESHOLD
      && bodyScrollTop > MOBILE_TOP_MENU_HEIGHT) {
      this.isMenuFixed = true;
    }

    this.isMenuOnTop = bodyScrollTop < MOBILE_TOP_MENU_HEIGHT;

    this.lastScrollTop = bodyScrollTop;
  }

  private setNavigationOpened(isNavigationOpened: boolean): void {
    this.isNavigationOpened = isNavigationOpened;
    this.menuTooltip.hide();
    this.closeTooltip.hide();

    this.htmlEl.classList.toggle('cdk-global-scrollblock', isNavigationOpened);
  }

  private updateDocumentTitle(): void {
    document.title = compact([`${APP_NAME}`, this.title]).join(' - ');
  }
}
