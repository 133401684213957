/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { includes, isNil, isString } from 'lodash';
import { Observable } from 'rxjs';

export enum UploadStatus { NotStarted, Uploading, Completed, Error }

export enum UploadEventType { Started, Progress, Completed, Cancelled, Error }

/**
 * Describes changing of state of an upload process.
 */
export interface UploadEvent {
  type: UploadEventType;
  item: UploadItem;
  error?: any;
}

/**
 * This interface describes state of upload process.
 */
export interface UploadItem {
  name: string; // symbolic name of the upload item
  mimeType: string; // mime type
  uploadId?: string; // correlation id created by upload engine to associate upload item with upload process
  location?: string; // url of the uploaded item
  status?: UploadStatus; // status of the uploading process
  progress?: number; // progress of the uploading process
  blob?: Blob;
  coordinates?: Coordinates;
}

export interface Coordinates {
  xLeft: number;
  yTop: number;
  xRight: number;
  yBottom: number;
}

export interface FileInfo {
  name: string;
  mimeType?: string;
  blob: Blob;
}

/**
 * Low-level interface of underlying upload engine.
 *
 * Note! This interface and its implementation should not be used directly. Use {@link UploadContext} instead.
 */
export abstract class UploadEngine {
  abstract event$: Observable<UploadEvent>;

  abstract create(file: FileInfo): UploadItem;

  abstract start(item: UploadItem, params: Hash<any>): void;

  abstract cancel(item: UploadItem): void;
}

/**
 * {@link UploadController} is used by uploader directive to connect itself to the uploading process.
 * This abstraction is necessary to detach uploading process from the UI logic.
 */
export abstract class UploadController {
  abstract event$: Observable<UploadEvent>;
  abstract items: UploadItem[];

  abstract create(file: FileInfo): UploadItem;

  abstract start(item: UploadItem): void;

  abstract cancel(item: UploadItem): void;
}

/**
 * Returns true when uploading was completed successfully.
 */
export function isUploadCompleted(item: UploadItem): boolean {
  return !isNil(item.location);
}

/**
 * Returns true when uploading is in the progress.
 */
export function isUploadStarted(item: UploadItem): boolean {
  return item.status === UploadStatus.Uploading;
}

export function getUploadItemName(item: UploadItem): string {
  return item.name;
}

const FINAL_EVENTS = [UploadEventType.Completed, UploadEventType.Cancelled, UploadEventType.Error];
const FINAL_STATUSES = [UploadStatus.Completed, UploadStatus.Error];

export function isUploadFinalEvent(event: UploadEvent): boolean {
  return isFinalEventType(event.type);
}

export function isUploadCompletedEvent(event: UploadEvent): boolean {
  return event.type === UploadEventType.Completed;
}

/**
 * Returns true is type corresponds to the Completed, Cancelled or Error event.
 * Each of these events is the last in the chain of events for a single upload process.
 */
export function isFinalEventType(type: UploadEventType): boolean {
  return includes(FINAL_EVENTS, type);
}

export function isFinalStatus(status: UploadStatus): boolean {
  return includes(FINAL_STATUSES, status);
}

export function createFileInfoFromBlob(file: File): FileInfo;
export function createFileInfoFromBlob(name: string, blob: Blob, mimeType?: string): FileInfo;
export function createFileInfoFromBlob(nameOrFile: string | File, blob?: Blob, mimeType?: string): FileInfo {
  return { name: isString(nameOrFile) ? nameOrFile : nameOrFile.name, blob: blob || <Blob>nameOrFile, mimeType };
}
