/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { TranslationService } from '../translation/translation.service';
import * as i0 from "@angular/core";
import * as i1 from "../translation/translation.service";
/**
 * This service encapsulates common logic of retrieving message translations for form errors.
 *
 * This service translates message in the following way:
 * 1. If message params were provided and parameter <code>messageId</code> exists,
 * <code>messageId</code> is used to retrieve translation for this message.
 *
 * 2. Otherwise, provided <code>key</code> is used to construct translation key.
 * Translation key is constructed by adding prefix <code>msg_field_</code>
 * (e. g. <code>required => msg_field_required</code>)
 */
var FormMessageService = /** @class */ (function () {
    function FormMessageService(translationService) {
        this.translationService = translationService;
    }
    /**
     * Creates nested {@link MessageRepository}.
     * Initially, given {@link MessageRepository} tries to find translation for the message.
     * If translation was not found it loads translation using common logic described here above.
     */
    FormMessageService.prototype.createNested = function (repository) {
        var _this = this;
        return {
            getText: function (key, params) {
                var text = repository.getText(key, params);
                if (text) {
                    return text;
                }
                return _this.getText(key, params);
            },
        };
    };
    FormMessageService.prototype.getText = function (key, params) {
        var text = '';
        if (params && params.messageId) {
            text = this.translationService.get(params.messageId, params);
        }
        if (!text) {
            text = this.translationService.get("msg_field_" + key, params);
        }
        return text;
    };
    FormMessageService.ɵfac = function FormMessageService_Factory(t) { return new (t || FormMessageService)(i0.ɵɵinject(i1.TranslationService)); };
    FormMessageService.ɵprov = i0.ɵɵdefineInjectable({ token: FormMessageService, factory: FormMessageService.ɵfac });
    return FormMessageService;
}());
export { FormMessageService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FormMessageService, [{
        type: Injectable
    }], function () { return [{ type: i1.TranslationService }]; }, null); })();
