/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: 'gh-message', // tslint:disable-line:directive-selector
})
export class MessageDirective {
  @Input() key: string;

  constructor(private elementRef: ElementRef) {

  }

  get value(): string {
    return this.elementRef.nativeElement.textContent;
  }
}
