var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { LocalizationService, TranslationService } from '@gh/core-mls';
import { currentDate, currentDayOfMonth, isDateInvalid } from '@gh/core-util';
import { isNil, isNumber, isString, merge } from 'lodash';
import { MAT_NATIVE_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/platform";
import * as i2 from "@gh/core-mls";
var DisplayFormatTarget;
(function (DisplayFormatTarget) {
    DisplayFormatTarget[DisplayFormatTarget["Input"] = 0] = "Input";
    DisplayFormatTarget[DisplayFormatTarget["Layout"] = 1] = "Layout";
})(DisplayFormatTarget || (DisplayFormatTarget = {}));
export var APP_DATE_FORMATS = merge({}, MAT_NATIVE_DATE_FORMATS, {
    parse: {
        dateInput: 'date',
    },
    display: {
        dateInput: { target: DisplayFormatTarget.Input, query: 'shortDate' },
        monthYearLabel: { target: DisplayFormatTarget.Layout, query: 'monthYear' },
        dateA11yLabel: { target: DisplayFormatTarget.Layout, query: 'dateA11y' },
        monthYearA11yLabel: { target: DisplayFormatTarget.Layout, query: 'monthYearA11y' },
    },
});
var getValidDate = function (date) { return isDateInvalid(date) ? currentDate() : date; };
var AppDateAdapter = /** @class */ (function (_super) {
    __extends(AppDateAdapter, _super);
    function AppDateAdapter(platform, localizationProvider, translationService) {
        var _this = _super.call(this, localizationProvider.getEffectiveLocale().code, platform) || this;
        _this.platform = platform;
        _this.localizationProvider = localizationProvider;
        _this.translationService = translationService;
        return _this;
    }
    AppDateAdapter.prototype.today = function () {
        return currentDate();
    };
    AppDateAdapter.prototype.createDate = function (year, month, date) {
        return _super.prototype.createDate.call(this, year, month, date < 0 ? currentDayOfMonth() : date);
    };
    AppDateAdapter.prototype.getYear = function (date) {
        return _super.prototype.getYear.call(this, getValidDate(date));
    };
    AppDateAdapter.prototype.getMonth = function (date) {
        return _super.prototype.getMonth.call(this, getValidDate(date));
    };
    AppDateAdapter.prototype.getDate = function (date) {
        return isDateInvalid(date) ? -1 : _super.prototype.getDate.call(this, getValidDate(date));
    };
    AppDateAdapter.prototype.getDayOfWeek = function (date) {
        return _super.prototype.getDayOfWeek.call(this, getValidDate(date));
    };
    AppDateAdapter.prototype.getYearName = function (date) {
        return _super.prototype.getYearName.call(this, getValidDate(date));
    };
    AppDateAdapter.prototype.getNumDaysInMonth = function (date) {
        return _super.prototype.getNumDaysInMonth.call(this, getValidDate(date));
    };
    AppDateAdapter.prototype.clone = function (date) {
        return _super.prototype.clone.call(this, getValidDate(date));
    };
    AppDateAdapter.prototype.addCalendarYears = function (date, years) {
        return _super.prototype.addCalendarYears.call(this, getValidDate(date), years);
    };
    AppDateAdapter.prototype.addCalendarMonths = function (date, months) {
        return _super.prototype.addCalendarMonths.call(this, getValidDate(date), months);
    };
    AppDateAdapter.prototype.addCalendarDays = function (date, days) {
        return _super.prototype.addCalendarDays.call(this, getValidDate(date), days);
    };
    AppDateAdapter.prototype.toIso8601 = function (date) {
        return _super.prototype.toIso8601.call(this, getValidDate(date));
    };
    AppDateAdapter.prototype.parse = function (value, parseFormat) {
        if (isNil(value) || isString(value) && value.trim().length === 0) {
            return null; // tslint:disable-line:no-null-keyword
        }
        else if (isNumber(value)) {
            return new Date(value);
        }
        return this.localizationProvider.getDateTimeParser().parse(parseFormat, value);
    };
    AppDateAdapter.prototype.format = function (date, displayFormat) {
        var target = displayFormat.target, query = displayFormat.query;
        var invalid = isDateInvalid(date);
        if (invalid && target === DisplayFormatTarget.Input) {
            return this.translationService.get('lbl_invalid_date');
        }
        else {
            return this.localizationProvider.getDateTimeFormat().format(query, getValidDate(date));
        }
    };
    /**
     * This method always treats date as valid. This is necessary to avoid binding of
     * internal datepicker validator. Internal validator is bound to the form control each time
     * component is created. This way we can have several similar validators and all of them
     * except the latest one (may be) will validate date based on input of <b>disposed (!!!) datepicker</b>.
     * Thus we have to avoid any internal datepicker validations.
     */
    AppDateAdapter.prototype.isValid = function (date) {
        return true;
    };
    AppDateAdapter.ɵfac = function AppDateAdapter_Factory(t) { return new (t || AppDateAdapter)(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.LocalizationService), i0.ɵɵinject(i2.TranslationService)); };
    AppDateAdapter.ɵprov = i0.ɵɵdefineInjectable({ token: AppDateAdapter, factory: AppDateAdapter.ɵfac });
    return AppDateAdapter;
}(NativeDateAdapter));
export { AppDateAdapter };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppDateAdapter, [{
        type: Injectable
    }], function () { return [{ type: i1.Platform }, { type: i2.LocalizationService }, { type: i2.TranslationService }]; }, null); })();
