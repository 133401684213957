/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreMlsModule } from '@gh/core-mls';
import { S3UploadEngine } from './s3-upload-engine.service';
import { SingleUploaderComponent } from './single-uploader.component';
import { UploadContext } from './upload-context.service';
import { UploadMultiSlotDirective } from './upload-multi-slot.directive';
import { UploadSlotDirective } from './upload-slot.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import * as i0 from "@angular/core";
var CoreUiUploaderModule = /** @class */ (function () {
    function CoreUiUploaderModule() {
    }
    CoreUiUploaderModule.forRoot = function () {
        return {
            ngModule: CoreUiUploaderModule,
            providers: [
                UploadContext,
                S3UploadEngine,
            ],
        };
    };
    CoreUiUploaderModule.forChild = function () {
        return { ngModule: CoreUiUploaderModule };
    };
    CoreUiUploaderModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreUiUploaderModule });
    CoreUiUploaderModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreUiUploaderModule_Factory(t) { return new (t || CoreUiUploaderModule)(); }, imports: [[
                CommonModule,
                MatFormFieldModule,
                MatInputModule,
                MatIconModule,
                MatButtonModule,
                CoreMlsModule,
            ]] });
    return CoreUiUploaderModule;
}());
export { CoreUiUploaderModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreUiUploaderModule, { declarations: [SingleUploaderComponent,
        UploadSlotDirective,
        UploadMultiSlotDirective], imports: [CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        CoreMlsModule], exports: [SingleUploaderComponent,
        UploadSlotDirective,
        UploadMultiSlotDirective] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreUiUploaderModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                    MatFormFieldModule,
                    MatInputModule,
                    MatIconModule,
                    MatButtonModule,
                    CoreMlsModule,
                ],
                declarations: [
                    SingleUploaderComponent,
                    UploadSlotDirective,
                    UploadMultiSlotDirective,
                ],
                exports: [
                    SingleUploaderComponent,
                    UploadSlotDirective,
                    UploadMultiSlotDirective,
                ],
            }]
    }], null, null); })();
