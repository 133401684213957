/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:variable-name

import { date, DateFormat, entity, enumeration } from '@gh/core-data';

import { EDocumentListItemDto, EDocumentStatus } from './e-document.dto';

export const EDocumentsListMapper = entity<EDocumentListItemDto>({
  sentDate: date(DateFormat.DateTime),
  status: enumeration(EDocumentStatus),
});
