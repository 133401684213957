/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { of$, throwError$ } from '@gh/rx';
import * as i0 from "@angular/core";
/**
 * Simple store that associates data with the given key
 */
var RouteDataStore = /** @class */ (function () {
    function RouteDataStore() {
        this.data = {};
    }
    /**
     * Associates some data with the given key
     */
    RouteDataStore.prototype.set = function (key, value) {
        this.data[key] = value;
        return of$(value);
    };
    /**
     * Returns data associated with the given key.
     * If data is not associated with the given key method returns failed observable.
     */
    RouteDataStore.prototype.get = function (key) {
        var entry = this.data[key];
        if (entry) {
            return of$(entry);
        }
        else {
            return throwError$("Entry does not exist for '" + key + "'");
        }
    };
    /**
     * Remove state by key
     */
    RouteDataStore.prototype.clear = function (key) {
        delete this.data[key];
    };
    RouteDataStore.ɵfac = function RouteDataStore_Factory(t) { return new (t || RouteDataStore)(); };
    RouteDataStore.ɵprov = i0.ɵɵdefineInjectable({ token: RouteDataStore, factory: RouteDataStore.ɵfac });
    return RouteDataStore;
}());
export { RouteDataStore };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(RouteDataStore, [{
        type: Injectable
    }], null, null); })();
