/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DecimalPipe } from '@angular/common';
import { Locale } from '../locale';
import { NumberFormat, NumberFormatQuery, NumberLocalization, NumberParser } from './localization-provider';

const SIGN_PART_INDEX = 1;
const INTEGRAL_PART_INDEX = 4;
const FRACTIONAL_PART_INDEX = 6;
const ONLY_FRACTIONAL_PART_INDEX = 8;

export class NumberFormatImpl implements NumberFormat {
  constructor(private locale: Locale,
              private numberPipe: DecimalPipe) {
  }

  format(query: NumberFormatQuery, n: number): string {
    return <string>this.numberPipe.transform(
      n,
      `1.${query.minimumFractionDigits}-${query.maximumFractionDigits}`,
      this.locale.code);
  }
}

/**
 * It is very poor implementation of localizable number parser.
 * It was implemented to avoid usage of fat parsing libraries.
 *
 * If capabilities of this class will be not enough we can integrate good and robust library.
 *
 * TODO: refactor this class because it is very poor implementation of number parsing
 */
export class NumberParserImpl implements NumberParser {
  private re: RegExp;
  private groupRe: RegExp;

  /**
   * Creates parser for the given set of localization parameters
   *
   * @param localization
   */
  constructor(private localization: NumberLocalization) {
    this.re = compilePattern(localization);

    if (this.localization.groupSeparator) {
      this.groupRe = new RegExp(this.localization.groupSeparator, 'g');
    }
  }

  /**
   * Parses given string into number
   *
   * @param str
   * @returns {Number}
   */
  parse(str: string): number {
    const match = this.re.exec(str);

    if (!match) {
      throw new Error(`Value '${str}' cannot be converted to a number`);
    }

    let integralPart = match[INTEGRAL_PART_INDEX] || '';

    if (this.groupRe) {
      integralPart = integralPart.replace(this.groupRe, '');
    }

    // tslint:disable:max-line-length
    return Number(`${match[SIGN_PART_INDEX] || ''}${integralPart}.${match[FRACTIONAL_PART_INDEX] || match[ONLY_FRACTIONAL_PART_INDEX] || '0'}`);
  }

  /**
   * Validates that given string can be converted to number.
   *
   * @param str
   * @returns {boolean}
   */
  validate(str: string): boolean {
    return this.re.test(str);
  }
}

function compileSignPart(localization: NumberLocalization): string {
  return '(\\+|-)';
}

function compileIntegralPart(localization: NumberLocalization): string {
  const pattern = [];
  if (localization.groupSeparator) {
    pattern.push('([0-9\\', localization.groupSeparator);
    if (localization.grouping) {
      pattern.push(' ');
    }
    pattern.push(']+)');
  } else {
    pattern.push('([0-9');
    if (localization.grouping) {
      pattern.push(' ');
    }
    pattern.push(']+)');
  }
  return pattern.join('');
}

function compileFractionalpart(localization: NumberLocalization): string {
  return `(\\${localization.decimalSeparator}([0-9]+)?)`;
}

function compilePattern(localization: NumberLocalization): RegExp {
  const pattern = [];
  const space = '\\s*';
  const sign = compileSignPart(localization);
  const integral = compileIntegralPart(localization);
  const fractional = compileFractionalpart(localization);

  pattern.push('^');
  pattern.push(space);
  pattern.push(sign, '?');
  pattern.push(space);

  pattern.push('(');
  if (localization.decimalSeparator) {
    pattern.push('(', integral, fractional, '?', ')');
    pattern.push('|');
    pattern.push(fractional);
  } else {
    pattern.push(integral);
  }
  pattern.push(')');

  pattern.push(space);
  pattern.push('$');

  return new RegExp(pattern.join(''));
}
