var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { CommonUserContextService, ConfigurationHttpRequestInterceptor } from '@gh/common-shared';
import { REGION_CODE } from '@gh/config';
import { AuthService } from '@gh/core-auth';
import { isNotNil } from '@gh/core-util';
import { CustomerJobRestService, CustomerUserProfileRestService } from '@gh/customer-shared-data';
import { map$, mapTo$, switchMap$, tap$ } from '@gh/rx/operators';
import { find, isNil, sortBy } from 'lodash';
import { CustomerUserProfileStore } from './customer-user-profile-store.service';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-auth";
import * as i2 from "./customer-user-profile-store.service";
import * as i3 from "@gh/customer-shared-data";
import * as i4 from "@gh/common-shared";
var CustomerUserContextService = /** @class */ (function (_super) {
    __extends(CustomerUserContextService, _super);
    function CustomerUserContextService(authService, userProfileStore, profileRest, jobRest, configurationHttpRequestInterceptor) {
        var _this = _super.call(this, authService, userProfileStore) || this;
        _this.profileRest = profileRest;
        _this.jobRest = jobRest;
        _this.configurationHttpRequestInterceptor = configurationHttpRequestInterceptor;
        _this._jobs = [];
        return _this;
    }
    Object.defineProperty(CustomerUserContextService.prototype, "jobs", {
        get: function () {
            this.checkUserInitialized();
            return this._jobs;
        },
        set: function (jobs) {
            throw new Error('CustomerUserContextService: list of jobs cannot be changed');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomerUserContextService.prototype, "currentJob", {
        get: function () {
            this.checkUserInitialized();
            return this._currentJob;
        },
        set: function (job) {
            throw new Error("CustomerUserContextService: to change job use setCurrentJob method");
        },
        enumerable: true,
        configurable: true
    });
    CustomerUserContextService.prototype.init = function (params) {
        var _this = this;
        var acceptedOfficeId = params.acceptedOfficeId;
        return _super.prototype.init.call(this, params).pipe(switchMap$(function (userData) { return acceptedOfficeId
            ? _this.profileRest.acceptInvitation(acceptedOfficeId).pipe(mapTo$(userData))
            : [userData]; }), switchMap$(function (userData) {
            return _this.jobRest.findAll().pipe(map$(function (jobs) { return sortBy(jobs, 'jobNumber'); }), tap$(function (jobs) {
                _this._jobs = jobs;
                if (jobs.length) {
                    _this.setCurrentJob(jobs[0].jobId);
                }
            }), mapTo$(userData));
        }));
    };
    CustomerUserContextService.prototype.setCurrentJob = function (jobId) {
        if (this._currentJob && this._currentJob.jobId === jobId) {
            return;
        }
        var nextJob = find(this._jobs, { jobId: jobId });
        if (isNil(nextJob)) {
            throw new Error("CustomerUserContextService: id \"" + jobId + "\" does not correspond to any of loaded jobs");
        }
        this._currentJob = nextJob;
        this.configurationHttpRequestInterceptor.setHeader('X-OfficeId', nextJob.officeId);
    };
    CustomerUserContextService.prototype.isJobExist = function (jobId) {
        return isNotNil(find(this._jobs, { jobId: jobId }));
    };
    CustomerUserContextService.prototype.hasPermission = function (permission) {
        throw new Error('CustomerUserContext: permissions are not supported');
    };
    CustomerUserContextService.prototype.updateUserData = function (user) {
        // nothing to do
    };
    CustomerUserContextService.prototype.setupHttpHeaders = function (params) {
        this.configurationHttpRequestInterceptor.headers = {
            'X-Region': REGION_CODE,
        };
    };
    CustomerUserContextService.ɵfac = function CustomerUserContextService_Factory(t) { return new (t || CustomerUserContextService)(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.CustomerUserProfileStore), i0.ɵɵinject(i3.CustomerUserProfileRestService), i0.ɵɵinject(i3.CustomerJobRestService), i0.ɵɵinject(i4.ConfigurationHttpRequestInterceptor)); };
    CustomerUserContextService.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerUserContextService, factory: CustomerUserContextService.ɵfac });
    return CustomerUserContextService;
}(CommonUserContextService));
export { CustomerUserContextService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerUserContextService, [{
        type: Injectable
    }], function () { return [{ type: i1.AuthService }, { type: i2.CustomerUserProfileStore }, { type: i3.CustomerUserProfileRestService }, { type: i3.CustomerJobRestService }, { type: i4.ConfigurationHttpRequestInterceptor }]; }, null); })();
