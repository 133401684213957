/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { LocalizationService } from '@gh/core-mls';
import { each, uniqueId } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
export var TYPE_SET = new InjectionToken('TypeSet');
var TypeService = /** @class */ (function () {
    function TypeService(typeSets, localizationService) {
        var _this = this;
        this.localizationService = localizationService;
        this.registry = {};
        typeSets.forEach(function (set) {
            each(set, function (type, name) {
                if (_this.registry[name]) {
                    throw new Error("Type with name " + name + " is already registered");
                }
                _this.registry[name] = type;
                type.init(name, localizationService);
            });
        });
    }
    TypeService.prototype.create = function (type) {
        type.init(uniqueId('DynamicType:'), this.localizationService);
        return type;
    };
    TypeService.prototype.get = function (name) {
        return this.registry[name];
    };
    TypeService.ɵfac = function TypeService_Factory(t) { return new (t || TypeService)(i0.ɵɵinject(TYPE_SET), i0.ɵɵinject(i1.LocalizationService)); };
    TypeService.ɵprov = i0.ɵɵdefineInjectable({ token: TypeService, factory: TypeService.ɵfac });
    return TypeService;
}());
export { TypeService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TypeService, [{
        type: Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: Inject,
                args: [TYPE_SET]
            }] }, { type: i1.LocalizationService }]; }, null); })();
