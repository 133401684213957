/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
// tslint:disable:no-magic-numbers variable-name
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { quantityNumberType } from '@gh/core-type';
import { getRangedDecimalParams } from './number-utils';
export var BaseQuantityNumberType = quantityNumberType({
    messages: {
        min: 'msg_field_invalid_format',
        max: 'msg_field_invalid_format',
        range: 'msg_field_invalid_range',
    },
});
export var QuantityTypes = {
    QGeneric: quantityNumberType(BaseQuantityNumberType, __assign({}, getRangedDecimalParams({ precision: 20, scale: 4, includeMin: false, includeMax: false }))),
};
