/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { DictionaryService, getRouteParam } from '@gh/common-shared';
import { CustomerFinancialRecordRestService, InvoiceType } from '@gh/customer-shared-data';
import * as i0 from "@angular/core";
import * as i1 from "@gh/customer-shared-data";
import * as i2 from "@gh/common-shared";
var CustomerFinancialRecordBusinessService = /** @class */ (function () {
    function CustomerFinancialRecordBusinessService(restService, dictionaryService) {
        this.restService = restService;
        this.dictionaryService = dictionaryService;
    }
    CustomerFinancialRecordBusinessService.prototype.initView = function (route) {
        var jobId = getRouteParam(route, 'jobId');
        return {
            financialRecord: this.get(jobId),
            invoiceType: this.dictionaryService.getByEnum(InvoiceType),
        };
    };
    CustomerFinancialRecordBusinessService.prototype.get = function (jobId) {
        return this.restService.get(jobId);
    };
    CustomerFinancialRecordBusinessService.ɵfac = function CustomerFinancialRecordBusinessService_Factory(t) { return new (t || CustomerFinancialRecordBusinessService)(i0.ɵɵinject(i1.CustomerFinancialRecordRestService), i0.ɵɵinject(i2.DictionaryService)); };
    CustomerFinancialRecordBusinessService.ɵprov = i0.ɵɵdefineInjectable({ token: CustomerFinancialRecordBusinessService, factory: CustomerFinancialRecordBusinessService.ɵfac });
    return CustomerFinancialRecordBusinessService;
}());
export { CustomerFinancialRecordBusinessService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerFinancialRecordBusinessService, [{
        type: Injectable
    }], function () { return [{ type: i1.CustomerFinancialRecordRestService }, { type: i2.DictionaryService }]; }, null); })();
