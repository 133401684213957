/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Pipe, PipeTransform } from '@angular/core';

import { DEFAULT_CURRENCY_TEMPLATE } from '@gh/config';
import { splitLikeNumber, substitute } from '@gh/core-util';

@Pipe({
  name: 'defaultCurrency',
  pure: true,
})
export class DefaultCurrencyPipe implements PipeTransform {
  transform(price: any): any {
    return price ? substitute(DEFAULT_CURRENCY_TEMPLATE, splitLikeNumber(price)) : '';
  }
}
