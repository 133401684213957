/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl, ValidationErrors } from '@angular/forms';
import {
  DirtyType,
  EntityFieldState,
  EntityState,
  EntityVersion,
  getEntityFieldState,
  getEntityState,
  ID,
  ProduceStatus,
  setEntityFieldState,
  setEntityState,
  WithCreatedDate,
  WithDirty,
  WithDirtyType,
  WithEntityState,
  WithFormMode,
  WithProduceStatus,
  WithRecalculationNeeded,
  WithUniqueId,
  WithVersion,
} from '@gh/common-shared-data';
import { Type } from '@gh/core-type';
import { FormMode, isCreateFormMode, isEditableFormMode } from '@gh/core-ui';
import { coalesce, currentDate, denull, isNotNil, isSimpleValueEqual, nullate } from '@gh/core-util';
import {
  assign,
  fromPairs,
  identity,
  isBoolean,
  isEmpty,
  isFunction,
  isNil,
  isString,
  isUndefined,
  iteratee,
  keyBy,
  mapValues,
  merge,
  omit,
  omitBy,
  pick,
  pickBy,
  uniqueId,
} from 'lodash';
import { FormControlSchemaConfig } from '../../form/form-schema/form-control-schema';

export type ValueValidatorFn = (value: any) => Nullable<Maybe<ValidationErrors>>;
export type FieldValidatorFn<T> = (entity: T, name: string, value: any) => Maybe<ValidationErrors>;
export type EntityValidatorFn<T> = (value: T) => Maybe<Hash<ValidationErrors>>;
export type FieldSetterFn<T> = (entity: T, field: string, value: any) => any;
export type FieldConfig<P> = {
  required?: boolean | PredicateFn2<any, string>;
  editable?: boolean | PredicateFn2<any, string>;
  type?: Type<P> | ((entity: any, name: string) => Type<P>);
  validator?: FieldValidatorFn<any>;
};
export type EntityConfig<T> = { [name: string]: FieldConfig<any> };

export type EntityMerge<T> = (oldData: Maybe<T>, newData: T, id: ID) => Maybe<T>;
export type EntityReplace<T> = (oldData: Maybe<T>, newData: Maybe<T>, id: ID) => Maybe<T>;
export type EntityTransform<T> = (data: T, id: ID) => Maybe<T>;

let lastNewItemId = 1;

export function nextNewItemId(): number {
  return lastNewItemId++;
}

/**
 * Returns error if value is empty
 */
export function requiredValidator<T>(value: T): Maybe<ValidationErrors> {
  return isNil(value) || isString(value) && value.trim() === '' ? { required: true } : void 0;
}

/**
 * Returns error if value does not satisfy corresponding type
 */
export function typeValidator(type: Type<any>): ValueValidatorFn {
  return (value) => type.validate(value);
}

/**
 * Array validator which returns error if one of array entities is invalid.
 */
export function nestedArrayValidator(array: WithEntityState<any>[]): Maybe<ValidationErrors> {
  return array.some(isEntityInvalid) ? { nestedArray: true } : void 0;
}

/**
 * Composes validator from the given set of validators. Final errors are merged into single object.
 */
export function composeValidators(...validators: ValueValidatorFn[]): ValueValidatorFn {
  return (value) => validators.reduce(
    (errors, validator) => {
      const nextErrors = validator(value);

      return errors ? assign(errors, nextErrors) : nextErrors;
    },
    <Nullable<Maybe<ValidationErrors>>>void 0);
}

export function requiredIfFieldValidator<T>(predicate: PredicateFn2<T, string>): FieldValidatorFn<T> {
  return (entity, name) => predicate(entity, name) ? requiredValidator(entity[name]) : void 0;
}

/**
 * Composes validator from the given set of validators. Final errors are merged into single object.
 */
export function composeFieldValidators<T>(...validators: FieldValidatorFn<T>[]): FieldValidatorFn<T> {
  return (entity, name, value) => validators.reduce(
    (errors, validator) => {
      const nextErrors = validator(entity, name, value);

      return errors ? assign(errors, nextErrors) : nextErrors;
    },
    <Maybe<ValidationErrors>>void 0);
}

/**
 * Creates validator that validates all fields of the entity
 */
export function entityValidatorFromFields<T>(validators: Hash<FieldValidatorFn<any>>): EntityValidatorFn<T> {
  return (entity) => {
    const errors = <Hash<ValidationErrors>>omitBy(
      mapValues(validators, (validator, name) => validator(entity, name, entity[name])),
      isUndefined);

    if (Object.keys(errors).length > 0) {
      return errors;
    }
    return void 0;
  };
}

/**
 * Creates validator that can validate each separate entity field
 */
export function combineFieldValidators<T>(validators: Hash<FieldValidatorFn<any>>): FieldValidatorFn<T> {
  return (entity, name, value) => validators[name] ? denull(validators[name](entity, name, value)) : void 0;
}

/**
 * Creates field validator based on the provided value validator
 */
export function fieldValidatorFrom<T>(validator: ValueValidatorFn): FieldValidatorFn<T> {
  return (entity, name, value) => denull(validator(value));
}

/**
 * Merges all setters into a single setter. At least one setter (main setter) should be defined.
 * All other setters will be called only if main setter has transformed original value.
 */
export function composeSetters<T>(main: FieldSetterFn<T>, ...otherSetters: FieldSetterFn<T>[]): FieldSetterFn<T> {
  return (entity, field, name) => otherSetters.reduce(
    (previous, setter) => {
      if (previous === entity) {
        return entity;
      } else {
        return setter(previous, field, name);
      }
    },
    main(entity, field, name));
}

/**
 * Immutable setter for the given field.
 */
export function setEntityField<T, N extends keyof T>(entity: T, name: N, value: T[N]): T;
export function setEntityField<T, N extends keyof T>(entity: T, name: N, value: (old: T[N]) => T[N]): T;
export function setEntityField<T>(entity: T, name: string, valueOrFn: any | ((value: any) => any)): T {
  const value = isFunction(valueOrFn) ? valueOrFn.call(void 0, entity[name]) : valueOrFn;

  if (entity && !isSimpleValueEqual(entity[name], value)) {
    return assign({}, entity, {
      [name]: value,
    });
  } else {
    return entity;
  }
}

/**
 * Immutable setter for nested field
 */
export function setNestedEntityField<T,
  P1 extends keyof T,
  P2 extends keyof T[P1],
  P3 extends keyof T[P1][P2]>(entity: T, path: [P1, P2, P3], value: T[P1][P2][P3]): T;
export function setNestedEntityField<T,
  P1 extends keyof T,
  P2 extends keyof T[P1]>(entity: T, path: [P1, P2], value: T[P1][P2]): T;
export function setNestedEntityField<T>(entity: T, path: string[], value: any): T {
  if (path.length > 1) {
    return setEntityField(entity, <any>path[0], setNestedEntityField(entity[path[0]], <any>path.slice(1), value));
  } else {
    return setEntityField(entity, <keyof T>path[0], value);
  }
}

/**
 * Immutable setter for the given fields.
 */
export function setEntityFields<T>(entity: T, values: Hash<any>): T {
  if (entity) {
    const modifiedFields = Object.keys(values).filter((field) => !isSimpleValueEqual(entity[field], values[field]));

    if (modifiedFields.length > 0) {
      return assign({}, entity, fromPairs(modifiedFields.map((field) => [field, values[field]])));
    }
  }

  return entity;
}

/**
 * Removes given field from the entity. If field does not exist in the entity => returns the same entity
 */
export function removeEntityField<T>(entity: T, field: string | number): T {
  // @ts-ignore
  return entity.hasOwnProperty(field) ? <any>omit(<any>entity, field) : entity;
}

/**
 * Removes given set of fields from the entity. If all fields do not exist in the entity => returns the same entity
 */
export function removeEntityFields<T>(entity: T, fields: string[]): T {
  // @ts-ignore
  return fields.some((field) => entity.hasOwnProperty(<any>field)) ? <any>omit(<any>entity, <any>fields) : entity;
}

/**
 * Creates setter that updates validation state of the field
 */
export function fieldValidationSetter<T extends WithEntityState<E>, E>(validator: FieldValidatorFn<T>): FieldSetterFn<T> {
  return (entity: T, field, value) => {
    const errors = validator(entity, field, value);
    const fieldState = getEntityFieldState(entity, field);

    if (fieldState.errors || isNil(fieldState.errors) && errors) {
      return setFieldErrors(entity, field, errors);
    } else {
      return entity;
    }
  };
}

/**
 * Creates setter that updates validation state of whole entity
 */
export function entityValidationSetter<T extends WithEntityState<E>, E>(validator: EntityValidatorFn<T>): FieldSetterFn<T> {
  return (entity: T) => {
    const errors = validator(entity);
    const allInvalidFields = (errors ? Object.keys(errors) : []).concat(getInvalidFields(entity));
    return allInvalidFields.reduce(
      (updatedEntity, field) => {
        const fieldState = getEntityFieldState(updatedEntity, field);
        const fieldErrors = errors && errors[field];

        if (fieldState.errors || isNil(fieldState.errors) && fieldErrors) {
          return setFieldErrors(updatedEntity, field, fieldErrors);
        } else {
          return updatedEntity;
        }
      },
      entity);
  };
}

/**
 * Merges errors into state of the given field
 * @param entity
 * @param field
 * @param errors
 */
export function setFieldErrors<T extends WithEntityState<E>, E>(entity: T,
                                                                field: string,
                                                                errors?: ValidationErrors): any {
  const fieldState = getEntityFieldState(entity, field);
  return setEntityFieldState(entity, field, { ...fieldState, errors });
}

/**
 * Sets or clears corresponding error in the state of the given field
 * @param entity
 * @param field
 * @param errorKey
 * @param errorDetails
 */
export function setFieldError<T extends WithEntityState<E>, E>(entity: T,
                                                               field: string,
                                                               errorKey: string,
                                                               errorDetails?: any): any {
  const fieldState = getEntityFieldState(entity, field);
  const { errors } = fieldState;
  const setError = !isNil(errorDetails);
  const hasError = !isNil(errors && errors[errorKey]);

  if (setError && !hasError) {
    const newErrors = { ...(errors || {}), [errorKey]: errorDetails };
    return setFieldErrors(entity, field, newErrors);
  } else if (!setError && hasError) {
    const newErrors = omit(<any>errors, errorKey);
    return setFieldErrors(entity, field, isEmpty(newErrors) ? void 0 : newErrors);
  } else {
    return entity;
  }
}

/**
 * Immutable setter for dirty flag
 */
export function setDirty<T extends WithDirty<E>, E>(entity: T): T {
  return setEntityField(entity, 'isDirty', true);
}

/**
 * Immutable setter for dirty flag
 */
export function setDirtyField<T extends WithDirty<E>, E>(entity: T, isDirty: boolean): T {
  return setEntityField(entity, 'isDirty', isDirty);
}

/**
 * Immutable resets dirty flag
 */
export function setPristine<T extends WithDirty<E>, E>(entity: T): T {
  return setEntityField(entity, 'isDirty', false);
}

/**
 * Immutable setter for recalculation needed flag
 */
export function setRecalculationNeeded<T extends WithRecalculationNeeded<E>, E>(entity: T): T {
  return setEntityField(entity, 'isRecalculationNeeded', true);
}

/**
 * Creates function that merges entity state calculated by the given functions into an original entity
 *
 * @param sources
 */
// tslint:disable-next-line:max-line-length
export function entityStateMerger<T extends WithEntityState<E>, E>(...sources: ((entity: T) => Hash<Partial<EntityFieldState>>)[]): (entity: T) => T {
  return (entity) => setEntityState(entity, <EntityState>merge({}, ...sources.map((source) => source(entity))));
}

/**
 * Create function that calculates entity state based on provided entity validator
 *
 * @param validator
 */
// tslint:disable-next-line:max-line-length
export function errorsEntityState<T extends WithEntityState<E>, E>(validator: EntityValidatorFn<T>): (value: T) => EntityState {
  return (entity) => <any>mapValues(validator(entity) || {}, (errors) => ({ errors }));
}

// tslint:disable-next-line:max-line-length
export function fieldStateByEntityConfig<T extends WithEntityState<E>, E>(entityConfig: EntityConfig<T>):
  (entity: T, field: keyof T) => EntityFieldState {
  return (entity, field) => {
    const { required, editable } = entityConfig[<any>field] || <EntityFieldState>{};
    return {
      required: coalesce(isFunction(required) ? required(entity, <any>field) : required, false),
      editable: coalesce(isFunction(editable) ? editable(entity, <any>field) : editable, true),
    };
  };
}

/**
 * Creates field validator based on provided {@link EntityConfig}
 */
// tslint:disable-next-line:max-line-length
export function fieldValidatorByEntityConfig<T>(entityConfig: EntityConfig<T>): FieldValidatorFn<T> {
  return combineFieldValidators(validatorMap(entityConfig));
}

/**
 * Creates form description for the provided data according to the given {@link EntityConfig}
 */
export function formSchemaBuilderByEntityConfig<T>(data: T,
                                                   entityConfig: EntityConfig<T>): Hash<FormControlSchemaConfig> {
  return mapValues(entityConfig, ({ required, editable, validator, type }, fieldName) => ({
    required: isNil(required) || isBoolean(required) ? coalesce(required, false) : required(data, fieldName),
    enabled: isNil(editable) || isBoolean(editable) ? coalesce(editable, true) : editable(data, fieldName),
    validators: isNil(validator) ? void 0 :
      [(control: AbstractControl) => nullate(validator(control.parent.value, fieldName, control.value))],
    type: isFunction(type) ? type(data, fieldName) : type,
  }));
}

/**
 * Creates entity validator based on provided {@link EntityConfig}
 */
// tslint:disable-next-line:max-line-length
export function entityValidatorByEntityConfig<T>(entityConfig: EntityConfig<T>): EntityValidatorFn<T> {
  return entityValidatorFromFields(validatorMap(entityConfig));
}

/**
 * Creates predicate based on provided entity validator.
 * Predicate returns true iff validator does not return any errors.
 */
export function entityValidatorToPredicate<T>(validator: EntityValidatorFn<T>): PredicateFn<T> {
  return (entity) => isNotNil(validator(entity));
}

/**
 * Creates map of field validators according to the provided {@link EntityConfig}.
 */
// tslint:disable-next-line:max-line-length
export function validatorMap<T>(entityConfig: EntityConfig<T>): Hash<FieldValidatorFn<any>> {
  return <any>pickBy(mapValues(entityConfig, (fieldConfig: FieldConfig<any>) => {
    const validators: FieldValidatorFn<any>[] = [];
    if (fieldConfig.required) {
      if (isFunction(fieldConfig.required)) {
        validators.push(requiredIfFieldValidator(fieldConfig.required));
      } else {
        validators.push(fieldValidatorFrom(requiredValidator));
      }
    }

    if (fieldConfig.type) {
      validators.push(isFunction(fieldConfig.type)
        // tslint:disable-next-line:max-line-length
        ? (entity, field, value) => fieldValidatorFrom(typeValidator((<Function>fieldConfig.type)(entity, field)))(
          entity,
          field,
          value)
        : fieldValidatorFrom(typeValidator(fieldConfig.type)));
    }

    if (fieldConfig.validator) {
      validators.push(fieldConfig.validator);
    }

    return validators.length > 1 ? composeFieldValidators(...validators) : validators[0];
  }));
}

/**
 * Generates and merges unique ID into entity
 * @param entity
 */
export function bindUniqueId<T>(entity: T): WithUniqueId<T> {
  return mergeUniqueId(uniqueId('entity:'), entity);
}

/**
 * Merges provided uniqueId into entity
 */
export function mergeUniqueId<T>(uid: ID, entity: T): WithUniqueId<T> {
  return { ...<any>entity, uniqueId: uid };
}

/**
 * Returns true if entity state has at least one error for any of fields
 * @param entity
 */
export function hasEntityStateErrors<T extends WithEntityState<E>, E>(entity: T): boolean {
  const entityState = getEntityState(entity);

  const fields = Object.keys(entityState);

  return !fields.every((field) => isNil(entityState[field].errors));
}

/**
 * Returns all fields having at least one error
 * @param entity
 */
export function getInvalidFields<T extends WithEntityState<E>, E>(entity: T): string[] {
  const entityState = getEntityState(entity);
  const fields = Object.keys(entityState);
  return fields.filter((field) => !isNil(entityState[field].errors));
}

/**
 * Returns true iff entity is invalid (has errors)
 */
export function isEntityInvalid<T extends WithEntityState<E>, E>(entity: T): boolean {
  const entityState = getEntityState(entity);
  const fields = Object.keys(entityState);
  return fields.some((field) => !isNil(entityState[field].errors));
}

/**
 * Returns true iff corresponding field of the given entity is invalid (has errors)
 */
export function isFieldInvalid<T extends WithEntityState<E>, E>(entity: T, name: string): boolean {
  const entityState = getEntityState(entity);

  return !isNil(entityState[name].errors);
}

/**
 * Merges entities in destination array with corresponding entities from the source array.
 * Entities to be merged are determined using <code>getId</code> function.
 * Merging is executed by <code>merger</code> function which accepts destination and source entities.
 *
 * @param {(entity: T) => any} getId
 * @param {(dest: T, source: T) => T} merger
 */
export function mergeEntitiesBy<T>(getId: (entity: T) => string,
                                   merger: (dest: T, source: T) => T): (dest: T[], source: T[]) => T[] {
  return (dest: T[], source: T[]) => {
    const sourceIndex = keyBy(source, getId);

    return dest.map((destEntity) => {
      const sourceEntity = sourceIndex[getId(destEntity)];

      if (sourceEntity) {
        return merger(destEntity, sourceEntity);
      } else {
        return destEntity;
      }
    });
  };
}

/**
 * Returns true if entity's dirty type is 'New'
 */
export function whenNew<T extends WithDirtyType<E>, E>(entity: T): boolean {
  return entity.dirtyType === DirtyType.New;
}

/**
 * Returns true if entity's dirty type is not 'Removed'
 */
export function whenExisting<T extends WithDirtyType<E>, E>(entity: T): boolean {
  return entity.dirtyType !== DirtyType.Removed;
}

/**
 * Returns true if entity's dirty type is 'Removed'
 */
export function whenRemoved<T extends WithDirtyType<E>, E>(entity: T): boolean {
  return entity.dirtyType === DirtyType.Removed;
}

/**
 * Returns true if entity's dirty type is 'Removed' or entity is undefined
 */
export function whenNotExist<T extends WithDirtyType<E>, E>(entity?: T): boolean {
  return isNil(entity) || entity.dirtyType === DirtyType.Removed;
}

/**
 * Returns true if entity's dirty type is 'Updated'
 */
export function whenUpdated<T extends WithDirtyType<E>, E>(entity: T): boolean {
  return entity.dirtyType === DirtyType.Updated;
}

/**
 * Returns true if entity has any dirty type assigned
 */
export function whenModified<T extends WithDirtyType<E>, E>(entity: T): boolean {
  return !isNil(entity.dirtyType);
}

/**
 * Returns true if entity does not have any dirty type assigned
 */
export function whenNotModified<T extends WithDirtyType<E>, E>(entity: T): boolean {
  return isNil(entity.dirtyType);
}

/**
 * Returns true if entity has any dirty type assigned
 */
export function whenDirty<T extends WithDirtyType<E>, E>(entity: T): boolean {
  return !isNil(entity.dirtyType);
}

/**
 * Marks entity as 'New'
 */
export function setNewDirtyType<T extends WithDirtyType<E>, E>(entity: T): T {
  return setEntityField(entity, 'dirtyType', DirtyType.New);
}

/**
 * Marks entity as 'Removed'
 */
export function setRemovedDirtyType<T extends WithDirtyType<E>, E>(entity: T): T {
  return setEntityField(entity, 'dirtyType', DirtyType.Removed);
}

/**
 * Resets dirty type of the provided entity
 */
export function resetDirtyType<T extends WithDirtyType<E>, E>(entity: T): T {
  return setEntityField(entity, 'dirtyType', void 0);
}

/**
 * Sets dirty type of the provided entity to 'Updated'
 */
export function forceUpdatedDirtyType<T extends WithDirtyType<E>, E>(entity: T): T {
  return setEntityField(entity, 'dirtyType', DirtyType.Updated);
}

/**
 * Tries to mark entity as modified. This method does not modify dirty type state if entity is already dirty,
 * otherwise entity is marked as 'Updated',
 */
export function setUpdatedDirtyType<T extends WithDirtyType<E>, E>(entity: T): T {
  if (whenNew(entity) || whenRemoved(entity) || whenUpdated(entity)) {
    return entity;
  } else {
    return setEntityField(entity, 'dirtyType', DirtyType.Updated);
  }
}

export function entityFieldsMerger<T>(fields: string[] = []): EntityMerge<T> {
  if (isEmpty(fields)) {
    return identity;
  } else {
    return (oldState, newState) => {
      if (oldState && newState) {
        return setEntityFields(oldState, pick(newState, fields));
      } else {
        return oldState;
      }
    };
  }
}

/**
 * Creates entity merger which calls provided merger iff both old and new entities are exist,
 * otherwise returns new entity (in this case it returns either full entity or nil)
 */
export function entityReplaceOrMergeWith<T>(merger: EntityMerge<T>): EntityMerge<T> {
  return (oldState, newState, id) => oldState && newState ? merger(oldState, newState, id) : newState;
}

export function entityFieldMergeBy<T, F extends keyof T>(field: F,
                                                         fieldMerger: (oldState: T[F],
                                                                       newState: T[F]) => T[F]): EntityMerge<T> {
  return (oldState, newState) => {
    if (oldState && oldState[field]) {
      return setEntityField(oldState, field, fieldMerger(oldState[field], newState[field]));
    } else {
      return oldState;
    }
  };
}

export function composeEntityMergers<T>(...mergers: EntityMerge<T>[]): EntityMerge<T> {
  return (oldState, newState, id) => mergers.reduce((nextState, merger) => merger(nextState, newState, id), oldState);
}

export function onChangeEntityMerger<T>(main: EntityMerge<T>, ...otherMergers: EntityMerge<T>[]): EntityMerge<T> {
  const composedOtherMerger = composeEntityMergers(...otherMergers);
  return (oldState, newState, id) => {
    const modifiedEntity = main(oldState, newState, id);
    return modifiedEntity === oldState ? modifiedEntity : composedOtherMerger(modifiedEntity, newState, id);
  };
}

export function setCreatedDate<T extends WithCreatedDate<E>, E>(entity: T): T {
  return setEntityField(entity, 'createdDate', currentDate());
}

export function resetCreatedDate<T extends WithCreatedDate<E>, E>(entity: T): T {
  return setEntityField(entity, 'createdDate', void 0);
}

// tslint:disable-next-line:max-line-length
export function newEntityComparator<T extends WithCreatedDate<WithDirtyType<E>>, E>(left: T, right: T): number {
  const isLeftNew = left.dirtyType === DirtyType.New ? 1 : 0;
  const isRightNew = right.dirtyType === DirtyType.New ? 1 : 0;

  if (isLeftNew && isLeftNew === isRightNew) {
    // tslint:disable-next-line:no-non-null-assertion
    return right.createdDate!.getTime() - left.createdDate!.getTime();
  }

  return isRightNew - isLeftNew;
}

export function mergeArraysByKey<T>(anchor: string | TransformFn<T, string>): (oldState: T[], newState: T[]) => T[] {
  const it = iteratee(anchor);

  return (oldState, newState) => {
    const oldStateIndex = keyBy(oldState, it);

    return newState.map((newItem) => {
      const oldItem = oldStateIndex[<any>it(newItem)];

      if (oldItem) {
        return assign({}, oldItem, newItem);
      } else {
        return newItem;
      }
    });
  };
}

export function queryDirty<T extends WithDirty<E>, E>(state: T): boolean {
  return state.isDirty || false;
}

export function queryRecalculationNeeded<T extends WithRecalculationNeeded<E>, E>(state: T): boolean {
  return state.isRecalculationNeeded === true;
}

export function queryProduceStatus<T extends WithProduceStatus<E>, E>(state: T): ProduceStatus {
  return state.produceStatus;
}

export function queryFormMode<T extends WithFormMode<E>, E>(state: T): FormMode {
  return state.formMode;
}

export function queryFormModeCreate<T extends WithFormMode<E>, E>(state: T): boolean {
  return isCreateFormMode(state.formMode);
}

export function queryFormEditable<T extends WithFormMode<E>, E>(state: T): boolean {
  return isEditableFormMode(queryFormMode(state));
}

export function queryVersion<T extends WithVersion<E>, E>(state: T): Maybe<EntityVersion> {
  return state.version;
}

export function queryAll<T>(state: T): T {
  return state;
}

export function isDirty<T extends WithDirty<E>, E>(entity: T): boolean {
  return entity.isDirty || false;
}

export function isDirtyTypeNew(dirtyType?: DirtyType): boolean {
  return dirtyType === DirtyType.New;
}

export function isDirtyTypeUpdated(dirtyType?: DirtyType): boolean {
  return dirtyType === DirtyType.Updated;
}

export function isDirtyTypeRemoved(dirtyType?: DirtyType): boolean {
  return dirtyType === DirtyType.Removed;
}

export function entityComplementor<T extends WithEntityState<E>, E>(validator: EntityValidatorFn<T>): IdentityFn<T> {
  return entityStateMerger(errorsEntityState(validator));
}

// tslint:disable-next-line:max-line-length
export function mergeAndValidateFields<T extends WithEntityState<E>, E>(validator: EntityValidatorFn<T>): TransformFn<Partial<T>, IdentityFn<T>> {
  const complementor = entityComplementor(validator);
  return (fields) => (entity) => complementor(setEntityFields(entity, fields));
}

export const setEntityFieldWithDirty = composeSetters(setEntityField, setDirty);
