/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataTypeDirective, DateTypeDirective, MaskedTypeDirective, NumberTypeDirective, QuantityTypeDirective, } from './directives';
import { TypeFormatPipe } from './pipes';
import { InputState, TYPE_SET, TypeService } from './services';
import * as i0 from "@angular/core";
export function injectTypeSet(typeSet) {
    return {
        provide: TYPE_SET,
        useValue: typeSet,
        multi: true,
    };
}
export function injectTypeSets(typeSets) {
    return (typeSets || []).map(injectTypeSet);
}
var CoreTypeModule = /** @class */ (function () {
    function CoreTypeModule() {
    }
    CoreTypeModule.forRoot = function (typeSets) {
        return {
            ngModule: CoreTypeModule,
            providers: [
                // define both these pipes as providers in order to inject them in service
                // revise this solution when feature https://github.com/angular/angular/issues/20536 will be ready
                DecimalPipe, DatePipe,
                TypeService,
                InputState,
                injectTypeSets(typeSets),
            ],
        };
    };
    CoreTypeModule.forChild = function (typeSets) {
        return {
            ngModule: CoreTypeModule,
            providers: injectTypeSets(typeSets),
        };
    };
    CoreTypeModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreTypeModule });
    CoreTypeModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreTypeModule_Factory(t) { return new (t || CoreTypeModule)(); } });
    return CoreTypeModule;
}());
export { CoreTypeModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreTypeModule, { declarations: [DataTypeDirective,
        MaskedTypeDirective,
        NumberTypeDirective,
        DateTypeDirective,
        QuantityTypeDirective,
        TypeFormatPipe], exports: [DataTypeDirective,
        MaskedTypeDirective,
        NumberTypeDirective,
        DateTypeDirective,
        QuantityTypeDirective,
        TypeFormatPipe] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreTypeModule, [{
        type: NgModule,
        args: [{
                declarations: [
                    DataTypeDirective,
                    MaskedTypeDirective,
                    NumberTypeDirective,
                    DateTypeDirective,
                    QuantityTypeDirective,
                    TypeFormatPipe,
                ],
                exports: [
                    DataTypeDirective,
                    MaskedTypeDirective,
                    NumberTypeDirective,
                    DateTypeDirective,
                    QuantityTypeDirective,
                    TypeFormatPipe,
                ],
            }]
    }], null, null); })();
