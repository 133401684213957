/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  EMPTY_PRODUCE_STATUS, EMPTY_REPORT_STATUS,
  isProduceStatusInProgress, isReportStatusInProgress,
  ProduceStatus,
  ReportStatus,
} from '@gh/common-shared-data';
import { PRODUCE_STATUS_CHECK_INTERVAL } from '@gh/config';
import { logError, wrapInObservable } from '@gh/core-util';
import { interval$ } from '@gh/rx';
import { delay$, distinctUntilChanged$, mergeMap$, scan$, switchMap$, takeWhile$ } from '@gh/rx/operators';
import { Observable, Subject } from 'rxjs';

export enum ProgressStatusEventType { Start, Next, Finish, Complete }

export type ProgressPollingEvent<P> = {
  type: ProgressStatusEventType;
  nextStatus: P;
};

const COMPLETE_EVENT: ProgressPollingEvent<any> = { type: ProgressStatusEventType.Complete, nextStatus: <any>void 0 };

export type ProgressPollingOptions<P> = {
  empty?: P;
  isInProgress: PredicateFn<P>;
  status: Observable<P>;
  poll(): Observable<P>;
};

export function pollProgress<P>({
                                  empty,
                                  isInProgress,
                                  status,
                                  poll,
                                }: ProgressPollingOptions<P>): Observable<ProgressPollingEvent<P>> {
  return status.pipe(
    distinctUntilChanged$((a, b) => isInProgress(a) === isInProgress(b)),
    delay$(0),
    switchMap$((nextStatus) =>
      isInProgress(nextStatus) ?
        interval$(PRODUCE_STATUS_CHECK_INTERVAL).pipe(
          switchMap$(poll),
          scan$(
            (lastEvent: ProgressPollingEvent<P>, polledStatus: P) => ({
              type: isInProgress(polledStatus)
                ? ProgressStatusEventType.Next
                : ProgressStatusEventType.Finish,
              nextStatus: polledStatus,
            }),
            { type: ProgressStatusEventType.Next, nextStatus }),
          mergeMap$((event: ProgressPollingEvent<P>) =>
            event.type === ProgressStatusEventType.Finish ? [event, COMPLETE_EVENT] : [event]),
          takeWhile$(({ type }: ProgressPollingEvent<P>) => type !== ProgressStatusEventType.Complete))
        : [{ type: ProgressStatusEventType.Start, nextStatus: <any>empty }]));
}

export type ProduceStatusPollingOptions = {
  status: Observable<ProduceStatus>;
  poll(): Observable<ProduceStatus>;
};

export type ReportStatusPollingOptions = {
  status: Observable<ReportStatus>;
  poll(): Observable<ReportStatus>;
};

export function pollProducingStatus({
                                      status,
                                      poll,
                                    }: ProduceStatusPollingOptions): Observable<ProgressPollingEvent<ProduceStatus>> {
  return pollProgress({
    empty: EMPTY_PRODUCE_STATUS,
    isInProgress: isProduceStatusInProgress,
    status,
    poll,
  });
}

export function pollReportingStatus({
                                      status,
                                      poll,
                                    }: ReportStatusPollingOptions): Observable<ProgressPollingEvent<ReportStatus>> {
  return pollProgress({
    empty: EMPTY_REPORT_STATUS,
    isInProgress: isReportStatusInProgress,
    status,
    poll,
  });
}

export type ManualProgressPollingOptions<P> = {
  empty?: P;
  isInProgress: PredicateFn<P>;
  poll(): Observable<P>;
};

export type ManualPollProgress<P> = {
  set(progress: P): void;
  track(p$: Observable<P>): void;
  finish(): void;
  onNext(callback: ProcedureFn<P>): void;
};

export function manualPollProgress<P>({
                                        empty,
                                        isInProgress,
                                        poll,
                                      }: ManualProgressPollingOptions<P>): ManualPollProgress<P> {
  const listeners: ProcedureFn<P>[] = [];
  const status$$ = new Subject<P | Observable<P>>();
  const subscription = pollProgress({
    empty,
    isInProgress,
    poll,
    status: status$$.pipe(switchMap$(wrapInObservable)),
  }).subscribe(
    (event: ProgressPollingEvent<P>) => {
      if (event.type !== ProgressStatusEventType.Start) {
        listeners.forEach((listener) => listener(event.nextStatus));
      }
    },
    logError);

  return {
    set: (progress) => status$$.next(progress),
    track: (progress) => status$$.next(progress),
    onNext: (listener) => listeners.push(listener),
    finish: () => subscription.unsubscribe(),
  };
}

export function isProducingStatusFinishEvent(event: ProgressPollingEvent<any>): boolean {
  return event.type === ProgressStatusEventType.Finish;
}
