/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

export function openInNewWindow(url: string): void {
  window.open(url, '_blank');
}

export function openInThisWindow(url: string): void {
  window.open(url, '_self');
}

export function dispatchResizeWindow(): void {
  window.dispatchEvent(new Event('resize'));
}
