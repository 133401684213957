<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<gh-ext-page>
  <gh-ext-page-title title="{{'lbl_financial_records' | translate}}"></gh-ext-page-title>
  <gh-ext-page-content matSort="">
    <gh-ext-load-tracker *ngIf="!isRecordsExist" fxFlex="" noData=""></gh-ext-load-tracker>

    <ng-container *ngIf="isRecordsExist">
      <!-- Desktop version -->
      <mat-table *ghExtIfQuery="'gt-sm'" [dataSource]="invoiceListDataSource" [trackBy]="getInvoiceId" multiTemplateDataRows="">

        <ng-container matColumnDef="dropDownAction">
          <mat-header-cell fxFlex="25px" *matHeaderCellDef="">
          </mat-header-cell>
          <mat-cell fxFlex="25px" *matCellDef="let invoiceData">
            <mat-icon svgIcon="gh:arrow_drop_down" class="drop-down-arrow rotate-270" *ngIf="!isRowExpanded(invoiceData)"></mat-icon>
            <mat-icon svgIcon="gh:arrow_drop_down" class="drop-down-arrow" *ngIf="isRowExpanded(invoiceData)"></mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="invoiceNumber">
          <mat-header-cell fxFlex="90px" *matHeaderCellDef="" mat-sort-header="">{{'lbl_invoice_no' | translate}}</mat-header-cell>
          <mat-cell fxFlex="90px" *matCellDef="let invoiceData">
            {{ invoiceData.invoiceNumber }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="accountingDate">
          <mat-header-cell fxFlex="110px" *matHeaderCellDef="" mat-sort-header="">{{'lbl_invoice_date' | translate}}</mat-header-cell>
          <mat-cell fxFlex="110px" *matCellDef="let invoiceData">{{ invoiceData.accountingDate | typeFormat:'ShortDate' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="dueDate">
          <mat-header-cell fxFlex="110px" *matHeaderCellDef="" mat-sort-header="">{{'lbl_due_date' | translate}}</mat-header-cell>
          <mat-cell fxFlex="110px" *matCellDef="let invoiceData">{{ invoiceData.dueDate | typeFormat:'ShortDate' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell fxFlex="140px" *matHeaderCellDef="" mat-sort-header="">{{'lbl_type' | translate}}</mat-header-cell>
          <mat-cell fxFlex="140px" *matCellDef="let invoiceData">{{ getInvoiceTypeLabelId(invoiceData.type) | translate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell fxFlex="40%" *matHeaderCellDef="" mat-sort-header="">{{'lbl_description' | translate}}</mat-header-cell>
          <mat-cell fxFlex="40%" *matCellDef="let invoiceData">{{ invoiceData.description }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="amountWithGst">
          <mat-header-cell fxFlex="100px" *matHeaderCellDef="" mat-sort-header="">{{'lbl_invoiced_dollar' | translate}}</mat-header-cell>
          <mat-cell fxFlex="100px" fxLayoutAlign="end" *matCellDef="let invoiceData">{{ invoiceData.amountWithGst | typeFormat:'Price' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="balance">
          <mat-header-cell fxFlex="100px" *matHeaderCellDef="" mat-sort-header="">{{'lbl_balance_dollar' | translate}}</mat-header-cell>
          <mat-cell fxFlex="100px" fxLayoutAlign="end" *matCellDef="let invoiceData">{{ invoiceData.balance | typeFormat:'Price' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="payments">
          <mat-cell *matCellDef="let invoiceData" fxFlex="100%">
            <div class="invoice-details">

              <gh-customer-financial-record-payment-list [paymentDataSource]="invoiceData.paymentDataSource">
              </gh-customer-financial-record-payment-list>

            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let invoice; columns: displayedColumns;" matRipple="" [class.expanded-row]="isRowExpanded(invoice)" (click)="onRowExpand(invoice)" [class.ext-primary-color]="isInvoiceExpired(invoice)" class="invoice-row mat-row-clickable">
        </mat-row>
        <mat-row [class.invoice-row-collapsed]="!isRowExpanded(invoice)" [class.invoice-row-expanded]="isRowExpanded(invoice)" *matRowDef="let invoice; columns: expandedDisplayedColumns;">
        </mat-row>

      </mat-table>

      <div fxShow="" fxHide.lt-md="true" class="footer" fxLayout="row">
        <div class="footer-container first" fxLayoutAlign="start"></div>
        <div class="footer-container" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="30px">
          <div class="footer-container-item paid-total">
            <span>{{'lbl_paid_total' | translate}}</span>:&nbsp;<span class="value">{{ financialRecord.paidTotal | typeFormat:'Price' | defaultCurrency }}</span>
          </div>
          <div class="footer-container-item invoiced-total">
            <span>{{'lbl_invoiced_total' | translate}}</span>:&nbsp;<span class="value">{{ financialRecord.invoicedTotal | typeFormat:'Price' | defaultCurrency }}</span>
          </div>
        </div>
      </div>

      <!-- Mobile version -->
      <div *ghExtIfQuery="'lt-md'">
        <ng-container *ngFor="let invoice of invoiceListDataSource | connect">

          <mat-card [class.no-border-supplement-parent-radius]="isRowExpanded(invoice)" class="card-list-item card-with-supplement">
            <mat-card-content>
              <div class="card-content-item">
                <div class="card-content-item-title">{{'lbl_invoice_no' | translate}}</div>
                <div class="card-content-item-value">{{ invoice.invoiceNumber }}</div>
              </div>
              <div class="card-content-item">
                <div class="card-content-item-title">{{'lbl_type' | translate}}</div>
                <div class="card-content-item-value">{{ getInvoiceTypeLabelId(invoice.type) | translate }}</div>
              </div>
              <div class="card-content-item">
                <div class="card-content-item-title">{{'lbl_invoice_date' | translate}}</div>
                <div class="card-content-item-value">{{ invoice.accountingDate | typeFormat:'ShortDate' }}</div>
              </div>
              <div class="card-content-item">
                <div class="card-content-item-title">{{'lbl_due_date' | translate}}</div>
                <div class="card-content-item-value">{{ invoice.dueDate | typeFormat:'ShortDate' }}</div>
              </div>
              <div class="card-content-item">
                <div class="card-content-item-title">{{'lbl_invoiced_dollar' | translate}}</div>
                <div class="card-content-item-value">{{ invoice.amountWithGst | typeFormat:'Price' | defaultCurrency }}</div>
              </div>
              <div class="card-content-item">
                <div class="card-content-item-title">{{'lbl_balance_dollar' | translate}}</div>
                <div class="card-content-item-value">{{ invoice.balance | typeFormat:'Price' | defaultCurrency }}</div>
              </div>
              <div class="card-content-item full-width">
                <div class="card-content-item-title">{{'lbl_description' | translate}}</div>
                <div class="card-content-item-value">{{ invoice.invoiceNumber }}</div>
              </div>
              <div class="card-content-item full-width">
                <div class="card-content-item-value"></div>
              </div>

              <div class="card-content-item full-width">
                <div class="card-content-item-value card-actions">
                  <mat-icon svgIcon="gh:arrow_drop_down" class="rotate-270" *ngIf="!isRowExpanded(invoice)"></mat-icon>
                  <mat-icon svgIcon="gh:arrow_drop_down" *ngIf="isRowExpanded(invoice)"></mat-icon>
                  <span (click)="onRowExpand(invoice)" class="card-toggle-action">{{ toggleReceiptsActionName(invoice) }}
              </span>
                </div>
              </div>

            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="isRowExpanded(invoice)" class="card-supplement">
            <mat-card-content>
              <ng-container *ngFor="let payment of invoice.payments">
                <div class="card-content-item full-width">
                  <div class="card-content-item-title">{{'lbl_receipt_no' | translate}}</div>
                  <div class="card-content-item-value">{{ payment.reference }}</div>
                </div>

                <div class="card-content-item">
                  <div class="card-content-item-title">{{'lbl_date' | translate}}</div>
                  <div class="card-content-item-value">{{ payment.paidDate | typeFormat:'ShortDate' }}</div>
                </div>

                <div class="card-content-item">
                  <div class="card-content-item-title">{{'lbl_paid_dollar' | translate}}</div>
                  <div class="card-content-item-value">{{ payment.amount | typeFormat:'Price' }}</div>
                </div>

                <div class="card-content-item full-width footer-divider">
                  <div class="card-content-item-value"></div>
                </div>
              </ng-container>

              <div class="card-content-item">
                <div class="card-content-item-value"></div>
              </div>
              <div class="card-content-item card-supplement-footer">
                <div class="card-content-item-title">{{'lbl_total_dollar' | translate}}</div>
                <div class="card-content-item-value value">{{ invoice.paymentsTotal | typeFormat:'Price' }}</div>
              </div>
            </mat-card-content>
          </mat-card>

        </ng-container>

        <mat-card *ghExtIfQuery="'lt-md'" class="card-list-footer">
          <mat-card-content>

            <div class="card-content-item">
              <div class="card-content-item-title">{{'lbl_paid_total' | translate}}</div>
              <div class="card-content-item-value paid-total">{{ financialRecord.paidTotal | typeFormat:'Price' | defaultCurrency }}</div>
            </div>

            <div class="card-content-item">
              <div class="card-content-item-title">{{'lbl_invoiced_total' | translate}}</div>
              <div class="card-content-item-value invoiced-total">{{ financialRecord.invoicedTotal | typeFormat:'Price' | defaultCurrency }}</div>
            </div>

          </mat-card-content>
        </mat-card>

      </div>
    </ng-container>
  </gh-ext-page-content>
</gh-ext-page>
