var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APPLICATION, BACKEND, REGION_CODE } from '@gh/config';
import { AuthState } from '@gh/core-auth';
import { denull } from '@gh/core-util';
import { delay$, publishReplay$, refCount$, scan$, tap$ } from '@gh/rx/operators';
import { BehaviorSubject } from 'rxjs';
import { MockAuthProvider } from '../mock-auth-provider.service';
import { MockLoginDialogComponent } from './mock-login-dialog/mock-login-dialog.component';
import * as i0 from "@angular/core";
var STORAGE_KEY = "mock-auth:" + APPLICATION + ":" + BACKEND + ":" + REGION_CODE + ":user-key";
function getUserKey() {
    return denull(localStorage.getItem(STORAGE_KEY));
}
function setUserKey(userKey) {
    localStorage.setItem(STORAGE_KEY, userKey);
}
function removeUserKey() {
    localStorage.removeItem(STORAGE_KEY);
}
function updateUserKey(userKey) {
    if (userKey) {
        setUserKey(userKey);
    }
    else {
        removeUserKey();
    }
}
export var PRIMARY_MOCK_OPTIONS = new InjectionToken('PrimaryMockOptions');
var PrimaryMockAuthProvider = /** @class */ (function (_super) {
    __extends(PrimaryMockAuthProvider, _super);
    function PrimaryMockAuthProvider(injector) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        _this.userKey$$ = new BehaviorSubject(getUserKey());
        _this.onAuthState = _this.userKey$$.pipe(tap$(updateUserKey), scan$(function (state, userKey) {
            if (userKey) {
                return AuthState.LoggedIn;
            }
            else if (state === AuthState.LoggedIn) {
                return AuthState.LogggedOut;
            }
            else {
                return AuthState.Unknown;
            }
        }, AuthState.Unknown), publishReplay$(1), refCount$(), 
        // Delay is necessary to make this stream asynchronous.
        // This stream can be read under APP_INITIALIZER service.
        // When stream is synchronous ApplicationRef may be not initialized when dependent code needs it.
        delay$(0));
        _this.onAuthState.subscribe(function (state) {
            _this._authState = state;
        });
        return _this;
    }
    Object.defineProperty(PrimaryMockAuthProvider.prototype, "dialogService", {
        get: function () {
            return this.injector.get(MatDialog);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PrimaryMockAuthProvider.prototype, "authState", {
        get: function () {
            return this._authState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PrimaryMockAuthProvider.prototype, "userKey", {
        get: function () {
            var value = this.userKey$$.value;
            if (value) {
                return value;
            }
            else {
                throw new Error('User is not logged in');
            }
        },
        enumerable: true,
        configurable: true
    });
    PrimaryMockAuthProvider.prototype.login = function () {
        var _this = this;
        return this.dialogService.open(MockLoginDialogComponent, { disableClose: true })
            .afterClosed().toPromise().then(function (userKey) {
            _this.userKey$$.next(userKey);
            window.location.reload();
            return Boolean(userKey);
        });
    };
    PrimaryMockAuthProvider.prototype.logout = function () {
        this.userKey$$.next(void 0);
        window.location.reload();
        return Promise.resolve(true);
    };
    PrimaryMockAuthProvider.ɵfac = function PrimaryMockAuthProvider_Factory(t) { return new (t || PrimaryMockAuthProvider)(i0.ɵɵinject(i0.Injector)); };
    PrimaryMockAuthProvider.ɵprov = i0.ɵɵdefineInjectable({ token: PrimaryMockAuthProvider, factory: PrimaryMockAuthProvider.ɵfac });
    return PrimaryMockAuthProvider;
}(MockAuthProvider));
export { PrimaryMockAuthProvider };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(PrimaryMockAuthProvider, [{
        type: Injectable
    }], function () { return [{ type: i0.Injector }]; }, null); })();
