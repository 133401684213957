/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';
import { get, isNil } from 'lodash';

import { TranslationMap } from './translation.interfaces';

@Injectable()
export class TranslationStorage {
  private maps: TranslationMap[] = [];

  putAll(map: TranslationMap): void {
    this.maps.push(map);
  }

  get(key: string): any {
    const maps = this.maps;

    for (const map of maps) {
      const translation = get(map, key);

      if (!isNil(translation)) {
        return translation;
      }
    }
  }
}
