/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable } from '@angular/core';

import { AbstractRestService, arrayOf, RestSettings } from '@gh/core-data';

import { Observable } from 'rxjs';

import { CustomerJobMapper } from './job.mapper';
import { CustomerJobDto } from './jobs.dto';

@Injectable()
export class CustomerJobRestService extends AbstractRestService {
  constructor(private settings: RestSettings) {
    super(settings);
  }

  findAll(): Observable<CustomerJobDto[]> {
    return this.httpGet(`/jobs`, arrayOf(CustomerJobMapper));
  }
}
