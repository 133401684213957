import { Injectable } from '@angular/core';
import { uniq } from 'lodash';
import { API_SB_URL } from '@gh/config';
import * as i0 from "@angular/core";
var ConfigurationHttpRequestInterceptor = /** @class */ (function () {
    function ConfigurationHttpRequestInterceptor() {
        this._headers = {};
        this.headerKeys = [];
    }
    Object.defineProperty(ConfigurationHttpRequestInterceptor.prototype, "headers", {
        set: function (headers) {
            this._headers = headers;
            this.headerKeys = Object.keys(headers);
        },
        enumerable: true,
        configurable: true
    });
    ConfigurationHttpRequestInterceptor.prototype.setHeader = function (key, value) {
        this._headers[key] = value;
        this.headerKeys = uniq(this.headerKeys.concat([key]));
    };
    ConfigurationHttpRequestInterceptor.prototype.updateUrl = function (isSandbox, url) {
        if (isSandbox) {
            var modifiedUrlArr = url.split('/');
            if (modifiedUrlArr.includes('bp')) {
                modifiedUrlArr[2] = API_SB_URL;
                return modifiedUrlArr.join('/');
            }
            else {
                return url;
            }
        }
        else {
            return url;
        }
    };
    ConfigurationHttpRequestInterceptor.prototype.intercept = function (originalReq, next) {
        var _this = this;
        var modifiedHeaders = this.headerKeys.reduce(function (headers, key) { return headers.set(key, _this._headers[key]); }, originalReq.headers);
        var isSandbox = this.headerKeys.includes('X-SandBox-Mode');
        var modifiedUrl = this.updateUrl(isSandbox, originalReq.url);
        return next.handle(originalReq.clone({
            headers: modifiedHeaders,
            url: modifiedUrl,
        }));
    };
    ConfigurationHttpRequestInterceptor.ɵfac = function ConfigurationHttpRequestInterceptor_Factory(t) { return new (t || ConfigurationHttpRequestInterceptor)(); };
    ConfigurationHttpRequestInterceptor.ɵprov = i0.ɵɵdefineInjectable({ token: ConfigurationHttpRequestInterceptor, factory: ConfigurationHttpRequestInterceptor.ɵfac });
    return ConfigurationHttpRequestInterceptor;
}());
export { ConfigurationHttpRequestInterceptor };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ConfigurationHttpRequestInterceptor, [{
        type: Injectable
    }], null, null); })();
