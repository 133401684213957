/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Permission } from './permission';

export abstract class PermissionProvider {
  abstract hasPermission(permission: Permission): boolean;
}
