/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicAuthHttpRequestInterceptor } from '@gh/core-auth';
import { Observable } from 'rxjs';
import { MockAuthProvider } from './mock-auth-provider.service';

@Injectable()
export class MockAuthHttpRequestInterceptor extends BasicAuthHttpRequestInterceptor {

  constructor(private authProvider: MockAuthProvider) {
    super();
  }

  protected interceptWithRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.interceptMock(req, next);
  }

  protected interceptWithoutRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.interceptMock(req, next);
  }

  private interceptMock(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.clone({ headers: req.headers.set('X-MockUserEmail', this.authProvider.userKey) }));
  }
}
