/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { AppRouter } from '@gh/common-shared';
import { KeycloakService } from '@gh/core-auth-keycloak';
import { DialogService } from '@gh/core-ui';
import { openInThisWindow, replaceUrlSegment } from '@gh/core-util';
import { CustomerUserContext, JobInfo } from '@gh/customer-shared';

import { map$ } from '@gh/rx/operators';

import { Subscription } from 'rxjs';

import { customerJobInfoDialogRoute } from '../customer-job-info-dialog/customer-job-info-dialog.component';

const EMPTY_JOB: JobInfo = <any>{};

@Component({
  selector: 'gh-customer-app',
  templateUrl: 'customer-app.component.html',
  styleUrls: ['customer-app.component.scss'],
})
export class CustomerAppComponent implements OnInit, OnDestroy {
  jobs: JobInfo[];
  hasJobs: boolean;

  infiniteScrollViewport?: string;
  private mediaSubscription: Subscription;

  get currentJob(): JobInfo {
    return this.userContext.currentJob || EMPTY_JOB;
  }

  constructor(private observer: MediaObserver,
              public userContext: CustomerUserContext,
              private dialogs: DialogService,
              private router: AppRouter,
              @Optional() private keycloak?: KeycloakService) {
  }

  ngOnInit(): void {
    // user context may be not initialized in case of error on login
    if (!this.userContext.isInitialized()) {
      return;
    }

    const { jobs } = this.userContext;
    this.jobs = jobs.length > 0 ? jobs : [EMPTY_JOB];
    this.hasJobs = jobs.length > 0;

    this.mediaSubscription = this.observer.media$.pipe(
      map$(() => this.observer.isActive('lt-md')))
      .subscribe((isRoot) => {
        this.infiniteScrollViewport = isRoot ? 'root' : void 0;
      });
  }

  ngOnDestroy(): void {
    if (this.mediaSubscription) {
      this.mediaSubscription.unsubscribe();
    }
  }

  onCurrentJobChange(job: JobInfo): void {
    this.router.navigateByUrl(replaceUrlSegment(this.router.url, 1, job.jobId));
  }

  onOpenJobInfo(): void {
    this.dialogs.openWithInput(customerJobInfoDialogRoute, {
      job: this.currentJob,
    });
  }

  onChangePassword(): void {
    if (this.keycloak) {
      openInThisWindow(this.keycloak.getChangePasswordUrl());
    }
  }

  onLogout(): void {
    this.userContext.logout();
  }
}
