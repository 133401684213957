/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:directive-selector use-host-property-decorator
import { Directive, Input } from '@angular/core';

/**
 * This directive allows to define calculatable attribute for automation testing.
 *
 * TODO: It is necessary to create custom webpack loader in order to remove all data-automation-id directives
 *       from all templates
 */
@Directive({
  selector: '[automation-id]',
  host: {
    '[attr.data-automation-id]': 'automationId',
  },
})
export class AutomationIDDirective {
  @Input('automation-id') automationId: string;
}
