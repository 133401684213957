/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { wrapInObservable } from '@gh/core-util';
import { first$, take$ } from '@gh/rx/operators';
import { cond, constant, fromPairs, stubTrue } from 'lodash';
import { Observable } from 'rxjs';
import { Action, ActionDispatcher, ConstantAction, LogicalAction } from './action.interfaces';
import { compoundLogicalAction, constantAction, exitAction, goToStep, logicalAction } from './actions';

export type FlowStateWithError<FS> = FS & { error?: any };

export const trueAction = eitherAction(stubTrue);

/**
 * Creates LogicalAction which runs provided predicate with current flow state.
 * If predicate returns true corresponding action is resolved successfully, otherwise it is failed.
 */
export function eitherAction<FS>(predicate: PredicateFn<FS>): LogicalAction<FS> {
  return logicalAction(
    'success', 'fail',
    (dispatch, flowState: FS) => dispatch(predicate(flowState) ? 'success' : 'fail', flowState));
}

/**
 * Creates ConstantAction which transforms flow state using provided function
 */
export function mapAction<FS>(transform: TransformFn<FS, FS>): ConstantAction<FS> {
  return constantAction(
    'next',
    (dispatcher: ActionDispatcher, state: FS) => dispatcher('next', transform(state)));
}

/**
 * Creates ConstantAction which runs provided function.
 * Primary goal of this action is to declare explicit side effects.
 */
export function sideEffectAction<FS>(procedure: ProcedureFn<FS>): ConstantAction<FS> {
  return constantAction(
    'next',
    (dispatcher: ActionDispatcher, state: FS) => {
      procedure(state);
      dispatcher('next', state);
    });
}

/**
 * Creates LogicalAction which creates Observable using provided function.
 * Action is resolved as soon as first event returned from the observable.
 * Returned event (data) becomes new flow state.
 * If observable is resolved to error state, error is merged into flow state under <code>error</code> name.
 * If observable is resolved without value, error action flow is triggered and flow state is not changed.
 */
export function streamValueAction<FS>(wait: (state: FS) => (Observable<FS> | Promise<FS>)): LogicalAction<FS> {
  return logicalAction(
    'success',
    'fail',
    (dispatcher: ActionDispatcher, state: FS) => {
      let fulfilled = false;
      const fulfill = (step: string, nextState: FS) => {
        if (!fulfilled) {
          fulfilled = true;
          dispatcher(step, nextState);
        }
      };
      wrapInObservable<FS>(wait(state)).pipe(
        take$(1))
        .subscribe(
          (nextState) => fulfill('success', nextState),
          (error) => fulfill('fail', { ...<any>state, error }),
          () => fulfill('fail', state));
    });
}

/**
 * Creates LogicalAction which creates Observable using provided function.
 * Action is resolved as soon as first event returned from the observable.
 * Returned event (data) becomes new flow state.
 * If observable is resolved to error state, error is merged into flow state under <code>error</code> name.
 * If observable is resolved without value, success action flow is triggered and flow state is not changed.
 */
export function streamOptionalValueAction<FS>(wait: (state: FS) => (Observable<FS> | Promise<FS>)): LogicalAction<FS> {
  return logicalAction(
    'success',
    'fail',
    (dispatcher: ActionDispatcher, state: FS) => {
      let fulfilled = false;
      const fulfill = (step: string, nextState: FS) => {
        if (!fulfilled) {
          fulfilled = true;
          dispatcher(step, nextState);
        }
      };
      wrapInObservable<FS>(wait(state)).pipe(
        take$(1))
        .subscribe(
          (nextState) => fulfill('success', nextState),
          (error) => fulfill('fail', { ...<any>state, error }),
          () => fulfill('success', state));
    });
}

/**
 * Creates LogicalAction which creates Observable using provided function.
 * Observable should return boolean value.
 * Action is resolved as soon as first value returned from the observable.
 * If value is true corresponding action is resolved successfully, otherwise it is failed.
 */
export function booleanStreamAction<FS>(predicate: (state: FS) => Observable<boolean>): LogicalAction<FS> {
  return logicalAction(
    'success',
    'fail',
    (dispatcher: ActionDispatcher, state: FS) =>
      predicate(state).pipe(
        first$())
        .subscribe((bool) => dispatcher(bool ? 'success' : 'fail', state)));
}

/**
 * Creates LogicalAction which resolves successfully when all provided actions are resolved successfully.
 * Otherwise, action is failed.
 */
export function andActions<FS>(...actions: LogicalAction<FS>[]): LogicalAction<FS> {
  const lastIndex = actions.length - 1;
  return compoundLogicalAction('success', 'fail', {
    'start': goToStep('condition.0'),
    ...fromPairs(actions.map((action, i) => [
      `condition.${i}`,
      action.bindAs(i === lastIndex ? 'condition.success' : `condition.${i + 1}`, 'condition.fail'),
    ])),
    'condition.success': exitAction('success'),
    '*': exitAction('fail'),
  });
}

/**
 * Creates LogicalAction which resolves successfully when one of provided actions is resolved successfully.
 * Otherwise, action is failed.
 */
export function orActions<FS>(...actions: LogicalAction<FS>[]): LogicalAction<FS> {
  const lastIndex = actions.length - 1;
  return compoundLogicalAction('success', 'fail', {
    'start': goToStep('condition.0'),
    ...fromPairs(actions.map((action, i) => [
      `condition.${i}`,
      action.bindAs('condition.success', i === lastIndex ? 'condition.fail' : `condition.${i + 1}`),
    ])),
    'condition.success': exitAction('success'),
    '*': exitAction('fail'),
  });
}

/**
 * Creates Action which starts one of given steps if corresponding predicate returns true.
 */
export function condAction<FS>(stepPairs: [PredicateFn<FS>, string][]): Action<FS> {
  return (dispatcher, state) => cond([
    ...<any[]>stepPairs.map(([predicate, step]) => [predicate, (fs: FS) => dispatcher(step, fs)]),
    [constant(true), () => {
      throw new Error('Target condition was not found');
    }],
  ])(state);
}
