/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { NgModule } from '@angular/core';
import { DATA_SERVICES } from './services';
import * as i0 from "@angular/core";
var CustomerSharedDataModule = /** @class */ (function () {
    function CustomerSharedDataModule() {
    }
    CustomerSharedDataModule.forRoot = function () {
        return {
            ngModule: CustomerSharedDataModule,
            providers: DATA_SERVICES,
        };
    };
    CustomerSharedDataModule.forChild = function () {
        return {
            ngModule: CustomerSharedDataModule,
            providers: [],
        };
    };
    CustomerSharedDataModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomerSharedDataModule });
    CustomerSharedDataModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomerSharedDataModule_Factory(t) { return new (t || CustomerSharedDataModule)(); }, imports: [[]] });
    return CustomerSharedDataModule;
}());
export { CustomerSharedDataModule };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomerSharedDataModule, [{
        type: NgModule,
        args: [{
                imports: [],
                declarations: [],
                exports: [],
            }]
    }], null, null); })();
