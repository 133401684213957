/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { MatDialogRef } from '@angular/material/dialog';
import { logError } from '@gh/core-util';
import { filter$, switchMap$, tap$ } from '@gh/rx/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

/**
 * This class serves as wrapper around {@link MatDialogRef}.
 * It is necessary to provide additional capabilities for dialog: like resolving of initial data.
 * Instance of this class takes care about lifecycle of MatDialog.
 */
export class DialogRef<C> {
  private subscription: Subscription;

  private nativeRef$$ = new BehaviorSubject<Maybe<MatDialogRef<C>>>(void 0);

  constructor(nativeRef$: Observable<MatDialogRef<C>>) {
    this.subscription = nativeRef$.pipe(
      tap$<MatDialogRef<C>>({
        next: (ref) => this.nativeRef$$.next(ref),
        error: (error) => this.nativeRef$$.error(error),
      }),
      switchMap$((dialogRef) => dialogRef.afterClosed()))
      .subscribe(
        () => {
          this.nativeRef$$.complete();
        },
        (error) => {
          logError(error);
          this.nativeRef$$.error(error);
        });
  }

  get componentInstance(): Maybe<C> {
    const nativeRef = this.nativeRef$$.value;
    return nativeRef ? nativeRef.componentInstance : void 0;
  }

  /**
   * Closes the dialog.
   *
   * @param dialogResult Optional result to return to the dialog opener.
   */
  close(dialogResult?: any): void {
    if (this.nativeRef$$.value) {
      this.nativeRef$$.value.close(dialogResult);
    }

    this.subscription.unsubscribe();
    this.nativeRef$$.complete();
  }

  /**
   * Gets an observable that is notified when the dialog is finished closing.
   */
  afterOpened(): Observable<any> {
    return this.nativeRef$$.pipe(
      filter$(Boolean),
      switchMap$((nativeRef: MatDialogRef<C>) => nativeRef.afterOpened()));
  }

  /**
   * Gets an observable that is notified when the dialog is finished closing.
   */
  afterClosed(): Observable<any> {
    return this.nativeRef$$.pipe(
      filter$(Boolean),
      switchMap$((nativeRef: MatDialogRef<C>) => nativeRef.afterClosed()));
  }
}
