/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

// tslint:disable:no-magic-numbers

import { padStart } from 'lodash';

// tslint:disable-next-line
const BitArray = require('bit-array');

function reorderHex(hex: string): string {
  const wordCount = Math.ceil(hex.length / 8);

  const words: string[] = [];
  for (let i = 0; i < wordCount; i++) {
    words.push(hex.substring(i * 8, i * 8 + 8));
  }
  words.reverse();

  return words.join('');
}

export class BitSet<T> {
  private array: any;

  constructor(size: number = 64, hex?: string) {
    if (hex) {
      hex = reorderHex(padStart(hex, size / 4, '0'));
    }

    this.array = new BitArray(size, hex);
  }

  set(order: T, value: boolean): void {
    this.array.set(order, value);
  }

  has(order: T): boolean {
    return this.array.get(order);
  }

  toString(): string {
    return reorderHex(this.array.toHexString());
  }
}
