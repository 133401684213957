/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Injectable, OnDestroy } from '@angular/core';
import { isFunction, isNil } from 'lodash';

@Injectable()
export class Clipboard implements OnDestroy {
  private clipboardEl?: HTMLTextAreaElement;

  ngOnDestroy(): void {
    this.destroy();
  }

  copy(text: string): void {
    this.init();

    const currentFocusedElement = document.activeElement;

    if (this.clipboardEl) {
      this.clipboardEl.value = text;
      this.clipboardEl.select();
      document.execCommand('copy');

      if (currentFocusedElement && isFunction(currentFocusedElement['focus'])) {
        currentFocusedElement['focus']();
      }
    }
  }

  private init(): void {
    if (isNil(this.clipboardEl)) {
      this.clipboardEl = document.createElement('textarea');
      this.clipboardEl.readOnly = true;
      this.clipboardEl.style.position = 'absolute';
      this.clipboardEl.style.left = '-1000px';
      this.clipboardEl.style.top = '-1000px';
      document.body.appendChild(this.clipboardEl);
    }
  }

  private destroy(): void {
    if (this.clipboardEl) {
      document.body.removeChild(this.clipboardEl);
    }
    this.clipboardEl = void 0;
  }
}
