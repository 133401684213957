/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { ExtNavigationItemComponent } from './ext-navigation-item.component';

@Component({
  selector: 'gh-ext-navigation',
  templateUrl: 'ext-navigation.component.html',
  styleUrls: ['ext-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtNavigationComponent {
  @ContentChildren(ExtNavigationItemComponent) items: QueryList<ExtNavigationItemComponent>;

  get hasOptions(): boolean {
    return this.items.some((item) => item.type === 'option');
  }
}
