/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { TranslationStorage } from '@gh/core-mls';
import { logError } from '@gh/core-util';
import { isNil } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@gh/core-mls";
var AppSubstitutionCompiler = /** @class */ (function () {
    function AppSubstitutionCompiler(translationStorage) {
        this.translationStorage = translationStorage;
    }
    AppSubstitutionCompiler.prototype.compileKey = function (key) {
        var dictionarySelectorIndex = key.indexOf('@');
        if (dictionarySelectorIndex >= 0) {
            return {
                name: key.substring(0, dictionarySelectorIndex),
                attributes: { dictionary: key.substring(dictionarySelectorIndex + 1) },
            };
        }
        else {
            return { name: key };
        }
    };
    AppSubstitutionCompiler.prototype.apply = function (key, value) {
        var dictionary = key.attributes && key.attributes.dictionary;
        if (dictionary) {
            var dictionaryKey = "dictionary:" + dictionary + ":" + value;
            var translation = this.translationStorage.get(dictionaryKey);
            if (isNil(translation)) {
                // tslint:disable-next-line:max-line-length prefer-template
                logError("AppSubstitutionCompiler: translation was not found for message parameter '" + key.name + "@" + dictionary + "=" + value + "'.\n" +
                    ("Add translation for key '" + dictionaryKey + "'"));
            }
            return translation;
        }
        else {
            return value;
        }
    };
    AppSubstitutionCompiler.ɵfac = function AppSubstitutionCompiler_Factory(t) { return new (t || AppSubstitutionCompiler)(i0.ɵɵinject(i1.TranslationStorage)); };
    AppSubstitutionCompiler.ɵprov = i0.ɵɵdefineInjectable({ token: AppSubstitutionCompiler, factory: AppSubstitutionCompiler.ɵfac });
    return AppSubstitutionCompiler;
}());
export { AppSubstitutionCompiler };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppSubstitutionCompiler, [{
        type: Injectable
    }], function () { return [{ type: i1.TranslationStorage }]; }, null); })();
