/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { DEFAULT_CURRENCY_TEMPLATE } from '@gh/config';
import { get, isNil } from 'lodash';

export function substitute(str: string, params: Hash<string>): string {
  return str.replace(/{([a-zA-Z0-9_.]+)}/g, (_, path) => {
    const text = get(params, path);

    return isNil(text) ? '' : text;
  });
}

export function inDefaultCurrency(price: string): string {
  return substitute(DEFAULT_CURRENCY_TEMPLATE, splitLikeNumber(price));
}

/**
 * Transforms given string to allow it used as plain string in regular expression
 */
export function regexpablePlainString(str: string): string {
  return str.replace(/([\(\)\{\}\+\-\[\]\\\.\^\$\|\?\:\*])/g, '\\$1');
}

const NUMBER_RE = /^([-]?)(.+)$/;

/**
 * Splits localized number string on sign and absolute number
 */
export function splitLikeNumber(str: string): { sign: '-' | ''; number: string } {
  const match = NUMBER_RE.exec(str);
  // tslint:disable-next-line:no-magic-numbers
  return match ? { sign: <any>match[1], number: match[2] } : { sign: '', number: '0' };
}

export function includesIgnoreCase(str: string, sub: string): boolean {
  const loweredSub = sub ? sub.toLowerCase() : void 0;
  return loweredSub ? str.toLowerCase().includes(loweredSub) : true;
}
