/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { Injectable } from '@angular/core';
import { logDebug } from '@gh/core-util';
import * as invariant from 'invariant';
import { isNil, isObject } from 'lodash';
import { TranslationCompiler } from './translation-compiler.service';
import { TranslationStorage } from './translation-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./translation-storage.service";
import * as i2 from "./translation-compiler.service";
var TranslationService = /** @class */ (function () {
    function TranslationService(storage, translationCompiler) {
        this.storage = storage;
        this.translationCompiler = translationCompiler;
        this.cache = {};
        this.cache[''] = this.translationCompiler.compile('');
    }
    TranslationService.prototype.get = function (keyOrTuple, params) {
        var isTuple = isObject(keyOrTuple);
        var key = isTuple ? keyOrTuple['id'] : keyOrTuple;
        if (isNil(key)) {
            invariant(key, 'Translation key is undefined');
            return key;
        }
        var compiled = this.cache[key];
        if (isNil(compiled)) {
            var text = this.storage.get(key);
            if (isNil(text)) {
                logDebug("Translation for key '" + key + "' is missing");
                text = key;
            }
            this.cache[key] = compiled = this.translationCompiler.compile(text);
        }
        return compiled(isTuple ? keyOrTuple['params'] : params);
    };
    TranslationService.prototype.getRaw = function (key) {
        return this.storage.get(key);
    };
    TranslationService.ɵfac = function TranslationService_Factory(t) { return new (t || TranslationService)(i0.ɵɵinject(i1.TranslationStorage), i0.ɵɵinject(i2.TranslationCompiler)); };
    TranslationService.ɵprov = i0.ɵɵdefineInjectable({ token: TranslationService, factory: TranslationService.ɵfac });
    return TranslationService;
}());
export { TranslationService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TranslationService, [{
        type: Injectable
    }], function () { return [{ type: i1.TranslationStorage }, { type: i2.TranslationCompiler }]; }, null); })();
