/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { REGION_CODE } from '@gh/config';
import { FormMode } from '@gh/core-ui';
import { denull } from '@gh/core-util';
import { constant, flatten, isArray, isBoolean, isFunction, isNil, map, overEvery, overSome, toArray } from 'lodash';
import { REGION_AU, REGION_NZ, REGION_US } from '../../../regions';
export function getForm(snapshot) {
    if (isNil(snapshot.currentForm)) {
        throw new Error('Current application state does not have form assigned');
    }
    return snapshot.currentForm;
}
export function getFormControl(snapshot, path) {
    var form = getForm(snapshot);
    if (isNil(form.group)) {
        return void 0;
    }
    return denull(form.group.get(path));
}
export function whenOneOf() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return overSome(toApplicationStatePredicates.apply(void 0, flatten(args)));
}
export function whenAllOf() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return overEvery(toApplicationStatePredicates.apply(void 0, flatten(args)));
}
export function whenModeEq(mode) {
    return function (snapshot) { return getForm(snapshot).mode === mode; };
}
export function whenControlHasExactValue(path, value) {
    return function (snapshot) {
        var control = getFormControl(snapshot, path);
        if (isNil(control)) {
            return false;
        }
        return control.value === value;
    };
}
export function whenControlHasAnyValue(path) {
    return function (snapshot) {
        var control = getFormControl(snapshot, path);
        if (isNil(control)) {
            return false;
        }
        return !isNil(control.value);
    };
}
export function whenRegionIn() {
    var regions = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        regions[_i] = arguments[_i];
    }
    return function (snapshot) { return toArray(regions).includes(REGION_CODE); };
}
export function whenHasPermission(permission) {
    return function (snapshot) { return snapshot.hasPermission(permission); };
}
export function whenHasPrivilegedToken(entryPoint) {
    return function (snapshot) {
        var control = getFormControl(snapshot, 'privilegedTokens');
        return control && !isNil(control.value[entryPoint]) || false;
    };
}
export function whenHasOneOfPrivilegedTokens(entryPoint) {
    var predicates = entryPoint.map(whenHasPrivilegedToken);
    return function (snapshot) { return predicates.reduce(function (has, predicate) { return has ? has : predicate(snapshot); }, false); };
}
export var alwaysTrue = function () { return true; };
export var alwaysFalse = function () { return false; };
export var whenViewMode = constant(whenModeEq(FormMode.ViewMode));
export var whenEditMode = constant(whenModeEq(FormMode.EditMode));
export var whenCreateMode = constant(whenModeEq(FormMode.CreateMode));
export var whenCreateOrEditMode = constant(whenOneOf(whenCreateMode(), whenEditMode()));
export var whenEditOrViewMode = constant(whenOneOf(whenEditMode(), whenViewMode()));
export var whenHasAnyPrivilegedToken = constant(whenControlHasAnyValue('privilegedTokens'));
export var whenUsRegion = constant(whenRegionIn(REGION_US));
export var whenAuRegion = constant(whenRegionIn(REGION_AU));
export var whenNzRegion = constant(whenRegionIn(REGION_NZ));
export var whenUsOrAuRegion = constant(whenRegionIn(REGION_US, REGION_AU));
export var whenAuOrNzRegion = constant(whenRegionIn(REGION_AU, REGION_NZ));
function toApplicationStatePredicates() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return map(args, function (predicate) {
        if (isFunction(predicate)) {
            return predicate;
        }
        else if (isArray(predicate)) {
            return whenOneOf.apply(void 0, predicate);
        }
        else if (isBoolean(predicate)) {
            return constant(predicate);
        }
        else {
            // tslint:disable:max-line-length
            throw new Error("Predicate is of illegal type: " + predicate + " (" + (!isNil(predicate) ? predicate['constructor'] : 'unknown') + ")");
        }
    });
}
