/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import {
  ApplicationState,
  ApplicationStateHolder,
  ApplicationStatePredicate,
  ApplicationStatePredicateSet,
} from '../app-state/application-state';
import { AppControl } from '../controls/app-control';

export type ControlSchemaWhenPrivileged = 'hide' | 'disable' | 'none';
export enum ControlSchemaType { Leaf, Group, Array, Native, Root }

export interface ControlSchemaPackedValue {
  value?: any;
}

export interface BaseControlSchemaConfig {
  key?: string;
  visible?: ApplicationStatePredicateSet;
  required?: ApplicationStatePredicateSet;
  enabled?: ApplicationStatePredicateSet;
  privileged?: string | string[];
  whenPrivileged?: ControlSchemaWhenPrivileged;
  validators?: ValidatorFn[];
  asyncValidators?: AsyncValidatorFn[];
}

export interface BaseControlSchema {
  schemaType: ControlSchemaType;

  key?: string;
  visible?: ApplicationStatePredicate;
  required?: ApplicationStatePredicate;
  enabled?: ApplicationStatePredicate;
  privilegedEntryPoint?: string[];
  whenPrivileged: ControlSchemaWhenPrivileged;
  validators: ValidatorFn[];
  asyncValidators: AsyncValidatorFn[];

  readonly state: ApplicationState;
  readonly stateHolder: ApplicationStateHolder;

  update(config: BaseControlSchemaConfig): void;

  applyTo(control: AppControl, packedValue?: ControlSchemaPackedValue): void;

  build(value?: any): AbstractControl;
}

export function controlPackedValue(value: any): ControlSchemaPackedValue {
  return { value };
}
