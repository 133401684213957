/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

export const KEY_ENTER = 13;
export const KEY_TAB = 9;
export const KEY_ESCAPE = 27;
export const KEY_SPACE = 32;
export const KEY_UP = 38;
export const KEY_DOWN = 40;
export const KEY_PAGE_UP = 33;
export const KEY_PAGE_DOWN = 34;
export const KEY_LEFT = 37;
export const KEY_RIGHT = 39;
export const KEY_HOME = 36;
export const KEY_END = 35;

export const MOUSE_LEFT_BUTTON = 0;
export const MOUSE_MIDDLE_BUTTON = 1;
export const MOUSE_RIGHT_BUTTON = 2;
