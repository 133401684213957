/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { nullate, setArrayElement, setField } from '@gh/core-util';
import { of$ } from '@gh/rx';
import { filter$, startWith$ } from '@gh/rx/operators';
import { forEach, get, isEmpty, isNil, isString, mapValues, some } from 'lodash';
import { Observable } from 'rxjs';

export enum FormMode { CreateMode = 1, EditMode = 2, ViewMode = 3}

export const isCreateFormMode = (mode: FormMode) => mode === FormMode.CreateMode;
export const isEditFormMode = (mode: FormMode) => mode === FormMode.EditMode;
export const isViewFormMode = (mode: FormMode) => mode === FormMode.ViewMode;
export const isEditableFormMode = (mode: FormMode) => mode === FormMode.CreateMode || mode === FormMode.EditMode;
export const isExistingFormMode = (mode: FormMode) => mode === FormMode.ViewMode || mode === FormMode.EditMode;

/**
 * Returns true, if value is empty or has at least one empty values
 *
 * @param value
 * @returns {boolean}
 */
function isNestedEmpty(value: any): boolean {
  if (isEmpty(value)) {
    return true;
  } else {
    return some(value, isEmpty);
  }
}

/**
 * Traverses through a tree of controls: FormControls, FormArrays and FormGroups
 *
 * @param root
 * @param fn
 */
export function traverseControlTree(root: AbstractControl, fn: ProcedureFn<AbstractControl>): void {
  const stack: AbstractControl[] = [];

  stack.push(root);

  while (stack.length) {
    const current = stack.pop();

    if (current instanceof FormGroup) {
      forEach(current.controls, (control) => stack.push(control));
    } else if (current instanceof FormArray) {
      current.controls.forEach((control) => stack.push(control));
    } else if (current) {
      fn(current);
    }
  }
}

/**
 * Traverses control tree and transforms into value tree by transforming each control leaf
 *
 * @param control
 * @param fn
 */
export function mapControlTree(control: AbstractControl, fn: TransformFn<AbstractControl, any>): any {
  if (control instanceof FormGroup) {
    return mapValues(control.controls, (current) => mapControlTree(current, fn));
  } else if (control instanceof FormArray) {
    return control.controls.map((current) => mapControlTree(current, fn));
  } else {
    return fn(control);
  }
}

/**
 * Reduces control tree using specified reducer and original value.
 *
 * @param {AbstractControl} control
 * @param {(original: any, control: AbstractControl) => any} reducer
 * @param original
 * @returns {any}
 */
export function reduceControlTree(control: AbstractControl,
                                  reducer: (original: any, control: AbstractControl) => any,
                                  original: any): any {
  if (control instanceof FormGroup) {
    const { controls } = control;
    return Object.keys(controls).reduce(
      (pOriginal, key) => setField(
        pOriginal,
        key,
        reduceControlTree(controls[key], reducer, get(pOriginal, key, void 0))),
      original);
  } else if (control instanceof FormArray) {
    return control.controls.reduce(
      (pOriginal, currentControl, i) => setArrayElement(
        pOriginal,
        i,
        reduceControlTree(currentControl, reducer, get(pOriginal, i, void 0))),
      original);
  } else {
    return reducer(original, control);
  }
}

/**
 * Traverses control tree and updates each control leaf by the value from the value tree
 *
 * @param control
 * @param value
 * @param fn
 */
export function updateControlTree(control: AbstractControl,
                                  value: any,
                                  fn: (control: AbstractControl, value: any) => void): void {
  if (control instanceof FormGroup) {
    forEach(control.controls, (current, key) => updateControlTree(current, get(value, key, void 0), fn));
  } else if (control instanceof FormArray) {
    control.controls.forEach((current, i) => updateControlTree(current, get(value, i, void 0), fn));
  } else {
    fn(control, value);
  }
}

/**
 * Finds form control in an array of controls using provided predicate
 *
 * @param array
 * @param predicate
 * @returns {AbstractControl}
 */
export function findFormControl(array: FormArray,
                                predicate: (control: AbstractControl) => boolean): Maybe<AbstractControl> {
  const length = array.length;

  for (let i = 0; i < length; i++) {
    const control = array.at(i);

    if (predicate(control)) {
      return control;
    }
  }

  return;
}

/**
 * Sets optional set of errors
 *
 * @param control
 * @param errors
 */
export function setErrors(control: AbstractControl, errors: Maybe<ValidationErrors>): void {
  control.setErrors(<any>errors);
}

/**
 * Resets errors set on control
 *
 * @param control
 */
export function clearErrors(control: AbstractControl): void {
  control.setErrors(<any>void 0);
}

/**
 * Clears all errors in this and nested controls
 *
 * @param root
 */
export function clearNestedErrors(root: AbstractControl): void {
  traverseControlTree(root, clearErrors);
}

/**
 * Collect values from given control and all its descendants into single object.
 * This method ignores state of control.
 * Value of FormGroup and FormArray consists of values of enabled children, this means if child control is disabled
 * its value is not merged into value of parent control. This method allows to bypass this restriction
 *
 * @param control
 * @returns {any}
 */
export function collectValues(control: AbstractControl): any {
  if (control instanceof FormArray) {
    return control.controls.map(collectValues);
  } else if (control instanceof FormGroup) {
    return mapValues(control.controls, collectValues);
  } else {
    return control.value;
  }
}

/**
 * Returns new validator that ignores nil values. Non-nil values are passed into given validator (original validator).
 *
 * @param validatorFn
 * @returns {(control:AbstractControl)=>(any|{[p: string]: any})}
 */
export function notEmptyValidator(validatorFn: ValidatorFn): ValidatorFn {
  return (control: AbstractControl) => {
    if (isNestedEmpty(control.value)) {
      return <any>void 0;
    } else {
      return validatorFn(control);
    }
  };
}

/**
 * Returns new async validator that ignores nil values.
 * Non-nil values are passed into given validator (original validator).
 *
 * @param validatorFn
 * @returns {(control:AbstractControl)=>(any|any)}
 */
export function notEmptyAsyncValidator(validatorFn: AsyncValidatorFn): AsyncValidatorFn {
  return (control: AbstractControl) => {
    if (isNestedEmpty(control.value)) {
      return of$(nullate(void 0));
    } else {
      return validatorFn(control);
    }
  };
}

/**
 * Returns control from the group by the given path. If control does not exist this method throws an error.
 *
 * @param group
 * @param path
 * @returns {AbstractControl}
 */
export function getControl(group: FormGroup, path: (string | number)[] | string): AbstractControl {
  const control = group.get(path);

  if (isNil(control)) {
    throw new Error(`FormGroup does not have control for '${path}' path`);
  }

  return control;
}

/**
 * Returns the last ascendant of the given control.
 *
 * @param control
 */
export function getRootControl(control: AbstractControl): AbstractControl {
  let parent = control.parent;
  while (parent.parent) {
    parent = parent.parent;
  }

  return parent;
}

/**
 * Returns value of the control from the group by the given path. If control does not exist this method throws an error.
 *
 * @param group
 * @param path
 * @returns {any}
 */
export function getControlValue(group: FormGroup, path: string): any {
  return getControl(group, path).value;
}

/**
 * Returns raw value of the control from the group by the given path.
 * If control does not exist this method throws an error.
 *
 * @param group
 * @param path
 * @returns {any}
 */
export function getControlRawValue(group: FormGroup, path: string): any {
  const control = getControl(group, path);
  return control instanceof FormGroup || control instanceof FormArray ? control.getRawValue() : control.value;
}

/**
 * Returns value of the control from the group by the given path. If control does not exist this method throws an error.
 *
 * @param group
 * @returns {any}
 */
export function getArrayControls(group: FormArray): any {
  return group.controls;
}

/**
 * Sets value of the control from the group by the given path. If control does not exist this method throws an error.
 *
 * @param group
 * @param path
 * @param value
 * @returns {any}
 */
export function setControlValue(group: FormGroup, path: string, value: any): void {
  getControl(group, path).setValue(value);
}

/**
 * Validates value of the control from the group by the given path.
 * If control does not exist this method throws an error.
 *
 * @param group
 * @param path
 * @returns {any}
 */
export function validateControlValue(group: FormGroup, path: string): void {
  getControl(group, path).updateValueAndValidity();
}

/**
 * Sets control from the group as dirty. If control does not exist this method throws an error.
 */
export function markControlAsDirty(group: FormGroup, path: string): void {
  getControl(group, path).markAsDirty();
}

/**
 * Sets control from the group as touched. If control does not exist this method throws an error.
 */
export function markControlAsTouched(group: FormGroup, path: string): void {
  getControl(group, path).markAsTouched();
}

/**
 * Patches value of the control from the group by the given path. If control does not exist this method throws an error.
 *
 * @param group
 * @param path
 * @param value
 * @returns {any}
 */
export function patchControlValue(group: FormGroup, path: string, value: any): void {
  getControl(group, path).patchValue(value);
}

/**
 * Sets enabled state of the control from the group by the given path.
 * If control does not exist this method throws an error.
 *
 * @param group
 * @param path
 * @param value
 * @returns {any}
 */
export function setControlEnabled(group: FormGroup, path: string, enabled: boolean): void {
  const control = getControl(group, path);

  if (control.enabled !== enabled) {
    if (enabled) {
      control.enable();
    } else {
      control.disable();
    }
  }
}

export function calculatedControlValue(control: AbstractControl): Observable<any> {
  return control.valueChanges.pipe(startWith$(control.value));
}

/**
 * Retrieves whether control by the given path is dirty
 */
export function isControlDirty(group: FormGroup, path: string): boolean {
  return getControl(group, path).dirty;
}

/**
 * Returns stream of control values
 */
export function controlValues(control: AbstractControl): Observable<any> {
  return control.valueChanges.pipe(startWith$(control.value));
}

/**
 * Returns stream of control values
 */
export function controlValidValues(control: AbstractControl): Observable<any> {
  return control.valueChanges.pipe(startWith$(control.value), filter$(() => control.valid));
}

/**
 * Functional wrapper around {@link FormControl} creation
 */
export function newFormControl(formState?: any): FormControl {
  return new FormControl(formState);
}

/**
 * Required validator for forms (similar to {@link Validators#required}, but unlike {@link Validators#required}
 * returns error for space-only string)
 */
export function requiredFormValidator(control: AbstractControl): Nullable<ValidationErrors> {
  const { value } = control;
  // value.length === 0 check is necessary for compatibility with {@link Validators#required}
  if (isNil(value) || value.length === 0) {
    return { required: true };
  } else if (isString(value)) {
    return value.trim().length === 0 ? { required: true } : null; // tslint:disable-line:no-null-keyword
  } else {
    return null; // tslint:disable-line:no-null-keyword
  }
}

export function validationError(messageId: string, params?: Hash<any>): any {
  return { messageId, ...params };
}

/**
 * Merges all errors from the given control and its descendants
 * @param control
 */
export function getAllErrors(control: AbstractControl): Maybe<ValidationErrors> {
  if (control.valid) {
    return void 0;
  }

  const stack = [control];
  let errors: Maybe<ValidationErrors>;

  while (stack.length) {
    const current = stack.pop()!; // tslint:disable-line:no-non-null-assertion

    errors = current.errors
      ? (errors ? { ...errors, ...current.errors } : current.errors)
      : errors;

    if (current instanceof FormArray) {
      current.controls.forEach((child) => stack.push(child));
    } else if (current instanceof FormGroup) {
      forEach(current.controls, (child) => stack.push(child));
    }
  }

  return errors;
}
