/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

export const MODULE_ACTION = '$MODULE$';
export const NESTED_ACTION = '$NESTED$';

export function isPermissionComplete(path: string): boolean {
  return /#/.test(path);
}
