/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { NgModule } from '@angular/core';
import { MessageHttpErrorInterceptor } from './message-http-error-interceptor';
import { MessageHttpResponseInterceptor } from './message-http-response-interceptor';

import { MessagePushService } from './message-push.service';

@NgModule({
  providers: [MessagePushService, MessageHttpResponseInterceptor, MessageHttpErrorInterceptor],
})
export class CoreMessagesModule {

}
