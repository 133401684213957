/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { CONTEXT_PATH, DEFAULT_LOCALE, SCAYT_SERVICE_ID } from '@gh/config';
import { arrayify, isNotNil } from '@gh/core-util';
import { cloneDeep, compact, defaults, isNil, omitBy } from 'lodash';

export type TinymceSettings = import('tinymce').Settings;
export type TinymcePasteOptions = { asText: boolean };
export type TinymceToolbarOptions = { styleSelect?: boolean; alignments?: boolean; links?: boolean; lists?: boolean };
const DEFAULT_TOOLBAR_OPTIONS: TinymceToolbarOptions = {
  styleSelect: true,
  alignments: true,
  links: true,
  lists: false,
};

export function loadTinymce(): Promise<any> {
  return import(/* webpackMode: 'lazy' */ './tinymce-bundle');
}

window['tinyMCEPreInit'] = {
  base: `${window.location.origin}${CONTEXT_PATH}tinymce`,
  suffix: '',
};

const TM_DEFAULT_SETTINGS = {
  menubar: false,
  fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
  branding: false,
  resize: false,
};

const hasScaytEnv = !!SCAYT_SERVICE_ID;

// configure scayt plugin for WSC proofreader
const TM_SCAYT_SETTINGS = SCAYT_SERVICE_ID
  ? {
    scayt_serviceId: SCAYT_SERVICE_ID,
    scayt_spellcheckLang: DEFAULT_LOCALE,
  }
  : {};

export class TinyMceSettingsBuilder {
  private _images = false;
  private _scayt = false;
  private _heightBounds?: [number, number];
  private _height?: number | string;
  private _styles?: string;
  private _css?: string[];
  private _statusBar: boolean = true;
  private _fontFamilySelector: boolean = true;
  private _formats?: Hash<any>;
  private _fontSizes?: string;
  private _responsive: boolean = false;
  private _pasteOptions?: TinymcePasteOptions;
  private _toolbarOptions: TinymceToolbarOptions = DEFAULT_TOOLBAR_OPTIONS;

  images(hasImages: boolean = true): this {
    this._images = hasImages;
    return this;
  }

  toolbarOptions(options: TinymceToolbarOptions): this {
    this._toolbarOptions = defaults({}, options, this._toolbarOptions, DEFAULT_TOOLBAR_OPTIONS);
    return this;
  }

  pasteOptions(options: TinymcePasteOptions): this {
    this._pasteOptions = options;
    return this;
  }

  scayt(): this {
    this._scayt = true;
    return this;
  }

  resizable(min: number, max: number): this {
    this._heightBounds = [min, max];
    return this;
  }

  height(height: number | string): this {
    this._height = height;
    return this;
  }

  statusBar(allowed: boolean): this {
    this._statusBar = allowed;
    return this;
  }

  css(css: string | string[]): this {
    this._css = <string[]>arrayify(css);
    return this;
  }

  styles(styles: string): this {
    this._styles = styles;
    return this;
  }

  noFontFamilySelector(): this {
    this._fontFamilySelector = false;
    return this;
  }

  fontSizes(fontSizes: string): this {
    this._fontSizes = fontSizes;
    return this;
  }

  formats(formats: Hash<any>): this {
    this._formats = formats;
    return this;
  }

  responsive(): this {
    this._responsive = true;
    return this;
  }

  build(): TinymceSettings {
    return <any>{
      ...cloneDeep(TM_DEFAULT_SETTINGS),
      ...(hasScaytEnv && this._scayt ? cloneDeep(TM_SCAYT_SETTINGS) : {}),
      plugins: this.buildPlugins(),
      toolbar: this.buildToolbar(),
      ...this.buildHeightSettings(),
      ...this.buildStyles(),
      ...this.buildFormats(),
      ...this.buildPasteSettings(),
      statusbar: this._responsive ? false : this._statusBar,
    };
  }

  private buildPlugins(): string[] {
    return ['link', 'noneditable', 'simple_paste']
      .concat(this._images ? ['upload_image'] : [])
      .concat(hasScaytEnv && this._scayt ? ['proofreader'] : [])
      .concat(this._toolbarOptions.lists ? ['lists'] : []);
  }

  private buildToolbar(): string[] {
    const commonButtons = ['bold', 'italic', 'underline'];
    if (this._responsive) {
      return [commonButtons.join(' ')];
    }
    const alignmentButtons = this._toolbarOptions.alignments ?
      ['alignleft', 'aligncenter', 'alignright', 'alignjustify']
      : [];
    const linkButtons = this._toolbarOptions.links ?
      ['link', 'unlink']
      : [];
    return [
      [
        ...commonButtons, 'noneditable',
        ...linkButtons,
        ...(this._images ? ['upload_image'] : []),
        'numlist', 'bullist',
        'copy', 'cut', 'removeformat',
        ...alignmentButtons,
        'undo', 'redo',
      ].join(' '),
      compact([
        this._toolbarOptions.styleSelect ? 'styleselect' : void 0,
        this._fontFamilySelector ? 'fontselect' : void 0,
        'fontsizeselect', 'forecolor', 'backcolor',
        'code', 'anchor',
      ]).join(' '),
    ];
  }

  private buildHeightSettings(): Maybe<TinymceSettings> {
    const { _height: height, _heightBounds: bounds } = this;
    return omitBy(
      {
        min_height: bounds && bounds[0],
        max_height: bounds && bounds[1],
        height,
        resize: isNotNil(bounds),
      },
      isNil);
  }

  private buildPasteSettings(): Maybe<TinymceSettings> {
    const { _pasteOptions } = this;
    if (isNil(_pasteOptions)) {
      return {};
    }
    const { asText } = _pasteOptions;
    return omitBy(
      {
        paste_as_text: asText,
      },
      isNil);
  }

  private buildStyles(): Maybe<TinymceSettings> {
    return omitBy(
      {
        content_style: this._styles,
        content_css: this._css,
      },
      isNil);
  }

  private buildFormats(): Maybe<TinymceSettings> {
    return omitBy(
      {
        formats: this._formats,
        fontsize_formats: this._fontSizes,
      },
      isNil);
  }
}

export function tmSettingsBuilder(): TinyMceSettingsBuilder {
  return new TinyMceSettingsBuilder();
}
