<!--
  ~ Developed for G.J. Gardner Homes by Softeq Development Corporation
  ~ http://www.softeq.com
  -->

<mat-table *ghExtIfQuery="'gt-sm'" [dataSource]="paymentDataSource" [trackBy]="getPaymentId" matSort="">
  <ng-container matColumnDef="reference">
    <mat-header-cell fxFlex="30%" *matHeaderCellDef="" mat-sort-header="" class="cell-align-left">{{'lbl_receipt_no' | translate}}</mat-header-cell>
    <mat-cell fxFlex="30%" *matCellDef="let payment" class="cell-align-left">{{ payment.reference }}</mat-cell>
    <mat-footer-cell *matFooterCellDef=""></mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="paidDate">
    <mat-header-cell fxFlex="50%" *matHeaderCellDef="" mat-sort-header="" class="cell-align-left">{{'lbl_date' | translate}}</mat-header-cell>
    <mat-cell fxFlex="50%" *matCellDef="let payment" class="cell-align-left">{{ payment.paidDate | typeFormat:'ShortDate' }}</mat-cell>
    <mat-footer-cell *matFooterCellDef=""></mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
    <mat-header-cell fxFlex="20%" *matHeaderCellDef="" mat-sort-header="" class="cell-align-right">{{'lbl_paid_dollar' | translate}}</mat-header-cell>
    <mat-cell fxFlex="20%" *matCellDef="let payment" class="cell-align-right">{{ payment.amount | typeFormat:'Price' }}</mat-cell>
    <mat-footer-cell *matFooterCellDef="" class="cell-align-right">{{ 'lbl_total' | translate }}:&nbsp;<span class="footer-total">{{ paidTotal() }}</span></mat-footer-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let payment; columns: displayedColumns;"></mat-row>
  <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>

</mat-table>
