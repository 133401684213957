/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { of$ } from '@gh/rx';
import { filter$, first$, mapTo$ } from '@gh/rx/operators';
import { findIndex, isNil, omit } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { isFinalStatus, UploadEngine, UploadEvent, UploadItem } from './upload.interfaces';

/**
 * {@link UploadMultiSlot} allows to track several upload processes. This class allows to detach upload processes
 * from the UI and rebind them to the UI component using ghUploadMultiSlot directive.
 *
 * Also {@link UploadMultiSlot} can be bound to the {@link AbstractControl} to update by the current state
 * of an upload process.
 */
export class UploadMultiSlot {
  items: UploadItem[] = [];
  event$: Observable<UploadEvent>;

  private itemUploadIndex: Hash<boolean> = {};
  private control?: AbstractControl;
  private itemSubscription: Subscription;

  constructor(private engine: UploadEngine, private uploadParams: Hash<any>) {
    this.event$ = this.engine.event$.pipe(
      filter$((event) => event.item.uploadId && this.itemUploadIndex[event.item.uploadId] || false));

    this.itemSubscription = this.event$.subscribe((event) => this.updateItem(event.item));
  }

  // get assigned(): boolean {
  //   return !isNil(this.item);
  // }

  /**
   * Adds new upload process
   * @param item
   */
  add(item: UploadItem): void {
    this.addItem(item);
  }

  /**
   * Remove upload process
   * @param {UploadItem} item
   */
  remove(item: UploadItem): void {
    this.cancel(item);
  }

  setAll(items?: UploadItem[]): void {
    this.reset();

    (items || []).forEach((item) => this.add(item));
  }

  /**
   * Sets undelrying control. This control will be updated by the current state of upload process.
   * @param control
   */
  setControl(control: AbstractControl): void {
    this.setAll(control.value);
    this.control = control;
  }

  start(item: UploadItem): void {
    this.filterByAssigned(item).forEach((i) => this.engine.start(i, this.uploadParams));
  }

  stop(item: UploadItem): void {
    this.filterByAssigned(item).forEach((i) => this.engine.cancel(i));
  }

  cancel(item: UploadItem): void {
    this.filterByAssigned(item).forEach((i) => {
      this.stop(item);
      this.removeItem(item);
    });
  }

  // cancel(): void {
  //   const { item } = this;
  //
  //   if (isNil(item)) {
  //     return;
  //   }
  //
  //   if (isUploadStarted(item) && !isUploadCompleted(item)) {
  //     this.engine.cancel(item);
  //   } else {
  //     this._unsubscribe();
  //   }
  //
  //   this.setItem(void 0);
  // }

  reset(): void {
    this.items.forEach((item) => this.cancel(item));
  }

  destroy(): void {
    this.itemSubscription.unsubscribe();
  }

  /**
   * Provides form validator for the assigned control.
   */
  formValidator(): AsyncValidatorFn {
    return (control) => {
      const { value }: { value: UploadItem[] } = control;

      if (isNil(value) || value.every((item) => item.status ? isFinalStatus(item.status) : true)) {
        // tslint:disable-next-line:no-null-keyword
        return of$(null);
      }

      return this.event$.pipe(
        first$(() => value.every((item) => item.status ? isFinalStatus(item.status) : true)),
        // tslint:disable-next-line:no-null-keyword
        mapTo$(null));
    };
  }

  private addItem(item: UploadItem): void {
    this.items = this.items.concat(item);
    if (item.uploadId) {
      this.itemUploadIndex[item.uploadId] = true;
    }
    this.updateItemControl();
  }

  private removeItem(item: UploadItem): void {
    this.items = this.items.filter((i) => item.uploadId ? item.uploadId === i.uploadId : i === item);
    if (item.uploadId) {
      this.itemUploadIndex = <Hash<boolean>>omit(this.itemUploadIndex, item.uploadId);
    }
    this.updateItemControl();
  }

  private updateItem(item: UploadItem): void {
    const { items } = this;
    const index = findIndex(items, (i) => item.uploadId ? item.uploadId === i.uploadId : i === item);

    if (index >= 0) {
      this.items = items.slice(0, index).concat(item).concat(items.slice(index + 1));
      this.updateItemControl();
    }
  }

  private updateItemControl(): void {
    const { control } = this;

    if (control) {
      control.setValue(this.items);
      control.markAsDirty();
    }
  }

  private filterByAssigned(item: UploadItem): UploadItem[] {
    if (item.uploadId) {
      return this.itemUploadIndex[item.uploadId] ? [item] : [];
    } else {
      return this.items.indexOf(item) >= 0 ? [item] : [];
    }
  }
}
