/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { TableLayoutBodyComponent, TableLayoutComponent, TableLayoutHeaderComponent } from './table-layout.component';

@NgModule({
  declarations: [
    TableLayoutComponent,
    TableLayoutHeaderComponent,
    TableLayoutBodyComponent,
  ],
  exports: [
    TableLayoutComponent,
    TableLayoutHeaderComponent,
    TableLayoutBodyComponent,
  ],
})
export class CoreUiTableLayoutModule {
  static forRoot(): ModuleWithProviders {
    return { ngModule: CoreUiTableLayoutModule };
  }

  static forChild(): ModuleWithProviders {
    return { ngModule: CoreUiTableLayoutModule };
  }
}
