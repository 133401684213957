/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */
import { of$ } from '@gh/rx';
import { filter$, first$, mapTo$ } from '@gh/rx/operators';
import { isNil } from 'lodash';
import { isUploadFinalEvent, isUploadCompleted, isUploadStarted, UploadStatus, } from './upload.interfaces';
/**
 * {@link UploadSlot} allows to track single upload process. This class allows to detach upload process from the UI
 * and rebind upload process to the UI component using ghUploadSlot directive.
 *
 * Also {@link UploadSlot} can be bound to the {@link AbstractControl} to update by the current state
 * of an upload process.
 */
var UploadSlot = /** @class */ (function () {
    function UploadSlot(engine, uploadParams) {
        var _this = this;
        this.engine = engine;
        this.uploadParams = uploadParams;
        this.event$ = this.engine.event$.pipe(filter$(function (event) { return _this.item ? event.item.uploadId === _this.item.uploadId : false; }));
    }
    Object.defineProperty(UploadSlot.prototype, "assigned", {
        get: function () {
            return !isNil(this.item);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Assignes new upload process. Old upload process will be reset (cancelled)
     * @param item
     */
    UploadSlot.prototype.assign = function (item) {
        this.reset();
        this.setItem(item);
        this._subscribe();
    };
    /**
     * Sets undelrying control. This control will be updated by the current state of upload process.
     * @param control
     */
    UploadSlot.prototype.setControl = function (control) {
        this.control = void 0;
        this.assign(control.value);
        this.control = control;
    };
    UploadSlot.prototype.start = function () {
        if (this.item) {
            this.engine.start(this.item, this.uploadParams);
        }
    };
    UploadSlot.prototype.cancel = function () {
        var item = this.item;
        if (isNil(item)) {
            return;
        }
        if (isUploadStarted(item) && !isUploadCompleted(item)) {
            this.engine.cancel(item);
        }
        else {
            this._unsubscribe();
        }
        this.setItem(void 0);
    };
    UploadSlot.prototype.reset = function () {
        this.cancel();
    };
    UploadSlot.prototype.destroy = function () {
        this._unsubscribe();
    };
    /**
     * Provides form validator for the assigned control.
     */
    UploadSlot.prototype.formValidator = function () {
        var _this = this;
        return function (control) {
            var value = control.value;
            if (isNil(value) || value.status !== UploadStatus.Uploading) {
                // tslint:disable-next-line:no-null-keyword
                return of$(null);
            }
            return _this.event$.pipe(first$(isUploadFinalEvent), 
            // tslint:disable-next-line:no-null-keyword
            mapTo$(null));
        };
    };
    UploadSlot.prototype.setItem = function (item) {
        var control = this.control;
        this.item = item;
        if (control) {
            control.setValue(item);
            control.markAsDirty();
        }
    };
    UploadSlot.prototype._subscribe = function () {
        var _this = this;
        this.itemSubscription = this.event$.subscribe(function (event) {
            var item = event.item;
            if (_this.item && _this.item.uploadId === item.uploadId) {
                _this.setItem(item);
            }
            if (isUploadFinalEvent(event)) {
                _this._unsubscribe();
            }
        });
    };
    UploadSlot.prototype._unsubscribe = function () {
        if (this.itemSubscription) {
            this.itemSubscription.unsubscribe();
            this.itemSubscription = void 0;
        }
    };
    return UploadSlot;
}());
export { UploadSlot };
