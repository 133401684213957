/*
 * Developed for G.J. Gardner Homes by Softeq Development Corporation
 * http://www.softeq.com
 */

import { Directive, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadContext } from './upload-context.service';
import { UploadMultiSlot } from './upload-multi-slot';
import { FileInfo, UploadController, UploadEvent, UploadItem } from './upload.interfaces';

/**
 * Implementation of {@link UploadController} interface
 * which binds itself to the assigned {@link UploadMultiSlot} instance.
 */
@Directive({
  selector: '[ghUploadMultiSlot]',
  providers: [{ provide: UploadController, useExisting: UploadMultiSlotDirective }],
})
export class UploadMultiSlotDirective extends UploadController {
  @Input('ghUploadMultiSlot') slot: UploadMultiSlot;

  constructor(private context: UploadContext) {
    super();
  }

  get event$(): Observable<UploadEvent> {
    return this.slot.event$;
  }

  get items(): UploadItem[] {
    return this.slot.items;
  }

  create(file: FileInfo): UploadItem {
    const item = this.context.create(file);

    this.slot.add(item);

    return item;
  }

  start(item: UploadItem): void {
    this.slot.start(item);
  }

  cancel(item: UploadItem): void {
    this.slot.cancel(item);
  }
}
